import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'businessLogic/shared/Buttons/Button';
import Icon from 'ui/shared/Icon';
import './styles.scss';

class SelectionOptions extends PureComponent {
	render() {
		const {visible, deleteFormat, editFormat} = this.props;
		const classes = classNames({
			'selection-options': true,
			'selection-options--show': visible,
			'selection-options--hide': !visible
		});
		let leftDistance;
		if (this.props.width <= 117) {
			leftDistance = this.props.width / 2 + this.props.left - 50.5;
		} else {
			leftDistance = (this.props.width - 117) / 2 + this.props.left;
		}
		return (
			<ul
				className={classes}
				style={{
					top: this.props.top + 15 + this.props.height + 'px',
					left: leftDistance + 'px'
				}}
			>
				<li className="selection-options__item" onClick={editFormat}>
					<Button theme="default">
						<Icon name="edit" size="normal" />
					</Button>
				</li>
				<li className="selection-options__item" onClick={deleteFormat}>
					<Button theme="default">
						<Icon name="delete" size="normal" />
					</Button>
				</li>
			</ul>
		);
	}
}
SelectionOptions.propTypes = {
	visible: PropTypes.bool,
	top: PropTypes.number,
	left: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
	deleteFormat: PropTypes.func,
	editFormat: PropTypes.func
};

SelectionOptions.defaultProps = {
	visible: false,
	top: 0,
	left: 0,
	width: 0,
	height: 0
};
export default SelectionOptions;
