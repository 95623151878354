import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

class ImgOverlay extends PureComponent {
	render() {
		const {colorLayer} = this.props;
		return (
			<div
				className="image-overlay"
				style={{
					backgroundColor: `rgba(${colorLayer.r}, ${colorLayer.g}, ${colorLayer.b}, ${colorLayer.a})`,
				}}
			/>
		);
	}
}

ImgOverlay.propTypes = {
	colorLayer: PropTypes.object,
};

ImgOverlay.defaultProps = {
	colorLayer: {},
};

export default ImgOverlay;
