import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';
// import Icon from 'ui/shared/Icon';

import {ReactComponent as FaviconBlack} from './img/favicon-black.svg';

/**
 * Botón de añadir elemento lo utilizaremos para mostrar el botón de añadir ladrillo, o cualquier tipo de elemento
 */
class AddButton extends PureComponent {
	handleClick = () => {
		if (this.props.onClick) {
			this.props.onClick();
		}
	};
	render() {
		const {className, labelButton, black} = this.props;
		const classes = classNames({
			'addButton-cmp': true,
			'addButton-cmp--label': labelButton,
			'addButton-cmp--black': black,
			[`${className}`]: true,
		});

		return (
			<div className={classes} onClick={this.handleClick}>
				<div className="addButton-cmp__icon-wrapper">
					{/* <Icon name="plus" size="normal" /> */}
					<FaviconBlack />
				</div>
				<span className="addButton-cmp__label">{labelButton}</span>
			</div>
		);
	}
}

AddButton.propTypes = {
	className: PropTypes.string,
	labelButton: PropTypes.string,
	onClick: PropTypes.func,
	black: PropTypes.bool,
};

AddButton.defaultProps = {
	className: '',
	labelButton: '',
	onClick: null,
	black: false,
};

export default AddButton;
