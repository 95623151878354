import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class FourImageCompositionZIndex extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Composición de cuatro imágenes - estilo 2`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault4Opt.svg',
					credit: '',
				},
			},
		],
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const images = data.images || [];

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'FourImageCompositionZIndex']);

		const classes = classNames({
			'brick': true,
			'four-image-composition-zindex-brick': true,
		});

		return (
			<Container
				size="extra-large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<Animation
						playAnimation={this.state.playEffect}
						delay={1}
						effect={Array.isArray(brickAnimation) ? brickAnimation[0] : brickAnimation}
					>
						<div className="image-wrap image2">
							<div className="the-img">
								<NewImageClickable imgObj={images[1].imageObj} clickable={true} />
							</div>

							{images[1].imageObj.credit && (
								<div className="image-caption image-caption--right">
									<div className="caption-credit"> {images[1].imageObj.credit} </div>
								</div>
							)}
						</div>
					</Animation>
					<Animation
						playAnimation={this.state.playEffect}
						delay={1.5}
						effect={Array.isArray(brickAnimation) ? brickAnimation[1] : brickAnimation}
					>
						<div className="image-wrap image1">
							<div className="the-img">
								<NewImageClickable imgObj={images[0].imageObj} clickable={true} />
							</div>

							{images[0].imageObj.credit && (
								<div className="image-caption">
									<div className="caption-credit"> {images[0].imageObj.credit} </div>
								</div>
							)}
						</div>
					</Animation>
					<Animation
						playAnimation={this.state.playEffect}
						delay={2}
						effect={Array.isArray(brickAnimation) ? brickAnimation[2] : brickAnimation}
					>
						<div className="image-wrap image3">
							<div className="the-img">
								<NewImageClickable imgObj={images[2].imageObj} clickable={true} />
							</div>

							{images[2].imageObj.credit && (
								<div className="image-caption">
									<div className="caption-credit"> {images[2].imageObj.credit} </div>
								</div>
							)}
						</div>
					</Animation>
					<Animation
						playAnimation={this.state.playEffect}
						delay={2.5}
						effect={Array.isArray(brickAnimation) ? brickAnimation[3] : brickAnimation}
					>
						<div className="image-wrap image4">
							<div className="the-img">
								<NewImageClickable imgObj={images[3].imageObj} clickable={true} />
							</div>

							{images[3].imageObj.credit && (
								<div className="image-caption">
									<div className="caption-credit"> {images[3].imageObj.credit} </div>
								</div>
							)}
						</div>
					</Animation>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(FourImageCompositionZIndex);
