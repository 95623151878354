import * as CONST from './constants.js';
import Logger from 'businessLogic/services/Logger';
import assign from 'lodash/assign';
import {getCookie} from 'businessLogic/helpers/functions';

let initialState = {
	dataUser: false,
};
try {
	if (!getCookie(CONST.AUTH_TOKEN)) {
		localStorage.removeItem(CONST.AUTH_TOKEN);
	}
	initialState[CONST.AUTH_TOKEN] = localStorage.getItem(CONST.AUTH_TOKEN);
} catch (e) {
	Logger.captureException(e);
}

//Exportamos por defecto la función reducer que gestiona todas las acciones
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case CONST.SET_TOKEN:
			return setToken(state, action);
		case CONST.SET_DATA_SESSION:
			return setDataUser(state, action);
		case CONST.DELETE_DATA_SESSION:
			return deleteDataUser(state, action);
		default:
			return state;
	}
}

//Definimos las funciones que gestionan cada acción recibida para modificar el store
function setToken(state, action) {
	const {token} = action.dataPayload;
	const newState = assign({}, state);
	newState[CONST.AUTH_TOKEN] = token;
	return newState;
}

function setDataUser(state, action) {
	const newState = assign({}, state);
	newState.dataUser = action.dataPayload;
	return newState;
}

function deleteDataUser(state) {
	const newState = assign({}, state);
	newState.dataUser = null;
	return newState;
}

export function delete_cookie(name) {
	document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
