import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import classNames from 'classnames';
import './styles.scss';

class ResponseTest extends Component {
	render() {
		const classes = classNames({
			'response-test-component': true,
			checked: this.props.active,
		});

		return (
			<div className={classes} onClick={this.props.onClick}>
				<div className="response-test-component__counter">
					<span>{this.props.optionLetter}</span>
				</div>
				<div className="response-test-component__text">
					<FrontEditableText text={this.props.text} editable={false} />
				</div>
			</div>
		);
	}
}

ResponseTest.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	optionLetter: PropTypes.string,
	onClick: PropTypes.func,
	active: PropTypes.bool
};

ResponseTest.defaultProps = {
	active: false
};
export default ResponseTest;
