import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Slate, Editable, withReact} from 'slate-react';
import {createEditor, Range, Editor, Node} from 'slate';
import Logger from 'businessLogic/services/Logger';
// import {Editor, Transforms, Text, createEditor} from 'slate';

import {withHistory} from 'slate-history';

import helpers from './helpers';
import HoveringToolbar from './components/HoveringToolbar';
import Element from './components/Element';
import Leaf from './components/Leaf';
import {htmlToSlate, slateToHtml} from './serializers';
import debounce from 'lodash/debounce';
import isHotkey from 'is-hotkey';
import './styles.scss';

const boldHotKey = isHotkey('mod+b');
const italicHotKey = isHotkey('mod+i');
const underlineHotKey = isHotkey('mod+u');

const withHelpers = (editor) => {
	editor.helpers = helpers;
	const inlineElements = ['link', 'wiki'];
	editor.isInline = (element) => {
		if (inlineElements.includes(element.type)) {
			return true;
		}
		return false;
	};
	return editor;
};

const emptyTextObject = {
	slateValue: [
		{
			type: 'paragraph',
			children: [{text: ''}],
		},
	],
	textValue: `<p></p>`,
};

const getTextObject = (value) => {
	if (!value) {
		return emptyTextObject;
	}

	if (typeof value === 'string') {
		// Si value no tiene tags html, entonces es un texto plano
		if (!/<\/?[a-z][\s\S]*>/i.test(value)) {
			return {
				slateValue: [
					{
						type: 'paragraph',
						children: [{text: value}],
					},
				],
				textValue: `<p>${value}</p>`,
			};
		}
		return {
			slateValue: htmlToSlate(value),
			textValue: value,
		};
	}

	if (typeof value === 'object') {
		if (Array.isArray(value)) {
			return {
				slateValue: value,
				textValue: slateToHtml(value),
			};
		}

		if (value.slateValue && Array.isArray(value.slateValue)) {
			if (value.textValue) {
				return value;
			}
			return {
				slateValue: value.slateValue,
				textValue: slateToHtml(value.slateValue),
			};
		}

		if (value?.textValue === '') {
			return emptyTextObject;
		}

		if (value.textValue) {
			// Si value no tiene tags html, entonces es un texto plano
			if (!/<\/?[a-z][\s\S]*>/i.test(value.textValue)) {
				return {
					slateValue: [
						{
							type: 'paragraph',
							children: [{text: value.textValue}],
						},
					],
					textValue: `<p>${value.textValue}</p>`,
				};
			}
			return {
				slateValue: htmlToSlate(value.textValue),
				textValue: value.textValue,
			};
		}
	}
};

const debouncedOnChange = debounce((value, onChange, initialObject = {}, increaseValueVersion) => {
	if (onChange) {
		let v = initialObject?.v || 0;
		if (increaseValueVersion) {
			v = v + 1;
		}
		onChange({
			...initialObject,
			slateValue: value,
			textValue: slateToHtml(value),
			v,
		});
	}
}, 500);

const SlateTextEditor = ({
	text,
	defaultValue,
	onChange,
	style,
	readOnly,
	increaseValueVersion,
	reloadOnVersionChange,
}) => {
	const editor = useMemo(() => withHelpers(withHistory(withReact(createEditor()))), []);
	const slateTextObject = useMemo(() => getTextObject(text || defaultValue), [text, defaultValue]);
	const [showToolbar, setShowToolbar] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const previousAnchorElPosition = React.useRef(undefined);

	React.useEffect(() => {
		if (anchorEl) {
			if (typeof anchorEl === 'object') {
				previousAnchorElPosition.current = anchorEl.getBoundingClientRect();
			} else {
				previousAnchorElPosition.current = anchorEl().getBoundingClientRect();
			}
		}
	}, [anchorEl]);

	const handleClose = () => {
		setShowToolbar(false);
	};

	const toggleToolbar = React.useCallback(() => {
		setShowToolbar(!showToolbar);
	}, [showToolbar]);

	// Escuchamos el evento para desenfocar el editor
	React.useEffect(() => {
		const handleEditorFocus = (e) => {
			if (e.detail.editor !== editor) {
				handleClose();
			}
		};

		window.addEventListener('editorFocus', handleEditorFocus);

		return () => {
			window.removeEventListener('editorFocus', handleEditorFocus);
		};
	}, [editor]);

	const handleMouseUp = React.useCallback(() => {
		const selection = window.getSelection();

		// Resets when the selection has a length of 0
		// if (!selection || selection.anchorOffset === selection.focusOffset) {
		if (!selection || selection.rangeCount === 0) {
			handleClose();
			return;
		}

		const getBoundingClientRect = () => {
			// if (previousAnchorElPosition.current) {
			// 	return previousAnchorElPosition.current;
			// }

			if (!selection || selection.rangeCount === 0) {
				// setShowToolbar(false);
				if (previousAnchorElPosition.current) {
					return previousAnchorElPosition.current;
				}
				return {};
			}

			try {
				const range = selection.getRangeAt(0);
				const rect = range.getBoundingClientRect();
				return rect;
			} catch (error) {
				console.error('Error en getBoundingClientRect', error);
				return previousAnchorElPosition.current;
			}
		};

		setShowToolbar(true);

		setAnchorEl({getBoundingClientRect});
	}, []);

	const handleKeyDown = React.useCallback(
		(event) => {
			// Si pulsa borrar y es una lista vacía, entonces borramos la lista
			if (event.key === 'Backspace') {
				const {selection} = editor;
				if (selection && Range.isCollapsed(selection)) {
					const [match] = Editor.nodes(editor, {
						match: (n) => n.type === 'list-item',
					});
					if (match) {
						// Obtenemos el contenido del nodo
						const [, path] = match;
						const node = Node.get(editor, path);
						const text = Node.string(node);
						if (text === '') {
							event.preventDefault();
							const parent = Node.parent(editor, path);
							return editor.helpers.toggleBlock(editor, parent.type, 'type');
						}
					}
				}
			}

			// Si pulsa enter y es una lista vacía, entonces borramos la lista
			if (event.key === 'Enter') {
				const {selection} = editor;
				if (selection && Range.isCollapsed(selection)) {
					const [match] = Editor.nodes(editor, {
						match: (n) => n.type === 'list-item',
					});
					if (match) {
						// Obtenemos el contenido del nodo
						const [, path] = match;
						const node = Node.get(editor, path);
						const text = Node.string(node);
						if (text === '') {
							event.preventDefault();
							const parent = Node.parent(editor, path);
							return editor.helpers.toggleBlock(editor, parent.type, 'type');
						}
					}
				}
			}

			// Si introduce "* " o "- " y es principio de línea, entonces lo convertimos en una lista
			if (event.key === ' ') {
				const {selection} = editor;
				if (selection && Range.isCollapsed(selection)) {
					const [match] = Editor.nodes(editor, {
						match: (n) => n.type === 'paragraph',
					});
					if (match) {
						// Obtenemos el contenido del nodo
						const [, path] = match;
						const node = Node.get(editor, path);
						const text = Node.string(node);
						if (text === '*' || text === '-') {
							event.preventDefault();
							// Eliminamos el contenido del nodo
							editor.deleteBackward('character');

							return editor.helpers.toggleBlock(editor, 'bulleted-list', 'type');
						}

						if (text === '1.') {
							event.preventDefault();
							// Eliminamos el contenido del nodo
							editor.deleteBackward('character');
							editor.deleteBackward('character');

							return editor.helpers.toggleBlock(editor, 'numbered-list', 'type');
						}
					}
				}
			}

			if (boldHotKey(event)) {
				event.preventDefault();
				return editor.helpers.toggleMark(editor, 'bold');
			}

			if (italicHotKey(event)) {
				event.preventDefault();
				return editor.helpers.toggleMark(editor, 'italic');
			}

			if (underlineHotKey(event)) {
				event.preventDefault();
				return editor.helpers.toggleMark(editor, 'underlined');
			}

			// Si se pulsa Escape, alternamos el toolbar
			if (event.key === 'Escape') {
				event.preventDefault();
				toggleToolbar();
				return;
			}

			// if (!event.ctrlKey) {
			// 	handleClose();
			// 	return;
			// }
			// switch (event.key) {
			// 	case 'b':
			// 		event.preventDefault();
			// 		return editor.helpers.toggleMark(editor, 'bold');
			// 	case 'i':
			// 		event.preventDefault();
			// 		return editor.helpers.toggleMark(editor, 'italic');
			// 	case 'u':
			// 		event.preventDefault();
			// 		return editor.helpers.toggleMark(editor, 'underlined');
			// }
		},
		[editor, toggleToolbar],
	);

	const handleMouseDown = React.useCallback(() => {
		handleClose();
	}, []);

	return (
		<Logger.ErrorBoundary beforeCapture={() => Logger.setTag('codeBlock', 'SlateTextEditor')}>
			<Slate
				editor={editor}
				initialValue={slateTextObject?.slateValue || emptyTextObject.slateValue}
				// initialValue={[]}
				onChange={(value) => {
					const isChange = editor.operations.some((op) => op.type !== 'set_selection');
					if (!isChange) {
						return;
					}

					debouncedOnChange(value, onChange, slateTextObject, increaseValueVersion);
				}}
				key={reloadOnVersionChange ? slateTextObject?.v : undefined}
			>
				<HoveringToolbar
					open={showToolbar}
					anchorEl={anchorEl}
					onClose={handleClose}
					editor={editor}
				/>
				{/* <div className="quill-container">
				<div className="quill notranslate">
					<div className="ql-container"> */}
				<Editable
					renderLeaf={Leaf}
					renderElement={Element}
					className="ql-editor"
					onMouseUp={handleMouseUp}
					onMouseDown={handleMouseDown}
					onKeyDown={handleKeyDown}
					onClick={(event) => {
						event.preventDefault();
						event.stopPropagation();
					}}
					readOnly={readOnly}
					// placeholder="Enter some text..."
					// onDOMBeforeInput={(event) => {
					// 	switch (event.inputType) {
					// 		case 'formatBold':
					// 			event.preventDefault();
					// 			return toggleFormat(editor, 'bold');
					// 		case 'formatItalic':
					// 			event.preventDefault();
					// 			return toggleFormat(editor, 'italic');
					// 		case 'formatUnderline':
					// 			event.preventDefault();
					// 			return toggleFormat(editor, 'underlined');
					// 	}
					// }}

					style={{
						':focus': {
							outline: 'none',
						},
						'padding': '8px',
						'outline': 'none',
						'whiteSpace': 'pre-wrap',
						...style,
					}}
					// disableDefaultStyles
				/>
				{/* </div>
				</div>
			</div> */}
			</Slate>
		</Logger.ErrorBoundary>
	);
};

SlateTextEditor.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onChange: PropTypes.func,
	style: PropTypes.object,
	readOnly: PropTypes.bool,
	increaseValueVersion: PropTypes.bool,
	reloadOnVersionChange: PropTypes.bool,
};

SlateTextEditor.defaultProps = {
	readOnly: false,
	increaseValueVersion: false,
	reloadOnVersionChange: false,
};

export default SlateTextEditor;
