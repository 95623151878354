import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

const modalRoot = document.getElementById('portals');

class DeleteModal extends React.PureComponent {
	constructor(props) {
		super(props);
		this.deleteModalContainer = document.createElement('div');
		this.isDivMounted = false;
	}
	componentWillUnmount() {
		if (modalRoot && this.isDivMounted && modalRoot.contains(this.deleteModalContainer)) {
			modalRoot.removeChild(this.deleteModalContainer);
		}
	}

	mountDiv = () => {
		if (modalRoot && !this.isDivMounted) {
			modalRoot.appendChild(this.deleteModalContainer);
			this.isDivMounted = true;
		}
	};

	handleOnClickdDelete = () => {
		this.props.deleteBrick();
		this.props.onClose();
	};

	render() {
		const classes = classNames({
			'delete-group-modal': true,
			'show': this.props.show,
		});

		this.mountDiv();

		return ReactDOM.createPortal(
			<div className={classes}>
				<div className="delete-group-modal__content">
					<div className="delete-group-modal__content__header">
						<h2>{t`¿Seguro que quieres eliminar este ladrillo?`}</h2>
					</div>

					<div className="delete-group-modal__content__footer">
						<Button variant="contained" color="secondary" onClick={this.props.onClose}>
							{t`Cancelar`}
						</Button>

						<Button variant="contained" color="error" onClick={this.handleOnClickdDelete}>
							{t`Eliminar`}
						</Button>
					</div>
				</div>

				<div className="delete-group-modal__overlay" onClick={this.props.onClose} />
			</div>,
			this.deleteModalContainer,
		);
	}
}

DeleteModal.propTypes = {
	show: PropTypes.bool,
	onClose: PropTypes.func,
	deleteBrick: PropTypes.func,
};

DeleteModal.defaultProps = {
	show: true,
};

export default DeleteModal;
