import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import editionFields from './edit.js';
import './styles.scss';

class AudioStop extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Stop audio`;
	static brickImage = brickImage;
	// static disableEdit = true;
	static brickHelpText = () => (
		<p>
			{t`Detiene el audio del ladrillo Audio con scroll automático.`}
			<br />
			<br />
			{t`Este ladrillo sirve para crear Storytelling. ¿Quieres ver un ejemplo? Pincha`}
			<a href="https://story-telling.cs01.cloud/" target="_blank" rel="noreferrer noopener">
				<i>{t`aquí`}</i>
			</a>
		</p>
	);

	static brickDefaultData = {
		audio: {
			audioSrc: '',
			credit: '',
			description: '',
		},
		changeSpeed: true,
	};

	static brickDefaultStyles = {
		containerStyle: {
			margin: '0 auto',
		},
	};

	constructor(props) {
		super(props);
		this.scrollPlay = false;
		this.isVisible = false;

		this.playerRef = React.createRef();
		this.playerContainer = React.createRef();
		this.distanceBetweenAudioBricks = 0;

		this.nextAudioBrick = null;
		this.prevAudioBrick = null;
	}

	setPrevAudioBrick = (audioRef) => {
		// Esta función nos permitirá saber cuando un ladrillo de audio tiene otro audio anterior
		// para más adelante mejorar el código y que si un ladrillo tiene anterior que se está
		// reproduciendo este no se reproduzca hasta que termine el anterior.
		// El problema viene cuando dos ladrillos se encuentran en la pantalla visibles a la vez
		// debería reproducirse el primero y setear la velocidad del scroll a 0 y cuando este termine que se reproduzca el siguiente
		this.prevAudioBrick = audioRef;
	};

	isVisible = () => {
		return this.isVisible;
	};

	onScrollStop = () => {
		this.scrollPlay = false;
		this.playerRef.current.pause();
		this.props.brickEvent('audioStop');
	};

	onNextAudioBrickMount = (audioRef) => {
		this.nextAudioBrick = audioRef;
		this.props.configObject.removeEventListener('audioAutoPlayMount', this.onNextAudioBrickMount);
	};

	handleVisibilityChange = (isVisible) => {
		this.isVisible = isVisible;
		if (this.playerRef.current) {
			const player = this.playerRef.current;
			if (isVisible) {
				player.currentTime = 0;
				let configSpeedObjetc = {};
				let audioDuration = player.duration + 1;
				let nextAudioBrickTop;
				if (this.nextAudioBrick && this.nextAudioBrick.refs.container) {
					nextAudioBrickTop = this.nextAudioBrick.refs.container.getBoundingClientRect().top;
				}
				let currentAudioBrickTop =
					this.playerContainer.current.refs.container.getBoundingClientRect().top;
				let distanceBetweenAudioBricks = nextAudioBrickTop - currentAudioBrickTop;
				if (distanceBetweenAudioBricks !== 0 && !isNaN(distanceBetweenAudioBricks)) {
					let newAudioDuration;
					if (isNaN(audioDuration)) {
						newAudioDuration = 0;
					} else {
						newAudioDuration = audioDuration;
					}
					let newScrollSpeed = distanceBetweenAudioBricks / (newAudioDuration * 10);
					configSpeedObjetc = {
						scrollSpeed: newScrollSpeed,
					};
				} else {
					configSpeedObjetc = {
						scrollSpeed: parseInt(this.props.configObject.returnSpeedBrick(), 10),
					};
				}
				this.props.configObject.unregisterConfig(this.playerContainer.current.refs.container);
				this.registerHandler = this.props.configObject.registerConfig(
					configSpeedObjetc,
					this.playerContainer.current.refs.container,
				);

				if (this.scrollPlay) {
					player.play();
				}
			}
		}
	};
	componentDidMount() {
		this.props.configObject.addEventListener('stop', this.onScrollStop);

		this.props.configObject.callEventListeners('audioAutoPlayMount', this.playerContainer.current);
	}

	componentWillUnmount() {
		this.props.configObject.removeEventListener('stop', this.onScrollStop);
	}

	static brickDefaultConfig = {};
	render() {
		const classes = classNames({
			'brick': true,
			'audio-brick-stop': true,
		});

		const styles = this.props.brick.styles || {};

		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
				ref={this.playerContainer}
				withMargin={false}
			>
				<div className={classes}>
					<audio controls key={'audioStop' + this.props.brick.id} loop={false} ref={this.playerRef}>
						<source src={''} type="audio/mp3" />
					</audio>
				</div>
				{this.props.editMode && (
					<div className="audio-brick-stop__edit">
						<div className="audio-brick-stop__edit__icon">
							<span className="audio-brick-stop__edit__icon__square" />
						</div>
						<span className="audio-brick-stop__edit__text">{t`Ladrillo Stop audio`}</span>
						<span className="audio-brick-stop__edit__advice">
							{t`(Texto y fondo solo visible en modo edición)`}
						</span>
					</div>
				)}
			</Container>
		);
	}
}

export default brickWrapper(AudioStop);
