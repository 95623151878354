import React, {PureComponent} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import OpenFieldQuestion from './components/OpenFieldQuestion';
import NumericSliderQuestion from './components/NumericSliderQuestion';
import AssessmentQuestion from './components/AssessmentQuestion';
import VisualAssessmentQuestion from './components/VisualAssessmentQuestion';
import SelectedChoiceQuestion from './components/SelectedChoiceQuestion';
import './styles.scss';

class Question extends PureComponent {
	constructor(props) {
		super(props);
		this.questionRef = React.createRef();
	}

	canContinue = () => {
		if (this.questionRef.current && this.questionRef.current.canContinue()) {
			return true;
		}
		return false;
	};

	render() {
		const {index, activeIndex} = this.props;
		const mainStyleClass = 'survey-question';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			'swiper-slide': true,
			'swiper-no-swiping': true,
		});

		let element;
		switch (this.props.flexibleContent.flexibleContentType) {
			case 'OrderPreferenceQuestion':
			case 'OrderPreferenceQuestion2':
				element = (
					<SelectedChoiceQuestion
						ref={this.questionRef}
						subtype="preferenceOrder"
						{...this.props}
					/>
				);
				break;
			case 'NumericSliderQuestion':
				element = <NumericSliderQuestion ref={this.questionRef} {...this.props} />;
				break;
			case 'AssessmentQuestion':
				element = <AssessmentQuestion ref={this.questionRef} {...this.props} />;
				break;
			case 'VisualAssessmentQuestion':
				element = <VisualAssessmentQuestion ref={this.questionRef} {...this.props} />;
				break;
			case 'OpenFieldQuestion':
				element = <OpenFieldQuestion ref={this.questionRef} {...this.props} />;
				break;
			case 'SelectedChoice':
			case 'SelectedChoice2':
				element = (
					<SelectedChoiceQuestion ref={this.questionRef} subtype="randomOrder" {...this.props} />
				);
				break;
			default:
				element = null;
		}
		return (
			<div className={classes}>
				{(activeIndex === index || activeIndex - 1 === index || activeIndex + 1 === index) && (
					<React.Fragment>{element}</React.Fragment>
				)}
			</div>
		);
	}
}

Question.propTypes = {
	flexibleContent: PropTypes.object,
	onFinishQuestion: PropTypes.func,
	set: PropTypes.func,
	index: PropTypes.number,
	onTyping: PropTypes.func,
};

export default Question;
