import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import PropTypes from 'prop-types';
import VerticalVideoVimeo from './components/VerticalVideoVimeo';
import VerticalVideoYouTube from './components/VerticalVideoYouTube';
import VerticalVideoUploaded from './components/VerticalVideoUploaded';
import './styles.scss';

class VideoCmp extends PureComponent {
	render() {
		const {
			videoSrc,
			videoCaption,
			controls,
			autoPlay,
			videoLoop,
			muted,
			credit,
			onPlay,
			onPause,
			onEnd,
		} = this.props;

		// Comprobación de la url del video
		let regExpYouTube = /^.*(youtu)/g;
		let regExpVimeo = /^.*(vimeo)/g;

		let videoIsYoutube = false;
		let videoIsVimeo = false;
		let videoIsUploaded = false;

		if (videoSrc !== undefined) {
			let matchYouTube = videoSrc.match(regExpYouTube);
			let matchVimeo = videoSrc.match(regExpVimeo);
			if (matchYouTube) {
				videoIsYoutube = true;
			} else if (matchVimeo) {
				videoIsVimeo = true;
			} else {
				videoIsUploaded = true;
			}
		}
		return (
			<div className="vertical-video-cmp">
				{videoIsVimeo && (
					<VerticalVideoVimeo
						video={videoSrc}
						videoCaption={videoCaption}
						controls={controls}
						autoPlay={autoPlay}
						videoLoop={videoLoop}
						muted={muted}
						onPlay={onPlay}
						onPause={onPause}
						onEnd={onEnd}
					/>
				)}
				{videoIsYoutube && (
					<VerticalVideoYouTube
						video={videoSrc}
						videoCaption={videoCaption}
						controls={controls}
						autoPlay={autoPlay}
						videoLoop={videoLoop}
						muted={muted}
						onPlay={onPlay}
						onPause={onPause}
						onEnd={onEnd}
					/>
				)}
				{videoIsUploaded && (
					<VerticalVideoUploaded
						video={videoSrc}
						videoCaption={videoCaption}
						controls={controls}
						autoPlay={autoPlay}
						videoLoop={videoLoop}
						muted={muted}
						onPlay={onPlay}
						onPause={onPause}
						onEnd={onEnd}
					/>
				)}
				{credit && <span className="video-cmp__credit">{credit}</span>}
			</div>
		);
	}
}

VideoCmp.propTypes = {
	videoSrc: PropTypes.string,
	videoCaption: PropTypes.string,
	controls: PropTypes.bool,
	autoPlay: PropTypes.bool,
	videoLoop: PropTypes.bool,
	muted: PropTypes.bool,
	onPlay: PropTypes.func,
	onPause: PropTypes.func,
	onEnd: PropTypes.func,
};

export default brickWrapper(VideoCmp);
