import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Input from 'ui/shared/FormFields/Input';
import InputCustomCheckBox from 'businessLogic/shared/Input/InputCustomCheckBox';
import LegalConditionsLink from 'businessLogic/shared/Links/LegalConditionsLink';
import TextArea from 'businessLogic/shared/Input/TextArea';
import classNames from 'classnames';
import './styles.scss';

class Form extends PureComponent {
	showLegalPopUp = () => {
		this.props.onShow(true);
	};
	setNameAndSurname = (event) => {
		this.props.setData('nameAndSurname', event.target.value);
	};
	setEmail = (event) => {
		this.props.setData('email', event.target.value);
	};
	setPhone = (event) => {
		this.props.setData('phone', event.target.value);
	};
	setMotivation = (event) => {
		this.props.setData('motivation', event.target.value);
	};
	setAcceptLegals = (event) => {
		this.props.setData('acceptLegals', event);
	};
	render() {
		const {data, onSendData, errorsObj} = this.props;
		let classes = classNames({
			'form-contest': true,
		});

		return (
			<div className={classes}>
				<Input
					className="form-contest__input"
					type="text"
					placeholder={data.nameAndSurname}
					theme="platform-input"
					errorText={errorsObj.nameAndSurnameError}
					onChange={this.setNameAndSurname}
				/>
				<Input
					className="form-contest__input"
					type="text"
					placeholder={data.email}
					theme="platform-input"
					errorText={errorsObj.emailError}
					onChange={this.setEmail}
				/>
				<Input
					className="form-contest__input"
					type="text"
					placeholder={data.phone}
					theme="platform-input"
					errorText={errorsObj.phoneError}
					onChange={this.setPhone}
				/>
				<TextArea
					className="form-contest__textarea"
					placeholder={data.motivation}
					theme="platform-textarea"
					errorText={errorsObj.motivationError}
					onChange={this.setMotivation}
				/>
				<div className="form-contest__input--checkbox">
					<InputCustomCheckBox setData={this.setAcceptLegals}>
						<LegalConditionsLink
							label={data.legalTextAccept}
							link={data.legalTextShowPopUp}
							text={data.legalTextContent}
							errorText={errorsObj.acceptLegalsError}
						/>
					</InputCustomCheckBox>
				</div>
				<button className={'form-contest__button'} onClick={onSendData}>
					{data.button}
				</button>
			</div>
		);
	}
}

Form.propTypes = {
	data: PropTypes.object,
};

Form.defaultProps = {
	data: {},
};

export default Form;
