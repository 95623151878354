import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ImageIcon from '@mui/icons-material/Image';
import VideoIcon from '@mui/icons-material/VideoLibrary';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import DocumentIcon from '@mui/icons-material/Description';
// import {useSelector} from 'react-redux';
// import {isLogged} from 'businessLogic/store/data/session';
import {t} from 'businessLogic/scope/user/helper/userTtag';

import FileItem from './components/FileItem';

const styles = (sx) => (theme) => ({
	'&.FileInput': {
		width: '100%',
	},
	'& .FileInput__input': {
		display: 'none',
	},
	'& .FileInput__preview': {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '0.5rem',
		marginBottom: '0.5rem',
		width: '100%',
	},
	'& .FileInput__button': {
		'width': '100%',
		// Botón de color de interacción
		'color': theme.platformStyles.interactionColor,
		// Botón de borde de interacción
		'borderColor': theme.platformStyles.interactionColor,
		// Botón de color de interacción al pasar el mouse
		'&:hover': {
			color: theme.platformStyles.interactionColor,
			borderColor: theme.platformStyles.interactionColor,
		},
		// Botón de color de interacción al estar activo
		'&:active': {
			color: theme.platformStyles.interactionColor,
			borderColor: theme.platformStyles.interactionColor,
		},
	},
	'& .FileInput__error': {
		color: theme.palette.error.main,
		fontSize: 12,
		textAlign: 'right',
		paddingTop: 1,
	},
	...sx,
});

const getButtonText = (mimeType) => {
	switch (mimeType) {
		case 'image':
			return t`Subir imagen`;
		case 'video':
			return t`Subir video`;
		case 'audio':
			return t`Subir audio`;
		case 'pdf':
			return t`Subir PDF`;
		case 'document':
			return t`Subir documento`;
		default:
			return t`Subir archivo`;
	}
};

const getIcon = (mimeType) => {
	switch (mimeType) {
		case 'image':
			return <ImageIcon />;
		case 'video':
			return <VideoIcon />;
		case 'audio':
			return <LibraryMusicIcon />;
		case 'pdf':
			return <PdfIcon />;
		case 'document':
			return <DocumentIcon />;
		default:
			return <UploadFileIcon />;
	}
};

const getAccept = (mimeType) => {
	switch (mimeType) {
		case 'image':
			return 'image/*';
		case 'video':
			return 'video/*';
		case 'audio':
			return 'audio/*';
		case 'pdf':
			return 'application/pdf';
		case 'document':
			return 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		default:
			return '*';
	}
};

export default function FileInput({mimeType, multiple, onChange, errorText, sx}) {
	const [files, setFiles] = React.useState([]);
	const inputRef = React.useRef(null);
	// const isUserLogged = useSelector(isLogged);
	const buttonText = getButtonText(mimeType);
	const icon = getIcon(mimeType);
	const accept = getAccept(mimeType);

	const isButtonDisabled = () => {
		// if (!isUserLogged) {
		// 	return true;
		// }
		if (multiple) {
			return false;
		}
		return files.length > 0;
	};

	return (
		<Box className="FileInput" sx={styles(sx)}>
			{files.length > 0 && (
				<Box className="FileInput__preview">
					{files.map((file, index) => (
						<FileItem
							key={index}
							file={file}
							onDelete={() => {
								const newFiles = [...files];
								newFiles.splice(index, 1);
								setFiles(newFiles);
								onChange(newFiles);
							}}
							mimeType={mimeType}
						/>
					))}
				</Box>
			)}

			<input
				className="FileInput__input"
				type="file"
				accept={accept}
				multiple={multiple}
				onChange={(event) => {
					const newFiles = event.target.files;
					const allFiles = [...files, ...newFiles];
					setFiles(allFiles);
					onChange(allFiles);
				}}
				ref={inputRef}
			/>
			<Button
				className="FileInput__button"
				variant="outlined"
				component="span"
				startIcon={icon}
				onClick={() => inputRef.current.click()}
				disabled={isButtonDisabled()}
			>
				{buttonText}
			</Button>
			{/* {!isUserLogged && (
				<Box className="FileInput__error">{t`Debes iniciar sesión para poder subir archivos al formulario`}</Box>
			)} */}
			{errorText && <Box className="FileInput__error">{errorText}</Box>}
		</Box>
	);
}
