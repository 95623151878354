import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Repeater',
						min: 2,
						max: 10,
						fieldName: 'images',
						blockTitle: t`Imagen`,
						content: [
							{
								type: 'Image',
								fieldName: 'imageObj',
								defaultImage: '/uploads/default-images/csDefault1Opt.svg',
							},
							{
								type: 'TextEditor',
								fieldName: 'title',
								title: t`Título de la imagen`,
								placeholder: t`Introduce el título de la imagen`,
							},
							{
								type: 'TextEditor',
								fieldName: 'text',
								title: t`Texto de la imagen`,
								placeholder: t`Introduce el texto de la imagen`,
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Configuración`,
				fieldName: 'config',
				content: [
					{
						type: 'Number',
						fieldName: 'delay',
						title: t`Intervalo entre slides (en seg.)`,
						placeholder: t`Aumenta o disminuye`,
						min: 0,
						max: 10,
					},
					{
						type: 'Number',
						fieldName: 'transitionTime',
						title: t`Duración de la transición (en seg.)`,
						placeholder: t`Aumenta o disminuye`,
						min: 0,
						max: 10,
					},
					{
						type: 'Switch',
						fieldName: 'autoplay',
						title: t`Reproducción automática`,
					},
					{
						type: 'Switch',
						fieldName: 'loop',
						title: t`Reproducción en bucle`,
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'ColorLayerOpacity',
						fieldName: ['containerStyle', 'colorLayerOpacity'],
					},
					{
						type: 'ThemeSelector',
						fieldName: ['containerStyle', 'themeSelected'],
					},
				],
			},
		],
	},
];
export default editionFields;
