import React, {PureComponent} from 'react';
import './styles.scss';

class Separator extends PureComponent {
	render() {
		return (
			<div className="text-bar__separator">
				<div className="separator-container">
					<div className="separator-line" />
				</div>
			</div>
		);
	}
}
export default Separator;
