import queryString from 'query-string';

export const platformHomeUrl = () => {
	return `/`;
};

export const sectionUrl = (sectionSlug) => {
	return `/${sectionSlug}/`;
};

export const pillUrl = (sectionSlug, pillSlug, pageNumber) => {
	const url = `/${sectionSlug}/${pillSlug}/`;
	if (pageNumber) {
		return `${url}${pageNumber}/`;
	}
	return url;
};

export const pillIdUrl = (pillId) => {
	return `/view/${pillId}/`;
};

export const taxonomyTypeUrl = (taxonomyTypeSlug) => {
	return `/${taxonomyTypeSlug}/`;
};

export const taxonomyUrl = (taxonomyTypeSlug, taxonomySlug) => {
	return `/${taxonomyTypeSlug}/${taxonomySlug}/`;
};

export const downloadPillFileUrl = (pillId, path, filename) => {
	return `/pillFile/${pillId}/${path}${filename}`;
};

//página not found user
export const userNotFoundUrl = () => {
	return `/notfound/`;
};

export const loginUrl = () => {
	return '/login/';
};

export const registUrl = () => {
	return '/regist/';
};

export const getPublishedPillUrl = (sectionSlug, pillSlug) => {
	if (!sectionSlug) return;
	if (!pillSlug) return window.location.origin + sectionUrl(sectionSlug);
	return window.location.origin + pillUrl(sectionSlug, pillSlug);
};

export const searchUrl = (search) => {
	const baseUrl = '/search/';

	if (search && search !== '') {
		const query = queryString.stringify({q: search});
		return `${baseUrl}?${query}`;
	}
	return baseUrl;
};

export const userUploadPillFileUrl = () => {
	return `/pillFile`;
};
