import React from 'react';
import {useSlate} from 'slate-react';
import ToggleButton from '../basics/ToggleButton';

import UndoIcon from '@mui/icons-material/Undo';

const UndoButton = () => {
	const editor = useSlate();
	const {isUndoable} = editor.helpers;

	const canUndo = isUndoable(editor);

	return (
		<ToggleButton disabled={!canUndo} onClick={() => editor.undo()} format={'undo'}>
			<UndoIcon />
		</ToggleButton>
	);
};

export default UndoButton;
