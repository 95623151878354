import {combineReducers} from 'redux'; //Función combineReducers usando tipos immutable

import editPillReducer from './EditPill/data';
// import editPillReducerSideBar from "./EditPillSideBar/data";

//Exportamos por defecto la función reducer que gestiona todas las acciones
export default combineReducers({
	editPill: editPillReducer
	// editPillSideBar: editPillReducerSideBar
});
