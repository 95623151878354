import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class SpacerCustom extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Espaciado tamaño personalizado`;
	static brickImage = brickImage;
	static brickDefaultData = {
		height: 10,
	};

	render() {
		const styles = this.props.brick.styles || {};
		const data = this.props.brick.data;

		const classes = classNames({
			'brick': true,
			'spacer-custom': true,
		});
		const percent = '%';
		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes} style={{height: data.height + 'vh'}}>
					{this.props.editMode && (
						<div className="spacer-custom__edit">
							<span className="spacer-custom__edit__text">
								{t`Espaciado ${data.height}${percent} de pantalla`}
							</span>
							<span className="spacer-custom__edit__advice">
								{t`(Texto y fondo solo visible en modo edición)`}
							</span>
						</div>
					)}
				</div>
			</Container>
		);
	}
}

export default brickWrapper(SpacerCustom);
