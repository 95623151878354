import React, {PureComponent} from 'react';
import './styles.scss';

class StylesOptionsWrapper extends PureComponent {
	render() {
		return <div className="default-styles-options">{this.props.children}</div>;
	}
}

export default StylesOptionsWrapper;
