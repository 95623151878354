import gql from 'graphql-tag';

export const VIDEOS_QUERY = gql`
	query Videos($offset: Int, $limit: Int) {
		videos(offset: $offset, limit: $limit) {
			id
			name
			path
			author
		}
	}
`;

export const VIDEO_ADD_SUBSCRIBE = gql`
	subscription AddVideoSubscription($identificator: String) {
		addVideoSubscription(identificator: $identificator) {
			id
			name
			path
			author
		}
	}
`;

export const VIDEO_DELETE_SUBSCRIBE = gql`
	subscription DeleteVideoSubscription($identificator: String) {
		deleteVideoSubscription(identificator: $identificator) {
			id
			name
			path
			author
		}
	}
`;

export const VIDEO_ADD_MUTATION = gql`
	mutation addVideo($input: MediaInput!, $identificator: String) {
		addVideo(input: $input, identificator: $identificator) {
			id
			name
			path
			author
		}
	}
`;

export const VIDEO_DELETE_MUTATION = gql`
	mutation deleteVideo($idVideo: String!, $identificator: String) {
		deleteVideo(idVideo: $idVideo, identificator: $identificator) {
			id
			deleted
		}
	}
`;
