import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import TextAreaComponent from 'businessLogic/shared/Input/TextArea';
import Instructions from 'businessLogic/core/shared/Edit/Instructions';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class TextAreaGoogleMap extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			error: false,
		};
	}
	static getDefaultValue = (editionBrickStruct) => {
		let defaultValue = '';
		if (editionBrickStruct.defaultValue) {
			defaultValue = editionBrickStruct.defaultValue;
		}

		return defaultValue;
	};

	onChange = (event) => {
		this.props.setMainField(event.target.value);
		this.handleError(event.target.value);
	};

	handleError = (value) => {
		const correctSource = 'https://www.google.com/maps/d/embed?',
			error = !value.includes(correctSource);
		if (error !== this.state.error) {
			this.setState({
				error,
			});
		}
	};

	render() {
		return (
			<div className="edition-brick-text">
				<label className="edition-brick-text__label">{this.props.editionBrickStruct.title}</label>
				{this.props.editionBrickStruct.instructions !== undefined && (
					<Instructions text={this.props.editionBrickStruct.instructions} />
				)}
				<TextAreaComponent
					name="box-title"
					type="text"
					placeholder={this.props.editionBrickStruct.placeholder}
					value={this.props.getMainField()}
					onChange={this.onChange}
					onKeyUp={this.onChange}
					rows={this.props.editionBrickStruct.rows}
					errorText={
						this.state.error
							? t`El código no es correcto. Por favor utiliza la opción "Embed this map" de google y pega aqui el código HTML facilitado`
							: ''
					}
				/>
			</div>
		);
	}
}

export default editionBrickWrapper(TextAreaGoogleMap);
