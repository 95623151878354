import ReactGA from 'react-ga';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

class GoogleAnalyticsManager {
	constructor() {
		this.initialized = false;
		this.queue = [];
		this.queueEnabled = true;
		this.userId = undefined;
		this.analyticsVersion = undefined; // Can be "UA" for Universal Analytics or "G" for Google Analytics 4
	}

	initializeUA(analyticsCode) {
		ReactGA.initialize(analyticsCode);
		this.initialized = true;
		this.queueEnabled = false;
		this.analyticsVersion = 'UA';
		if (this.userId) {
			ReactGA.set({userId: this.userId});
		}
		this.processQueue();
	}

	initializeG(analyticsCode) {
		const head = document.getElementsByTagName('head')[0];
		const js = document.createElement('script');
		js.type = 'text/javascript';
		js.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsCode}`;
		js.onload = () => {
			window.dataLayer = window.dataLayer || [];
			window.gtag = function gtag() {
				window.dataLayer.push(arguments);
			};
			window.gtag('js', new Date());

			const params = {
				send_page_view: false,
				// debug_mode: true,
			};
			if (this.userId) {
				params.user_id = this.userId;
			}
			window.gtag('config', analyticsCode, params);

			this.initialized = true;
			this.queueEnabled = false;
			this.analyticsVersion = 'G';
			this.processQueue();
		};
		head.appendChild(js);
	}

	initialize(analyticsCode) {
		// console.log('GA initialize', analyticsCode);
		this.analyticsCode = analyticsCode;
		if (analyticsCode.startsWith('UA-')) {
			this.initializeUA(analyticsCode);
			return;
		}

		if (analyticsCode.startsWith('G')) {
			this.initializeG(analyticsCode);
			return;
		}

		this.disableQueue();
	}

	disableAnalytics() {
		// console.log('GA disableAnalytics');
		this.initialized = false;
		this.queueEnabled = false;
	}

	setUserId(user) {
		// console.log('GA setUserId', user);
		if (get(user, 'admin') || get(user, 'superAdmin')) {
			this.disableAnalytics();
			// console.log('GA Admin o superAdmin');
			return;
		}

		this.userId = get(user, 'id');
		if (this.initialized) {
			if (this.analyticsVersion === 'UA') {
				ReactGA.set({userId: this.userId});
			}
			if (this.analyticsVersion === 'G') {
				window.gtag('config', this.analyticsCode, {
					user_id: this.userId,
					send_page_view: false,
					// debug_mode: true,
				});
			}
		}
	}

	async initializeFromEntity(entity) {
		// console.log('GA initializeFromEntity');
		const gaId = get(entity, 'data.gaId');

		const cookieConsentEnabled = get(entity, 'data.legals.cookieConsent.active', false);

		// Obtenemos el valor de la cookie CookieConsent
		const cookieConsent = document.cookie
			.split('; ')
			.find((row) => row.startsWith('CookieConsent'));
		const cookieConsentValue = cookieConsent ? cookieConsent.split('=')[1] === 'true' : false;

		const shouldInitialize = gaId && (!cookieConsentEnabled || cookieConsentValue);

		if (shouldInitialize) {
			this.initialize(gaId);
		} else {
			this.disableQueue();
		}
	}

	pageview(pathname) {
		// console.log(
		// 	'GA pageview',
		// 	pathname,
		// 	document.title,
		// 	location.href,
		// 	this.initialized,
		// 	location.search,
		// );

		if (location.search.includes('preview=true')) {
			this.disableAnalytics();
			return;
		}

		this.pageviewWithTitle(pathname);
	}

	//Usamos un debounce para dar tiempo a que el título cambie,
	// y evitar coger un título erroneo
	pageviewWithTitle = debounce((pathname) => {
		const pathData = {
			pathname,
			title: document.title,
			url: location.href,
		};

		if (this.initialized) {
			this.countPageview(pathData);
		} else if (this.queueEnabled) {
			this.queue.push(pathData);
		}
	}, 500);

	countPageview(pathData) {
		// console.log('GA countPageview', pathData, this.analyticsVersion);
		if (this.analyticsVersion === 'UA') {
			ReactGA.pageview(pathData.pathname);
		} else if (this.analyticsVersion === 'G') {
			window.gtag('event', 'page_view', {
				page_path: pathData.pathname,
				page_location: pathData.url,
				page_title: pathData.title,
			});
			// window.gtag('config', this.analyticsCode, {page_path: pathname});
		}
	}

	processQueue() {
		// console.log('GA processQueue');
		if (this.queue.length) {
			this.queue.forEach((element) => {
				this.countPageview(element);
			});
			this.queue = [];
		}
	}

	disableQueue() {
		// console.log('GA disableQueue');
		this.queueEnabled = false;
		this.queue = [];
	}
}

export default new GoogleAnalyticsManager();
