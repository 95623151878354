import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'TextEditor',
						fieldName: 'text',
						title: t`Texto`,
						placeholder: t`Introduce un texto`,
					},
					{
						type: 'TextEditor',
						fieldName: 'important',
						title: t`Importante`,
						placeholder: t`Introduce el texto importante`,
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'CustomColor',
						name: 'Color del aviso',
						fieldName: ['containerStyle', 'generalColor'],
					},
					{
						type: 'PaddingTop',
						fieldName: ['containerStyle', 'paddingTop'],
					},
					{
						type: 'PaddingBottom',
						fieldName: ['containerStyle', 'paddingBottom'],
					},
					{
						type: 'ThemeSelector',
						fieldName: ['containerStyle', 'themeSelected'],
					},
				],
			},
		],
	},
];
export default editionFields;
