import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Image',
						fieldName: 'image',
					},
					{
						type: 'Text',
						fieldName: 'brickIdentifier',
						title: t`Identificador del formulario de contacto`,
					},
					{
						type: 'Text',
						fieldName: 'title',
						title: t`Título`,
					},
					{
						type: 'Text',
						fieldName: 'subtitle',
						title: t`Subtítulo`,
					},
					{
						type: 'Text',
						fieldName: 'emailUser',
						title: t`Texto para el input del email del usuario`,
					},
					{
						type: 'Text',
						fieldName: 'emailAdmin',
						title: t`Email donde será enviado el formulario de contacto`,
					},
					{
						type: 'Text',
						fieldName: 'text',
						title: t`Instrucciones para que el usuario complete el campo`,
					},
					{
						type: 'Text',
						fieldName: 'button',
						title: t`Texto del botón de enviar`,
					},

					{
						type: 'TextEditor',
						fieldName: 'finalText',
						title: t`Texto para el usuario tras completar el formulario`,
						defaultValue: t`Tu consulta ha sido enviada con éxito. Gracias por contactar con nosotros`,
					},
				],
			},
		],
	},
];

export default editionFields;
