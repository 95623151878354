import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
// import detailImage from './img/detail.svg';
import {ReactComponent as DetailImage} from './img/detail.svg';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';

import './styles.scss';

class ImportantTextRight extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Destacado alineado a la derecha`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		text: 'Feri dem eos volorat. Archil iusdaecto inveria nditat.Hiligent quae re pre la doloremporro magni od quiatur a sunditate cuptas dolestrunt, cus ipiendis into el magnis soluptiunt dunt',
	};

	static brickDefaultConfig = {};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ImportantTextRight']);

		const classes = classNames({
			'brick': true,
			'important-text-right-brick': true,
		});
		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<Animation
						playAnimation={this.state.playEffect}
						delay={1}
						duration={0.5}
						effect={Array.isArray(brickAnimation) ? brickAnimation[0] : brickAnimation}
					>
						<div className="detail">
							{/* <img src={imageUrl(detailImage)} alt={detailImage} /> */}
							<DetailImage />
						</div>
					</Animation>
					<Animation
						playAnimation={this.state.playEffect}
						delay={1.5}
						effect={Array.isArray(brickAnimation) ? brickAnimation[1] : brickAnimation}
					>
						<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
					</Animation>
					<Animation
						playAnimation={this.state.playEffect}
						delay={2}
						effect={Array.isArray(brickAnimation) ? brickAnimation[2] : brickAnimation}
					>
						<div className="separator" />
					</Animation>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ImportantTextRight);
