import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';
class Tooltip extends Component {
	render() {
		const {text, theme, position, className, onClick, show} = this.props;
		const mainStyleClass = 'tooltip';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			[`${mainStyleClass + '--dark'}`]: theme === 'dark',
			[`${mainStyleClass + '--light'}`]: theme === 'light',
			[`${mainStyleClass + '--bottom'}`]: position === 'bottom',
			[`${mainStyleClass + '--top'}`]: position === 'top',
			[`${mainStyleClass + '--right'}`]: position === 'right',
			[`${mainStyleClass + '--left'}`]: position === 'left',
			[`${mainStyleClass + '--show'}`]: show,
			[`${className}`]: true
		});
		return (
			<div className={classes} onClick={onClick}>
				<div className={mainStyleClass + '__text'}>{text}</div>
			</div>
		);
	}
}

Tooltip.propTypes = {
	text: PropTypes.string,
	theme: PropTypes.oneOf(['dark', 'light']),
	position: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),
	className: PropTypes.string,
	show: PropTypes.bool,
	onClick: PropTypes.func
};
Tooltip.defaultProps = {
	className: '',
	theme: 'dark',
	show: false
};

export default Tooltip;
