import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import Icon from 'ui/shared/Icon';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import get from 'lodash/get';
import './styles.scss';

class Background extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Transición de fondo`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickHelpText = () => (
		<p>
			{t`Permite sustituir la imagen de fondo de la píldora por otra imagen de fondo creando un efecto de transición entre las dos.`}
			<br />
			<br /> {t`Este ladrillo sirve para crear Storytelling. ¿Quieres ver un ejemplo? Pincha`}
			<a href="https://story-telling.cs01.cloud/" target="_blank" rel="noreferrer noopener">
				<i>{t`aquí`}</i>
			</a>
		</p>
	);

	static brickDefaultData = {
		background: {},
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 0,
			paddingBottom: 0,
		},
	};

	constructor(props) {
		super(props);

		this.brickRef = React.createRef();
	}

	componentDidMount() {
		let newBackgroundObj = {};
		if (this.props.brick.data && this.props.brick.data.background) {
			if (this.props.brick.data.background.imageBg) {
				newBackgroundObj.imageBg = this.props.brick.data.background.imageBg;
				newBackgroundObj.imgBgOn = true;
			}
			if (this.props.brick.data.background.colorBg) {
				newBackgroundObj.colorBg = this.props.brick.data.background.colorBg;
				newBackgroundObj.bgColorOn = true;
			}
			if (this.props.brick.data.background.colorLayerOpacity) {
				newBackgroundObj.colorLayerOpacity = this.props.brick.data.background.colorLayerOpacity;
				newBackgroundObj.layerColorOn = true;
			}
			if (this.props.brick.data.background.themeSelected) {
				newBackgroundObj.themeSelected = this.props.brick.data.background.themeSelected;
			}

			this.registerHandler = this.props.configObject.registerConfig(
				newBackgroundObj,
				this.brickRef.current,
			);
		}
	}
	componentWillUnmount() {
		this.props.configObject.unregisterConfig(this.brickRef.current);
	}

	componentDidUpdate(prevProps) {
		if (this.props.brick.data && this.props.brick.data.background) {
			if (prevProps.brick.data.background !== this.props.brick.data.background) {
				this.props.configObject.updateRegisteredConfig(
					this.registerHandler,
					this.props.brick.data.background,
				);
			}
		}
	}

	render() {
		const styles = this.props.brick.styles || {};
		const dataBg = get(this.props[('brick', 'data', 'background')], {});

		const classes = classNames({
			'brick': true,
			'background-transition-brick': true,
		});

		const editClasses = classNames({
			'background-transition-brick__edit': true,
			[`theme-${get(dataBg, 'themeSelected')}`]: get(dataBg, 'themeSelected'),
		});

		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes} ref={this.brickRef} />
				{this.props.editMode && (
					<div className={editClasses}>
						<div className="background-transition-brick__edit__bg-layers">
							<div
								className="background-transition-brick__edit__bg-layers__color"
								style={{
									background: !get(dataBg, 'colorBg')
										? 'none'
										: `rgba(${dataBg.colorBg.r}, ${dataBg.colorBg.g}, ${dataBg.colorBg.b}, ${dataBg.colorBg.a})`,
								}}
							/>
							<div
								className="background-transition-brick__edit__bg-layers__img"
								style={{backgroundImage: `url('${get(dataBg, 'imageBg')}')`}}
							/>
							<div
								className="background-transition-brick__edit__bg-layers__layer-opacity"
								style={{
									background: !get(dataBg, 'colorLayerOpacity')
										? 'none'
										: `rgba(${dataBg.colorLayerOpacity.r}, ${dataBg.colorLayerOpacity.g}, ${dataBg.colorLayerOpacity.b}, ${dataBg.colorLayerOpacity.a})`,
								}}
							/>
						</div>
						<Icon
							name="bg-transition"
							size="large"
							className="background-transition-brick__edit__icon"
						/>
						<span className="background-transition-brick__edit__text">
							{t`Ladrillo Transición de fondo`}
						</span>
						<span className="background-transition-brick__edit__advice">
							{t`(Texto y fondo solo visible en modo edición)`}
						</span>
					</div>
				)}
			</Container>
		);
	}
}

export default brickWrapper(Background);
