import React from 'react';
import MailOutline from '@mui/icons-material/MailOutline';
import TrendingUpOutlined from '@mui/icons-material/TrendingUpOutlined';
import Error from '@mui/icons-material/Error';
import EmojiEventsOutlined from '@mui/icons-material/EmojiEventsOutlined';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import TextSnippetOutlined from '@mui/icons-material/TextSnippetOutlined';
import Person from '@mui/icons-material/Person';
import Group from '@mui/icons-material/Group';

const icons = {
	MailOutline: MailOutline,
	TrendingUpOutlined: TrendingUpOutlined,
	EmojiEventsOutlined: EmojiEventsOutlined,
	HomeOutlined: HomeOutlined,
	TextSnippetOutlined: TextSnippetOutlined,
	Person: Person,
	Group: Group,
	error: Error,
};

const MaterialUiIcon = (props) => {
	const Icon = icons[props.name];
	if (Icon) {
		return <Icon {...props} />;
	}
	return null;
};

export default MaterialUiIcon;
