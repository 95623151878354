import React, {Component} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import ImageSelectorDialog from 'businessLogic/shared/MediaSelector/ImageSelectorDialog';
import LabelForInput from 'businessLogic/shared/Input/LabelForInput';
import Input from 'ui/shared/FormFields/Input';
import EditImageBox from 'businessLogic/shared/Image/EditImageBox';
import Icon from 'ui/shared/Icon';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import pdfImageUrl from 'ui/shared/images/pdf-image.svg';
import './styles.scss';

//en realidad solo debe permitir subida de archivos pdf
class File extends Component {
	static getDefaultValue = (editionBrickStruct) => {
		const defaultValue = {
			src: editionBrickStruct.defaultFile || '',
			fileName: editionBrickStruct.defaultFileName || '',
		};

		return defaultValue;
	};
	handleDelete = () => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'src'], '');
	};

	handleReplace = () => {
		this.refs.imageSelectorDialog.show();
	};

	handleNewfile = (src) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'src'], src);
	};

	handleChangeFileName = (event) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'fileName'], event.target.value);
	};

	render() {
		const editionBrickStruct = this.props.editionBrickStruct;

		let src = this.props.get([editionBrickStruct.fieldName, 'src']);
		let fileName = this.props.get([editionBrickStruct.fieldName, 'fileName']);

		//Parche para los ladrillos ya creados anteriormente
		if (src === undefined) src = this.props.get('src');
		if (fileName === undefined) fileName = this.props.get('fileName');
		const pathObject = this.props.editionBrickData.data.file.src.split('/');
		//obtener tamaño de objeto devuelto por split
		Object.size = function (obj) {
			var size = 0,
				key;
			for (key in obj) {
				if (obj.hasOwnProperty(key)) size++;
			}
			return size;
		};
		let size = Object.size(pathObject);

		//el nombre del fichero siempre será la última posición del objeto devuelto por split
		let newFileName = pathObject[--size];

		let currentFileName = newFileName;
		if (newFileName === undefined) currentFileName = src;

		let srcImageBox;
		let classFile;
		if (currentFileName.search('.pdf') > -1) {
			srcImageBox = pdfImageUrl;
			classFile = 'pdf';
		} else {
			srcImageBox = src;
			classFile = 'img';
		}
		return (
			<div className="edition-brick-file">
				<div className="top-info">
					<div className="file__instructions">
						<span>Sube un archivo en formato PDF pulsando en el icono</span>
						<Icon name="image-search" size="small" />
					</div>
					<div className={'file ' + classFile}>
						<EditImageBox
							srcImage={srcImageBox}
							onDelete={this.handleDelete}
							onReplace={this.handleReplace}
							handleChangeCenterPosition={this.handleChangeCenterPosition}
							small={false}
							editable={false} //no es editable ya que tiene que ser un pdf no una imagen
						/>
						<p className="file__file-name">{currentFileName}</p>
					</div>
					<div className="file-info">
						<LabelForInput text="Nombre de archivo personalizado:" />
						<Input
							name="file-name"
							type="text"
							placeholder=" Agrega un nombre de archivo personalizado"
							value={fileName}
							onChange={this.handleChangeFileName}
						/>
					</div>
				</div>
				<ImageSelectorDialog
					ref="imageSelectorDialog"
					onSelect={this.handleNewfile}
					dialogName={t`Fichero PDF`}
					fileType="pdf"
				/>
			</div>
		);
	}
}

export default editionBrickWrapper(File);
