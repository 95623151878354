import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {t} from 'businessLogic/scope/user/helper/userTtag';
import entityManager from 'businessLogic/services/EntityManager';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
	'& .MuiDialog-paper': {
		padding: theme.spacing(1),
		backgroundColor: '#111d37',
		color: '#ffffff',
		textAlign: 'center',
		borderRadius: 15,
	},
	// '& .MuiDialogContent-root': {
	// 	padding: theme.spacing(3),
	// 	backgroundColor: '#111d37',
	// 	color: '#ffffff',
	// 	textAlign: 'center',
	// },
	// '& .MuiDialogActions-root': {
	// 	padding: theme.spacing(1),
	// },
	'& .MuiBackdrop-root': {
		backgroundColor: 'rgba(255, 255, 255, 0.7)',
	},
}));

const BootstrapDialogTitle = (props) => {
	const {children, onClose, ...other} = props;

	return (
		<DialogTitle sx={{m: 0, p: 2}} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({open, onClose, onLogin, onRegister}) {
	const interactionColor = getComputedStyle(document.documentElement).getPropertyValue(
		'--interactionColor',
	);
	return (
		<div>
			<BootstrapDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
				<DialogContent sx={{}}>
					<Typography
						sx={{
							fontSize: 22,
							fontWeight: 500,
							marginBottom: 3,
							letterSpacing: 1.2,
							lineHeight: 1.4,
						}}
					>
						{t`No podemos registrar tu progreso si no te identificas`}
					</Typography>
					<Typography
						sx={{
							fontSize: 18,
							fontWeight: 400,
							marginBottom: 3,
							letterSpacing: 1.2,
							lineHeight: 1.4,
						}}
					>
						{t`Para ponerte a prueba y contabilizar tus puntos necesitamos que inicies sesión.`}
					</Typography>
					<Box sx={{margin: '35px 0px'}}>
						<Button
							onClick={onLogin}
							sx={{
								'backgroundColor': interactionColor,
								'fontSize': 18,
								'fontWeight': 400,
								'letterSpacing': 1.2,
								'color': '#ffffff',
								'textTransform': 'uppercase',
								'borderRadius': 50,
								'padding': '3px 30px 1px',
								'&:hover': {
									backgroundColor: interactionColor,
								},
							}}
						>{t`Iniciar sesión`}</Button>
					</Box>
					{entityManager.isRegistrationEnabled() && (
						<Box
							sx={{
								fontSize: 16,
								fontWeight: 600,
							}}
						>
							{t`Aún no tengo cuenta,`}{' '}
							<Box
								onClick={onRegister}
								sx={{color: interactionColor, display: 'inline-block', cursor: 'pointer'}}
							>{t`¡regístrame!`}</Box>
						</Box>
					)}
				</DialogContent>
			</BootstrapDialog>
		</div>
	);
}
