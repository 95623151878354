import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import ConfirmPopUp from './components/ConfirmPopUp';

import {CSSTransition} from 'react-transition-group';
import './styles.scss';

const modalRoot = document.getElementById('portals');

class ModalForTestBricks extends Component {
	constructor(props) {
		super(props);
		this.containerRef = React.createRef();
		this.state = {
			showConfirm: false,
		};
		this.containerModalForTestsBricks = document.createElement('div');
	}

	handleCloseModal = () => {
		this.props.onClose();
	};

	showConfirmPopUp = () => {
		if (!this.props.exitMessage) {
			this.props.onClose();
			return;
		}
		this.setState({showConfirm: true});
	};

	confirmSelectedOption = (option) => {
		if (option) {
			this.props.onClose();
		} else {
			this.setState({showConfirm: false});
		}
	};

	componentWillUnmount() {
		if (modalRoot && this.isDivMounted && modalRoot.contains(this.containerModalForTestsBricks)) {
			modalRoot.removeChild(this.containerModalForTestsBricks);
		}
	}

	mountDiv = () => {
		// si existe modalRoot y no
		if (modalRoot && !this.isDivMounted) {
			modalRoot.appendChild(this.containerModalForTestsBricks);
			this.isDivMounted = true;
		}
	};

	render() {
		const mainStyleClass = 'modal-for-tests-bricks';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			[`${mainStyleClass + '--edit-mode'}`]: this.props.isEditMode,
		});

		this.mountDiv();
		const content = (
			<div className={classes} ref={this.containerRef}>
				<div className={mainStyleClass + '__inner'}>
					{this.props.canClose && (
						<div className={mainStyleClass + '__close'} onClick={this.showConfirmPopUp}>
							<Icon name="close-modal" size="normal" />
						</div>
					)}
					<div className={mainStyleClass + '__content'}>{this.props.children}</div>
				</div>
				<div className={mainStyleClass + '__bg-overlay'} />

				<ConfirmPopUp
					text={this.props.exitMessage}
					show={this.state.showConfirm}
					confirm={this.confirmSelectedOption}
				/>
			</div>
		);

		//En modo edición comportamiento de la modal queda deshabilitado para facilitar la edición desde el sidebar de
		if (!this.props.isEditMode) {
			return ReactDOM.createPortal(
				<CSSTransition in={true} timeout={5000} classNames="show">
					{content}
				</CSSTransition>,

				this.containerModalForTestsBricks,
			);
		}
		return content;
	}
}

ModalForTestBricks.propTypes = {
	backLinkText: PropTypes.string,
	dataEntity: PropTypes.object,
	isEditMode: PropTypes.bool,
	exitMessage: PropTypes.string,
	canClose: PropTypes.bool,
};

ModalForTestBricks.defaultProps = {
	backLinkText: 'Volver atrás',
	isEditMode: false,
	canClose: true,
};

export default ModalForTestBricks;
