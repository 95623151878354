import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {imageUrl} from 'businessLogic/helpers/images';
import debounce from 'lodash/debounce';
import {checkWindowWidth} from 'businessLogic/helpers/functions';
import Icon from 'ui/shared/Icon';
import './styles.scss';

const modalRoot = document.getElementById('portals');

class ViewText extends PureComponent {
	mountDiv = () => {
		if (modalRoot && !this.isDivMounted) {
			modalRoot.appendChild(this.wikiContainerPortal);
			this.isDivMounted = true;
		}
	};

	constructor(props) {
		super(props);
		this.state = {
			wikiData: {
				show: false,
				text: '',
				img: '',
				imgCredits: '',
				visibleText: '',
				top: 0,
				left: 0,
				width: 0,
				height: 0,
			},
		};
		this.wikiContainerPortal = document.createElement('div');
		this.isDivMounted = false;

		this.viewTextContainerRef = React.createRef();
		this.wikiContainerRef = React.createRef();

		// Debounce
		this.wikiHide = debounce(this.wikiHide, 250);
		this.wikiShow = debounce(this.wikiShow, 250);
	}
	wikiOverFunction = () => {
		if (this.viewTextContainerRef.current !== undefined) {
			let myWikiElements = this.viewTextContainerRef.current.querySelectorAll('.my-wiki');
			let wikiContainer = this.wikiContainerRef.current;
			if (myWikiElements.length > 0) {
				for (let i = 0; i < myWikiElements.length; i++) {
					myWikiElements[i].addEventListener('mouseenter', this.onEnterWordEvent);
					myWikiElements[i].addEventListener('mouseleave', this.onOutEvent);

					myWikiElements[i].addEventListener('touchstart', this.onEnterWordEvent);
					myWikiElements[i].addEventListener('touchmove', this.onOutEvent);
				}
				wikiContainer.addEventListener('mouseenter', this.onEnterDialogEvent);
				wikiContainer.addEventListener('mouseleave', this.onOutEvent);
				wikiContainer.addEventListener('touchstart', this.onEnterDialogEvent);
				wikiContainer.addEventListener('touchmove', this.onOutEvent);
			} else {
				try {
					if (modalRoot && modalRoot.contains(this.wikiContainerPortal)) {
						modalRoot.removeChild(this.wikiContainerPortal);
					}
				} catch (e) {}
				this.isDivUnmounted = true;
			}
			if (
				checkWindowWidth() !== 'desktop' &&
				!!navigator.platform &&
				/iPad|iPhone|iPod/.test(navigator.platform)
			) {
				if (this.state.enterWord) {
					document.addEventListener('click', this.onOutEvent);
				}
			}
		}
	};

	onEnterWordEvent = (event) => {
		this.wikiHide.cancel();
		this.wikiShow(event);
	};

	onEnterDialogEvent = () => {
		this.wikiHide.cancel();
	};

	onOutEvent = () => {
		this.wikiShow.cancel();
		this.wikiHide();
	};

	addScrollEvent = () => {
		const scrollContainer = document.getElementById('scroll-container');
		if (scrollContainer) {
			scrollContainer.addEventListener('scroll', this.wikiHide);
		}
	};

	removeScrollEvent = () => {
		const scrollContainer = document.getElementById('scroll-container');
		if (scrollContainer) {
			scrollContainer.removeEventListener('scroll', this.wikiHide);
		}
	};

	wikiShow = (event) => {
		this.setState(() => {
			// if (!state.wikiData.show) {
			const wordPosition = event.target.getBoundingClientRect();
			this.addScrollEvent();
			return {
				wikiData: {
					text: event.target.getAttribute('data-text'),
					img: event.target.getAttribute('data-image'),
					imgCredits: event.target.getAttribute('data-img-credits'),
					visibleText: event.target.innerHTML,
					top: wordPosition.top,
					left: wordPosition.left,
					width: Math.round(wordPosition.width),
					height: Math.round(wordPosition.height),
					show: true,
				},
			};
			// }
		});
	};

	wikiHide = () => {
		this.setState((state) => ({wikiData: {...state.wikiData, show: false}}));
		this.timeoutWikiHide = setTimeout(() => {
			this.setState({
				wikiData: {
					text: '',
					img: '',
					imgCredits: '',
					visibleText: '',
					top: 0,
					left: 0,
					width: 0,
					height: 0,
					show: false,
				},
			});
		}, 20);
		this.removeScrollEvent();
	};

	componentWillUnmount() {
		this.wikiHide.cancel();
		this.wikiShow.cancel();
		if (modalRoot && this.isDivMounted && !this.isDivUnmounted) {
			try {
				if (modalRoot && modalRoot.contains(this.wikiContainerPortal)) {
					modalRoot.removeChild(this.wikiContainerPortal);
				}
			} catch (e) {}
		}
	}
	render() {
		const {text} = this.props;
		this.mountDiv();

		let wikiStyles = {};
		let windowHeight = window.innerHeight;
		let windowWidth = window.innerWidth;

		const wikiData = this.state.wikiData;

		if (wikiData.top < windowHeight / 2) {
			if (wikiData.left < (windowWidth / 7) * 2) {
				wikiStyles = {
					top: wikiData.top + wikiData.height,
					left: wikiData.left,
				};
			} else if (wikiData.left + wikiData.width > (windowWidth / 6) * 4.5) {
				wikiStyles = {
					top: wikiData.top + wikiData.height,
					left: wikiData.left + wikiData.width - 250,
				};
			} else {
				wikiStyles = {
					top: wikiData.top + wikiData.height,
					left: wikiData.left - 10 - 250 / 2 + wikiData.width / 2,
				};
			}
		}
		if (wikiData.top > windowHeight / 2) {
			if (wikiData.left < (windowWidth / 7) * 2) {
				wikiStyles = {
					bottom: windowHeight - wikiData.top,
					left: wikiData.left,
				};
			} else if (wikiData.left + wikiData.width > (windowWidth / 6) * 4.5) {
				wikiStyles = {
					bottom: windowHeight - wikiData.top,
					left: wikiData.left + wikiData.width - 250,
				};
			} else {
				wikiStyles = {
					bottom: windowHeight - wikiData.top,
					left: wikiData.left - 10 - 250 / 2 + wikiData.width / 2,
				};
			}
		}

		const wikiElement = ReactDOM.createPortal(
			<div
				className={`wiki-container ${wikiData.show ? 'wiki-show' : 'wiki-hide'}`}
				ref={this.wikiContainerRef}
				style={wikiStyles}
			>
				{wikiData.show && (
					<React.Fragment>
						{checkWindowWidth() !== 'desktop' && (
							<div className="wiki-container__close" onClick={this.wikiHide}>
								<Icon name="close-modal" size="small" />
							</div>
						)}
						{wikiData.img && (
							<div className="wiki-img">
								<img src={imageUrl(wikiData.img)} alt={wikiData.imgCredits} />
								{wikiData.imgCredits && (
									<span className="wiki-img__credits">{wikiData.imgCredits}</span>
								)}
							</div>
						)}
						{wikiData.text && (
							<div className="wiki-text" dangerouslySetInnerHTML={{__html: wikiData.text}} />
						)}
					</React.Fragment>
				)}
			</div>,
			this.wikiContainerPortal,
		);
		return (
			<React.Fragment>
				<div
					className="ql-editor"
					ref={this.viewTextContainerRef}
					dangerouslySetInnerHTML={{__html: text}}
				/>
				{wikiElement}
			</React.Fragment>
		);
	}

	componentDidMount() {
		this.wikiOverFunction();
	}
	UNSAFE_componentWillUpdate() {
		this.wikiOverFunction();
	}
}

ViewText.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ViewText.defaultProps = {
	text: {
		textValue: '<p></p>',
		deltaValue: {
			ops: [
				{
					insert: '',
				},
			],
		},
	},
};

export default ViewText;
