import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {imageUrl} from 'businessLogic/helpers/images';
import ImageMapper from 'react-image-mapper';

import DialogMui from 'businessLogic/shared/Modals/DialogMui';

// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';

const NewAlertModal = ({
	show,
	srcImage,
	onHide,
	valueCenterPosition,
	handleChangeCenterPosition,
	index,
}) => {
	const imageRef = React.createRef();
	return (
		<DialogMui onClose={onHide} open={show} title={t`Editar imagen`}>
			<DialogContent>
				<Box sx={{minHeight: 300, padding: 1}}>
					<Box>
						<p>{t`Haz click en la imagen para seleccionar el punto de centrado. Esto te servirá para poder centrar la parte con mayor importancia de la imagen.`}</p>
						<p>{t`Si quieres eliminar el punto pulsa sobre él de nuevo.`}</p>
						<p>{t`Nota: Si no marcas ningún punto, la imagen se alineará al centro.`}</p>

						<Box
							sx={{
								'width': 500,
								'position': 'relative',
								'& img': {maxWidth: '100%'},
								'textAlign': 'center',
								'margin': 'auto',
							}}
						>
							<ImageMapper
								src={imageUrl(srcImage)}
								width={500}
								onImageClick={(capturedPosition) => {
									const positionX = capturedPosition.nativeEvent.layerX;
									const positionY = capturedPosition.nativeEvent.layerY;
									const imgClientWidth = imageRef.current.img.clientWidth;
									const imgClientHeight = imageRef.current.img.clientHeight;
									const percentagePositionX = ((positionX - 12) * 100) / imgClientWidth;
									const percentagePositionY = ((positionY - 12) * 100) / imgClientHeight;

									handleChangeCenterPosition(
										{x: percentagePositionX, y: percentagePositionY},
										srcImage,
										index,
									);
								}}
								ref={imageRef}
							/>
							{valueCenterPosition && valueCenterPosition.y > 0 && valueCenterPosition.x > 0 && (
								<Box
									sx={{
										position: 'absolute',
										background: '#fff',
										width: 24,
										height: 24,
										zIndex: 30,
										border: '6px solid #33ccff',
										boxShadow: '1px 1px 15px -4px rgb(0 0 0 / 40%)',
										borderRadius: '50%',
										cursor: 'pointer',
									}}
									style={{top: valueCenterPosition.y + '%', left: valueCenterPosition.x + '%'}}
									onClick={() => handleChangeCenterPosition({x: 0, y: 0}, srcImage, index)}
								/>
							)}
						</Box>
					</Box>
				</Box>
			</DialogContent>
		</DialogMui>
	);
};

NewAlertModal.propTypes = {
	show: PropTypes.bool,
	onClose: PropTypes.func,
	group: PropTypes.object,
	callRefetchData: PropTypes.func,
};

NewAlertModal.defaultProps = {
	show: true,
};

export default NewAlertModal;
