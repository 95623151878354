import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

class YouTubeForIE11 extends PureComponent {
	render() {
		return (
			<object
				width={this.props.dimensions.width}
				height={this.props.dimensions.height}
				data={'https://www.youtube.com/embed/' + this.props.youtubeID}
				aria-label="YouTube Video"
			/>
		);
	}
}

YouTubeForIE11.propTypes = {
	youtubeID: PropTypes.string
};

export default YouTubeForIE11;
