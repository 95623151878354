import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import SelectableTagLabel from 'businessLogic/core/shared/Label/SelectableTagLabel';
import get from 'lodash/get';
import {slugify} from '@createandshare/tools';
import './styles.scss';

class SelectedChoiceQuestion extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			responses: [],
			feedback: '',
		};
	}
	canContinue = () => {
		if (!get(this.props, 'flexibleContent.required', false) || this.state.responses.length > 0) {
			return true;
		}
		return false;
	};
	handleOnchange = (option) => {
		this.setState((state) => {
			const responses = state.responses.slice();
			//Comprobar si el elemento ya existe
			const indexResponse = responses.indexOf(option._id);
			const max = parseInt(get(this.props.flexibleContent, ['maxOptionsResponses'], ''), 10);

			// Existe un limite?
			const isMaxlimit = !Number.isNaN(max) || max !== 0;
			// He llegado al limite?
			const isOverLimit = isMaxlimit && responses.length === max;
			let feedback;

			if (indexResponse < 0) {
				if (!isOverLimit) {
					responses.push(option._id);
				} else {
					feedback = 'Recuerda que solo puedes contestar como máximo ' + max;
				}
			} else {
				//si existe, eleminarlo
				responses.splice(indexResponse, 1);
			}
			return {
				responses,
				feedback,
			};
		}, this.sendDataQuestion);
	};

	sendDataQuestion = () => {
		const dataState = {};
		const dataResponses = this.state.responses.slice();
		const keyData = this.props.flexibleContent._id;
		const type = this.props.flexibleContent.flexibleContentType;
		const name = slugify(this.props.flexibleContent.name);

		//Si el estado no está vacío
		const parseDataResponses = this.parseData(
			dataResponses,
			this.props.flexibleContent.options,
			'_i',
			'optionTag',
		);

		dataState[keyData] = {
			value: parseDataResponses,
			valueIds: dataResponses,
			name,
			type,
		};
		this.props.onFinishQuestion(dataState);
	};

	//Traducir el array de ids de respuestas dadas por el usuario, a sus respectivos labels
	parseData = (arrayA, arrayB) => {
		const result = arrayA.map((elementA) => {
			const indexResult = arrayB.findIndex((elementB) => elementB._id === elementA);
			return arrayB[indexResult].optionTag;
		});
		return result;
	};

	render() {
		const mainStyleClass = 'selected-choice-question';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
		});
		const {
			flexibleContent: {options, statement, max},
		} = this.props;

		return (
			<div className={classes}>
				<div className="row">
					<div className="col-xs-12 col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
						<div className={mainStyleClass + '__statement swiper-no-swiping'}>
							<FrontEditableText
								text={statement}
								fieldName="statement"
								set={(fieldPath, fieldData) => {
									//Propagamos la llamada al método set añadiendo mi nodo al path
									if (this.props.set)
										this.props.set(
											['flexibleContent', this.props.index].concat(fieldPath),
											fieldData,
										);
								}}
							/>
							<div className={mainStyleClass + '__feedback swiper-no-swiping'}>
								{this.state.feedback}
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-xs-12 col-xl-10 col-xl-offset-1">
						<fieldset className="swiper-no-swiping">
							{options.map((option, indexOption) => {
								const indexResponse = this.state.responses.indexOf(option._id);
								const optionId = get(option, ['_id'], '');
								return (
									<SelectableTagLabel
										key={indexOption}
										name={optionId.toString()}
										checked={indexResponse < 0 ? false : true}
										text={option.optionTag}
										type={max && max === 1 ? 'radio' : 'checkbox'}
										order={
											indexResponse < 0 || this.props.subtype === 'randomOrder'
												? undefined
												: indexResponse + 1
										}
										onChange={() => {
											this.handleOnchange(option);
										}}
										disable={this.state.feedback ? true : false}
									/>
								);
							})}
						</fieldset>
					</div>
				</div>
			</div>
		);
	}
}

SelectedChoiceQuestion.propTypes = {
	subtype: PropTypes.oneOf(['preferenceOrder', 'randomOrder']),
	flexibleContent: PropTypes.object,
	index: PropTypes.number,
	onFinishQuestion: PropTypes.func,
	set: PropTypes.func,
};
SelectedChoiceQuestion.defaultProps = {
	subtype: 'randomOrder',
};
export default SelectedChoiceQuestion;
