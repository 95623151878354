import {Editor, Transforms, Element as SlateElement, Range} from 'slate';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

// Marks
export const isMarkActive = (editor, format) => {
	const marks = Editor.marks(editor);
	return marks ? marks[format] === true : false;
};

export const getMark = (editor, format) => {
	const marks = Editor.marks(editor);
	return marks ? marks[format] : null;
};

export const toggleMark = (editor, format) => {
	const isActive = isMarkActive(editor, format);

	if (isActive) {
		Editor.removeMark(editor, format);
	} else {
		Editor.addMark(editor, format, true);
	}
};

export const setMark = (editor, format, value) => {
	Editor.addMark(editor, format, value);
};

export const removeMark = (editor, format) => {
	Editor.removeMark(editor, format);
};

// Blocks
export const isBlockActive = (editor, format, blockType = 'type') => {
	const {selection} = editor;
	if (!selection) return false;

	const [match] = Array.from(
		Editor.nodes(editor, {
			at: Editor.unhangRange(editor, selection),
			match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
		}),
	);

	return !!match;
};

export const getBlockFormat = (editor, blockType) => {
	const {selection} = editor;
	if (!selection) return false;

	const [match] = Array.from(
		Editor.nodes(editor, {
			at: Editor.unhangRange(editor, selection),
			match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.hasOwnProperty(blockType),
		}),
	);

	if (!match) return false;

	const [node] = match;

	return node[blockType];
};

export const toggleBlock = (editor, format) => {
	const isActive = isBlockActive(editor, format, isAlignFormat(format) ? 'align' : 'type');
	const isList = isListFormat(format);

	Transforms.unwrapNodes(editor, {
		match: (n) =>
			!Editor.isEditor(n) &&
			SlateElement.isElement(n) &&
			isListFormat(n.type) &&
			!isAlignFormat(format),
		split: true,
	});
	let newProperties;
	if (isAlignFormat(format)) {
		newProperties = {
			align: isActive ? undefined : format,
		};
	} else {
		newProperties = {
			type: isActive ? 'paragraph' : isList ? 'list-item' : format,
		};
	}
	Transforms.setNodes(editor, newProperties);

	if (!isActive && isList) {
		const block = {type: format, children: []};
		Transforms.wrapNodes(editor, block);
	}
};

export const setBlock = (editor, blockType, value) => {
	Transforms.setNodes(editor, {[blockType]: value});
};

// Links
export const isLinkActive = (editor) => {
	const [link] = Editor.nodes(editor, {match: (n) => n.type === 'link'});
	return !!link;
};

export const getLink = (editor) => {
	const [link] = Editor.nodes(editor, {match: (n) => n.type === 'link'});

	if (link) {
		return link;
	}

	return [];
};

export const wrapLink = (editor, url) => {
	if (isLinkActive(editor)) {
		unwrapLink(editor);
	}

	const {selection} = editor;
	const isCollapsed = selection && Range.isCollapsed(selection);
	const link = {
		type: 'link',
		url,
		children: isCollapsed ? [{text: url}] : [],
	};

	if (isCollapsed) {
		Transforms.insertNodes(editor, link);
	} else {
		Transforms.wrapNodes(editor, link, {split: true});
		Transforms.collapse(editor, {edge: 'end'});
	}
};

export const unwrapLink = (editor) => {
	Transforms.unwrapNodes(editor, {match: (n) => n.type === 'link'});
};

export const editLink = (editor, url) => {
	if (isLinkActive(editor)) {
		const [link, path] = getLink(editor);

		if (link && path) {
			const newProperties = {
				url,
			};

			Transforms.setNodes(editor, newProperties, {at: path});
		}
	}
};

// Wikis
export const isWikiActive = (editor) => {
	const [wiki] = Editor.nodes(editor, {match: (n) => n.type === 'wiki'});
	return !!wiki;
};

export const getWiki = (editor) => {
	const [wiki] = Editor.nodes(editor, {match: (n) => n.type === 'wiki'});

	if (wiki) {
		return wiki;
	}

	return [];
};

export const wrapWiki = (editor, wikiData) => {
	if (isWikiActive(editor)) {
		unwrapWiki(editor);
	}

	const {selection} = editor;

	const isCollapsed = selection && Range.isCollapsed(selection);
	const wikiNode = {
		type: 'wiki',
		wikiData,
		children: isCollapsed ? [{text: 'wiki'}] : [],
	};

	if (isCollapsed) {
		Transforms.insertNodes(editor, wikiNode);
	} else {
		Transforms.wrapNodes(editor, wikiNode, {split: true});
		Transforms.collapse(editor, {edge: 'end'});
	}
};

export const unwrapWiki = (editor) => {
	Transforms.unwrapNodes(editor, {match: (n) => n.type === 'wiki'});
};

export const editWiki = (editor, wikiData) => {
	if (isWikiActive(editor)) {
		const [wikiNode, path] = getWiki(editor);

		if (wikiNode && path) {
			const newProperties = {
				wikiData,
			};

			Transforms.setNodes(editor, newProperties, {at: path});
		}
	}
};

// Replace selection content
export const replaceSelectionContent = (editor, content) => {
	Transforms.delete(editor);
	Transforms.insertNodes(editor, [{text: content}]);
};

export const getSelectionContent = (editor) => {
	const {selection} = editor;
	if (!selection) return '';

	const text = Editor.string(editor, selection);
	return text;
};

// Formats
export const isAlignFormat = (format) => {
	return TEXT_ALIGN_TYPES.includes(format);
};

export const isListFormat = (format) => {
	return LIST_TYPES.includes(format);
};

// History
export const isUndoable = (editor) => {
	const {history} = editor;
	if (!history) return false;

	return history.undos.length > 0;
};

export const isRedoable = (editor) => {
	const {history} = editor;
	if (!history) return false;

	return history.redos.length > 0;
};

export default {
	// Marks
	isMarkActive,
	getMark,
	toggleMark,
	setMark,
	removeMark,

	// Blocks
	isBlockActive,
	toggleBlock,
	getBlockFormat,
	setBlock,

	// Links
	isLinkActive,
	getLink,
	editLink,
	wrapLink,
	unwrapLink,

	// Wikis
	isWikiActive,
	getWiki,
	editWiki,
	wrapWiki,
	unwrapWiki,

	// Formats
	isAlignFormat,
	isListFormat,

	// History
	isUndoable,
	isRedoable,

	// AI
	replaceSelectionContent,
	getSelectionContent,
};
