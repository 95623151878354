import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import './styles.scss';

const Loading = (props) => {
	const {className, size} = props;

	const classes = classNames({
		loading: true,
		[`${className}`]: true
	});

	const style = {
		container: {
			position: 'relative'
		},
		refresh: {
			display: 'inline-block',
			position: 'relative'
		}
	};

	return (
		<div className={classes}>
			<CircularProgress
				status="loading"
				size={size || 40}
				top={0}
				left={0}
				style={style.refresh}
				// loadingColor="#0037fb"
			/>
		</div>
	);
};

Loading.propTypes = {
	className: PropTypes.string,
	size: PropTypes.number
};

Loading.defaultProps = {
	className: ''
};
export default Loading;
