import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

class TextArea extends PureComponent {
	focus() {
		this.refs.input.focus();
	}
	blur() {
		this.refs.input.blur();
	}
	render() {
		const {
			name,
			value,
			onChange,
			onBlur,
			onKeyUp,
			onKeyDown,
			className,
			placeholder,
			rows,
			id,
			errorText,
			theme,
			error
		} = this.props;
		const classes = classNames({
			'text-area': true,
			[`${className}`]: true,
			[`${theme}`]: true,
			error: errorText !== ''
		});

		return (
			<div className={classes}>
				<textarea
					{...{name, value, onChange, onKeyDown,onBlur, onKeyUp, placeholder, rows, id, theme, error}}
					ref="textarea"
				/>
				{errorText !== '' && <span className="textarea__error">{errorText}</span>}
			</div>
		);
	}
}

const {string, func} = PropTypes;

TextArea.propTypes = {
	name: string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	type: string.isRequired,
	onChange: func.isRequired,
	onBlur: func,
	onKeyUp: func,
	onKeyDown:func,
	className: string,
	placeholder: string,
	theme: PropTypes.oneOf(['generic', 'platform-textarea','brick-survey']),
	errorText: string
};

TextArea.defaultProps = {
	name: '',
	type: 'text',
	className: '',
	placeholder: '',
	theme: 'generic',
	errorText: ''
};

export default TextArea;
