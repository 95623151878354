import React, {PureComponent} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Question from './components/Question';
import NavigationTestBrick from 'businessLogic/core/shared/Test/NavigationTestBrick';
import BeforeHeadingTestBrick from 'businessLogic/core/shared/Test/BeforeHeadingTestBrick';
import ScrollBarTestBrick from 'businessLogic/core/shared/Test/ScrollBarTestBrick';
import TimerTestBrick from 'businessLogic/core/shared/Test/TimerTestBrick';
import Swiper from 'react-id-swiper';
import './styles.scss';

class Test extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			isTyping: false,
		};
		this.responses = {};
		this.swiper = null;
		this.testRef = React.createRef();
	}

	goNext = () => {
		if (this.swiper) this.swiper.slideNext();
	};

	goPrev = () => {
		if (this.swiper) this.swiper.slidePrev();
	};

	//valor en porcentaje del progreso
	getProgress = () => {
		if (this.swiper) {
			const totalQuestion = this.props.collectionContent.length;
			const currentQuestion = this.state.activeIndex + 1;
			const progressValue = (currentQuestion / totalQuestion) * 100;
			return parseInt(progressValue, 10);
		}
	};
	changeActiveSlideContent = () => {
		if (this.swiper) {
			this.setState({
				activeIndex: this.swiper.activeIndex,
			});
		}
	};

	isFinish = () => {
		if (this.props.collectionContent.length === this.state.activeIndex + 1) return true;
	};
	handleFinishTest = () => {
		this.props.onFinishTest(this.responses);
	};

	render() {
		/**todas las preguntas */
		const mainStyleClass = 'test-exam-test';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			[`${mainStyleClass + '--typing'}`]: this.state.isTyping,
		});
		//Parámetros configuración swiper
		//https://www.npmjs.com/package/react-id-swiper
		const params = {
			shouldSwiperUpdate: true, //si no no se vuelve a renderizar al añadir imágenes al carrusel
			rebuildOnUpdate: false, //se recontruye si se cambian parámetros (valueDelay) o se añaden elementos al carrusel
			direction: 'vertical',
			initialSlide: 0,
			spaceBetween: 0,
			speed: 1500,
			slidesPerView: 1,
			loop: false,
			noSwiping: true,
			keyboard: true,

			coverflowEffect: {
				rotate: 50,
				stretch: 0,
				depth: 100,
				modifier: 1,
				slideShadows: true,
			},
			navigation: {
				nextEl: mainStyleClass + '__trigger--arrow__next',
				prevEl: mainStyleClass + '__trigger--arrow__prev',
			},
			getSwiper: (swiper) => {
				this.swiper = swiper;

				this.changeActiveSlideContent();
				this.swiper.on('slideChange', () => {
					this.changeActiveSlideContent();
				});
			},
		};

		return (
			<div className={classes} ref={this.testRef}>
				<header className={mainStyleClass + '__header'}>
					<BeforeHeadingTestBrick text={this.props.beforeHeading} set={this.props.set} />
					{/* La cuenta atras aparece si esta definida , si es mayor que 0 y si el test no esta disable */}
					{!!this.props.maxDuration && this.props.maxDuration > 0 && !this.props.disable && (
						<TimerTestBrick onStop={this.handleFinishTest} maxDuration={this.props.maxDuration} />
					)}
				</header>
				<Swiper {...params}>
					{this.props.collectionContent.map((itemContent, indexContent) => {
						return (
							<Question
								key={indexContent}
								content={itemContent}
								onFinishQuestion={(data) => {
									const itemResponse = {
										[itemContent._id]: parseInt(data, 10),
									};
									this.responses = Object.assign(this.responses, itemResponse);
								}}
								set={this.props.set}
								index={indexContent}
								disable={this.props.disable}
								submissions={this.props.submissions}
								activeIndex={this.state.activeIndex}
							/>
						);
					})}
				</Swiper>

				<NavigationTestBrick
					isFirstQuestion={this.state.activeIndex === 0 ? true : false}
					isLastQuestion={this.isFinish() ? true : false}
					onGoPrev={this.goPrev}
					onGoNext={this.goNext}
					onGoFinish={this.handleFinishTest}
					disableTest={this.props.disable}
				/>

				<ScrollBarTestBrick percentValue={this.getProgress()} />
			</div>
		);
	}
}

Test.propTypes = {
	beforeHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	collectionContent: PropTypes.array,
	onFishedTest: PropTypes.func,
	submissions: PropTypes.object,
	disable: PropTypes.bool,
	maxDuration: PropTypes.number,
	set: PropTypes.func,
};
export default Test;
