import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ReplayIcon from '@mui/icons-material/Replay';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import DialogMui from 'businessLogic/shared/Modals/DialogMui';

import {t} from 'businessLogic/scope/admin/helper/adminTtag';

const styles = (sx) => () => ({
	...sx,
});

export default function AIDialog({open, onCancel, onAccept, defaultPrompt, selectedText, sx}) {
	const [prompt, setPrompt] = React.useState('');
	const [inputText, setInputText] = React.useState('');
	const [response, setResponse] = React.useState('');
	const [model, setModel] = React.useState('gpt-4o-mini');
	const [isLoading, setIsLoading] = React.useState(false);

	React.useEffect(() => {
		if (defaultPrompt) setPrompt(defaultPrompt);
		if (selectedText) setInputText(selectedText);
	}, [defaultPrompt, selectedText]);

	const handleCancel = () => {
		if (onCancel) onCancel();
		setPrompt('');
		setInputText('');
		setResponse('');
	};

	const handleAccept = () => {
		if (onAccept) onAccept(response);
		setPrompt('');
		setInputText('');
		setResponse('');
	};

	const handleDialogClick = (e) => {
		e.stopPropagation();
	};

	return (
		<DialogMui
			open={open}
			onClose={handleCancel}
			className="AIDialog"
			sx={styles(sx)}
			maxWidth="md"
			fullWidth
			title={t`Asistente de IA`}
			onClick={handleDialogClick}
		>
			{/* <DialogTitle>{t`Información adicional`}</DialogTitle> */}
			<DialogContent onClick={handleDialogClick}>
				{/* <DialogContentText>{t`Indica las instrucciones para la IA`}</DialogContentText> */}

				<TextField
					margin="dense"
					id="prompt"
					label={t`Instrucciones`}
					type="text"
					fullWidth
					variant="standard"
					value={prompt}
					onChange={(e) => setPrompt(e.target.value)}
				/>

				{selectedText && (
					<Box
						sx={{
							'p': 1,
							// 'textAlign': 'center',
							'marginTop': 2,
							'& textarea': {
								'resize': 'none',
								'border': 'none',
								'background': 'none',
								'fontSize': 18,
								'fontWeight': 400,
								'fontFamily': 'inherit',
								'width': '100%',
								// 'textAlign': 'center',
								'&:focus': {
									border: 'none',
									outline: 'none',
								},
							},
						}}
					>
						{/* <TextField
							margin="dense"
							id="inputText"
							label={t`Texto de entrada`}
							type="text"
							fullWidth
							variant="outlined"
							value={inputText}
							onChange={(e) => setInputText(e.target.value)}
						/> */}
						<TextareaAutosize value={inputText} onChange={(e) => setInputText(e.target.value)} />
					</Box>
				)}

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end',
						width: '100%',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-end',
							width: '100%',
							gap: 2,
						}}
					>
						<Select
							value={model}
							onChange={(e) => setModel(e.target.value)}
							variant="standard"
							size="small"
							native={true}
						>
							<option value="gpt-4o-mini">GPT-4o-mini</option>
							<option value="gpt-4o-2024-08-06">GPT-4o</option>
						</Select>
						<Button
							onClick={async () => {
								setIsLoading(true);

								const serviceBaseUrl =
									'https://europe-west1-createandshare.cloudfunctions.net/cs-ia-service/';
								// 'http://management.192.168.1.235.nip.io:9090/';
								const serviceUrl = selectedText
									? `${serviceBaseUrl}transformText`
									: `${serviceBaseUrl}generateText`;
								const response = await fetch(serviceUrl, {
									method: 'POST',
									headers: {
										'Content-Type': 'application/json',
									},
									body: JSON.stringify({
										prompt,
										text: inputText,
										model,
									}),
								});

								const data = await response.json();

								// console.log('data', data);

								const text = data.text;
								setResponse(text);
								setIsLoading(false);
							}}
							variant="contained"
							disabled={!prompt || isLoading}
							size="small"
							startIcon={isLoading ? <CircularProgress size={20} /> : <ReplayIcon />}
						>
							{t`Generar`}
						</Button>
					</Box>
				</Box>

				<Box
					sx={{
						'p': 1,
						// 'textAlign': 'center',
						'marginTop': 2,
						'& textarea': {
							'resize': 'none',
							'border': 'none',
							'background': 'none',
							'fontSize': 18,
							'fontWeight': 400,
							'fontFamily': 'inherit',
							'width': '100%',
							// 'textAlign': 'center',
							'&:focus': {
								border: 'none',
								outline: 'none',
							},
						},
					}}
				>
					<TextareaAutosize
						autoFocus
						// placeholder={t`Res`}
						disabled={!response}
						value={response}
						onChange={(e) => setResponse(e.target.value)}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							width: '100%',
							gap: 2,
						}}
					>
						<Button
							onClick={handleCancel}
							color="secondary"
							variant="contained"
						>{t`Cancelar`}</Button>
						<Button
							onClick={handleAccept}
							variant="contained"
							disabled={!response}
						>{t`Aceptar`}</Button>
					</Box>
				</Box>
			</DialogActions>
		</DialogMui>
	);
}
