import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import {imageUrl} from 'businessLogic/helpers/images';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import brickImage2 from './img/brickPreview2.jpg';
import editionFields from './edit.js';
import './styles.scss';

class GalleryInteractive extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Galería – estilo 1`;
	static brickIcon = 'carousel';
	static brickImage = brickImage;
	static brickImage2 = brickImage2;

	static brickDefaultData = {
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault4Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault5Opt.svg',
					credit: '',
				},
			},
		],
	};
	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const images = data.images || [];

		const classes = classNames({
			'brick': true,
			'gallery-interactive': true,
		});

		let elements = images.map((item, index) => {
			return (
				<div className="gallery-interactive__item" key={index}>
					<div
						className="gallery-interactive__item__img"
						style={{
							backgroundImage: `url('${imageUrl(item.imageObj.imageSrc)}')`,
						}}
					>
						<span className="gallery-interactive__item__img__credit">{item.imageObj.credit}</span>
					</div>
				</div>
			);
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>{elements}</div>
			</Container>
		);
	}
}

export default brickWrapper(GalleryInteractive);
