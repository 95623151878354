import get from 'lodash/get';

import datefns_formatDistanceToNow from 'date-fns/formatDistanceToNow';
import datefns_formatDuration from 'date-fns/formatDuration';
import datefns_format from 'date-fns/format';

let userLocale, adminLocale;

const langToLocaleImport = {
	es_ES: 'es',
	en_GB: 'en-GB',
	pt_PT: 'pt',
	it_IT: 'it',
	de_DE: 'de',
	da_DK: 'da',
	sv_SE: 'sv',
	nn_NO: 'nn',
};

export const setDefaultLocale = async (userLang, adminLang) => {
	const userLocaleName = get(langToLocaleImport, userLang, 'en-GB');
	userLocale = (await import(`date-fns/locale/${userLocaleName}`)).default;

	const adminLocaleName = get(langToLocaleImport, adminLang, 'en-GB');
	adminLocale = (await import(`date-fns/locale/${adminLocaleName}`)).default;
};

export const formatDistanceToNow = (date, options = {}, user) => {
	return datefns_formatDistanceToNow(date, {
		...options,
		locale: user ? userLocale : adminLocale,
	});
};

export const formatDuration = (duration, options = {}, user) => {
	return datefns_formatDuration(duration, {
		...options,
		locale: user ? userLocale : adminLocale,
	});
};

export const format = (date, format, options = {}, user) => {
	return datefns_format(date, format, {
		...options,
		locale: user ? userLocale : adminLocale,
	});
};
