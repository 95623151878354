import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {t} from 'businessLogic/scope/admin/helper/adminTtag';

const styles = (sx) => () => ({
	...sx,
});

export default function UrlDialog({open, onCancel, onAccept, defaultValue, sx}) {
	const [url, setUrl] = React.useState('');

	React.useEffect(() => {
		setUrl(defaultValue);
	}, [defaultValue]);

	const cancelHandler = () => {
		if (onCancel) onCancel();
		setUrl('');
	};

	const acceptHandler = () => {
		if (onAccept) onAccept(url);
		setUrl('');
	};

	return (
		<Dialog
			open={open}
			onClose={cancelHandler}
			className="UrlDialog"
			sx={styles(sx)}
			maxWidth="xs"
			fullWidth
		>
			<DialogTitle>{t`Enlace`}</DialogTitle>
			<DialogContent>
				<DialogContentText>{t`Introduce la url del enlace`}</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="url"
					label={t`URL`}
					type="url"
					fullWidth
					variant="standard"
					value={url}
					onChange={(e) => setUrl(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.stopPropagation();
							e.preventDefault();
							acceptHandler();
						}
						if (e.key === 'Escape') {
							e.stopPropagation();
							e.preventDefault();
							cancelHandler();
						}
					}}
				/>
			</DialogContent>
			<DialogActions
				sx={{
					display: 'flex',
					justifyContent: 'center',
					gap: 2,
					paddingBottom: 2,
				}}
			>
				<Button onClick={cancelHandler} color="secondary" variant="contained">{t`Cancelar`}</Button>
				<Button onClick={acceptHandler} variant="contained">{t`Aceptar`}</Button>
			</DialogActions>
		</Dialog>
	);
}
