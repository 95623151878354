import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import ImageSelectorDialog from 'businessLogic/shared/MediaSelector/ImageSelectorDialog';
import EditImageSubmenu from 'businessLogic/scope/admin/shared/Image/EditImageSubmenu';
import StylesOptionsWrapper from '../StylesOptionsWrapper';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class ImageBg extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	static getDefaultValue = () => {
		const defaultValue = {
			imageBg: '',
		};

		return defaultValue;
	};

	handleDeleteImageBackground = () => {
		this.props.setMainField('');
	};

	handleClickReplaceImageBackground = () => {
		this.refs.selectorDialog.show();
	};

	handleNewImageBackground = (imageSrc) => {
		this.props.setMainField(imageSrc);
	};

	render() {
		const imageBg = this.props.getMainField();

		return (
			<StylesOptionsWrapper>
				<label className="item-title">{t`Imagen de fondo`}</label>
				<EditImageSubmenu
					srcImage={imageBg}
					onDelete={this.handleDeleteImageBackground}
					onReplace={this.handleClickReplaceImageBackground}
				/>

				{/* </Dialog> */}
				<ImageSelectorDialog ref="selectorDialog" onSelect={this.handleNewImageBackground} />
			</StylesOptionsWrapper>
		);
	}
}

export default editionBrickWrapper(ImageBg);
