import React, {Component} from 'react';
import classNames from 'classnames';
import editionBrickWrapper from '../editionBrickWrapper';
import AudioSelectorDialog from 'businessLogic/shared/MediaSelector/AudioSelectorDialog';
import LabelForInput from 'businessLogic/shared/Input/LabelForInput';
import Icon from 'ui/shared/Icon';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
// import Input from "ui/shared/FormFields/Input";

import './styles.scss';

class Audio extends Component {
	handleDelete = () => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'audioSrc'], ' ');
	};

	handleReplace = () => {
		this.refs.audioSelectorDialog.show();
	};
	handleNewAudio = (audioSrc) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'audioSrc'], audioSrc);
	};

	handleChangeCredit = (event) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'credit'], event.target.value);
	};

	render() {
		const editionBrickStruct = this.props.editionBrickStruct;
		// const showCredit = editionBrickStruct.credit !== undefined ? editionBrickStruct.credit : true;

		let audioSrc = this.props.get([editionBrickStruct.fieldName, 'audioSrc']);

		let valueCredit = this.props.get([editionBrickStruct.fieldName, 'credit']);

		//Parche para los ladrillos ya creados anteriormente
		if (audioSrc === undefined) audioSrc = this.props.get('audio');
		if (valueCredit === undefined) valueCredit = this.props.get('credit');

		const classes = classNames({
			'edition-brick-audio': true,
		});

		const pathObject = this.props.editionBrickData.data.audio.audioSrc.split('/');
		//obtener tamaño de objeto devuelto por split
		Object.size = function(obj) {
			var size = 0,
				key;
			for (key in obj) {
				if (obj.hasOwnProperty(key)) size++;
			}
			return size;
		};
		let size = Object.size(pathObject);

		//el nombre del fichero siempre será la última posición del objeto devuelto por split
		let audioFileName = pathObject[--size];

		let currentAudioFileName = audioFileName;
		if (audioFileName === undefined) currentAudioFileName = audioSrc;

		return (
			<div className={classes}>
				<div className={classes + '__select-audio'}>
					<LabelForInput text={t`Selecciona un archivo de audio:`} />
					<div className={classes + '__select-audio__options'}>
						<div className={classes + '__select-audio__options__icon'}>
							<Icon name="add-audio" size="normal" />
						</div>
						<div
							className={classes + '__select-audio__options__add btn-audio'}
							onClick={this.handleReplace}
						>
							<Icon name="plus" size="normal" />
							<span>{t`Añadir/Reemplazar audio`}</span>
						</div>
						<div
							className={classes + '__select-audio__options__remove btn-audio'}
							onClick={this.handleDelete}
						>
							<Icon name="delete" size="normal" />
							<span>{t`Borrar audio`}</span>
						</div>
					</div>
					<p className="side-bar-config__sidebar__select-audio__file-name">
						{currentAudioFileName}
					</p>
				</div>
				<AudioSelectorDialog ref="audioSelectorDialog" onSelect={this.handleNewAudio} />
			</div>
		);
	}
}

export default editionBrickWrapper(Audio);
