import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import YouTube from '@u-wave/react-youtube';
import PropTypes from 'prop-types';
import './styles.scss';

class YouTubeBackground extends PureComponent {
	loopVideo = (event) => {
		if (this.props.loop === true) {
			if (event.data === 0) {
				event.target.playVideo();
			}
		}
	};
	render() {
		return (
			<div
				className="video-youtube-container-background"
				ref="videoContainer"
				key={this.props.video}
			>
				<YouTube
					video={this.props.video}
					autoplay={true}
					showRelatedVideos={false}
					showCaptions={false}
					showInfo={false}
					controls={false}
					muted={this.props.muted}
					allowFullscreen={false}
					onStateChange={this.loopVideo}
				/>
			</div>
		);
	}
}

YouTubeBackground.propTypes = {
	video: PropTypes.string,
	paused: PropTypes.bool
};

export default brickWrapper(YouTubeBackground);
