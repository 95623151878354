export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'empty',
			},
		],
	},
];
export default editionFields;
