import {getPill} from 'businessLogic/store/data/pills';
import store from 'businessLogic/store/store';

//Funciones usadas por el ladrillo de portada para obtener información de los enlaces a píldoras
// obtenidos por el editionBrick correspondiente

export const getTitle = (pillLink) => {
	if (pillLink.usePillData) {
		const pill = getPill(store.getState(), pillLink.pillId);
		if (pill) return pill.name || '';
		return '';
	}

	return pillLink.title;
};

export const getText = (pillLink) => {
	if (pillLink.usePillData) {
		const pill = getPill(store.getState(), pillLink.pillId);
		if (pill) return pill.description || '';
		return '';
	}

	return pillLink.text;
};

export const getImage = (pillLink) => {
	if (pillLink.usePillData) {
		const pill = getPill(store.getState(), pillLink.pillId);
		if (pill) {
			if (pill.imageObj !== null) {
				return {imageSrc: pill.imageObj.imageSrc || '', credit: ''};
			} else {
				return {imageSrc: pill.image || '', credit: ''};
			}
		}
		return {imageSrc: '', credit: ''};
	}

	return pillLink.imageObj;
};
