import React from 'react';
import Box from '@mui/material/Box';
import intervalToDuration from 'date-fns/intervalToDuration';
import differenceInDays from 'date-fns/differenceInDays';
import {t} from 'businessLogic/scope/user/helper/userTtag';

const styles = (sx) => (theme) => ({
	'&.TimeLeftCountdown': {
		'display': 'flex',
		'alignItems': 'stretch',
		'justifyContent': 'center',
		'gap': 1,
		'& .TimeLeftCountdown__days': {
			'display': 'flex',
			'flexDirection': 'column',
			'alignItems': 'center',
			'justifyContent': 'center',
			// 'gap': 0.5,
			'color': '#ffffff',
			'backgroundColor': theme.platformStyles?.menuColor,
			'borderRadius': 2,
			'padding': '0.5rem 1.2rem',
			'marginRight': 1,
			'& .TimeLeftCountdown__days__number': {
				fontSize: 48,
				fontWeight: 600,
			},
			'& .TimeLeftCountdown__days__text': {
				fontSize: 24,
				fontWeight: 400,
			},
		},
		'& .TimeLeftCountdown__time': {
			'display': 'flex',
			'flexDirection': 'column',
			'alignItems': 'center',
			'justifyContent': 'center',
			// 'gap': 0.5,
			'color': theme.platformStyles?.menuColor,
			'& .TimeLeftCountdown__time__number': {
				fontSize: 48,
				fontWeight: 600,
			},
			'& .TimeLeftCountdown__time__text': {
				fontSize: 24,
				fontWeight: 400,
			},
		},
		'& .TimeLeftCountdown__separator': {
			'fontSize': 48,
			'fontWeight': 600,
			'display': 'flex',
			'flexDirection': 'column',
			'alignItems': 'center',
			'justifyContent': 'center',
			// 'gap': 0.5,
			'color': theme.platformStyles?.menuColor,
			'& .TimeLeftCountdown__separator__number': {
				fontSize: 48,
				fontWeight: 600,
			},
			'& .TimeLeftCountdown__separator__text': {
				fontSize: 24,
				fontWeight: 400,
				visibility: 'hidden',
			},
		},
		[theme.breakpoints.down('lg')]: {
			'& .TimeLeftCountdown__days': {
				'& .TimeLeftCountdown__days__number': {
					fontSize: 36,
				},
				'& .TimeLeftCountdown__days__text': {
					fontSize: 18,
				},
			},
			'& .TimeLeftCountdown__time': {
				'& .TimeLeftCountdown__time__number': {
					fontSize: 36,
				},
				'& .TimeLeftCountdown__time__text': {
					fontSize: 18,
				},
			},
			'& .TimeLeftCountdown__separator': {
				'& .TimeLeftCountdown__separator__number': {
					fontSize: 36,
				},
				'& .TimeLeftCountdown__separator__text': {
					fontSize: 18,
				},
			},
		},
	},
	...sx,
});

export default function TimeLeftCountdown({date, sx}) {
	const [currentDate, setCurrentDate] = React.useState(new Date());
	const requestRef = React.useRef();

	React.useEffect(() => {
		const updateCurrentDate = () => {
			setCurrentDate(new Date());
			requestRef.current = requestAnimationFrame(updateCurrentDate);
		};

		requestRef.current = requestAnimationFrame(updateCurrentDate);
		return () => cancelAnimationFrame(requestRef.current);
	}, []);

	const durationToStart = intervalToDuration({
		start: currentDate,
		end: date,
	});

	const {hours, minutes, seconds} = durationToStart;
	const days = differenceInDays(date, currentDate);

	return (
		<Box className="TimeLeftCountdown" sx={styles(sx)}>
			{/* {`${days}d ${hours}h ${minutes}m ${seconds}s`} */}
			<Box className="TimeLeftCountdown__days">
				<Box className="TimeLeftCountdown__days__number">{days}</Box>
				<Box className="TimeLeftCountdown__days__text">{t`Días`}</Box>
			</Box>
			<Box className="TimeLeftCountdown__time">
				<Box className="TimeLeftCountdown__time__number">{hours}</Box>
				<Box className="TimeLeftCountdown__time__text">{t`Hrs`}</Box>
			</Box>
			<Box className="TimeLeftCountdown__separator">
				<Box className="TimeLeftCountdown__separator__number">:</Box>
				<Box className="TimeLeftCountdown__separator__text">:</Box>
			</Box>
			<Box className="TimeLeftCountdown__time">
				<Box className="TimeLeftCountdown__time__number">
					{minutes < 10 ? `0${minutes}` : minutes}
				</Box>
				<Box className="TimeLeftCountdown__time__text">{t`Min`}</Box>
			</Box>
			<Box className="TimeLeftCountdown__separator">
				<Box className="TimeLeftCountdown__separator__number">:</Box>
				<Box className="TimeLeftCountdown__separator__text">:</Box>
			</Box>
			<Box className="TimeLeftCountdown__time">
				<Box className="TimeLeftCountdown__time__number">
					{seconds < 10 ? `0${seconds}` : seconds}
				</Box>
				<Box className="TimeLeftCountdown__time__text">{t`Seg`}</Box>
			</Box>
		</Box>
	);
}
