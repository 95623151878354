import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Image',
						fieldName: 'image',
					},
					{
						type: 'Text',
						fieldName: 'nameAndSurname',
						title: t`Nombre y apellidos`,
					},
					{
						type: 'Text',
						fieldName: 'email',
						title: 'Email',
					},
					{
						type: 'Text',
						fieldName: 'phone',
						title: t`Teléfono`,
					},
					{
						type: 'Text',
						fieldName: 'motivation',
						title: t`Motivación`,
					},
					{
						type: 'Text',
						fieldName: 'legalTextAccept',
						title: t`Texto legal para aceptar`,
					},
					{
						type: 'Text',
						fieldName: 'legalTextShowPopUp',
						title: t`Enlace para ampliar información`,
					},
					{
						type: 'TextEditor',
						fieldName: 'legalTextContent',
						title: t`Texto de condiciones legales`,
					},
					{
						type: 'Text',
						fieldName: 'button',
						title: t`Texto del botón de enviar`,
					},

					{
						type: 'TextEditor',
						fieldName: 'finalText',
						title: t`Texto para el usuario tras completar el formulario`,
					},
				],
			},
			{
				type: 'Tab',
				title: t`Participantes`,
				fieldName: 'data',
				content: [
					{
						type: 'CsvDownload',
						title: t`Descargar listado de participantes`,
						headers: [
							{label: t`Nombre y apellidos`, key: 'nameAndSurname'},
							{label: t`Email`, key: 'email'},
							{label: t`Teléfono`, key: 'phone'},
							{label: t`Motivación`, key: 'motivation'},
							{label: t`Acepta condiciones legales`, key: 'acceptLegals'},
							{label: t`Fecha de inscripción`, key: 'createdAt'},
						],
						csvFileName: 'participants-contest-list.csv',
					},
				],
			},
		],
	},
];
export default editionFields;
