import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import Input from 'ui/shared/FormFields/Input';
import Instructions from 'businessLogic/core/shared/Edit/Instructions';
import {parseTypeNumeric} from 'businessLogic/helpers/functions';
import './styles.scss';

class Text extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		let defaultValue = 0;
		if (editionBrickStruct.defaultValue) {
			defaultValue = editionBrickStruct.defaultValue;
		}
		return defaultValue;
	};

	onChange = (event) => {
		this.props.setMainField(this.validationValue(event.target.value));
	};
	validationValue = (value) => {
		let newValue;
		const parseNumericValue = parseTypeNumeric(value);
		const parseNumericMax = parseTypeNumeric(this.props.editionBrickStruct.max);
		// const parseNumericMin = parseTypeNumeric(this.props.editionBrickStruct.min);

		if (parseNumericMax !== undefined && parseNumericMax < parseNumericValue) {
			newValue = parseNumericMax;
		} else {
			newValue = value;
		}
		return newValue;
	};
	render() {
		return (
			<div className={'edition-brick-number ' + this.props.editionBrickStruct.fieldName}>
				<label className="edition-brick-text__label">{this.props.editionBrickStruct.title}</label>
				{this.props.editionBrickStruct.instructions !== undefined && (
					<Instructions text={this.props.editionBrickStruct.instructions} />
				)}
				<Input
					name="box-number"
					type="number"
					step={this.props.editionBrickStruct.step}
					value={this.validationValue(this.props.getMainField())}
					onChange={this.onChange}
					min={this.props.editionBrickStruct.min}
					max={this.props.editionBrickStruct.max}
				/>
			</div>
		);
	}
}

export default editionBrickWrapper(Text);
