import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import {graphql} from 'react-apollo';
import {compose} from 'recompose';
import {ENTITY_QUERY} from 'businessLogic/store/data/entity/queries';
import Form from './components/Form';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import FormValidator from 'businessLogic/helpers/FormValidator';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import {userUploadPillFileUrl} from 'businessLogic/scope/user/helper/userUrlHelper';
// import get from 'lodash/get';
import {client} from 'businessLogic/store/store';
import {USER_SET_PILL_INCLUDE_FILES} from 'businessLogic/store/data/pills/graphql/query';
import store from 'businessLogic/store/store.js';
import {getDataUser} from 'businessLogic/store/data/session';
import './styles.scss';

class PersonalizableContest extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Formulario personalizable con descarga de datos`;
	static brickImage = brickImage;
	static brickHelpText = () =>
		t`Permite rellenar un formulario cuyos datos se guardan en un archivo descargable`;
	static canReload = true;
	static brickDefaultData = () => ({
		// image: {
		// 	imageSrc: '/uploads/default-images/csDefault1Opt.svg',
		// 	credit: '',
		// },
		title: _('Concurso'),
		button: _('Participar'),
		finalText: _('Tu participación ha sido realizada con éxito. ¡Suerte!'),
		flexibleContent: [
			{
				required: true,
				labelField: '',
				slugField: 'nameAndSurname',
				textFieldForm: _('Nombre y apellidos'),
				flexibleContentType: 'TextField',
			},
			{
				required: true,
				labelField: '',
				slugField: 'email',
				emailFieldForm: 'tuemail@tuemail.com',
				flexibleContentType: 'EmailField',
			},
			{
				required: true,
				labelField: '',
				slugField: 'motivation',
				textAreaFieldForm: _('Introduce el texto motivación'),
				flexibleContentType: 'TextAreaField',
			},
			{
				required: true,
				labelField: _('Acepto las condiciones de uso.'),
				checkFieldFormTextLink: _('Más información'),
				slugField: 'acceptCheck',
				checkFieldForm:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam leo urna, sodales ac semper eget, eleifend et ante. Aliquam finibus mi eget magna malesuada ultricies. Nulla elementum bibendum ipsum sit amet dapibus. Nulla varius, lectus et auctor imperdiet, ipsum nisi tincidunt enim, et pulvinar nulla erat ac lectus. Praesent eu lorem id ante feugiat maximus ac vitae nisl. Quisque fringilla, ex sit amet faucibus vestibulum, mi augue tempor enim, id gravida risus ligula et eros. Curabitur ornare sagittis lectus mollis ultricies. Sed imperdiet dignissim ullamcorper. Sed ultrices vitae nunc at congue. Sed scelerisque fermentum consectetur. Ut rhoncus pretium justo quis viverra.',
				flexibleContentType: 'CheckField',
			},
		],
	});

	//Método estático para generar los headers del csv a partir de los datos del ladrillo introducidos por el administrador
	static getHeaders = (brickData) => {
		const brickDataFlexible = brickData.data.flexibleContent;
		const dinamicHeader = [{label: _('Fecha de inscripción'), key: 'createdAt'}];

		const includeLoggedUserData = brickData?.config?.includeLoggedUserData;

		if (includeLoggedUserData) {
			dinamicHeader.push({label: _('Nombre'), key: 'loggedUserName'});
			dinamicHeader.push({label: _('Email'), key: 'loggedUserEmail'});
		}

		for (let i = 0; i < brickDataFlexible.length; i++) {
			dinamicHeader.push({
				label: brickDataFlexible[i].internalLabel
					? brickDataFlexible[i].internalLabel
					: brickDataFlexible[i].slugField,
				key: brickDataFlexible[i].slugField,
				type: brickDataFlexible[i].flexibleContentType,
			});
		}

		return dinamicHeader;
	};

	constructor(props) {
		super(props);
		//creamos la instancia de FormValidator
		this.validator = new FormValidator(this.getValidatorConfig(props));
		const allowSendMoreThanOnce = props.brick?.config?.allowSendMoreThanOnce;
		this.state = {
			showLegals: false,
			dataSent: allowSendMoreThanOnce ? false : this.props.brickAnomDataExists(),
			dataObj: {},
			sending: false,
			validation: this.validator.valid(),
		};
	}

	getLoggedUser() {
		return getDataUser(store.getState());
	}

	getValidatorConfig = (props) => {
		const data = props.brick.data;
		const validatorConfig = [];
		// const userLang = get(this.props, 'entityQuery.currentEntity.langs.userLang', 'es-ES').replace(
		// 	'_',
		// 	'-',
		// );

		data.flexibleContent.forEach(function (flexibleField) {
			//si el campo es requerido cuando esté vacío no es válido
			if (flexibleField.required) {
				validatorConfig.push({
					field: flexibleField.slugField,
					method: 'isEmpty',
					validWhen: false,
					message: t`Este campo es requerido, no lo puedes dejar vacío.`,
				});
			}
			//miramos que tipo de campo es para crear la regla específica
			switch (flexibleField.flexibleContentType) {
				case 'EmailField':
					validatorConfig.push({
						field: flexibleField.slugField,
						method: 'isEmail',
						validWhen: true,
						message: t`La dirección de correo no es válida.`,
					});
					break;
				// case 'PhoneField':
				// 	validatorConfig.push({
				// 		field: flexibleField.slugField,
				// 		method: 'isMobilePhone',
				// 		validWhen: true,
				// 		message: t`El número de teléfono no es válido.`,
				// 		args: [userLang],
				// 	});
				// 	break;
				case 'CheckField':
					if (flexibleField.required) {
						validatorConfig.push({
							field: flexibleField.slugField,
							method: PersonalizableContest.isChecked,
							validWhen: true,
							message: t`Debes marcar el check para poder continuar.`,
						});
					}
					break;
				case 'File':
					if (flexibleField.required) {
						validatorConfig.push({
							field: flexibleField.slugField,
							method: PersonalizableContest.isEmptyArray,
							validWhen: false,
							message: t`Este campo es requerido, no lo puedes dejar vacío.`,
						});
					}
					break;
				default:
					break;
			}
		});

		return validatorConfig;
	};

	setUploadFileError = async (slugField, errorMsg, uploadedFilePaths) => {
		const validation = {
			isValid: false,
			[slugField]: [errorMsg],
		};
		this.setState({validation, sending: false});

		//todo: borrar los ficheros subidos
		for (let i = 0; i < uploadedFilePaths.length; i++) {
			// const filePath = uploadedFilePaths[i];
			// this.props.deleteFile(filePath);
		}
	};

	uploadFileFields = async () => {
		const brickDataFlexible = this.props.brick.data.flexibleContent;
		const fileFields = brickDataFlexible.filter((field) => field.flexibleContentType === 'File');
		const url = userUploadPillFileUrl();
		let fileUploaded = false;

		for (let i = 0; i < fileFields.length; i++) {
			const fileField = fileFields[i];
			const files = this.state.dataObj[fileField.slugField];
			const prefixEmail = Boolean(fileField.prefixEmail);
			if (files && files.length > 0) {
				const filePaths = [];
				for (let j = 0; j < files.length; j++) {
					const file = files[j];
					const data = new FormData();
					const path = '';
					data.append('file', file);
					data.append('filename', file.name);
					data.append('pillId', this.props.parentId || this.props.pillId);
					data.append('path', path);
					data.append('prefixEmail', prefixEmail);
					if (prefixEmail) {
						// Si hay campo de tipo email, lo añadimos al formData como userEmail
						const emailFields = brickDataFlexible.filter(
							(field) => field.flexibleContentType === 'EmailField',
						);
						if (emailFields.length > 0) {
							const userEmail = this.state.dataObj[emailFields[0].slugField];
							data.append('userEmail', userEmail);
						}
					}
					const options = {
						method: 'POST',
						body: data,
					};
					try {
						const response = await fetch(url, options);

						if (response.status === 413) {
							await this.setUploadFileError(
								fileField.slugField,
								t`El fichero ${file.name} es demasiado grande.`,
								filePaths,
							);
							return false;
						}
						if (!response.ok || response.status !== 200) {
							await this.setUploadFileError(
								fileField.slugField,
								t`Error al subir el archivo ${file.name}`,
								filePaths,
							);
							return false;
						}
						const responseJSON = await response.json();

						filePaths.push(`${path}${responseJSON.name}`);
						fileUploaded = true;
						// this.props.onFileUploaded();
					} catch (error) {
						console.error(error);
						await this.setUploadFileError(
							fileField.slugField,
							t`Error al subir el archivo ${file.name}`,
							filePaths,
						);
						return false;
					}
				}

				this.setState({
					dataObj: Object.assign({}, this.state.dataObj, {
						[fileField.slugField]: filePaths,
					}),
				});
			}
		}

		if (fileUploaded) {
			client.mutate({
				mutation: USER_SET_PILL_INCLUDE_FILES,
				variables: {
					pillId: this.props.parentId || this.props.pillId,
					status: true,
				},
			});
		}

		return true;
	};

	//funcion para la regla de check obligatorio
	static isChecked = (value) => {
		return value === 'true';
	};

	static isEmptyArray = (value) => {
		if (!value || value.length === 0) {
			return true;
		}
		return false;
	};

	setDataObj = (field, value) => {
		this.setState({dataObj: Object.assign({}, this.state.dataObj, {[field]: value})});
	};

	sendData = async () => {
		const filesUploaded = await this.uploadFileFields();
		if (!filesUploaded) {
			return;
		}
		const dataObj = this.state.dataObj;

		const includeLoggedUserData = this.props.brick?.config?.includeLoggedUserData;
		if (includeLoggedUserData) {
			const loggedUser = this.getLoggedUser();
			if (loggedUser) {
				dataObj.loggedUserName = loggedUser.name;
				dataObj.loggedUserEmail = loggedUser.email;
			}
		}

		this.props.setBrickAnomData(dataObj);
		// console.log('sendData', this.state.dataObj);
		this.setState({dataSent: true, sending: false});
	};

	showLegals = (bool) => {
		this.setState({showLegals: bool});
	};

	validate = async () => {
		if (this.state.sending) {
			return;
		}
		this.setState({sending: true});
		let validation = this.validator.validate(this.state.dataObj);

		if (validation.isValid) {
			await this.sendData();
		}
		this.setState({validation, sending: false});
	};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const image = data.image;
		const classes = classNames({
			'brick': true,
			'personalizable-contest': true,
		});

		const allowSendMoreThanOnce = this.props.brick?.config?.allowSendMoreThanOnce;

		const showImage =
			image &&
			image.imageSrc &&
			image.imageSrc !== '' &&
			image.imageSrc !== '/uploads/default-images/csDefault1Opt.svg';

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div className={`personalizable-contest__form ${showImage ? 'with-image' : ''}`}>
						{!this.state.dataSent && (
							<Form
								data={data}
								onShow={this.showLegals}
								onSendData={this.validate}
								setData={this.setDataObj}
								validation={this.state.validation}
								sending={this.state.sending}
							/>
						)}
						{this.state.dataSent && (
							<div className="personalizable-contest__form__final">
								<FrontEditableText
									className="personalizable-contest__form__final-text"
									fieldName="finalText"
									text={data.finalText}
									editable={false}
									set={this.props.set}
								/>
								<div className="personalizable-contest__form__final-button">
									{allowSendMoreThanOnce && (
										<button
											className="personalizable-contest__form__final-button__send-more"
											onClick={() => {
												this.setState({dataSent: false});
											}}
										>
											{t`Volver a participar`}
										</button>
									)}
								</div>
							</div>
						)}
					</div>
					{showImage && (
						<div className="personalizable-contest__image">
							<NewImageClickable imgObj={image} clickable={false} />
						</div>
					)}
				</div>
			</Container>
		);
	}
}

const PersonalizableContestQuery = compose(graphql(ENTITY_QUERY, {name: 'entityQuery'}))(
	PersonalizableContest,
);

export default brickWrapper(PersonalizableContestQuery);
