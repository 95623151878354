import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import {routerMiddleware} from './middlewares/routerMiddleware';
import {createBrowserHistory} from 'history';
import {startListener} from './listener';

//Importamos los reducer
import routerReducer from './data/router';
import pillsReducer from './data/pills';
import scenesReducer from 'businessLogic/scope/admin/pages/reducer';
import sessionReducer from './data/session';
import thunkMiddleware from 'redux-thunk';

import {ApolloClient} from 'apollo-client';
import {HttpLink} from 'apollo-link-http';
import {ApolloLink, concat} from 'apollo-link';
import {InMemoryCache} from 'apollo-cache-inmemory';

// Comprobamos si se ha incluido el parámetro aau para indicar que es un admin entrando como usuario
const urlParams = new URLSearchParams(window.location.search);
export const adminAsUserToken = urlParams.has('aau') ? urlParams.get('aau') : null;

const getGraphqlLink = () => {
	const link = new HttpLink({
		uri: '/graphql',
		credentials: 'include',
	});

	if (adminAsUserToken) {
		const adminAsUserMiddleware = new ApolloLink((operation, forward) => {
			operation.setContext({
				headers: {
					CustomHeaderAdminAsUser: adminAsUserToken,
				},
			});

			return forward(operation);
		});

		return concat(adminAsUserMiddleware, link);
	}
	return link;
};

// Creamos el objeto history
export const history = createBrowserHistory();

const middlewares = [thunkMiddleware];

//Función generadora de ids únicos
function* idGen() {
	var index = 0;
	while (true) yield index++;
}

const gen = idGen();

const apolloOptions = {
	// link: apollo_client,
	link: getGraphqlLink(),
	cache: new InMemoryCache({
		dataIdFromObject: (object) => {
			if (object.__typename === 'BrickType') {
				//A los objetos de tipo BrickType le damos ids únicos siempre, porque sus
				// ids no son globales sino que se pueden repetir entre píldoras
				return gen.next().value;
			}
			return object.id;
		},
	}),
};
if (process.env.REACT_APP_EVENTS_API === 'scorm') {
	apolloOptions.defaultOptions = {
		query: {
			fetchPolicy: 'cache-only',
		},
		watchQuery: {
			fetchPolicy: 'cache-only',
		},
	};
} else {
	//Cuando no estamos en scorm, necesitamos el routerMiddleware para que cambie la url visible
	// Creamos el router Middleware
	const routerMid = routerMiddleware(history);
	middlewares.push(routerMid);
}

export const client = new ApolloClient(apolloOptions);

const dataGeneral = combineReducers({
	router: routerReducer,
	pills: pillsReducer,
	scenes: scenesReducer,
	session: sessionReducer,
});

const reducers = combineReducers({
	dataGeneral,
});

// Creamos el store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(...middlewares)));

// Lanzamos el listener del objeto history que modificará el store ante los cambios en la url
//al tener dispatch debe ir después de la definición del store
if (process.env.REACT_APP_EVENTS_API !== 'scorm') {
	startListener(history, store);
}

export default store;
