import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import PlayButtonAnimation from 'businessLogic/core/shared/Buttons/PlayButtonAnimation';
import debounce from 'lodash/debounce';
import Icon from 'ui/shared/Icon';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

class StopScroll extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Stop scroll`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickHelpText = () => (
		<p>
			{`Detiene automáticamente el movimiento del ladrillo Play scroll.`}
			<br />
			<br />
			{t`Este ladrillo sirve para crear Storytelling. ¿Quieres ver un ejemplo? Pincha`}
			<a href="https://story-telling.cs01.cloud/" target="_blank" rel="noreferrer noopener">
				<i>{t`aquí`}</i>
			</a>
		</p>
	);
	static brickDefaultData = {
		autoStop: true,
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 0,
			paddingBottom: 0,
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			play: false,
			show: false,
			showScrollIcon: false,
		};
		this.onScrollPlay = debounce(this.onScrollPlay, 1500);
	}

	handlePause = () => {
		this.setState({play: false, show: false});
		this.props.configObject.stopScrollAnimation();
	};

	handleVisibilityChange(isVisible) {
		if (isVisible) {
			if (this.props.brick.data.autoStop && this.state.play) {
				if (this.props.configObject) {
					this.props.configObject.stopScrollAnimation();
					this.setState({play: false, showScrollIcon: true});
					this.props.brickEvent('scrollAutoStop');
				}
			}
		} else {
			if (this.props.brick.data.autoStop) {
				this.setState({showScrollIcon: false});
			}
		}
	}

	onScrollStop = () => {
		this.setState({play: false, show: false});
		this.props.configObject.callEventListeners('muteOn');
	};

	onScrollPlay = () => {
		this.setState({play: true, show: true});
	};

	componentDidMount() {
		this.props.configObject.addEventListener('start', this.onScrollPlay);
		this.props.configObject.addEventListener('stop', this.onScrollStop);
	}

	componentWillUnmount() {
		this.props.configObject.removeEventListener('start', this.onScrollPlay);
		this.props.configObject.addEventListener('stop', this.onScrollStop);
		this.onScrollPlay.flush();
	}

	render() {
		const styles = this.props.brick.styles || {};

		const classes = classNames({
			'brick': true,
			'stop-scroll-brick': true,
		});

		const classesIcon = classNames({
			'stop-scroll-brick__icon': true,
			'show-scroll-icon': this.state.showScrollIcon,
		});

		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<PlayButtonAnimation
						handlePlayPause={this.handlePause}
						show={this.state.show}
						hideButton={this.state.showScrollIcon}
						playState={true}
						sticky={true}
						counter={1}
						editMode={this.props.editMode}
					/>
					<div className={classesIcon}>
						<div className="stop-scroll-brick__icon__wrap">
							<Icon name="mouse" size="large" className="stop-scroll-brick__icon__wrap__mouse" />
							<Icon
								name="arrow-narrow-down"
								size="normal"
								className="stop-scroll-brick__icon__wrap__arrow"
							/>
						</div>
					</div>
				</div>
				{this.props.editMode && (
					<div className="stop-scroll-brick__edit">
						<div className="stop-scroll-brick__edit__icon">
							<span className="stop-scroll-brick__edit__icon__square" />
						</div>
						<span className="stop-scroll-brick__edit__text">{t`Ladrillo Stop scroll`}</span>
						<span className="stop-scroll-brick__edit__advice">
							{t`(Texto y fondo solo visible en modo edición)`}
						</span>
					</div>
				)}
			</Container>
		);
	}
}

export default brickWrapper(StopScroll);
