import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class ThreeImageCompositionColDiffMTopWidth extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Composición de tres imágenes con texto - estilo 2`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
				text: 'Quis eost, ofcim sinverum vellant quam corrumque il moluptium ariscimod eveducid.Cim eum quasper oribusae vendae nos aut faciistem sitint.Olut aut ut que et la simustium voluptatur alitatia dis re endit illis doluptati simet inveniam, sit reptat manstrea sastrera manutre',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
				text: 'Quis eost, ofcim sinverum vellant quam corrumque il moluptium ariscimod eveducid.Cim eum quasper oribusae vendae nos aut faciistem sitint.Olut aut ut que et la simustium voluptatur alitatia dis re endit illis doluptati simet inveniam, sit reptat manstrea sastrera manutre',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
				text: 'Quis eost, ofcim sinverum vellant quam corrumque il moluptium ariscimod eveducid.Cim eum quasper oribusae vendae nos aut faciistem sitint.Olut aut ut que et la simustium voluptatur alitatia dis re endit illis doluptati simet inveniam, sit reptat manstrea sastrera manutre',
			},
		],
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}
	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ThreeImageCompositionColDiffMTopWidth']);

		const images = data.images || [];
		const classes = classNames({
			'brick': true,
			'three-image-composition-col-margintop-width-brick': true,
		});

		return (
			<Container
				size="extra-large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<WindowSizeContext.Consumer>
					{({device}) => {
						return (
							<div className={classes}>
								<div className="col col1">
									<div className="image-wrap image1">
										<Animation
											playAnimation={this.state.playEffect}
											delay={1}
											effect={brickAnimation}
										>
											<div className="the-img">
												<NewImageClickable
													imgObj={images[0].imageObj}
													clickable={
														device === 'mobile' || device === 'mobileLandscape' ? false : true
													}
												/>
											</div>

											<div className="the-caption">
												{images[0].imageObj.credit && (
													<div className="caption-credit"> {images[0].imageObj.credit} </div>
												)}
												{images[0].text && (
													<div className="caption-description">
														<FrontEditableText
															fieldName="text"
															text={images[0].text}
															set={(fieldPath, fieldData) => {
																//Propagamos la llamada al método set añadiendo mi nodo al path
																if (this.props.set)
																	this.props.set(['images', 0].concat(fieldPath), fieldData);
															}}
														/>
													</div>
												)}
											</div>
										</Animation>
									</div>
									<div className="image-wrap image3">
										<Animation
											playAnimation={this.state.playEffect}
											delay={2}
											effect={brickAnimation}
										>
											<div className="the-img">
												<NewImageClickable
													imgObj={images[2].imageObj}
													clickable={
														device === 'mobile' || device === 'mobileLandscape' ? false : true
													}
												/>
											</div>
											<div className="the-caption">
												{images[2].imageObj.credit && (
													<div className="caption-credit"> {images[2].imageObj.credit} </div>
												)}
												{images[2].text && (
													<div className="caption-description">
														<FrontEditableText
															fieldName="text"
															text={images[2].text}
															set={(fieldPath, fieldData) => {
																//Propagamos la llamada al método set añadiendo mi nodo al path
																if (this.props.set)
																	this.props.set(['images', 2].concat(fieldPath), fieldData);
															}}
														/>
													</div>
												)}
											</div>
										</Animation>
									</div>
								</div>

								<div className="col col2">
									<div className="image-wrap image2">
										<Animation
											playAnimation={this.state.playEffect}
											delay={1.5}
											effect={brickAnimation}
										>
											<div className="the-img">
												<NewImageClickable
													imgObj={images[1].imageObj}
													clickable={
														device === 'mobile' || device === 'mobileLandscape' ? false : true
													}
												/>
											</div>
											<div className="the-caption">
												{images[1].imageObj.credit && (
													<div className="caption-credit"> {images[1].imageObj.credit} </div>
												)}
												{images[1].text && (
													<div className="caption-description">
														<FrontEditableText
															fieldName="text"
															text={images[1].text}
															set={(fieldPath, fieldData) => {
																//Propagamos la llamada al método set añadiendo mi nodo al path
																if (this.props.set)
																	this.props.set(['images', 1].concat(fieldPath), fieldData);
															}}
														/>
													</div>
												)}
											</div>
										</Animation>
									</div>
								</div>
							</div>
						);
					}}
				</WindowSizeContext.Consumer>
			</Container>
		);
	}
}

export default brickWrapper(ThreeImageCompositionColDiffMTopWidth);
