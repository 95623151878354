import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import Logger from 'businessLogic/services/Logger';
import './styles.scss';

class HTMLCode extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Código HTML`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		htmlCode: `<div>Edita el código HTML</div>`,
	};
	static brickDefaultConfig = {};

	componentDidMount() {
		window.resizeIframe = (obj) => {
			obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
		};
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const htmlCode = data.htmlCode || '';

		const classes = classNames({
			'brick': true,
			'html-code-brick': true,
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<Logger.ErrorBoundary beforeCapture={() => Logger.setTag('codeBlock', 'htmlCodeBrick')}>
						<div dangerouslySetInnerHTML={{__html: htmlCode}} />
					</Logger.ErrorBoundary>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(HTMLCode);
