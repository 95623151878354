import React, {PureComponent} from 'react';
import classNames from 'classnames';
import Link from 'businessLogic/shared/Links/Link';
import {imageUrl} from 'businessLogic/helpers/images';
import {Query} from 'react-apollo';
import {ENTITY_QUERY} from 'businessLogic/store/data/entity/queries';
import './styles.scss';
import get from 'lodash/get';

class PlatformLogo extends PureComponent {
	render() {
		const classes = classNames({
			'platform-logo': true,
		});
		return (
			<Query query={ENTITY_QUERY}>
				{({data}) => {
					const logoImage = get(data, 'currentEntity.data.logoImage');
					if (!logoImage) {
						return null;
					}
					return (
						<div className={classes}>
							<Link to="/">
								<img src={imageUrl(logoImage)} alt={get(data, 'currentEntity.name')} />
							</Link>
						</div>
					);
				}}
			</Query>
		);
	}
}

export default PlatformLogo;
