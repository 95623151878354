import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import classNames from 'classnames';
import Response from './components/Response';
import BlurImage from './components/BlurImage';
import TimerBoard from './components/TimerBoard';
import {t} from 'businessLogic/scope/user/helper/userTtag';
import './styles.scss';

class Question extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			finished: false,
			result: {},
			multiSuccess: false,
			currentDecSeconds: 0,
		};
		this.successes = 0;
		this.failures = 0;
		this.totalSuccesses = this.getSolutions();
		this.coefSuccess = 0;
		this.animationFrameHandler = null;
		this.maxDuration = 100;
		this.delay = 30;
	}

	/*Recorre todas las opciones de respuesta de la pregunta, y devuelve el número de ellas con solución "verdadero"*/
	getSolutions = () => {
		let solutionSucces = 0;
		for (let i = 0; i < this.props.setResponses.length; i++) {
			if (this.props.setResponses[i].valueSolution === true) {
				solutionSucces++;
			}
		}
		return solutionSucces;
	};

	/*Se invoca cuando se considera que la pregunta está finalizada. Recoge los resultados y ejecuta el método onFinished declarado en la clase del Brick */
	handleFinishedQuestion = (duration) => {
		clearTimeout(this.goNextTimeoutHandler);

		this.goNextTimeoutHandler = setTimeout(() => {
			/*Contabilizar el tiempo tardado por el usuario en contestar, en el Coeficiente de acierto */
			this.handleSuccessTimer();
			this.props.onFinished(this.successes, this.failures, this.coefSuccess);
		}, duration);
	};

	/*Determina si la pregunta es multirespuesta */
	handleNoticeMultiSuccess = () => {
		if (this.totalSuccesses > 1) {
			this.setState({
				multiSuccess: true,
			});
		}
	};

	//Tener en cuenta el tiempo que tarda el usuario en contestar
	//Caso 1 -- No han pasado mas de 10s y se ha terminado de constestar, descontar al coeficiente de acierto el tiempo transcurrido.
	//Caso 2-- Si han pasado mas de 10s, la respuesta se da por finalizada y el coeficiente de puntuación es 0.
	handleSuccessTimer = () => {
		if (this.state.finished) {
			if (this.coefSuccess > 0) {
				// puntuación sobre 1 teniendo en cuenta el tiempo = -1/15 * tiempo +1
				// Equivale a decir que si tengo 15pt máximos por pregunta, acierto todas las preguntas pero con el marcador a 0, obtendré 5pt

				const coefTimer = (-1 / 15) * (0.1 * this.state.currentDecSeconds) + 1;
				this.coefSuccess = this.coefSuccess * coefTimer;
			}
		}
	};

	initTimeCounter = () => {
		this.start = 0;
		this.animationFrameHandler = window.requestAnimationFrame(this.checkTime);
	};

	checkTime = (timestamp) => {
		if (!this.start) {
			this.start = timestamp;
		}
		let currentDecSeconds = Math.floor((timestamp - this.start) * 0.01); //cuenta atrás

		this.setState((state) => {
			let newState = Object.assign({}, state);

			if (currentDecSeconds !== newState.currentDecSeconds) {
				if (currentDecSeconds >= 0 && currentDecSeconds < this.delay) {
					newState.currentDecSeconds = 0;
				} else {
					newState.currentDecSeconds = currentDecSeconds - this.delay;
				}
			}

			if (currentDecSeconds <= this.maxDuration + this.delay && !this.state.finished) {
				this.animationFrameHandler = window.requestAnimationFrame(this.checkTime);
			}
			return {
				currentDecSeconds: newState.currentDecSeconds,
			};
		});
	};

	/*Completar la pregunta para darla como finalizada en el caso de que a usuario se le acabe el tiempo */
	showSolutionTest = (result) => {
		for (let i = 0; i < this.props.setResponses.length; i++) {
			if (this.props.setResponses[i].valueSolution === true) {
				result[i] = this.props.setResponses[i].valueSolution === true;
			}
		}
	};

	handleClick = (responseItem, responseIndex) => {
		//"seteamos" el state tomando como base el state anterior utilizándolo como argumnento. Además nos aseguramos de que setState se dispare justo despues de cada actualización del state anterior
		this.setState((state) => {
			//Pass By Reference :  Necesitamos una nueva referencia para que react detecte el cambio de estado y lo vuelva a renderizar
			const newResult = Object.assign({}, state.result);

			// responseItem.valueSolution === true; Convertimos este dato en un boolean siempre.
			newResult[responseIndex] = responseItem.valueSolution === true;
			if (newResult[responseIndex] === true) {
				this.successes++;
				this.coefSuccess = this.coefSuccess + 1 / this.totalSuccesses;
			} else {
				this.failures++;
			}
			let responsesCompleted = state.finished;
			if (
				(this.totalSuccesses !== 0 && this.successes === this.totalSuccesses) ||
				this.failures > 0
			) {
				responsesCompleted = true;
				this.handleFinishedQuestion(2000);
			}
			return {
				result: newResult,
				finished: responsesCompleted,
			};
		});
	};

	componentDidMount() {
		this.handleNoticeMultiSuccess();
		if (this.props.active && !this.state.finished) {
			this.initTimeCounter();
		}
	}
	componentWillUnmount() {
		clearTimeout(this.goNextTimeoutHandler);
	}
	componentDidUpdate(prevProps) {
		this.totalSuccesses = this.getSolutions();
		if (this.props.active !== prevProps.active) {
			if (this.props.active && !this.state.finished) {
				this.initTimeCounter();
			}
		}
	}

	render() {
		const {index, activeIndex} = this.props;
		const mainStyleClass = 'discover-image__question';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			'swiper-slide': true,
		});
		return (
			<div className={classes}>
				{(activeIndex === index || activeIndex - 1 === index || activeIndex + 1 === index) && (
					<React.Fragment>
						<div className="row">
							<div className="col-xs-10 col-xs-offset-1 col-sm-12 col-sm-offset-0 col-md-6 col-md-offset-6 col-lg-offset-7 col-lg-5  order--md--2">
								<div className={mainStyleClass + '__statement'}>
									<FrontEditableText
										text={this.props.statement}
										fieldName="question"
										set={this.props.set}
									/>

									{this.state.multiSuccess && (
										<div className={mainStyleClass + '__notice'}>
											<p>{t`¡Ojo! Hay ${this.totalSuccesses} respuestas verdaderas`}</p>
										</div>
									)}
								</div>
							</div>
							<div className="col-xs-10 col-xs-offset-1   col-sm-12 col-sm-offset-0 col-md-6  col-lg-offset-1 order--md--1">
								<div className={mainStyleClass + '__image'}>
									<BlurImage
										imageSrc={this.props.imageStatement.imageSrc}
										timer={this.state.finished ? this.maxDuration : this.state.currentDecSeconds}
									/>
									<TimerBoard
										timer={
											this.state.currentDecSeconds === 0
												? 10
												: Math.floor(this.state.currentDecSeconds * 0.1)
										}
									/>
								</div>
							</div>
							<div className="col-xs-10 col-xs-offset-1 col-sm-12 col-sm-offset-0 col-md-6 col-md-offset-6 col-lg-offset-7 col-lg-5 responses-container">
								<div className={mainStyleClass + '__responses'}>
									{this.props.setResponses.map((responseItem, responseIndex) => {
										return (
											<Response
												key={responseIndex}
												text={responseItem.answer}
												onClick={() => {
													if (!this.state.finished) {
														this.handleClick(responseItem, responseIndex);
													}
												}}
												feedbackCorrect={this.state.result[responseIndex]}
												disable={this.state.finished}
												valueSolution={responseItem.valueSolution}
											/>
										);
									})}
								</div>
							</div>
						</div>
					</React.Fragment>
				)}
			</div>
		);
	}
}
Question.propType = {
	statement: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	imageStatement: PropTypes.object,
	setResponses: PropTypes.array,
	set: PropTypes.func,
	onFinished: PropTypes.func,
	active: PropTypes.bool,
};

export default Question;
