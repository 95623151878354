import React, {Component} from 'react';
import ButtonInTextBar from '../ButtonInTextBar';
import classNames from 'classnames';
import ColorSelector from 'businessLogic/shared/Selects/ColorSelector';
import './styles.scss';

class ToolBarReduced extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showTextColor: false,
			activeOptionIndex: -1,
			enterColorPicker: false,
		};
		this.colorPickerRef = React.createRef();
	}

	changeActiveOption = (OptionIndex) => {
		if (this.state.activeOptionIndex !== OptionIndex) {
			this.setState({activeOptionIndex: OptionIndex});
		} else {
			this.setState({
				activeOptionIndex: -1,
				showTextColor: false,
			});
		}
	};

	colorTextShowPicker = () => {
		if (this.state.showTextColor === false) {
			this.setState({
				showTextColor: true,
			});
		} else {
			this.setState({
				showTextColor: false,
			});
		}
	};
	componentDidUpdate() {
		if (
			this.props._thisTextEditor.state.showToolbar === false &&
			this.state.activeOptionIndex !== -1
		) {
			this.setState({
				activeOptionIndex: -1,
				showTextColor: false,
			});
		}
	}

	componentDidMount() {
		this.colorPickerHoverFunction();
	}
	UNSAFE_componentWillUpdate() {
		this.colorPickerHoverFunction();
	}

	colorPickerHoverFunction = () => {
		if (this.colorPickerRef.current) {
			this.colorPickerRef.current.addEventListener('mouseenter', this.colorPickerMouseEnter);
			this.colorPickerRef.current.addEventListener('mouseleave', this.colorPickerMouseOut);
		}
	};

	colorPickerHide = () => {
		this.setState({
			showTextColor: false,
		});
	};
	callColorPickerHide = () => {
		let _this = this;
		this.callColorPickerHideTimeOut = setTimeout(() => {
			_this.colorPickerHide();
		}, 1000);
	};
	colorPickerMouseEnter = () => {
		this.setState({enterColorPicker: true});
		if (this.callColorPickerHideTimeOut) clearTimeout(this.callColorPickerHideTimeOut);
	};
	colorPickerMouseOut = () => {
		this.setState({enterColorPicker: false});
		this.callColorPickerHide();
	};

	componentWillUnmount() {
		if (this.callColorPickerHideTimeOut) clearTimeout(this.callColorPickerHideTimeOut);
	}

	render() {
		const {_thisTextEditor} = this.props;
		const showTextColor = classNames({
			'show-picker': this.state.showTextColor,
		});

		/**Array con las opciones del toolbar */
		const toolbarOptions = [
			{
				type: 'heading',
				defaultIcon: 'text-bold',
				options: [
					{
						optionType: 'text-bold',
						function: _thisTextEditor.textBold,
						active: _thisTextEditor.state.format.bold,
						format: true,
					},
					{
						optionType: 'text-italic',
						function: _thisTextEditor.textItalic,
						active: _thisTextEditor.state.format.italic,
						format: true,
					},
					{
						optionType: 'text-underline',
						function: _thisTextEditor.textUnderline,
						active: _thisTextEditor.state.format.underline,
						format: true,
					},
				],
			},
			{
				type: 'align',
				defaultIcon: 'align-left',
				options: [
					{
						optionType: 'align-left',
						function: _thisTextEditor.alignLeft,
						active: _thisTextEditor.state.format.align,
						format: '',
					},
					{
						optionType: 'align-center',
						function: _thisTextEditor.alignCenter,
						active: _thisTextEditor.state.format.align,
						format: 'center',
					},
					{
						optionType: 'align-right',
						function: _thisTextEditor.alignRight,
						active: _thisTextEditor.state.format.align,
						format: 'right',
					},
					{
						optionType: 'align-justify',
						function: _thisTextEditor.alignJustify,
						active: _thisTextEditor.state.format.align,
						format: 'justify',
					},
				],
			},
			{
				type: 'list',
				defaultIcon: 'numeric-list',
				options: [
					{
						optionType: 'numeric-list',
						function: _thisTextEditor.listOrdered,
						active: _thisTextEditor.state.format.list,
						format: 'ordered',
					},
					{
						optionType: 'symbol-list',
						function: _thisTextEditor.listBullets,
						active: _thisTextEditor.state.format.list,
						format: 'bullet',
					},
				],
			},
			{
				type: 'color',
				defaultIcon: 'text-color',
			},
			{
				type: 'link',
				defaultIcon: 'text-link',
			},
			{
				type: 'wiki',
				defaultIcon: 'text-wikipedia',
			},
		];
		let toolbar = toolbarOptions.map((item, index) => {
			return (
				<li
					key={index}
					className={
						'text-editor-toolbar-reduced__content__option' +
						(this.state.activeOptionIndex === index ? ' active' : '')
					}
				>
					{item.options !== undefined && (
						<React.Fragment>
							<div onClick={() => this.changeActiveOption(index)}>
								<ButtonInTextBar iconName={item.defaultIcon} />
							</div>
							<ul className="text-editor-toolbar-reduced__content__option__submenu">
								{item.options.map((option, optionIndex) => (
									<li
										key={optionIndex}
										className="text-editor-toolbar-reduced__content__option__submenu__item"
									>
										{option.optionType !== 'text-color' && (
											<div onClick={option.function}>
												<ButtonInTextBar
													iconName={option.optionType}
													active={option.active === option.format}
												/>
											</div>
										)}
									</li>
								))}
							</ul>
						</React.Fragment>
					)}

					{item.type === 'color' && (
						<React.Fragment>
							<button className="te-clean-button" onClick={this.colorTextShowPicker}>
								<ButtonInTextBar
									iconName={item.defaultIcon}
									active={_thisTextEditor.state.format.color}
									className="color-text"
								/>
							</button>
							<div className={'color-picker ' + showTextColor} ref={this.colorPickerRef}>
								<ColorSelector
									color={_thisTextEditor.state.format.color}
									onSelectColor={_thisTextEditor.handleChangeTextColor}
									type={'text-color'}
								/>
							</div>
						</React.Fragment>
					)}
					{item.type === 'link' && (
						<button className="ql-link te-clean-button" onClick={_thisTextEditor.linkHandler}>
							<ButtonInTextBar
								iconName={item.defaultIcon}
								active={_thisTextEditor.state.format.myLink !== undefined}
								className="link"
							/>
						</button>
					)}
					{item.type === 'wiki' && (
						<button className="ql-wiki te-clean-button" onClick={_thisTextEditor.wikiHandler}>
							<ButtonInTextBar
								className="wiki"
								iconName={item.defaultIcon}
								active={_thisTextEditor.state.format.wiki !== false}
							/>
						</button>
					)}
				</li>
			);
		});

		return (
			<div
				className={
					'text-editor-toolbar text-editor-toolbar-reduced' +
					(_thisTextEditor.state.showToolbar === true ? ' show-toolbar' : '')
				}
				id={_thisTextEditor.randomID}
				ref="toolbarReduced"
			>
				<ul
					className={
						'text-editor-toolbar-reduced__content' +
						(this.state.activeOptionIndex >= 0 ? ' open-options ' : '')
					}
				>
					{toolbar}
				</ul>
			</div>
		);
	}
}

export default ToolBarReduced;
