import React from 'react';
import {useSlate} from 'slate-react';
import ToggleButton from '../basics/ToggleButton';
import WikiIcon from '@mui/icons-material/Comment';

import WikiDialog from './components/WikiDialog';

const AddWikiButton = () => {
	const [open, setOpen] = React.useState(false);
	const editor = useSlate();
	const {wrapWiki, unwrapWiki, getWiki, editWiki} = editor.helpers;

	const [wiki] = getWiki(editor);
	// console.log('wiki', wiki);

	return (
		<React.Fragment>
			<ToggleButton
				format="wiki"
				disabled={editor.selection == null}
				onClick={() => {
					setOpen(true);
				}}
				value="wiki"
				selected={wiki != null}
			>
				<WikiIcon />
			</ToggleButton>
			<WikiDialog
				open={open}
				onCancel={() => setOpen(false)}
				onAccept={(wikiData) => {
					if (wikiData) {
						if (!wiki) {
							wrapWiki(editor, wikiData);
						} else {
							editWiki(editor, wikiData);
						}
					}
					setOpen(false);
				}}
				onDelete={() => {
					unwrapWiki(editor);
					setOpen(false);
				}}
				defaultValue={wiki ? wiki.wikiData : ''}
			/>
		</React.Fragment>
	);
};

export default AddWikiButton;
