import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

class ProgressCircle extends PureComponent {
	constructor(props) {
		super(props);
		this.normalizedRadius = this.props.radius - this.props.strokeWidth;
		this.circumference = this.normalizedRadius * 2 * Math.PI;
	}

	render() {
		const styleProgress = {
			animationName: 'progressCircle',
			animationTimingFunction: 'linear',
			animationIterationCount: '1',
			animationDirection: 'normal',
			strokeDashoffset:
				this.circumference - (this.props.value / this.props.max) * this.circumference * 0.5,
		};

		return (
			<svg className="circle-progress" height={this.props.radius * 2} width={this.props.radius * 2}>
				{/* circle background */}
				<circle
					className="circle-progress__ring--background"
					strokeWidth="10"
					strokeLinecap="round"
					stroke="#979797"
					fill="transparent"
					r={this.normalizedRadius}
					cx={this.props.cx}
					cy={this.props.cy}
					strokeDasharray={this.circumference + ' ' + this.circumference}
				/>
				{/* circle progress */}
				<circle
					className="circle-progress__ring"
					strokeWidth={this.props.strokeWidth}
					strokeLinecap="round"
					stroke={this.props.strokeColor}
					fill={this.props.fillColor}
					r={this.normalizedRadius}
					cx={this.props.cx}
					cy={this.props.cy}
					strokeDasharray={this.circumference + ' ' + this.circumference}
					style={styleProgress}
				/>
			</svg>
		);
	}
}

ProgressCircle.propTypes = {
	radius: PropTypes.number,
	cx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	cy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	strokeWidth: PropTypes.number,
	strokeColor: PropTypes.string,
	fillColor: PropTypes.string,
	value: PropTypes.number,
	max: PropTypes.number,
};

export default ProgressCircle;
