import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import SeveralImages from 'businessLogic/core/shared/Image/SeveralImages';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import get from 'lodash/get';
import './styles.scss';

class ThreeImagesVertical extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Tres imágenes verticales`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
			},
		],
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};
	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && this.state.counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}
	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const images = data.images || [];

		const classes = classNames({
			'brick': true,
			'three-images-vertical-margin-brick': true,
		});

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ThreeImagesVertical']);

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<SeveralImages
						images={images}
						format="portrait"
						margin={true}
						effect={brickAnimation}
						delay={0.5}
						playAnimation={this.state.playEffect}
						set={(fieldPath, fieldData) => {
							//Propagamos la llamada al método set añadiendo mi nodo al path
							this.props.set(['images'].concat(fieldPath), fieldData);
						}}
						imgWidths={{
							mobileLandscape: 480,
							tabletPortrait: 768,
							tabletLandscape: 768,
							desktop: 768,
							desktop4k: 1220,
						}}
					/>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ThreeImagesVertical);
