import React, {PureComponent} from 'react';

import EditionBrickSwitch from '../../../editionBricks/EditionBrickSwitch';
// import UIDropDown from 'ui/scope/admin/DropDown/UIDropDown';
import './styles.scss';

class EditContent extends PureComponent {
	render() {
		return (
			<div className="edit-content">
				{this.props.title && <h3 className="brick-title">{this.props.title}</h3>}
				{this.props.helpText && <div className="brick-help-text">{this.props.helpText}</div>}
				{/* {this.props.helpText && (
					<UIDropDown
						initialHeight={20}
						title="¿Para qué sirve este ladrillo?"
						icon={true}
					>
						<p
							className="brick-help-text"
							dangerouslySetInnerHTML={{__html: this.props.helpText}}
						/>
					</UIDropDown>
				)} */}

				{this.props.editionFields.map((editionBrickStruct, index) => {
					return (
						<EditionBrickSwitch
							key={index}
							editionBrickStruct={editionBrickStruct}
							editionBrickData={this.props.brick}
							pillId={this.props.pillId}
							parentType={this.props.parentType}
							parentId={this.props.parentId}
						/>
					);
				})}
			</div>
		);
	}
}

export default EditContent;
