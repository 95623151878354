import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import ImageMapper from 'react-image-mapper';
import Icon from 'ui/shared/Icon';
import {imageUrl} from 'businessLogic/helpers/images';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

const modalRoot = document.getElementById('portals');

class EditImage extends Component {
	constructor(props) {
		super(props);
		this.containerEditImage = document.createElement('div');
		this.imageRef = React.createRef();
		this.isDivMounted = false;
	}
	mountDiv() {
		if (modalRoot && !this.isDivMounted) {
			modalRoot.appendChild(this.containerEditImage);
			this.isDivMounted = true;
		}
	}

	unmountDiv() {
		if (
			modalRoot &&
			this.isDivMounted &&
			this.containerEditImage &&
			modalRoot.contains(this.containerEditImage)
		) {
			modalRoot.removeChild(this.containerEditImage);
			this.isDivMounted = false;
		}
	}
	componentWillUnmount() {
		this.unmountDiv();
	}

	clickedOnImage = (capturedPosition) => {
		const positionX = capturedPosition.nativeEvent.layerX;
		const positionY = capturedPosition.nativeEvent.layerY;
		const imgClientWidth = this.imageRef.current.img.clientWidth;
		const imgClientHeight = this.imageRef.current.img.clientHeight;
		const percentagePositionX = ((positionX - 12) * 100) / imgClientWidth;
		const percentagePositionY = ((positionY - 12) * 100) / imgClientHeight;

		this.props.handleChangeCenterPosition(
			{x: percentagePositionX, y: percentagePositionY},
			this.props.srcImage,
			this.props.index,
		);
	};

	deleteCenterPoint = () => {
		this.props.handleChangeCenterPosition({x: 0, y: 0}, this.props.srcImage, this.props.index);
	};
	render() {
		const {show, srcImage, onHide, valueCenterPosition} = this.props;

		if (!show) {
			this.unmountDiv();
			return null;
		}

		const classes = classNames({
			'edit-image': true,
			'show': show,
		});

		let imagePosition;
		if (valueCenterPosition) imagePosition = valueCenterPosition;

		this.mountDiv();
		return ReactDOM.createPortal(
			<div className={classes}>
				<div className="edit-image__content">
					<div className="edit-image__content__header">
						<h2 className="edit-image__content__header__title">{t`Editar imagen`}</h2>
						<div className="edit-image__content__header__close" onClick={onHide}>
							<Icon name="close-modal" size="normal" />
						</div>
					</div>
					<div className="edit-image__content__body">
						<p className="edit-image__content__body__instructions">
							{t`Haz click en la imagen para seleccionar el punto de centrado. Esto te servirá para poder centrar la parte con mayor importancia de la imagen.`}
						</p>
						<p className="edit-image__content__body__instructions">
							{t`Si quieres eliminar el punto pulsa sobre él de nuevo.`}
						</p>
						<p className="edit-image__content__body__instructions">
							{t`Nota: Si no marcas ningún punto, la imagen se alineará al centro.`}
						</p>
						<div className="edit-image__content__body__img-wrapper">
							<ImageMapper
								src={imageUrl(srcImage)}
								onImageClick={(capturedPosition) => this.clickedOnImage(capturedPosition)}
								ref={this.imageRef}
								width={590}
							/>
							{imagePosition && imagePosition.y > 0 && imagePosition.x > 0 && (
								<div
									className="edit-image__content__body__img-wrapper__pointer"
									style={{top: imagePosition.y + '%', left: imagePosition.x + '%'}}
									onClick={this.deleteCenterPoint}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="edit-image__overlay" onClick={onHide} />
			</div>,
			this.containerEditImage,
		);
	}
}

EditImage.propTypes = {
	srcImage: PropTypes.string,
	onDelete: PropTypes.func,
	onReplace: PropTypes.func,
	onEdit: PropTypes.func,
	mainIcon: PropTypes.string,
	mainLabel: PropTypes.string,
	onClickMainButton: PropTypes.func,
	square: PropTypes.bool,
	landscape: PropTypes.bool,
	portrait: PropTypes.bool,
	positionImg: PropTypes.array,
	small: PropTypes.bool,
};

EditImage.defaultProps = {
	srcImage: '',
	onDelete: null,
	onReplace: null,
	onEdit: null,
	mainIcon: '',
	mainLabel: '',
	onClickMainButton: null,
	square: false,
	landscape: false,
	portrait: false,
	positionImg: null,
	small: false,
};

export default EditImage;
