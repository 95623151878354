import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Icon from 'ui/shared/Icon';
import PlatformLogo from 'businessLogic/shared/Layout/PlatformLogo';
import classNames from 'classnames';
import './styles.scss';

const modalRoot = document.getElementById('portals');

class LegalConditionsPopUP extends PureComponent {
	constructor(props) {
		super(props);
		this.containerLegalPopUp = document.createElement('div');
		this.isDivMounted = false;
	}

	mountDiv = () => {
		if (modalRoot && !this.isDivMounted) {
			modalRoot.appendChild(this.containerLegalPopUp);
			this.isDivMounted = true;
		}
	};
	componentWillUnmount() {
		if (modalRoot && this.isDivMounted && modalRoot.contains(this.containerLegalPopUp)) {
			modalRoot.removeChild(this.containerLegalPopUp);
		}
	}

	render() {
		const {text, show, onHide} = this.props;
		let classes = classNames({
			'legal-conditions-pop-up': true,
			'show': show,
		});

		this.mountDiv();
		return ReactDOM.createPortal(
			<div className={classes} onClick={onHide}>
				<React.Fragment>
					{show && (
						<div className="legal-conditions-pop-up__container" size="large">
							<div className="legal-conditions-pop-up__container__logo">
								<PlatformLogo />
							</div>
							<span className="legal-conditions-pop-up__container__close">
								<Icon name="close-modal" size="normal" />
							</span>
							<FrontEditableText
								className="legal-conditions-pop-up__container__text"
								fieldName="legalTextContent"
								text={text}
								editable={false}
								set={this.props.set}
							/>
						</div>
					)}
				</React.Fragment>
			</div>,
			this.containerLegalPopUp,
		);
	}
}

LegalConditionsPopUP.propTypes = {
	show: PropTypes.bool,
	onHide: PropTypes.func,
};

LegalConditionsPopUP.defaultProps = {
	show: false,
};

export default LegalConditionsPopUP;
