import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import Tooltip from 'businessLogic/shared/Tooltip';
import './styles.scss';

class InstructionTestBrick extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
		};
	}
	UNSAFE_componentWillMount() {
		document.addEventListener('mousedown', this.handleClick, false);
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false);
	}
	handleClick = () => {
		this.onShow();
	};
	onShow = () => {
		this.setState((prevState) => ({
			show: !prevState.show,
		}));
	};
	render() {
		const mainStyleClass = 'instruction-test-brick';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			[`${mainStyleClass + '--dark'}`]: this.props.theme === 'dark',
			[`${mainStyleClass + '--light'}`]: this.props.theme === 'light',
		});
		if (!this.props.textHelp) {
			return null;
		}
		return (
			<div className={classes} ref={(node) => (this.node = node)} onClick={this.handleClick}>
				<div className={mainStyleClass + '__trigger'}>
					<Icon name="info" size="normal" />
					{this.props.textLabel && (
						<span className={mainStyleClass + '__trigger__label'}>{this.props.textLabel}</span>
					)}
				</div>
				<Tooltip
					text={this.props.textHelp}
					theme={this.props.theme}
					className={mainStyleClass + '__tooltip'}
					position="right"
					show={this.state.show}
				/>
			</div>
		);
	}
}
InstructionTestBrick.propTypes = {
	textLabel: PropTypes.string,
	textHelp: PropTypes.string,
	theme: PropTypes.oneOf(['dark', 'light']),
};

export default InstructionTestBrick;
