import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import Instructions from 'businessLogic/core/shared/Edit/Instructions';
import './styles.scss';

class Select extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		let defaultValue = '';
		if (editionBrickStruct.defaultValue) {
			defaultValue = editionBrickStruct.defaultValue;
		}

		return defaultValue;
	};

	onChangeSelect = (event) => {
		this.props.setMainField(event.target.value);
	};
	render() {
		return (
			<div className="edition-brick-select">
				<label className="edition-brick-select__label">{this.props.editionBrickStruct.title}</label>
				{this.props.editionBrickStruct.instructions !== undefined && (
					<Instructions text={this.props.editionBrickStruct.instructions} />
				)}
				<select onChange={this.onChangeSelect} value={this.props.getMainField()}>
					{this.props.editionBrickStruct.options.map((option, index) => (
						<option key={index} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
			</div>
		);
	}
}

export default editionBrickWrapper(Select);
