import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import VisibleImageContext from 'businessLogic/contexts/VisibleImageContext';
import {
	PieChart,
	Pie,
	// Sector,
	Cell,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import editionFields from './edit.js';
// import {generateId} from 'businessLogic/helpers/functions';
import './styles.scss';

class PieDiagram extends PureComponent {
	// http://recharts.org/en-US/examples/PieChartWithCustomizedLabel
	static editionFields = editionFields;
	static brickName = () => t`Gráfico circular`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	// static canReload = true;
	static brickDefaultData = {
		title: t`Título del gráfico`,
		references: t`Referencias`,

		dataSheetPie: [
			{name: 'Columna A', value: 400},
			{name: 'Columna B', value: 300},
			{name: 'Columna C', value: 300},
			{name: 'Columna D', value: 200},
		],
		percentValues: true,
		// valuesInsidePie: false,
	};

	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
		barChartStyle: {
			colors: [
				'#31C6B8',
				'#687EDB',
				'#FEDC82',
				'#FA5F85',
				'#33CCFF',
				'#FF9900',
				'#FF3300',
				'#CC0000',
				'#990000',
				'#660000',
				'#330000',
				'#00FF00',
				'#00CC00',
				'#009900',
				'#006600',
				'#003300',
				'#00FFFF',
				'#FFCC00',
				'#FF6600',
				'#FF0000',
				'#000000',
			],
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			device: null,
			isVisible: false,
			isAnimationActive: true,
			showLine: !this.props.brick.data.valuesInsidePie,
		};
	}

	changeDevice = (device) => {
		if (this.state.device !== null) {
			if (this.state.device !== device) {
				this.setState({isAnimationActive: false}); //si cambia el device desactivamos la animación del gráfico
			}
		}
		this.setState({device: device});
	};

	handleVisibilityChange(isVisible) {
		if (isVisible && !this.state.isVisible) this.setState({isVisible: isVisible});
	}

	componentDidMount() {
		this.setState({showLine: !this.props.brick.data.valuesInsidePie});
	}

	render() {
		const colorsNum = PieDiagram.brickDefaultStyles.barChartStyle.colors.length;
		const data = this.props.brick.data;

		const styles = get(this.props, 'brick.styles', {});
		const mainStyleClass = 'pie-chart-brick';
		const classes = classNames({
			brick: true,
			[mainStyleClass]: true,
		});

		//queremos mostrar porcentajes o no
		let labelForPie;
		if (data.percentValues) {
			labelForPie = ({percent}) => {
				return `${(percent * 100).toFixed(0)}%`;
			};
		} else {
			labelForPie = true;
		}

		// //mostrar porcentaje en cada quesito
		// const RADIAN = Math.PI / 180;
		// const renderCustomizedLabel = ({
		// 	cx,
		// 	cy,
		// 	midAngle,
		// 	innerRadius,
		// 	outerRadius,
		// 	percent,
		// 	value,
		// }) => {
		// 	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		// 	const x = cx + radius * Math.cos(-midAngle * RADIAN);
		// 	const y = cy + radius * Math.sin(-midAngle * RADIAN);

		// 	let label;
		// 	if (data.percentValues) {
		// 		label = `${(percent * 100).toFixed(0)}%`;
		// 	} else {
		// 		label = value;
		// 	}

		// 	return (
		// 		<text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
		// 			{label}
		// 		</text>
		// 	);
		// };
		// //mostrar porcentaje en cada quesito

		// //queremos mostrar las etiquetas dentro del quesito o no
		// if (data.valuesInsidePie) {
		// 	labelForPie = renderCustomizedLabel;
		// }

		// const labelPercent = ({percent}) => {
		// 	return `${(percent * 100).toFixed(0)}%`;
		// };

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<VisibleImageContext.Consumer>
					{(visibleImageContext) => (
						<WindowSizeContext.Consumer>
							{({device}) => {
								this.changeDevice(device);
								return (
									<div className={classes}>
										<div className={mainStyleClass + '__title'}>
											<FrontEditableText fieldName="title" text={data.title} set={this.props.set} />
										</div>
										<div className={mainStyleClass + '__wrapperDiagram'}>
											<ResponsiveContainer aspect={1.78} id="pie-container">
												<PieChart>
													<Tooltip />
													<Legend verticalAlign="top" height={60} />
													{(this.state.isVisible || visibleImageContext.visibleImages) && (
														<Pie
															data={data.dataSheetPie}
															dataKey="value"
															// label={renderCustomizedLabel} //para el porcentaje en el centro del quesito
															// label // para poner el número fuera del quesito
															// labelLine={!this.props.brick.data.valuesInsidePie} //si queremos o no mostrar la línea fuera del quesito que apunta a la label
															label={labelForPie}
															animationDuration={2000}
															// animationBegin={2000}
															animationEasing="ease-in-out"
															isAnimationActive={this.state.isAnimationActive}
														>
															{/* crear los quesitos de cada color */}

															{data.dataSheetPie.map((entry, index) => (
																<Cell
																	key={index}
																	fill={get(
																		styles,
																		`barChartStyle.colors[${index % colorsNum}]`,
																		'#64af3f',
																	)}
																/>
															))}
														</Pie>
													)}
												</PieChart>
											</ResponsiveContainer>
										</div>
										<div className={mainStyleClass + '__references'}>
											<FrontEditableText
												fieldName="references"
												text={data.references}
												set={this.props.set}
											/>
										</div>
									</div>
								);
							}}
						</WindowSizeContext.Consumer>
					)}
				</VisibleImageContext.Consumer>
			</Container>
		);
	}
}

export default brickWrapper(PieDiagram);
