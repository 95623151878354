import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
// import 'react-quill/dist/quill.core.css';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import entityManager from 'businessLogic/services/EntityManager';
import {getColorObject} from 'businessLogic/helpers/functions';

class AccordionSmall extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Acordeón pequeño`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		blocks: [
			{
				// title: 'Dusue nobistamanurs saeamos',
				title: {
					slateValue: [
						{
							type: 'paragraph',
							children: [
								{
									text: 'Dusue nobistamanurs saeamos',
									bold: true,
								},
							],
						},
					],
					textValue: '<p><span style=""><strong>Dusue nobistamanurs saeamos</strong></span></p>',
				},
				text: 'Ma quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi as exerchita doloreperum quaectur, ut into te la nobitatur? Quiduciet, iunt ut am, nem nos aut molupiet etusamus, ut resed et fugiti a dolore pa vel istios es cus sed modipiderum re doluptat et aut qui amet ipsam vent inctoris quis sitatib uscidis que posam istios es cus sed modipiderum.Ibus, tet landis alissit odicima gnatiis doluptam sit quiatum quatem nos militas sint untestiae nonemquiam iuriae nonsequist ulparum sit et placcaborum quiasperio quis doluptis nemqui ommodita imporiorita doluptatibus ad quia audistia et rem reribust, sit harumquatem hil ium quodiction evelest pro eaturer namusciti optatur, qui con porum qui volupta quibust ipit as volorpor moluptius nus des et arcidelecto te estrum ese plitio consed que lam apist odi custis dissunt ibeatur, illanient mollanis ditatis earibus, quam in earions editasciti optatur, qui con porum qui.',
			},
		],
	};
	static brickDefaultConfig = {};

	constructor(props) {
		super(props);
		this.state = {
			expanded: null,
		};
	}

	handleChange = (panel) => (event, newExpanded) => {
		this.setState({expanded: newExpanded ? panel : false});
	};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const classes = classNames({
			'brick': true,
			'accordion-small-brick': true,
		});

		const blocks = data.blocks || [];

		const {highlightColor, lightFontColor, darkFontColor} = entityManager.getCustomStyles();

		const selectedBGColor = styles.accordionBGColor;

		const bgColorObject = selectedBGColor
			? getColorObject(selectedBGColor)
			: getColorObject(highlightColor);

		const bgColorRGB = bgColorObject.rgb;
		const innerBgColor = `rgba(${bgColorRGB.r}, ${bgColorRGB.g}, ${bgColorRGB.b}, ${
			bgColorRGB.a * 0.7
		})`;

		const textColorObject = bgColorObject.tinycolor.isDark()
			? getColorObject(lightFontColor)
			: getColorObject(darkFontColor);

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					{blocks.map((block, index) => {
						return (
							<Accordion
								key={index}
								expanded={this.state.expanded === 'panel' + index}
								onChange={this.handleChange('panel' + index)}
								sx={{
									// 'backgroundColor': bgColorObject.hex,
									'color': textColorObject.hex,
									'marginBottom': '5px',
									'& .MuiAccordionSummary-expandIconWrapper': {
										color: textColorObject.hex,
									},
									'overflow': 'hidden',
									// '&:before': {
									// 	backgroundColor: bgColorObject.hex,
									// },
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1-content"
									id="panel1-header"
									sx={{
										'backgroundColor': bgColorObject.hex,
										'&:before': {
											backgroundColor: bgColorObject.hex,
										},
										'overflow': 'hidden',
									}}
								>
									<FrontEditableText
										fieldName="title"
										text={block.title}
										set={(fieldPath, fieldData) => {
											//Propagamos la llamada al método set añadiendo mi nodo al path
											if (this.props.set)
												this.props.set(['blocks', index].concat(fieldPath), fieldData);
										}}
									/>
								</AccordionSummary>
								<AccordionDetails
									sx={{
										backgroundColor: innerBgColor,
									}}
								>
									<FrontEditableText
										fieldName="text"
										text={block.text}
										set={(fieldPath, fieldData) => {
											//Propagamos la llamada al método set añadiendo mi nodo al path
											if (this.props.set)
												this.props.set(['blocks', index].concat(fieldPath), fieldData);
										}}
									/>
								</AccordionDetails>
							</Accordion>
						);
					})}

					{/* <div className={classes + '__title'}>
						<FrontEditableText fieldName="title" text={data.title} set={this.props.set} />
					</div>

					<div className={classes + '__text'}>
						<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
					</div> */}
				</div>
			</Container>
		);
	}
}
export default brickWrapper(AccordionSmall);
