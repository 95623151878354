import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'CustomColor',
						name: t`Color del separador`,
						fieldName: ['containerStyle', 'generalColor'],
					},
				],
			},
		],
	},
];

export default editionFields;
