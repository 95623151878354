import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import StylesOptionsWrapper from '../StylesOptionsWrapper';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class PaddingTop extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	static getDefaultValue = () => {
		const defaultValue = {
			paddingTop: 0,
			paddingBottom: 0,
		};

		return defaultValue;
	};

	handleChangePaddingTop = (event) => {
		this.props.setMainField(event.target.value);
	};

	render() {
		const paddingTop = this.props.getMainField();

		return (
			<StylesOptionsWrapper>
				<label className="item-title padding-item">{t`Padding superior`}</label>
				<input
					className="padding-input"
					type="number"
					name="paddingTop"
					value={paddingTop}
					step="5"
					placeholder={t`Aumenta o disminuye el padding`}
					onChange={this.handleChangePaddingTop}
				/>
			</StylesOptionsWrapper>
		);
	}
}

export default editionBrickWrapper(PaddingTop);
