import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import ImageComp from 'businessLogic/core/shared/Image/Image';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import brickImage2 from './img/brickPreview2.png';
import editionFields from './edit.js';
import LayerMoreInfo from 'businessLogic/core/shared/Modals/LayerMoreInfo';
import LayerTrigger from 'businessLogic/core/shared/Buttons/LayerTrigger';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import './styles.scss';

class ShowInfoOneImage extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Más información con una imagen`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickImage2 = brickImage2;
	static brickDefaultData = () => ({
		image: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
			footer: '',
		},
		buttonText: _('Más información'),
	});
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};
	constructor(props) {
		super(props);
		this.state = {
			showMoreInfo: false,
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}
	showInfo = () => {
		this.setState({
			showMoreInfo: true,
		});

		this.props.brickEvent('showInfo');
	};
	hideInfo = () => {
		this.setState({
			showMoreInfo: false,
		});

		this.props.brickEvent('hideInfo');
	};
	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const imageObj = data.image;

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ShowInfoOneImage']);

		const classes = classNames({
			'brick': true,
			'show-info-one-image-brick': true,
		});
		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<Animation playAnimation={this.state.playEffect} delay={1} effect={brickAnimation}>
						<div className="show-info-one-image-brick__item">
							<ImageComp imgObj={imageObj} clickable={false} />
							<LayerTrigger onShow={this.showInfo} buttonText={data.buttonText} />
						</div>
					</Animation>
					{(imageObj.credit || imageObj.footer) && (
						<div className="image-brick__caption">
							{imageObj.footer && (
								<div className="image-brick__caption__footer"> {imageObj.footer} </div>
							)}
							{imageObj.credit && (
								<div className="image-brick__caption__credit"> {imageObj.credit} </div>
							)}
						</div>
					)}
				</div>
				<LayerMoreInfo
					show={this.state.showMoreInfo}
					onClose={this.hideInfo}
					mainProps={this.props}
					editMode={this.props.editMode}
				/>
			</Container>
		);
	}
}
export default brickWrapper(ShowInfoOneImage);
