import CourseStateManager from './CourseStateManager';
import Logger from 'businessLogic/services/Logger';
import {client} from 'businessLogic/store/store';
import eventQueries from 'businessLogic/store/data/events/queries';
import {isLogged, getDataUser} from 'businessLogic/store/data/session';
import store from 'businessLogic/store/store';
import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';

class CsCourseStateManager extends CourseStateManager {
	onPillStateDataUpdate = (cache, {data: {setPillStateData}}) => {
		cache.writeQuery({
			query: eventQueries.GET_PILL_STATE_DATA,
			variables: {
				pillId: setPillStateData.pillId,
				userId: this.getLoggedUserId(),
			},
			data: {pillStateData: setPillStateData},
		});
	};

	isUserLogged() {
		return isLogged(store.getState());
	}

	getLoggedUserId() {
		const loggedUser = getDataUser(store.getState());
		return loggedUser?.id;
	}

	async initCourseSession(course) {
		this.setCourse(course);
		this.sessionStartTime = new Date();
	}

	async endCourseSession() {
		//TODO: Añadir duración al evento
		if (this.course) {
			// Dejamos esta linea comentada hasta que podamos dedicarle tiempo para buscar una solución,
			// ya que al entrar en un curso aún que no se matricule el alumno, se está seteando un stateData
			// al salir del curso y aparece como si ya lo hubiera empezado
			// await this.setPillData(this.course.id, "close", true);
		}

		this.setCourse(null);

		//TODO: Actualizar tiempo total al estado
	}

	async updateCache() {
		if (!this.isUserLogged() || !this.course) return;

		await client.query({
			query: eventQueries.GET_PILL_STATE_DATA,
			variables: {
				pillId: this.course.id,
				userId: this.getLoggedUserId(),
			},
			fetchPolicy: 'network-only',
		});
	}

	async setCourseCompletionStatus(completionStatus) {
		if (!this.isUserLogged() || !this.course) return;
		try {
			await client.mutate({
				mutation: eventQueries.SET_PILL_STATE_DATA,
				variables: {
					pillId: this.course.id,
					data: {completionStatus},
					userId: this.getLoggedUserId(),
				},
				update: this.onPillStateDataUpdate,
			});
		} catch (e) {
			Logger.captureException(e);
		}
	}

	async getCourseCompletionStatus() {
		if (!this.isUserLogged() || !this.course) {
			return 'not attempted';
		}

		const queryResult = await client.query({
			query: eventQueries.GET_PILL_STATE_DATA,
			variables: {
				pillId: this.course.id,
				userId: this.getLoggedUserId(),
			},
		});
		const pillStateData = get(queryResult, 'data.pillStateData');
		if (!pillStateData) {
			return 'not attempted';
		}
		return get(pillStateData, 'data.status.completed', false) ? 'completed' : 'incomplete';
	}

	async setCourseSuccessStatus(successStatus) {
		if (!this.isUserLogged() || !this.course) return;
		try {
			await client.mutate({
				mutation: eventQueries.SET_PILL_STATE_DATA,
				variables: {
					pillId: this.course.id,
					data: {successStatus},
					userId: this.getLoggedUserId(),
				},
				update: this.onPillStateDataUpdate,
			});
		} catch (e) {
			Logger.captureException(e);
		}
	}

	async getCourseSuccessStatus() {
		if (!this.isUserLogged() || !this.course) {
			return 'unknown';
		}

		const queryResult = await client.query({
			query: eventQueries.GET_PILL_STATE_DATA,
			variables: {
				pillId: this.course.id,
				userId: this.getLoggedUserId(),
			},
		});
		return get(queryResult, 'data.pillStateData.data.status.success', false) ? 'unknown' : 'passed';
	}

	async setCourseCurrentLocation(location) {
		if (!this.isUserLogged() || !this.course) return;
		try {
			await client.mutate({
				mutation: eventQueries.SET_PILL_STATE_DATA,
				variables: {
					pillId: this.course.id,
					data: {location},
					userId: this.getLoggedUserId(),
				},
				update: this.onPillStateDataUpdate,
			});
		} catch (e) {
			Logger.captureException(e);
		}
	}

	async getCourseCurrentLocation() {
		const defaultData = '';
		if (!this.isUserLogged() || !this.course) {
			return defaultData;
		}

		const queryResult = await client.query({
			query: eventQueries.GET_PILL_STATE_DATA,
			variables: {
				pillId: this.course.id,
				userId: this.getLoggedUserId(),
			},
		});
		return get(queryResult, 'data.pillStateData.data.location', defaultData);
	}

	async setCourseProgress(progress, courseId) {
		if (!this.isUserLogged()) return;

		const defCourseId = courseId || this.course?.id;

		if (!defCourseId) return;

		const prevProgress = await this.getCourseProgress();

		if (progress === prevProgress) return;

		try {
			await client.mutate({
				mutation: eventQueries.SET_PILL_STATE_DATA,
				variables: {
					pillId: defCourseId,
					data: {status: {progress}},
					userId: this.getLoggedUserId(),
				},
				update: this.onPillStateDataUpdate,
			});
		} catch (e) {
			Logger.captureException(e);
		}
	}

	async getCourseProgress() {
		const defaultData = 0;
		if (!this.isUserLogged() || !this.course) {
			return defaultData;
		}

		const queryResult = await client.query({
			query: eventQueries.GET_PILL_STATE_DATA,
			variables: {
				pillId: this.course.id,
				userId: this.getLoggedUserId(),
			},
		});
		return get(queryResult, 'data.pillStateData.data.status.progress', defaultData);
	}

	async setCourseScore(score, courseId) {
		if (!this.isUserLogged()) return;

		const defCourseId = courseId || this.course?.id;

		if (!defCourseId) return;

		const prevScore = await this.getCourseScore();

		if (isEqual(score, prevScore)) return;

		// const {scaled, raw, min, max} = score;
		try {
			await client.mutate({
				mutation: eventQueries.SET_PILL_STATE_DATA,
				variables: {
					pillId: defCourseId,
					data: {status: {score}},
					userId: this.getLoggedUserId(),
				},
				update: this.onPillStateDataUpdate,
			});
		} catch (e) {
			Logger.captureException(e);
		}
	}

	async getCourseScore() {
		const defaultData = {
			scaled: 0,
			raw: 0,
			min: 0,
			max: 0,
		};
		if (!this.isUserLogged() || !this.course) {
			return defaultData;
		}

		const queryResult = await client.query({
			query: eventQueries.GET_PILL_STATE_DATA,
			variables: {
				pillId: this.course.id,
				userId: this.getLoggedUserId(),
			},
		});
		return get(queryResult, 'data.pillStateData.data.status.score', defaultData);
	}

	async finishCourse() {}

	async setPillData(pillId, path, value) {
		if (!this.isUserLogged()) return;
		const data = {};
		set(data, path, value);

		try {
			await client.mutate({
				mutation: eventQueries.SET_PILL_STATE_DATA,
				variables: {
					pillId,
					data,
					userId: this.getLoggedUserId(),
				},
				update: this.onPillStateDataUpdate,
			});
		} catch (e) {
			Logger.captureException(e);
		}

		//Publicamos el cambio
		this.publishToPillData(pillId, data);
	}

	async getPillData(pillId, path, defaultData) {
		if (!this.isUserLogged()) {
			return defaultData;
		}

		const queryResult = await client.query({
			query: eventQueries.GET_PILL_STATE_DATA,
			variables: {
				pillId,
				userId: this.getLoggedUserId(),
			},
		});
		const data = get(queryResult, 'data.pillStateData.data', {});
		return path ? get(data, path, defaultData) : data;
	}

	async getUpdatedPillData(pillId, path, defaultData) {
		if (!this.isUserLogged()) {
			return defaultData;
		}

		// console.log('getUpdatedPillData', pillId);

		const queryResult = await client.query({
			query: eventQueries.GET_PILL_STATE_DATA,
			variables: {
				pillId,
				userId: this.getLoggedUserId(),
				recalculateIfNeeded: true,
			},
			fetchPolicy: 'network-only',
		});

		const pillStateData = get(queryResult, 'data.pillStateData', {});
		// console.log('pillStateData', pillStateData);
		await client.writeQuery({
			query: eventQueries.GET_PILL_STATE_DATA,
			variables: {
				pillId,
				userId: this.getLoggedUserId(),
			},
			data: {pillStateData},
		});

		//Publicamos el cambio
		this.publishToPillData(pillId, pillStateData);

		const data = get(queryResult, 'data.pillStateData.data', {});
		return path ? get(data, path, defaultData) : data;
	}

	async setBrickData(pillId, brickId, path, value) {
		if (!this.isUserLogged()) return;
		let data = {};
		if (path) {
			set(data, path, value);
		} else {
			data = value;
		}

		await client.mutate({
			mutation: eventQueries.SET_BRICK_STATE_DATA,
			variables: {
				pillId,
				brickId,
				data,
				userId: this.getLoggedUserId(),
			},
			update: (cache, {data: {setBrickStateData}}) => {
				cache.writeQuery({
					query: eventQueries.GET_BRICK_STATE_DATA,
					variables: {
						pillId: setBrickStateData.pillId,
						brickId: setBrickStateData.brickId,
						userId: this.getLoggedUserId(),
					},
					data: {brickStateData: setBrickStateData},
				});
			},
		});

		//Publicamos el cambio
		this.publishToBrickData(pillId, brickId, data);
	}

	async getBrickData(pillId, brickId, path, defaultData) {
		if (!this.isUserLogged()) {
			return defaultData;
		}

		const queryResult = await client.query({
			query: eventQueries.GET_BRICK_STATE_DATA,
			variables: {
				pillId,
				brickId,
				userId: this.getLoggedUserId(),
			},
		});
		const data = get(queryResult, 'data.brickStateData.data', {});
		return path ? get(data, path, defaultData) : data;
	}
}

export default CsCourseStateManager;
