import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

class PointScale extends PureComponent {
	render() {
		const mainStyleClass = 'point-scale';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			[`${mainStyleClass + '__passed'}`]: this.props.passed,
		});
		return (
			<div className={classes} style={{left:this.props.posX + '%'}}>
				<span className={mainStyleClass + '__dot'} />
				{this.props.point !== undefined && (
					<span className={mainStyleClass + '__number'}>{this.props.point}</span>
				)}
			</div>
		);
	}
}
PointScale.propTypes = {
	point: PropTypes.number,
	posX: PropTypes.number,
	passed: PropTypes.bool
};
export default PointScale;
