import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MaximizedImage from './components/MaximizedImage';
import PillConfigContext from 'businessLogic/contexts/PillConfigContext';
import VisibleImageContext from 'businessLogic/contexts/VisibleImageContext';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import {imageUrl, getDeviceWidth} from 'businessLogic/helpers/images';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

/**
 * Componente para imagen, tendrá la imagen de fondo y podrá tener diferentes proporciones y punto de centrado
 */

class NewImageClickable extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			openedImage: false,
			isVisible: this.props.defaultVisible,
		};

		this.containerRef = React.createRef();
	}
	changeVisibility = (isVisible) => {
		if (isVisible) {
			this.setState({isVisible});
		}
	};
	onClick = () => {
		this.setState({openedImage: true});
	};
	closeImage = () => {
		this.setState({openedImage: false});
	};
	componentDidMount() {
		this.props.configObject.observeImage(this.containerRef.current, this.changeVisibility);
	}
	componentWillUnmount() {
		this.props.configObject.unobserveImage(this.containerRef.current);
	}
	render() {
		const {className, imgObj, clickable, showCaption} = this.props;
		const classes = classNames({
			'image-clickable-cmp': true,
			'image-clickable-cmp--clickable': clickable,
			'image-clickable-cmp--with-caption':
				showCaption && imgObj && (imgObj.footer || imgObj.credit),
			[`${className}`]: true,
		});

		const divStyle = {};

		if (this.state.isVisible) {
			divStyle.backgroundImage =
				'url("' +
				imageUrl(imgObj?.imageSrc, getDeviceWidth(this.props.device, this.props.imgWidths)) +
				'")';
		}
		if (
			imgObj &&
			imgObj.centerPosition &&
			imgObj.centerPosition.x > 0 &&
			imgObj.centerPosition.y > 0
		) {
			divStyle.backgroundPosition = imgObj.centerPosition.x + '% ' + imgObj.centerPosition.y + '%';
		}

		return (
			<div className={classes} ref={this.containerRef}>
				{clickable ? (
					<React.Fragment>
						<div
							className="image-clickable-cmp__image"
							style={divStyle}
							onClick={this.onClick}
							title={t`Ampliar Imagen`}
						></div>
						<MaximizedImage
							open={this.state.openedImage}
							onClose={this.closeImage}
							imgObj={imgObj}
						/>
					</React.Fragment>
				) : (
					<div className="image-clickable-cmp__image" style={divStyle} />
				)}

				{showCaption && (imgObj.credit || imgObj.footer) && (
					<div className="image-clickable-cmp__caption">
						{imgObj.footer && (
							<div className="image-clickable-cmp__caption__footer"> {imgObj.footer} </div>
						)}
						{imgObj.credit && (
							<div className="image-clickable-cmp__caption__credit"> {imgObj.credit} </div>
						)}
					</div>
				)}
			</div>
		);
	}
}

NewImageClickable.propTypes = {
	className: PropTypes.string,
	imgObj: PropTypes.object,
	clickable: PropTypes.bool,
	defaultVisible: PropTypes.bool,
	device: PropTypes.string,
	imgWidths: PropTypes.object, //Objeto con el ancho asociado a cada dispositivo
	showCaption: PropTypes.bool,
};

NewImageClickable.defaultProps = {
	className: '',
	imgObj: {},
	clickable: true,
	defaultVisible: false,
	device: 'desktop',
	imgWidths: {},
	showCaption: false,
};

export default (props) => (
	<PillConfigContext.Consumer>
		{(configObject) => (
			<VisibleImageContext.Consumer>
				{(visibleImageContext) => (
					<WindowSizeContext.Consumer>
						{({device}) => (
							<NewImageClickable
								configObject={configObject}
								defaultVisible={props.defaultVisible || visibleImageContext.visibleImages}
								device={device}
								{...props}
							/>
						)}
					</WindowSizeContext.Consumer>
				)}
			</VisibleImageContext.Consumer>
		)}
	</PillConfigContext.Consumer>
);
