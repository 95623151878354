import {PUSH, REPLACE, GO, GO_BACK, GO_FORWARD} from 'businessLogic/store/data/router';

const getUrlObject = (relUrl) => {
	const urlParts = relUrl.split('?');

	return {pathname: urlParts[0], search: urlParts[1] ? `?${urlParts[1]}` : undefined};
};

/*
ACCIONES: Captura las acciones relacionadas con la URL
LÓGICA: Con el history modifica la URL en base a la tipo de acción que haya llegado
PROPAGACIÓN: Al pasar por una de la acciones corta la acción (ya que no hay
	next(action)) para que no se ejecute ninguna action del reducer router
*/
export const routerMiddleware = (history) => () => (next) => (action) => {
	switch (action.type) {
		case PUSH:
			history.push(getUrlObject(action.payload.href), {
				omitRender: action.payload.omitRender,
			});
			break;
		case REPLACE:
			history.replace(getUrlObject(action.payload.href), {
				omitRender: action.payload.omitRender,
			});
			break;
		case GO:
			history.go(action.payload);
			break;
		case GO_BACK:
			history.goBack();
			break;
		case GO_FORWARD:
			history.goForward();
			break;
		default:
			return next(action);
	}
};
