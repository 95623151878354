import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ResponseTest from './components/ResponseTest';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import classNames from 'classnames';
import './styles.scss';

class QuestionTest extends Component {
	render() {
		const {index, activeIndex} = this.props;
		const counter = 'ABCD';
		const classes = classNames({
			'test-personality-images-brick__question-slide': true,
			'swiper-slide': true,
		});

		return (
			<div className={classes}>
				{(activeIndex === index || activeIndex - 1 === index || activeIndex + 1 === index) && (
					<React.Fragment>
						<div className="row">
							<div className="col-xs-10 col-xs-offset-1 col-sm-12 col-sm-offset-0 col-lg-6 col-lg-offset-3 ">
								<div className="test-personality-images-brick__question-slide__question ">
									<FrontEditableText
										text={this.props.textQuestion}
										fieldName="question"
										set={this.props.set}
									/>
								</div>
							</div>

							<div className="col-xs-12">
								<div className="test-personality-images-brick__question-slide__answers">
									{this.props.responsesQuestion.map((responseItem, responseIndex) => (
										<ResponseTest
											key={responseIndex}
											image={responseItem.answerImageObj}
											text={responseItem.answer}
											optionLetter={counter[responseIndex]}
											onClick={() => {
												this.props.onClick(responseIndex);
											}}
											active={this.props.responseActive === responseIndex}
										/>
									))}
								</div>
							</div>
						</div>
					</React.Fragment>
				)}
			</div>
		);
	}
}

QuestionTest.propTypes = {
	textQuestion: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	responsesQuestion: PropTypes.array,
	onClick: PropTypes.func,
	responseActive: PropTypes.number,
	set: PropTypes.func,
};

export default QuestionTest;
