import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import Instructions from 'businessLogic/core/shared/Edit/Instructions';
// import {t} from 'businessLogic/scope/admin/helper/adminTtag';

import {StylesProvider} from '@material-ui/core/styles';
import {DateTimePicker} from '@material-ui/pickers';

import './styles.scss';

class DateTimeSelector extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		let defaultValue = '';
		if (editionBrickStruct.defaultValue) {
			defaultValue = editionBrickStruct.defaultValue;
		}

		return defaultValue;
	};

	onChange = (dateTime) => {
		this.props.setMainField(dateTime.toString());
	};
	render() {
		const stringDate = this.props.getMainField();
		const date = new Date(stringDate);

		return (
			<div className="edition-brick-text">
				<label className="edition-brick-text__label">{this.props.editionBrickStruct.title}</label>
				{this.props.editionBrickStruct.instructions !== undefined && (
					<Instructions text={this.props.editionBrickStruct.instructions} />
				)}
				<div className="edition-brick-text__input">
					{/* Styles provider con injectFirst es necesario para que los estilos de la 
							versión antigua	no interfieran con los de la nueva en los botones de abajo. 
							Si no se pone, hay un escenario donde el botón del diálogo pierde los estilos.
							Para reproducirlo hay que abrir el diálogo, ver el calendario, salir al listado 
							de píldoras	y volver a mostrar el calendario. */}
					<StylesProvider injectFirst>
						<DateTimePicker
							autoOk
							// disablePast
							ampm={false}
							value={date}
							onChange={this.onChange}
							// label={t`Inicio`}
							format={'Pp'}
							// className={classes.dateTimeSelector}
							variant="inline"
						/>
					</StylesProvider>
				</div>
			</div>
		);
	}
}

export default editionBrickWrapper(DateTimeSelector);
