import {client} from 'businessLogic/store/store';
import {SIGNUP_MUTATION, SET_PUSH_TOKEN, REMOVE_PUSH_TOKEN} from './query';
import Logger from 'businessLogic/services/Logger';

export function signUpMutation(email, password, name, customFields) {
	return client
		.mutate({
			variables: {
				email,
				password,
				name,
				customFields,
			},
			mutation: SIGNUP_MUTATION,
		})
		.then((data) => {
			return data.data.signup;
		})
		.catch((error) => {
			Logger.captureException(error);
			alert(
				'No se ha creado correctamente el usuario, se ha producido algún problema, actualice de nuevo la página cargandola de nuevo y vuelva a intentarlo.',
			);
		});
}

export function setPushToken(pushToken, pushProvider) {
	return client.mutate({
		mutation: SET_PUSH_TOKEN,
		variables: {pushToken, pushProvider},
	});
}

export function removePushToken(pushToken) {
	return client.mutate({
		mutation: REMOVE_PUSH_TOKEN,
		variables: {pushToken},
	});
}
