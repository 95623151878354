import React, {PureComponent} from 'react';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import './styles.scss';

class AccordionItem extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			height: 0
		};

		this.contentRef = React.createRef();
	}

	calcultateHeight = () => {
		let contentHeight = this.contentRef.current.offsetHeight;
		this.setState({height: contentHeight});
	};

	onHandleClick = () => {
		this.calcultateHeight();
		this.props.onHandle(this.props.index);
	};
	componentDidUpdate() {
		this.calcultateHeight();
	}
	render() {
		const {accordionProps, active, title, parentProps} = this.props;

		const classes = classNames({
			'accordion-item': true,
			'active': active
		});
		return (
			<li className={classes} style={{height: active ? this.state.height + 30 + 'px' : '61px'}}>
				<div className="accordion-item__title" onClick={this.onHandleClick}>
					<p>{title}</p>
					<Icon name="navigate-down" size="normal" />
				</div>
				<div className="accordion-item__content" ref={this.contentRef}>
					{parentProps.renderChildren(accordionProps.content)}
				</div>
			</li>
		);
	}
}

export default AccordionItem;
