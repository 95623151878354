import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import PropTypes from 'prop-types';
import './styles.scss';

class VideoTimer extends PureComponent {
	render() {
		const {time} = this.props;
		return (
			<div className="video-timer" ref="videoTimeContainer">
				<span ref="videoTime">{time}</span>
			</div>
		);
	}
}

VideoTimer.propTypes = {
	time: PropTypes.string
};

export default brickWrapper(VideoTimer);
