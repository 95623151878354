import React, {Component} from 'react';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.core.css';
import PropTypes from 'prop-types';
import ToolBar from './components/ToolBar';
import EditOptions from './components/EditOptions';
import ToolBarReduced from './components/ToolBarReduced';
import WikiDialog from './components/WikiDialog';
import LinkDialog from './components/LinkDialog';
import PlainClipboard from './modules/PlainClipboard';
import WikiBlot from './modules/WikiBlot';
import TextLinkBlot from './modules/TextLinkBlot';
import './styles.scss';

// Module para poder pegar un texto sin formato
Quill.register('modules/clipboard', PlainClipboard, true);
// Module para el wiki
Quill.register(WikiBlot);
// Module para el link
Quill.register(TextLinkBlot);

const defaultFormat = {
	bold: false,
	italic: false,
	underline: false,
	align: '',
	list: false,
	link: false,
	wiki: false,
	header: 0,
};

class TextEditor extends Component {
	static emptyObject = {
		textValue: '<p></p>',
		deltaValue: {
			ops: [
				{
					insert: '',
				},
			],
		},
	};
	constructor(props) {
		super(props);
		this.state = {
			editorHtml: '',
			format: defaultFormat,
			selectionOptionsVisible: false,
			selectionTop: 0,
			selectionLeft: 0,
			selectionWidth: 0,
			selectionHeight: 0,
			showToolbar: false,
			reloadTextValue: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.makeID = this.makeID.bind(this);
		this.randomID = this.makeID();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.text !== this.props.text) return true;
		if (nextProps.reducedVersion !== this.props.reducedVersion) return true;
		if (nextProps.repaintAlways) return true;

		if (nextState.editorHtml !== this.state.editorHtml) return true;
		if (nextState.selectionOptionsVisible !== this.state.selectionOptionsVisible) return true;
		if (nextState.selectionTop !== this.state.selectionTop) return true;
		if (nextState.selectionLeft !== this.state.selectionLeft) return true;
		if (nextState.selectionWidth !== this.state.selectionWidth) return true;
		if (nextState.selectionHeight !== this.state.selectionHeight) return true;
		if (nextState.showToolbar !== this.state.showToolbar) return true;
		if (nextState.reloadTextValue !== this.state.reloadTextValue) return true;

		if (nextState.format.bold !== this.state.format.bold) return true;
		if (nextState.format.italic !== this.state.format.italic) return true;
		if (nextState.format.underline !== this.state.format.underline) return true;
		if (nextState.format.align !== this.state.format.align) return true;
		if (nextState.format.list !== this.state.format.list) return true;
		if (nextState.format.myLink !== this.state.format.myLink) return true;
		if (nextState.format.wiki !== this.state.format.wiki) return true;
		if (nextState.format.header !== this.state.format.header) return true;
		if (nextState.format.color !== this.state.format.color) return true;

		return false;
	}

	componentDidMount() {
		this.attachQuillRefs();
		let _this = this;
		let textEditor = this.refs.textEditor;
		textEditor.addEventListener('click', function () {
			_this.setState({
				showToolbar: true,
			});
		});
		this.quillEditor.on('selection-change', function (range) {
			if (range !== null) {
				if (range.length === 0) {
					var blot = _this.quillEditor.getLeaf(range.index);
					let format = _this.quillEditor.getFormat(range.index, range.length);
					if (format.hasOwnProperty('wiki') || format.hasOwnProperty('myLink')) {
						_this.setState({
							selectionOptionsVisible: true,
							selectionTop: blot[0].domNode.parentNode.offsetTop,
							selectionLeft: blot[0].domNode.parentNode.offsetLeft,
							selectionWidth: blot[0].domNode.parentNode.offsetWidth,
							selectionHeight: blot[0].domNode.parentNode.offsetHeight,
						});
					} else {
						_this.setState({
							selectionOptionsVisible: false,
							showToolbar: false,
						});
						setTimeout(function () {
							_this.setState({
								selectionTop: 0,
								selectionLeft: 0,
								selectionWidth: 0,
								selectionHeight: 0,
							});
						}, 200);
					}
				}
			} else {
				_this.setState({
					selectionOptionsVisible: false,
					showToolbar: false,
				});
			}
		});
	}

	componentDidUpdate() {
		this.attachQuillRefs();
	}

	//Asocia el editor Quill a una variable de entorno de la clase para poder llamar a sus funciones
	attachQuillRefs = () => {
		if (!(this.refs.editor && this.refs.editor.getEditor)) return;
		this.quillEditor = this.refs.editor.getEditor();
	};

	makeID() {
		let text = '';
		let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

		for (let i = 0; i < 5; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));

		return text;
	}

	handleChange(html, delta, source, editor) {
		if (this.ignoreChanges) {
			return;
		}
		if (this.props.onChange) {
			if (html === '<p><br></p>') {
				this.props.onChange({textValue: '', deltaValue: {ops: []}});
			} else {
				this.props.onChange({
					textValue: html,
					deltaValue: editor.getContents(),
				});
			}
		}
	}

	alignRight = () => {
		this.quillEditor.format('align', 'right');
	};
	alignLeft = () => {
		this.quillEditor.format('align', '');
	};
	alignCenter = () => {
		this.quillEditor.format('align', 'center');
	};
	alignJustify = () => {
		this.quillEditor.format('align', 'justify');
	};
	textBold = () => {
		const bold = !this.state.format.bold;
		this.quillEditor.format('bold', bold);
		this.setState({format: Object.assign({}, this.state.format, {bold: bold})});
	};
	textItalic = () => {
		const italic = !this.state.format.italic;
		this.quillEditor.format('italic', italic);
		this.setState({
			format: Object.assign({}, this.state.format, {italic: italic}),
		});
	};
	textUnderline = () => {
		const underline = !this.state.format.underline;
		this.quillEditor.format('underline', underline);
		this.setState({
			format: Object.assign({}, this.state.format, {underline: underline}),
		});
	};
	listOrdered = () => {
		let list = '';
		if (this.state.format.list !== 'ordered') {
			list = 'ordered';
		}
		this.quillEditor.format('list', list);
	};
	listBullets = () => {
		let list = '';
		if (this.state.format.list !== 'bullet') {
			list = 'bullet';
		}
		this.quillEditor.format('list', list);
	};
	handleChangeHeader = (event) => {
		this.setState({
			format: Object.assign({}, this.state.format, {
				header: event.target.value,
			}),
		});
	};
	heading1 = () => {
		this.quillEditor.format('header', 1);
	};
	heading2 = () => {
		this.quillEditor.format('header', 2);
	};
	heading3 = () => {
		this.quillEditor.format('header', 3);
	};
	heading4 = () => {
		this.quillEditor.format('header', 4);
	};
	paragraph = () => {
		this.quillEditor.format('header', 0);
	};

	handleChangeTextColor = (textColor) => {
		let colorToString;
		if (textColor === false) {
			colorToString = false;
		} else {
			colorToString =
				'rgba(' + textColor.r + ',' + textColor.g + ',' + textColor.b + ',' + textColor.a + ')';
		}
		this.quillEditor.format('color', colorToString);
		this.setState({
			format: Object.assign({}, this.state.format, {color: colorToString}),
		});
	};

	onChangeSelection = (range) => {
		if (range) {
			this.attachQuillRefs();
			const range = this.quillEditor.getSelection();
			if (range) {
				const format = this.quillEditor.getFormat(range);
				this.setState({format: Object.assign({}, defaultFormat, format)});
			}
		}
	};

	wikiHandler = () => {
		if (this.quillEditor) {
			const range = this.quillEditor.getSelection();
			if (range) {
				let content = this.quillEditor.getContents(range);
				if (content.ops[0]) {
					let selectText = content.ops[0].insert;
					this.refs.wikiDialog.showDialog(selectText);
				}
			}
		}
	};

	linkHandler = () => {
		if (this.quillEditor) {
			const range = this.quillEditor.getSelection();
			if (range) {
				this.refs.linkDialog.showDialog();
			}
		}
	};

	changeContentLink = (data, index, length) => {
		if (this.state.format.myLink === undefined) {
			this.quillEditor.format('myLink', data);
			this.setState({
				format: Object.assign({}, this.state.format, {myLink: true}),
				linkVisible: false,
			});
		} else {
			this.quillEditor.formatText(index, length, {
				myLink: data,
			});
			this.setState({
				format: Object.assign({}, this.state.format, {myLink: true}),
				linkVisible: false,
			});
		}
	};

	changeContentWiki = (dataWiki, index, length, formats) => {
		if (this.state.format.wiki === false) {
			this.quillEditor.format('wiki', dataWiki);
			this.setState({
				format: Object.assign({}, this.state.format, {wiki: true}),
				wikiVisible: false,
			});
		} else {
			formats.wiki = dataWiki;
			this.ignoreChanges = true;
			this.quillEditor.insertText(index, dataWiki.visibleText, formats);
			this.ignoreChanges = false;
			this.quillEditor.deleteText(index + dataWiki.visibleText.length, length);

			this.setState({
				format: Object.assign({}, this.state.format, {wiki: true}),
				wikiVisible: false,
			});
		}
	};

	changeFormat = (value) => {
		if (this.quillEditor) {
			const range = this.quillEditor.getSelection();
			let blot = this.quillEditor.getLeaf(range.index);
			let indexOfSelectedWord = range.index - blot[1];
			let lengthOfSelectedWord = blot[0].domNode.length;
			let textOfSelectedWord = blot[0].text;
			let format = this.quillEditor.getFormat(indexOfSelectedWord, lengthOfSelectedWord);
			if (range) {
				if (value.delete === true && value.edit === false) {
					if (format.hasOwnProperty('wiki')) {
						this.quillEditor.formatText(indexOfSelectedWord, lengthOfSelectedWord, {
							wiki: false,
						});
					}
					if (format.hasOwnProperty('myLink')) {
						this.quillEditor.formatText(indexOfSelectedWord, lengthOfSelectedWord, {
							myLink: false,
						});
					}
				}
				if (value.edit === true && value.delete === false) {
					if (format.hasOwnProperty('wiki')) {
						let formatWithoutWiki = format;
						delete formatWithoutWiki.wiki;
						let attributesOfSelectedWord = blot[0].domNode.parentElement.dataset;
						this.refs.wikiDialog.showDialog(
							textOfSelectedWord,
							attributesOfSelectedWord,
							indexOfSelectedWord,
							lengthOfSelectedWord,
							formatWithoutWiki,
						);
					}
					if (format.hasOwnProperty('myLink')) {
						let hrefOfSelectedWord = blot[0].domNode.parentElement.href;
						this.refs.linkDialog.showDialog(
							hrefOfSelectedWord,
							indexOfSelectedWord,
							lengthOfSelectedWord,
						);
					}
				}
			}
		}
	};

	deleteFormat = () => {
		let options = {
			delete: true,
			edit: false,
		};
		this.changeFormat(options);
	};

	editFormat = () => {
		let options = {
			delete: false,
			edit: true,
		};
		this.changeFormat(options);
	};

	// Obtenemos el valor delta para introducir en el editor quill en función del
	// tipo de entrada recibida
	getTextFromProp = () => {
		const text = this.props.text;

		if (!text) return this.props.defaultValue;

		//Si contiene un objeto con la propiedad deltaValue, devolvemos este valor
		if (text.deltaValue) return text.deltaValue;

		if (text.textValue) {
			if (this.quillEditor) {
				const generatedDelta = this.quillEditor.clipboard.convert(text.textValue);
				return generatedDelta;
			}
			setTimeout(() => this.setState({reloadTextValue: true}), 0);
			return {
				ops: [
					{
						insert: '',
					},
				],
			};
		}

		//En caso contrario entendemos que es un string y devolvemos un delta básico con ese string
		return {
			ops: [
				{
					insert: text,
				},
			],
		};
	};

	render() {
		const {reducedVersion} = this.props;

		//  * Quill modules to attach to TextEditor
		//  * See https://quilljs.com/docs/modules/ for complete options

		TextEditor.modules = {
			clipboard: {
				// toggle to add extra line breaks when pasting HTML:
				matchVisual: false,
			},
		};
		/** Quill TextEditor formats
		 * See https://quilljs.com/docs/formats/ */

		TextEditor.formats = [
			'header',
			'font',
			'align',
			'size',
			'color',
			'bold',
			'italic',
			'underline',
			'strike',
			'blockquote',
			'list',
			'bullet',
			'indent',
			'myLink',
			'image',
			'video',
			'align',
			'wiki',
		];

		let toolbar = null;

		if (!this.props.readOnly) {
			toolbar = reducedVersion ? (
				<ToolBarReduced _thisTextEditor={this} />
			) : (
				<ToolBar _thisTextEditor={this} />
			);
		}

		return (
			<div className="quill-container" ref="textEditor" onClick={this.props.onClick}>
				{toolbar}

				<ReactQuill
					onChangeSelection={this.onChangeSelection}
					onChange={this.handleChange}
					//en realidad es valor delta el que recibe no el texto html
					value={this.getTextFromProp()}
					defaultValue={this.props.defaultValue}
					modules={TextEditor.modules}
					formats={TextEditor.formats}
					placeholder={this.props.placeholder}
					theme={null}
					ref="editor"
					//Para evitar que Google pueda traducir el contenido, porque al hacerlo lo borra
					className="notranslate"
					readOnly={this.props.readOnly}
				/>
				<EditOptions
					visible={this.state.selectionOptionsVisible}
					top={this.state.selectionTop}
					left={this.state.selectionLeft}
					width={this.state.selectionWidth}
					height={this.state.selectionHeight}
					deleteFormat={this.deleteFormat}
					editFormat={this.editFormat}
				/>
				<WikiDialog ref="wikiDialog" onUpdate={this.changeContentWiki} />
				<LinkDialog ref="linkDialog" onUpdate={this.changeContentLink} />
			</div>
		);
	}
}

TextEditor.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onChange: PropTypes.func,
	reducedVersion: PropTypes.bool,
	focus: PropTypes.bool,
	placeholder: PropTypes.string,
	defaultValue: PropTypes.string,
	onClick: PropTypes.func,
	repaintAlways: PropTypes.bool,
	readOnly: PropTypes.bool,
};

TextEditor.defaultProps = {
	reducedVersion: false,
	focus: false,
	repaintAlways: false,
};

export default TextEditor;
