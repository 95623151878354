import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import './styles.scss';

class BeforeHeadingTestBrick extends Component {
	render() {
		const classes = classNames({
			'before-heading-test-brick': true
		});
		return (
			<div className={classes}>
				<FrontEditableText
					fieldName="beforeHeading"
					text={this.props.text}
					set={this.props.set}
				/>
			</div>
		);
	}
}
BeforeHeadingTestBrick.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	set: PropTypes.func
};

export default BeforeHeadingTestBrick;
