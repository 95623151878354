import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import Icon from 'ui/shared/Icon';

import './styles.scss';

class AddElementInSelector extends PureComponent {
	render() {
		const {iconName, nameButton, handleUpload} = this.props;
		return (
			<div className="edit__add-video" onClick={handleUpload}>
				<div className="video-list-load-video">
					<div className="icon-container">
						<Icon name={iconName} size="large" />
						<Icon name="plus" size="small" className="add" />
					</div>
					<div className="label-add-video">{nameButton}</div>
				</div>
			</div>
		);
	}
}

AddElementInSelector.propTypes = {
	iconName: PropTypes.string,
	nameButton: PropTypes.string,
	handleUpload: PropTypes.func
};
AddElementInSelector.defaultProps = {
	iconName: '',
	nameButton: '',
	handleUpload: () => {}
};

export default AddElementInSelector;
