import React from 'react';
import ColorSelectorModal from 'businessLogic/shared/Modals/ColorSelectorModal';
import Box from '@mui/material/Box';
import {getColorObject} from 'businessLogic/helpers/functions';
import nocolorImg from './img/nocolor.svg';
import EditIcon from '@mui/icons-material/Edit';
import tinycolor from 'tinycolor2';
import colors from 'styles/colors';

const styles = (color, sx) => () => ({
	'padding': '3px',
	'backgroundColor': '#ccc',
	'borderRadius': '50%',
	'cursor': 'pointer',
	'&:hover': {
		transform: 'scale(1.1)',
	},
	'.admin-color-circle': {
		'width': '33px',
		'height': '33px',
		'borderRadius': '50%',
		'position': 'relative',
		'overflow': 'hidden',
		// Si hay color, se pone el color de fondo, si no, se pone la imagen de no color
		'backgroundColor': color ? `${color.hex}` : '#ccc',
		'backgroundImage': color ? 'none' : `url(${nocolorImg})`,
		'backgroundSize': 'cover',
		'backgroundPosition': 'center',
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'center',
		'paddingBottom': '2px',

		'& .admin-color-circle__edit-button': {
			zIndex: 2,
			color: tinycolor(color?.hex || '#ccc').isDark() ? '#fff' : colors.mainBlue,
		},
	},
	...sx,
});

const AdminColorSelector = ({value, onChange, excludeCustomColors, defaultAlpha, sx}) => {
	const [open, setOpen] = React.useState(false);
	const color = getColorObject(value);

	return (
		<>
			<Box className="AdminColorSelector" sx={styles(color, sx)}>
				<Box className="admin-color-circle" onClick={() => setOpen(true)}>
					{/* {!color && <img src={nocolorImg} alt="No color" />} */}
					<EditIcon className="admin-color-circle__edit-button" />
				</Box>
			</Box>
			<ColorSelectorModal
				open={open}
				color={color}
				onChange={(colorObj) => {
					onChange(colorObj?.rgb || false);
				}}
				onClose={() => setOpen(false)}
				excludeCustomColors={excludeCustomColors}
				defaultAlpha={defaultAlpha}
			/>
		</>
	);
};

export default AdminColorSelector;
