import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import Instructions from 'businessLogic/core/shared/Edit/Instructions';
import Icon from 'ui/shared/Icon';
import BrickAnomDataManager from 'businessLogic/services/BrickAnomDataManager';
import get from 'lodash/get';
import {BRICKS} from 'businessLogic/core/bricks/bricks';
import {CSVLink} from 'react-csv';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

//NOTA: Si la descarga del CSV falla, comprobar en primer lugar que el plugin Adblock Plus está desactivado

class CsvDownload extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		let defaultValue = '';
		if (editionBrickStruct.defaultValue) {
			defaultValue = editionBrickStruct.defaultValue;
		}

		return defaultValue;
	};

	constructor(props) {
		super(props);

		this.state = {
			csvData: false,
		};

		//Obtenemos los headers del csv. Si no vienen en el edit.json, intentamos obtenerlos del ladrillo
		this.headers = [];

		if (this.props.editionBrickStruct.headers) {
			//los headers vienen en el edit.js
			this.headers = this.props.editionBrickStruct.headers;
		} else {
			//obtenemos los headers del ladrillo - casos - encuesta y formulario personalizable
			//que tienen este método estático - static getHeaders = (brickData) =>
			const brick = BRICKS[this.props.editionBrickData.type];
			if (brick && brick.getHeaders) {
				this.headers = brick.getHeaders(this.props.editionBrickData);
			}
		}

		//Obtenemos el listado respuestas de los usuarios finales
		BrickAnomDataManager.getBrickAnomDataList(
			this.props.pillId,
			this.props.editionBrickData.id,
		).then((brickAnomDataList) => {
			const list = get(brickAnomDataList, 'data.brickAnomDataList');

			if (list) {
				const csvData = list.map((elem) => {
					if (!elem.data) {
						elem.data = {};
					}
					// Recorremos los headers para asegurarnos que todos los datos incluyen un valor por defecto
					if (this.headers) {
						for (const column of this.headers) {
							if (column.type === 'CheckField' && elem.data[column.key] === undefined) {
								elem.data[column.key] = false;
							}
						}
					}

					return {
						...elem.data,
						createdAt: new Date(parseInt(elem.createdAt, 10)),
						updatedAt: new Date(parseInt(elem.updatedAt, 10)),
					};
				});

				this.setState(() => {
					return {
						csvData,
					};
				});
			}
		});
	}

	onChange = (event) => {
		this.props.setMainField(event.target.value);
	};
	render() {
		return (
			<div className="edition-brick-csv-download">
				<label className="edition-brick-csv-download__label">
					{this.props.editionBrickStruct.title}
				</label>
				{this.props.editionBrickStruct.instructions !== undefined && (
					<Instructions text={this.props.editionBrickStruct.instructions} />
				)}
				{this.state.csvData && (
					<div className="edition-brick-csv-download__items">
						<div className="edition-brick-csv-download__items__number">
							{t`Participantes inscritos:`}
							<span>{this.state.csvData.length}</span>
						</div>
						{this.state.csvData.length > 0 && (
							<div className="edition-brick-csv-download__items__button">
								<CSVLink
									filename={get(this.props, 'editionBrickStruct.csvFileName', 'datalist.csv')}
									data={this.state.csvData}
									headers={this.headers}
									target="_blank"
									separator={';'}
								>
									{t`Descargar listado de participantes`}
									<Icon name="download" size="normal" />
								</CSVLink>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default editionBrickWrapper(CsvDownload);
