import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';
class TimerTestBrick extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isRunning: false,
			currentSeconds: 0
		};
		this.animationFrameHandler = null;
		this.delay = 0;
	}
	initTimeCounter = () => {
		this.start = 0;
		this.animationFrameHandler = window.requestAnimationFrame(this.updateTime);
	};

	parseTimer = (timestampSeconds) => {
		const maxDuration = this.props.maxDuration * 60;
		const countdown = maxDuration - timestampSeconds;
		const parseCountdown = {
			minutes: Math.floor(countdown / 60),
			seconds: Math.floor(countdown % 60)
		};

		return parseCountdown;
	};

	updateTime = (timestamp) => {
		if (!this.start) {
			this.start = timestamp;
		}
		let currentSeconds = Math.floor((timestamp - this.start) * 0.001);

		this.setState((state) => {
			let newState = Object.assign({}, state);

			if (currentSeconds === newState.currentSeconds) {
				this.animationFrameHandler = window.requestAnimationFrame(this.updateTime);
				return null;
			}

			if (currentSeconds !== newState.currentSeconds) {
				if (currentSeconds >= 0 && currentSeconds < this.delay) {
					newState.currentSeconds = 0;
					newState.isRunning = false;
				} else {
					newState.currentSeconds = currentSeconds - this.delay;
					newState.isRunning = true;
				}
			}

			if (currentSeconds <= this.props.maxDuration * 60 + this.delay) {
				this.animationFrameHandler = window.requestAnimationFrame(this.updateTime);
			}
			if (newState.currentSeconds >= this.props.maxDuration * 60) {
				this.props.onStop(newState.currentSeconds);

				newState.isRunning = false;
			}
			return {
				currentSeconds: newState.currentSeconds,
				isRunning: newState.isRunning
			};
		});
	};
	componentDidMount() {
		this.initTimeCounter();
	}
	componentWillUnmount() {
		window.cancelAnimationFrame(this.animationFrameHandler);
	}
	render() {
		const minutes = this.parseTimer(this.state.currentSeconds).minutes;
		const seconds = this.parseTimer(this.state.currentSeconds).seconds;
		const alert =
			(this.state.isRunning && this.props.maxDuration * 60 - this.state.currentSeconds <= 10) ||
			(minutes === 0 && seconds === 0);
		const mainStyleClass = 'timer-test-brick';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			[`${mainStyleClass + '--alert'}`]: alert
		});

		return (
			<div className={classes}>
				<i className={mainStyleClass + '__icon'} />
				<div className={mainStyleClass + '__timestamp'}>
					<div className={mainStyleClass + '__minutes'}>{minutes}</div>
					<span>:</span>
					<div className={mainStyleClass + '__seconds'}>
						{seconds < 10 ? '0' + seconds : seconds}
					</div>
				</div>
			</div>
		);
	}
}

TimerTestBrick.propTypes = {
	maxDuration: PropTypes.number,
	onStop: PropTypes.func,
	onStart: PropTypes.func
};
TimerTestBrick.defaultProps = {
	maxDuration: 60
};

export default TimerTestBrick;
