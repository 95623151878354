import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Icon from 'ui/shared/Icon';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

class AudioBox extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			audioButton: 'play-icon',
			volumeIcon: 'volume-on',
			volumeLevel: 100,
			currentTime: 0,
		};
	}
	calculateTotalValue = (length) => {
		let minutes = Math.floor(length / 60);
		if (minutes <= 10) {
			minutes = '0' + minutes;
		}
		let seconds_int = length - minutes * 60;
		if (seconds_int <= 10) {
			seconds_int = '0' + seconds_int;
		}
		let seconds_str = seconds_int.toString();
		let seconds = seconds_str.substr(0, 2);
		let time = minutes + ':' + seconds;
		return time;
	};
	calculateCurrentValue = (currentTime) => {
		//current_hour = parseInt(currentTime / 3600) % 24,
		let current_minute = parseInt(currentTime / 60, 10) % 60,
			current_seconds_long = currentTime % 60,
			current_seconds = Math.floor(current_seconds_long),
			current_time =
				(current_minute < 10 ? '0' + current_minute : current_minute) +
				':' +
				(current_seconds < 10 ? '0' + current_seconds : current_seconds);

		this.setState({
			currentTime: current_time,
		});
		return current_time;
	};
	initProgressBar = () => {
		const length = this.refs.player.duration;
		const player = this.refs.player;
		let current_time = player.currentTime;
		// calculate total length of value
		const totalLength = this.calculateTotalValue(length);
		this.refs.endTime.innerHTML = totalLength;
		// calculate current value time
		let currentTime = this.calculateCurrentValue(current_time);
		this.refs.startTime.innerHTML = currentTime;
		//Position of progressbar
		let timeProgress = (player.currentTime / player.duration) * 100;
		let timeProgressRound = timeProgress.toFixed(2);
		const progressBarSpan = this.refs.progressBarSpan;
		progressBarSpan.style.width = timeProgressRound + '%';
		this.refs.progressBar.addEventListener('click', seek);
		if (player.currentTime === player.duration) {
			this.setState({
				audioButton: 'play-icon',
			});
		}
		function seek(evt) {
			let percent = evt.offsetX / this.offsetWidth;
			player.currentTime = percent * player.duration;
			let percentCurrentTime = percent / 100;
			progressBarSpan.style.width = percentCurrentTime + '%';
		}
	};
	initPlayers = () => {
		// Variables
		// ----------------------------------------------------------
		// audio embed object
		let this_g = this;
		const player = this.refs.player,
			playBtn = this.refs.playBtn;
		(function () {
			// Controls Listeners
			// ----------------------------------------------------------
			if (playBtn != null) {
				togglePlay();
			}
			// Controls & Sounds Methods
			// ----------------------------------------------------------
			function togglePlay() {
				if (player.paused === false) {
					player.pause();
					this_g.setState({
						audioButton: 'play-icon',
					});
				} else {
					player.play();
					this_g.setState({
						audioButton: 'pause-icon',
					});
				}
			}
		})();
	};

	muteAudio = () => {
		const player = this.refs.player;
		if (player.muted) {
			player.muted = false;
			this.setState({
				volumeIcon: 'volume-on',
			});
		} else {
			player.muted = true;
			this.setState({
				volumeIcon: 'volume-off',
			});
		}
	};

	setVolume = (event) => {
		const player = this.refs.player;
		player.volume = parseInt(event.target.value, 10) / 10;
		this.setState({volumeLevel: event.target.value * 10});
	};
	setDurationTime = () => {
		const audioPlayer = this.refs.player;
		const playerEndTime = this.refs.endTime;
		audioPlayer.addEventListener(
			'loadedmetadata',
			function () {
				const totalDuration = audioPlayer.duration;
				let minutes = Math.floor(totalDuration / 60);
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				let seconds_int = totalDuration - minutes * 60;
				if (seconds_int < 10) {
					seconds_int = '0' + seconds_int;
				}
				const seconds_str = seconds_int.toString();
				const seconds = seconds_str.substr(0, 2);
				const totalTime = minutes + ':' + seconds;
				playerEndTime.innerHTML = totalTime;
			},
			true,
		);
	};

	componentDidMount() {
		this.setDurationTime(this.refs.audioPlayer);
	}

	playAudio = () => {
		this.initPlayers();
	};

	componentDidUpdate() {
		this.setDurationTime(this.refs.audioPlayer);
	}

	render() {
		const mainClass = 'audio';
		const {src, loop, theme, onPlay, onPause, onEnd} = this.props;
		const classes = classNames({
			[`${mainClass}`]: true,
			[`${theme}`]: theme,
		});

		return (
			<div className={classes}>
				<div ref="audioPlayer" className="audio__player ">
					<div ref="playBtn" className="audio__player__play-btn" onClick={this.playAudio}>
						<Icon name={this.state.audioButton} size="normal" ref="playBtnIcon" />
					</div>
					<div className="audio__player__timer">
						<span ref="startTime">00:00</span>
						<span> / </span>
						<span ref="endTime">--:--</span>
					</div>
					<div className="audio__player__wrapper" ref="audioWrapper">
						<audio
							key={src}
							ref="player"
							onTimeUpdate={this.initProgressBar}
							loop={loop}
							onPlay={onPlay}
							onPause={onPause}
							onEnded={onEnd}
						>
							<source src={src} type="audio/mp3" />
						</audio>
					</div>
					<div className="audio__player__controls">
						<div className="audio__player__controls__progress-bar" ref="progressBar">
							<div className="audio__player__controls__progress-bar__span" ref="progressBarSpan">
								<span className="audio__player__controls__progress-bar__span__current-time">
									{this.state.currentTime}
								</span>
							</div>
						</div>
					</div>
					<div className="audio__player__volume">
						<div className="audio__player__volume__controls" ref="volumeControls">
							<div
								className="audio__player__volume__controls__mute"
								ref="volumeMute"
								onClick={this.muteAudio}
							>
								<Icon name={this.state.volumeIcon} size="normal" ref="volumeToggle" />
							</div>
							<div className="audio__player__volume__controls__wrap">
								<div
									className="audio__player__volume__controls__wrap__level"
									style={{width: this.state.volumeLevel + '%'}}
								/>
								<input
									className="audio__player__volume__controls__wrap__bar"
									ref="volumeBar"
									type="range"
									title="volume"
									min="0"
									max="10"
									step="1"
									value="10"
									onChange={this.setVolume}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

AudioBox.propTypes = {
	src: PropTypes.string,
	theme: PropTypes.string,
	loop: PropTypes.bool,
	onPlay: PropTypes.func,
	onPause: PropTypes.func,
	onEnd: PropTypes.func,
};

AudioBox.defaultProps = {
	src: '',
	loop: false,
	theme: 'player',
	onPlay: () => {},
	onPause: () => {},
	onEnd: () => {},
};

export default brickWrapper(AudioBox);
