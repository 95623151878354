import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
// import 'react-quill/dist/quill.core.css';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

class HeadingWithNumber extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Título con número`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)
	static brickDefaultData = () => ({
		antetitulo: _('Día'),
		number: '01',
		subtitle: 'Lampang / Chiang Rai',
		text: 'Ma quis eost, officim sinverum vellant officim sinverum vellant .',
	});
	static brickDefaultConfig = {};
	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const brickCss = 'heading-number-brick';
		const classes = classNames({
			'brick': true,
			'heading-number-brick': true,
		});
		return (
			<Container
				size="extra-large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div className="my-row">
						<div className={brickCss + '__antetitulo'}>
							<FrontEditableText
								fieldName="antetitulo"
								text={data.antetitulo}
								set={this.props.set}
							/>
						</div>
						<div className={brickCss + '__number'}>
							<FrontEditableText fieldName="number" text={data.number} set={this.props.set} />
						</div>
						<div className={brickCss + '__subtitle'}>
							<FrontEditableText fieldName="subtitle" text={data.subtitle} set={this.props.set} />
						</div>
					</div>

					<div className={brickCss + '__text'}>
						<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
					</div>
				</div>
			</Container>
		);
	}
}
export default brickWrapper(HeadingWithNumber);
