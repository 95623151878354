import {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';

const generateUniqueSlug = () => {
	const randomString = Math.random().toString(36).substring(12);
	return randomString;
};

class UniqueSlug extends PureComponent {
	static getDefaultValue = () => {
		return generateUniqueSlug();
	};

	render() {
		return null;
	}
}

export default editionBrickWrapper(UniqueSlug);
