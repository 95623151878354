import gql from 'graphql-tag';
// import InputDateCalendar from '../../../../shared/Input/InputDateCalendar/index';

export const PILL_ADD_SUBSCRIBE = gql`
	subscription AddPillSubscription($identificator: String) {
		addPillSubscription(identificator: $identificator) {
			id
			type
			name
			description
			image
			owner
			content {
				containerBrick
				bricks {
					id
					type
					children
					data
					config
				}
			}
		}
	}
`;

export const PILL_DELETE_SUBSCRIBE = gql`
	subscription DeletePillSubscription($identificator: String) {
		deletePillSubscription(identificator: $identificator) {
			id
		}
	}
`;

export const PILLS_QUERY = gql`
	query {
		pills(type: "pill") {
			id
			slug
			type
			section
			name
			description
			image
			imageObj
			owner
			config
			data
			diploma
			content {
				containerBrick
				bricks {
					id
					type
					children
					data
					config
				}
			}
			published
			unpublished
			taxonomyIds
			taxonomyObjs {
				id
				name
				description
				slug
				taxonomyTypeObj {
					id
					name
					description
					slug
				}
			}
		}
	}
`;

export const PILL_QUERY = gql`
	query Pill($id: String!) {
		pill(id: $id) {
			id
			slug
			type
			section
			sectionObj {
				name
				slug
				type
				shortName
				icon
				config
			}
			name
			description
			image
			imageObj
			config
			data
			diploma
			status
			maxScore
			taxonomyIds
			taxonomyObjs {
				id
				name
				description
				slug
				taxonomyTypeObj {
					id
					name
					description
					slug
				}
			}
			content {
				containerBrick
				bricks {
					id
					type
					parentId
					children
					data
					config
					styles
				}
			}
			pills {
				id
				slug
				name
				description
				image
				imageObj
				config
				type
				data
				diploma
				content {
					containerBrick
					bricks {
						id
						type
						parentId
						children
						data
						config
						styles
					}
				}
			}
			owner
			published
			unpublished
			stateData
			userCanUploadFiles
			error
			reactions {
				total
				like
				clap
				love
				idea
				useful
				surprise
				sad
			}
		}
	}
`;

export const PILLS_BY_OWNER_QUERY = gql`
	query Pills($owner: String!) {
		pills(owner: $owner) {
			id
			slug
			type
			section
			name
			description
			image
			imageObj
			owner
			config
			data
			diploma
			taxonomyIds
			taxonomyObjs {
				id
				name
				description
				slug
				taxonomyTypeObj {
					id
					name
					description
					slug
				}
			}
			content {
				containerBrick
				bricks {
					id
					type
					children
					data
					config
					styles
				}
			}
		}
	}
`;

export const PILL_LIST_BY_TYPE = gql`
	query Pills($type: String, $entityId: String) {
		pills(type: $type, entityId: $entityId) {
			id
			slug
			section
			name
			description
			image
			imageObj
		}
	}
`;

export const DUPLICATE_PILL = gql`
	mutation duplicatePill($idPill: String!, $idEntity: String, $updatePillName: Boolean) {
		duplicatePill(idPill: $idPill, idEntity: $idEntity, updatePillName: $updatePillName) {
			id
			slug
			name
			section
			description
			image
			imageObj
		}
	}
`;

export const COURSES_QUERY = gql`
	{
		pills(type: "course", status: "published") {
			id
			slug
			name
			image
			section
			imageObj
			description
			vstatus
			taxonomyIds
			taxonomyObjs {
				id
				name
				description
				slug
				color
				taxonomyTypeObj {
					id
					name
					description
					slug
					index
				}
			}
			pills {
				id
			}
			stateData
			status
			data
			diploma
		}
	}
`;

export const COURSES_SECTION__QUERY = gql`
	query Pills($section: String, $status: String) {
		pills(section: $section, status: $status) {
			id
			slug
			name
			image
			section
			imageObj
			description
			vstatus
			pills {
				id
			}
			status
		}
	}
`;
export const SEARCH_QUERY = gql`
	query SearchPills($filter: PillSearchFilter, $offset: Int, $limit: Int, $sort: String) {
		pillSearch(filter: $filter, offset: $offset, limit: $limit, sort: $sort) {
			number
			total
			totalFiltered
			offset
			pills {
				id
				slug
				name
				image
				section
				sectionObj {
					name
					slug
					type
					shortName
					icon
				}
				type
				imageObj
				description
				createdAt
				published
				unpublished
				data
				stateData
				taxonomyIds
				taxonomyObjs {
					id
					name
					description
					slug
					color
					taxonomyTypeObj {
						id
						name
						description
						slug
						index
					}
				}
			}
		}
	}
`;

// Query que devuelve el listado de pildoras de una taxonomía
export const PILL_LIST_BY_TAXONOMY = gql`
	query Pills($taxonomies: [ID!], $entityId: String) {
		pills(taxonomies: $taxonomies, entityId: $entityId, status: "published") {
			id
			slug
			name
			description
			type
			section
			sectionObj {
				name
				slug
				type
				shortName
				icon
			}
			image
			imageObj
			published
			unpublished
			taxonomyObjs {
				id
				name
				description
				slug
				color
				taxonomyTypeObj {
					id
					name
					description
					slug
				}
			}
		}
	}
`;

// Query para el listado de pildoras de un usuario concreto
export const ADMIN_GET_USER_PILLS = gql`
	query adminGetUserPills(
		$userId: String
		$section: String
		$offset: Int
		$limit: Int
		$sort: String
	) {
		adminGetUserPills(
			userId: $userId
			section: $section
			offset: $offset
			limit: $limit
			sort: $sort
		) {
			userPills {
				pill {
					id
					slug
					type
					section
					name
					description
					image
					imageObj
					config
					data
					status
					published
					unpublished
					access {
						rule
					}
				}
				accessReason
			}
		}
	}
`;
export const ADMIN_GET_GROUP_PILLS = gql`
	query adminGetGroupPills(
		$groupId: ID
		$section: String
		$offset: Int
		$limit: Int
		$sort: String
	) {
		adminGetGroupPills(
			groupId: $groupId
			section: $section
			offset: $offset
			limit: $limit
			sort: $sort
		) {
			userPills {
				pill {
					id
					slug
					type
					section
					name
					description
					image
					imageObj
					config
					data
					status
					published
					unpublished
					access {
						rule
						users {
							id
							name
							email
						}
						groups {
							id
						}
					}
				}
				accessReason
			}
		}
	}
`;
//Query para borrar una pildora de un usuario concreto
export const ADMIN_DELETE_USER_PILL_MUTATION = gql`
	mutation DeletePillMutation($id: String!) {
		deletePill(idPill: $id) {
			id
		}
	}
`;

//Mutación para duplicar una pildora de un usuario concreto
export const ADMIN_DUPLICATE_USER_PILL_MUTATION = gql`
	mutation DuplicatePillMutation($idPill: String!) {
		duplicatePill(idPill: $idPill, keepAccess: true) {
			id
			slug
			type
			section
			name
			description
			image
			imageObj
			config
			data
			status
			published
			unpublished
			access {
				rule
				users {
					id
					name
					email
				}
				groups {
					id
				}
			}
		}
	}
`;

//Mutación para crear una pildora a un usuario concreto
export const ADMIN_CREATE_USER_PILL = gql`
	mutation adminCreateUserPill(
		$type: String
		$userId: String
		$groupId: String
		$section: String
		$imageObj: ScalarGeneric
		$basePillId: String
	) {
		adminCreateUserPill(
			userId: $userId
			groupId: $groupId
			input: {type: $type, section: $section, imageObj: $imageObj}
			basePillId: $basePillId
		) {
			id
			slug
			type
			section
			name
			description
			image
			imageObj
			config
			data
			status
			published
			unpublished
			access {
				rule
				users {
					id
					name
					email
				}
				groups {
					id
				}
			}
		}
	}
`;

export const PILL_DELETE_MUTATION = gql`
	mutation deletePill($idPill: String!) {
		deletePill(idPill: $idPill) {
			id
			error
		}
	}
`;

export const UPDATE_PILL_MUTATION = gql`
	mutation updatePill($idPill: String!, $input: PillCreateInput!) {
		updatePill(idPill: $idPill, input: $input) {
			id
			slug
			type
			section
			name
			description
			config
			data
			diploma
			image
			imageObj
		}
	}
`;

export const CREATE_PILL_MUTATION = gql`
	mutation createPill($input: PillCreateInput!) {
		createPill(input: $input) {
			id
			slug
			type
			section
			name
			description
			image
			imageObj
			owner
			config
			data
			diploma
			content {
				containerBrick
				bricks {
					id
					type
					children
					data
					config
					styles
				}
			}
			error
		}
	}
`;

export const ADD_NEW_CHILD_BRICK_MUTATION = gql`
	mutation addNewChildBrick(
		$pillId: String
		$brickId: String
		$newBrickId: String
		$brickType: String
		$index: Int
		$brickConfig: ScalarGeneric
		$brickData: ScalarGeneric
		$brickStyles: ScalarGeneric
		$brickChildren: ScalarGeneric
		$childList: String
		$identificator: String
	) {
		addNewChildBrick(
			pillId: $pillId
			brickId: $brickId
			newBrickId: $newBrickId
			brickType: $brickType
			index: $index
			brickConfig: $brickConfig
			brickData: $brickData
			brickStyles: $brickStyles
			brickChildren: $brickChildren
			childList: $childList
			identificator: $identificator
		) {
			id
			slug
			type
			section
			name
			description
			image
			imageObj
			owner
			content {
				containerBrick
				bricks {
					id
					type
					children
					data
					config
					styles
				}
			}
			error
		}
	}
`;

export const CREATE_BRICK_MUTATION = gql`
	mutation createBrick(
		$pillId: String
		$brickId: String
		$brickType: String
		$brickConfig: ScalarGeneric
		$brickData: ScalarGeneric
		$brickStyles: ScalarGeneric
		$brickChildren: ScalarGeneric
		$identificator: String
	) {
		createBrick(
			pillId: $pillId
			brickId: $brickId
			brickType: $brickType
			brickConfig: $brickConfig
			brickData: $brickData
			brickStyles: $brickStyles
			brickChildren: $brickChildren
			identificator: $identificator
		) {
			id
			error
		}
	}
`;

export const UPDATE_BRICK_MUTATION = gql`
	mutation updateBrick(
		$pillId: String!
		$brickId: String!
		$brick: BrickInput
		$identificator: String
	) {
		updateBrick(pillId: $pillId, brickId: $brickId, brick: $brick, identificator: $identificator) {
			id
			error
		}
	}
`;

export const DELETE_BRICK_MUTATION = gql`
	mutation deleteBrick($pillId: String!, $brickId: String!, $identificator: String) {
		deleteBrick(pillId: $pillId, brickId: $brickId, identificator: $identificator) {
			id
			error
		}
	}
`;

export const ADD_NEW_BRICK_SUBSCRIBE = gql`
	subscription AddNewBrickSubscription($identificator: String) {
		addNewBrickSubscription(identificator: $identificator) {
			id
			type
			section
			name
			description
			image
			imageObj
			owner
			content {
				containerBrick
				bricks {
					id
					type
					children
					data
					config
					styles
				}
			}
		}
	}
`;

/****************************************************************
 * LISTA DE ACCESO
 *****************************************************************/

export const PILL_ACCESS_QUERY = gql`
	query PillAccess($id: String!) {
		pill(id: $id) {
			id
			slug
			name
			access {
				rule
				groups {
					id
					name
					description
				}
				users {
					id
					name
					email
				}
				dynamicFilterObj
			}
		}
	}
`;
// Modificar regla de acceso a pildora
export const CHANGE_ACCESS_RULE = gql`
	mutation changeAccessRuleForPill($idPill: String!, $rule: String!) {
		changeAccessRule(idPill: $idPill, rule: $rule) {
			id
			slug
			name
			access {
				rule
			}
		}
	}
`;

export const PILL_ADMIN__ACCESS__QUERY = gql`
	query pillAdminAccess($id: String!) {
		pill(id: $id) {
			id
			slug
			name
			adminAccess {
				rule
			}
			userCanUploadFiles
			type
		}
	}
`;
export const UPDATE__PILL_ADMIN__ACCESS = gql`
	mutation changeAdminAccessRule($idPill: String!, $rule: String!) {
		changeAdminAccessRule(idPill: $idPill, rule: $rule) {
			id
			slug
			name
			adminAccess {
				rule
			}
			userCanUploadFiles
		}
	}
`;
// Añade un grupo a la lista de acceso
export const ADD_GROUP_TO_ACCESS_LIST = gql`
	mutation addGroupToAccessList($idPill: String!, $idGroup: String!) {
		addGroupToAccessList(idPill: $idPill, idGroup: $idGroup) {
			id
			slug
			name
			access {
				groups {
					id
					name
					description
				}
			}
		}
	}
`;

// Añade varios grupos (array de ids) a la lista de acceso
export const ADD_GROUPS_TO_ACCESS_LIST = gql`
	mutation AddGroupsToAccessList($pillId: String!, $groupsIds: [String!]!, $sendAlert: Boolean) {
		addGroupsToAccessList(pillId: $pillId, groupsIds: $groupsIds, sendAlert: $sendAlert) {
			access {
				groups {
					name
					id
					description
				}
			}
		}
	}
`;

// BORRA un grupo a la lista de acceso
export const REMOVE_GROUP_FROM_ACCESS_LIST = gql`
	mutation RemoveGroupFromAccessList($idPill: String, $idGroup: String) {
		removeGroupFromAccessList(idPill: $idPill, idGroup: $idGroup) {
			id
			slug
			name
			access {
				groups {
					id
					name
					description
				}
			}
		}
	}
`;

// Añade varios USUARIOS (array de ids) a la lista de acceso
export const ADD_USERS_TO_ACCESS_LIST = gql`
	mutation AddUsersToAccessList($pillId: String!, $usersIds: [String!]!, $sendAlert: Boolean) {
		addUsersToAccessList(pillId: $pillId, usersIds: $usersIds, sendAlert: $sendAlert) {
			id
			name
			access {
				users {
					id
					name
					email
				}
			}
		}
	}
`;

// BORRA un grupo a la lista de acceso
export const REMOVE_USER_FROM_ACCESS_LIST = gql`
	mutation RemoveUserFromAccessList($idPill: String, $idUser: String) {
		removeUserFromAccessList(idPill: $idPill, idUser: $idUser) {
			id
			name
			access {
				users {
					id
					name
					email
				}
			}
		}
	}
`;

// adminSetPillDynamicFilter
export const ADMIN_SET_PILL_DYNAMIC_FILTER = gql`
	mutation adminSetPillDynamicFilter($idPill: String!, $dynamicFilterObj: ScalarGeneric) {
		adminSetPillDynamicFilter(idPill: $idPill, dynamicFilterObj: $dynamicFilterObj) {
			id
			name
			access {
				dynamicFilterObj
			}
		}
	}
`;

/****************************************************************
 * Queries y mutaciones PILL PARENT
 *****************************************************************/
//Query para obtener el listado de publicaciones
export const PILLPARENT_QUERY = gql`
	query pillParentQuery($id: String!) {
		pill(id: $id) {
			id
			slug
			name
			description
			slug
			image
			imageObj
			config
			owner
			data
			diploma
			type
			section
			status
			createdAt
			published
			unpublished
			sendAlert
			taxonomyIds
			taxonomyObjs {
				id
				name
				description
				slug
				color
				taxonomyTypeObj {
					id
					name
					description
					slug
				}
			}
			pills {
				id
				slug
				name
				type
				description
				image
				imageObj
				status
				config
			}
			referenced
			reference
			calendar {
				active
				allDay
				start
				end
			}
		}
	}
`;

//Query para actualizar un publicación
export const UPDATE_PARENT_MUTATION = gql`
	mutation UpdateParentMutation(
		$id: String!
		$name: String
		$description: String
		$slug: String
		$image: String
		$imageObj: ScalarGeneric
		$config: ScalarGeneric
		$data: ScalarGeneric
		$diploma: ScalarGeneric
		$pills: [String]
		$status: String
		$calendar: PillCalendarInput
	) {
		updatePill(
			idPill: $id
			input: {
				name: $name
				description: $description
				slug: $slug
				image: $image
				imageObj: $imageObj
				config: $config
				data: $data
				diploma: $diploma
				pills: $pills
				status: $status
				calendar: $calendar
			}
		) {
			id
			slug
			name
			description
			slug
			image
			imageObj
			config
			data
			diploma
			type
			section
			status
			pills {
				id
				slug
				name
				description
				image
				imageObj
				status
			}
			createdAt
			published
			unpublished
			sendAlert
			calendar {
				active
				allDay
				start
				end
			}
		}
	}
`;

//Mutación para añadir una píldora a una publicación o curso
export const ADD_PILL_MUTATION = gql`
	mutation AddPillMutation($id: String!, $image: String, $type: String) {
		createPill(parent: $id, input: {image: $image, type: $type}) {
			id
			slug
			name
			description
			slug
			image
			imageObj
			config
			data
			diploma
			type
			section
			status
			pills {
				id
				slug
				name
				description
				image
				imageObj
				status
			}
			createdAt
			published
			unpublished
			sendAlert
		}
	}
`;

//Mutación para duplicar una píldora
export const DUPLICATE_PILL_MUTATION = gql`
	mutation DuplicatePillMutation($id: String!) {
		duplicatePill(idPill: $id) {
			id
		}
	}
`;

//Mutación para eliminar una píldora
export const DELETE_PILL_MUTATION = gql`
	mutation DeletePillMutation($id: String!) {
		deletePill(idPill: $id) {
			id
			slug
			name
			description
			slug
			image
			imageObj
			config
			data
			diploma
			type
			section
			status
			pills {
				id
				slug
				name
				description
				image
				imageObj
				status
			}
			createdAt
			published
			unpublished
			sendAlert
		}
	}
`;

//Mutación para actualizar la información de publicación
export const UPDATE_PILL_PUBLISH_DATA_MUTATION = gql`
	mutation UpdatePillPublishData(
		$idPill: String!
		$status: PillStatus!
		$publishDate: String
		$unpublishDate: String
	) {
		updatePillPublishData(
			idPill: $idPill
			status: $status
			publishDate: $publishDate
			unpublishDate: $unpublishDate
		) {
			id
			slug
			name
			description
			image
			imageObj
			config
			data
			diploma
			type
			section
			status
			pills {
				id
				slug
				name
				description
				image
				imageObj
				status
			}
			createdAt
			published
			unpublished
			sendAlert
		}
	}
`;

//Mutación para actualizar la información de publicación
export const UPDATE_PILL_SEND_ALERT_MUTATION = gql`
	mutation UpdatePillSendAlertData($idPill: String!, $sendAlert: Boolean) {
		updatePillSendAlert(idPill: $idPill, sendAlert: $sendAlert) {
			id
			slug
			name
			description
			image
			imageObj
			config
			data
			diploma
			type
			section
			status
			pills {
				id
				slug
				name
				description
				image
				imageObj
				status
			}
			createdAt
			published
			unpublished
			sendAlert
		}
	}
`;

// Mutación para setear el slug de un pildora
// Modificar regla de acceso a pildora
export const SET_PILL_SLUG = gql`
	mutation setPillSlug($idPill: String!, $slug: String!, $pillName: String) {
		setPillSlug(idPill: $idPill, slug: $slug, pillName: $pillName) {
			error
			slug
			pill {
				id
				name
				description
				slug
			}
		}
	}
`;

export const CHECK_JOIN_QUERY = gql`
	query checkJoinQuery($id: String!) {
		checkJoin(courseId: $id)
	}
`;

export const ADMIN_PILLS_QUERY = gql`
	query AdminPillsQuery($section: String, $dontGetUserPills: Boolean) {
		pills(section: $section, dontGetUserPills: $dontGetUserPills) {
			id
			name
			description
			slug
			image
			imageObj
			section
			type
			status
			published
			unpublished
			createdAt
			sendAlert
			access {
				users {
					id
					name
					email
				}
			}
		}
	}
`;

//Query para obtener el listado de Píldoras
export const ADMIN_GET_PILLS = gql`
	query adminGetPills(
		$offset: Int
		$limit: Int
		$sort: String
		$baseFilter: PillFilter
		$userFilter: PillFilter
		$fromParents: Boolean
		$fromGroup: Boolean
		$onlyPrioritized: Boolean
	) {
		adminGetPills(
			offset: $offset
			limit: $limit
			sort: $sort
			baseFilter: $baseFilter
			userFilter: $userFilter
			fromParents: $fromParents
			fromGroup: $fromGroup
			onlyPrioritized: $onlyPrioritized
		) {
			offset
			number
			total
			totalFiltered
			pills {
				id
				name
				description
				slug
				image
				imageObj
				section
				owner
				sectionObj {
					name
					type
					icon
				}
				type
				status
				published
				createdAt
				entity
				sendAlert
				access {
					rule
					groups {
						id
						name
						slug
						description
					}
					users {
						id
						name
						email
					}
				}
				entityShortName
			}
		}
	}
`;

export const CHANGE_USER_CAN_UPLOAD_FILES = gql`
	mutation ChangeUserCanUploadFiles($pillId: ID!, $status: Boolean!) {
		changeUserCanUploadFiles(pillId: $pillId, status: $status)
	}
`;

// userSetPillIncludeFiles
export const USER_SET_PILL_INCLUDE_FILES = gql`
	mutation UserSetPillIncludeFiles($pillId: ID!, $status: Boolean!) {
		userSetPillIncludeFiles(pillId: $pillId, status: $status)
	}
`;
