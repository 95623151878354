import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Input from 'ui/shared/FormFields/Input';
import InputCustomCheckBox from 'businessLogic/shared/Input/InputCustomCheckBox';
import LegalConditionsLink from 'businessLogic/shared/Links/LegalConditionsLink';
import TextArea from 'businessLogic/shared/Input/TextArea';
import classNames from 'classnames';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import './styles.scss';

class Form extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
		};
	}
	setText = (event) => {
		this.props.setData('text', event.target.value);
	};

	setPersonalizableField = (value, field) => {
		this.props.setData(field, value);
	};

	setEmailUser = (event) => {
		this.props.setData('emailUser', event.target.value);
		this.setState({email: event.target.value});
	};

	onClickSendData = () => {
		this.props.onSendData(this.props.user.email);
	};
	render() {
		const {data, set, user, validation} = this.props;
		let classes = classNames({
			'form-new-contact-form': true,
		});
		return (
			<div className={classes}>
				<div className={classes + '__title'}>
					<FrontEditableText
						fieldName="titleContactForm"
						text={data.title}
						editable={true}
						set={set}
					/>
				</div>
				<div className={classes + '__subtitle'}>
					<FrontEditableText
						fieldName="subtitleContactForm"
						text={data.subtitle}
						editable={true}
						set={set}
					/>
				</div>
				{/* este campo solo lo va a ver el usuario final en caso de que no esté registrado
				como administrador cuando estoy creando la píldora nunca lo voy a ver. */}
				{!user.email && (
					<Input
						className={classes + '__input'}
						type="email"
						placeholder={data.emailUser}
						theme="platform-input"
						onChange={this.setEmailUser}
						value={this.state.email}
					/>
				)}

				{/* contenido flexible - personalizable */}
				{data.flexibleContent.map((flexibleField, index) => {
					let isInvalid = false;
					let errorMsg = '';
					if (validation[flexibleField.slugField]) {
						isInvalid = validation[flexibleField.slugField].isInvalid;
						errorMsg = validation[flexibleField.slugField].message;
					}
					switch (flexibleField.flexibleContentType) {
						case 'TextField':
							return (
								<Input
									key={index}
									name={flexibleField.slugField}
									className="form-personalizable-contest__input"
									type="text"
									placeholder={flexibleField.textFieldForm}
									theme="platform-input"
									errorText={(isInvalid && errorMsg) || undefined}
									onChange={(event) => {
										this.setPersonalizableField(event.target.value, flexibleField.slugField);
									}}
								/>
							);
						case 'CheckField':
							return (
								<div className="form-personalizable-contest__input--checkbox" key={index}>
									<InputCustomCheckBox
										errorText={(isInvalid && errorMsg) || undefined}
										name={flexibleField.slugField}
										setData={(event) => {
											// console.log('Check', event);
											this.setPersonalizableField(event.target.checked, flexibleField.slugField);
										}}
									>
										{/* si hay texto para mostrar en un modal */}
										{flexibleField.checkFieldForm ? (
											<LegalConditionsLink
												label={flexibleField.labelField}
												link={flexibleField.checkFieldFormTextLink}
												text={flexibleField.checkFieldForm}
											/>
										) : (
											<LegalConditionsLink label={flexibleField.labelField} />
										)}
									</InputCustomCheckBox>
								</div>
							);

						case 'EmailField':
							return (
								<Input
									name={flexibleField.slugField}
									key={index}
									className="form-personalizable-contest__input"
									type="email"
									placeholder={flexibleField.emailFieldForm}
									theme="platform-input"
									errorText={(isInvalid && errorMsg) || undefined}
									onChange={(event) => {
										this.setPersonalizableField(event.target.value, flexibleField.slugField);
									}}
								/>
							);
						case 'PhoneField':
							return (
								<Input
									name={flexibleField.slugField}
									key={index}
									className="form-personalizable-contest__input"
									type="tel"
									placeholder={flexibleField.phoneFieldForm}
									theme="platform-input"
									errorText={(isInvalid && errorMsg) || undefined}
									onChange={(event) => {
										this.setPersonalizableField(event.target.value, flexibleField.slugField);
									}}
								/>
							);
						case 'TextAreaField':
							return (
								<TextArea
									name={flexibleField.slugField}
									key={index}
									className="form-personalizable-contest__textarea"
									placeholder={flexibleField.textAreaFieldForm}
									theme="platform-textarea"
									errorText={(isInvalid && errorMsg) || undefined}
									onChange={(event) => {
										this.setPersonalizableField(event.target.value, flexibleField.slugField);
									}}
								/>
							);

						default:
							return (
								<Input
									name={flexibleField.slugField}
									key={index}
									className="form-personalizable-contest__input"
									type="text"
									placeholder={flexibleField.textFieldForm}
									theme="platform-input"
									errorText={(isInvalid && errorMsg) || undefined}
									onChange={(event) => {
										this.setPersonalizableField(event.target.value, flexibleField.slugField);
									}}
								/>
							);
					}
				})}
				{/* contenido flexible - personalizable */}

				<button className={'form-new-contact-form__button'} onClick={this.onClickSendData}>
					{data.button}
				</button>
			</div>
		);
	}
}

Form.propTypes = {
	data: PropTypes.object,
};

Form.defaultProps = {
	data: {},
};

export default Form;
