import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import {BrickContext} from 'businessLogic/core/bricks';
import VisibleImageContext from 'businessLogic/contexts/VisibleImageContext';
import AddBrickButton from 'businessLogic/core/shared/Buttons/AddBrickButton';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

const modalRoot = document.getElementById('portals');

class LayerMoreInfo extends PureComponent {
	constructor(props) {
		super(props);

		this.brickRef = React.createRef();
		this.containerLayerMoreInfo = document.createElement('div');
		this.isDivMounted = false;
	}
	addBrick = () => {
		this.props.mainProps.addBrick(this.props.mainProps.brickId, 0, this.props.childList);
	};

	mountDiv() {
		if (modalRoot && !this.isDivMounted) modalRoot.appendChild(this.containerLayerMoreInfo);
	}
	componentWillUnmount() {
		if (modalRoot && this.isDivMounted && modalRoot.contains(this.containerLayerMoreInfo)) {
			modalRoot.removeChild(this.containerLayerMoreInfo);
		}
	}

	render() {
		const {show, onClose, mainProps, childList, editMode} = this.props;
		const classes = classNames({
			'layer-more-info': true,
			'layer-more-info--show': show,
			'theme-light': true,
			'edit-mode': editMode,
		});

		const noBricks = get(mainProps, `brick.children.${childList}`, []).length === 0;
		const intro = {
			title: t`Empieza a crear`,
			text: t`Sitúa el ratón sobre este texto y haz click en el icono + que aparece debajo para añadir tu primer ladrillo`,
		};

		const layer = (
			<div className={classes}>
				{show && (
					<VisibleImageContext.Provider value={{visibleImages: true}}>
						<div className="layer-more-info__overlay" onClick={onClose} />
						<div className="layer-more-info__brick-wrapper">
							{mainProps.children}
							<div className="main-container-rows-brick" ref={this.brickRef}>
								{mainProps.editMode && (
									<div className="first-brick">
										{noBricks && mainProps.editMode ? (
											<div className="main-container-rows-brick__help-text">
												<h2>{intro.title}</h2>
												<p>{intro.text}</p>
											</div>
										) : (
											''
										)}
										<div className="brick-add-button">
											<AddBrickButton ref="addBrickButton" addBrick={this.addBrick} />
										</div>
									</div>
								)}
								{mainProps.renderChildren(childList, {
									includeAddBrickButton: true,
									includeEditButtons: true,
								})}
							</div>
						</div>
						<span className="close" onClick={onClose}>
							<Icon name="close-modal" size="normal" />
						</span>
					</VisibleImageContext.Provider>
				)}
			</div>
		);
		if (!show) {
			return null;
		}

		return (
			<BrickContext.Consumer>
				{(context) => {
					const editMode = context.editMode;
					if (editMode) {
						return layer;
					} else {
						this.mountDiv();
						return ReactDOM.createPortal(layer, this.containerLayerMoreInfo);
					}
				}}
			</BrickContext.Consumer>
		);
	}
}
LayerMoreInfo.propTypes = {
	mainProps: PropTypes.object,
	show: PropTypes.bool,
	onClose: PropTypes.func,
	childList: PropTypes.string,
};

LayerMoreInfo.defaultProps = {
	show: false,
	childList: 'default',
};
export default LayerMoreInfo;
