import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import ImagesHorizontalNavigation from './components/ImagesHorizontalNavigation';
import ImagesVerticalNavigation from './components/ImagesVerticalNavigation';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import brickImage from './img/brickPreview.jpg';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class GalleryVerticalWithText extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Galería con texto`;
	static brickIcon = 'carousel';
	static brickImage = brickImage;

	static brickDefaultData = {
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
				title: 'NOBISTA QUE DUS MEI',
				description:
					'Duci si sequi. Officim sinverum eostmaionsed vellant quam corrumque il. Quis magnimetur as imendit aut ea quatis ent Ibus no. Moluptium ducid ariscimod everios.',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
				title: 'DUS QUE MEI NOBISTA',
				description:
					'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi. Ibus no.',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
				title: 'MEI QUE NOBISTA DUS',
				description:
					'Vellant sinverum as imendit aut ea quatis si sequi quis. Moluptium magnimetur ent Ibus no. Quam corrumque officim eostmaionsed il. Duci ariscimod everios ducid.',
			},
		],
	};

	constructor(props) {
		super(props);
		this.state = {index: 0};
	}

	handleNextClick = () => {
		this.setState((prevState) => ({
			index: (prevState.index + 1) % this.props.brick.data.images.length,
		}));
	};

	handlePreviousClick = () => {
		this.setState((prevState) => ({
			index: prevState.index === 0 ? this.props.brick.data.images.length - 1 : prevState.index - 1,
		}));
	};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const images = data.images || [];
		const currentIndex = this.state.index;
		const {className} = this.props;

		const classes = classNames({
			'brick': true,
			'gallery-vertical-with-text': true,
			[`${className}`]: true,
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<WindowSizeContext.Consumer>
					{({device}) => {
						return (
							<div className={classes}>
								<ImagesHorizontalNavigation
									items={images}
									currentIndex={this.state.index}
									onNextClick={this.handleNextClick}
									onPreviousClick={this.handlePreviousClick}
									clickable={device === 'mobile' || device === 'mobileLandscape' ? false : true}
								/>

								<ImagesVerticalNavigation
									items={images}
									currentIndex={this.state.index}
									onNextClick={this.handleNextClick}
									onPreviousClick={this.handlePreviousClick}
								/>

								<div className="creditTextDownRight weigthForText">
									{images[currentIndex].imageObj.credit}
								</div>

								<div className="weigthForText" style={{fontWeight: 'bold'}}>
									<FrontEditableText
										fieldName="title"
										text={images[this.state.index].title}
										set={(fieldPath, fieldData) => {
											//Propagamos la llamada al método set añadiendo mi nodo al path
											if (this.props.set)
												this.props.set(['images', this.state.index].concat(fieldPath), fieldData);
										}}
									/>
								</div>
								<div className="weigthForText">
									<FrontEditableText
										fieldName="description"
										text={images[this.state.index].description}
										set={(fieldPath, fieldData) => {
											//Propagamos la llamada al método set añadiendo mi nodo al path
											if (this.props.set)
												this.props.set(['images', this.state.index].concat(fieldPath), fieldData);
										}}
									/>
								</div>
							</div>
						);
					}}
				</WindowSizeContext.Consumer>
			</Container>
		);
	}
}

export default brickWrapper(GalleryVerticalWithText);
