import {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
// import EffectButton from "./components/EffectButton";
import './styles.scss';

class EffectSelector extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		};
	}
	onClickEffect = (effect) => {
		this.props.setMainField(effect);
	};

	openSelector = () => {
		let listEffectsHeight = this.refs.listEffects.offsetHeight;
		if (this.state.open === false) {
			this.refs.effectSelector.classList.add('open');
			this.refs.effectSelector.style.height = listEffectsHeight + 'px';
			this.setState({open: true});
		} else {
			this.refs.effectSelector.classList.remove('open');
			this.refs.effectSelector.style.height = '42px';
			this.setState({open: false});
		}
	};
	render() {
		// let activeEffect = this.props.getMainField();
		// return (
		// 	<div className="edition-brick-effect-selector">
		// 		<label className="edition-brick-effect-selector__label">
		// 			{this.props.editionBrickStruct.title}
		// 		</label>
		// 		<div className="effect-selector" ref="effectSelector">
		// 			<ul className="list-effects" ref="listEffects">
		// 				<li className="name-active" onClick={this.openSelector}>
		// 					<span>{activeEffect || "Elige un efecto"}</span>
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Ninguno" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Zoom" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Bounce" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Fade" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Flip" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Rotate" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Slide left" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Slide right" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Slide top" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Slide bottom" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="LightSpeed" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Jump" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Flash" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="HeadShake" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Jello" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Pulse" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="RubberBand" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Shake" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Spin" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Swing" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Tada" />
		// 				</li>
		// 				<li>
		// 					<EffectButton onClickEffect={this.onClickEffect} optionName="Wobble" />
		// 				</li>
		// 			</ul>
		// 		</div>
		// 	</div>
		// );

		// Hasta que encontremos una solución para los efectos
		return null;
	}
}

export default editionBrickWrapper(EffectSelector);
