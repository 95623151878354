import React, {PureComponent} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from 'businessLogic/shared/Buttons/Button';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import './styles.scss';

class ConfirmPopUp extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
		};
		this.show = this.props.show;
	}

	handleAccept = () => {
		this.props.confirm(true);
	};

	handleCancel = () => {
		this.props.confirm(false);
	};
	render() {
		const mainStyleClass = 'confirm-pop-up';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			show: this.props.show,
		});

		const text = this.props.text || _('¿Deseas continuar?');

		return (
			<div className={classes}>
				<div className={mainStyleClass + '__content'}>
					<div className={mainStyleClass + '__content__message'}>
						<div className={mainStyleClass + '__content__message__warning'}>
							<span className={mainStyleClass + '__content__message__warning__icon'}>!</span>
						</div>

						{this.props.text && (
							<p className={mainStyleClass + '__content__message__text'}>{text}</p>
						)}
					</div>

					<div className={mainStyleClass + '__buttons'}>
						<div className={mainStyleClass + '__buttons--cancel'}>
							<Button theme="primary" view="outline" rounded={true} onClick={this.handleAccept}>
								{_('Salir')}
							</Button>
						</div>
						<div className={mainStyleClass + '__buttons--accept'}>
							<Button theme="primary" view="outline" rounded={true} onClick={this.handleCancel}>
								{_('Continuar')}
							</Button>
						</div>
					</div>
				</div>

				<div className={mainStyleClass + '__mask'} />
			</div>
		);
	}
}

ConfirmPopUp.propTypes = {
	text: PropTypes.string,
	show: PropTypes.bool,
	confirm: PropTypes.func,
};

export default ConfirmPopUp;
