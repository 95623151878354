import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import Icon from 'ui/shared/Icon';
import AnswerAnimatedIcon from 'ui/bricks/Icons/AnswerAnimatedIcon';
import classNames from 'classnames';
import './styles.scss';

class Response extends Component {
	renderCheck = () => {
		/*fallo a la primera . He terminado porque he fallado y no he hecho click sobre la soluciones verdaderas */
		if (this.props.feedbackCorrect !== undefined) {
			return (
				<AnswerAnimatedIcon answer={this.props.valueSolution === true ? 'success' : 'failure'} />
			);
		} else {
			if (this.props.disable && this.props.valueSolution) {
				return <Icon name="success" />;
			}
		}
	};
	render() {
		const mainStyleClass = 'response-test-basic-images-component';

		const classes = classNames({
			[`${mainStyleClass}`]: true,
			[`${mainStyleClass}--failure`]: this.props.feedbackCorrect === false,
			[`${mainStyleClass}--success`]:
				this.props.feedbackCorrect === true ||
				(this.props.feedbackCorrect === undefined &&
					this.props.disable &&
					this.props.valueSolution),
			[`${mainStyleClass}--disable`]: this.props.disable && this.props.feedbackCorrect === undefined
		});
		return (
			<figure className={classes} onClick={this.props.onClick}>
				<div className={mainStyleClass + '__image'}>
					<NewImageClickable imgObj={this.props.image} clickable={false} defaultVisible={true} />

					<div className={mainStyleClass + '__feedback'}>
						<div className="response-test-basic-images-component__icon">{this.renderCheck()}</div>
					</div>
				</div>
				{this.props.text && (
					<figcaption className={mainStyleClass + '__text'}>
						<FrontEditableText text={this.props.text} editable={false} />
					</figcaption>
				)}
			</figure>
		);
	}
}

/*feedbackCorrect: tiene un valor inicial de undefined , por eso no recibe ningún defaultProps */
Response.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	comment: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onClick: PropTypes.func,
	disable: PropTypes.bool,
	feedbackCorrect: PropTypes.bool,
	valueSolution: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

Response.defaultProps = {
	disable: false
};

export default Response;
