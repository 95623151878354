import React from 'react';
import {useSlate} from 'slate-react';
import ToggleButton from '../basics/ToggleButton';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';

const Icon = ({format}) => {
	switch (format) {
		case 'numbered-list':
			return <FormatListNumberedIcon />;
		case 'bulleted-list':
		default:
			return <FormatListBulletedIcon />;
	}
};

const listFormats = ['numbered-list', 'bulleted-list'];

const SetListButton = () => {
	const editor = useSlate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const {toggleBlock, getBlockFormat} = editor.helpers;

	const listFormat = getBlockFormat(editor, 'type');

	const isActive = listFormats.includes(listFormat);

	return (
		<>
			<ToggleButton
				format={'type'}
				onClick={(event) => {
					event.preventDefault();
					event.stopPropagation();
					if (anchorEl) {
						setAnchorEl(null);
						return;
					}

					setAnchorEl(event.currentTarget);
				}}
				selected={isActive}
			>
				<Icon format={listFormat} />
			</ToggleButton>
			<Popper
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => {
					setAnchorEl(null);
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				sx={{
					'zIndex': 1,
					'& .MuiPaper-root': {
						boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
					},
				}}
				modifiers={[
					{
						name: 'offset',
						enabled: true,
						options: {
							offset: [0, 0],
						},
					},
				]}
			>
				<Paper
					sx={{
						transition: 'opacity 0.75s',
						backgroundColor: '#ffffff',
						// padding: '5px 5px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<ToggleButton
						format="bulleted-list"
						selected={listFormat === 'bulleted-list'}
						onClick={(event) => {
							event.preventDefault();
							event.stopPropagation();
							toggleBlock(editor, 'bulleted-list', 'type');
							setAnchorEl(null);
						}}
					>
						<Icon format="bulleted-list" />
					</ToggleButton>
					<ToggleButton
						format="numbered-list"
						selected={listFormat === 'numbered-list'}
						onClick={(event) => {
							event.preventDefault();
							event.stopPropagation();
							toggleBlock(editor, 'numbered-list', 'type');
							setAnchorEl(null);
						}}
					>
						<Icon format="numbered-list" />
					</ToggleButton>
				</Paper>
			</Popper>
		</>
	);
};

export default SetListButton;
