import React from 'react';
import * as Sentry from '@sentry/react';

export default {
	debug: (...args) => {
		if (process.env.NODE_ENV !== 'production') {
			// eslint-disable-next-line no-console
			console.log(...args);
		}
	},
	warn: (...args) => {
		console.warn(...args);
	},
	error: (...args) => {
		console.error(...args);
	},
	captureException: (err, context) => {
		Sentry.captureException(err, context);
	},
	// Crea un error con la cadena pasada antes de enviar a Sentry
	captureError: (err, context) => {
		Sentry.captureException(new Error(err), context);
	},
	captureMessage: (err, context) => {
		Sentry.captureMessage(err, context);
	},
	setUser: (user) => {
		if (!user) return;

		// Por defecto solo enviamos el id del usuario
		const userToLog = {
			id: user.id,
		};
		// Si es superAdmin, enviamos también el email
		if (user.superAdmin) {
			userToLog.email = user.email;
		}

		Sentry.setUser(userToLog);

		// Establecemos tags relativos al usuario
		Sentry.setTag('admin', Boolean(user.admin));
		Sentry.setTag('superAdmin', Boolean(user.superAdmin));
		Sentry.setTag('adminAsUser', Boolean(user.adminAsUser));
		Sentry.setTag('adminAsGroup', Boolean(user.adminAsGroup));
	},
	clearUser: () => {
		Sentry.configureScope((scope) => {
			scope.setUser(null);
			scope.setTag('admin', null);
			scope.setTag('superAdmin', null);
			scope.setTag('adminAsUser', null);
			scope.setTag('adminAsGroup', null);
		});
	},
	setTag: (key, value) => {
		Sentry.setTag(key, value);
	},
	setContext: (key, value) => {
		Sentry.setContext(key, value);
	},
	ErrorBoundary: ({fallback, children, ...props}) => {
		return (
			<Sentry.ErrorBoundary fallback={fallback} {...props}>
				{children}
			</Sentry.ErrorBoundary>
		);
	},
};
