import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import VimeoDefault from './components/VimeoDefault';
import VimeoBackground from './components/VimeoBackground';
import PropTypes from 'prop-types';
import './styles.scss';

class VideoVimeo extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			viewImageVideo: true,
			videoTime: '--:--',
			paused: true,
		};
	}
	render() {
		const {videoCaption, description, credit, video, onPlay, onPause, onEnd} = this.props;

		// eslint-disable-next-line max-len
		let regExp =
			/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
		let matchVimeo = video.match(regExp);
		let vimeoID = '';
		if (matchVimeo) {
			vimeoID = matchVimeo[3];
		}
		return (
			<div className="video-vimeo-container" ref="videoContainer" key={vimeoID}>
				<div
					className={
						'video-vimeo-container__video-embed' +
						(this.props.autoPlay ? ' background ' : ' default ')
					}
				>
					{this.props.autoPlay === false ||
						(this.props.autoPlay === undefined && (
							<VimeoDefault
								vimeoID={vimeoID}
								paused={this.state.paused}
								image={videoCaption}
								description={description}
								credit={credit}
								onPlay={onPlay}
								onPause={onPause}
								onEnd={onEnd}
							/>
						))}
					{this.props.autoPlay === true && (
						<VimeoBackground
							vimeoID={vimeoID}
							paused={this.state.paused}
							muted={this.props.muted}
							videoLoop={this.props.videoLoop}
						/>
					)}
				</div>
			</div>
		);
	}
	componentDidMount() {
		this.changePauseState();
	}
	componentDidUpdate() {
		this.changePauseState();
	}

	changePauseState = () => {
		if (this.props.autoPlay === true) {
			this.setState({paused: false});
		} else {
			this.setState({paused: true});
		}
	};
}

VideoVimeo.propTypes = {
	videoCaption: PropTypes.string,
	description: PropTypes.string,
	credit: PropTypes.string,
	video: PropTypes.string,
	controls: PropTypes.bool,
	onPlay: PropTypes.func,
	onPause: PropTypes.func,
	onEnd: PropTypes.func,
};

VideoVimeo.defaultProps = {
	videoCaption: '',
	description: '',
	credit: '',
	video: '',
	controls: true,
	onPlay: () => {},
	onPause: () => {},
	onEnd: () => {},
};

export default brickWrapper(VideoVimeo);
