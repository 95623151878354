import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import PropTypes from 'prop-types';
import './styles.scss';

const modalRoot = document.getElementById('portals');

class PlayButtonAnimation extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			play: false,
			counter: 0,
			firstTime: true,
		};
		this.containerPlay = document.createElement('div');
	}
	componentDidMount() {
		if (modalRoot) modalRoot.appendChild(this.containerPlay);
	}
	componentWillUnmount() {
		if (modalRoot && modalRoot.contains(this.containerPlay)) {
			modalRoot.removeChild(this.containerPlay);
		}
	}
	render() {
		const play = classNames({
			'play-state': this.props.playState,
			'pause-state': !this.props.playState,
		});
		const show = classNames({
			'edit': this.props.editMode,
			'show': this.props.show,
			'sticky': this.props.sticky,
			'relative': !this.props.sticky,
			'hide': this.props.hideButton,
			'first-time': this.props.counter === 0 && this.props.animation,
		});

		const playButton = (
			<div
				className={'play-button-animation ' + play + ' ' + show}
				onClick={this.props.handlePlayPause}
			>
				{this.props.show && (
					<React.Fragment>
						<span className="play-button-animation__bar-wrap">
							<span className="play-button-animation__bar-wrap__bar--one">
								<i />
								<em />
							</span>
							<span className="play-button-animation__bar-wrap__bar--two">
								<i />
								<em />
							</span>
						</span>
						<div
							className="play-button-animation__back-panel"
							onClick={this.props.handlePlayPause}
						/>
					</React.Fragment>
				)}
			</div>
		);

		if (this.props.sticky) {
			return ReactDOM.createPortal(playButton, this.containerPlay);
		} else {
			return playButton;
		}
	}
}

PlayButtonAnimation.propTypes = {
	handlePlayPause: PropTypes.func,
	show: PropTypes.bool,
	playState: PropTypes.bool,
	sticky: PropTypes.bool,
	relative: PropTypes.bool,
	counter: PropTypes.number,
	animation: PropTypes.bool,
};

PlayButtonAnimation.defaultProps = {
	show: false,
	playState: false,
	sticky: false,
	relative: false,
	counter: 0,
	animation: true,
};

export default brickWrapper(PlayButtonAnimation);
