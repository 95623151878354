import React, {PureComponent} from 'react';
import Logger from 'businessLogic/services/Logger';
import AddFile from 'businessLogic/shared/Buttons/AddFile';
import ThumbnailBox from 'businessLogic/shared/Image/ThumbnailBox';
import Loading from 'ui/scope/admin/Loading';
import Dropzone from 'react-dropzone';
import {IMAGES_QUERY, IMAGE_NUMBER_QUERY, IMAGE_ADD_MUTATION, IMAGE_DELETE_MUTATION} from './query';
import {graphql} from 'react-apollo';
import {compose} from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import PropTypes from 'prop-types';
import pdfImageUrl from 'ui/shared/images/pdf-image.svg';
import entityManager from 'businessLogic/services/EntityManager';
import './styles.scss';

class FilesList extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			filesInProcess: [],
			scrollToFetch: 0,
			page: 1,
		};

		this.uploadingFileId = 0;
	}

	isImageType = (file) => {
		const fileType = file.type;
		return (
			fileType === 'image/jpeg' ||
			fileType === 'image/png' ||
			fileType === 'image/gif' ||
			fileType === 'image/bmp'
		);
	};

	getImageDimensions = async (file) => {
		return new Promise((resolve, reject) => {
			if (!this.isImageType(file)) resolve(null);
			const img = new Image();
			img.onload = () => {
				resolve({width: img.width, height: img.height});
			};
			img.onerror = () => {
				reject();
			};
			img.src = URL.createObjectURL(file);
		});
	};

	isValidImage = (imgType, imgSize, imgWidth, imgHeight) => {
		const requiredWidth = get(this.props, 'filter.width');
		const requiredHeight = get(this.props, 'filter.height');
		switch (imgType) {
			case 'gif':
				if (imgSize && imgSize > 5242880) {
					this.imageError = t`Los gifs deben tener un tamaño inferior a 5MB.`;
					return false;
				}
				break;
			default:
				//Restringimos las imágenes de más de 25 megapíxeles
				if (imgWidth && imgHeight && imgWidth * imgHeight > 30000000) {
					this.imageError = t`Las imágenes deben tener menos de 30 megapíxeles`;
					return false;
				}
				if (
					(requiredWidth && requiredWidth !== imgWidth) ||
					(requiredHeight && requiredHeight !== imgHeight)
				) {
					this.imageError = t`Solo se admiten imágenes de ${requiredWidth}x${requiredHeight}`;
					return false;
				}
				break;
		}

		return true;
	};

	upload = async (file) => {
		//Obtenemos el tamaño del fichero a subir para ver si supera el límite
		const fileSize = file.size;
		const sizeLimit = this.isImageType(file)
			? entityManager.getImageUploadSizeLimit()
			: entityManager.getFileUploadSizeLimit();
		if (fileSize > sizeLimit * 1024 * 1024) {
			alert(
				t`El fichero que estás intentando subir pesa más de ${sizeLimit}MB. Por favor, reduce el tamaño y vuelve a intentarlo.`,
			);
			return;
		}

		const dimensions = await this.getImageDimensions(file);
		if (
			!this.isValidImage(file.type.split('/')[1], file.size, dimensions?.width, dimensions?.height)
		) {
			alert(this.imageError);
			return;
		}

		// Solicitamos la url de carga
		const uploadLinkFetchPromise = fetch('/getUploadLink', {
			method: 'post',
			credentials: 'same-origin',
			mode: 'same-origin',
			body: JSON.stringify({filename: file.name, type: file.type}),

			headers: {
				'Content-Type': 'application/json',
			},
		});

		// Obtenemos la url de carga
		const uploadLinkFetchRes = await uploadLinkFetchPromise;

		const uploadLinkFetchJson = await uploadLinkFetchRes.json();

		if (uploadLinkFetchJson.error !== 0) {
			Logger.captureError('Error al obtener la url de carga para subir el fichero', (scope) => {
				return scope;
			});
			alert(
				t`Se ha producido un error al subir el fichero. Prueba a recargar la página y volver a intentarlo. Si el problema persiste contacta con el administrador de la plataforma.`,
			);
		}

		const {url, relPath} = uploadLinkFetchJson;

		// Info del video que vamos a subir
		const fileToUpload = {
			id: ++this.uploadingFileId,
			file,
			progress: 0,
		};

		//Añadimos un audio dummy para que el usuario sepa que se está subiendo
		this.setState((state) => {
			const filesInProcess = [].concat(state.filesInProcess);
			filesInProcess.push(fileToUpload);
			return {filesInProcess};
		});

		// TODO: Obtener la duración del video y guardarla en base de datos
		// https://stackoverflow.com/questions/29285056/get-video-duration-when-input-a-video-file/29285597

		const ajax = new XMLHttpRequest();
		ajax.upload.addEventListener(
			'progress',
			(event) => {
				fileToUpload.progress = Math.round((100 * event.loaded) / event.total);
				this.setState((state) => {
					const filesInProcess = [].concat(state.filesInProcess);
					const fileIndex = filesInProcess.findIndex(
						(fileInProcess) => fileInProcess.id === fileToUpload.id,
					);

					if (fileIndex < 0) return;

					filesInProcess[fileIndex] = Object.assign({}, fileToUpload);
					return {filesInProcess};
				});
			},
			false,
		);
		ajax.addEventListener(
			'load',
			() => {
				if (ajax.status === 200) {
					// Obtenemos el nombre del fichero de relPath
					const relPathParts = relPath.split('/');
					const relPathPartsLength = relPathParts.length;
					const fileName = relPathParts[relPathPartsLength - 1];
					this.props
						.addNewImage({
							input: {
								name: fileName,
								path: relPath,
								fileSize: fileSize,
								width: dimensions?.width,
								height: dimensions?.height,
							},
						})
						.then(() => {
							this.removeLoading(fileToUpload);
						});
					return;
				}

				if (ajax.status === 413) {
					alert(
						t`El fichero que estás intentando subir pesa demasiado. Por favor, reduce el tamaño y vuelve a intentarlo.`,
					);
					//Eliminamos el audio dummy
					this.removeLoading(fileToUpload);
					return;
				}
				Logger.captureError('Error al subir fichero', (scope) => {
					scope.setContext('ajax', {status: ajax.status, response: ajax.response});
					return scope;
				});
				alert(
					t`Se ha producido un error al subir el fichero. Prueba a recargar la página y volver a intentarlo. Si el problema persiste contacta con el administrador de la plataforma.`,
				);
				this.removeLoading(fileToUpload);
			},
			false,
		);
		ajax.addEventListener(
			'error',
			(event) => {
				Logger.captureError('Evento de error al subir fichero', (scope) => {
					scope.setContext('event', {event});
					return scope;
				});
				console.error('Error al subir video', event);
				alert(
					t`Se ha producido un error al subir el fichero. Prueba a recargar la página y volver a intentarlo. Si el problema persiste contacta con el administrador de la plataforma.`,
				);
				this.removeLoading(fileToUpload);
			},
			false,
		);
		ajax.addEventListener(
			'abort',
			(event) => {
				console.error('Subida de fichero abortada', event);
				this.removeLoading(fileToUpload);
			},
			false,
		);

		ajax.open('PUT', url, true);
		ajax.setRequestHeader('Content-Type', file.type);
		ajax.send(file);
	};

	onDrop = (files) => {
		files.forEach(this.upload);
	};

	handleUpload = () => {
		this.uploadInput.click();
	};

	onUpload = (ev) => {
		ev.preventDefault();

		const files = this.uploadInput.files;
		Object.values(files).forEach(this.upload);
	};

	removeLoading = (fileToUpload) => {
		this.setState((state) => {
			const filesInProcess = [].concat(state.filesInProcess);
			const fileIndex = filesInProcess.findIndex(
				(fileInProcess) => fileInProcess.id === fileToUpload.id,
			);

			if (fileIndex < 0) return;

			filesInProcess.splice(fileIndex, 1);

			return {filesInProcess};
		});
	};

	deleteImage = async (image) => {
		let mutationDelete = await this.props.deleteImage({
			idImage: image.id,
		});
		/**TODO faltaría darle estilos a no poder borrar la imagen */
		if (!get(mutationDelete, 'data.deleteImage.deleted')) {
			alert(t`No se puede borrar la imagen, ya que está siendo usada en alguna pildora`);
		} else {
			this.props.images.refetch();
		}
	};

	getFiles = () => {
		let renderImagesLoading;
		const loading = get(this.props, 'images.loading');
		const images = get(this.props, 'images.images_v2');
		if (loading) {
			renderImagesLoading = <Loading />;
			return renderImagesLoading;
		}
		if (!images || images.length === 0) {
			renderImagesLoading = <div>No hay ninguna imagen aún.</div>;
			return renderImagesLoading;
		}

		if (this.state.filesInProcess.length > 0) {
			renderImagesLoading = this.state.filesInProcess.map(() => {
				return (
					<ThumbnailBox
						className="normal"
						imageUrl="https://via.placeholder.com/150x150"
						key={`${images.length + this.state.filesInProcess.length}key`}
					/>
				);
			});
		}
		let renderImages;
		renderImages = images
			.map((image) => {
				let srcImageBox;

				//obtener el nombre del fichero pdf para ponerlo con el thumbnail
				const pathObject = image.path.split('/');
				//obtener tamaño de objeto devuelto por split
				Object.size = function (obj) {
					var size = 0,
						key;
					for (key in obj) {
						if (obj.hasOwnProperty(key)) size++;
					}
					return size;
				};
				let size = Object.size(pathObject);

				//el nombre del fichero siempre será la última posición del objeto devuelto por split
				let newFileName = pathObject[--size];

				//si el archivo es pdf y el tipo de archivo a insertar es pdf
				if (this.props.fileType === 'pdf') {
					srcImageBox = pdfImageUrl;
					return (
						<ThumbnailBox
							className="normal"
							imageUrl={srcImageBox}
							onDelete={() => this.deleteImage(image)}
							key={image.id}
							onClick={this.props.onSelect}
							imgId={image.path}
							subtitle={newFileName}
						/>
					);
				}

				//si el archivo no es pdf y el tipo de archivo tampoco
				if (this.props.fileType === 'img') {
					srcImageBox = image.path;
					return (
						<ThumbnailBox
							className="normal"
							imageUrl={srcImageBox}
							onDelete={() => this.deleteImage(image)}
							key={image.id}
							onClick={this.props.onSelect}
							imgId={image.path}
						/>
					);
				}

				return null;
			})
			.filter((image) => !!image);

		if (renderImages.length === 0)
			if (Array.isArray(renderImagesLoading) && renderImagesLoading.length > 0) {
				renderImages = renderImagesLoading.concat(renderImages);
			}
		return renderImages;
	};

	loadMoreImages = (page) => {
		this.props.fetchMoreToImages(page);
	};

	hasMore = () => {
		if (this.props.images && this.props.images.images_v2) {
			return this.props.images.images_v2.length < this.props.imageNumber;
		}

		return false;
	};

	render() {
		//segun el prop fileType deberá mostrarse un tipo de documentos u otros

		const acceptFile = this.props.fileType === 'pdf' ? 'application/pdf' : 'image/*';

		return (
			<Dropzone onDrop={this.onDrop} disableClick={true} activeStyle={{backgroundColor: '#CEE3F6'}}>
				{({getRootProps}) => (
					<div className="add-image-thumbnails-box" {...getRootProps()}>
						<AddFile
							className="normal-gallery"
							handleUploadImage={this.handleUpload}
							fileType={this.props.fileType}
						/>
						<InfiniteScroll
							pageStart={1}
							loadMore={this.loadMoreImages}
							initialLoad={false}
							hasMore={this.hasMore()}
							loader={<Loading key={0} />}
							useWindow={false}
						>
							{this.getFiles()}
						</InfiniteScroll>
						<input
							style={{display: 'none'}}
							ref={(ref) => {
								this.uploadInput = ref;
							}}
							type={'file'}
							accept={acceptFile} //al pulsar añadir archivo se mostrará segun el prop fileType
							hidden={true}
							onChange={this.onUpload}
							multiple
						/>
					</div>
				)}
			</Dropzone>
		);
	}
}

const IMAGES_LIMIT = 20;

const withDataAndMutation = compose(
	graphql(IMAGES_QUERY, {
		name: 'images',
		options: (props) => {
			return {
				variables: {
					offset: 1,
					limit: IMAGES_LIMIT,
					width: get(props, 'filter.width'),
					height: get(props, 'filter.height'),
					type: get(props, 'fileType'),
				},
			};
		},
		props: (props) => {
			return {
				...props,
				fetchMoreToImages: (page) => {
					return props.images.fetchMore({
						variables: {
							offset: page,
						},
						updateQuery: (prev, {fetchMoreResult}) => {
							if (!fetchMoreResult.images_v2 || fetchMoreResult.images_v2.length === 0) {
								return prev;
							}
							let arrImageNew = [];
							for (var i in prev.images_v2) {
								var shared = false;
								for (var j in fetchMoreResult.images_v2)
									if (fetchMoreResult.images_v2[j].id === prev.images_v2[i].id) {
										shared = true;
										break;
									}
								if (!shared) arrImageNew.push(prev.images_v2[i]);
							}
							arrImageNew = arrImageNew.concat(fetchMoreResult.images_v2);
							return Object.assign(
								{},
								{
									images_v2: arrImageNew,
								},
							);
						},
					});
				},
			};
		},
	}),
	graphql(IMAGE_NUMBER_QUERY, {
		name: 'imageNumber',
		options: (props) => {
			return {
				variables: {
					width: get(props, 'filter.width'),
					height: get(props, 'filter.height'),
					type: get(props, 'fileType'),
				},
			};
		},
		props: ({imageNumber}) => ({
			loading: imageNumber.loading,
			imageNumber: imageNumber.imageNumber,
		}),
	}),
	graphql(IMAGE_ADD_MUTATION, {
		props({mutate}) {
			return {
				addNewImage({input}) {
					return mutate({
						variables: {input},
						updateQueries: {
							Images: (prev, {mutationResult}) => {
								const newImage = mutationResult.data.addImage_v2;
								return Object.assign(
									{},
									{
										images_v2: [newImage, ...prev.images_v2],
									},
								);
							},
						},
					});
				},
			};
		},
	}),
	graphql(IMAGE_DELETE_MUTATION, {
		props({mutate}) {
			return {
				deleteImage({idImage}) {
					return mutate({
						variables: {idImage},
						update: (store, {data: {deleteImage}}) => {
							const data = store.readQuery({
								query: IMAGES_QUERY,
								variables: {offset: 1, limit: IMAGES_LIMIT},
							});
							if (deleteImage.deleted) {
								data.images.some(function (item, index) {
									return data.images[index]['id'] === deleteImage.id
										? !!data.images.splice(index, 1)
										: false;
								});
							}
							store.writeQuery({
								query: IMAGES_QUERY,
								data,
								variables: {offset: 1, limit: IMAGES_LIMIT},
							});
						},
					});
				},
			};
		},
	}),
)(FilesList);

FilesList.propTypes = {
	fileType: PropTypes.oneOf(['pdf', 'img']),
};

FilesList.defaultProps = {
	fileType: 'img',
};

export default withDataAndMutation;
