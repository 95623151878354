import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import Button from 'businessLogic/shared/Buttons/Button';
import './styles.scss';

class ButtonLoadInSelector extends PureComponent {
	render() {
		const {iconName, nameButton, onClick, className} = this.props;
		const classes = classNames({
			'edit__load-option-button': true,
			[`${className}`]: true
		});
		return (
			<div className={classes} onClick={onClick}>
				<div className="back-white-box">
					<Button theme="default" view="link" className="back-white">
						<Icon name={iconName} size="large" />
					</Button>
				</div>

				{nameButton}
			</div>
		);
	}
}

ButtonLoadInSelector.propTypes = {
	className: PropTypes.string,
	iconName: PropTypes.string,
	nameButton: PropTypes.string,
	onClick: PropTypes.func
};

ButtonLoadInSelector.defaultProps = {
	className: '',
	iconName: '',
	nameButton: ''
};

export default ButtonLoadInSelector;
