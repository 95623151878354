import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class SixImageComposition extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Composición de seis imágenes`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault4Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault5Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault6Opt.svg',
					credit: '',
				},
			},
		],
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const images = data.images || [];

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'SixImageComposition']);

		const classes = classNames({
			'brick': true,
			'six-image-composition-brick': true,
		});

		let credits = images.map((item, index) => {
			if (item.imageObj.credit) {
				return (
					<li key={index}>
						<span>{index + 1 + '. ' + item.imageObj.credit}</span>
					</li>
				);
			}
			return null;
		});

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div ref="partLeft" className="six-image-composition-brick__part-left">
						<div className="part-top">
							<div className="image-wrap image1">
								<Animation
									playAnimation={this.state.playEffect}
									delay={2.25}
									effect={Array.isArray(brickAnimation) ? brickAnimation[2] : brickAnimation}
								>
									<div className="the-img">
										<NewImageClickable imgObj={images[0].imageObj} />
									</div>

									{(images[0].imageObj.footer || images[0].imageObj.credit) && (
										<div className="image-caption">
											<div className="caption-footer"> {images[0].imageObj.footer} </div>
											<div className="caption-credit"> {images[0].imageObj.credit} </div>
										</div>
									)}
								</Animation>
							</div>
							<div className="image-wrap image2">
								<Animation
									playAnimation={this.state.playEffect}
									delay={2}
									effect={Array.isArray(brickAnimation) ? brickAnimation[3] : brickAnimation}
								>
									<div className="the-img">
										<NewImageClickable imgObj={images[1].imageObj} />
									</div>

									{(images[1].imageObj.footer || images[1].imageObj.credit) && (
										<div className="image-caption">
											<div className="caption-footer"> {images[1].imageObj.footer} </div>
											<div className="caption-credit"> {images[1].imageObj.credit} </div>
										</div>
									)}
								</Animation>
							</div>
						</div>
						<div className="part-bottom">
							<div className="image-wrap image3">
								<Animation
									playAnimation={this.state.playEffect}
									delay={1.5}
									effect={Array.isArray(brickAnimation) ? brickAnimation[1] : brickAnimation}
								>
									<div className="the-img">
										<NewImageClickable imgObj={images[2].imageObj} />
									</div>

									{(images[2].imageObj.footer || images[2].imageObj.credit) && (
										<div className="image-caption">
											<div className="caption-footer"> {images[2].imageObj.footer} </div>
											<div className="caption-credit"> {images[2].imageObj.credit} </div>
										</div>
									)}
								</Animation>
							</div>
						</div>
					</div>
					<div ref="partRight" className="six-image-composition-brick__part-right">
						<div className="part-top">
							<div className="image-wrap image4">
								<Animation
									playAnimation={this.state.playEffect}
									delay={1}
									effect={Array.isArray(brickAnimation) ? brickAnimation[0] : brickAnimation}
								>
									<div className="the-img">
										<NewImageClickable imgObj={images[3].imageObj} />
									</div>

									{(images[3].imageObj.footer || images[3].imageObj.credit) && (
										<div className="image-caption">
											<div className="caption-footer"> {images[3].imageObj.footer} </div>
											<div className="caption-credit"> {images[3].imageObj.credit} </div>
										</div>
									)}
								</Animation>
							</div>
						</div>
						<div className="part-bottom">
							<div className="image-wrap image5">
								<Animation
									playAnimation={this.state.playEffect}
									delay={2.5}
									effect={Array.isArray(brickAnimation) ? brickAnimation[4] : brickAnimation}
								>
									<div className="the-img">
										<NewImageClickable imgObj={images[4].imageObj} />
									</div>

									{(images[4].imageObj.footer || images[4].imageObj.credit) && (
										<div className="image-caption">
											<div className="caption-footer"> {images[4].imageObj.footer} </div>
											<div className="caption-credit"> {images[4].imageObj.credit} </div>
										</div>
									)}
								</Animation>
							</div>
							<div className="image-wrap image6">
								<Animation
									playAnimation={this.state.playEffect}
									delay={2.75}
									effect={Array.isArray(brickAnimation) ? brickAnimation[5] : brickAnimation}
								>
									<div className="the-img">
										<NewImageClickable imgObj={images[5].imageObj} />
									</div>

									{(images[5].imageObj.footer || images[5].imageObj.credit) && (
										<div className="image-caption">
											<div className="caption-footer"> {images[5].imageObj.footer} </div>
											<div className="caption-credit"> {images[5].imageObj.credit} </div>
										</div>
									)}
								</Animation>
							</div>
						</div>
					</div>
				</div>
				<ul className={classes + '__credits'}>{credits}</ul>
			</Container>
		);
	}
}

export default brickWrapper(SixImageComposition);
