import React, {Component} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import ImageSelectorDialog from 'businessLogic/shared/MediaSelector/ImageSelectorDialog';
import LabelForInput from 'businessLogic/shared/Input/LabelForInput';
import Input from 'ui/shared/FormFields/Input';
import EditImageBox from 'businessLogic/shared/Image/EditImageBox';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';

import './styles.scss';

class Image extends Component {
	static getDefaultValue = (editionBrickStruct) => {
		const defaultValue = {
			imageSrc: editionBrickStruct.defaultImage || '',
			footer: editionBrickStruct.defaultFooter || '',
			credit: editionBrickStruct.defaultCredit || '',
		};

		return defaultValue;
	};
	handleDelete = () => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'imageSrc'], '');
	};

	handleReplace = () => {
		this.refs.imageSelectorDialog.show();
	};

	handleNewImage = (imageSrc) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'imageSrc'], imageSrc);
	};

	handleChangeFooter = (event) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'footer'], event.target.value);
	};

	handleChangeCredit = (event) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'credit'], event.target.value);
	};

	handleChangeCenterPosition = (positionObj) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'centerPosition'], positionObj);
	};

	render() {
		const editionBrickStruct = this.props.editionBrickStruct;
		const showCredit = editionBrickStruct.credit !== undefined ? editionBrickStruct.credit : true;
		const showFooter = editionBrickStruct.footer !== undefined ? editionBrickStruct.footer : false;

		let srcImage = this.props.get([editionBrickStruct.fieldName, 'imageSrc']);
		let valueCredit = this.props.get([editionBrickStruct.fieldName, 'credit']);
		let valueFooter = this.props.get([editionBrickStruct.fieldName, 'footer']);
		let valueCenterPosition = this.props.get([editionBrickStruct.fieldName, 'centerPosition']);

		//Parche para los ladrillos ya creados anteriormente
		if (srcImage === undefined) srcImage = this.props.get('image');
		if (valueCredit === undefined) valueCredit = this.props.get('credit');
		if (valueFooter === undefined) valueFooter = this.props.get('footer');
		if (valueCenterPosition === undefined) valueCenterPosition = this.props.get('centerPosition');

		return (
			<div className="edition-brick-image ">
				{editionBrickStruct.title && (
					<label className="edition-brick-image__label">{editionBrickStruct.title}</label>
				)}
				<div className="top-info">
					<div className="image">
						<EditImageBox
							srcImage={srcImage}
							valueCenterPosition={valueCenterPosition}
							onDelete={this.handleDelete}
							onReplace={this.handleReplace}
							handleChangeCenterPosition={this.handleChangeCenterPosition}
							small={true}
							editable={true}
						/>
					</div>
					<ul className="image-info">
						{showFooter && (
							<li className="img-footer">
								<LabelForInput text={t`Pie de foto:`} />
								<Input
									name="pie-foto"
									type="text"
									placeholder={t`Agrega un pie de foto`}
									value={valueFooter}
									onChange={this.handleChangeFooter}
								/>
							</li>
						)}
						{showCredit && (
							<li className="img-credit">
								<LabelForInput text={t`Crédito:`} />
								<Input
									name="credito-foto"
									type="text"
									placeholder={t`Introduce el crédito`}
									value={valueCredit}
									onChange={this.handleChangeCredit}
								/>
							</li>
						)}
					</ul>
				</div>
				<ImageSelectorDialog ref="imageSelectorDialog" onSelect={this.handleNewImage} />
			</div>
		);
	}
}

export default editionBrickWrapper(Image);
