import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'TextEditor',
						fieldName: 'title',
						title: t`Título`,
						placeholder: t`Introduce el título`,
					},
					{
						type: 'TextEditor',
						fieldName: 'text',
						title: t`Texto`,
						placeholder: t`Introduce el texto`,
					},
				],
			},
			{
				type: 'Tab',
				title: 'Estilos',
				fieldName: 'styles',
				content: [
					{
						type: 'ColorBg',
						fieldName: ['containerStyle', 'colorBg'],
					},
					{
						type: 'ImageBg',
						fieldName: ['containerStyle', 'imageBg'],
					},

					{
						type: 'ColorLayerOpacity',
						fieldName: ['containerStyle', 'colorLayerOpacity'],
					},
					{
						type: 'PaddingTop',
						fieldName: ['containerStyle', 'paddingTop'],
					},
					{
						type: 'PaddingBottom',
						fieldName: ['containerStyle', 'paddingBottom'],
					},
					{
						type: 'ThemeSelector',
						fieldName: ['containerStyle', 'themeSelected'],
					},
				],
			},
		],
	},
];
export default editionFields;
