//Funciones que devuelven partes del store, partiendo del store global
import get from 'lodash/get';

//Obtiene el listado del píldoras
export const getPills = (state) => get(state, ['dataGeneral', 'pills']);

//Obtiene una píldora concreta a través de su id
export const getPill = (state, pillId) =>
	get(state, ['dataGeneral', 'pills']).find((value) => value.id === pillId);

//Obtiene una píldora concreta a través de su slug
export const getPillBySlug = (state, pillSlug) =>
	get(state, ['dataGeneral', 'pills']).find((value) => value.slug === pillSlug);

//Obtiene el índice de una píldora concreta a partir de su id dentro del listado de píldoras
export const getPillIndex = (state, pillId) =>
	get(state, ['dataGeneral', 'pills']).findIndex((value) => value.id === pillId);

//Obtiene un ladrillo a partir de su id
export const getBrick = (state, pillId, brickId) => {
	const bricks = get(getPill(state, pillId), ['content', 'bricks']);
	if (!bricks) return null;
	return bricks.find((value) => value.id === brickId);
};

// Obtiene el índice de un ladrillo concreto a partir de su id dentro del
// listado de ladrillos de una píldora
export const getBrickIndex = (state, pillId, brickId) =>
	get(getPill(state, pillId), ['content', 'bricks']).findIndex((value) => value.id === brickId);
