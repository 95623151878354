import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import Modal from 'businessLogic/shared/Modals/Modal';
import DialogHeader from './components/DialogHeader';
import DialogBody from './components/DialogBody';
import DialogFooter from './components/DialogFooter';
import classNames from 'classnames';
import './styles.scss';

let dialogCounter = 1;
class Dialog extends PureComponent {
	constructor(props) {
		super(props);
		this.customClass = `dialog_${dialogCounter++}`;
	}

	onReady = () => {
		if (this.props.onReady) {
			this.props.onReady();
			return;
		}

		if (this.props.onCancel) {
			this.props.onCancel();
		}
	};

	render() {
		const {
			className,
			size,
			title,
			visible,
			onCancel,
			children,
			mask,
			noDraggable,
			footer,
		} = this.props;
		const classes = classNames({
			modal__content: true,
			[`modal__content--${size}`]: true,
			[`${className}`]: true,
		});
		return (
			<Modal
				visible={visible}
				onCancel={onCancel}
				mask={mask}
				noDraggable={noDraggable}
				handleSelector={`.${this.customClass}`}
				zIndex={this.props.zIndex}
			>
				<div className={classes}>
					<DialogHeader text={title} onClose={onCancel} className={this.customClass} />
					<DialogBody>{children}</DialogBody>
					<DialogFooter onClose={this.onReady} footer={footer} />
				</div>
			</Modal>
		);
	}
}

Dialog.propTypes = {
	className: PropTypes.string,
	size: PropTypes.oneOf(['small', 'normal', 'large']),
	title: PropTypes.string,
	visible: PropTypes.bool.isRequired,
	onCancel: PropTypes.func,
	mask: PropTypes.bool,
	noDraggable: PropTypes.bool,
	footer: PropTypes.bool,
	zIndex: PropTypes.number,
};

Dialog.defaultProps = {
	visible: false,
	size: 'small',
	mask: false,
	footer: true,
};

export default Dialog;
