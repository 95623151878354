import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import AudioBox from 'businessLogic/core/shared/Audio/AudioBox';
import Container from 'businessLogic/core/shared/Container/Container';
import sampleAudioUrl from './SampleAudio.mp3';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class Audio extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Audio`;
	static brickImage = brickImage;

	static brickDefaultData = {
		audio: {
			audioSrc: sampleAudioUrl,
			credit: '',
			description: '',
		},
	};

	static brickDefaultConfig = {};
	render() {
		const classes = classNames({
			'brick': true,
			'audio-brick': true,
		});

		const data = get(this.props, ['brick', 'data']);
		const styles = get(this.props, ['brick', 'styles'], {});
		const audio = get(data, 'audio');

		return (
			<Container
				size="normal"
				styleConfig={get(styles, 'containerStyle')}
				scrollEffect={get(styles, 'scrollEffect')}
			>
				<div className={classes}>
					<AudioBox
						src={audio.audioSrc}
						loop={data.loop}
						onPlay={() => {
							this.props.configObject.stopBackgroundAudio();
							this.props.brickEvent('play');
						}}
						onPause={() => {
							this.props.brickEvent('pause');
						}}
						onEnd={() => {
							this.props.brickEvent('end');
						}}
					/>
					<div> {audio.description} </div>
					<div> {audio.credit} </div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(Audio);
