import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {imageUrl} from 'businessLogic/helpers/images';
import Icon from 'ui/shared/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import './styles.scss';

class UploadingVideoRow extends Component {
	render() {
		const {videoTitle, thumbnailImg, progress, className} = this.props;
		const classes = classNames({
			[`${className}`]: true,
		});
		return (
			<div className={classes}>
				<div className="edit__video-box-row">
					<div className="video-list-uploading-video-item">
						<div className="mini-video-col">
							<div className="mini-video-container">
								<img alt="" src={imageUrl(thumbnailImg)} />
								<div className="mini-play-button">
									<Icon name="play-mini" size="small" />
								</div>
							</div>
						</div>
						<div className="video-title-author">
							<span className="video-title">{videoTitle}</span>
							<br />
							<br />
							<LinearProgress
								className="video-upload-progress-bar"
								variant="determinate"
								value={progress}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

UploadingVideoRow.propTypes = {
	className: PropTypes.string,
	videoTitle: PropTypes.string,
	videoUrl: PropTypes.string,
	progress: PropTypes.number,
	thumbnailImg: PropTypes.string,
};

UploadingVideoRow.defaultProps = {
	className: '',
	videoTitle: '',
	videoUrl: '',
	thumbnailImg: '',
};
export default UploadingVideoRow;
