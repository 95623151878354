import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class ThreeImageCompositionOneText extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Composición de tres imágenes con un texto`;
	static brickIcon = 'image';
	static brickImage = brickImage;

	static brickDefaultData = {
		beforeTitle: 'XERCHITA DOLOREPERU',
		title: 'DUSUE NOBISTA MANTE MAS MANSA NOMSTEES',
		text: 'Quis eost, ofcim sinverum vellant quam corrumque csxsasasa il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi as exerchita doloreperum quaectur, ut into te la nobitatur?',

		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
			},
		],
	};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const title = data.title || '';
		const text = data.text || '';
		const images = data.images || [];

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ThreeImageCompositionOneText']);

		const classes = classNames({
			'brick': true,
			'three-image-composition-with-onetext-brick': true,
		});

		let imgBg;
		if (styles) {
			if (styles.containerStyle) {
				if (styles.containerStyle.imageBg) {
					imgBg = true;
				} else {
					imgBg = false;
				}
			}
		}
		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<WindowSizeContext.Consumer>
					{({device}) => {
						return (
							<div className={classes + (imgBg ? ' with-imgBg' : '')}>
								<div className="three-image-composition-with-onetext-brick__part-top">
									<div className="three-image-composition-with-onetext-brick__part-top__image-wrap">
										<Animation
											playAnimation={this.state.playEffect}
											delay={1}
											effect={brickAnimation}
										>
											<div className="the-img">
												<NewImageClickable
													imgObj={images[0].imageObj}
													clickable={
														device === 'mobile' || device === 'mobileLandscape' ? false : true
													}
												/>
											</div>

											{images[0].imageObj.credit && (
												<div className="image-caption">
													<div className="caption-credit"> {images[0].imageObj.credit} </div>
												</div>
											)}
										</Animation>
									</div>
								</div>
								<div className="three-image-composition-with-onetext-brick__part-bottom">
									<div className="three-image-composition-with-onetext-brick__part-bottom__left">
										<div className="three-image-composition-with-onetext-brick__part-bottom__left__before-title">
											<FrontEditableText
												fieldName="beforeTitle"
												text={data.beforeTitle}
												set={this.props.set}
											/>
										</div>
										<div className="three-image-composition-with-onetext-brick__part-bottom__left__title">
											<FrontEditableText fieldName="title" text={title} set={this.props.set} />
										</div>
										<div className="three-image-composition-with-onetext-brick__part-bottom__left__text">
											<FrontEditableText fieldName="text" text={text} set={this.props.set} />
										</div>
									</div>
									<div className="three-image-composition-with-onetext-brick__part-bottom__right">
										<div className="three-image-composition-with-onetext-brick__part-bottom__right__img img-1">
											<div className="the-img">
												<Animation
													playAnimation={this.state.playEffect}
													delay={1.5}
													effect={brickAnimation}
												>
													<NewImageClickable imgObj={images[1].imageObj} />

													{images[1].imageObj.credit && (
														<div className="image-caption">
															<div className="caption-credit"> {images[1].imageObj.credit} </div>
														</div>
													)}
												</Animation>
											</div>
										</div>
										<div className="three-image-composition-with-onetext-brick__part-bottom__right__img img-2">
											<div className="the-img">
												<Animation
													playAnimation={this.state.playEffect}
													delay={2}
													effect={brickAnimation}
												>
													<NewImageClickable imgObj={images[2].imageObj} />

													{images[2].imageObj.credit && (
														<div className="image-caption">
															<div className="caption-credit"> {images[2].imageObj.credit} </div>
														</div>
													)}
												</Animation>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					}}
				</WindowSizeContext.Consumer>
			</Container>
		);
	}
}

export default brickWrapper(ThreeImageCompositionOneText);
