import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
// import Button from 'businessLogic/shared/Buttons/Button';
import './styles.scss';
class LayerTrigger extends PureComponent {
	render() {
		const {onShow, buttonText} = this.props;
		const classes = classNames({
			'layer-trigger': true,
		});
		return (
			<div className={classes} onClick={onShow}>
				<div className="layer-trigger__btn">
					<div className="layer-trigger__pulse">
						<Icon name="plus" size="normal" />
					</div>
					<div className="layer-trigger__txt"> {buttonText}</div>
				</div>
			</div>
		);
	}
}
LayerTrigger.propTypes = {
	onShow: PropTypes.func,
	buttonText: PropTypes.string,
};
LayerTrigger.defaultProps = {
	buttonText: 'Más información',
};
export default LayerTrigger;
