import Quill from 'quill';
const Inline = Quill.import('blots/inline');

class TextLinkBlot extends Inline {
	// Ante posibles dudas visitar: https://github.com/quilljs/quill/blob/develop/formats/link.js
	static create(value) {
		const node = super.create();
		if (value.href) {
			node.setAttribute('href', value.href);
		} else {
			node.setAttribute('href', value);
		}
		node.setAttribute('target', '_blank');
		return node;
	}

	static formats(domNode) {
		// Posteriormente para que cuando ejecutamos el código anterior no reciba los parámetros creados vacios
		// necesitamos a través del "formats" recuperar los atributos que tienen el contenido en este caso "data-image" y "date-text"
		const object = {
			href: domNode.getAttribute('href'),
			blank: domNode.getAttribute('target', '_blank')
		};

		return object;
	}
	format(name, value) {
		if (name !== this.statics.blotName || !value) return super.format(name, value);
		if (value.href) {
			this.domNode.setAttribute('href', value.href);
		} else {
			this.domNode.setAttribute('href', value);
		}
		this.domNode.setAttribute('target', '_blank');
	}
}
TextLinkBlot.blotName = 'myLink';
TextLinkBlot.tagName = 'a';
TextLinkBlot.className = 'my-link';

export default TextLinkBlot;
