import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import Input from 'ui/shared/FormFields/Input';
import {getPlainTextFromTextEditorObject} from 'businessLogic/helpers/functions';
import Instructions from 'businessLogic/core/shared/Edit/Instructions';
import './styles.scss';

class Text extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		let defaultValue = '';
		if (editionBrickStruct.defaultValue) {
			defaultValue = editionBrickStruct.defaultValue;
		}

		return defaultValue;
	};

	onChange = (event) => {
		this.props.setMainField(event.target.value);
	};
	render() {
		return (
			<div className="edition-brick-text">
				<label className="edition-brick-text__label">{this.props.editionBrickStruct.title}</label>
				{this.props.editionBrickStruct.instructions !== undefined && (
					<Instructions text={this.props.editionBrickStruct.instructions} />
				)}
				<Input
					name="box-title"
					type="text"
					placeholder={this.props.editionBrickStruct.placeholder}
					value={getPlainTextFromTextEditorObject(this.props.getMainField())}
					onChange={this.onChange}
				/>
			</div>
		);
	}
}

export default editionBrickWrapper(Text);
