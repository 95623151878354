import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Image from 'businessLogic/core/shared/Image/Image';
import Container from 'businessLogic/core/shared/Container/Container';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import debounce from 'lodash/debounce';
import Animation from 'businessLogic/core/shared/Animation';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import get from 'lodash/get';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

class ThreeImagesSquareTitleTextBehind extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Composición de tres imágenes cuadradas con texto que aparece`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
				title: 'dus que nobist veleseque vellore',
				text: 'Quis eost, officim sinverum vellan quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
				title: 'dus que nobist veleseque vellore',
				text: 'Quis eost, officim sinverum vellan quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
				title: 'dus que nobist veleseque vellore',
				text: 'Quis eost, officim sinverum vellan quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.',
			},
		],
	};
	constructor(props) {
		super(props);

		this.state = {
			showFirstCard: false,
			playEffect: false,
			counter: 0,
		};

		this.navigationContextObject = {
			goToPill: this.goToPillById,
		};

		this.isVisible = false;

		// Debounce
		this.showFirstCard = debounce(this.showFirstCard, 1500);
		this.hideFirstCard = debounce(this.hideFirstCard, 4000);
	}

	handleVisibilityChange(isVisible) {
		this.isVisible = isVisible;
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
			this.showFirstCard();
		}
	}

	showFirstCard = () => {
		this.setState({
			showFirstCard: true,
		});
		this.hideFirstCard();
	};

	hideFirstCard = () => {
		this.setState({
			showFirstCard: false,
		});
	};

	componentWillUnmount() {
		this.showFirstCard.flush();
		this.hideFirstCard.flush();
	}
	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const images = data.images || [];
		const classes = classNames({
			'brick': true,
			'three-images-title-text-behind-brick': true,
		});

		const classesFirstCard = classNames({
			'show-first-card': this.state.showFirstCard,
		});
		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ThreeImagesSquareTitleTextBehind']);
		let elements = images.map((listItem, index) => {
			return (
				<div
					className={'image-element image-wrap ' + (index === 0 ? classesFirstCard : '')}
					key={index}
				>
					<Animation
						key={index}
						playAnimation={this.state.playEffect}
						effect={brickAnimation}
						delay={index * 0.5}
					>
						<div className="image-text-container">
							{/* <div className="three-images-title-text-behind-brick__point" /> */}
							<div className="image-container-side-front">
								<Image format="square" imgObj={listItem.imageObj} clickable={false} />
							</div>

							<div className="title-text-box-side-back">
								<div>
									<div className="title-box">
										<FrontEditableText
											fieldName="title"
											text={listItem.title}
											set={(fieldPath, fieldData) => {
												//Propagamos la llamada al método set añadiendo mi nodo al path
												if (this.props.set)
													this.props.set(['images', index].concat(fieldPath), fieldData);
											}}
										/>
									</div>

									<div className="text-box">
										<FrontEditableText
											fieldName="text"
											text={listItem.text}
											set={(fieldPath, fieldData) => {
												//Propagamos la llamada al método set añadiendo mi nodo al path
												if (this.props.set)
													this.props.set(['images', index].concat(fieldPath), fieldData);
											}}
										/>
									</div>
								</div>
							</div>
							{(listItem.imageObj.footer || listItem.imageObj.credit) && (
								<div className="image-text-container__image-caption">
									<div className="image-text-container__image-caption__footer">
										{' '}
										{listItem.imageObj.footer}{' '}
									</div>
									<div className="image-text-container__image-caption__credit">
										{' '}
										{listItem.imageObj.credit}{' '}
									</div>
								</div>
							)}
						</div>
					</Animation>
				</div>
			);
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>{elements}</div>
			</Container>
		);
	}
}

export default brickWrapper(ThreeImagesSquareTitleTextBehind);
