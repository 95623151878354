import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Animation from 'businessLogic/core/shared/Animation';
import './styles.scss';

class HotSpotModal extends PureComponent {
	render() {
		const {data, index, playAnimation, delay, effect} = this.props;
		const classes = classNames({'hotspot-modal': true});

		let coordinatesX = data.coordinates.x || 0;
		let coordinatesY = data.coordinates.y || 0;
		return (
			<React.Fragment>
				{coordinatesX !== 0 && coordinatesY !== 0 && (
					<div
						key={index}
						className={classes}
						style={{top: coordinatesY + '%', left: coordinatesX + '%'}}
						onClick={this.props.onClick}
					>
						<Animation playAnimation={playAnimation} effect={effect} delay={delay}>
							{this.props.showNumbers === true ? (
								<div className="hotspot-modal__number" ref="hotSpotNumber">
									{index + 1}
								</div>
							) : (
								<div className="hotspot-modal__pointer" ref="hotSpotNumber" />
							)}
						</Animation>
					</div>
				)}
			</React.Fragment>
		);
	}
}

HotSpotModal.propTypes = {
	data: PropTypes.object,
	index: PropTypes.number,
	onClick: PropTypes.func,
};

HotSpotModal.defaultProps = {
	data: {},
	index: 0,
};

export default HotSpotModal;
