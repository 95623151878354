import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import AdminColorSelector from 'businessLogic/shared/Selects/AdminColorSelector';
import StylesOptionsWrapper from '../StylesOptionsWrapper';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class ColorBg extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	static getDefaultValue = () => {
		const defaultValue = {
			colorBg: false,
		};

		return defaultValue;
	};

	handleChangeColorBackground = (color) => {
		this.props.setMainField(color);
	};

	render() {
		const colorBg = this.props.getMainField();
		const label = this.props.editionBrickStruct?.label || t`Color de fondo`;

		return (
			<StylesOptionsWrapper>
				<label className="item-title">{label}</label>
				<AdminColorSelector value={colorBg} onChange={this.handleChangeColorBackground} />
			</StylesOptionsWrapper>
		);
	}
}

export default editionBrickWrapper(ColorBg);
