import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';

import Test from './components/Test';
import SimpleTestIntro from 'businessLogic/core/shared/Test/SimpleTestIntro';

import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Element} from 'react-scroll';

import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import InstructionTestBrick from 'businessLogic/core/shared/Test/InstructionTestBrick';
import CallActionTestBrickWithBoxes from 'businessLogic/core/shared/Test/CallActionTestBrickWithBoxes';
import ModalForTestBricks from 'businessLogic/core/shared/Test/ModalForTestBricks';

import set from 'lodash/set';

import './styles.scss';

class TestPersonalityWithImages extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Test de personalidad con imágenes`;
	static brickIcon = 'test-personality';
	static brickHelpText = () => t`Permite categorizar al usuario por perfil`;
	static brickImage = brickImage;
	static canReload = true;
	static brickDefaultConfig = () => ({
		attempts: 10,
		brickName: t`Test de personalidad con imágenes`,
		brickDescription: t`Este test permite categorizar al usuario por perfil.`,
		requiredActions: true,
	});

	static brickDefaultData = () => ({
		image: {
			imageSrc: '/uploads/default-images/ilustration_test.svg',
			credit: '',
		},
		textLinkInstructions: '',
		instructions: '',
		beforeHeading: _('Test de personalidad con imágenes'),
		descriptionTest: _(
			'Instrucciones de funcionamiento del test. Marca la imagen correcta y envía tu respuesta',
		),
		textButtonStart: _('EMPEZAR'),
		textButtonAgain: _('VOLVER A HACER'),
		profiles: [
			{
				profileTitle: _('Perfil A'),
				profileImageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
				profileDescription:
					'Nulla eget luctus tortor. Pellentesque nec enim quis turpis ultrices tincidunt at vel leo. Suspendisse eu commodo est. Nam nulla ipsum, fermentum quis lectus in, fermentum facilisis velit. Aliquam id feugiat lacus. Nulla a volutpat odio. Aenean elementum magna eros, aliquam fermentum lectus consequat dig',
				profileTag: _('Perfil A'),
			},
			{
				profileTitle: _('Perfil B'),
				profileImageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
				profileDescription:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lacinia quam diam, quis varius magna pulvinar ac. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Proin eget scelerisque purus. Sed commodo vitae tortor ac pharetra. Vestibulum euismod augue augue, ac faucibus quam euismod in. In sed iaculis sapien. Nunc dictum pretium lacus eleifend dictum. Suspendisse non neque tristique massa convallis egestas. Vestibulum sed nisi non diam maximus luctus. Etiam sed mollis purus.',
				profileTag: _('Perfil B'),
			},
			{
				profileTitle: _('Perfil C'),
				profileImageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
				profileDescription:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lacinia quam diam, quis varius magna pulvinar ac. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Proin eget scelerisque purus. Sed commodo vitae tortor ac pharetra. Vestibulum euismod augue augue, ac faucibus quam euismod in. In sed iaculis sapien. Nunc dictum pretium lacus eleifend dictum. Suspendisse non neque tristique massa convallis egestas. Vestibulum sed nisi non diam maximus luctus. Etiam sed mollis purus.',
				profileTag: _('Perfil C'),
			},
			{
				profileTitle: _('Perfil D'),
				profileImageObj: {
					imageSrc: '/uploads/default-images/csDefault4Opt.svg',
					credit: '',
				},
				profileDescription:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lacinia quam diam, quis varius magna pulvinar ac. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Proin eget scelerisque purus. Sed commodo vitae tortor ac pharetra. Vestibulum euismod augue augue, ac faucibus quam euismod in. In sed iaculis sapien. Nunc dictum pretium lacus eleifend dictum. Suspendisse non neque tristique massa convallis egestas. Vestibulum sed nisi non diam maximus luctus. Etiam sed mollis purus.',
				profileTag: _('Perfil D'),
			},
		],
		questions: [
			{
				question: '¿Lorem ipsum dolor sit amet consectetur adipiscing elit?',
				answers: [
					{
						answer: 'Sociis feugiat nulla venenatis aenean, netus suspendisse.',
						answerImageObj: {
							imageSrc: '/uploads/default-images/csDefault1Opt.svg',
							credit: '',
						},
						tag: _('Perfil A'),
					},
					{
						answer: 'Lorem ipsum dolor sit amet consectetur, adipiscing elit augue.',
						answerImageObj: {
							imageSrc: '/uploads/default-images/csDefault2Opt.svg',
							credit: '',
						},
						tag: _('Perfil B'),
					},
					{
						answer: 'Sociis feugiat nulla venenatis aenean, netus suspendisse.',
						answerImageObj: {
							imageSrc: '/uploads/default-images/csDefault3Opt.svg',
							credit: '',
						},
						tag: _('Perfil C'),
					},
					{
						answer: 'Lorem ipsum dolor sit amet consectetur, adipiscing elit augue.',
						answerImageObj: {
							imageSrc: '/uploads/default-images/csDefault4Opt.svg',
							credit: '',
						},
						tag: _('Perfil D'),
					},
				],
			},
			{
				question: '¿Magnis velit aptent diam dapibus, sodales porta.?',
				answers: [
					{
						answer:
							'Viverra fusce dis malesuada mauris fringilla ultricies nec odio, cras sagittis mollis gravida ligula senectus varius,',
						answerImageObj: {
							imageSrc: '/uploads/default-images/csDefault1Opt.svg',
							credit: '',
						},
						tag: _('Perfil A'),
					},
					{
						answer:
							'Vestibulum sodales ultricies ultrices euismod est phasellus aliquet sollicitudin donec pharetra',
						answerImageObj: {
							imageSrc: '/uploads/default-images/csDefault2Opt.svg',
							credit: '',
						},
						tag: _('Perfil B'),
					},
					{
						answer: 'Sociis feugiat nulla venenatis aenean, netus suspendisse.',
						answerImageObj: {
							imageSrc: '/uploads/default-images/csDefault3Opt.svg',
							credit: '',
						},
						tag: _('Perfil C'),
					},
					{
						answer: 'Lorem ipsum dolor sit amet consectetur, adipiscing elit augue.',
						answerImageObj: {
							imageSrc: '/uploads/default-images/csDefault4Opt.svg',
							credit: '',
						},
						tag: _('Perfil D'),
					},
				],
			},
		],
	});
	/*El estado stateMachine, simula una máquina de Estados que contempla a su vez 3 posibles estados del componente: estado intro, estado test, estado result */
	constructor(props) {
		super(props);
		this.state = {
			type: 'TestPersonalityWithImages',
			stateMachine: 'intro',
			repeats: 0,
			resultIndex: 0,
			heightBrick: 0,
			completed: false,
			success: false,
			responses: [],
		};

		this.testRef = React.createRef();
		this.props.getBrickStateData('').then((brickStateData) => {
			if (brickStateData && brickStateData.state) {
				this.setState(brickStateData.state);

				//Parche para solventar los casos donde no se ha guardado el status del ladrillo
				if (brickStateData.state.stateMachine === 'result' && !brickStateData.status) {
					this.initStateTestResult();
				}
			}
		});
	}
	saveBrickState = () => {
		this.props.setBrickStateData('state', this.state);
	};
	initStateTestQuestion = () => {
		if (
			!this.props.brick.config.attempts ||
			this.props.brick.config.attempts > this.state.repeats
		) {
			this.setState(
				{
					stateMachine: 'test',
				},
				this.saveBrickState,
			);

			this.handleScrollTest();
			//Lanzamos el evento de inicio de test
			this.props.brickEvent('start');
		}
	};

	initStateTestResult = () => {
		const responses = this.testRef.current.getResponses();

		//asignar un índice a cada profile tag ejem {sibarita:0, ecológico:1, Moderna:2}
		const profileTags = {};
		this.props.brick.data.profiles.forEach((item, index) => {
			profileTags[item.profileTag] = index;
		});

		//Inicializar Contador de resultados. Cada elemento corresponde al número de veces que se repite cada tag
		const results = [0, 0, 0, 0];

		responses.forEach((responseIndex, questionIndex) => {
			//Recorrer cada pregunta de responses y devolver la tag seleccionada en cada una de ellas
			const profileTag = this.props.brick.data.questions[questionIndex].answers[responseIndex].tag;

			//Asociar el número de cada pregunta al índice de las categoría de la respuesta seleccionadda
			if (results[profileTags[profileTag]] >= 0) {
				results[profileTags[profileTag]] += 1;
			} else {
				results[profileTags[profileTag]] = 1;
			}
		});

		const max = Math.max(...results);
		const resultIndex = results.findIndex((element) => element === max);

		// Almacenamos los resultados del intento en state.responses
		const newResponsesArray = [].concat(this.state.responses);
		const responseObject = {
			responses,
			results,
			resultIndex,
			date: Date.now(),
		};
		set(newResponsesArray, [this.state.repeats], responseObject);

		this.setState(
			(state) => ({
				stateMachine: 'result',
				completed: true,
				success: true,
				resultIndex: resultIndex,
				responses: newResponsesArray,
				repeats: state.repeats + 1,
			}),
			this.saveBrickState,
		);

		clearTimeout(this.setHeightTimeoutHandler);
		this.setHeightTimeoutHandler = setTimeout(() => {
			this.setHeightContent();
		}, 500);
		this.handleScrollTest();

		//Lanzamos el evento de fin de test
		this.props.brickEvent('end');
		//Marcamos el test como completado y añadidmos si es evaluable
		const status = {
			completed: true,
			success: true,
		};
		this.props.setBrickStateData('status', status).then(() => {
			this.props.configObject.callEventListeners('brickStatusChanged', {
				brick: 'TestPersonalityWithImages',
				type: 'info',
				status,
			});
		});

		// Enviamos también datos anónimos de este ladrillo
		this.props.setBrickAnomData(newResponsesArray);
	};

	handleScrollTest = () => {
		// scroller.scrollTo("brick--" + this.props.brickId, {
		// 	smooth: false,
		// 	containerId: "scroll-container",
		// 	offset: -100
		// });
	};

	setHeightContent = (height) => {
		if (height !== undefined) {
			this.setState({
				heightBrick: height,
			});
		} else {
			this.setState({
				heightBrick: 0,
			});
		}
	};
	componentDidMount() {
		this.setHeightContent();
	}
	UNSAFE_componentWillMount() {
		clearTimeout(this.setHeightTimeoutHandler);
	}
	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const questions = data.questions || [];
		const profiles = data.profiles || [];

		const classes = classNames({
			'brick': true,
			'test-personality-images-brick': true,
		});
		let element;
		switch (this.state.stateMachine) {
			default:
			case 'intro':
				element = (
					<CSSTransition key="intro" timeout={500} classNames="show">
						<div className="step step--1 ">
							<CallActionTestBrickWithBoxes
								type="toStart"
								textButton={data.textButtonStart}
								beforeHeading={data.beforeHeading}
								image={data.image}
								onClick={this.initStateTestQuestion}
								set={this.props.set}
								className={classes}
							>
								<SimpleTestIntro textDescription={data.descriptionTest} set={this.props.set} />
								<InstructionTestBrick
									textLabel={data.textLinkInstructions}
									textHelp={data.instructions}
									theme="dark"
								/>
							</CallActionTestBrickWithBoxes>
						</div>
					</CSSTransition>
				);

				break;
			case 'test':
				element = (
					<CSSTransition key="test" timeout={500} classNames="show">
						<div className="step step--2">
							<ModalForTestBricks
								onClose={this.initStateTestResult}
								isEditMode={this.props.editMode}
							>
								<Test
									ref={this.testRef}
									questions={questions}
									onFinish={this.initStateTestResult}
									set={this.props.set}
									setHeight={this.setHeightContent}
									onScroll={this.handleScrollTest}
								/>
							</ModalForTestBricks>
						</div>
					</CSSTransition>
				);

				break;

			case 'result':
				//compatibilidad con test anteriores
				let descriptionIsHtml = false;
				if (
					typeof data.profiles[this.state.resultIndex].profileDescription.textValue !== 'undefined'
				) {
					descriptionIsHtml = true;
				}
				let profileTitleIsHtml = false;

				if (data.profiles[this.state.resultIndex].profileTitle.textValue !== 'undefined') {
					profileTitleIsHtml = true;
				}

				element = (
					<CSSTransition key="result" timeout={500} classNames="show">
						<div className="step step--3">
							<CallActionTestBrickWithBoxes
								type="toRepeat"
								textButton={data.textButtonAgain}
								beforeHeading={data.beforeHeading}
								image={profiles[this.state.resultIndex].profileImageObj}
								onClick={this.initStateTestQuestion}
								set={(fieldPath, fieldData) => {
									//Propagamos la llamada al método set añadiendo mi nodo al path
									if (this.props.set)
										this.props.set(
											['profiles', this.state.resultIndex].concat(fieldPath),
											fieldData,
										);
								}}
								disabledAgain={
									!this.props.brick.config.attempts ||
									this.state.repeats === parseInt(this.props.brick.config.attempts)
								}
								className={classes}
							>
								{profileTitleIsHtml ? (
									<div
										className="profile-title"
										dangerouslySetInnerHTML={{
											__html: data.profiles[this.state.resultIndex].profileTitle.textValue,
										}}
									/>
								) : (
									<div className="profile-title">
										{data.profiles[this.state.resultIndex].profileTitle}
									</div>
								)}
								{descriptionIsHtml ? (
									<div
										className="profile-description"
										dangerouslySetInnerHTML={{
											__html: data.profiles[this.state.resultIndex].profileDescription.textValue,
										}}
									/>
								) : (
									<div className="profile-description">
										{data.profiles[this.state.resultIndex].profileDescription}
									</div>
								)}
							</CallActionTestBrickWithBoxes>
						</div>
					</CSSTransition>
				);

				break;
		}

		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<Element name={'brick--' + this.props.brickId}>
					<div
						className={classes}
						ref="innerContentStep"
						style={{height: this.state.heightBrick > 0 ? this.state.heightBrick + 'px' : 'auto'}}
					>
						<TransitionGroup className="todo-list">{element}</TransitionGroup>
					</div>
				</Element>
			</Container>
		);
	}
}

export default brickWrapper(TestPersonalityWithImages);
