import gql from 'graphql-tag';

//Definimos en primer lugar nuestro fragmento y tipos necesarios
const stateDataFragment = gql`
	fragment StateDataOutput on StateData {
		id
		pillId
		brickId
		data
		createdAt
		updatedAt
	}
`;

gql`
	input EventInput {
		verb: Int!
		object: ActivityInput!
		result: ResultInput
		parentActivity: ActivityInput
		groupingActivity: ActivityInput
		timestamp: Int
	}

	input ActivityInput {
		type: ActivityType!
		data: ScalarGeneric
	}

	input ResultInput {
		success: Boolean
		completion: Boolean
		duration: Int
		response: String
		score: ScoreInput
	}
`;

export const GET_PILL_STATE_DATA = gql`
	query getPillStateData($pillId: String!, $userId: ID, $recalculateIfNeeded: Boolean) {
		pillStateData(pillId: $pillId, userId: $userId, recalculateIfNeeded: $recalculateIfNeeded) {
			...StateDataOutput
		}
	}
	${stateDataFragment}
`;

export const SET_PILL_STATE_DATA = gql`
	mutation setPillStateData(
		$pillId: String!
		$data: ScalarGeneric!
		$overwrite: Boolean
		$userId: ID
	) {
		setPillStateData(pillId: $pillId, data: $data, overwrite: $overwrite, userId: $userId) {
			...StateDataOutput
		}
	}
	${stateDataFragment}
`;

export const GET_BRICK_STATE_DATA = gql`
	query getBrickStateData($pillId: String!, $brickId: String!, $userId: ID) {
		brickStateData(pillId: $pillId, brickId: $brickId, userId: $userId) {
			...StateDataOutput
		}
	}
	${stateDataFragment}
`;

export const SET_BRICK_STATE_DATA = gql`
	mutation setBrickStateData(
		$pillId: String!
		$brickId: String!
		$data: ScalarGeneric!
		$overwrite: Boolean
		$userId: ID
	) {
		setBrickStateData(
			pillId: $pillId
			brickId: $brickId
			data: $data
			overwrite: $overwrite
			userId: $userId
		) {
			...StateDataOutput
		}
	}
	${stateDataFragment}
`;

export const SET_BRICK_ANOM_DATA = gql`
	mutation setBrickAnomData($pillId: String!, $brickId: String!, $data: ScalarGeneric!) {
		setBrickAnomData(pillId: $pillId, brickId: $brickId, data: $data) {
			...StateDataOutput
		}
	}
	${stateDataFragment}
`;

export const GET_BRICK_ANOM_DATA_LIST = gql`
	query getBrickAnomDataList($pillId: String!, $brickId: String!) {
		brickAnomDataList(pillId: $pillId, brickId: $brickId) {
			...StateDataOutput
		}
	}
	${stateDataFragment}
`;

export const SET_EVENT = gql`
	mutation setEvent($input: EventInput!) {
		setEvent(input: $input)
	}
`;

export const SEND_BRICK_CONTACT_FORM = gql`
	mutation sendBrickContactForm($pillId: String!, $brickId: String!, $data: ScalarGeneric!) {
		sendBrickContactForm(pillId: $pillId, brickId: $brickId, data: $data)
	}
`;

export default {
	GET_PILL_STATE_DATA,
	SET_PILL_STATE_DATA,
	GET_BRICK_STATE_DATA,
	SET_BRICK_STATE_DATA,
	SET_BRICK_ANOM_DATA,
	GET_BRICK_ANOM_DATA_LIST,
	SET_EVENT,
	SEND_BRICK_CONTACT_FORM,
};
