import Logger from 'businessLogic/services/Logger';

export default function ComponentLoader(lazyComponent, attemptsLeft = 3) {
	return () => {
		return new Promise((resolve, reject) => {
			lazyComponent()
				.then(resolve)
				.catch((error) => {
					// let us retry after 500 ms
					setTimeout(() => {
						if (attemptsLeft === 1) {
							Logger.captureException(error);
							reject(error);
							return;
						}
						ComponentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
					}, 500);
				});
		});
	};
}

// https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
