import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import ThemeSelector from 'businessLogic/scope/admin/shared/Settings/ThemeSelector';
import StylesOptionsWrapper from '../StylesOptionsWrapper';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class ThemeSelectorEditionBrick extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	handleChangeThemeSelected = (theme) => {
		this.props.setMainField(theme);
	};

	render() {
		const themeSelected = this.props.getMainField();

		return (
			<StylesOptionsWrapper>
				<ThemeSelector
					label={t`Tema`}
					onSelectTheme={this.handleChangeThemeSelected}
					active={themeSelected}
				/>
			</StylesOptionsWrapper>
		);
	}
}

export default editionBrickWrapper(ThemeSelectorEditionBrick);
