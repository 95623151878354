import React from 'react';
import {useSlate} from 'slate-react';
import ToggleButton from '../basics/ToggleButton';

import RedoIcon from '@mui/icons-material/Redo';

const RedoButton = () => {
	const editor = useSlate();
	const {isRedoable} = editor.helpers;

	const canRedo = isRedoable(editor);

	return (
		<ToggleButton disabled={!canRedo} onClick={() => editor.redo()} format={'redo'}>
			<RedoIcon />
		</ToggleButton>
	);
};

export default RedoButton;
