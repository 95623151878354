import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Image',
						fieldName: 'image',
					},

					{
						type: 'Text',
						fieldName: 'button',
						title: t`Texto del botón de enviar`,
					},

					{
						type: 'TextEditor',
						fieldName: 'finalText',
						title: t`Texto para el usuario tras completar el formulario`,
					},
					// contenido flexible campos personalizables
					{
						type: 'RepeaterFlexContent',
						fieldName: 'flexibleContent',
						blockTitle: t`Campo de formulario`,
						content: [
							{
								label: t`Texto`,
								flexibleContentType: 'TextField',
								content: [
									{
										type: 'Text',
										fieldName: 'textFieldForm',
										title: t`Texto`,
										placeholder: t`Introduce el texto de ejemplo`,
									},
								],
							},
							{
								label: t`Check`,
								flexibleContentType: 'CheckField',
								content: [
									{
										type: 'Text',
										fieldName: 'labelField',
										title: t`Texto`,
									},
									{
										type: 'Text',
										fieldName: 'checkFieldFormTextLink',
										title: t`Texto del link para mostrar más información`,
										placeholder: t`Introduce el texto del link para mostrar el modal`,
									},
									{
										type: 'TextEditor',
										fieldName: 'checkFieldForm',
										title: t`Texto para ampliar información`,
										placeholder: t`Introduce el texto para ampliar información que se abrirá en un modal`,
									},
								],
							},
							{
								label: t`Email`,
								flexibleContentType: 'EmailField',
								content: [
									{
										type: 'Text',
										fieldName: 'emailFieldForm',
										title: t`Email`,
										placeholder: t`Introduce el email de ejemplo`,
									},
								],
							},
							{
								label: t`Teléfono`,
								flexibleContentType: 'PhoneField',
								content: [
									{
										type: 'Text',
										fieldName: 'phoneFieldForm',
										title: t`Teléfono`,
										placeholder: t`Introduce el teléfono de ejemplo`,
									},
								],
							},
							{
								label: t`Área de texto`,
								flexibleContentType: 'TextAreaField',
								content: [
									{
										type: 'Text',
										fieldName: 'textAreaFieldForm',
										title: t`Área de texto`,
										placeholder: t`Introduce el texto de ejemplo`,
									},
								],
							},
							{
								label: t`Archivo`,
								flexibleContentType: 'File',
								content: [
									{
										type: 'Text',
										fieldName: 'label',
										title: t`Texto`,
									},
									{
										type: 'Text',
										fieldName: 'indications',
										title: t`Indicaciones`,
									},
									{
										type: 'Select',
										fieldName: 'mimeType',
										title: t`Tipo de archivo`,
										defaultValue: 'image',
										options: [
											{
												label: t`Imagen`,
												value: 'image',
											},
											{
												label: t`Video`,
												value: 'video',
											},
											{
												label: t`Audio`,
												value: 'audio',
											},
											{
												label: t`PDF`,
												value: 'pdf',
											},
											{
												label: t`Documento`,
												value: 'document',
											},
											{
												label: t`Cualquier tipo de archivo`,
												value: 'any',
											},
										],
									},
									{
										type: 'Switch',
										fieldName: 'multiple',
										title: t`¿Permitir subir varios archivos?`,
									},
									{
										type: 'Switch',
										fieldName: 'prefixEmail',
										title: t`Incluir email en el nombre del archivo`,
									},
								],
							},
						],
						commonContent: [
							{
								type: 'Text',
								fieldName: 'internalLabel',
								title: t`Nombre interno`,
								instructions: t`Nombre interno para identificar el dato en los resultados del formulario. No se mostrará al usuario.`,
								defaultValue: t`Campo personalizable`,
							},
							{
								type: 'UniqueSlug',
								fieldName: 'slugField',
								title: t`Identificador`,
								instructions: t`Debe ser único. Establece como se almacena internamente la información. No se mostrará al usuario.`,
							},
							{
								type: 'Switch',
								fieldName: 'required',
								title: t`¿Es obligatorio?`,
							},
						],
					},
					//contenido flexible campos personalizables
				],
			},
			{
				type: 'Tab',
				title: t`Configuración`,
				fieldName: 'config',
				content: [
					{
						type: 'TextArea',
						fieldName: 'brickName',
						title: t`Nombre del formulario`,
						placeholder: t`Introduce un titulo para el formulario`,
						instructions: t`El nombre del formulario (no visible para el usuario) te ayudará a identificarla en el panel de administración.`,
						rows: 1,
					},
					{
						type: 'TextArea',
						fieldName: 'brickDescription',
						title: t`Descripción del test`,
						placeholder: t`Introduce una descripción para el formulario`,
						instructions: t`La descripción del formulario (no visible para el usuario) te ayudará a identificarla en el panel de administración.`,
						rows: 4,
					},
					{
						type: 'Switch',
						fieldName: 'allowSendMoreThanOnce',
						title: t`Permitir enviar el formulario más de una vez`,
					},
					{
						type: 'Switch',
						fieldName: 'includeLoggedUserData',
						title: t`Incluir datos del usuario si se ha identificado`,
					},
				],
			},
			{
				type: 'Tab',
				title: t`Participantes`,
				fieldName: 'data',
				content: [
					{
						type: 'CsvDownload',
						title: t`Descargar listado de participantes`,
						// headers: [
						// 	{label: t`Nombre y apellidos`, key: 'nameAndSurname'},
						// 	{label: t`Email`, key: 'email'},
						// 	{label: t`Teléfono`, key: 'phone'},
						// 	{label: t`Motivación`, key: 'motivation'},
						// 	{label: t`Acepta condiciones legales`, key: 'acceptLegals'},
						// 	{label: t`Fecha de inscripción`, key: 'createdAt'},
						// ],
						csvFileName: 'participants-contest-list.csv',
					},
				],
			},
		],
	},
];
export default editionFields;
