import React, {PureComponent} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ProgressCircle from 'businessLogic/core/shared/Icon/ProgressCircle';
import './styles.scss';

class Speedometer extends PureComponent {
	render() {
		const classes = classNames({
			speedometer: true,
		});

		const degScore = this.props.max !== 0 ? (this.props.value / this.props.max) * 180 : 1;

		const style = {
			transform: 'rotate(' + degScore + 'deg)',
		};

		const highlightColor = getComputedStyle(document.documentElement).getPropertyValue(
			'--highlightColor',
		);

		return (
			<div className={classes}>
				<div className="half-circle">
					<ProgressCircle
						radius={72}
						cx="50%"
						cy={62}
						strokeWidth={10}
						strokeColor={highlightColor}
						fillColor="transparent"
						value={this.props.value}
						max={this.props.max}
					/>
					<div className="pointer" style={style}>
						<div className="pointer__icon" />
					</div>
				</div>
			</div>
		);
	}
}

Speedometer.propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
	value: PropTypes.number,
};

Speedometer.defaultProps = {
	min: 0,
	max: 180,
	value: 0,
};

export default Speedometer;
