import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Icon from 'ui/shared/Icon';
import PropTypes from 'prop-types';
import './styles.scss';

class PlayButton extends PureComponent {
	render() {
		const onClick = this.props.onClick;
		return (
			<div>
				<div type="button" className="play-button" onClick={onClick}>
					<Icon name="play-button" size="normal" ref="playBtnIcon" />
				</div>
			</div>
		);
	}
}

PlayButton.propTypes = {
	className: PropTypes.string
};

export default brickWrapper(PlayButton);
