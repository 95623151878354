import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Input from 'ui/shared/FormFields/Input';
import TextArea from 'businessLogic/shared/Input/TextArea';
import classNames from 'classnames';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import './styles.scss';

class Form extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
		};
	}
	setText = (event) => {
		this.props.setData('text', event.target.value);
	};

	setEmailUser = (event) => {
		this.props.setData('emailUser', event.target.value);
		this.setState({email: event.target.value});
	};

	onClickSendData = () => {
		this.props.onSendData(this.props.user.email);
	};
	render() {
		const {data, errorsObj, set, user} = this.props;
		let classes = classNames({
			'form-new-contact-form': true,
		});
		return (
			<div className={classes}>
				<div className={classes + '__title'}>
					<FrontEditableText
						fieldName="titleContactForm"
						text={data.title}
						editable={true}
						set={set}
					/>
				</div>
				<div className={classes + '__subtitle'}>
					<FrontEditableText
						fieldName="subtitleContactForm"
						text={data.subtitle}
						editable={true}
						set={set}
					/>
				</div>
				{!user.email && (
					<Input
						className={classes + '__input'}
						type="email"
						placeholder={data.emailUser}
						theme="platform-input"
						errorText={errorsObj.emailUserError}
						onChange={this.setEmailUser}
						value={this.state.email}
					/>
				)}
				<TextArea
					className="form-new-contact-form__textarea"
					placeholder={data.text}
					theme="platform-textarea"
					errorText={errorsObj.textError}
					onChange={this.setText}
				/>
				<button
					className={'form-new-contact-form__button'}
					onClick={this.onClickSendData}
				>
					{data.button}
				</button>
			</div>
		);
	}
}

Form.propTypes = {
	data: PropTypes.object,
};

Form.defaultProps = {
	data: {},
};

export default Form;
