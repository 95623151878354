import React, {PureComponent} from 'react';
import {EditionBrickSwitch} from '.';
import {updateBrick, mutationUpdateBrick} from 'businessLogic/store/data/pills';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import Logger from 'businessLogic/services/Logger';

function editionBrickWrapper(WrapperComponent) {
	return class extends PureComponent {
		static getDefaultValue = (editionBrickStruct) => {
			if (WrapperComponent.getDefaultValue) {
				return WrapperComponent.getDefaultValue(editionBrickStruct);
			}
			return '';
		};
		renderChildren = (editionBrickList, extraPath) => {
			let newPath = cloneDeep(this.props.path);
			if (this.props.editionBrickStruct.fieldName) {
				newPath.push(this.props.editionBrickStruct.fieldName);
			}
			if (extraPath !== undefined) {
				if (Array.isArray(extraPath)) {
					newPath = newPath.concat(extraPath);
				} else {
					newPath.push(extraPath);
				}
			}

			return editionBrickList.map((editionBrick, index) => (
				<EditionBrickSwitch
					editionBrickStruct={editionBrick}
					editionBrickData={this.props.editionBrickData}
					pillId={this.props.pillId}
					parentType={this.props.parentType}
					parentId={this.props.parentId}
					path={newPath}
					key={index}
				/>
			));
		};
		get = (fieldName, defaultValue = undefined) => {
			let newPath = cloneDeep(this.props.path);
			if (Array.isArray(fieldName)) {
				newPath = newPath.concat(fieldName);
			} else {
				newPath.push(fieldName);
			}

			return get(this.props.editionBrickData, newPath, defaultValue);
		};
		getMainField = (defaultValue = undefined) => {
			return this.get(this.props.editionBrickStruct.fieldName, defaultValue);
		};
		getByPath = (path, defaultValue = undefined) => {
			return get(this.props.editionBrickData, path, defaultValue);
		};
		set = (fieldName, fieldData) => {
			const newBrick = cloneDeep(this.props.editionBrickData);
			let newPath = cloneDeep(this.props.path);
			if (Array.isArray(fieldName)) {
				newPath = newPath.concat(fieldName);
			} else {
				newPath.push(fieldName);
			}
			set(newBrick, newPath, fieldData);

			updateBrick(this.props.pillId, newBrick);
			this.programServerUpdate(newBrick);
		};
		setMainField = (fieldData) => {
			return this.set(this.props.editionBrickStruct.fieldName, fieldData);
		};
		setByPath = (path, fieldData) => {
			const newBrick = cloneDeep(this.props.editionBrickData);
			set(newBrick, path, fieldData);

			updateBrick(this.props.pillId, newBrick);
			this.programServerUpdate(newBrick);
		};
		programServerUpdate = (newBrick) => {
			this.brickDataToUpdate = newBrick;
			clearTimeout(this.timeoutHandle);
			this.timeoutHandle = setTimeout(() => {
				this.serverUpdate();
			}, 500);
		};
		serverUpdate = () => {
			mutationUpdateBrick(this.props.pillId, this.brickDataToUpdate.id, this.brickDataToUpdate);
			this.timeoutHandle = null;
		};
		componentWillUnmount() {
			if (this.timeoutHandle) {
				this.serverUpdate();
			}
		}
		render() {
			return (
				<Logger.ErrorBoundary beforeCapture={() => Logger.setTag('codeBlock', 'editionBrick')}>
					<WrapperComponent
						renderChildren={this.renderChildren}
						get={this.get}
						getMainField={this.getMainField}
						set={this.set}
						setMainField={this.setMainField}
						getByPath={this.getByPath}
						setByPath={this.setByPath}
						{...this.props}
					/>
				</Logger.ErrorBoundary>
			);
		}
	};
}

export default editionBrickWrapper;
