/* eslint-disable react-hooks/rules-of-hooks */
/* disable ttag */
import {
	t as t_,
	jt as jt_,
	gettext as gettext_,
	ngettext as ngettext_,
	c as c_,
	useLocale,
} from 'ttag';

let adminTtagCurrentLang = 'es_ES';

export function setAdminCurrentLang(lang) {
	adminTtagCurrentLang = lang;
}

export function t(strings, ...exprs) {
	useLocale(adminTtagCurrentLang);
	return t_(strings, ...exprs);
}

export function jt(strings, ...exprs) {
	useLocale(adminTtagCurrentLang);
	return jt_(strings, ...exprs);
}

export function gettext(id) {
	useLocale(adminTtagCurrentLang);
	return gettext_(id);
}

export function ngettext(...args) {
	useLocale(adminTtagCurrentLang);
	return ngettext_(...args);
}

export function c(ctx) {
	useLocale(adminTtagCurrentLang);
	return c_(ctx);
}
