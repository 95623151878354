import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import DialogMui from 'businessLogic/shared/Modals/DialogMui';

import ImageSelector from './components/ImageSelector';

import {t} from 'businessLogic/scope/admin/helper/adminTtag';

const styles = (sx) => () => ({
	...sx,
});

export default function WikiDialog({open, onCancel, onAccept, onDelete, defaultValue, sx}) {
	const [wikiText, setWikiText] = React.useState();
	const [wikiImageUrl, setWikiImageUrl] = React.useState();
	const [wikiImageCredits, setWikiImageCredits] = React.useState();

	React.useEffect(() => {
		if (defaultValue && defaultValue.text) setWikiText(defaultValue.text);
		if (defaultValue && defaultValue.imageUrl) setWikiImageUrl(defaultValue.imageUrl);
		if (defaultValue && defaultValue.imageCredits) setWikiImageCredits(defaultValue.imageCredits);
	}, [defaultValue]);

	const handleCancel = () => {
		if (onCancel) onCancel();
		setWikiText('');
		setWikiImageUrl('');
		setWikiImageCredits('');
	};

	const handleAccept = () => {
		if (onAccept)
			onAccept({
				text: wikiText,
				imageUrl: wikiImageUrl,
				imageCredits: wikiImageCredits,
			});
		setWikiText('');
		setWikiImageUrl('');
		setWikiImageCredits('');
	};

	const imageObj = {
		imageSrc: wikiImageUrl,
	};

	return (
		<DialogMui
			open={open}
			onClose={handleCancel}
			className="WikiDialog"
			sx={styles(sx)}
			maxWidth="xs"
			fullWidth
			title={t`Información adicional`}
		>
			{/* <DialogTitle>{t`Información adicional`}</DialogTitle> */}
			<DialogContent>
				<DialogContentText>{t`Introduce la imagen y el texto que quieres mostrar al pasar el ratón por encima del elemento`}</DialogContentText>
				<ImageSelector
					imageObj={imageObj}
					onChange={(imageObj) => {
						setWikiImageUrl(imageObj.imageSrc);
					}}
				/>
				{/* <TextField
					margin="dense"
					id="wikiText"
					label={t`Texto`}
					type="text"
					fullWidth
					variant="standard"
					value={wikiText}
					onChange={(e) => setWikiText(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.stopPropagation();
							e.preventDefault();
							onClick();
						}
						if (e.key === 'Escape') {
							e.stopPropagation();
							e.preventDefault();
							onClose();
						}
					}}
				/> */}
				{wikiImageUrl && (
					<TextField
						margin="dense"
						id="wikiImageCredits"
						label={t`Créditos de la imagen`}
						type="text"
						fullWidth
						variant="standard"
						value={wikiImageCredits}
						onChange={(e) => setWikiImageCredits(e.target.value)}
						sx={{
							'& input': {
								fontSize: 14,
							},
						}}
					/>
				)}
				<Box
					sx={{
						'p': 1,
						// 'textAlign': 'center',
						'marginTop': 2,
						'& textarea': {
							'resize': 'none',
							'border': 'none',
							'background': 'none',
							'fontSize': 18,
							'fontWeight': 400,
							'fontFamily': 'inherit',
							'width': '100%',
							// 'textAlign': 'center',
							'&:focus': {
								border: 'none',
								outline: 'none',
							},
						},
					}}
				>
					<TextareaAutosize
						autoFocus
						placeholder={t`Información adicional`}
						value={wikiText}
						onChange={(e) => setWikiText(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.stopPropagation();
								e.preventDefault();
								handleAccept();
							}
							if (e.key === 'Escape') {
								e.stopPropagation();
								e.preventDefault();
								handleCancel();
							}
						}}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<Box>
						{defaultValue && (
							<Button onClick={onDelete} color="error" variant="contained">{t`Eliminar`}</Button>
						)}
					</Box>

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							width: '100%',
							gap: 2,
						}}
					>
						<Button
							onClick={handleCancel}
							color="secondary"
							variant="contained"
						>{t`Cancelar`}</Button>
						<Button onClick={handleAccept} variant="contained">{t`Aceptar`}</Button>
					</Box>
				</Box>
			</DialogActions>
		</DialogMui>
	);
}
