import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Image',
						fieldName: 'image',
						defaultImage: '/uploads/default-images/csDefault1Opt.svg',
						defaultCredit: t`Crédito de ejemplo`,
						footer: true,
					},
					{
						type: 'Text',
						fieldName: 'hrefLink',
						title: t`Enlace de la imagen`,
						placeholder: t`Introduce el enlace de la imagen`,
						defaultValue: t`Introduce el enlace de la imagen`,
					},
					{
						type: 'Switch',
						fieldName: 'showLinkIcon',
						title: t`¿Mostrar icono de vínculo?`,
						defaultValue: false,
					},
				],
			},
		],
	},
];
export default editionFields;
