import React, {PureComponent} from 'react';
import Logger from 'businessLogic/services/Logger';
import AddElementInSelector from 'businessLogic/shared/MediaSelector/AddElementInSelector';
import './styles.scss';
import Dropzone from 'react-dropzone';
import {VIDEOS_QUERY, VIDEO_ADD_MUTATION, VIDEO_DELETE_MUTATION} from './query';
import {graphql} from 'react-apollo';
import {compose} from 'recompose';
import VideoAudioBoxRow from 'businessLogic/shared/MediaSelector/VideoAudioBoxRow';
import UploadingVideoRow from './components/UploadingVideoRow';
import entityManager from 'businessLogic/services/EntityManager';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import get from 'lodash/get';

class VideosList extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			videosInProcess: [],
			scrollToFetch: 0,
			page: 1,
		};

		this.uploadingVideoId = 0;
	}

	uploadVideo = async (file) => {
		//Obtenemos el tamaño del fichero a subir para ver si supera el límite
		const fileSize = file.size;
		const videoSizeLimit = entityManager.getVideoUploadSizeLimit();
		if (fileSize > videoSizeLimit * 1024 * 1024) {
			alert(
				t`El fichero que estás intentando subir pesa más de ${videoSizeLimit}MB. Por favor, reduce el tamaño y vuelve a intentarlo.`,
			);
			return;
		}

		// Solicitamos la url de carga
		const uploadLinkFetchPromise = fetch('/getUploadLink', {
			method: 'post',
			credentials: 'same-origin',
			mode: 'same-origin',
			body: JSON.stringify({filename: file.name, type: file.type}),

			headers: {
				'Content-Type': 'application/json',
			},
		});

		// Obtenemos la url de carga
		const uploadLinkFetchRes = await uploadLinkFetchPromise;

		const uploadLinkFetchJson = await uploadLinkFetchRes.json();

		if (uploadLinkFetchJson.error !== 0) {
			Logger.captureError('Error al obtener la url de carga para subir el video', (scope) => {
				return scope;
			});
			alert(
				t`Se ha producido un error al subir el fichero. Prueba a recargar la página y volver a intentarlo. Si el problema persiste contacta con el administrador de la plataforma.`,
			);
		}

		const {url, relPath} = uploadLinkFetchJson;

		// Info del video que vamos a subir
		const videoToUpload = {
			id: ++this.uploadingVideoId,
			file,
			progress: 0,
		};

		//Añadimos el video para que el usuario sepa que se está subiendo
		this.setState((state) => {
			const videosInProcess = [].concat(state.videosInProcess);
			videosInProcess.push(videoToUpload);
			return {videosInProcess};
		});

		// TODO: Obtener la duración del video y guardarla en base de datos
		// https://stackoverflow.com/questions/29285056/get-video-duration-when-input-a-video-file/29285597

		const ajax = new XMLHttpRequest();
		ajax.upload.addEventListener(
			'progress',
			(event) => {
				videoToUpload.progress = Math.round((100 * event.loaded) / event.total);
				this.setState((state) => {
					const videosInProcess = [].concat(state.videosInProcess);
					const videoIndex = videosInProcess.findIndex(
						(videoInProcess) => videoInProcess.id === videoToUpload.id,
					);

					if (videoIndex < 0) return;

					videosInProcess[videoIndex] = Object.assign({}, videoToUpload);
					return {videosInProcess};
				});
			},
			false,
		);
		ajax.addEventListener(
			'load',
			() => {
				if (ajax.status === 200) {
					// Obtenemos el nombre del fichero de relPath
					const relPathParts = relPath.split('/');
					const relPathPartsLength = relPathParts.length;
					const fileName = relPathParts[relPathPartsLength - 1];
					this.props
						.addNewVideo({
							input: {name: fileName, path: relPath, fileSize: fileSize},
						})
						.then(() => {
							this.removeLoading(videoToUpload);
						});
					return;
				}

				if (ajax.status === 413) {
					alert(
						t`El fichero que estás intentando subir pesa demasiado. Por favor, reduce el tamaño y vuelve a intentarlo.`,
					);
					this.removeLoading(videoToUpload);
					return;
				}
				Logger.captureError('Error al subir video', (scope) => {
					scope.setContext('ajax', {status: ajax.status, response: ajax.response});
					return scope;
				});
				alert(
					t`Se ha producido un error al subir el fichero. Prueba a recargar la página y volver a intentarlo. Si el problema persiste contacta con el administrador de la plataforma.`,
				);
				this.removeLoading(videoToUpload);
			},
			false,
		);
		ajax.addEventListener(
			'error',
			(event) => {
				Logger.captureError('Evento de error al subir video', (scope) => {
					scope.setContext('event', {event});
					return scope;
				});
				console.error('Error al subir video', event);
				alert(
					t`Se ha producido un error al subir el fichero. Prueba a recargar la página y volver a intentarlo. Si el problema persiste contacta con el administrador de la plataforma.`,
				);
				this.removeLoading(videoToUpload);
			},
			false,
		);
		ajax.addEventListener(
			'abort',
			(event) => {
				console.error('Subida de video abortada', event);
				this.removeLoading(videoToUpload);
			},
			false,
		);

		ajax.open('PUT', url, true);
		ajax.setRequestHeader('Content-Type', file.type);
		ajax.send(file);
	};

	onDrop = (files) => {
		files.forEach(this.uploadVideo);
	};

	handleUploadVideo = () => {
		this.uploadInput.click();
	};

	onUploadVideo = (ev) => {
		ev.preventDefault();
		const files = this.uploadInput.files;
		Object.values(files).forEach(this.uploadVideo);
	};

	removeLoading = (videoToUpload) => {
		this.setState((state) => {
			const videosInProcess = [].concat(state.videosInProcess);
			const videoIndex = videosInProcess.findIndex(
				(videoInProcess) => videoInProcess.id === videoToUpload.id,
			);

			if (videoIndex < 0) return;

			videosInProcess.splice(videoIndex, 1);

			return {videosInProcess};
		});
	};

	deleteVideo = async (video) => {
		let mutationDelete = await this.props.deleteVideo({
			idVideo: video.id,
		});
		/**TODO faltaría darle estilos a no poder borrar la imagen */

		if (!get(mutationDelete, 'data.deleteVideo.deleted')) {
			alert(t`No se puede borrar el video, ya que está siendo usada en alguna pildora`);
		} else {
			if (this.props.videos) {
				this.props.videos.refetch();
			}
		}
	};

	getVideos = () => {
		let renderVideosLoading;
		const {loading, videos} = this.props.videos;
		const {onSelect} = this.props;
		if (loading) {
			renderVideosLoading = <div>{t`Loading...`}</div>;
			return renderVideosLoading;
		}
		if (!videos || videos.length === 0) {
			renderVideosLoading = <div>{t`No hay ningún video aún.`}</div>;
			return renderVideosLoading;
		}

		if (this.state.videosInProcess.length > 0) {
			renderVideosLoading = this.state.videosInProcess.map((video) => {
				return (
					<UploadingVideoRow
						thumbnailImg="/uploads/default-images/120x100.png"
						videoTitle={video.file.name}
						progress={video.progress}
						key={video.id}
					/>
				);
			});
		}
		let renderVideos;
		renderVideos = videos.map((video, index) => {
			if (video !== null) {
				return (
					<VideoAudioBoxRow
						key={video ? video.id : index}
						thumbnailImg="/uploads/default-images/120x100.png"
						videoTitle={video.name}
						videoUrl={video.path}
						videoAutor={video.author}
						videoDuration={video.duration || ''}
						onDelete={(event) => {
							event.stopPropagation();
							this.deleteVideo(video);
							return false;
						}}
						onClick={() => {
							onSelect(video.path);
						}}
					/>
				);
			}
			return null;
		});
		if (Array.isArray(renderVideosLoading) && renderVideosLoading.length > 0) {
			renderVideos = renderVideosLoading.concat(renderVideos);
		}
		return renderVideos;
	};

	handleScroll = (event) => {
		let scrollTop = event.srcElement.scrollTop;
		let scroll = event.srcElement.scrollHeight - 450;
		let scrollToFetch = scroll - 400;
		if (scrollTop > scrollToFetch) {
			if (scrollToFetch !== this.state.scrollToFetch) {
				let pageNew = this.state.page + 1;
				this.setState({
					scrollToFetch: scrollToFetch,
					page: pageNew,
				});
				this.props.fetchMoreToVideos(this.state.page);
			}
		}
	};

	componentDidMount() {
		let myElement = document.getElementsByClassName('add-video-thumbnails-box')[0];
		if (myElement) myElement.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		let myElement = document.getElementsByClassName('add-video-thumbnails-box')[0];
		if (myElement) myElement.removeEventListener('scroll', this.handleScroll);
	}

	render() {
		return (
			<Dropzone onDrop={this.onDrop} disableClick={true} activeStyle={{backgroundColor: '#CEE3F6'}}>
				{({getRootProps}) => (
					<div className="add-video-thumbnails-box" {...getRootProps()}>
						<AddElementInSelector
							iconName="add-video"
							nameButton={t`Añadir video`}
							handleUpload={this.handleUploadVideo}
						/>
						{this.getVideos()}
						<input
							style={{display: 'none'}}
							ref={(ref) => {
								this.uploadInput = ref;
							}}
							type={'file'}
							accept={'video/*'}
							hidden={true}
							onChange={this.onUploadVideo}
							multiple
						/>
					</div>
				)}
			</Dropzone>
		);
	}
}

const VIDEOS_LIMIT = 5;
const withData = graphql(VIDEOS_QUERY, {
	name: 'videos',
	options: () => {
		return {
			variables: {
				offset: 1,
				limit: VIDEOS_LIMIT,
			},
		};
	},
	props: (props) => {
		return {
			...props,
			fetchMoreToVideos: (page) => {
				return props.videos.fetchMore({
					variables: {
						offset: page,
					},
					updateQuery: (prev, {fetchMoreResult}) => {
						if (!fetchMoreResult.videos || fetchMoreResult.videos.length === 0) {
							return prev;
						}
						let arrVideoNew = [];
						for (var i in prev.videos) {
							var shared = false;
							for (var j in fetchMoreResult.videos)
								if (fetchMoreResult.videos[j].id === prev.videos[i].id) {
									shared = true;
									break;
								}
							if (!shared) arrVideoNew.push(prev.videos[i]);
						}
						arrVideoNew = arrVideoNew.concat(fetchMoreResult.videos);
						return Object.assign(
							{},
							{
								videos: arrVideoNew,
							},
						);
					},
				});
			},
		};
	},
})(VideosList);

const withDataAndMutation = compose(
	graphql(VIDEO_ADD_MUTATION, {
		props({mutate}) {
			return {
				addNewVideo({input}) {
					return mutate({
						variables: {input},
						updateQueries: {
							Videos: (prev, {mutationResult}) => {
								const newVideo = mutationResult.data.addVideo;
								return Object.assign(
									{},
									{
										videos: [newVideo, ...prev.videos],
									},
								);
							},
						},
					});
				},
			};
		},
	}),
	graphql(VIDEO_DELETE_MUTATION, {
		props({mutate}) {
			return {
				deleteVideo({idVideo}) {
					return mutate({
						variables: {idVideo},
						update: (store, {data: {deleteVideo}}) => {
							const data = store.readQuery({
								query: VIDEOS_QUERY,
								variables: {offset: 1, limit: VIDEOS_LIMIT},
							});
							if (deleteVideo.deleted) {
								data.videos.some(function (item, index) {
									return data.videos[index]['id'] === deleteVideo.id
										? !!data.videos.splice(index, 1)
										: false;
								});
							}
							store.writeQuery({
								query: VIDEOS_QUERY,
								data,
								variables: {offset: 1, limit: VIDEOS_LIMIT},
							});
						},
					});
				},
			};
		},
	}),
)(withData);

export default withDataAndMutation;
