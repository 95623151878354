import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import './styles.scss';

class TimerBoard extends Component {
	constructor(props) {
		super(props);
		this.canvasTimer = React.createRef();
	}

	componentDidMount() {
		this.ctxTimer = this.canvasTimer.current.getContext('2d');
		this.updateDrawTimeBoard(0);
	}
	/*controlamos el renderizado del canvas desde el componente padre a través de resquesFrameAnimation */
	shouldComponentUpdate(nextProps) {
		if (this.props.timer !== nextProps.timer) {
			this.updateDrawTimeBoard(nextProps.timer);
		}
		return false;
	}

	/* el canvas se repinta hasta 60fps gracias a window.requestAnimationFrame, que recibe como parámetro de callback esta función  */
	updateDrawTimeBoard = (timer) => {
		this.ctxTimer.save();
		this.clean(this.canvasTimer);
		this.drawTimerBoard(
			this.ctxTimer,
			0.95 * (this.canvasTimer.current.width - 80),
			1,
			80,
			160,
			timer > 10 ? 10 : 10 - timer,
		);
	};

	/*======================================
	Funciones encargadas de dibujar elementos 
	========================================*/

	drawTimerBoard = (ctx, posX, posY, width, height, text) => {
		// ctx.shadowColor = "rgba(204, 204, 204, 0.5)";
		// ctx.shadowBlur = 5;
		// ctx.shadowOffsetX = -1;
		// ctx.shadowOffsetY = -1;
		this.drawRoundedRect(ctx, posX, posY, width, height * 0.5, 8);
		// this.drawRoundedRectA(ctx, posX, posY, width, height * 0.5, 8);
		// this.drawRoundedRectB(ctx, posX, posY + height * 0.5, width, height * 0.5, 8);
		this.drawText(ctx, text + '\'\'', posX + width * 0.5, 40);
	};

	drawRoundedRectA = (ctx, x, y, width, height, radius) => {
		ctx.beginPath();
		ctx.moveTo(x, y + radius);
		ctx.lineTo(x, y + height);
		ctx.lineTo(x + width, y + height);
		ctx.lineTo(x + width, y + radius);
		ctx.quadraticCurveTo(x + width, y, x + width - radius, y);
		ctx.lineTo(x + radius, y);
		ctx.quadraticCurveTo(x, y, x, y + radius);
		this.ctxTimer.strokeStyle = '#cccccc';
		this.ctxTimer.lineWidth = 1;
		ctx.fillStyle = '#ffffff';
		ctx.fill();
		ctx.stroke();
	};

	drawRoundedRectB = (ctx, x, y, width, height, radius) => {
		//inicio del trazo
		ctx.beginPath();
		//Posicionar el inicio del trazo
		ctx.moveTo(x, y);
		ctx.lineTo(x, y + height - radius);
		ctx.quadraticCurveTo(x, y + height, x + radius, y + height);
		ctx.lineTo(x + width - radius, y + height);
		ctx.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
		ctx.lineTo(x + width, y);
		ctx.lineTo(x, y);
		ctx.stroke();

		this.ctxTimer.strokeStyle = '#cccccc';
		this.ctxTimer.lineWidth = 1;
		ctx.fillStyle = '#ffffff';
		ctx.fill();
		ctx.stroke();
	};

	drawRoundedRect = (ctx, x, y, width, height, radius) => {
		ctx.beginPath();
		ctx.moveTo(x, y + radius);
		ctx.lineTo(x, y + height - radius);
		ctx.quadraticCurveTo(x, y + height, x + radius, y + height);
		ctx.lineTo(x + width - radius, y + height);
		ctx.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
		ctx.lineTo(x + width, y + radius);
		ctx.quadraticCurveTo(x + width, y, x + width - radius, y);
		ctx.lineTo(x + radius, y);
		ctx.quadraticCurveTo(x, y, x, y + radius);
		this.ctxTimer.strokeStyle = '#cccccc';
		this.ctxTimer.lineWidth = 1;
		ctx.fillStyle = '#ffffff';
		ctx.fill();
		ctx.stroke();
	};
	drawText = (ctx, text, posX, posY) => {
		const highlightColor = getComputedStyle(document.documentElement)
    .getPropertyValue('--highlightColor');
		ctx.font = 'bolder 40px sans-serif';
		ctx.fillStyle = highlightColor;
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.fillText(text, posX, posY);
	};

	/*Cada vez que hay un cambio en canvas, no se borra lo anterior, se superpone a lo que había antes. Por eso es necesario borrar el frame previo durante una animación */
	clean = (canvas) => {
		/*Limpia todo el canvas volviéndolo a redimensionar */
		const cleanCanvas = canvas.current.width;
		canvas.current.width = cleanCanvas;
	};

	render() {
		const mainStyleClass = 'blur-image-canvas__timer';
		const classes = classNames({
			[mainStyleClass]: true,
		});

		return (
			<WindowSizeContext.Consumer>
				{({width, device}) => {
					if (
						device === 'mobile' ||
						device === 'mobileLandscape' ||
						device === 'mobileLandscape' ||
						device === 'tabletPortrait'
					) {
						return (
							<div className={classes}>
								<canvas
									ref={this.canvasTimer}
									width={width - 34}
									height={(width - 34) * 0.6978 + 40}
									className={mainStyleClass + '__canvas'}
								/>
							</div>
						);
					} else if (device === 'tabletLandscape') {
						return (
							<div className={classes}>
								<canvas
									ref={this.canvasTimer}
									width={860 * 0.5}
									height={860 * 0.5 * 0.6978}
									className={mainStyleClass + '__canvas'}
								/>
							</div>
						);
					} else if (device === 'desktop') {
						return (
							<div className={classes}>
								<canvas
									ref={this.canvasTimer}
									width={width * 0.8 * 0.5}
									height={width * 0.8 * 0.5 * 0.6978}
									className={mainStyleClass + '__canvas'}
								/>
							</div>
						);
					} else {
						return (
							<div className={classes}>
								<canvas
									ref={this.canvasTimer}
									width={1489 * 0.8 * 0.5}
									height={1489 * 0.8 * 0.5 * 0.6978}
									className={mainStyleClass + '__canvas'}
								/>
							</div>
						);
					}
				}}
			</WindowSizeContext.Consumer>
		);
	}
}

TimerBoard.propType = {
	timer: PropTypes.number,
};

Response.defaultProps = {
	timer: 0,
};
export default TimerBoard;
