import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
// import Icon from 'ui/shared/Icon';
import Container from 'businessLogic/core/shared/Container/Container';
import TimeLeftCountdown from './components/TimeLeftCountdown';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
// import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import './styles.scss';

class CountDownTimer extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Contador regresivo`;
	static brickIcon = 'pdf';
	static brickImage = brickImage;
	static brickDefaultData = () => ({
		destDateTime: new Date().toString(),
		// button: _('Descargar'),
	});
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 0,
			paddingBottom: 0,
		},
	};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const destDateTime = data.destDateTime ? new Date(data.destDateTime) : new Date();

		const classes = classNames({
			'brick': true,
			'external-download-button-brick': true,
		});

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<TimeLeftCountdown date={destDateTime} />
				</div>
			</Container>
		);
	}
}

export default brickWrapper(CountDownTimer);
