import gql from 'graphql-tag';

export const IMAGES_QUERY = gql`
	query Images($offset: Int, $limit: Int, $width: Int, $height: Int, $type: String) {
		images_v2(offset: $offset, limit: $limit, width: $width, height: $height, type: $type) {
			id
			name
			width
			height
			path
		}
	}
`;

export const IMAGE_NUMBER_QUERY = gql`
	query ImageNumber($width: Int, $height: Int, $type: String) {
		imageNumber(width: $width, height: $height, type: $type)
	}
`;

export const IMAGE_ADD_SUBSCRIBE = gql`
	subscription AddImageSubscription($identificator: String) {
		addImageSubscription(identificator: $identificator) {
			id
			name
			width
			height
			path
		}
	}
`;

export const IMAGE_DELETE_SUBSCRIBE = gql`
	subscription DeleteImageSubscription($identificator: String) {
		deleteImageSubscription(identificator: $identificator) {
			id
			name
			width
			height
			path
		}
	}
`;

export const IMAGE_ADD_MUTATION = gql`
	mutation addImage($input: ImageInput!) {
		addImage_v2(input: $input) {
			id
			name
			width
			height
			path
		}
	}
`;

export const IMAGE_DELETE_MUTATION = gql`
	mutation deleteImage($idImage: String!) {
		deleteImage(idImage: $idImage) {
			id
			deleted
		}
	}
`;
