import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class ListBigNumbers extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Lista con números grandes`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		listItems: [
			{
				title: 'Micias nihilia suis cullita turi labo',
				text:
					'Whether it’s content related to your industry or updates on what your business is doing, stay in touch with your audience with Facebook posts. Use short, fun-to-read copy and eye-catching images to get attention. You can even schedule your posts to save time.',
			},
			{
				title: 'Micias nihilia suis cullita turi labo',
				text:
					'Whether it’s content related to your industry or updates on what your business is doing, stay in touch with your audience with Facebook posts. Use short, fun-to-read copy and eye-catching images to get attention. You can even schedule your posts to save time.',
			},
			{
				title: 'Micias nihilia suis cullita turi labo',
				text:
					'Whether it’s content related to your industry or updates on what your business is doing, stay in touch with your audience with Facebook posts. Use short, fun-to-read copy and eye-catching images to get attention. You can even schedule your posts to save time.',
			},
		],
	};

	static brickDefaultConfig = {startNumber: 1};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick?.styles ?? {};
		const config = this.props.brick?.config ?? {};
		let items = data.listItems || [];

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ListBigNumbers']);

		const classes = classNames({
			'list-big-numbers-brick': true,
		});

		const baseNumber = parseInt(config?.startNumber || 1);

		let elements = items.map((listItem, index) => {
			const currentNumber = index + baseNumber;
			return (
				<li key={index} className="list-big-numbers-brick__ul__li">
					<div className="list-big-numbers-brick__ul__li__number">
						<Animation
							playAnimation={this.state.playEffect}
							delay={(index + 1) * 0.5}
							effect={brickAnimation}
						>
							<span className="list-big-numbers-brick__ul__li__number__span">{currentNumber}</span>
						</Animation>
					</div>
					<div className={classes + '__item-content'}>
						<div className={classes + '__item-content__title'}>
							<FrontEditableText
								fieldName="title"
								text={listItem.title}
								set={(fieldPath, fieldData) => {
									//Propagamos la llamada al método set añadiendo mi nodo al path
									if (this.props.set)
										this.props.set(['listItems', index].concat(fieldPath), fieldData);
								}}
							/>
						</div>
						<div className={classes + '__item-content__text'}>
							<FrontEditableText
								fieldName="text"
								text={listItem.text}
								set={(fieldPath, fieldData) => {
									//Propagamos la llamada al método set añadiendo mi nodo al path
									if (this.props.set)
										this.props.set(['listItems', index].concat(fieldPath), fieldData);
								}}
							/>
						</div>
					</div>
				</li>
			);
		});

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={'brick ' + classes}>
					<ul className="list-big-numbers-brick__ul">{elements}</ul>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ListBigNumbers);
