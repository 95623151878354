import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';

import './styles.scss';

class ImportantTextLeft extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Destacado alineado a la izquierda`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		text:
			'Feri dem eos volorat. Archil iusdaecto inveria nditat.Hiligent quae re pre la doloremporro magni od quiatur a sunditate cuptas dolestrunt, cus ipiendis into el magnis soluptiunt dunt',
	};

	static brickDefaultConfig = {};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ImportantTextLeft']);

		const classes = classNames({
			'brick': true,
			'important-text-left-brick': true,
		});

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div className="important-text-left-brick__wrapper">
						<Animation
							playAnimation={this.state.playEffect}
							delay={1}
							duration={1.5}
							effect={Array.isArray(brickAnimation) ? brickAnimation[0] : brickAnimation}
						>
							<div className="important-text-left-brick__line" />
						</Animation>
					</div>
					<div className="important-text-left-brick__text">
						<Animation
							playAnimation={this.state.playEffect}
							delay={2}
							effect={Array.isArray(brickAnimation) ? brickAnimation[1] : brickAnimation}
						>
							<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
						</Animation>
					</div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ImportantTextLeft);
