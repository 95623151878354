import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Question from './components/Question';
import Swiper from 'react-id-swiper';
import './styles.scss';

class Test extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
		};
		this.swiper = null;
	}
	goNext = () => {
		if (this.swiper) {
			this.swiper.slideNext();
			this.props.onScroll();
		}
	};
	changeActiveSlideContent = () => {
		if (this.swiper) {
			this.setState({
				activeIndex: this.swiper.activeIndex,
			});
		}
	};

	render() {
		const classes = classNames({
			'test-basic-images-brick__questions': true,
			'swiper-no-swiping': true,
		});

		//Parámetros configuración swiper
		//https://www.npmjs.com/package/react-id-swiper
		const params = {
			shouldSwiperUpdate: true, //si no no se vuelve a renderizar al añadir imágenes al carrusel
			rebuildOnUpdate: false, //se recontruye si se cambian parámetros (valueDelay) o se añaden elementos al carrusel
			direction: 'vertical',
			spaceBetween: 0,
			speed: 1500,
			slidesPerView: 1,
			loop: false,
			noSwiping: true,
			autoHeight: true,
			getSwiper: (swiper) => {
				this.swiper = swiper;
				this.changeActiveSlideContent();
				this.swiper.on('slideChange', () => {
					this.changeActiveSlideContent();
				});
			},
		};
		return (
			<div className={classes}>
				<Swiper {...params}>
					{this.props.questionsCollection.map((questionItem, questionIndex) => (
						<Question
							key={questionIndex}
							statement={questionItem.question}
							setResponses={questionItem.answers}
							onFinished={this.props.onFishedQuestion}
							index={questionIndex}
							activeIndex={this.state.activeIndex}
							initialQuestionTime={this.props.initialQuestionTime}
							set={(fieldPath, fieldData) => {
								//Propagamos la llamada al método set añadiendo mi nodo al path
								if (this.props.set)
									this.props.set(['questions', questionIndex].concat(fieldPath), fieldData);
							}}
							valueSuccess={this.props.valueSuccess}
							valueFailure={this.props.valueFailure}
						/>
					))}
				</Swiper>

				<div className="pagination">
					<span className="pagination__bullet">{this.state.activeIndex + 1}</span>
					<span className="pagination__bullet">/</span>
					<span className="pagination__bullet">{this.props.questionsCollection.length}</span>
				</div>
			</div>
		);
	}
}

Test.propTypes = {
	questionsCollection: PropTypes.array,
	valueQuestion: PropTypes.number,
	valueFailure: PropTypes.number,
	onFinish: PropTypes.func,
	onFishedQuestion: PropTypes.func,
	showButton: PropTypes.bool,
	set: PropTypes.func,
	onClickNext: PropTypes.func,
	onScroll: PropTypes.func,
};
export default Test;
