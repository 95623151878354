import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Icon from 'ui/shared/Icon';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class AddFile extends PureComponent {
	render() {
		const {className, buttonText} = this.props;
		let finalButtonText = buttonText;
		if (this.props.fileType === 'pdf') finalButtonText = t`Añadir fichero pdf`;
		return (
			<div className="edit__add-image-carousel">
				<div className={'carousel-image-container ' + className}>
					{className !== 'normal-gallery' && <span className="title">&nbsp;</span>}
					<div
						className={'carousel-image-add-image ' + className}
						onClick={this.props.handleUploadImage}
					>
						<Icon name="add-image" size="normal" />
						<div>{finalButtonText}</div>
					</div>
				</div>
			</div>
		);
	}
}

AddFile.propTypes = {
	className: PropTypes.string,
	buttonText: PropTypes.string,
	handleUploadImage: PropTypes.func,
};
AddFile.defaultProps = {
	className: '',
	buttonText: t`Añadir imagen`,
};

export default AddFile;
