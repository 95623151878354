import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import VisibleImageContext from 'businessLogic/contexts/VisibleImageContext';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	// Label,
	// Text,
	ResponsiveContainer,
} from 'recharts';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import editionFields from './edit.js';
import {generateId} from 'businessLogic/helpers/functions';
import cloneDeep from 'lodash/cloneDeep';
import './styles.scss';

class LineDiagram extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Gráfico de líneas`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	// static canReload = true;
	static brickDefaultData = {
		title: t`Título del gráfico`,
		references: t`Referencias`,
		titleX: t`Título del ejeX`,
		titleY: t`Título del ejeY`,
		dataSheet: [
			{
				name: t`Columna A`,
				value: [
					{_id: generateId(), raw: 2000, label: t`Serie 1`}, //los labels de las filas
					{_id: generateId(), raw: 1000, label: t`Serie 2`},
				],
			},

			{
				name: t`Columna B`,
				value: [
					{_id: generateId(), raw: 2000, label: t`Serie 1`},
					{_id: generateId(), raw: 1000, label: t`Serie 2`},
				],
			},
			{
				name: t`Columna C`,
				value: [
					{_id: generateId(), raw: 1000, label: t`Serie 1`},
					{_id: generateId(), raw: 200, label: t`Serie 2`},
				],
			},
			{
				name: t`Columna D`,
				value: [
					{_id: generateId(), raw: 500, label: t`Serie 1`},
					{_id: generateId(), raw: 500, label: t`Serie 2`},
				],
			},
			{
				name: t`Columna E`,
				value: [
					{_id: generateId(), raw: 10000, label: t`Serie 1`},
					{_id: generateId(), raw: 500, label: t`Serie 2`},
				],
			},
		],
	};

	static brickDefaultConfig = {
		yAxisConfig: {
			yAxisMin: 0,
			yAxisMax: 'auto',
			yAxisInterval: null,
		},
	};

	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
		barChartStyle: {
			colors: [
				'#31C6B8',
				'#687EDB',
				'#FEDC82',
				'#FA5F85',
				'#33CCFF',
				'#FF9900',
				'#FF3300',
				'#CC0000',
				'#990000',
				'#660000',
				'#330000',
				'#00FF00',
				'#00CC00',
				'#009900',
				'#006600',
				'#003300',
				'#00FFFF',
				'#FFCC00',
				'#FF6600',
				'#FF0000',
				'#000000',
			],
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			device: null,
			isVisible: false,
			isAnimationActive: true,
		};
	}

	changeDevice = (device) => {
		if (this.state.device !== null) {
			if (this.state.device !== device) {
				this.setState({isAnimationActive: false}); //si cambia el device desactivamos la animación del gráfico
			}
		}
		this.setState({device: device});
	};

	handleVisibilityChange(isVisible) {
		if (isVisible && !this.state.isVisible) this.setState({isVisible: isVisible});
	}

	// Limpia el conjunto de datos poniendo a 0 los datos inválidos para evitar petes
	cleanDataSet(dataset) {
		const cleanDataset = cloneDeep(dataset);
		for (const column of cleanDataset) {
			for (const value of column.value) {
				if (isNaN(value.raw)) {
					value.raw = 0;
				}
			}
		}
		return cleanDataset;
	}

	render() {
		const data = this.props.brick.data;
		const config = get(this.props, 'brick.config', {});
		const styles = get(this.props, 'brick.styles', {});
		const mainStyleClass = 'line-chart-brick';
		const classes = classNames({
			brick: true,
			[mainStyleClass]: true,
		});

		/**
		 * Ñapa
		 * Los colores se están guardando en el ladrillo en styles, con el objetivo de poder
		 * editarlos en un futuro. Se quiere que el primer color sea el de Destacado.
		 * De manera provisional, establecemos la secuencia como fija, con la condición de que si
		 * solo hay una serie, usamos el color de destacado de la plataforma. Así garantizamos
		 * que los colores casan entre si en caso de que haya más de uno.
		 *
		 * Para poder editar los colos en un futuro, lo ideal sería crear un edition brick específico
		 * que permita crear un array de colores, de modo que si no existe o está deshabilitado se coja
		 * el de por defecto, y si existe coja esos. Lo ideal sería usar otra propiedad distinta de
		 * barCharStyle para no estar atado a lo que hay guardado de antes.
		 */
		const colors = ['#31C6B8', '#687EDB', '#FEDC82', '#FA5F85', '#33CCFF'];
		const nSeries = get(data, 'dataSheet.0.value.length');
		if (nSeries === 1) {
			colors.unshift('var(--highlightColor, #73e5d1)');
		}

		let yAxisMax, yAxisMin, yAxisInterval;

		if (config === null || config.yAxisConfig === undefined) {
			yAxisMax = 'auto';
			yAxisMin = 0;
			yAxisInterval = null;
		} else {
			yAxisMax =
				config.yAxisConfig.yAxisMax !== 'auto' && parseInt(config.yAxisConfig.yAxisMax) > 0
					? parseInt(config.yAxisConfig.yAxisMax)
					: 'auto';
			yAxisMin =
				parseInt(config.yAxisConfig.yAxisMin) > 0 ? parseInt(config.yAxisConfig.yAxisMin) : 0;
			yAxisInterval = parseInt(config.yAxisConfig.yAxisInterval);
		}

		let yAxisTicks = null; //si no va a haber ticks tiene que ser null para que pinte las divisiones de los ticks por defecto si no no los pinta

		if (yAxisInterval > 0 && yAxisMax > 0) {
			let valueReference = yAxisMax - yAxisMin;

			let intervalLimit = Math.floor(valueReference * 5) / 100;
			//solo se aplicará el intervalo si es igual o superior al 5% de la diferencia máximo-mínimo

			if (yAxisInterval >= intervalLimit) {
				yAxisTicks = [];
				for (let i = yAxisMin; i <= yAxisMax; i += yAxisInterval) {
					yAxisTicks.push(i);
				}
				//incluir el máximo como tick si no se ha incluido con el for

				if (yAxisTicks.length > 0 && yAxisTicks[yAxisTicks.length - 1] < yAxisMax)
					yAxisTicks.push(yAxisMax);
			}
		}
		//Se están borrando las labels del eje x al hacer resize o cambio de device,
		//la solución buscando en foros y funciona es crear un tick personalizado y así no desaparece
		class CustomizedAxisTick extends PureComponent {
			render() {
				const {x, y, payload} = this.props;
				return (
					<g transform={`translate(${x},${y})`}>
						{/* <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)"> */}
						<text x={0} y={0} dy={10} textAnchor="middle">
							{payload.value}
						</text>
					</g>
				);
			}
		}

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<VisibleImageContext.Consumer>
					{(visibleImageContext) => (
						<WindowSizeContext.Consumer>
							{({device}) => {
								this.changeDevice(device);
								return (
									<div className={classes}>
										<div className={mainStyleClass + '__title'}>
											<FrontEditableText fieldName="title" text={data.title} set={this.props.set} />
										</div>

										<div className={mainStyleClass + '__wrapperDiagram'}>
											<div className={mainStyleClass + '__title--xaxis'}>
												<FrontEditableText
													fieldName="titleX"
													text={data.titleX}
													set={this.props.set}
												/>
											</div>
											<div className={mainStyleClass + '__title--yaxis'}>
												<FrontEditableText
													fieldName="titleY"
													text={data.titleY}
													set={this.props.set}
												/>
											</div>

											<ResponsiveContainer aspect={1.78}>
												<LineChart data={this.cleanDataSet(data.dataSheet)}>
													<CartesianGrid strokeDasharray="3 3" />

													<XAxis
														dataKey="name"
														domain={['auto', 'auto']}
														tick={<CustomizedAxisTick />}
													/>

													{/* eje y personalizable con valor máximo y mínimo */}
													<YAxis
														type="number"
														domain={[yAxisMin, yAxisMax]}
														interval={0}
														ticks={yAxisTicks}
													/>

													<Tooltip />
													<Legend verticalAlign="top" height={60} />
													{(this.state.isVisible || visibleImageContext.visibleImages) &&
														data.dataSheet[0].value.map((valueBar, index) => {
															return (
																<Line
																	key={valueBar._id}
																	dataKey={`value[${index}].raw`}
																	type="monotone"
																	//para los colores tenemos una paleta usamos el operador % - módulo, para calcular el resto, así si hay más barras que colores, se usaran otra vez los del principio
																	stroke={colors[index % colors.length]}
																	strokeWidth={4}
																	name={valueBar.label}
																	animationDuration={2000}
																	animationBegin={2000 * index}
																	animationEasing="ease-in-out"
																	isAnimationActive={this.state.isAnimationActive}
																/>
															);
														})}
												</LineChart>
											</ResponsiveContainer>
										</div>

										<div className={mainStyleClass + '__references'}>
											<FrontEditableText
												fieldName="references"
												text={data.references}
												set={this.props.set}
											/>
										</div>
									</div>
								);
							}}
						</WindowSizeContext.Consumer>
					)}
				</VisibleImageContext.Consumer>
			</Container>
		);
	}
}

export default brickWrapper(LineDiagram);
