import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import PlayButton from 'businessLogic/shared/Buttons/PlayButton';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {imageUrl} from 'businessLogic/helpers/images';
import './styles.scss';

class VideoImage extends PureComponent {
	render() {
		const {onClick, image, videoState} = this.props;
		const videoImageClasses = classNames({
			'video-image': true,
			'video-image--show': videoState,
			'video-image--hide': !videoState,
		});

		return (
			<div
				className={videoImageClasses}
				ref="videoImage"
				style={{
					backgroundImage: `url('${imageUrl(image)}')`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			>
				{/* <img src={imageUrl(image)} alt="" /> */}
				<PlayButton onClick={onClick} />
			</div>
		);
	}
}

VideoImage.propTypes = {
	className: PropTypes.string,
	image: PropTypes.string,
	onClick: PropTypes.func,
	videoState: PropTypes.bool,
};

export default brickWrapper(VideoImage);
