import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import SeveralImages from 'businessLogic/core/shared/Image/SeveralImages';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import get from 'lodash/get';

import './styles.scss';

class TwoImagesNoMargin extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Dos imágenes horizontales sin márgenes`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
			},
		],
	};
	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && this.state.counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}
	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const images = data.images || [];
		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'TwoImagesTitleTextNoMargin']);

		const classes = classNames({
			'brick': true,
			'two-images-no-margin-brick': true,
			'two-images-no-margin-brick--with-caption1':
				images[0].imageObj.footer || images[0].imageObj.credit,
			'two-images-no-margin-brick--with-caption2':
				images[1].imageObj.footer || images[1].imageObj.credit,
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<WindowSizeContext.Consumer>
					{({device}) => {
						return (
							<div className={classes}>
								<SeveralImages
									format="landscape"
									margin={false}
									images={images}
									effect={brickAnimation}
									delay={0.5}
									playAnimation={this.state.playEffect}
									set={(fieldPath, fieldData) => {
										//Propagamos la llamada al método set añadiendo mi nodo al path
										this.props.set(['images'].concat(fieldPath), fieldData);
									}}
									clickable={device === 'mobile' || device === 'mobileLandscape' ? false : true}
								/>
							</div>
						);
					}}
				</WindowSizeContext.Consumer>
			</Container>
		);
	}
}

export default brickWrapper(TwoImagesNoMargin);
