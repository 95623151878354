import assign from 'lodash/assign';
import store from 'businessLogic/store/store.js';
import get from 'lodash/get';
//Action Types
export const PUSH = 'myb-admin/router/action/PUSH';
export const REPLACE = 'myb-admin/router/action/REPLACE';
export const GO = 'myb-admin/router/action/GO';
export const GO_BACK = 'myb-admin/router/action/GO_BACK';
export const GO_FORWARD = 'myb-admin/router/action/GO_FORWARD';
export const LOCATION_CHANGE = 'myb-admin/router/action/LOCATION_CHANGE';

//Action Creators
export const push = (href, omitRender = false) => {
	store.dispatch({
		type: PUSH,
		payload: {href, omitRender},
	});
};
export const getQueryParams = () => {
	const {dataGeneral} = store.getState();
	const query = get(dataGeneral, 'router.search', null);
	const objectQuery = {};
	if (query) {
		query
			.replace('?', '')
			.split('&')
			.forEach((param) => (objectQuery[param.split('=')[0]] = param.split('=')[1]));
	}
	return objectQuery;
};
export const replace = (href, omitRender = false) =>
	store.dispatch({
		type: REPLACE,
		payload: {href, omitRender},
	});

//SANTI: Comento estas tres funciones porque no es sencillo darles soporte en Scorm, y dado que ahora no se usan, es mejor no llegar a usarlas
// export const go = (index) =>
// 	store.dispatch({
// 		type: GO,
// 		payload: index
// 	});

// export const goBack = () =>
// 	store.dispatch({
// 		type: GO_BACK
// 	});

// export const goForward = () =>
// 	store.dispatch({
// 		type: GO_FORWARD
// 	});

export const locationChange = ({pathname, search, hash, omitRender}) => {
	return {
		type: LOCATION_CHANGE,
		payload: {
			pathname,
			search,
			hash,
			omitRender,
		},
	};
};

const getAbsoluteUrl = (function () {
	let a;

	return function (url) {
		if (!a) a = document.createElement('a');
		a.href = url;

		return a.href;
	};
})();

let initialState = {
	pathname: '/',
	search: '',
	hash: '',
	omitRender: false,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return assign({}, state, action.payload);
		case PUSH:
		case REPLACE:
			//Esto solo se ejecutará en Scorm, porque el resto del tiempo el routerMiddleware intercepta la acción y cambia el history, y los cambios del history provocan un changeLocation
			const url = new URL(action.payload.href, getAbsoluteUrl(state.pathname));
			return assign({}, state, {pathname: url.pathname, search: '', hash: ''});
		default:
			return state;
	}
}
