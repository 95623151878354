import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import AddButton from 'businessLogic/core/shared/Buttons/AddButton';
// import Button from 'businessLogic/shared/Buttons/Button';
import Icon from 'ui/shared/Icon';
import DeleteModal from './components/DeleteModal';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class EditBrickWrapper extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showModalDelete: false,
			openActions: false,
		};
	}
	handleShowModalDelete = () => {
		this.setState((state) => {
			return {
				showModalDelete: !state.showModalDelete,
			};
		});
	};
	openBrickTypeSelector = () => {
		this.props.addBrick(this.props.parentBrickId, this.props.brickIndex + 1, this.props.childList);
	};
	edit = () => {
		this.props.editBrick(this.props.brick.id);
	};
	remove = () => {
		this.props.deleteBrick(this.props.brick.id);
	};
	reloadBrick = () => {
		this.props.reloadBrick(this.props.brick.id);
	};
	duplicateBrick = () => {
		this.props.duplicateBrick(this.props.brick.id);
	};
	moveUp = () => {
		this.props.moveUp(this.props.brick.id);
	};
	moveDown = () => {
		this.props.moveDown(this.props.brick.id);
	};
	render() {
		const defaultOptions = {
			includeEditButtons: false,
			includeAddBrickButton: false,
		};
		//Obtenemos las opciones, cogiendo los valores por defecto en caso de no especificarlos
		const {includeEditButtons, includeAddBrickButton} = {...defaultOptions, ...this.props.options};
		if (!this.props.editMode) {
			return <div data-brick-id={this.props.brick.id}>{this.props.children}</div>;
		}
		return (
			<div
				className={
					this.props.children.props.options === undefined ? 'bricks-wrap' : 'brick-wrapper'
				}
				data-brick-id={this.props.brick.id}
				onMouseEnter={() => {
					this.setState({openActions: true});
				}}
				onMouseLeave={() => {
					this.setState({openActions: false});
				}}
			>
				{includeEditButtons && (
					<SpeedDial
						ariaLabel={t`Acciones`}
						open={this.state.openActions}
						direction="left"
						sx={() => ({
							'position': 'absolute',
							'right': '5px',
							// 'left': '0',
							// 'margin': 'auto',
							'top': '5px',
							'zIndex': 1,
							'paddingRight': 0,
							'justifyContent': 'center',

							'& .MuiSpeedDial-fab': {
								display: 'none',
							},
							'& .MuiSpeedDial-actions': {
								flexDirection: 'row',
								paddingRight: '0px',
								marginRight: '0px',
							},
							'& .icon-comp': {
								'fontSize': '20px',
								'margin': 0,
								'paddingTop': '2px',
								'&.view-icon': {
									fontSize: '22px',
									paddingTop: '4px',
								},
							},
							'& button': {
								margin: '5px',
							},
						})}
					>
						{this.props.reloadBrick && (
							<SpeedDialAction
								icon={<Icon name="replay" size="small" />}
								tooltipTitle={t`Recargar`}
								onClick={this.reloadBrick}
								tooltipPlacement="bottom"
							/>
						)}

						{!this.props.disableEdit && (
							<SpeedDialAction
								icon={<Icon name="edit" size="small" />}
								tooltipTitle={t`Editar`}
								onClick={this.edit}
								tooltipPlacement="bottom"
							/>
						)}

						{this.props.duplicateBrick && (
							<SpeedDialAction
								icon={<Icon name="duplicate" size="small" />}
								tooltipTitle={t`Duplicar`}
								onClick={this.duplicateBrick}
								tooltipPlacement="bottom"
							/>
						)}

						<SpeedDialAction
							icon={<Icon name="delete" size="small" />}
							tooltipTitle={t`Eliminar`}
							onClick={this.handleShowModalDelete}
							tooltipPlacement="bottom"
						/>

						{this.props.moveUp && (
							<SpeedDialAction
								icon={<Icon name="arrow-up" size="small" />}
								tooltipTitle={t`Subir`}
								onClick={this.moveUp}
								tooltipPlacement="bottom"
							/>
						)}

						{this.props.moveDown && (
							<SpeedDialAction
								icon={<Icon name="arrow-down" size="small" />}
								tooltipTitle={t`Bajar`}
								onClick={this.moveDown}
								tooltipPlacement="bottom"
							/>
						)}
					</SpeedDial>
				)}
				{this.state.showModalDelete && (
					<DeleteModal
						onClose={this.handleShowModalDelete}
						show={this.state.handleShowModalDelete}
						deleteBrick={this.remove}
					/>
				)}
				{this.props.children}
				<div className="add-button-wrapper">
					{includeAddBrickButton && (
						<AddButton labelButton="Añadir elemento" onClick={this.openBrickTypeSelector} />
					)}
				</div>
			</div>
		);
	}
}

EditBrickWrapper.propTypes = {
	className: PropTypes.string,
	addBrick: PropTypes.func,
	childList: PropTypes.string,
	disableEdit: PropTypes.bool,
};

EditBrickWrapper.defaultProps = {
	className: '',
	childList: 'default',
	disableEdit: false,
};

export default EditBrickWrapper;
