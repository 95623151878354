import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Image from 'businessLogic/core/shared/Image/Image';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';

// import brickImage from "./img/brickPreview.png";
class ImageElement extends PureComponent {
	render() {
		const {image, format, clickable} = this.props;
		const imageObj = image.imageObj || {};
		const credit = image.imageObj.credit || '';
		const footer = image.imageObj.footer || '';
		const title = image.title || '';
		const text = image.text || '';
		return (
			<div className="image-text-container">
				<div className="image-container">
					<Image
						format={format}
						imgObj={imageObj}
						imgWidths={this.props.imgWidths}
						clickable={clickable}
					/>

					{(credit !== '' || footer !== '') && (
						<div className="image-caption">
							<div className="caption-footer"> {footer} </div>
							<div className="caption-credit"> {credit} </div>
						</div>
					)}
				</div>
				{(title !== '' || text !== '') && (
					<div className="title-text-box">
						<div className="title-box">
							<FrontEditableText fieldName="title" text={title} set={this.props.set} />
						</div>

						<div className="text-box">
							<FrontEditableText fieldName="text" text={text} set={this.props.set} />
						</div>
					</div>
				)}
			</div>
		);
	}
}

ImageElement.propTypes = {
	image: PropTypes.object,
	format: PropTypes.string,
	set: PropTypes.func,
	imgWidths: PropTypes.object, //Objeto con el ancho asociado a cada dispositivo
	clickable: PropTypes.bool,
};

ImageElement.defaultProps = {imgWidths: {}, clickable: true};

export default ImageElement;
