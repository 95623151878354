import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import Button from 'businessLogic/shared/Buttons/Button';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import './styles.scss';

const NavigationTestBrick = (props) => {
		const [sendClicked, setSendClicked] = React.useState(false);
		const mainStyleClass = 'navigation-test-brick';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			[`${mainStyleClass + '--disable-required'}`]: props.canContinue === false,
		});
		return (
			<div className={classes}>
				<CSSTransition
					in={props.isFirstQuestion === true ? false : true}
					timeout={500}
					classNames="message"
					unmountOnExit
				>
					<div
						className={mainStyleClass + '__arrow ' + mainStyleClass + '__arrow--prev'}
						onClick={props.onGoPrev}
					>
						<Icon name="navigate-up" size="normal" />
					</div>
				</CSSTransition>

				<TransitionGroup className="todo-list">
					{props.isLastQuestion ? (
						<CSSTransition key="finish" timeout={500} classNames="show">
							<div className={mainStyleClass + '__call-action-finished'}>
								{!props.disableTest && (
									<Button
										theme="primary"
										view="outline"
										rounded={true}
										// onClick={props.onGoFinish}
										onClick={() => {
											if (!sendClicked) {
												setSendClicked(true);
												props.onGoFinish();
											}
										}}
										title={!props.canContinue ? 'Debes contestar para poder continuar' : ''}
									>
										{_('ENVIAR')} <Icon name="success" size="normal" />
									</Button>
								)}
							</div>
						</CSSTransition>
					) : (
						<CSSTransition key="next" timeout={500} classNames="show">
							<div
								className={mainStyleClass + '__arrow ' + mainStyleClass + '__arrow--next'}
								onClick={props.onGoNext}
								title={!props.canContinue ? 'Debes contestar para poder continuar' : ''}
							>
								<Icon name="navigate-down" size="normal" />
							</div>
						</CSSTransition>
					)}
				</TransitionGroup>
			</div>
		);
	};


NavigationTestBrick.propTypes = {
	isFirstQuestion: PropTypes.bool,
	isLastQuestion: PropTypes.bool,
	onGoPrev: PropTypes.func,
	onGoNext: PropTypes.func,
	onGoFinish: PropTypes.func,
	disableTest: PropTypes.bool,
	canContinue: PropTypes.bool,
};

export default NavigationTestBrick;
