import React, {PureComponent} from 'react';
import LabelForInput from 'businessLogic/shared/Input/LabelForInput';
import Input from 'ui/shared/FormFields/Input';
import Dialog from 'businessLogic/shared/Modals/Dialog';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class LinkDialog extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			linkVisible: false,
			href: '',
			index: 0,
			length: 0
		};
	}
	showDialog = (url, index, length) => {
		this.setState({
			linkVisible: true
		});
		if (url) {
			this.setState({
				href: url
			});
		} else {
			this.setState({
				href: ''
			});
		}
		if (index && length) {
			this.setState({
				index: index,
				length: length
			});
		}
	};
	closeDialog = () => {
		let data = this.state.href;
		this.props.onUpdate(data, this.state.index, this.state.length);
		this.setState({linkVisible: false});
	};
	onChangeInputhref = (event) => {
		this.setState({href: event.target.value});
	};
	render() {
		return (
			<React.Fragment>
				{this.state.linkVisible && (
					<Dialog
						size="small"
						title={t`Enlace`}
						visible={true}
						onCancel={this.closeDialog}
						mask={true}
						noDraggable={true}
						zIndex={this.props.zIndex || 2}
						footer={true}
					>
						<div className="link-edit-dialog">
							<LabelForInput text={t`Introduce un link`} />
							<Input
								name="text-button"
								type="text"
								placeholder={t`Añade una url`}
								value={this.state.href}
								refs="inputhref"
								onChange={this.onChangeInputhref}
							/>
						</div>
					</Dialog>
				)}
			</React.Fragment>
		);
	}
}
export default LinkDialog;
