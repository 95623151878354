import {client} from 'businessLogic/store/store';
import {PILLS_QUERY, PILL_QUERY, PILLS_BY_OWNER_QUERY} from './query';
import {initialState} from '../actions';
import Logger from 'businessLogic/services/Logger';

export function queryGetPills() {
	return client
		.query({
			query: PILLS_QUERY,
			fetchPolicy: 'network-only',
		})
		.then((data) => {
			initialState(data.data.pills);
			return true;
		})
		.catch((error) => Logger.captureException(error));
}

export function queryGetPill(pillId) {
	return client
		.query({
			query: PILL_QUERY,
			variables: {
				id: pillId,
			},
			fetchPolicy: process.env.REACT_APP_EVENTS_API === 'scorm' ? 'cache-only' : 'network-only',
		})
		.then((data) => {
			initialState([data.data.pill], true);
			return true;
		})
		.catch((error) => Logger.captureException(error));
}

export function queryGetPillsByOwner(owner) {
	return client
		.query({
			query: PILLS_BY_OWNER_QUERY,
			variables: {
				owner,
			},
		})
		.then((data) => {
			initialState(data.data.pills);
			return true;
		})
		.catch((error) => Logger.captureException(error));
}
