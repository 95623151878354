import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import './styles.scss';

class SelectableTagLabel extends Component {
	
	renderChecked = () => {
		if (!this.props.order && this.props.order !== 0) {
			return <Icon name="check" size="normal" />;
		}
		return <span>{this.props.order}</span>;
	};

	render() {
		const mainStyleClass = 'tag-label-selectable';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			[`${mainStyleClass + '--checked'}`]: this.props.checked,
			[`${mainStyleClass + '--disable'}`]: this.props.disable,
			'swiper-no-swiping': true
		});

		return (
			<label className={classes}>
				<input
					name={this.props.name}
					checked={this.props.checked}
					type={this.props.type}
					onChange={this.props.onChange}
					className="visuallyhidden"
				/>

				<div className={mainStyleClass + '__status'}>
					{this.props.checked ? this.renderChecked() : <Icon name="plus" size="normal" />}
				</div>
				<p className={mainStyleClass + '__text'}>{this.props.text}</p>
			</label>
		);
	}
}

SelectableTagLabel.propTypes = {
	name: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	type: PropTypes.oneOf(['checkbox', 'radio']),
	checked: PropTypes.bool,
	order: PropTypes.number,
	onChange: PropTypes.func,
	template:PropTypes.oneOf(['radioTest', 'label'])
};
SelectableTagLabel.defaultProps = {
	text: 'Texto de etiqueta',
	checked: false,
	type: 'checkbox',
	order: undefined,
	template:'label'
};
export default SelectableTagLabel;
