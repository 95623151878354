import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Icon from 'ui/shared/Icon';
import Container from 'businessLogic/core/shared/Container/Container';
import sampleAudioUrl from './SampleAudio.mp3';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class AudioAutoPlay extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Audio con scroll`;
	static brickImage = brickImage;
	static brickHelpText = () => (
		<p>
			{t`Permite introducir un archivo de audio que se activará con el ladrillo Play scroll`}
			<br />
			<br />
			{t`Este ladrillo sirve para crear Storytelling. ¿Quieres ver un ejemplo? Pincha`}
			<a href="https://story-telling.cs01.cloud/" target="_blank" rel="noreferrer noopener">
				<i>{t`aquí`}</i>
			</a>
		</p>
	);

	static brickDefaultData = {
		audio: {
			audioSrc: sampleAudioUrl,
			credit: '',
			description: '',
		},
		volume: 100,
		changeSpeed: true,
	};

	static brickDefaultStyles = {
		containerStyle: {
			margin: '0 auto',
		},
	};

	constructor(props) {
		super(props);
		this.scrollPlay = false;
		this.isVisible = false;
		this.isMuted = false;
		this.playerRef = React.createRef();
		this.playerContainer = React.createRef();
		this.distanceBetweenAudioBricks = 0;
		this.playerDuration = 0;
		this.nextAudioBrick = null;
		this.prevAudioBrick = null;
	}

	setPrevAudioBrick = (audioRef) => {
		// Esta función nos permitirá saber cuando un ladrillo de audio tiene otro audio anterior
		// para más adelante mejorar el código y que si un ladrillo tiene anterior que se está
		// reproduciendo este no se reproduzca hasta que termine el anterior.
		// El problema viene cuando dos ladrillos se encuentran en la pantalla visibles a la vez
		// debería reproducirse el primero y setear la velocidad del scroll a 0 y cuando este termine que se reproduzca el siguiente
		this.prevAudioBrick = audioRef;
	};

	isVisible = () => {
		return this.isVisible;
	};

	onScrollPlay = () => {
		this.scrollPlay = true;
		if (this.isVisible) {
			this.playerRef.current.play();
		}
	};
	onScrollStop = () => {
		this.scrollPlay = false;
		this.playerRef.current.pause();
	};

	onMuteOn = () => {
		this.isMuted = true;
		this.playerRef.current.muted = true;
	};
	onMuteOff = () => {
		this.isMuted = false;
		this.playerRef.current.muted = false;
	};

	onNextAudioBrickMount = (audioRef) => {
		this.nextAudioBrick = audioRef;
		this.props.configObject.removeEventListener('audioAutoPlayMount', this.onNextAudioBrickMount);
	};

	handleVisibilityChange = (isVisible) => {
		this.isVisible = isVisible;
		if (this.playerRef.current) {
			const player = this.playerRef.current;
			if (isVisible) {
				player.currentTime = 0;
				let configSpeedObjetc = {};
				if (this.props.brick.data.changeSpeed === false) {
					configSpeedObjetc = {
						scrollSpeed: parseInt(this.props.configObject.returnSpeedBrick(), 10),
					};
				} else {
					let audioDuration = player.duration + 1;
					let nextAudioBrickTop;
					if (this.nextAudioBrick && this.nextAudioBrick.refs.container) {
						nextAudioBrickTop = this.nextAudioBrick.refs.container.getBoundingClientRect().top;
					}
					let currentAudioBrickTop =
						this.playerContainer.current.refs.container.getBoundingClientRect().top;

					let distanceBetweenAudioBricks = nextAudioBrickTop - currentAudioBrickTop;
					if (distanceBetweenAudioBricks !== 0 && !isNaN(distanceBetweenAudioBricks)) {
						let newAudioDuration;
						if (isNaN(audioDuration) || audioDuration === Infinity) {
							newAudioDuration = 60;
						} else {
							newAudioDuration = audioDuration;
						}
						let newScrollSpeed = distanceBetweenAudioBricks / (newAudioDuration * 10);

						configSpeedObjetc = {
							scrollSpeed:
								newScrollSpeed || parseInt(this.props.configObject.returnSpeedBrick(), 10),
						};
					} else {
						configSpeedObjetc = {
							scrollSpeed: parseInt(this.props.configObject.returnSpeedBrick(), 10),
						};
					}
				}
				this.props.configObject.unregisterConfig(this.playerContainer.current.refs.container);

				this.registerHandler = this.props.configObject.registerConfig(
					configSpeedObjetc,
					this.playerContainer.current.refs.container,
				);

				if (this.scrollPlay) {
					player.play();
				}
			} else {
				if (this.props.brick.data.pauseDisappear) {
					player.pause();
				}
			}
		}
	};
	componentDidUpdate() {
		this.setVolume();
	}
	componentDidMount() {
		this.setVolume();
		this.props.configObject.addEventListener('stop', this.onScrollStop);
		this.props.configObject.addEventListener('start', this.onScrollPlay);
		this.props.configObject.addEventListener('muteOn', this.onMuteOn);
		this.props.configObject.addEventListener('muteOff', this.onMuteOff);

		this.props.configObject.callEventListeners('audioAutoPlayMount', this.playerContainer.current);
		this.props.configObject.addEventListener('audioAutoPlayMount', this.onNextAudioBrickMount);
		this.playerRef.current.load();
	}

	componentWillUnmount() {
		this.props.configObject.removeEventListener('stop', this.onScrollStop);
		this.props.configObject.removeEventListener('start', this.onScrollPlay);
		this.props.configObject.removeEventListener('muteOn', this.onMuteOn);
		this.props.configObject.removeEventListener('muteOff', this.onMuteOff);
		this.props.configObject.removeEventListener('audioAutoPlayMount', this.onNextAudioBrickMount);
		this.props.configObject.unregisterConfig(this.playerContainer.current.refs.container);
	}

	setVolume = () => {
		const player = this.playerRef.current;
		if (this.props.brick.data.volume) {
			let audioVolume = parseInt(this.props.brick.data.volume, 10) / 100;
			let newAudioVolume;
			if (audioVolume < 1) {
				newAudioVolume = audioVolume;
			} else {
				newAudioVolume = 1;
			}
			let audioVolumeDecimal = newAudioVolume.toFixed(1);
			player.volume = audioVolumeDecimal;
		}
	};

	static brickDefaultConfig = {};
	render() {
		const classes = classNames({
			'brick': true,
			'audio-brick-autoplay': true,
		});
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const audio = data.audio;
		const pathObject = audio.audioSrc.split('/');
		//obtener tamaño de objeto devuelto por split
		Object.size = function (obj) {
			var size = 0,
				key;
			for (key in obj) {
				if (obj.hasOwnProperty(key)) size++;
			}
			return size;
		};
		let size = Object.size(pathObject);
		//el nombre del fichero siempre será la última posición del objeto devuelto por split
		const audioFileName = pathObject[--size];
		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
				ref={this.playerContainer}
				withMargin={false}
			>
				<div className={classes}>
					<audio
						controls
						key={audio.audioSrc}
						loop={data.loop}
						ref={this.playerRef}
						preload="auto"
						onPlay={() => {
							this.props.brickEvent('play');
						}}
						onPause={() => {
							this.props.brickEvent('pause');
						}}
						onEnded={() => {
							this.props.brickEvent('end');
						}}
					>
						<source src={audio.audioSrc} type="audio/mp3" />
					</audio>
				</div>
				{this.props.editMode && (
					<div className="audio-brick-autoplay__edit">
						<Icon name="add-audio" size="large" className="audio-brick-autoplay__edit__icon" />
						<span className="audio-brick-autoplay__edit__text">
							{t`Ladrillo Audio con scroll automático`}
						</span>
						<span className="audio-brick-autoplay__edit__file-name">{t`Archivo: ${audioFileName}`}</span>
						<span className="audio-brick-autoplay__edit__advice">
							{t`(Texto y fondo solo visible en modo edición)`}
						</span>
					</div>
				)}
			</Container>
		);
	}
}

export default brickWrapper(AudioAutoPlay);
