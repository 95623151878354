import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const styles = (theme) => ({
	root: {
		width: '100%',
		position: 'relative',
	},
	slider: {
		padding: '22px 0px',
	},
	track: {
		backgroundColor: theme.platformStyles.interactionColor,
	},
	thumb: {
		width: '18px',
		height: '18px',
		backgroundColor: theme.platformStyles.interactionColor,
		color: 'white',
		fontSize: '36px',
		fontWeight: 'bold',
	},
	trackAfter: {
		backgroundColor: 'white',
		opacity: '0.5',
	},
});

class NumericSlider extends Component {
	handleChange = (event, value) => {
		this.props.onChange(event, value);
	};

	render() {
		const {classes} = this.props;
		return (
			<div className="numeric-slider">
				<div className={classes.root}>
					<Slider
						classes={{
							container: classes.slider,
							track: classes.track,
							thumb: classes.thumb,
							trackAfter: classes.trackAfter,
						}}
						value={this.props.value === '' ? 0 : this.props.value}
						min={this.props.min}
						max={this.props.max}
						step={this.props.step}
						onChange={this.handleChange}
						thumb={
							<p style={{margin: 0, transform: 'translate(0,-250%)', minWidth: '40px'}}>
								{this.props.value}
							</p>
						}
					/>
				</div>
			</div>
		);
	}
}
NumericSlider.propTypes = {
	classes: PropTypes.object.isRequired,
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func,
};

NumericSlider.defaultProps = {
	min: 0,
	max: 100,
	step: 1,
	value: '',
};
export default withStyles(styles)(NumericSlider);
