import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Image',
						fieldName: t`image`,
						defaultImage: '/uploads/default-images/csDefault1Opt.svg',
						footer: false,
						credit: false,
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'CustomColor',
						name: t`Color del separador`,
						fieldName: ['containerStyle', 'generalColor'],
					},
				],
			},
		],
	},
];
export default editionFields;
