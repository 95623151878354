import * as CONST from './constants.js';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import get from 'lodash/get';

let initialState = [];

//Exportamos por defecto la función reducer que gestiona todas las acciones
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case CONST.CREATE_PILL:
			return createPill(state, action);
		case CONST.DELETE_PILL:
			return deletePill(state, action);
		case CONST.UPDATE_PILL:
			return updatePill(state, action);
		case CONST.CREATE_BRICK:
			return createBrick(state, action);
		case CONST.ADD_CHILD_BRICK:
			return addChildBrick(state, action);
		case CONST.UPDATE_BRICK:
			return updateBrick(state, action);
		case CONST.DELETE_BRICK:
			return deleteBrick(state, action);
		case CONST.INITIAL_STATE:
			return initialStatePills(state, action);
		default:
			return state;
	}
}

//Definimos las funciones que gestionan cada acción recibida para modificar el store

function createPill(state, action) {
	const pill = action.dataPayload.newPill;
	const newState = cloneDeep(state);
	newState.push(pill);
	return newState;
}

function deletePill(state, action) {
	const {pillId} = action.dataPayload;
	//Obtenemos el índice de la píldora que vamos a borrar
	const pillIndex = state.findIndex((value) => value.id === pillId);
	//Borramos la píldora a partir del indice obtenido

	const newState = cloneDeep(state);
	newState.splice(pillIndex, 1);
	return newState;
}

function updatePill(state, action) {
	const {pillId, pillData} = action.dataPayload;

	//Obtenemos el índice de la píldora que vamos a editar
	const pillIndex = state.findIndex((value) => value.id === pillId);

	const newState = cloneDeep(state);

	merge(newState[pillIndex], pillData);
	return newState;
}

function createBrick(state, action) {
	const {pillId, newBrick} = action.dataPayload;

	const newState = cloneDeep(state);

	//Obtenemos el índice de la píldora donde vamos a crear el ladrillo
	const pillIndex = newState.findIndex((value) => value.id === pillId);

	//Creamos un nuevo listado de ladrillos añadiendo el nuevo
	newState[pillIndex].content.bricks.push(newBrick);

	return newState;
}

function addChildBrick(state, action) {
	const {pillId, brickId, index, newBrick} = action.dataPayload;
	const childList = get(action.dataPayload, 'childList', 'default');

	const newState = cloneDeep(state);

	//Obtenemos el índice de la píldora donde vamos a crear el ladrillo
	const pill = newState.find((value) => value.id === pillId);

	//Obtenemos el índice del ladrillo que queremos modificar
	const brick = pill.content.bricks.find((value) => value.id === brickId);

	//Obtenemos el índice del nuevo hijo que vamos a incluir
	let childIndex = index;
	if (childIndex < 0) {
		childIndex = brick.children[childList].size;
	}

	pill.content.bricks.push(newBrick);
	if (!brick.children[childList]) {
		brick.children[childList] = [];
	}
	brick.children[childList].splice(childIndex, 0, newBrick.id);

	return newState;
}

function updateBrick(state, action) {
	const {pillId, brick} = action.dataPayload;

	const newState = cloneDeep(state);

	//Obtenemos el índice de la píldora donde vamos a crear el ladrillo
	const pill = newState.find((value) => value.id === pillId);

	//Obtenemos el índice del ladrillo que queremos modificar
	const brickIndex = pill.content.bricks.findIndex((value) => value.id === brick.id);

	//Actualizamos el ladrillo
	pill.content.bricks[brickIndex] = brick;

	return newState;
}

function deleteBrick(state, action) {
	const {pillId, brickId} = action.dataPayload;

	const newState = cloneDeep(state);

	//Obtenemos el índice de la píldora donde vamos a crear el ladrillo
	const pill = newState.find((value) => value.id === pillId);

	//Obtenemos el índice del ladrillo que queremos eliminar
	const brickIndex = pill.content.bricks.findIndex((value) => value.id === brickId);

	//Eliminamos el ladrillo de la lista de ladrillos
	pill.content.bricks.splice(brickIndex, 1);

	return newState;
}

function initialStatePills(state, action) {
	const {initialState, flatPillList} = action.dataPayload;
	const newState = cloneDeep(initialState);

	if (flatPillList) {
		const innerPills = [];
		recursiveFlatPillList(newState, innerPills);
		innerPills.reverse();
		return innerPills;
	}

	return newState;
}

// Recorre el listado de píldoras, añadiendo al array flattenPillArray todas las píldoras
// hijas, y sustituye pill.pills por un listado de ids
function recursiveFlatPillList(pillArray, flattenPillArray = []) {
	return pillArray.map((pill) => {
		if (pill && pill.pills && pill.pills.length > 0) {
			recursiveFlatPillList(pill.pills.filter((pill) => pill), flattenPillArray);
		}
		flattenPillArray.push(pill);
		return get(pill, 'id');
	});
}
