import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class ThemeSelector extends PureComponent {
	handleClickTheme = (theme) => {
		if (theme === this.props.active) {
			this.props.onSelectTheme('');
		} else {
			this.props.onSelectTheme(theme);
		}
	};

	render() {
		const {label, active} = this.props;
		const themes = [
			{slug: 'dark', label: t`Claro`},
			{slug: 'light', label: t`Oscuro`},
		];

		let themesElements = themes.map(({slug, label}, index) => {
			return (
				<div
					key={index}
					className={
						'theme-selector__list__button theme-selector__list__button--' +
						slug +
						(active === slug ? ' active ' : '')
					}
					onClick={() => this.handleClickTheme(slug)}
				>
					<div className="theme-selector__list__button__color-theme" />
					<span>{label}</span>
				</div>
			);
		});
		return (
			<div className="theme-selector">
				<div className="theme-selector__content">
					<label className="theme-selector__content__label">{label}</label>
					<p className="theme-selector__content__text">{t`Selecciona tema claro u oscuro.`}</p>
				</div>
				<div className="theme-selector__list">{themesElements}</div>
			</div>
		);
	}
}

ThemeSelector.propTypes = {
	label: PropTypes.string,
	onSelectTheme: PropTypes.func,
	active: PropTypes.string,
};

ThemeSelector.defaultProps = {
	label: 'Selecciona tema',
	active: '',
};

export default ThemeSelector;
