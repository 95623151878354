import React, {PureComponent} from 'react';
import Logger from 'businessLogic/services/Logger';
import AddFile from 'businessLogic/shared/Buttons/AddFile';
import ThumbnailBox from 'businessLogic/shared/Image/ThumbnailBox';
import Loading from 'ui/scope/admin/Loading';
import Dropzone from 'react-dropzone';
import {IMAGES_QUERY, IMAGE_NUMBER_QUERY, IMAGE_ADD_MUTATION, IMAGE_DELETE_MUTATION} from './query';
import {graphql} from 'react-apollo';
import {compose} from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import PropTypes from 'prop-types';
import pdfImageUrl from 'ui/shared/images/pdf-image.svg';
import './styles.scss';

class FilesList extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			imagesInProcess: [],
			scrollToFetch: 0,
			page: 1,
			reload: 1,
		};
	}

	handleUploadImage = () => {
		this.uploadInput.click();
	};

	isValidImage = (imgType, imgSize, imgWidth, imgHeight) => {
		const requiredWidth = get(this.props, 'filter.width');
		const requiredHeight = get(this.props, 'filter.height');
		switch (imgType) {
			case 'gif':
				if (imgSize && imgSize > 5242880) {
					this.imageError = t`Los gifs deben tener un tamaño inferior a 5MB.`;
					return false;
				}
				break;
			default:
				//Restringimos las imágenes de más de 25 megapíxeles
				if (imgWidth && imgHeight && imgWidth * imgHeight > 30000000) {
					this.imageError = t`Las imágenes deben tener menos de 30 megapíxeles`;
					return false;
				}
				if (
					(requiredWidth && requiredWidth !== imgWidth) ||
					(requiredHeight && requiredHeight !== imgHeight)
				) {
					this.imageError = t`Solo se admiten imágenes de ${requiredWidth}x${requiredHeight}`;
					return false;
				}
				break;
		}

		return true;
	};

	uploadImage = (file) => {
		//Obtenemos el tamaño del fichero a subir para ver si supera el límite
		const fileSize = file.size;
		if (fileSize > 25 * 1024 * 1024) {
			alert(
				t`El fichero que estás intentando subir pesa demasiado. Por favor, reduce el tamaño y vuelve a intentarlo.`,
			);
			return;
		}

		//Añadimos una imagen dummy para que el usuario sepa que se está subiendo la imagen
		this.setState((state) => {
			const imagesInProcess = [].concat(state.imagesInProcess);
			imagesInProcess.push('loading');
			return {imagesInProcess};
		});

		const data = new FormData();
		data.append('file', file);
		data.append('filename', file.name);
		data.append('type', 'image');
		fetch('/upload', {
			method: 'POST',
			body: data,
		})
			.then((response) => {
				//Comprobamos que la respuesta es correcta
				if (response.status === 413) {
					alert(
						t`El fichero que estás intentando subir pesa demasiado. Por favor, reduce el tamaño y vuelve a intentarlo.`,
					);
					this.removeLoading();
					return;
				}
				if (!response.ok || response.status !== 200) {
					alert(
						t`Se ha producido un error al subir el fichero. Prueba a recargar la página y volver a intentarlo. Si el problema persiste contacta con el administrador de la plataforma.`,
					);
					this.removeLoading();
					return;
				}

				//Obtenemos el json de respuesta del servidor
				response
					.json()
					.then((body) => {
						if (!this.isValidImage(body.extension, body.size, body.width, body.height)) {
							//Comprobamos que la imagen subida cumple los filtros
							alert(this.imageError);

							//Eliminamos la imagen dummy
							this.removeLoading();
							return;
						}

						//Añadimos la imagen subida
						this.props
							.addNewImage({
								input: {
									name: body.name,
									path: body.file,
									width: body.width,
									height: body.height,
								},
							})
							.then(() => {
								//Eliminamos la imagen dummy
								this.removeLoading();
							});
					})
					.catch((err) => {
						Logger.captureException(err);
						alert(
							t`Se ha producido un error al subir la imagen. Prueba a recargar la página y volver a intentarlo. Si el problema persiste contacta con el administrador de la plataforma.`,
						);
						//Eliminamos la imagen dummy
						this.removeLoading();
					});
			})
			.catch((err) => {
				Logger.captureException(err);
				alert(
					t`Se ha producido un error al subir la imagen. Prueba a recargar la página y volver a intentarlo. Si el problema persiste contacta con el administrador de la plataforma.`,
				);
				//Eliminamos la imagen dummy
				this.removeLoading();
			});
	};

	onUploadImage = (ev) => {
		ev.preventDefault();

		const files = this.uploadInput.files;
		Object.values(files).forEach(this.uploadImage);
	};

	onDrop = (files) => {
		files.forEach(this.uploadImage);
	};

	removeLoading = () => {
		this.setState((state) => {
			const imagesInProcess = [].concat(state.imagesInProcess);
			imagesInProcess.splice(0, 1);
			return {imagesInProcess};
		});
	};

	deleteImage = async (image) => {
		let mutationDelete = await this.props.deleteImage({
			idImage: image.id,
		});
		/**TODO faltaría darle estilos a no poder borrar la imagen */
		if (!get(mutationDelete, 'data.deleteImage.deleted')) {
			alert(t`No se puede borrar la imagen, ya que está siendo usada en alguna pildora`);
		} else {
			this.props.images.refetch();
		}
	};

	getFiles = () => {
		let renderImagesLoading;
		const loading = get(this.props, 'images.loading');
		const images = get(this.props, 'images.images_v2');
		if (loading) {
			renderImagesLoading = <Loading />;
			return renderImagesLoading;
		}
		if (!images || images.length === 0) {
			renderImagesLoading = <div>No hay ninguna imagen aún.</div>;
			return renderImagesLoading;
		}

		if (this.state.imagesInProcess.length > 0) {
			renderImagesLoading = this.state.imagesInProcess.map(() => {
				return (
					<ThumbnailBox
						className="normal"
						imageUrl="https://via.placeholder.com/150x150"
						key={`${images.length + this.state.imagesInProcess.length}key`}
					/>
				);
			});
		}
		let renderImages;
		renderImages = images
			.map((image) => {
				let srcImageBox;

				//obtener el nombre del fichero pdf para ponerlo con el thumbnail
				const pathObject = image.path.split('/');
				//obtener tamaño de objeto devuelto por split
				Object.size = function (obj) {
					var size = 0,
						key;
					for (key in obj) {
						if (obj.hasOwnProperty(key)) size++;
					}
					return size;
				};
				let size = Object.size(pathObject);

				//el nombre del fichero siempre será la última posición del objeto devuelto por split
				let newFileName = pathObject[--size];

				//si el archivo es pdf y el tipo de archivo a insertar es pdf
				if (this.props.fileType === 'pdf') {
					srcImageBox = pdfImageUrl;
					return (
						<ThumbnailBox
							className="normal"
							imageUrl={srcImageBox}
							onDelete={() => this.deleteImage(image)}
							key={image.id}
							onClick={this.props.onSelect}
							imgId={image.path}
							subtitle={newFileName}
						/>
					);
				}

				//si el archivo no es pdf y el tipo de archivo tampoco
				if (this.props.fileType === 'img') {
					srcImageBox = image.path;
					return (
						<ThumbnailBox
							className="normal"
							imageUrl={srcImageBox}
							onDelete={() => this.deleteImage(image)}
							key={image.id}
							onClick={this.props.onSelect}
							imgId={image.path}
						/>
					);
				}

				return null;
			})
			.filter((image) => !!image);

		if (renderImages.length === 0)
			if (Array.isArray(renderImagesLoading) && renderImagesLoading.length > 0) {
				renderImages = renderImagesLoading.concat(renderImages);
			}
		return renderImages;
	};

	loadMoreImages = (page) => {
		this.props.fetchMoreToImages(page);
	};

	hasMore = () => {
		if (this.props.images && this.props.images.images_v2) {
			return this.props.images.images_v2.length < this.props.imageNumber;
		}

		return false;
	};

	render() {
		//segun el prop fileType deberá mostrarse un tipo de documentos u otros

		const acceptFile = this.props.fileType === 'pdf' ? 'application/pdf' : 'image/*';

		return (
			<Dropzone onDrop={this.onDrop} disableClick={true} activeStyle={{backgroundColor: '#CEE3F6'}}>
				{({getRootProps}) => (
					<div className="add-image-thumbnails-box" {...getRootProps()}>
						<AddFile
							className="normal-gallery"
							handleUploadImage={this.handleUploadImage}
							fileType={this.props.fileType}
						/>
						<InfiniteScroll
							pageStart={1}
							loadMore={this.loadMoreImages}
							initialLoad={false}
							hasMore={this.hasMore()}
							loader={<Loading key={0} />}
							useWindow={false}
						>
							{this.getFiles()}
						</InfiniteScroll>
						<input
							style={{display: 'none'}}
							ref={(ref) => {
								this.uploadInput = ref;
							}}
							type={'file'}
							accept={acceptFile} //al pulsar añadir archivo se mostrará segun el prop fileType
							hidden={true}
							onChange={this.onUploadImage}
							multiple
						/>
					</div>
				)}
			</Dropzone>
		);
	}
}

const IMAGES_LIMIT = 20;

const withDataAndMutation = compose(
	graphql(IMAGES_QUERY, {
		name: 'images',
		options: (props) => {
			return {
				variables: {
					offset: 1,
					limit: IMAGES_LIMIT,
					width: get(props, 'filter.width'),
					height: get(props, 'filter.height'),
					type: get(props, 'fileType'),
				},
			};
		},
		props: (props) => {
			return {
				...props,
				fetchMoreToImages: (page) => {
					return props.images.fetchMore({
						variables: {
							offset: page,
						},
						updateQuery: (prev, {fetchMoreResult}) => {
							if (!fetchMoreResult.images_v2 || fetchMoreResult.images_v2.length === 0) {
								return prev;
							}
							let arrImageNew = [];
							for (var i in prev.images_v2) {
								var shared = false;
								for (var j in fetchMoreResult.images_v2)
									if (fetchMoreResult.images_v2[j].id === prev.images_v2[i].id) {
										shared = true;
										break;
									}
								if (!shared) arrImageNew.push(prev.images_v2[i]);
							}
							arrImageNew = arrImageNew.concat(fetchMoreResult.images_v2);
							return Object.assign(
								{},
								{
									images_v2: arrImageNew,
								},
							);
						},
					});
				},
			};
		},
	}),
	graphql(IMAGE_NUMBER_QUERY, {
		name: 'imageNumber',
		options: (props) => {
			return {
				variables: {
					width: get(props, 'filter.width'),
					height: get(props, 'filter.height'),
					type: get(props, 'fileType'),
				},
			};
		},
		props: ({imageNumber}) => ({
			loading: imageNumber.loading,
			imageNumber: imageNumber.imageNumber,
		}),
	}),
	graphql(IMAGE_ADD_MUTATION, {
		props({mutate}) {
			return {
				addNewImage({input}) {
					return mutate({
						variables: {input},
						updateQueries: {
							Images: (prev, {mutationResult}) => {
								const newImage = mutationResult.data.addImage_v2;
								return Object.assign(
									{},
									{
										images_v2: [newImage, ...prev.images_v2],
									},
								);
							},
						},
					});
				},
			};
		},
	}),
	graphql(IMAGE_DELETE_MUTATION, {
		props({mutate}) {
			return {
				deleteImage({idImage}) {
					return mutate({
						variables: {idImage},
						update: (store, {data: {deleteImage}}) => {
							const data = store.readQuery({
								query: IMAGES_QUERY,
								variables: {offset: 1, limit: IMAGES_LIMIT},
							});
							if (deleteImage.deleted) {
								data.images.some(function (item, index) {
									return data.images[index]['id'] === deleteImage.id
										? !!data.images.splice(index, 1)
										: false;
								});
							}
							store.writeQuery({
								query: IMAGES_QUERY,
								data,
								variables: {offset: 1, limit: IMAGES_LIMIT},
							});
						},
					});
				},
			};
		},
	}),
)(FilesList);

FilesList.propTypes = {
	fileType: PropTypes.oneOf(['pdf', 'img']),
};

FilesList.defaultProps = {
	fileType: 'img',
};

export default withDataAndMutation;
