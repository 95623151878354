import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {EDITION_BRICKS} from './editionBricks';

//Se encarga de renderizar ladrillos o su ventana de edición
class EditionBrickSwitch extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		//Si no tenemos ladrillo no mostramos nada
		if (!this.props.editionBrickStruct) {
			return <div />;
		}
		const EditionBrick = EDITION_BRICKS[this.props.editionBrickStruct.type];
		if (!EditionBrick) {
			return <div>Ladrillo de edición no definido en EditBrickSwitch</div>;
		}

		return <EditionBrick {...this.props} />;
	}
}

EditionBrickSwitch.propTypes = {
	editionBrickStruct: PropTypes.object,
	editionBrickData: PropTypes.object,
	path: PropTypes.array,
	pillId: PropTypes.string,
	parentType: PropTypes.string,
	parentId: PropTypes.string
};

EditionBrickSwitch.defaultProps = {
	path: []
};

export default EditionBrickSwitch;
