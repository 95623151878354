import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import userPerms from '@createandshare/userperms';

import SimpleTestIntro from 'businessLogic/core/shared/Test/SimpleTestIntro';
import Test from './components/Test';
import TestResult from 'businessLogic/core/shared/Test/TestResult';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Element} from 'react-scroll';
import get from 'lodash/get';

import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import InstructionTestBrick from 'businessLogic/core/shared/Test/InstructionTestBrick';
import CallActionTestBrickWithBoxes from 'businessLogic/core/shared/Test/CallActionTestBrickWithBoxes';
import ModalForTestBricks from 'businessLogic/core/shared/Test/ModalForTestBricks';

import './styles.scss';

class ClassifyGame extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Clasifica los elementos`;
	static brickIcon = 'test';
	static brickImage = brickImage;
	static canReload = true;
	static brickHelpText = () =>
		t`Juego de asociación que consiste en asignar conceptos a dos categorías diferentes.`;
	static brickDefaultConfig = () => ({
		pointSuccess: 5,
		pointFailure: 0,
		questionTimeInSeconds: 0,
		brickName: t`Clasifica los elementos`,
		brickDescription: t`Permite recoger preferencias del usuario a través de una clasificación de elementos. `,
		requiredActions: true,
	});
	static brickDefaultData = () => ({
		image: {
			imageSrc: '/uploads/default-images/ilustration_test.svg',
			credit: '',
		},
		textLinkInstructions: '',
		instructions: '',
		beforeHeading: _('Clasifica los elementos'),
		title: _('Clasifica los elementos'),
		descriptionTest: _(
			'Instrucciones de funcionamiento del test. Marca las casillas correctas y envía tu respuesta',
		),
		textButtonStart: _('EMPEZAR'),
		textResult: _('¡Buen trabajo!'),
		categories: [
			{
				category: _('CATEGORÍA A'),
				tags: [
					{
						tagName: 'Sociis ',
					},
					{
						tagName: 'malesuada ',
					},
					{
						tagName: 'amet consectetur',
					},
					{
						tagName: 'nulla',
					},
				],
			},
			{
				category: _('CATEGORÍA B'),
				tags: [
					{
						tagName: 'Sociis ',
					},
					{
						tagName: ' malesuada ',
					},
				],
			},
		],
	});
	constructor(props) {
		super(props);
		this.state = {
			type: 'ClassifyGame',
			completed: false,
			success: false,
			stateMachine: 'intro',
			successes: 0,
			failures: 0,
			remainingTime: 0,
			score: {
				min: 0,
				max: 0,
				raw: 0,
				scaled: 0,
			},
		};
		this.classifyGameTest = React.createRef();
		this.props.getBrickStateData('').then((brickStateData) => {
			if (brickStateData && brickStateData.state) {
				this.setState(brickStateData.state);
				//Parche para solventar los casos donde no se ha guardado el status del ladrillo
				if (brickStateData.state.stateMachine === 'result' && !brickStateData.status) {
					this.initStateTestResult();
				}
			}
		});
	}

	saveBrickState = () => {
		this.props.setBrickStateData('state', this.state);
	};

	handleScrollTest = () => {
		// scroller.scrollTo("brick--" + this.props.brickId, {
		// 	smooth: false,
		// 	containerId: "scroll-container",
		// 	offset: 0
		// });
	};
	getScore = () => {
		const valueFailure = get(this.props.brick.config, 'pointFailure', 0);
		const valueSuccess = get(this.props.brick.config, 'pointSuccess', 0);
		const questionTime = get(this.props.brick.config, 'questionTimeInSeconds', 0);
		const maxScore =
			this.getLabelsTags(this.props.brick.data.categories).length *
			(parseInt(valueSuccess, 10) + parseInt(questionTime, 10));

		const totalFailures = this.state.failures * parseInt(valueFailure, 10);
		const remainingTime = this.state.remainingTime;
		const userScore =
			parseFloat(valueSuccess) * parseFloat(this.state.successes) - totalFailures + remainingTime;
		return {
			min: 0,
			max: maxScore,
			raw: userScore,
			scaled: userScore / maxScore,
		};
	};

	//Crear la baraja con todos los labelsTags juntos
	getLabelsTags = (dataTagsCollection) => {
		let stack = [];
		dataTagsCollection.forEach((element) => {
			stack = stack.concat(element.tags);
		});
		return stack;
	};

	initStateTestQuestion = () => {
		this.setState({stateMachine: 'test'}, this.saveBrickState);
		this.handleScrollTest();
		//Lanzamos el evento de inicio de test
		this.props.brickEvent('start');
	};

	initStateTestResult = () => {
		const score = this.getScore();
		this.setState(
			{
				stateMachine: 'result',
				score,
				completed: true,
				success: true,
			},
			this.saveBrickState,
		);
		this.handleScrollTest();
		//Lanzamos el evento de fin de test
		this.props.brickEvent('end');

		//Marcamos el test como completado
		const status = {
			progress: 100,
			completed: true,
			success: true,
			score,
		};

		this.props.setBrickStateData('status', status).then(() => {
			this.props.configObject.callEventListeners('brickStatusChanged', {
				brick: 'ClassifyGame',
				type: 'evaluate',
				status,
			});
		});
	};
	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const tagsCollection = data.categories || [];
		const classes = classNames({
			'brick': true,
			'classify-game-brick': true,
		});
		const pointFailure = get(this.props.brick.config, 'pointFailure', 0);
		const pointSuccess = get(this.props.brick.config, 'pointSuccess', 0);
		const questionTime = get(this.props.brick.config, 'questionTimeInSeconds', 0) || 0;

		let step;
		switch (this.state.stateMachine) {
			default:
			case 'intro':
				step = (
					<CSSTransition key="intro" timeout={500} classNames="show">
						<div className="step stept--1">
							<CallActionTestBrickWithBoxes
								type="toStart"
								textButton={data.textButtonStart}
								beforeHeading={data.beforeHeading}
								image={data.image}
								onClick={this.initStateTestQuestion}
								set={this.props.set}
								className={classes}
								requireLogin={userPerms.gamesRequireLogin()}
							>
								<SimpleTestIntro
									textDescription={data.descriptionTest}
									valueSuccess={pointSuccess}
									valueFailure={pointFailure}
									onClick={this.initStateTestQuestion}
									set={this.props.set}
								/>
								<InstructionTestBrick
									textLabel={data.textLinkInstructions}
									textHelp={data.instructions}
									theme="dark"
								/>
							</CallActionTestBrickWithBoxes>
						</div>
					</CSSTransition>
				);
				break;
			case 'test':
				step = (
					<CSSTransition key="test" timeout={500} classNames="show">
						<div className="step stept--2">
							<ModalForTestBricks
								canClose={false}
								onClose={this.initStateTestResult}
								isEditMode={this.props.editMode}
							>
								<Test
									ref={this.classifyGameTest}
									tagsCollection={tagsCollection}
									set={this.props.set}
									initialQuestionTime={questionTime}
									valueSuccess={pointSuccess}
									onFinished={(successes, failures, remainingTime) => {
										this.setState(
											(state) => {
												return {
													successes: state.successes + successes,
													failures: state.failures + failures,
													remainingTime: state.remainingTime + remainingTime,
												};
											},

											() => this.initStateTestResult(),
										);
									}}
									onScroll={this.handleScrollTest}
								/>
							</ModalForTestBricks>
						</div>
					</CSSTransition>
				);
				break;
			case 'result':
				step = (
					<CSSTransition key="tresult" timeout={500} classNames="show">
						<div className="step stept--3">
							<TestResult
								score={this.state.score}
								successes={this.state.successes}
								failures={this.state.failures}
							/>
						</div>
					</CSSTransition>
				);
				break;
		}

		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<Element name={'brick--' + this.props.brickId}>
					<div className={classes}>
						{/* <div className="classify-game-brick__beforeHeading">
							<FrontEditableText
								fieldName="beforeHeading"
								text={data.beforeHeading}
								set={this.props.set}
							/>
						</div> */}

						<TransitionGroup className="todo-list">{step}</TransitionGroup>
					</div>
				</Element>
			</Container>
		);
	}
}

export default brickWrapper(ClassifyGame);
