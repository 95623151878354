import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import {imageUrl} from 'businessLogic/helpers/images';
import Icon from 'ui/shared/Icon';
// import Button from 'businessLogic/shared/Buttons/Button';
// import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class VideoAudioBoxRow extends Component {
	render() {
		const {
			videoTitle,
			videoAutor,
			videoDuration,
			// videoUrl,
			// thumbnailImg,
			onDelete,
			onClick,
			className,
			uploading,
		} = this.props;
		const classes = classNames({
			'video-box--delete': onDelete,
			[`${className}`]: true,
		});

		return (
			<div className={classes}>
				<div className="edit__video-box-row">
					<div className="video-list-video-item" onClick={onClick}>
						<div className="mini-video-col">
							<div className="mini-video-container">
								{/* thumbnailImg viene con 	thumbnailImg="/uploads/default-images/120x100.png" desde el padre */}
								{/* <img alt="" src={imageUrl(thumbnailImg)} /> */}
								<img alt="" src={'/uploads/default-images/120x100.png'} />
								{/* metadata: The author thinks that the browser should load only metadata when the page loads */}
								{/* <video>
									<source src={videoUrl} type="video/mp4" />
								</video> */}
								<div className="mini-play-button">
									<Icon name="play-mini" size="small" />
								</div>
							</div>
						</div>
						<div className="video-title-author">
							<span className="video-title">{videoTitle}</span>
							<br />
							<span className="video-author">{videoAutor}</span>
						</div>
						<div className="video-duration">{videoDuration}</div>
						{!uploading && (
							<div className="video-open-button-container">
								{/* <Button theme="primary" view="outline" rounded={true} onClick={onClick}>
									{t`Open`}
								</Button> */}
								<div className="video-box__delete-button" onClick={onDelete}>
									<Icon name="delete" size="small" />
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

VideoAudioBoxRow.propTypes = {
	className: PropTypes.string,
	videoTitle: PropTypes.string,
	videoUrl: PropTypes.string,
	videoAutor: PropTypes.string,
	videoDuration: PropTypes.string,
	onDelete: PropTypes.func,
	onClick: PropTypes.func,
	thumbnailImg: PropTypes.string,
	videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	uploading: PropTypes.bool,
};

VideoAudioBoxRow.defaultProps = {
	className: '',
	videoTitle: '',
	videoUrl: '',
	videoAutor: '',
	videoDuration: '',
	onDelete: null,
	thumbnailImg: '',
	uploading: false,
};
export default VideoAudioBoxRow;
