import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Configuración`,
				fieldName: 'data',
				content: [
					{
						type: 'Switch',
						fieldName: 'autoStop',
						title: t`Para el scroll automáticamente y sustituye el botón de stop por un icono de scroll`,
					},
				],
			},
		],
	},
];
export default editionFields;
