import React, {PureComponent} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Question from './components/Question';
import NavigationTestBrick from 'businessLogic/core/shared/Test/NavigationTestBrick';
import BeforeHeadingTestBrick from 'businessLogic/core/shared/Test/BeforeHeadingTestBrick';
import ScrollBarTestBrick from 'businessLogic/core/shared/Test/ScrollBarTestBrick';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import Swiper from 'react-id-swiper';
import get from 'lodash/get';
import './styles.scss';

class Test extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			isTyping: false,
			revision: 0,
		};
		this.responses = {};
		this.swiper = null;
		this.testRef = React.createRef();

		this.questionRefs = this.props.collectionflexibleContent.map(() => React.createRef());
	}

	goNext = () => {
		if (this.swiper && this.canContinue()) this.swiper.slideNext();
	};

	goPrev = () => {
		if (this.swiper) this.swiper.slidePrev();
	};

	incrementRevision = () => this.setState((state) => ({revision: state.revision + 1}));

	canContinue = () => {
		const currentQuestion = get(this.questionRefs, [this.state.activeIndex, 'current']);
		if (currentQuestion && currentQuestion.canContinue()) {
			return true;
		}
		return false;
	};

	//valor en porcentaje del progreso
	getProgress = () => {
		if (this.swiper) {
			const totalQuestion = this.props.collectionflexibleContent.length;
			const currentQuestion = this.state.activeIndex + 1;
			const progressValue = (currentQuestion / totalQuestion) * 100;
			return parseInt(progressValue, 10);
		}
	};
	changeActiveSlideContent = () => {
		if (this.swiper) {
			this.setState({
				activeIndex: this.swiper.activeIndex,
			});
		}
	};

	isFinish = () => {
		if (this.props.collectionflexibleContent.length === this.state.activeIndex + 1) return true;
	};
	handleFinishTest = () => {
		if (this.canContinue()) {
			this.props.onFinishTest(this.responses);
		}
	};
	handleTyping = (event) => {
		if (event) {
			const target = event.target;
			/**
			 * TODO: comprobar que viene type number y quitar el Number()
			 */
			if (Number(event.keyCode) === 13) {
				target.blur();
			}
			const isFocus = target === document.activeElement;
			this.setState({
				isTyping: isFocus,
			});
		}
	};

	render() {
		/**todas las preguntas */
		const mainStyleClass = 'survey-test';

		//Parámetros configuración swiper
		//https://www.npmjs.com/package/react-id-swiper
		const params = {
			shouldSwiperUpdate: true, //si no no se vuelve a renderizar al añadir imágenes al carrusel
			rebuildOnUpdate: false, //se recontruye si se cambian parámetros (valueDelay) o se añaden elementos al carrusel
			direction: 'vertical',
			initialSlide: 0,
			spaceBetween: 0,
			speed: 1500,
			slidesPerView: 1,
			loop: false,
			noSwiping: true,
			keyboard: this.canContinue() ? true : false,

			coverflowEffect: {
				rotate: 50,
				stretch: 0,
				depth: 100,
				modifier: 1,
				slideShadows: true,
			},
			navigation: {
				nextEl: mainStyleClass + '__trigger--arrow__next',
				prevEl: mainStyleClass + '__trigger--arrow__prev',
			},
			getSwiper: (swiper) => {
				this.swiper = swiper;

				this.changeActiveSlideContent();
				this.swiper.on('slideChange', () => {
					this.changeActiveSlideContent();
				});
			},
		};

		return (
			<WindowSizeContext.Consumer>
				{({device}) => {
					const classes = classNames({
						[`${mainStyleClass}`]: true,
						[`${mainStyleClass + '--typing'}`]:
							this.state.isTyping && device !== 'desktop' && device !== 'tabletLandscape',
					});
					return (
						<div className={classes} ref={this.testRef}>
							<header className={mainStyleClass + '__header'}>
								<BeforeHeadingTestBrick text={this.props.beforeHeading} set={this.props.set} />
							</header>
							<Swiper {...params}>
								{this.props.collectionflexibleContent.map((itemContent, indexContent) => {
									return (
										<Question
											key={indexContent}
											ref={get(this.questionRefs, [indexContent])}
											flexibleContent={itemContent}
											onFinishQuestion={(data) => {
												this.responses = Object.assign(this.responses, data);
												this.incrementRevision();
											}}
											set={this.props.set}
											index={indexContent}
											activeIndex={this.state.activeIndex}
											onTyping={this.handleTyping}
										/>
									);
								})}
							</Swiper>

							<NavigationTestBrick
								isFirstQuestion={this.state.activeIndex === 0 ? true : false}
								isLastQuestion={this.isFinish() ? true : false}
								canContinue={this.canContinue() ? true : false}
								onGoPrev={this.goPrev}
								onGoNext={this.goNext}
								onGoFinish={this.handleFinishTest}
								disable={false} // hacer desaparecer con los eventos del keyboard en mobile
							/>

							<ScrollBarTestBrick percentValue={this.getProgress()} />
							{/* {!this.canContinue() && <div className="alerta">Esta pregunta el requerida, necesitas contestarla para poder continuar</div>} */}
						</div>
					);
				}}
			</WindowSizeContext.Consumer>
		);
	}
}

Test.propTypes = {
	beforeHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	collectionflexibleContent: PropTypes.array,
	onFishedTest: PropTypes.func,
	set: PropTypes.func,
};
export default Test;
