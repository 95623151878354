import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'TextAreaGoogleMap',
						fieldName: 'videoSrc',
						title: t`Mapa de Google Maps`,
						placeholder: t`Mapa de Google Maps`,
						instructions: t`Pega aquí el mapa embebido de Google Maps`,
						rows: 2,
					},
					{
						type: 'Text',
						fieldName: 'videoFooter',
						title: t`Pie de mapa`,
						placeholder: t`Inserta un pie de mapa`,
						defaultValue: t`Inserta un pie de mapa`,
					},
				],
			},
		],
	},
];
export default editionFields;
