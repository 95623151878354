import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AddButton from 'businessLogic/core/shared/Buttons/AddButton';
import './styles.scss';

class AddBrickButton extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}
	openBrickTypeSelector = () => {
		this.props.addBrick();
	};
	render() {
		const {className} = this.props;
		const classes = classNames({
			'add-brick-button': true,
			[`${className}`]: true
		});
		return (
			<div className={classes}>
				<AddButton onClick={this.openBrickTypeSelector} />
			</div>
		);
	}
}

AddBrickButton.propTypes = {
	className: PropTypes.string,
	addBrick: PropTypes.func.isRequired
};

AddBrickButton.defaultProps = {
	className: ''
};

export default AddBrickButton;
