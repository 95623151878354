import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import EventHandlerCache from 'businessLogic/helpers/EventHandlerCache';
import {getPlainTextFromTextEditorObject} from 'businessLogic/helpers/functions';
import get from 'lodash/get';
import './styles.scss';

class TagSelect extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		let defaultValue = '';
		if (editionBrickStruct.defaultValue) {
			defaultValue = editionBrickStruct.defaultValue;
		}

		return defaultValue;
	};

	constructor(props) {
		super(props);
		this.eventHandlerCache = new EventHandlerCache();
	}

	handleTagSelect = (tag) => {
		this.props.setMainField(tag);
	};
	render() {
		const tagRepeater = get(
			this.props.editionBrickData,
			this.props.editionBrickStruct.repeaterPath
		);

		let tagList = tagRepeater.map((tag, index) => {
			const tagFieldName = getPlainTextFromTextEditorObject(
				get(tag, this.props.editionBrickStruct.tagFieldName)
			);
			return (
				<li
					key={index}
					onClick={this.eventHandlerCache.getEventHandler('li_onClick', tagFieldName, () =>
						this.handleTagSelect(tagFieldName)
					)}
					className={
						'edition-brick-tag-select__ul__tag tag--' +
						(index + 1) +
						(this.props.getMainField() === tagFieldName ? ' active ' : '')
					}
				>
					{tagFieldName}
				</li>
			);
		});
		return (
			<div className="edition-brick-tag-select">
				<label className="edition-brick-tag-select__label">
					{this.props.editionBrickStruct.title}
				</label>
				<ul className="edition-brick-tag-select__ul">{tagList}</ul>
			</div>
		);
	}
}

export default editionBrickWrapper(TagSelect);
