import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import {imageUrl} from 'businessLogic/helpers/images';
import './styles.scss';

const modalRoot = document.getElementById('portals');

class MaximizedImage extends PureComponent {
	constructor(props) {
		super(props);
		this.containerMaximized = document.createElement('div');
		this.isDivMounted = false;
	}

	componentDidMount() {
		// if (modalRoot) modalRoot.appendChild(this.containerMaximized);
	}
	componentWillUnmount() {
		if (modalRoot && this.isDivMounted && modalRoot.contains(this.containerMaximized)) {
			modalRoot.removeChild(this.containerMaximized);
		}
	}

	mountDiv = () => {
		if (modalRoot && !this.isDivMounted) {
			modalRoot.appendChild(this.containerMaximized);
			this.isDivMounted = true;
		}
	};

	render() {
		const {open, onClose, imgObj} = this.props;

		const openClass = classNames({
			'maximized-open': open,
		});

		if (!open) {
			return null;
		}
		this.mountDiv();
		return ReactDOM.createPortal(
			<div className={'maximized-image ' + openClass} onClick={onClose}>
				<div className="maximized-elements">
					<span className="close" onClick={onClose}>
						<Icon name="close-modal" size="normal" />
					</span>
					<div
						className="the-img"
						style={{
							// eslint-disable-next-line quotes
							backgroundImage: "url('" + imageUrl(imgObj.imageSrc) + "')",
						}}
					>
						{/* <img src={image} alt={image} /> */}
						<div className="maximized-image-caption">
							<div className="caption-footer"> {imgObj.footer} </div>
							<div className="caption-credit"> {imgObj.credit} </div>
						</div>
					</div>
				</div>
			</div>,
			this.containerMaximized,
		);
	}
}

MaximizedImage.propTypes = {
	className: PropTypes.string,
	image: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
	clickable: PropTypes.bool,
	footer: PropTypes.string,
	credit: PropTypes.string,
};

MaximizedImage.defaultProps = {
	className: '',
	image: '',
	title: '',
	clickable: true,
	footer: '',
	credit: '',
};

export default MaximizedImage;
