import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import Instructions from 'businessLogic/core/shared/Edit/Instructions';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css'; //importante los estilos de la librería para la tabla, cursor de añadir, celda seleccionada etc
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
// import get from 'lodash/get';
import set from 'lodash/set';
import Icon from 'ui/shared/Icon';
import './styles.scss';

class DataSheetPie extends PureComponent {
	onChange = (changes, additions) => {
		//si no estoy introduciendo valor por error en las celdas de añadir o eliminar
		//o en las celdas grises de las esquinas
		if (
			changes[0].cell.value !== '+' &&
			changes[0].cell.value !== '-' &&
			changes[0].cell.value !== ''
		) {
			const data = this.getData();

			//almacenamos numero de  columnas actuales
			const actualCols = data.length;
			//variables para almacenar numero y columnas que se han pegado
			let newCols = 0;
			//variables para almacenar número de columnas que se van a insertar
			let colsToInsert = 0;

			//vamos a unir los changes con las additions
			let totalChanges = [].concat(changes);

			//en additions tenemos todos los datos que no han cabido al pegar
			if (typeof additions !== 'undefined') {
				totalChanges = totalChanges.concat(additions);
				newCols = additions[additions.length - 1].col + 1;

				//si additions no existe, mirammos en changes si hay que añadir una columna o no.
			} else {
				newCols = changes[changes.length - 1].col + 1;
			}

			//calculamos las columnas que habría que insertar
			colsToInsert = newCols - actualCols;

			//insertarmos las filas y las columnas necesarias antes de aplicar los cambios
			if (colsToInsert > 0) {
				for (let i = 0; i < colsToInsert; i++) {
					this.createNewCol();
				}
			}

			//tenemos que obtener de nuevo el data para ver si ha habido nuevas filas o columnas
			const newData = this.getData();

			totalChanges.forEach(({row, col, value}) => {
				if (row === 0) {
					//estoy en la primera fila cambiando los títulos de columna
					newData[col].name = value;
				} else if (row === 1) {
					//estoy en la segunda fila, cambiando los valores numéricos
					let valueStr = value.replace(',', '.');
					newData[col].value = parseFloat(valueStr);
				}
			});

			this.props.setMainField(newData);
		}
	};

	createNewCol = () => {
		const dataSheetPie = [].concat(this.props.editionBrickData.data.dataSheetPie);

		const alphabet = ' ABCDEFGHIJKLMNOPQRSTUVWXYZ';

		dataSheetPie.push({
			name: t`Columna ` + alphabet[(dataSheetPie.length + 1) % alphabet.length],
			value: 0,
		});
		this.props.setMainField(dataSheetPie);
	};

	deleteCol = () => {
		const selectedCol = this.state.selected.start.j;
		const dataSheetPie = [].concat(this.props.editionBrickData.data.dataSheetPie);

		//eliminamos la  columna si no es la primera
		if (selectedCol === 0 && dataSheetPie.length === 1) {
			alert(t`no se puede eliminar`);
		} else {
			dataSheetPie.splice(selectedCol, 1);
		}

		this.props.setMainField(dataSheetPie);
	};

	getData = () => {
		return this.props.getMainField();
	};

	createGrid = (data) => {
		const grid = [];

		//**Generar cuerpo de la tabla - DATA CONTIENE TODAS LAS COLUMNAS

		for (let i = 0; i < data.length; i++) {
			const columna = data[i];

			//** Creamos los títulos de columna
			// utilizando generateThCol estaba dando problemas a la hora de selecciar las celdas con los cursores en el datasheet
			set(grid, [0, i, 'value'], columna.name);

			//solo hay una fila de valores numéricos
			//** Creamos en la posición correcta de la tabla el valor numérico
			set(grid, [1, i, 'value'], columna.value);

			//si estamos ya en la última columna
			//** Creamos la celda correspondiente a la COLUMNA NUEVA VACÍA
			if (i === data.length - 1) {
				set(grid, [1, i + 1, 'value'], '');
				set(grid, [1, i + 1, 'readOnly'], false);
				set(grid, [1, i + 1, 'className'], 'right-corner');
			}

			//** creamos la celda correspondiente a la fila con las opciones de eliminar columna
			//estarán en la fila 2 debajo de los valores
			set(grid, [2, i, 'value'], '-');
			set(grid, [2, i, 'readOnly'], false);
			set(grid, [2, i, 'className'], 'delete-col');
			set(
				grid,
				[2, i, 'component'],
				<div onClick={this.deleteCol} title={t`Eliminar columna`}>
					<Icon name="delete" size="small" />
				</div>,
			);
			set(grid, [2, i, 'forceComponent'], true);

			//** crear la celda nueva de la esquina inferior derecha
			set(grid, [2, i + 1, 'value'], '');
			set(grid, [2, i + 1, 'readOnly'], false);
			set(grid, [2, i + 1, 'className'], 'right-corner');
		}

		//**Añadimos el título de columna NUEVA COLUMNA
		// utilizando generateThCol estaba dando problemas a la hora de selecciar las celdas con los cursores en el datasheet
		set(grid, [0, data.length, 'value'], '+');
		set(grid, [0, data.length, 'readOnly'], false);
		set(grid, [0, data.length, 'className'], 'new-col');
		set(
			grid,
			[0, data.length, 'component'],
			<div onClick={this.createNewCol} title={t`Nueva columna`}>
				<Icon name="add-row" size="small" />
			</div>,
		);
		set(grid, [0, data.length, 'forceComponent'], true);

		return grid;
	};

	constructor(props) {
		super(props);

		this.state = {
			// selected: {
			// 	start: {i: 0, j: 0},
			// 	end: {i: 0, j: 0},
			// },
			selected: null,
		};
	}

	onSelect = (selected) => {
		const selectedCol = selected.start.j;
		const selectedRow = selected.start.i;
		this.setState({
			selected: {
				start: {i: selectedRow, j: selectedCol},
				end: {i: selectedRow, j: selectedCol},
			},
		});
	};

	render() {
		const dataTable = this.getData();

		return (
			<div className="edition-brick-data-sheet">
				<label className="edition-brick-textEditor__label">
					{this.props.editionBrickStruct.title}
				</label>
				{this.props.editionBrickStruct.instructions !== undefined && (
					<Instructions text={this.props.editionBrickStruct.instructions} />
				)}

				<div className="sheet-container">
					<ReactDataSheet
						data={this.createGrid(dataTable)}
						valueRenderer={(cell) => cell.value}
						onContextMenu={(e, cell) => (cell.readOnly ? e.preventDefault() : null)}
						onCellsChanged={this.onChange}
						onSelect={this.onSelect}
						// selected={null}
						// selected={this.state.selected}
					/>
				</div>
				<div className="sheet-options"></div>
			</div>
		);
	}
}

export default editionBrickWrapper(DataSheetPie);
