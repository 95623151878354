import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const LabelForInput = (props) => {
	const {text, className} = props;
	const classes = classNames({
		[`${className}`]: true
	});
	return (
		<div className={classes}>
			<label className="label-for-input"> {text} </label>
		</div>
	);
};

const {string} = PropTypes;

LabelForInput.propTypes = {
	text: string.isRequired,
	className: string
};

LabelForInput.defaultProps = {
	text: ''
};

export default LabelForInput;
