import React, {PureComponent} from 'react';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class ClosedNotice extends PureComponent {
	render() {
		const mainStyleClass = 'closed-notice';
		const classes = classNames({
			[`${mainStyleClass}`]: true
		});
		return (
			<div className={classes}>
				<div className={mainStyleClass + '__icon'}>
					<Icon name="hidden" size="normal" />
				</div>
				<p className={mainStyleClass + '__label'}>{t`Encuesta Cerrada:`}</p>
				<p className={mainStyleClass + '__title'}>{this.props.text}</p>
				<span>{t`(Texto solo visible en modo en el modo de Edición)`}</span>
			</div>
		);
	}
}
export default ClosedNotice;
