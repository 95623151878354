import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Icon from 'ui/shared/Icon';
import classNames from 'classnames';
import AnswerAnimatedIcon from 'ui/bricks/Icons/AnswerAnimatedIcon';
import './styles.scss';

class Response extends Component {
	renderCheck = () => {
		/*fallo a la primera . He terminado porque he fallado y no he hecho click sobre la soluciones verdaderas */
		if (this.props.feedbackCorrect !== undefined) {
			return (
				<AnswerAnimatedIcon answer={this.props.valueSolution === true ? 'success' : 'failure'} />
			);
		} else {
			if (this.props.disable && this.props.valueSolution) {
				return <Icon name="success" />;
			}
		}
	};
	render() {
		const classes = classNames({
			'response-test-basic-component': true,
			'response-test-basic-component--failure': this.props.feedbackCorrect === false,
			'response-test-basic-component--success':
				this.props.feedbackCorrect === true ||
				(this.props.feedbackCorrect === undefined &&
					this.props.disable &&
					this.props.valueSolution),
			'response-test-basic-component--disable':
				this.props.disable && this.props.feedbackCorrect === undefined
		});
		return (
			<div className={classes}>
				<div className="response-test-basic-component__trigger" onClick={this.props.onClick}>
					<div className="response-test-basic-component__icon">
						<div className="response-test-basic-component__icon__circle">{this.renderCheck()}</div>
					</div>

					<div className="response-test-basic-component__text ">
						<FrontEditableText text={this.props.text} editable={false} />
					</div>
				</div>
			</div>
		);
	}
}
/*feedbackCorrect: tiene un valor inicial de undefined , por eso no recibe ningún defaultProps */

Response.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	comment: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onClick: PropTypes.func,
	disable: PropTypes.bool,
	feedbackCorrect: PropTypes.bool,
	valueSolution: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

Response.defaultProps = {
	disable: false
};

export default Response;
