import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import AccordionItem from './components/AccordionItem';
import './styles.scss';

class Accordions extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeAccordionIndex: -1
		};
	}
	changeActiveAccordion = (accordionIndex) => {
		if (this.state.activeAccordionIndex !== accordionIndex) {
			this.setState({activeAccordionIndex: accordionIndex});
		} else {
			this.setState({activeAccordionIndex: -1});
		}
	};

	render() {
		//Nos quedamos con los hijos de tipo Accordion
		const accordionList = this.props.editionBrickStruct.items;

		const accordionElements = accordionList.map((accordion, index) => (
			<AccordionItem
				key={index}
				index={index}
				active={this.state.activeAccordionIndex === index ? true : false}
				title={accordion.blockTitle}
				accordionProps={accordion}
				parentProps={this.props}
				onHandle={this.changeActiveAccordion}
			/>
		));

		return (
			<div className="edition-brick-accordions">
				<ul className="edition-brick-accordions__list">{accordionElements}</ul>
			</div>
		);
	}
}

export default editionBrickWrapper(Accordions);
