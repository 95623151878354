import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

const modalRoot = document.getElementById('portals');

class Modal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeDrags: 0,
			deltaPosition: {
				x: 0,
				y: 0,
			},
		};

		this.containerEl = document.createElement('div');
	}

	componentDidMount() {
		this.compareHeight();
		if (modalRoot) modalRoot.appendChild(this.containerEl);
	}
	componentWillUnmount() {
		if (modalRoot && modalRoot.contains(this.containerEl)) modalRoot.removeChild(this.containerEl);
	}
	componentDidUpdate() {
		this.compareHeight();
	}

	//Funciones para el draggable1
	handleDrag = (e, ui) => {
		const {x, y} = this.state.deltaPosition;
		this.setState({
			deltaPosition: {
				x: x + ui.deltaX,
				y: y + ui.deltaY,
			},
		});
	};
	onStart = () => {
		this.setState({activeDrags: this.state.activeDrags + 1});
	};
	onStop = () => {
		this.setState({activeDrags: this.state.activeDrags - 1});
	};
	compareHeight = () => {
		let bodyHeight = document.body.clientHeight;
		let modalHeight = this.refs.modal.querySelector('.modal__content').offsetHeight;
		if (modalHeight >= bodyHeight) {
			this.refs.modal.classList.add('modal--top-align');
		}
	};

	render() {
		const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
		const {visible, onCancel, children, mask, noDraggable, zIndex} = this.props;
		const modalClasses = classNames({
			'modal': true,
			'modal--visible': visible,
			'modal--invisible': !visible,
			'modal--draggable': !noDraggable,
		});
		const maskClasses = classNames({
			'modal__mask': true,
			'modal__mask--visible': mask,
			'modal__mask--invisible': !mask,
		});
		const zIndexCalc = zIndex ? zIndex * 10 : 1;
		return ReactDOM.createPortal(
			<div ref="modal" className={modalClasses} style={{zIndex: zIndexCalc}}>
				<div className={maskClasses} onClick={onCancel} />
				<Draggable
					enableUserSelectHack={false}
					disabled={noDraggable}
					onDrag={this.handleDrag}
					handle={this.props.handleSelector}
					{...dragHandlers}
				>
					{children}
				</Draggable>
			</div>,
			this.containerEl,
		);
	}
}

Modal.propTypes = {
	className: PropTypes.string,
	size: PropTypes.oneOf(['small', 'normal', 'large']),
	title: PropTypes.string,
	visible: PropTypes.bool.isRequired,
	onCancel: PropTypes.func,
	mask: PropTypes.bool,
	noDraggable: PropTypes.bool,
	handleSelector: PropTypes.string,
	zIndex: PropTypes.number,
};

Modal.defaultProps = {
	visible: false,
	size: 'small',
	mask: false,
	zIndex: 1,
};

export default Modal;
