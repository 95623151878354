import CourseStateManager from './CourseStateManager';
import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';
import LZString from 'lz-string';
import intervalToDuration from 'date-fns/intervalToDuration';

class ScormCourseStateManager extends CourseStateManager {
	constructor() {
		super();
		this.scormApi = null;
		this.stateData = null;
		this.scormVersion = null;
	}

	wait = (ms) => new Promise((r) => setTimeout(r, ms));

	async initCourseSession(course) {
		// La versión de scorm viene en el campo slug del curso
		this.scormVersion = course.slug;
		this.setCourse(course);

		if (this.scormVersion === 'Scorm 1.2') {
			this.scormApi = new ScormApi12();
		} else {
			this.scormApi = new ScormApi();
		}
		this.scormApi.initialize();

		//Siempre suspendemos
		this.scormApi.setValue('cmi.exit', 'suspend');

		//Obtenemos los datos de píldoras y ladrillos
		this.stateData = this.getSuspendedData();

		// console.log('Initial courseStatus', this.getCourseCompletionStatus());

		//Si el estado del curso es "not attempted", lo cambiamos a "incomplete"
		//https://scorm.com/blog/the-top-5-things-every-piece-of-scorm-content-should-do-at-runtime/
		const courseStatus = this.getCourseCompletionStatus();
		if (courseStatus !== 'incomplete' && courseStatus !== 'complete') {
			this.setCourseCompletionStatus('incomplete');
		}
	}

	async endCourseSession() {
		this.scormApi.setValue('cmi.exit', this.scormVersion === 'Scorm 1.2' ? '' : 'normal');
		this.scormApi.terminate();
		this.setCourse(null);
	}

	async updateCache() {}

	/*
	completionStatus accepted values:
		- completed
		- incomplete
		- not attempted
		- unknown
	*/
	async setCourseCompletionStatus(completionStatus) {
		// console.log('setCourseCompletionStatus', completionStatus);
		if (this.scormVersion === 'Scorm 1.2') {
			if (completionStatus === 'incomplete') {
				this.scormApi.setValue('cmi.success_status', completionStatus);
			}
		}
		this.scormApi.setValue('cmi.completion_status', completionStatus);
	}

	async getCourseCompletionStatus() {
		if (this.scormVersion === 'Scorm 1.2') {
			const successStatus = this.scormApi.getValue('cmi.success_status');
			// console.log('getCourseCompletionStatus', successStatus);
			if (successStatus === 'completed' || successStatus === 'passed') {
				return 'completed';
			}
			if (successStatus === 'failed' || successStatus === 'incomplete') {
				return 'incomplete';
			}
			// // Aunque devolvemos 'not attempted', seteamos incomplete para la siguiente consulta
			// this.setCourseCompletionStatus('incomplete');
			return 'not attempted';
		}
		return this.scormApi.getValue('cmi.completion_status');
	}

	/*
	successStatus accepted values:
		- passed
		- failed
		- unknown
	*/
	async setCourseSuccessStatus(successStatus) {
		// console.log('setCourseSuccessStatus', successStatus);
		this.scormApi.setValue('cmi.success_status', successStatus);
	}

	async getCourseSuccessStatus() {
		if (this.scormVersion === 'Scorm 1.2') {
			const successStatus = this.scormApi.getValue('cmi.success_status');
			// console.log('getCourseSuccessStatus', successStatus);
			return successStatus === 'passed' ? 'passed' : 'unknown';
		}
		return this.scormApi.getValue('cmi.success_status');
	}

	async setCourseCurrentLocation(location) {
		// console.log('setCourseCurrentLocation', location);
		this.scormApi.setValue('cmi.location', LZString.compressToBase64(JSON.stringify(location)));
	}

	async getCourseCurrentLocation() {
		const location = JSON.parse(
			LZString.decompressFromBase64(this.scormApi.getValue('cmi.location')),
		);
		// console.log('getCourseCurrentLocation', location);
		return location;
	}

	async setCourseProgress(progress) {
		if (this.scormVersion === 'Scorm 1.2') {
			await this.setPillData('currentCourse', 'progress', progress);
		} else {
			this.scormApi.setValue('cmi.progress_measure', progress / 100);
		}

		// console.log('setCourseProgress', progress);

		//Si el progreso es 100, marcamos el curso como completado y superado
		if (progress === 100) {
			await this.setCourseSuccessStatus('passed');
			await this.setCourseCompletionStatus('completed');
		}
	}

	async getCourseProgress() {
		let progress;
		if (this.scormVersion === 'Scorm 1.2') {
			progress = await this.getPillData('currentCourse', 'progress', 0);
		} else {
			progress = this.scormApi.getValue('cmi.progress_measure') * 100;
		}
		// console.log('getCourseProgress', progress);
		return progress;
	}

	async setCourseScore(score) {
		const {scaled, raw, min, max} = score;

		if (this.scormVersion === 'Scorm 1.2') {
			if (max && get(this.course, 'maxScore', 0) < max) {
				set(this.course, 'maxScore', max);
			}
			if (raw) {
				const courseMax = get(this.course, 'maxScore', 100);
				this.scormApi.setValue('cmi.score.raw', (raw / courseMax) * 100);
				this.scormApi.setValue('cmi.score.min', 0);
				this.scormApi.setValue('cmi.score.max', 100);
			}
		} else {
			if (scaled) {
				this.scormApi.setValue('cmi.score.scaled', scaled);
			}

			if (raw) {
				this.scormApi.setValue('cmi.score.raw', raw);
			}

			if (min) {
				this.scormApi.setValue('cmi.score.min', min);
			}

			if (max) {
				this.scormApi.setValue('cmi.score.max', max);
			}
		}
	}

	async getCourseScore() {
		const scoreObj = {
			scaled: this.scormApi.getValue('cmi.score.scaled'),
			raw: this.scormApi.getValue('cmi.score.raw'),
			min: this.scormApi.getValue('cmi.score.min'),
			max: this.scormApi.getValue('cmi.score.max'),
		};
		if (this.scormVersion === 'Scorm 1.2') {
			const courseMax = get(this.course, 'maxScore', 100);
			return {
				scaled: scoreObj.raw / 100,
				raw: (courseMax * scoreObj.raw) / 100,
				min: scoreObj.min,
				max: courseMax,
			};
		}
		return scoreObj;
	}

	async finishCourse() {
		this.scormApi.terminate();
	}

	getSuspendedData() {
		const serializedSuspendedData = this.scormApi.getValue('cmi.suspend_data');

		if (serializedSuspendedData) {
			return this.scormApi.deserializeData(serializedSuspendedData);
		}
		return {};
	}

	saveStateData() {
		this.scormApi.setValue('cmi.suspend_data', this.scormApi.serializeData(this.stateData));
	}

	async setPillData(pillId, path, value) {
		const data = {};
		set(data, path, value);

		const newStateData = {data};

		this.stateData[pillId] = merge(this.stateData[pillId] || {}, newStateData);

		//Publicamos el cambio
		this.publishToPillData(pillId, get(this.stateData, [pillId, 'data'], {}));

		//Actualizamos la información en el LMS de Scorm
		this.saveStateData();
	}

	async getPillData(pillId, path, defaultData) {
		const pillData = get(this.stateData, [pillId, 'data'], {});
		return get(pillData, path, defaultData);
	}

	async getUpdatedPillData(pillId, path, defaultData) {
		const pillData = get(this.stateData, [pillId, 'data'], {});
		return get(pillData, path, defaultData);
	}

	async setBrickData(pillId, brickId, path, value) {
		const data = {};
		set(data, path, value);

		const newStateData = {
			bricks: {
				[brickId]: data,
			},
		};

		this.stateData[pillId] = merge(this.stateData[pillId], newStateData);

		//Publicamos el cambio
		this.publishToBrickData(pillId, brickId, get(this.stateData, [pillId, 'bricks', brickId], {}));

		//Actualizamos la información en el LMS de Scorm
		this.saveStateData();
	}

	async getBrickData(pillId, brickId, path, defaultData) {
		const pillData = get(this.stateData, [pillId, 'bricks', brickId], {});
		return get(pillData, path, defaultData);
	}
}

class ScormApi {
	constructor() {
		this.nFindAPITries = 0;
		this.API = null;
		this.MAX_PARENTS_TO_SEARCH = 500;

		//Constants
		this.SCORM_TRUE = 'true';
		this.SCORM_FALSE = 'false';
		this.SCORM_NO_ERROR = '0';

		//Since the Unload handler will be called twice, from both the onunload
		//and onbeforeunload events, ensure that we only call Terminate once.
		this.terminateCalled = false;

		//Track whether or not we successfully initialized.
		this.initialized = false;
	}

	scanForAPI(win) {
		/*
			Establish an outrageously high maximum number of
			parent windows that we are will to search as a
			safe guard against an infinite loop. This is
			probably not strictly necessary, but different
			browsers can do funny things with undefined objects.
      */
		let nParentsSearched = 0;

		/*
	 Search each parent window until we either:
			-find the API,
			-encounter a window with no parent (parent is null
					   or the same as the current window)
			-or, have reached our maximum nesting threshold
	 */

		while (
			win.API_1484_11 == null &&
			win.parent !== null &&
			win.parent !== win &&
			nParentsSearched <= this.MAX_PARENTS_TO_SEARCH
		) {
			nParentsSearched++;

			win = win.parent;
		}

		/*
	 If the API doesn't exist in the window we stopped looping on,
	 then this will return null.
	 */

		return win.API_1484_11 || null;
	}

	getAPI() {
		let API = null;

		//Search all the parents of the current window if there are any
		if (window.parent !== null && window.parent !== window) {
			API = this.scanForAPI(window.parent);
		}

		/*
		If we didn't find the API in this window's chain of parents,
		then search all the parents of the opener window if there is one
		*/
		if (API === null && window.top.opener !== null) {
			API = this.scanForAPI(window.top.opener);
		}

		if (API === null && window.opener !== null) {
			API = this.scanForAPI(window.opener);
		}

		return API;
	}

	initialize() {
		this.API = this.getAPI();

		if (this.API == null) {
			alert(
				'ERROR - Could not establish a connection with the LMS.\n\nYour results may not be recorded.',
			);
			return;
		}

		const result = this.API.Initialize('');

		if (result === this.SCORM_FALSE) {
			const errorNumber = this.API.GetLastError();
			const errorString = this.API.GetErrorString(errorNumber);
			const diagnostic = this.API.GetDiagnostic(errorNumber);

			const errorDescription =
				'Number: ' + errorNumber + '\nDescription: ' + errorString + '\nDiagnostic: ' + diagnostic;

			alert(
				'Error - Could not initialize communication with the LMS.\n\nYour results may not be recorded.\n\n' +
					errorDescription,
			);
			return;
		}

		this.initialized = true;

		this.startTime = Date.now();
	}

	terminate() {
		//Don't terminate if we haven't initialized or if we've already terminated
		if (this.initialized === false || this.terminateCalled === true) {
			return;
		}

		this.setSessionTime();
		const result = this.API.Terminate('');

		this.terminateCalled = true;

		if (result === this.SCORM_FALSE) {
			var errorNumber = this.API.GetLastError();
			var errorString = this.API.GetErrorString(errorNumber);
			var diagnostic = this.API.GetDiagnostic(errorNumber);

			var errorDescription =
				'Number: ' + errorNumber + '\nDescription: ' + errorString + '\nDiagnostic: ' + diagnostic;

			alert(
				'Error - Could not terminate communication with the LMS.\n\nYour results may not be recorded.\n\n' +
					errorDescription,
			);
			return;
		}
	}

	setSessionTime() {
		const sessionTime = Math.round((Date.now() - this.startTime) / 1000);
		this.setValue('cmi.session_time', sessionTime.toString());
	}

	getValue(element, checkError) {
		if (this.initialized === false || this.terminateCalled === true) {
			return '';
		}

		const result = this.API.GetValue(element);

		if (checkError === true && result === '') {
			var errorNumber = this.API.GetLastError();

			if (errorNumber !== this.SCORM_NO_ERROR) {
				var errorString = this.API.GetErrorString(errorNumber);
				var diagnostic = this.API.GetDiagnostic(errorNumber);

				var errorDescription =
					'Number: ' +
					errorNumber +
					'\nDescription: ' +
					errorString +
					'\nDiagnostic: ' +
					diagnostic;

				alert('Error - Could not retrieve a value from the LMS.\n\n' + errorDescription);
				return '';
			}
		}

		return result;
	}

	setValue(element, value) {
		if (this.initialized === false || this.terminateCalled === true) {
			return;
		}

		const result = this.API.SetValue(element, value);

		if (result === this.SCORM_FALSE) {
			var errorNumber = this.API.GetLastError();
			var errorString = this.API.GetErrorString(errorNumber);
			var diagnostic = this.API.GetDiagnostic(errorNumber);

			var errorDescription =
				'Number: ' + errorNumber + '\nDescription: ' + errorString + '\nDiagnostic: ' + diagnostic;

			alert(
				'Error - Could not store a value in the LMS.\n\nYour results may not be recorded.\n\n' +
					errorDescription,
			);
			return;
		}
	}

	serializeData(unserializedData) {
		const dataJson = JSON.stringify(unserializedData);
		const dataLZString = LZString.compressToBase64(dataJson);

		return dataLZString;
	}

	deserializeData(serializedData) {
		return JSON.parse(LZString.decompressFromBase64(serializedData)) || {};
	}
}

class ScormApi12 {
	constructor() {
		this.nFindAPITries = 0;
		this.API = null;
		this.MAX_PARENTS_TO_SEARCH = 500;

		//Constants
		this.SCORM_TRUE = 'true';
		this.SCORM_FALSE = 'false';
		this.SCORM_NO_ERROR = '0';

		//Since the Unload handler will be called twice, from both the onunload
		//and onbeforeunload events, ensure that we only call Terminate once.
		this.terminateCalled = false;

		//Track whether or not we successfully initialized.
		this.initialized = false;

		this.elementsNameConversion = {
			'cmi.exit': 'cmi.core.exit',
			'cmi.score.raw': 'cmi.core.score.raw',
			'cmi.score.min': 'cmi.core.score.min',
			'cmi.score.max': 'cmi.core.score.max',
			'cmi.score.scaled': null,
			'cmi.completion_status': undefined,
			'cmi.success_status': 'cmi.core.lesson_status',
			'cmi.location': 'cmi.core.lesson_location',
			'cmi.progress_measure': null,
			'cmi.suspend_data': 'cmi.suspend_data',
			'cmi.session_time': 'cmi.core.session_time',
		};
	}

	scanForAPI(win) {
		while (win.API == null && win.parent != null && win.parent !== win) {
			this.nFindAPITries++;
			if (this.nFindAPITries > 7) {
				alert('No se pudo encontrar el API de Scorm del LMS');
				return null;
			}

			win = win.parent;
		}
		return win.API;
	}

	getAPI() {
		let API = this.scanForAPI(window);

		/*
		If we didn't find the API in this window's chain of parents,
		then search all the parents of the opener window if there is one
		*/

		if (API == null && window.opener != null && typeof window.opener != 'undefined') {
			// console.log('API found in case 1');
			API = this.scanForAPI(window.opener);
		}

		//Search all the parents of the current window if there are any
		if (API == null && window.parent != null && window.parent !== window) {
			// console.log('API found in case 2');
			API = this.scanForAPI(window.parent);
		}

		if (API == null && window.top.opener != null) {
			// console.log('API found in case 3');
			API = this.scanForAPI(window.top.opener);
		}

		return API;
	}

	initialize() {
		this.API = this.getAPI();

		if (this.API == null) {
			alert(
				'ERROR - Could not establish a connection with the LMS.\n\nYour results may not be recorded.',
			);
			return;
		}

		const result = this.API.LMSInitialize('');

		if (result === this.SCORM_FALSE) {
			const errorNumber = this.API.LMSGetLastError();
			const errorString = this.API.LMSGetErrorString(errorNumber);
			const diagnostic = this.API.LMSGetDiagnostic(errorNumber);

			const errorDescription =
				'Number: ' + errorNumber + '\nDescription: ' + errorString + '\nDiagnostic: ' + diagnostic;

			alert(
				'Error - Could not initialize communication with the LMS.\n\nYour results may not be recorded.\n\n' +
					errorDescription,
			);
			return;
		}

		this.startTime = new Date();

		window.onbeforeunload = () => {
			// console.log('onbeforeunload llamando a terminate');
			this.terminate();
			return null;
		};

		document.addEventListener('visibilitychange', () => {
			if (document.visibilityState !== 'visible') {
				this.setSessionTime();
			}
		});

		this.initialized = true;
	}

	setSessionTime() {
		const sessionDuration = intervalToDuration({start: this.startTime, end: new Date()});
		this.setValue(
			'cmi.session_time',
			`${sessionDuration.hours.toLocaleString('en-US', {
				minimumIntegerDigits: 2,
				useGrouping: false,
			})}:${sessionDuration.minutes.toLocaleString('en-US', {
				minimumIntegerDigits: 2,
				useGrouping: false,
			})}:${sessionDuration.seconds.toLocaleString('en-US', {
				minimumIntegerDigits: 2,
				useGrouping: false,
			})}.0`,
		);
	}

	terminate() {
		// console.log('Scorm 1.2 Terminate called.');
		//Don't terminate if we haven't initialized or if we've already terminated
		if (this.initialized === false || this.terminateCalled === true) {
			return;
		}

		this.setSessionTime();
		// this.API.LMSCommit('');
		const result = this.API.LMSFinish('');

		// console.log('LMSFinish called', result);

		this.terminateCalled = true;

		if (result === this.SCORM_FALSE) {
			const errorNumber = this.API.LMSGetLastError();
			const errorString = this.API.LMSGetErrorString(errorNumber);
			const diagnostic = this.API.LMSGetDiagnostic(errorNumber);

			var errorDescription =
				'Number: ' + errorNumber + '\nDescription: ' + errorString + '\nDiagnostic: ' + diagnostic;

			alert(
				'Error - Could not terminate communication with the LMS.\n\nYour results may not be recorded.\n\n' +
					errorDescription,
			);
			return;
		}

		if (window.opener != null && typeof window.opener != 'undefined' && window.opener !== window) {
			// console.log('Cerrando ventana desde terminate');
			window.close();
		}
	}

	getValue(element, checkError) {
		if (this.initialized === false || this.terminateCalled === true) {
			return '';
		}

		const convertedElement = get(this.elementsNameConversion, element, null);
		if (!convertedElement) {
			return '';
		}

		const result = this.API.LMSGetValue(convertedElement);

		if (checkError === true && result === '') {
			const errorNumber = this.API.LMSGetLastError();

			if (errorNumber !== this.SCORM_NO_ERROR) {
				const errorString = this.API.LMSGetErrorString(errorNumber);
				const diagnostic = this.API.LMSGetDiagnostic(errorNumber);

				var errorDescription =
					'Number: ' +
					errorNumber +
					'\nDescription: ' +
					errorString +
					'\nDiagnostic: ' +
					diagnostic;

				alert('Error - Could not retrieve a value from the LMS.\n\n' + errorDescription);
				return '';
			}
		}

		return result;
	}

	setValue(element, value) {
		if (this.initialized === false || this.terminateCalled === true) {
			return;
		}

		const convertedElement = get(this.elementsNameConversion, element, null);
		if (!convertedElement) {
			return;
		}

		const result = this.API.LMSSetValue(convertedElement, value);

		if (result === this.SCORM_FALSE) {
			const errorNumber = this.API.LMSGetLastError();
			const errorString = this.API.LMSGetErrorString(errorNumber);
			const diagnostic = this.API.LMSGetDiagnostic(errorNumber);

			var errorDescription =
				'Number: ' + errorNumber + '\nDescription: ' + errorString + '\nDiagnostic: ' + diagnostic;

			alert(
				'Error - Could not store a value in the LMS.\n\nYour results may not be recorded.\n\n' +
					errorDescription,
			);
			return;
		}

		this.API.LMSCommit('');
	}

	serializeData(unserializedData) {
		const dataJson = JSON.stringify(unserializedData);
		const dataLZString = LZString.compressToBase64(dataJson);
		// console.log(
		// 	"saveStateData",
		// 	dataLZString.length,
		// 	dataJson.length,
		// 	unserializedData,
		// 	dataJson,
		// 	dataLZString
		// );
		return dataLZString;
	}

	deserializeData(serializedData) {
		return JSON.parse(LZString.decompressFromBase64(serializedData)) || {};
	}
}

export default ScormCourseStateManager;
