import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import ImageComp from 'businessLogic/core/shared/Image/Image';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import Animation from 'businessLogic/core/shared/Animation';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class Image extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Imagen`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {
		image: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
			footer: '',
		},
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'Image']);

		const imageObj = data.image;

		const classes = classNames({
			'brick': true,
			'image-brick': true,
		});

		const brickComponent = (
			<WindowSizeContext.Consumer>
				{({device}) => {
					return (
						<div className={classes}>
							<ImageComp
								imgObj={imageObj}
								clickable={device === 'mobile' || device === 'mobileLandscape' ? false : true}
							/>
							{(imageObj.credit || imageObj.footer) && (
								<div className="image-brick__caption">
									{imageObj.footer && (
										<div className="image-brick__caption__footer"> {imageObj.footer} </div>
									)}
									{imageObj.credit && (
										<div className="image-brick__caption__credit"> {imageObj.credit} </div>
									)}
								</div>
							)}
						</div>
					);
				}}
			</WindowSizeContext.Consumer>
		);
		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<Animation playAnimation={this.state.playEffect} delay={1} effect={brickAnimation}>
					{brickComponent}
				</Animation>
			</Container>
		);
	}
}

export default brickWrapper(Image);
