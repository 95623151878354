//En este fichero definimos todas las constantes que nombrarán las acciones sobre nuestro store
//Ejemplo:
// export const CREATE_PILL = "myb-admin/pills/action/CREATE_PILL";

export const CREATE_PILL = 'myb-admin/pills/action/CREATE_PILL';
export const UPDATE_PILL = 'myb-admin/pills/action/UPDATE_PILL';
export const CREATE_BRICK = 'myb-admin/pills/action/CREATE_BRICK';
export const ADD_CHILD_BRICK = 'myb-admin/pills/action/ADD_CHILD_BRICK';
export const UPDATE_BRICK = 'myb-admin/pills/action/UPDATE_BRICK';
export const DELETE_BRICK = 'myb-admin/pills/action/DELETE_BRICK';
export const GET_PILLS_API = 'myb-admin/pills/action/GET_PILLS_API';
export const INITIAL_STATE = 'myb-admin/pills/action/INITIAL_STATE';
export const DELETE_PILL = 'myb-admin/pills/action/DELETE_PILL';
