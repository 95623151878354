import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import PropTypes from 'prop-types';
import ImageElement from './components/ImageElement';
import Animation from 'businessLogic/core/shared/Animation';
import './styles.scss';

class SeveralImages extends PureComponent {
	render() {
		const {className, images, format, margin, playAnimation, effect, delay, clickable} = this.props;

		const classes = classNames({
			'several-images-component': true,

			'several-images-component--margin': margin,
			'several-images-component--two-images': images.length === 2,
			'several-images-component--three-images': images.length === 3,
			'several-images-component--two-images--with-text': images.length === 2,
			'several-images-component--three-images--with-text': images.length === 3,
			'several-images-component--four-images': images.length === 4,
			'several-images-component--six-images': images.length === 6,
			[`${className}`]: true,
		});
		return (
			<div className={classes}>
				{images.map((image, index) => (
					<div key={index} className="image-element image-wrap">
						<Animation
							playAnimation={playAnimation}
							effect={effect}
							delay={delay ? index * delay : index * 0.7}
						>
							<ImageElement
								image={image}
								format={format}
								set={(fieldPath, fieldData) => {
									//Propagamos la llamada al método set añadiendo mi nodo al path
									if (this.props.set) this.props.set([index].concat(fieldPath), fieldData);
								}}
								imgWidths={this.props.imgWidths}
								clickable={clickable}
							/>
						</Animation>
					</div>
				))}
			</div>
		);
	}
}

SeveralImages.propTypes = {
	className: PropTypes.string,
	images: PropTypes.array,
	format: PropTypes.oneOf(['square', 'landscape', 'portrait']),
	margin: PropTypes.bool,
	set: PropTypes.func,
	imgWidths: PropTypes.object, //Objeto con el ancho asociado a cada dispositivo
	clickable: PropTypes.bool,
};

SeveralImages.defaultProps = {
	className: '',
	format: 'landscape',
	margin: false,
	imgWidths: {},
	clickable: true,
};

export default brickWrapper(SeveralImages);
