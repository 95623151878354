import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Repeater',
						fieldName: 'images',
						blockTitle: t`Imagen`,
						content: [
							{
								type: 'Image',
								fieldName: 'image',
								defaultImage: '/uploads/default-images/csDefault4Opt.svg',
								defaultCredit: '',
								footer: true,
							},
							{
								type: 'Text',
								fieldName: 'hrefLink',
								title: t`Enlace de la imagen`,
								placeholder: t`Introduce el enlace de la imagen`,
								defaultValue: '',
							},
						],
					},
				],
			},
		],
	},
];
export default editionFields;
