import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import isEmpty from 'lodash/isEmpty';
import './styles.scss';

class ImportantTextColorBox extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Cita con caja`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		text:
			'"Feri dem eos volorat. Archil iusdaecto inveria nditat.Hiligent quae re pre la doloremporro magni od quiatur a sunditate cuptas dolestrunt, cus ipiendis into el magnis soluptiunt dunt"',
		author: 'ARCHIL IUSDAECTO',
	};

	static brickDefaultConfig = {};

	// static brickDefaultStyles = {
	// 	containerStyle: {
	// 		colorBg: {r: '115', g: '229', b: '209', a: '1'},
	// 	},
	// };

	render() {
		const data = this.props.brick.data;
		// const styles = this.props.brick.styles || {};

		const highlightColor = getComputedStyle(document.documentElement).getPropertyValue(
			'--highlightColor',
		);
		let styles;

		if (isEmpty(this.props.brick.styles)) {
			styles = {
				containerStyle: {
					colorBg: highlightColor,
				},
			};
		} else {
			styles = this.props.brick.styles || {};
		}
		const classes = classNames({
			'brick': true,
			'important-text-color-box-brick': true,
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div className="important-text-color-box-brick__text">
						<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
					</div>
					<div className="important-text-color-box-brick__author">
						<FrontEditableText fieldName="author" text={data.author} set={this.props.set} />
					</div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ImportantTextColorBox);
