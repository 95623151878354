import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
// import 'react-quill/dist/quill.core.css';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

class ParagraphThreeColumnsTitle extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Párrafo a tres columnas con título`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)
	//Aunque este ladrillo es de tres columnas, precargamos contenido para 3, por si cambia después el tipo
	static brickDefaultData = {
		columns: [
			{
				title: 'DUSUE NOBISTA MANTE MANTRESE',
				text: 'Ma quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi as exerchita doloreperum quaectur, ut into te la nobitatur? Quiduciet, iunt ut am, nem nos aut molupiet etusamus, ut resed et fugiti a dolore pa vel istios es cus sed modipiderum re doluptat et aut qui amet ipsam vent inctoris quis sitatib uscidis que posam istios es cus sed modipiderum.Ibus, tet landis alissit odicima gnatiis doluptam sit quiatum quatem nos militas sint untestiae nonemquiam iuriae nonsequist.',
			},
			{
				title: 'DUSUE NOBISTA MANTE MANTRESE',
				text: 'Ma quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi as exerchita doloreperum quaectur, ut into te la nobitatur? Quiduciet, iunt ut am, nem nos aut molupiet etusamus, ut resed et fugiti a dolore pa vel istios es cus sed modipiderum re doluptat et aut qui amet ipsam vent inctoris quis sitatib uscidis que posam istios es cus sed modipiderum.Ibus, tet landis alissit odicima gnatiis doluptam sit quiatum quatem nos militas sint untestiae nonemquiam iuriae nonsequist.',
			},
			{
				title: 'DUSUE NOBISTA MANTE MANTRESE',
				text: 'Ma quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi as exerchita doloreperum quaectur, ut into te la nobitatur? Quiduciet, iunt ut am, nem nos aut molupiet etusamus, ut resed et fugiti a dolore pa vel istios es cus sed modipiderum re doluptat et aut qui amet ipsam vent inctoris quis sitatib uscidis que posam istios es cus sed modipiderum.Ibus, tet landis alissit odicima gnatiis doluptam sit quiatum quatem nos militas sint untestiae nonemquiam iuriae nonsequist.',
			},
		],
	};
	static brickDefaultConfig = {};

	render() {
		const classes = classNames({
			'paragraph-three-columns-title-brick': true,
		});

		const data = this.props.brick.data;
		const columns = data.columns || [];
		const styles = this.props.brick.styles || {};

		let elements = columns.map((column, index) => {
			let columnName = ' ';
			if (index === 0) columnName = 'left';
			if (index === 1) columnName = 'center';
			if (index === 2) columnName = 'right';
			return (
				<div className={classes + '__column ' + classes + '__column--' + columnName} key={index}>
					<div className={classes + '__column__title'}>
						<FrontEditableText
							fieldName="title"
							text={column.title}
							set={(fieldPath, fieldData) => {
								//Propagamos la llamada al método set añadiendo mi nodo al path
								if (this.props.set) this.props.set(['columns', index].concat(fieldPath), fieldData);
							}}
						/>
					</div>
					<div className={classes + '__column__text'}>
						<FrontEditableText
							fieldName="text"
							text={column.text}
							set={(fieldPath, fieldData) => {
								//Propagamos la llamada al método set añadiendo mi nodo al path
								if (this.props.set) this.props.set(['columns', index].concat(fieldPath), fieldData);
							}}
						/>
					</div>
				</div>
			);
		});
		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={'brick ' + classes}>{elements}</div>
			</Container>
		);
	}
}

export default brickWrapper(ParagraphThreeColumnsTitle);
