import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import classNames from 'classnames';
import Response from './components/Response';
import FeedBackTestModal from 'businessLogic/core/shared/Test/FeedBackTestModal';
import AlarmIcon from '@mui/icons-material/Alarm';
import ScoreIcon from '@mui/icons-material/Speed';
import {t} from 'businessLogic/scope/user/helper/userTtag';
import './styles.scss';

class Question extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			finished: false,
			result: {},
			multiSuccess: false,
			showComment: false,
			remainingTime: props.initialQuestionTime,
		};
		this.successes = 0;
		this.failures = 0;
		this.totalSuccesses = this.getSolutions();
		this.coefSuccess = 0;
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.activeIndex !== this.props.activeIndex &&
			this.props.activeIndex === this.props.index
		) {
			this.totalSuccesses = this.getSolutions();
			this.startCounter();
		}
	}
	getSolutions = () => {
		let solutionSucces = 0;
		for (let i = 0; i < this.props.setResponses.length; i++) {
			if (this.props.setResponses[i].valueSolution === true) {
				solutionSucces++;
			}
		}
		return solutionSucces;
	};

	handleShowModal = () => {
		this.setState(
			(state) => {
				let responsesCompleted = state.finished;
				if (
					(this.totalSuccesses !== 0 && this.successes === this.totalSuccesses) ||
					this.failures > 0
				) {
					responsesCompleted = true;
				}

				return {
					showComment: false,
					finished: responsesCompleted,
				};
			},
			() => {
				if (this.state.finished) this.handleFinishedQuestion(1000);
			},
		);
	};

	handleFinishedQuestion = (duration) => {
		clearTimeout(this.goNextTimeoutHandler);
		this.goNextTimeoutHandler = setTimeout(() => {
			this.props.onFinished(
				this.successes,
				this.failures,
				this.coefSuccess,
				this.successes ? this.remainingTime : 0,
			);
		}, duration);
	};
	handleNoticeMultiSuccess = () => {
		if (this.totalSuccesses > 1) {
			this.setState({
				multiSuccess: true,
			});
		}
	};
	finishTest = (result) => {
		for (let i = 0; i < this.props.setResponses.length; i++) {
			if (this.props.setResponses[i].valueSolution === true) {
				result[i] = this.props.setResponses[i].valueSolution === true;
			}
		}
	};

	commentExists = (comment) => {
		if (!comment) return false;

		if (typeof comment === 'object') {
			return comment?.textValue !== '';
		}

		return comment !== '';
	};

	handleClick = (responseItem, responseIndex) => {
		//"seteamos" el state tomando como base el state anterior utilizándolo como argumnento. Además nos aseguramos de que setState se dispare justo despues de cada actualización del state anterior
		this.setState((state) => {
			//Pass By Reference :  Necesitamos una nueva referencia para que react detecte el cambio de estado y lo vuelva a renderizar
			const newResult = Object.assign({}, state.result);

			// responseItem.valueSolution === true; Convertimos este dato en un boolean siempre.
			newResult[responseIndex] = responseItem.valueSolution === true;
			if (newResult[responseIndex] === true) {
				this.successes++;
				this.coefSuccess = this.coefSuccess + 1 / this.totalSuccesses;
			} else {
				this.failures++;
			}

			let responsesCompleted = state.finished,
				newShowComment = state.showComment;

			/*Acutalizar el estado de de mostrar resultado solo en el caso de que este exista */
			// if (responseItem.comment && responseItem.comment.textValue) {
			if (this.commentExists(responseItem.comment)) {
				newShowComment = true;
			}
			if (
				(this.totalSuccesses !== 0 && this.successes === this.totalSuccesses) ||
				this.failures > 0
			) {
				responsesCompleted = true;
				this.remainingTime = this.state.remainingTime;
				if (!this.commentExists(responseItem.comment)) {
					this.handleFinishedQuestion(2000);
				}
			}
			return {
				result: newResult,
				finished: responsesCompleted,
				showComment: newShowComment,
			};
		});
	};

	startCounter = () => {
		// Reducimos el contador de segundos usando requestAnimationFrame
		if (this.state.remainingTime && !this.counterStarted) {
			this.counterStarted = true;

			let lastTimestamp;
			let milisecondsFromStart = 0;

			const loop = (timestamp) => {
				if (!lastTimestamp) lastTimestamp = timestamp;
				const delta = timestamp - lastTimestamp;
				lastTimestamp = timestamp;
				let newRemainingTime = this.state.remainingTime;
				// Solo contamos cuando no se está mostrando el comentario
				if (!this.state.showComment) {
					milisecondsFromStart += delta;

					newRemainingTime = Math.max(
						0,
						Math.round(this.props.initialQuestionTime - Math.round(milisecondsFromStart / 1000)),
					);

					this.setState({remainingTime: newRemainingTime});
				}

				if (newRemainingTime > 0 && !this.state.finished) {
					requestAnimationFrame(loop);
				}
			};

			requestAnimationFrame(loop);
		}
	};

	componentDidMount() {
		this.handleNoticeMultiSuccess();
		if (this.props.activeIndex === this.props.index) {
			this.startCounter();
		}
	}
	componentWillUnmount() {
		clearTimeout(this.goNextTimeoutHandler);
	}

	render() {
		const {index, activeIndex} = this.props;
		const classes = classNames({
			'question-test-basic-component': true,
			'swiper-slide': true,
		});
		const highlightTime = this.state.finished && this.remainingTime > 0 && this.successes > 0;
		const hideTime =
			this.state.remainingTime === 0 || (this.state.finished && this.successes === 0);

		const pointsPerCorrectAnswer = this.totalSuccesses
			? Math.trunc(this.props.valueSuccess / this.totalSuccesses)
			: 0;

		return (
			<div className={classes}>
				{(activeIndex === index || activeIndex - 1 === index || activeIndex + 1 === index) && (
					<React.Fragment>
						<div className="row">
							<div className="col-xs-10 col-xs-offset-1 col-sm-12 col-sm-offset-0 col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
								<div className="question-test-basic-component__statement">
									<FrontEditableText
										text={this.props.statement}
										fieldName="question"
										set={this.props.set}
									/>
									{this.state.multiSuccess && (
										<div className="question-test-basic-component__notice">
											<p>{t`¡Ojo! Hay ${this.totalSuccesses} respuestas verdaderas`}</p>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xs-10 col-xs-offset-1 col-sm-12 col-sm-offset-0 col-xl-10 col-xl-offset-1">
								<div className="question-test-basic-component__responses">
									{this.props.setResponses.map((responseItem, responseIndex) => {
										return (
											<React.Fragment key={'response--' + responseIndex}>
												<Response
													text={responseItem.answer}
													onClick={() => {
														if (!this.state.finished) {
															this.handleClick(responseItem, responseIndex);
														}
													}}
													feedbackCorrect={this.state.result[responseIndex]}
													disable={this.state.finished}
													valueSolution={responseItem.valueSolution}
												/>
												{this.state.result[responseIndex] !== undefined &&
													this.commentExists(responseItem.comment) && (
														<FeedBackTestModal
															show={this.state.showComment}
															feedbackCorrect={this.state.result[responseIndex]}
															comment={responseItem.comment}
															onClose={this.handleShowModal}
														/>
													)}
											</React.Fragment>
										);
									})}
								</div>
							</div>
						</div>
					</React.Fragment>
				)}
				<div className="question-test-basic-component__score-info">
					{!!pointsPerCorrectAnswer &&
						[...Array(this.totalSuccesses)].map((e, i) => {
							const highlight = i + 1 <= this.successes;
							const hide = !highlight && this.failures > 0;
							// const hide = this.totalSuccesses - i <= this.failures;
							return (
								<div
									key={i}
									className={`question-test-basic-component__score-info__element ${
										highlight ? 'highlight' : ''
									} ${hide ? 'hide' : ''}`}
								>
									<ScoreIcon />
									{pointsPerCorrectAnswer}
								</div>
							);
						})}
					{this.props.initialQuestionTime > 0 && (
						<div
							className={`question-test-basic-component__score-info__element ${
								highlightTime ? 'highlight' : ''
							} ${hideTime ? 'hide' : ''}`}
						>
							<AlarmIcon />
							{this.state.remainingTime}
						</div>
					)}
				</div>
			</div>
		);
	}
}
Question.propType = {
	statement: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	setResponses: PropTypes.array,
	set: PropTypes.func,
	onFinished: PropTypes.func,
};

export default Question;
