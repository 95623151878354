import React from 'react';
import PropTypes from 'prop-types';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import {t} from 'businessLogic/scope/user/helper/userTtag';
import './styles.scss';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const FeedBackTestModal = ({show, onClose, feedbackCorrect, comment}) => {
	return (
		<div>
			<Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={show}>
				<DialogTitle sx={{m: 0, p: 2}}>
					{onClose ? (
						<IconButton
							aria-label="close"
							onClick={onClose}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					) : null}
				</DialogTitle>
				<DialogContent>
					<div className="feedback-test-modal__solution">
						{feedbackCorrect ? (
							<p className="feedback-test-modal__solution--success">{t`La respuesta es correcta`}</p>
						) : (
							<p className="feedback-test-modal__solution--failure">{t`La respuesta es incorrecta`}</p>
						)}
					</div>
					<div className="feedback-test-modal__comment">
						<FrontEditableText text={comment} editable={false} />
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
};

// class FeedBackTestModal extends PureComponent {
// 	render() {
// 		const classes = classNames({
// 			'feedback-test-modal': true,
// 			'swiper-no-swiping': true,
// 			'feedback-test-modal--show': this.props.show,
// 		});
// 		return (
// 			<div className={classes} aria-modal="true">
// 				<div className="feedback-test-modal__inner">
// 					<div className="feedback-test-modal__trigger-close" onClick={this.props.onClose}>
// 						<Icon name="close-cross" />
// 					</div>
// 					<div className="feedback-test-modal__solution">
// 						{this.props.feedbackCorrect ? (
// 							<p className="feedback-test-modal__solution--success">{t`La respuesta es correcta`}</p>
// 						) : (
// 							<p className="feedback-test-modal__solution--failure">{t`La respuesta es incorrecta`}</p>
// 						)}
// 					</div>
// 					<div className="feedback-test-modal__comment">
// 						<FrontEditableText text={this.props.comment} editable={false} />
// 					</div>
// 				</div>
// 			</div>
// 		);
// 	}
// }

FeedBackTestModal.propTypes = {
	show: PropTypes.bool,
	comment: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onClose: PropTypes.func,
	feedbackCorrect: PropTypes.bool,
};
FeedBackTestModal.defaultProps = {
	show: false,
};
export default FeedBackTestModal;
