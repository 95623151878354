import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getPill} from 'businessLogic/store/data/pills';
import editionBrickWrapper from '../editionBrickWrapper';
import Instructions from 'businessLogic/core/shared/Edit/Instructions';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class PillSelector extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		const defaultValue = {
			pillId: '',
			usePillData: editionBrickStruct.defaultUsePillData || false,
			imageObj: {
				imageSrc: editionBrickStruct.defaultImage || '',
				credit: editionBrickStruct.defaultCredit || '',
			},
			title: editionBrickStruct.defaultTitle || '',
			text: editionBrickStruct.defaultText || '',
		};

		return defaultValue;
	};

	pillSelectorField = [
		{
			type: 'Select',
			fieldName: 'pillId',
			title: '',
			options: [{label: t`Sin enlace`, value: ''}],
		},
	];

	pillDataSwitchField = [
		{
			type: 'Switch',
			fieldName: 'usePillData',
			title: t`Usar datos de la píldora`,
		},
	];

	pillInfoFields = [
		{
			type: 'Image',
			fieldName: 'imageObj',
			defaultImage: '/uploads/default-images/csDefault1Opt.svg',
		},
		{
			type: 'TextEditor',
			fieldName: 'title',
			title: t`Título de la imagen`,
			placeholder: t`Introduce el título de la imagen`,
			defaultValue: 'DUS QUE NOBISTA MEI',
		},
		{
			type: 'TextEditor',
			fieldName: 'text',
			title: t`Texto de la imagen`,
			placeholder: t`Introduce el texto de la imagen`,
			defaultValue:
				'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
		},
	];

	render() {
		const selectOptions = [];
		selectOptions.push({label: t`Sin enlace`, value: ''});

		if (this.props.parentPill) {
			this.props.parentPill.pills.forEach((pill) => {
				selectOptions.push({label: pill.name, value: pill.id});
			});
		}

		this.pillSelectorField[0].options = selectOptions;

		const pillLinkData = this.props.getMainField();

		return (
			<div className="edition-brick-pill-selector">
				<label className="edition-brick-pill-selector__label">
					{this.props.editionBrickStruct.title}
				</label>
				{this.props.editionBrickStruct.instructions !== undefined && (
					<Instructions text={this.props.editionBrickStruct.instructions} />
				)}
				{this.props.renderChildren(this.pillSelectorField)}
				{pillLinkData.pillId !== '' && this.props.renderChildren(this.pillDataSwitchField)}
				{(!pillLinkData.usePillData || pillLinkData === '') &&
					this.props.renderChildren(this.pillInfoFields)}
			</div>
		);
	}
}

//function que sirve para pasar los datos al render
const mapStateToProps = (state, ownProps) => {
	const parentPill = getPill(state, ownProps.parentId);
	return {
		parentPill,
		...ownProps,
	};
};

const PillSelectorConnect = connect(mapStateToProps)(editionBrickWrapper(PillSelector));

export default PillSelectorConnect;
