import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import TabContent from 'businessLogic/core/shared/Tab/TabContent';
import Icon from 'ui/shared/Icon';
import EventHandlerCache from 'businessLogic/helpers/EventHandlerCache';
import brickImage from './img/brickPreview.png';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';

import './styles.scss';

class ShowInfoTabs extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Ampliar Información - Tabs`;
	static brickIcon = 'show-info-tabs';
	static brickImage = brickImage;

	static brickDefaultData = {
		tabs: [
			{
				section: 'Section',
				tabContent: [
					{
						imageObj: {
							imageSrc: '/uploads/default-images/csDefault1Opt.svg',
							credit: '',
						},
						title: 'ROS EOS INIS NAM UTASE BANSTREA SREAS',
						text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
					},
				],
			},
			{
				section: 'Section',
				tabContent: [
					{
						imageObj: {
							imageSrc: '/uploads/default-images/csDefault2Opt.svg',
							credit: '',
						},
						title: 'ROS EOS INIS NAM UTASE BANSTREA SREAS',
						text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
					},
				],
			},
			{
				section: 'Section',
				tabContent: [
					{
						imageObj: {
							imageSrc: '/uploads/default-images/csDefault3Opt.svg',
							credit: '',
						},
						title: 'ROS EOS INIS NAM UTASE BANSTREA SREAS',
						text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
					},
				],
			},
			{
				section: 'Section',
				tabContent: [
					{
						imageObj: {
							imageSrc: '/uploads/default-images/csDefault4Opt.svg',
							credit: '',
						},
						title: 'ROS EOS INIS NAM UTASE BANSTREA SREAS',
						text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
					},
				],
			},
			{
				section: 'Section',
				tabContent: [
					{
						imageObj: {
							imageSrc: '/uploads/default-images/csDefault5Opt.svg',
							credit: '',
						},
						title: 'ROS EOS INIS NAM UTASE BANSTREA SREAS',
						text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
					},
				],
			},
		],
	};

	constructor(props) {
		super(props);
		this.state = {
			activeTabIndex: 0,
			tabContentHeight: 0,
			tabContenContainertHeight: 0,
		};
		this.swiper = null;
		this.eventHandlerCache = new EventHandlerCache();
	}

	changeActiveTab = (tabIndex) => {
		if (this.state.activeTabIndex !== tabIndex) {
			this.setState({activeTabIndex: tabIndex});
			setTimeout(() => {
				this.calculateHeight();
			}, 500);
			let tabsContainer = this.refs.tabsContentContainer;
			tabsContainer.scrollTop = 0;
		}
	};

	closeImage = () => {
		this.setState({activeTabIndex: -1});
	};

	calculateHeight = () => {
		if (this.refs.tabsContentContainer) {
			let activeContentArray = this.refs.tabsContentContainer.querySelectorAll(
				'.show-info-tabs__content',
			);
			let activeTabIndex = activeContentArray[this.state.activeTabIndex];
			if (activeTabIndex !== undefined) {
				this.setState({
					tabContentHeight: activeTabIndex.clientHeight,
					tabContenContainertHeight: this.refs.tabsContentContainer.clientHeight,
				});
			}
		}
	};

	componentDidMount() {
		let _this = this;
		setTimeout(function () {
			_this.calculateHeight();
		}, 500);
		let tabsContainer = this.refs.tabsContentContainer;
		tabsContainer.scrollTop = 0;
		if (window.innerWidth < 768) {
			this.setState({activeTabIndex: -1});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const tabs = data.tabs || [];
		const classes = classNames({
			'brick': true,
			'show-info-tabs': true,
		});

		const tabsList = tabs.map((tabListItem, index) => {
			return (
				<li
					onClick={this.eventHandlerCache.getEventHandler('li_onClick', index, () =>
						this.changeActiveTab(index),
					)}
					className={this.state.activeTabIndex === index ? 'active' : ''}
					key={index}
				>
					<FrontEditableText
						fieldName="section"
						text={tabListItem.section}
						editable={false}
						set={(fieldPath, fieldData) => {
							//Propagamos la llamada al método set añadiendo mi nodo al path
							if (this.props.set) this.props.set(['tabs', index].concat(fieldPath), fieldData);
						}}
					/>
				</li>
			);
		});

		const tabsContent = tabs.map((tabContentItems, index) => {
			return (
				<WindowSizeContext.Consumer key={index}>
					{({device}) => {
						if (device === 'mobile') {
							return (
								<TabContent
									key={index}
									item={tabContentItems}
									index={index}
									activeIndex={this.state.activeTabIndex}
									onClose={this.closeImage}
									type="box"
								/>
							);
						} else {
							return (
								<div
									onClick={() => this.changeActiveTab(index)}
									className={
										'show-info-tabs__content ' +
										(this.state.activeTabIndex === index ? 'active' : '')
									}
									key={index}
								>
									{index === this.state.activeTabIndex && (
										<React.Fragment>
											<span
												className="show-info-tabs__content__close"
												onClick={() => this.closeImage(index)}
											>
												<Icon name="close-modal" size="normal" />
											</span>

											{tabContentItems.tabContent.map((tabContentItem, index) => (
												<div key={index}>
													{tabContentItem.title && (
														<div className="show-info-tabs__content__title">
															<FrontEditableText
																fieldName="title"
																text={tabContentItem.title}
																editable={false}
																set={(fieldPath, fieldData) => {
																	//Propagamos la llamada al método set añadiendo mi nodo al path
																	if (this.props.set)
																		this.props.set(['tabs', index].concat(fieldPath), fieldData);
																}}
															/>
														</div>
													)}
													{tabContentItem.text && (
														<div className="show-info-tabs__content__text">
															<FrontEditableText
																fieldName="text"
																text={tabContentItem.text}
																editable={false}
																set={(fieldPath, fieldData) => {
																	//Propagamos la llamada al método set añadiendo mi nodo al path
																	if (this.props.set)
																		this.props.set(['tabs', index].concat(fieldPath), fieldData);
																}}
															/>
														</div>
													)}
													{tabContentItem.imageObj.imageSrc && (
														<div className="show-info-tabs__content__image">
															<NewImageClickable imgObj={tabContentItem.imageObj} />
															<span className="show-info-tabs__content__image__credits">
																{tabContentItem.imageObj.credit}
															</span>
														</div>
													)}
												</div>
											))}
										</React.Fragment>
									)}
								</div>
							);
						}
					}}
				</WindowSizeContext.Consumer>
				//
			);
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<ul className="show-info-tabs__selector">{tabsList}</ul>
					<div
						className={
							'show-info-tabs__content-container ' +
							(this.state.tabContentHeight < this.state.tabContenContainertHeight
								? 'no-scroll'
								: '')
						}
						ref="tabsContentContainer"
					>
						{tabsContent}
					</div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ShowInfoTabs);
