import React from 'react';
import {useSlate} from 'slate-react';
// import {Transforms} from 'slate';
import ToggleButton from '../basics/ToggleButton';
import ColorSelector from 'businessLogic/shared/Selects/ColorSelector';

import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

import DialogMui from 'businessLogic/shared/Modals/DialogMui';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import entityManager from 'businessLogic/services/EntityManager';
import {getColorObject} from 'businessLogic/helpers/functions';

import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';

function PaperComponent(props) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

const Icon = ({format}) => {
	switch (format) {
		case 'color':
			return <FormatColorTextIcon />;
		case 'highlightColor':
			return <FormatColorFillIcon />;
		default:
			return null;
	}
};

// Función que comprueba si el color no es ninguno de los colores de la paleta, y en ese caso
// lo almacenará en el storage para que se pueda seleccionar en el futuro
const checkColor = (colors, color) => {
	if (!color) {
		return;
	}
	try {
		const colorObject = colors.find((c) => c.color.hex === color?.hex);
		if (!colorObject) {
			const currentColors = JSON.parse(localStorage.getItem('customColors')) || [];
			const newColors = [color, ...currentColors];
			// Si ya hay 4 colores en el storage, se eliminará el último
			if (newColors.length > 4) {
				newColors.pop();
			}
			localStorage.setItem('customColors', JSON.stringify(newColors));
		}
	} catch (error) {
		console.error('Error en checkColor', error);
	}
};

const getCustomColors = () => {
	const defaultColorObjects = [
		{title: '#FEDC82', color: getColorObject('#FEDC82')},
		{title: '#FA5F85', color: getColorObject('#FA5F85')},
		{title: '#33CCFF', color: getColorObject('#33CCFF')},
		{title: '#E97B5C', color: getColorObject('#E97B5C')},
	];
	try {
		const customColors = JSON.parse(localStorage.getItem('customColors')) || [];
		const customColorObjects = customColors.map((color) => {
			return {title: color.hex, color};
		});
		const colors = [...customColorObjects, ...defaultColorObjects];
		// Devolvermos un máximo de 4 colores, dando prioridad a los colores personalizados
		return colors.slice(0, 4);
	} catch (error) {
		console.error('Error en getCustomColors', error);
		return defaultColorObjects;
	}
};

const ColorButton = ({format}) => {
	const editor = useSlate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [prevColor, setPrevColor] = React.useState(null);
	// const [prevSelection, setPrevSelection] = React.useState(null);
	const [colorChanged, setColorChanged] = React.useState(false);

	const {getMark, setMark} = editor.helpers;

	const color = getMark(editor, format);
	const isActive = !!color;

	const {highlightColor, interactionColor, color1, color2, color3, color4} =
		entityManager.getCustomStyles();

	const customColors = getCustomColors();
	const colors = [
		{
			title: t`Color de interacción`,
			color: getColorObject(interactionColor, 1, '--interactionColor'),
		},
		{title: t`Color de destacado`, color: getColorObject(highlightColor, 1, '--highlightColor')},
		{title: t`Color 1`, color: getColorObject(color1, 1, '--color1')},
		{title: t`Color 2`, color: getColorObject(color2, 1, '--color2')},
		{title: t`Color 3`, color: getColorObject(color3, 1, '--color3')},
		{title: t`Color 4`, color: getColorObject(color4, 1, '--color4')},
		{title: '#FFFFFF', color: getColorObject('#FFFFFF')},
		{title: '#000000', color: getColorObject('#000000')},
		...customColors,
	];
	const colorSelectors = [
		// Quita el color
		<Box
			key={'reset'}
			className={`color-circle reset-color ${!color ? 'disabled' : ''}`}
			onClick={() => {
				setMark(editor, format, null);
			}}
		>
			<FormatColorResetIcon />
		</Box>,
	];
	colorSelectors.push(
		...colors.map((customColor) => {
			return (
				<Box
					key={customColor.title}
					title={customColor.title}
					className={`color-circle ${
						customColor.color?.hex === (color?.hex || false) ? 'active' : ''
					}`}
					sx={{
						backgroundColor: customColor.color.hex,
					}}
					onClick={() => {
						setMark(editor, format, customColor.color);
						setColorChanged(true);
					}}
				/>
			);
		}),
	);

	const onAccept = () => {
		setAnchorEl(null);
		if (colorChanged) {
			checkColor(colors, color);
		}

		// if (prevSelection) {
		// 	Transforms.deselect(editor);
		// 	Transforms.select(editor, prevSelection);
		// }
	};

	const onCancel = () => {
		setAnchorEl(null);
		setMark(editor, format, prevColor);

		// if (prevSelection) {
		// 	Transforms.deselect(editor);
		// 	Transforms.select(editor, prevSelection);
		// }
	};

	return (
		<>
			<ToggleButton
				format={format}
				onClick={(event) => {
					event.preventDefault();
					event.stopPropagation();
					if (anchorEl) {
						setAnchorEl(null);
						return;
					}

					setAnchorEl(event.currentTarget);
					setPrevColor(color);
					// setPrevSelection(editor.selection);
				}}
				selected={isActive}
				sx={{
					'&.Mui-selected': {
						color: color?.hex || 'inherit',
					},
				}}
			>
				<Icon format={format} />
			</ToggleButton>
			<DialogMui
				onClose={onAccept}
				open={Boolean(anchorEl)}
				title={t`Selección de color`}
				PaperComponent={PaperComponent}
				aria-labelledby="draggable-dialog-title"
			>
				<DialogContent
					sx={{
						'width': 300,
						'padding': 0,
						'& .color-selector .chrome-picker': {
							borderBottom: 'none',
						},
					}}
				>
					<ColorSelector
						onSelectColor={(color) => {
							setMark(editor, format, color);
							setColorChanged(true);
						}}
						color={color?.rgb || false}
						fullColor={true}
					/>
					{/* Listado con círculos de colores predeterminados para seleccionar color de manera rápida */}
					<Box
						sx={{
							'display': 'flex',
							'flexWrap': 'wrap',
							'justifyContent': 'center',
							'padding': '0 10px',

							'& .color-circle': {
								'width': '30px',
								'height': '30px',
								'borderRadius': '50%',
								'margin': '5px',
								'cursor': 'pointer',
								'boxShadow': '0 0 0 1px rgba(0,0,0,.1)',
								'&:hover': {
									boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
								},
								'&.active': {
									boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
								},
								'&.reset-color': {
									'boxShadow': 'none',

									'backgroundColor': 'transparent',
									'display': 'flex',
									'alignItems': 'center',
									'justifyContent': 'center',
									'color': '#a4a4a4',
									'&:hover': {
										boxShadow: 'none',
										transform: 'scale(1.1)',
										color: '#000',
									},

									'&.disabled': {
										'cursor': 'not-allowed',
										'boxShadow': 'none',
										'opacity': 0.5,
										'&:hover': {
											boxShadow: 'none',
											transform: 'none',
											color: '#a4a4a4',
										},
									},
								},
							},
						}}
					>
						{colorSelectors}
					</Box>
				</DialogContent>
				<DialogActions
					sx={{
						justifyContent: 'center',
						paddingTop: 2,
						paddingBottom: 2,
					}}
				>
					<Button onClick={onCancel} className="btn-comp" color="secondary" variant="contained">
						{t`Cancelar`}
					</Button>
					<Button
						onClick={onAccept}
						className="btn-comp"
						// disabled={disableButton}
						color="primary"
						variant="contained"
					>
						{t`Aceptar`}
					</Button>
				</DialogActions>
			</DialogMui>
		</>
	);
};

export default ColorButton;
