import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import Icon from 'ui/shared/Icon';
import cloneDeep from 'lodash/cloneDeep';
import {getDefaultValue} from '../editionBricks';
import {generateId} from 'businessLogic/helpers/functions';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import get from 'lodash/get';
import './styles.scss';

class Repeater extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		const defaultValue = [];
		const repeaterSize = editionBrickStruct.defaultNumber || editionBrickStruct.min || 0;
		const newObject = {};

		editionBrickStruct.content.forEach((element) => {
			newObject[element.fieldName] = getDefaultValue(element);
		});

		//Con esto evitamos que cada elemento del repeater comparta referencia
		for (let i = 0; i < repeaterSize; i++) {
			const objectCopy = cloneDeep(newObject);
			objectCopy._id = generateId();
			defaultValue.push(objectCopy);
		}

		return defaultValue;
	};
	/***Función para eliminar un elemento del repeater ***/
	handleDelete = (i) => {
		const blocksData = cloneDeep(this.props.getMainField());
		blocksData.splice(i, 1);
		this.props.setMainField(blocksData);
	};

	/***Función para añadir un elemento del repeater ***/
	handleAdd = () => {
		const myObjectDefaultData = {
			_id: generateId(),
		};
		for (let i = 0; i < this.props.editionBrickStruct.content.length; i++) {
			myObjectDefaultData[this.props.editionBrickStruct.content[i].fieldName] = getDefaultValue(
				this.props.editionBrickStruct.content[i],
			);
		}

		const blocksData = cloneDeep(this.props.getMainField());
		blocksData.push(myObjectDefaultData);
		this.props.setMainField(blocksData);
	};

	/***Función mover hacia arriba un elemento del repeater ***/
	handleUp = (i) => {
		const blocksData = cloneDeep(this.props.getMainField());
		let item = blocksData[i];
		if (i >= 1) {
			blocksData.splice(i, 1);
			blocksData.splice(i - 1, 0, item);
			this.setState({up: true});
		} else {
			this.setState({up: false});
		}
		this.props.setMainField(blocksData);
	};

	/***Función mover hacia abajo un elemento del repeater ***/
	handleDown = (i) => {
		const blocksData = cloneDeep(this.props.getMainField());
		let item = blocksData[i];
		if (i <= this.props.getMainField().length) {
			blocksData.splice(i, 1);
			blocksData.splice(i + 1, 0, item);
			this.setState({down: true});
		} else {
			this.setState({down: false});
		}
		this.props.setMainField(blocksData);
	};

	componentDidMount() {
		const mainField = cloneDeep(this.props.getMainField());
		let updateIds = false;
		for (let i = 0; i < mainField.length; i++) {
			if (!mainField[i]._id) {
				updateIds = true;
				mainField[i]._id = generateId();
			}
		}

		if (updateIds) {
			this.props.setMainField(mainField);
		}
	}

	render() {
		const blocks = [];
		const mainField = this.props.getMainField();
		if (mainField !== undefined) {
			for (let i = 0; i < mainField.length; i++) {
				const element = mainField[i];
				blocks.push(
					<div key={element._id || i} className="edition-brick-repeater__block">
						<h4 className="edition-brick-repeater__block-title">
							{this.props.editionBrickStruct.blockTitle + ' ' + (i + 1)}
						</h4>
						{this.props.renderChildren(this.props.editionBrickStruct.content, i)}
						<div className="item-options">
							{mainField.length > get(this.props, 'editionBrickStruct.min', 0) && (
								<div
									className={'btn btn-delete-item'}
									onClick={() => {
										this.handleDelete(i);
									}}
								>
									<Icon name="delete" size="small" />
								</div>
							)}
							{i > 0 && (
								<div
									className="btn btn-arrow-up"
									onClick={() => {
										this.handleUp(i);
									}}
								>
									<Icon name="arrow-up" size="small" />
								</div>
							)}
							{i + 1 !== mainField.length && (
								<div
									className="btn btn-arrow-down"
									onClick={() => {
										this.handleDown(i);
									}}
								>
									<Icon name="arrow-down" size="small" />
								</div>
							)}
						</div>
					</div>,
				);
			}
		}

		return (
			<div className="edition-brick-repeater">
				{blocks}
				{((mainField !== undefined && mainField.length < this.props.editionBrickStruct.max) ||
					this.props.editionBrickStruct.max === undefined) && (
					<div className="add-item">
						<div className="btn-add-item" onClick={this.handleAdd}>
							<Icon name="add-round" size="large" />
							<span>{t`Añadir elemento`}</span>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default editionBrickWrapper(Repeater);
