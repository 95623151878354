import {locationChange} from 'businessLogic/store/data/router';
import get from 'lodash/get';

export function startListener(history, store) {
	//dispatch encargado de lanzar un dispatch y almacenar la URL
	// la primera vez que se accede a la web
	store.dispatch(
		locationChange({
			pathname: history.location.pathname,
			search: history.location.search,
			hash: history.location.hash,
		}),
	);
	// listen se encarga de escuchar el cambio de url y lanzar un dispatch
	// que modifique el store y guarde la url nueva
	// history.listen(({action, location}) => {
	history.listen(({location}) => {
		store.dispatch(
			locationChange({
				pathname: location.pathname,
				search: location.search,
				hash: location.hash,
				omitRender: get(location, 'state.omitRender'),
			}),
		);
	});
}
