import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

class Button extends PureComponent {
	render() {
		const {
			className,
			disabled,
			wide,
			rounded,
			view,
			size,
			theme,
			title,
			dataCypress,
			onClick,
		} = this.props;
		const classes = classNames({
			'btn-comp': true,
			'btn-comp--wide': wide,
			'btn-comp--rounded': rounded,
			[`btn-comp--${size}`]: true,
			[`btn-comp--${view}`]: true,
			[`btn-comp--${theme}`]: true,
			[`${className}`]: true,
			'btn-comp--disabled': disabled,
		});

		return (
			<div
				ref="router"
				className={classes}
				onClick={() => {
					if (!disabled && onClick) {
						onClick();
					}
				}}
				title={title}
				data-cypress={dataCypress}
			>
				{this.props.children}
			</div>
		);
	}
}

Button.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	wide: PropTypes.bool,
	rounded: PropTypes.bool,
	view: PropTypes.oneOf(['button', 'link', 'outline', 'next']),
	size: PropTypes.oneOf(['small', 'normal', 'large']),
	theme: PropTypes.oneOf(['default', 'primary', 'success', 'danger', 'info', 'warning', 'link']),
	onClick: PropTypes.func,
	title: PropTypes.string,
};

Button.defaultProps = {
	disabled: false,
	wide: false,
	rounded: false,
	view: 'button',
	size: 'normal',
	theme: 'default',
	className: '',
};

export default Button;
