import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';

import './styles.scss';

class ButtonInTextBar extends PureComponent {
	render() {
		const {className, iconName, iconSize, active} = this.props;
		const classes = classNames({
			'text-bar-button': true,
			'text-bar-button--active': active,
			[`${className}`]: true
		});
		return (
			<div className={classes}>
				<Icon name={iconName} size={iconSize} />
			</div>
		);
	}
}
ButtonInTextBar.propTypes = {
	className: PropTypes.string,
	iconName: PropTypes.string.isRequired,
	iconSize: PropTypes.string
};

ButtonInTextBar.defaultProps = {
	className: '',
	iconName: '',
	iconSize: 'small',
	active: false
};
export default ButtonInTextBar;
