import React, {PureComponent} from 'react';
import LabelForInput from 'businessLogic/shared/Input/LabelForInput';
import Input from 'ui/shared/FormFields/Input';
import Dialog from 'businessLogic/shared/Modals/Dialog';
import ImageSelectorDialog from 'businessLogic/shared/MediaSelector/ImageSelectorDialog';
import EditImageBox from 'businessLogic/shared/Image/EditImageBox';
import TextArea from 'businessLogic/shared/Input/TextArea';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class WikiDialog extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			wikiVisible: false,
			wikiVisibleText: '',
			wikiText: '',
			wikiImg: '',
			wikiImgCredits: '',
			index: 0,
			length: 0,
			formats: {},
		};
	}
	showDialog = (visibleText, attributes, index, length, formatWithoutWiki) => {
		this.setState({
			wikiVisible: true,
			wikiVisibleText: visibleText,
		});
		if (attributes) {
			this.setState({
				wikiText: attributes.text,
				wikiImg: attributes.image,
				wikiImgCredits: attributes.imgCredits,
			});
		} else {
			this.setState({
				wikiText: '',
				wikiImg: '',
				wikiImgCredits: '',
			});
		}
		this.info = {};
		if (index && length) {
			this.info = {
				index: index,
				length: length,
			};
		}
		if (formatWithoutWiki) {
			this.info = {
				...this.info,
				formats: formatWithoutWiki,
			};
		}
	};
	closeDialog = () => {
		const wikiText = this.state.wikiText;
		const textClean =
			(wikiText.deltaValue &&
				wikiText.deltaValue.ops &&
				wikiText.deltaValue.ops[0] &&
				wikiText.deltaValue.ops[0].insert) ||
			wikiText ||
			'';

		const data = {
			visibleText: this.state.wikiVisibleText,
			text: textClean,
			img: this.state.wikiImg,
			imgCredits: this.state.wikiImgCredits,
		};

		this.props.onUpdate(data, this.info.index, this.info.length, this.info.formats);
		this.setState({wikiVisible: false});
	};
	onChangeInputVisibleText = (event) => {
		this.setState({wikiVisibleText: event.target.value});
	};
	onChangeInputImgCredits = (event) => {
		this.setState({wikiImgCredits: event.target.value});
	};
	onChangeTextEditor = (text) => {
		this.setState({wikiText: text});
	};
	onChangeTextArea = (event) => {
		this.setState({wikiText: event.target.value});
	};
	onClickReplaceImage = () => {
		this.refs.selectorDialog.show();
	};
	replaceImage = (newImageUrl) => {
		this.setState({
			wikiImg: newImageUrl,
		});
	};
	render() {
		return (
			<React.Fragment>
				{this.state.wikiVisible && (
					<Dialog
						size="normal"
						title={t`Información adicional`}
						visible={true}
						onCancel={this.closeDialog}
						mask={true}
						noDraggable={true}
						zIndex={this.props.zIndex || 2}
						footer={true}
					>
						<div className="wiki-edit-dialog">
							<div className="wiki-edit-dialog__part--left">
								<div className="wiki-edit-dialog__part--left__title">{t`Amplia la información`}</div>
								<div className="wiki-edit-dialog__part--left__resume-text">
									{t`Introduce el texto que quieres mostrar al pasar el ratón por encima del elemento Wiki`}
								</div>
								<LabelForInput text={t`Texto visible`} />
								<Input
									name="text-button"
									type="text"
									placeholder={t`Añade un texto`}
									value={this.state.wikiVisibleText}
									refs="inputVisibleText"
									onChange={this.onChangeInputVisibleText}
								/>
								<LabelForInput text={t`Texto que quieres mostrar`} />

								<TextArea value={this.state.wikiText} onChange={this.onChangeTextArea} rows="5" />
							</div>
							<div className="wiki-edit-dialog__part--right">
								<EditImageBox
									srcImage={this.state.wikiImg}
									onReplace={this.onClickReplaceImage}
									onClickMainButton={() => {
										alert(t`Editar imagen`);
									}}
								/>
								<LabelForInput text={t`Agregar un crédito`} />
								<Input
									name="text-button"
									type="text"
									placeholder={t`Crédito de la imagen`}
									value={this.state.wikiImgCredits}
									refs="inputImgCredits"
									onChange={this.onChangeInputImgCredits}
								/>
							</div>
						</div>
					</Dialog>
				)}
				<ImageSelectorDialog
					ref="selectorDialog"
					onSelect={this.replaceImage}
					zIndex={this.props.zIndex + 1 || 2.1}
				/>
			</React.Fragment>
		);
	}
}
export default WikiDialog;
