import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Icon from 'ui/shared/Icon';
import {imageUrl} from 'businessLogic/helpers/images';
import classNames from 'classnames';
import './styles.scss';

class PopUpHotSpot extends PureComponent {
	render() {
		const {data, index, popUpDirectionDown, popUpDirectionUp, device, onClose} = this.props;
		let classPopUp = classNames({
			'pop-up-hotspot__down': popUpDirectionDown,
			'pop-up-hotspot__up': popUpDirectionUp,
		});
		return (
			<div className={classPopUp + ' pop-up-hotspot__' + device + '__content'}>
				{window.innerWidth < 768 && (
					<div className="pop-up-hotspot__icon_delete" onClick={onClose}>
						<Icon name="close-cross" size="small" />
					</div>
				)}
				<div className="pop-up-hotspot__content">
					{data.imageObj.imageSrc && (
						<div
							className="pop-up-hotspot__content__image"
							style={{backgroundImage: `url('${imageUrl(data.imageObj.imageSrc)}')`}}
						/>
					)}
					{data.imageObj.credit && (
						<span className="pop-up-hotspot__content__credit">{data.imageObj.credit}</span>
					)}
					{data.title.textValue !== '' && (
						<FrontEditableText
							className="pop-up-hotspot__content__title"
							fieldName="title"
							text={data.title}
							editable={false}
							set={(fieldPath, fieldData) => {
								//Propagamos la llamada al método set añadiendo mi nodo al path
								if (this.props.set)
									this.props.set(['hotSpots', index].concat(fieldPath), fieldData);
							}}
						/>
					)}
					{data.text.textValue !== '' && (
						<FrontEditableText
							className="pop-up-hotspot__content__text"
							fieldName="text"
							text={data.text}
							editable={false}
							set={(fieldPath, fieldData) => {
								//Propagamos la llamada al método set añadiendo mi nodo al path
								if (this.props.set)
									this.props.set(['hotSpots', index].concat(fieldPath), fieldData);
							}}
						/>
					)}
				</div>
				{window.innerWidth >= 768 && (
					<div
						className={
							this.props.popUpDirectionDown
								? 'pop-up-hotspot__up__triangle'
								: 'pop-up-hotspot__down__triangle'
						}
					/>
				)}
			</div>
		);
	}
}

PopUpHotSpot.propTypes = {
	data: PropTypes.object,
	index: PropTypes.number,
	popUpDirectionDown: PropTypes.bool,
	popUpDirectionUp: PropTypes.bool,
	device: PropTypes.string,
	onClose: PropTypes.func,
};

PopUpHotSpot.defaultProps = {
	data: {},
	index: 0,
	popUpDirectionDown: false,
	popUpDirectionUp: false,
	device: '',
};

export default PopUpHotSpot;
