import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Configuración`,
				fieldName: 'data',
				content: [
					{
						type: 'Switch',
						fieldName: 'animation',
						title: t`Añadir animación de pulso al botón`,
						defaultValue: true,
					},
					{
						type: 'Number',
						fieldName: 'scrollSpeed',
						title: t`Velocidad del Play scroll`,
						placeholder: t`Aumenta o disminuye la velocidad de la animación`,
						min: 1,
						// max: 10,
					},
				],
			},
		],
	},
];

export default editionFields;
