import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Repeater',
						min: 3,
						max: 3,
						fieldName: 'images',
						blockTitle: t`Imagen`,
						content: [
							{
								type: 'Image',
								fieldName: 'imageObj',
								defaultImage: '/uploads/default-images/csDefault1Opt.svg',
								defaultCredit: 'Crédito de ejemplo',
							},
						],
					},
				],
			},
		],
	},
];
export default editionFields;
