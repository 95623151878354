import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import AudioBox from 'businessLogic/core/shared/Audio/AudioBox';
import Container from 'businessLogic/core/shared/Container/Container';
import sampleAudioUrl from './SampleAudio.mp3';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import get from 'lodash/get';
import './styles.scss';

class Podcast extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Podcast`;
	static brickImage = brickImage;

	static brickDefaultData = () => ({
		imageObj: {imageSrc: '/uploads/default-images/csDefault1Opt.svg'},
		podcastTitle: 'LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPSCING ELITR',
		podcastDescription:
			'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.',
		participants: [
			{
				imageObj: {imageSrc: '/uploads/default-images/user.png'},
				participantName: _('Participante 1'),
			},
		],
		audio: {
			audioSrc: sampleAudioUrl,
			credit: '',
			description: '',
		},
	});

	static brickDefaultConfig = {};
	render() {
		const classes = classNames({
			'brick': true,
			'podcast-brick': true,
		});

		const data = get(this.props, ['brick', 'data']);
		const styles = get(this.props, ['brick', 'styles'], {});
		const audio = get(data, 'audio', {});
		const imageObj = get(data, 'imageObj');
		const participants = get(data, 'participants');

		return (
			<Container
				size="normal"
				styleConfig={get(styles, 'containerStyle')}
				scrollEffect={get(styles, 'scrollEffect')}
			>
				<div className={classes}>
					<div className="podcast-brick__content">
						<div className="podcast-brick__content__img">
							<NewImageClickable imgObj={imageObj} clickable={false} defaultVisible={true} />
						</div>
						<div className="podcast-brick__content__info">
							<div className="podcast-brick__content__info__top">
								<FrontEditableText
									fieldName="podcastTitle"
									text={data.podcastTitle}
									editable={true}
									set={this.props.set}
									className="podcast-brick__content__info__top__title"
								/>
								<FrontEditableText
									fieldName="podcastDescription"
									text={data.podcastDescription}
									editable={true}
									set={this.props.set}
									className="podcast-brick__content__info__top__description"
								/>
							</div>
							<ul className="podcast-brick__content__info__bottom">
								{participants.map((participant, index) => {
									return (
										<li className="podcast-brick__content__info__bottom__participant" key={index}>
											<div className="podcast-brick__content__info__bottom__participant__img">
												<NewImageClickable
													imgObj={participant.imageObj}
													clickable={false}
													defaultVisible={true}
												/>
											</div>
											<FrontEditableText
												fieldName="participantName"
												text={get(participant, 'participantName')}
												className="podcast-brick__content__info__bottom__participant__name"
												set={(fieldPath, fieldData) => {
													//Propagamos la llamada al método set añadiendo mi nodo al path
													if (this.props.set)
														this.props.set(['participants', index].concat(fieldPath), fieldData);
												}}
											/>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
					<AudioBox
						src={audio.audioSrc}
						loop={data.loop}
						theme="podcast"
						onPlay={() => {
							this.props.configObject.stopBackgroundAudio();
							this.props.brickEvent('play');
						}}
						onPause={() => {
							this.props.brickEvent('pause');
						}}
						onEnd={() => {
							this.props.brickEvent('end');
						}}
					/>
					<div> {audio.description} </div>
					<div> {audio.credit} </div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(Podcast);
