import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Image',
						title: t`Imagen`,
						fieldName: 'image',
					},
					{
						type: 'Text',
						fieldName: 'textLinkInstructions',
						title: t`Texto del enlace de instrucciones`,
						placeholder: t`Añade texto para el enlace de intrucciones de tu encuesta`,
					},
					{
						type: 'TextArea',
						fieldName: 'instructions',
						title: t`Instrucciones`,
						placeholder: t`Añade un texto de ayuda para tus usuarios, si lo consideras necesario`,
						rows: 2,
					},
					{
						type: 'TextEditor',
						fieldName: 'beforeHeading',
						title: t`Título`,
						placeholder: t`Introduce un título`,
					},
					{
						type: 'TextEditor',
						fieldName: 'title',
						title: t`Texto`,
						placeholder: t`Introduce un texto`,
						rows: 2,
					},
					{
						type: 'Text',
						fieldName: 'textButtonStart',
						title: t`Texto del botón de inicio`,
						placeholder: t`Introduce el texto del botón de inicio`,
					},
					{
						type: 'RepeaterFlexContent',
						fieldName: 'flexibleContent',
						blockTitle: t`Contenido`,
						content: [
							{
								label: t`Orden de preferencia`,
								flexibleContentType: 'OrderPreferenceQuestion',
								deprecated: true,
								content: [
									{
										type: 'TextEditor',
										fieldName: 'statement',
										title: t`Pregunta`,
										placeholder: t`Introduce una pregunta`,
									},
									{
										type: 'Number',
										fieldName: 'maxOptionsResponses',
										title: t`Máximo de opciones seleccionadas`,
										instructions: t`Rellena este campo si quieres configurar el numéro de máximo de respuestas que puede seleccinar un usuario`,
										defaultValue: 4,
										min: 1,
										max: 15,
									},
									{
										type: 'Number',
										fieldName: 'minOptionsResponses',
										title: t`Mínimo de opciones seleccionadas`,
										instructions: t`Rellena este campo si quieres configurar el numéro de mínimo de respuestas que puede seleccinar un usuario`,
										min: 1,
										defaultValue: 1,
									},
									{
										type: 'Repeater',
										min: 2,
										max: 15,
										defaultNumber: 4,
										fieldName: 'options',
										blockTitle: t`Opción`,
										content: [
											{
												type: 'Text',
												fieldName: 'optionTag',
												title: t`Texto de la respuesta`,
												placeholder: t`Introduce el texto de la respuesta`,
											},
										],
									},
								],
							},
							{
								label: t`Orden de preferencia`,
								flexibleContentType: 'OrderPreferenceQuestion2',
								content: [
									{
										type: 'TextEditor',
										fieldName: 'statement',
										title: t`Pregunta`,
										placeholder: t`Introduce una pregunta`,
									},
									{
										type: 'Number',
										fieldName: 'maxOptionsResponses',
										title: t`Máximo de opciones seleccionadas`,
										instructions: t`Rellena este campo si quieres configurar el numéro de máximo de respuestas que puede seleccinar un usuario`,
										defaultValue: 4,
										min: 1,
										max: 15,
									},
									{
										type: 'Number',
										fieldName: 'minOptionsResponses',
										title: t`Mínimo de opciones seleccionadas`,
										instructions: t`Rellena este campo si quieres configurar el numéro de mínimo de respuestas que puede seleccinar un usuario`,
										min: 1,
										defaultValue: 1,
									},
									{
										type: 'Repeater',
										min: 2,
										max: 15,
										defaultNumber: 4,
										fieldName: 'options',
										blockTitle: t`Opción`,
										content: [
											{
												type: 'Text',
												fieldName: 'optionTag',
												title: t`Texto de la respuesta`,
												placeholder: t`Introduce el texto de la respuesta`,
											},
										],
									},
								],
							},
							{
								label: t`Selecciona`,
								flexibleContentType: 'SelectedChoice',
								deprecated: true,
								content: [
									{
										type: 'TextEditor',
										fieldName: 'statement',
										title: t`Pregunta`,
										placeholder: t`Introduce una pregunta`,
									},
									{
										type: 'Number',
										fieldName: 'maxOptionsResponses',
										title: t`Máximo de opciones seleccionadas`,
										instructions: t`Rellena este campo si quieres configurar el numéro de máximo de respuestas que puede seleccinar un usuario`,
										min: 1,
										max: 20,
										defaultValue: 4,
									},
									{
										type: 'Number',
										fieldName: 'minOptionsResponses',
										title: t`Mínimo de opciones seleccionadas`,
										instructions: t`Rellena este campo si quieres configurar el numéro de mínimo de respuestas que puede seleccinar un usuario`,
										min: 1,
										max: 20,
										defaultValue: 1,
									},
									{
										type: 'Repeater',
										min: 2,
										max: 20,
										defaultNumber: 4,
										fieldName: 'options',
										blockTitle: t`Opción`,
										content: [
											{
												type: 'Text',
												fieldName: 'optionTag',
												title: t`Texto de la respuesta`,
												placeholder: t`Introduce el texto de la respuesta`,
											},
										],
									},
								],
							},
							{
								label: t`Selecciona`,
								flexibleContentType: 'SelectedChoice2',
								content: [
									{
										type: 'TextEditor',
										fieldName: 'statement',
										title: t`Pregunta`,
										placeholder: t`Introduce una pregunta`,
									},
									{
										type: 'Number',
										fieldName: 'maxOptionsResponses',
										title: t`Máximo de opciones seleccionadas`,
										instructions: t`Rellena este campo si quieres configurar el numéro de máximo de respuestas que puede seleccinar un usuario`,
										min: 1,
										max: 20,
										defaultValue: 4,
									},
									{
										type: 'Number',
										fieldName: 'minOptionsResponses',
										title: t`Mínimo de opciones seleccionadas`,
										instructions: t`Rellena este campo si quieres configurar el numéro de mínimo de respuestas que puede seleccinar un usuario`,
										min: 1,
										max: 20,
										defaultValue: 1,
									},
									{
										type: 'Repeater',
										min: 2,
										max: 20,
										defaultNumber: 4,
										fieldName: 'options',
										blockTitle: t`Opción`,
										content: [
											{
												type: 'Text',
												fieldName: 'optionTag',
												title: t`Texto de la respuesta`,
												placeholder: t`Introduce el texto de la respuesta`,
											},
										],
									},
								],
							},
							{
								label: t`Escala numérica`,
								flexibleContentType: 'NumericSliderQuestion',
								content: [
									{
										type: 'TextEditor',
										fieldName: 'statement',
										title: t`Pregunta`,
										placeholder: t`Introduce una pregunta`,
									},
									{
										type: 'Number',
										fieldName: 'min',
										title: t`Valor Mínimo`,
										defaultValue: 50,
										min: 0,
									},
									{
										type: 'Number',
										fieldName: 'max',
										title: t`Valor Máximo`,
										defaultValue: 100,
										min: 0,
									},
									{
										type: 'Number',
										fieldName: 'scale',
										title: t`Intervalo`,
										defaultValue: 10,
										min: 0,
									},
								],
							},
							{
								label: t`Valoración`,
								flexibleContentType: 'AssessmentQuestion',
								// deprecated: true,
								content: [
									{
										type: 'TextEditor',
										fieldName: 'statement',
										title: t`Pregunta`,
										placeholder: t`Introduce una pregunta`,
									},
									{
										type: 'Number',
										fieldName: 'min',
										title: t`Valor Mínimo`,
										defaultValue: 1,
										min: 0,
									},
									{
										type: 'Number',
										fieldName: 'max',
										title: t`Valor Máximo`,
										defaultValue: 10,
										min: 0,
										max: 10,
									},
									{
										type: 'Text',
										fieldName: 'minLabel',
										title: t`Etiqueta del valor mínimo`,
										// placeholder: t`Pobre`,
									},
									{
										type: 'Text',
										fieldName: 'maxLabel',
										title: t`Etiqueta del valor máximo`,
										// placeholder: t`Excelente`,
									},
								],
							},
							{
								label: t`Valoración gráfica`,
								flexibleContentType: 'VisualAssessmentQuestion',
								// deprecated: true,
								content: [
									{
										type: 'TextEditor',
										fieldName: 'statement',
										title: t`Pregunta`,
										placeholder: t`Introduce una pregunta`,
									},
									{
										type: 'Switch',
										fieldName: 'reduced',
										title: t`Versión reducida`,
									},
								],
							},
							{
								label: t`Pregunta abierta`,
								flexibleContentType: 'OpenFieldQuestion',
								content: [
									{
										type: 'TextEditor',
										fieldName: 'statement',
										title: t`Pregunta`,
										placeholder: t`Introduce una pregunta`,
									},
									{
										type: 'TextEditor',
										fieldName: 'description',
										title: t`Descripción`,
										placeholder: t`Introduce una pregunta`,
									},
								],
							},
						],
						commonContent: [
							{
								type: 'Text',
								fieldName: 'name',
								title: t`Nombre del Contenido`,
								placeholder: t`Introduce el nombre de este contenido`,

								instructions: t`Este nombre no será visible para los usuarios que contesten a tu encuesta. Es el nombre con el que posteriormente identificarás tu pregunta cuando descargues tu archivo de respuestas`,
							},
							{
								type: 'Switch',
								fieldName: 'required',
								title: t`¿Es obligatorio?`,
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Información`,
				fieldName: 'config',
				content: [
					{
						type: 'Switch',
						fieldName: 'requiredActions',
						title: t`Si está incluido en un curso, ¿es obligatorio contestar para completar la lección?`,
						instructions: t`Si activas esta opción, la lección no se completará hasta que el alumno conteste a las preguntas`,
						defaultValue: false,
					},
					{
						type: 'Switch',
						fieldName: 'includeUserEmail',
						title: t`Incluir el email del usuario en caso de estar identificado en la plataforma`,
						instructions: t`Si activas esta opción, la encuesta dejará de ser anónima y cada respuesta quedará asociada al mail del usuario identificado.`,
						defaultValue: false,
					},
					{
						type: 'TextArea',
						fieldName: 'brickName',
						title: t`Nombre de la encuesta`,
						placeholder: t`Introduce un titulo para la encuesta`,
						instructions: t`El nombre de la encuesta (no visible para el usuario) te ayudará a identificarla en el panel de administración.`,
						rows: 1,
					},
					{
						type: 'TextArea',
						fieldName: 'brickDescription',
						title: t`Descripción del test`,
						placeholder: t`Introduce una descripción para la encuesta`,
						instructions: t`La descripción de la encuesta (no visible para el usuario) te ayudará a identificarla en el panel de administración.`,
						rows: 4,
					},
				],
			},

			{
				type: 'Tab',
				title: t`Respuestas`,
				fieldName: 'data',
				content: [
					{
						type: 'Switch',
						fieldName: 'isClosed',
						title: t`¿Deseas cerrar la encuesta?`,
						instructions: t`Si activas esta opción no recibiras mas respuestas`,
					},
					{
						type: 'CsvDownload',
						title: t`Descargar los resultados de esta encuesta`,
						csvFileName: 'resultado-encuesta.csv',
					},
				],
			},
		],
	},
];

export default editionFields;
