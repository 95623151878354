import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import './styles.scss';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';

class ListOrderedWithImgLine extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Lista numerada con imágenes - estilo 2`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		listItems: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
				},
				title: 'Micias nihilia suis cullita turi labo',
				text: 'Whether it’s content related to your industry or updates on what your business is doing, stay in touch with your audience with Facebook posts. Use short, fun-to-read copy and eye-catching images to get attention. You can even schedule your posts to save time.',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
				},
				title: 'Micias nihilia suis cullita turi labo',
				text: 'Whether it’s content related to your industry or updates on what your business is doing, stay in touch with your audience with Facebook posts. Use short, fun-to-read copy and eye-catching images to get attention. You can even schedule your posts to save time.',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
				},
				title: 'Micias nihilia suis cullita turi labo',
				text: 'Whether it’s content related to your industry or updates on what your business is doing, stay in touch with your audience with Facebook posts. Use short, fun-to-read copy and eye-catching images to get attention. You can even schedule your posts to save time.',
			},
		],
	};

	static brickDefaultConfig = {startNumber: 1};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}
	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick?.styles ?? {};
		const config = this.props.brick?.config ?? {};
		let items = data.listItems || [];

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ListOrderedWithImgLine'], []);
		const classes = classNames({
			'list-ordered-with-img-line-brick': true,
		});

		const baseNumber = parseInt(config?.startNumber || 1);

		let elements = items.map((listItem, index) => {
			const currentNumber = index + baseNumber;
			const classEvenOdd = currentNumber % 2 === 0 ? 'even' : 'odd';

			let itemAnimation;
			if (Array.isArray(brickAnimation)) {
				if (classEvenOdd === 'even') {
					itemAnimation = brickAnimation[0];
				} else {
					itemAnimation = brickAnimation[1];
				}
			} else {
				itemAnimation = brickAnimation;
			}
			return (
				<li key={index} className={classes + '__ul__li ' + classEvenOdd}>
					<div className="wrap-img">
						<div className="wrap-img__inner">
							<Animation
								playAnimation={this.state.playEffect}
								delay={(index + 1) * 0.5}
								effect={itemAnimation}
							>
								<NewImageClickable imgObj={listItem.imageObj} showCaption={true} />
							</Animation>
						</div>
					</div>
					<div className="wrap-content">
						<div className="wrap-content__title">
							<FrontEditableText
								fieldName="title"
								text={listItem.title}
								set={(fieldPath, fieldData) => {
									//Propagamos la llamada al método set añadiendo mi nodo al path
									if (this.props.set)
										this.props.set(['listItems', index].concat(fieldPath), fieldData);
								}}
							/>
							<span className="wrap-content__title__span">{currentNumber}</span>
						</div>
						<div className="wrap-content__text">
							<FrontEditableText
								fieldName="text"
								text={listItem.text}
								set={(fieldPath, fieldData) => {
									//Propagamos la llamada al método set añadiendo mi nodo al path
									if (this.props.set)
										this.props.set(['listItems', index].concat(fieldPath), fieldData);
								}}
							/>
						</div>
					</div>
				</li>
			);
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={'brick ' + classes}>
					<ul className={classes + '__ul'}>{elements}</ul>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ListOrderedWithImgLine);
