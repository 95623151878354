import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import VideoCmp from 'businessLogic/shared/Video/VideoCmp';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class VideoCentered extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Vídeo`;
	static brickImage = brickImage;

	static brickDefaultData = {
		video: {
			videoSrc: 'https://vimeo.com/203448506',
			thumbnailSrc: '/uploads/default-images/csDefault2Opt.svg',
			credit: '',
		},
	};

	static brickDefaultConfig = {};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const classes = classNames({
			'brick': true,
			'video-brick': true,
		});
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'VideoCentered']);

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<Animation playAnimation={this.state.playEffect} delay={1} effect={brickAnimation}>
						<VideoCmp
							videoCaption={data.video.thumbnailSrc}
							credit={data.video.credit}
							videoSrc={data.video.videoSrc}
							onPlay={() => {
								this.props.configObject.stopBackgroundAudio();
								this.props.brickEvent('play');
							}}
							onPause={() => {
								this.props.brickEvent('pause');
							}}
							onEnd={() => {
								this.props.brickEvent('end');
							}}
						/>
					</Animation>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(VideoCentered);
