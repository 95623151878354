import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import { imageUrl } from 'businessLogic/helpers/images';
import './styles.scss';

class ImagesVerticalNavigation extends PureComponent {
	render() {
		const classes = classNames({
			'images-vertical-navigation': true
		});

		const images = this.props.items;
		const currentIndex = this.props.currentIndex;
		const onNextClick = this.props.onNextClick;
		const onPreviousClick = this.props.onPreviousClick;

		return (
			<div className={classes}>
				<div className="float-icon-up" onClick={onPreviousClick}>
					<Icon
						name="navigate-up"
						size="small" //['small', 'normal', 'large'])
					/>
				</div>
				<div
					className="images-vertical-navigation__item first-item"
					style={{
						backgroundImage:
							'url(\'' +
							imageUrl(
								images[currentIndex === 0 ? images.length - 1 : currentIndex - 1].imageObj.imageSrc
							) +
							'\')'
					}}
					onClick={onPreviousClick}
				/>
				<div
					className="images-vertical-navigation__item"
					style={{
						backgroundImage: 'url(\'' + imageUrl(images[currentIndex].imageObj.imageSrc) + '\')'
					}}
				/>
				<div
					className="images-vertical-navigation__item last-item"
					style={{
						backgroundImage:
							'url(\'' +
							imageUrl(images[(currentIndex + 1) % images.length].imageObj.imageSrc) +
							'\')'
					}}
					onClick={onNextClick}
				/>
				<div className="float-icon-down" onClick={onNextClick}>
					<Icon
						name="navigate-down"
						size="small" //['small', 'normal', 'large'])
					/>
				</div>
			</div>
		);
	}
}

ImagesVerticalNavigation.propTypes = {
	items: PropTypes.array,
	currentIndex: PropTypes.number,
	onNextClick: PropTypes.func,
	onPreviousClick: PropTypes.func
};

ImagesVerticalNavigation.defaultProps = {
	items: [],
	currentIndex: 0
};

export default ImagesVerticalNavigation;
