import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Dialog from 'businessLogic/shared/Modals/Dialog';
import LabelForInput from 'businessLogic/shared/Input/LabelForInput';
import Input from 'ui/shared/FormFields/Input';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class VideoUrlModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			videoUrl: '',
		};
	}

	close = () => {
		if (this.props.close) {
			this.props.close();
		}
		return;

		// let regExp;
		// let idLenght;
		// let matchValue;
		// if (this.props.internetPlatform === 'Youtube') {
		// 	regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=|\?v=)([^#?]*).*/;
		// 	idLenght = 11;
		// 	matchValue = 2; //de esta regExp la id esta en 3 lugar
		// } else if (this.props.internetPlatform === 'Vimeo') {
		// 	// eslint-disable-next-line max-len
		// 	regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
		// 	idLenght = 9;
		// 	matchValue = 3; //de esta regExp la id esta en 4 lugar
		// }
		// //check url youtube
		// let url = this.state.videoUrl;
		// if (url !== undefined || url !== '') {
		// 	let match = url.match(regExp);
		// 	if (match && match[matchValue].length === idLenght) {
		// 		//11 son los dígitos de la id
		// 		// Do anything for being valid
		// 		this.props.close();
		// 	} else {
		// 		// Do anything for not being valid
		// 		// alert(
		// 		// 	"Url Incorrecta, o no pertenece la la plataforma seleccionada '" +
		// 		// 		this.props.internetPlatform +
		// 		// 		"', por favor revísela y vuelva a introducirla"
		// 		// );
		// 		this.setState({videoUrl: ''});
		// 		this.props.close();
		// 	}
		// }
	};

	onChangeInputLink = (event) => {
		this.setState({videoUrl: event.target.value});
		// this.props.onChangeInputLink(event.target.value);
	};

	onReady = () => {
		if (this.props.onChangeInputLink) {
			this.props.onChangeInputLink(this.state.videoUrl);
		}
	};

	onKeyDown = (event) => {
		if (event.key === 'Enter') {
			this.onReady();
		}
	};

	render() {
		const {internetPlatform, showDialog} = this.props;
		return (
			<Dialog
				size="small"
				title={t`Vídeo de ${internetPlatform}`}
				visible={showDialog}
				onCancel={this.close}
				onReady={this.onReady}
				noDraggable={this.props.noDraggable}
				mask={this.props.mask}
				zIndex={this.props.zIndex}
			>
				<LabelForInput text={t`Introduce link del vídeo:`} />
				<Input
					name="link-video"
					type="text"
					placeholder={t`Link vídeo`}
					value={this.state.videoUrl}
					onChange={this.onChangeInputLink}
					onKeyDown={this.onKeyDown}
				/>
			</Dialog>
		);
	}
}

VideoUrlModal.propTypes = {
	internetPlatform: PropTypes.string,
	showDialog: PropTypes.bool,
	close: PropTypes.func,
	onChangeInputLink: PropTypes.func,
};

VideoUrlModal.defaultProps = {
	internetPlatform: '',
	showDialog: false,
	close: null,
	onChangeInputLink: null,
};

export default VideoUrlModal;
