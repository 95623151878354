import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import {GET_USER_DATA} from 'businessLogic/store/data/session/graphql/query';
import {SEND_BRICK_CONTACT_FORM} from 'businessLogic/store/data/events/queries';
import {client} from 'businessLogic/store/store';
import {Query} from 'react-apollo';
import Form from './components/Form';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import './styles.scss';

class NewContactForm extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Formulario de contacto`;
	static brickHelpText = () => t`Permite que el usuario se comunique con una dirección de email.`;
	static brickImage = brickImage;
	static brickDefaultData = () => ({
		image: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
		},
		brickIdentifier: _('Formulario de contacto'),
		title: _('¿Tienes alguna pregunta?'),
		subtitle: _('Mándanos todas tus dudas'),
		text: _('Escribe tu comentario'),
		button: _('Enviar'),
		emailUser: _('Introduce tu email'),
		finalText: _('Tu consulta ha sido enviada con éxito. Gracias por contactar con nosotros'),
		emailAdmin: 'contact@example.com',
	});

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
			showLegals: false,
			dataSent: false,
			dataObj: {},
			errors: {},
		};
	}

	setDataObj = (field, value) => {
		this.setState({dataObj: Object.assign({}, this.state.dataObj, {[field]: value})});
	};

	// / Llamamos a la mutación SEND_BRICK_CONTACT_FORM que para enviar el mail
	sendContactFormMutation = async (loggedUserEmail, emailUser, text) => {
		await client.mutate({
			mutation: SEND_BRICK_CONTACT_FORM,
			variables: {
				pillId: this.props.pillId,
				brickId: this.props.brickId,
				data: {
					emailUser: loggedUserEmail ? loggedUserEmail : emailUser,
					text: text,
				},
			},
		});
		this.setState({dataSent: true});
	};

	validate = (loggedUserEmail) => {
		const data = this.state.dataObj;

		let errorObj = {
			emailUserError: '',
			textError: '',
		};

		let regExpMail = /^.*(@).*(\.)/g;
		if (data.emailUser && !data.emailUser.match(regExpMail))
			errorObj.emailUserError = _('El campo email no tiene el formato correcto');
		if (!data.emailUser || data.emailUser === '')
			errorObj.emailUserError = _('El campo email no puede estar vacio');
		if (!data.text || data.text === '')
			errorObj.textError = _('El campo comentario no puede estar vacio');

		if ((data.emailUser || loggedUserEmail) && data.text) {
			this.sendContactFormMutation(loggedUserEmail, data.emailUser, data.text);
		}
		this.setState({errors: errorObj});
	};

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const brickData = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const image = brickData.image;

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'NewContactForm']);
		const classes = classNames({
			'brick': true,
			'new-contact-form': true,
		});
		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div className="new-contact-form__form">
						{!this.state.dataSent && (
							<Query query={GET_USER_DATA}>
								{({loading, error, data}) => {
									if (loading || error) {
										return null;
									}
									const user = data.dataUser || {};
									return (
										<div className="new-contact-form__form__wrapper">
											<Animation
												playAnimation={this.state.playEffect}
												delay={1}
												effect={Array.isArray(brickAnimation) ? brickAnimation[0] : brickAnimation}
											>
												<Form
													data={brickData}
													onShow={this.showLegals}
													onSendData={this.validate}
													setData={this.setDataObj}
													set={this.props.set}
													errorsObj={this.state.errors}
													user={user}
												/>
											</Animation>
										</div>
									);
								}}
							</Query>
						)}
						{this.state.dataSent && (
							<div className="new-contact-form__form__final-text">
								<FrontEditableText
									fieldName="finalText"
									text={brickData.finalText}
									editable={false}
									set={this.props.set}
								/>
							</div>
						)}
					</div>
					<div className="new-contact-form__image">
						<Animation
							playAnimation={this.state.playEffect}
							delay={1.5}
							effect={Array.isArray(brickAnimation) ? brickAnimation[1] : brickAnimation}
						>
							<NewImageClickable imgObj={image} clickable={false} defaultVisible={true} />
						</Animation>
					</div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(NewContactForm);
