import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Image',
						title: t`Imagen`,
						fieldName: 'image',
					},
					{
						type: 'Text',
						fieldName: 'textLinkInstructions',
						title: t`Texto del enlace de instrucciones`,
						placeholder: t`Añade texto para el enlace de intrucciones de tu test`,
					},
					{
						type: 'TextArea',
						fieldName: 'instructions',
						title: t`Instrucciones`,
						placeholder: t`Añade un texto de ayuda para tus usuarios, si lo consideras necesario`,
						rows: 2,
					},
					{
						type: 'TextEditor',
						fieldName: 'beforeHeading',
						title: t`Título`,
						placeholder: t`Introduce un título`,
					},
					{
						type: 'TextEditor',
						fieldName: 'descriptionTest',
						title: t`Descripción`,
						placeholder: t`Instrucciones de funcionamiento del test. Marca las casillas correctas y envía tu respuesta`,
					},
					{
						type: 'Text',
						fieldName: 'textButtonStart',
						title: t`Texto del botón de inicio del test`,
						placeholder: t`Introduce el texto del botón de inicio del test`,
					},
					{
						type: 'Text',
						fieldName: 'textButtonAgain',
						title: t`Texto del botón para repetir el test`,
						placeholder: t`Introduce el texto del botón`,
					},
					{
						type: 'Accordions',
						title: t`Acordeones`,
						items: [
							{
								blockTitle: t`Perfiles`,
								content: [
									{
										type: 'Repeater',
										min: 2,
										max: 4,
										fieldName: 'profiles',
										blockTitle: t`Perfil`,
										content: [
											{
												type: 'TextEditor',
												fieldName: 'profileTitle',
												title: t`Nombre del Perfil`,
												placeholder: t`Introduce el nombre de tu perfil`,
											},
											{
												type: 'Image',
												fieldName: 'profileImageObj',
												defaultImage: '/uploads/default-images/csDefault1Opt.svg',
											},
											{
												type: 'TextEditor',
												fieldName: 'profileDescription',
												title: t`Descripción del perfil`,
												placeholder: t`Introduce una descripción sobre el perfil`,
											},
											{
												type: 'Text',
												fieldName: 'profileTag',
												title: t`Etiqueta del perfil`,
												placeholder: t`Introduce una etiqueta para tu perfil`,
											},
											{
												type: 'ColorAuto',
											},
										],
									},
								],
							},
							{
								blockTitle: t`Preguntas`,
								content: [
									{
										type: 'Repeater',
										min: 1,
										fieldName: 'questions',
										blockTitle: t`Pregunta`,
										content: [
											{
												type: 'TextEditor',
												fieldName: 'question',
												title: t`Pregunta`,
												placeholder: t`Introduce tu pregunta`,
											},
											{
												type: 'Repeater',
												min: 2,
												max: 4,
												defaultNumber: 3,
												fieldName: 'answers',
												blockTitle: t`Opción`,
												content: [
													{
														type: 'TagSelect',
														fieldName: 'tag',
														title: t`Etiquetas de perfiles`,
														repeaterPath: 'data.profiles',
														tagFieldName: 'profileTag',
													},
													{
														type: 'TextEditor',
														fieldName: 'answer',
														title: 'Opción',
														placeholder: t`Introduce el texto de la opción`,
													},
													{
														type: 'Image',
														fieldName: 'answerImageObj',
														defaultImage: '/uploads/default-images/csDefault1Opt.svg',
													},
												],
											},
										],
									},
								],
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Configuración`,
				fieldName: 'config',
				content: [
					{
						type: 'Switch',
						fieldName: 'requiredActions',
						title: t`Si el test está inluida en un curso, ¿Es obligatorio contestar para completar la lección?`,
						instructions: t`Si activas esta opción, la lección no se completará hasta que el alumno conteste a las preguntas`,
						defaultValue: false,
					},
					{
						type: 'Number',
						fieldName: 'attempts',
						title: t`Número máximo de intentos`,
						placeholder: t`Aumenta o disminuye`,
						min: 1,
						max: 100,
					},
					{
						type: 'TextArea',
						fieldName: 'brickName',
						title: t`Nombre del test`,
						placeholder: t`Introduce un titulo para el test`,
						instructions: t`El título del test (no visible para el usuario) te permitirá identificarlo en el panel de estadísticas.`,
						rows: 2,
					},
					{
						type: 'TextArea',
						fieldName: 'brickDescription',
						title: t`Descripción del test`,
						placeholder: t`Introduce una descripción para el test`,
						instructions: t`La descripción del test (no visible para el usuario) te permitirá identificarlo en el panel de estadísticas.`,
						rows: 4,
					},
				],
			},
		],
	},
];
export default editionFields;
