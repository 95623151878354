import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import Icon from 'ui/shared/Icon';
import PropTypes from 'prop-types';
import './styles.scss';

const modalRoot = document.getElementById('portals');

class TabContent extends PureComponent {
	constructor(props) {
		super(props);
		this.containerTab = document.createElement('div');
	}

	componentDidMount() {
		if (modalRoot) modalRoot.appendChild(this.containerTab);
	}
	componentWillUnmount() {
		if (modalRoot && modalRoot.contains(this.containerTab)) {
			modalRoot.removeChild(this.containerTab);
		}
	}

	handleClose = () => {
		this.props.onClose(this.props.index);
	};
	render() {
		const {item, index, activeIndex, type} = this.props;
		return ReactDOM.createPortal(
			<React.Fragment>
				<div
					ref="tabContent"
					// onClick={() => this.changeActiveTab(index)}
					className={'tab-content ' + type + ' ' + (activeIndex === index ? 'active' : '')}
					key={index}
				>
					{index === activeIndex && (
						<React.Fragment>
							<span className="tab-content__close" onClick={this.handleClose}>
								<Icon name="close-modal" size="normal" />
							</span>

							{item.tabContent.map((tabContentItem, tabContentIndex) => (
								<div key={tabContentIndex}>
									{tabContentItem.title && (
										<div className="tab-content__title">
											<FrontEditableText
												fieldName="title"
												text={tabContentItem.title}
												set={(fieldPath, fieldData) => {
													//Propagamos la llamada al método set añadiendo mi nodo al path
													if (this.props.set)
														this.props.set(
															['tabs', index, 'tabContent', tabContentIndex].concat(fieldPath),
															fieldData,
														);
												}}
											/>
										</div>
									)}
									{tabContentItem.text && (
										<div className="tab-content__text">
											<FrontEditableText
												fieldName="text"
												text={tabContentItem.text}
												set={(fieldPath, fieldData) => {
													//Propagamos la llamada al método set añadiendo mi nodo al path
													if (this.props.set)
														this.props.set(
															['tabs', index, 'tabContent', tabContentIndex].concat(fieldPath),
															fieldData,
														);
												}}
											/>
										</div>
									)}
									{tabContentItem.imageObj.imageSrc && (
										<div className="tab-content__image">
											<NewImageClickable imgObj={tabContentItem.imageObj} defaultVisible={true} />
											<span className="tab-content__image__credits">
												{tabContentItem.imageObj.credit}
											</span>
										</div>
									)}
								</div>
							))}
						</React.Fragment>
					)}
				</div>

				<div
					className={'tab-content__overlay' + (activeIndex === index ? ' active ' : '')}
					onClick={this.handleClose}
				/>
			</React.Fragment>,
			this.containerTab,
		);
	}
}

TabContent.propTypes = {
	item: PropTypes.object,
	index: PropTypes.number,
	activeIndex: PropTypes.number,
};

TabContent.defaultProps = {
	item: {},
	index: 0,
	activeIndex: 0,
};

export default TabContent;
