import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

// "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20436.15958588016!2d-3.7170429439631483!3d40.41210059042732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422997800a3c81%3A0xc436dec1618c2269!2sMadrid!5e0!3m2!1ses!2ses!4v1549891866491",
class MapIframe extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Mapa (Google Maps)`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickHelpText = () => t`Permite insertar en la píldora un mapa de Google Maps`;
	static brickDefaultData = {
		videoSrc:
			'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3036.0548896654295!2d-3.6950209846030035!3d40.45192207936107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4228e2d2dd6c8d%3A0x73fe570bae0a8b51!2sAv.%20del%20General%20Per%C3%B3n%2C%2038%2C%2028020%20Madrid!5e0!3m2!1ses!2ses!4v1576769257103!5m2!1ses!2ses',
		videoFooter: '',
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};

	parsetSrcMap = (value) => {
		const correctSource = 'https://www.google.com/maps/embed?'; //mapas de google maps
		const correctSourceOwnMap = 'https://www.google.com/maps/d/embed?'; //los mapas creados por un usuario y compartidos
		if (value) {
			const newValue = value.slice(0);
			if (newValue.includes(correctSource) || newValue.includes(correctSourceOwnMap)) {
				const startSrc = newValue.includes(correctSource)
					? newValue.indexOf(correctSource)
					: newValue.indexOf(correctSourceOwnMap);
				let endSrc;
				if (newValue.indexOf('"') < 0) {
					endSrc = newValue.length;
				} else {
					endSrc = newValue.indexOf('"', startSrc);
				}
				return newValue.slice(startSrc, endSrc);
			} else {
				return '';
			}
		}
		return '';
	};
	render() {
		const data = this.props.brick.data,
			styles = this.props.brick.styles || {},
			video = data.videoSrc || '',
			footer = data.videoFooter || '',
			classes = classNames({
				'brick': true,
				'map-brick': true,
			});

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<figure>
						{video && this.parsetSrcMap(video) ? (
							<iframe
								title="video"
								src={this.parsetSrcMap(video)}
								frameBorder="0"
								allowFullScreen
							/>
						) : (
							_('Este mapa no está bien embebido, por favor vuelve a intentarlo')
						)}
						<figcaption>{footer}</figcaption>
					</figure>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(MapIframe);
