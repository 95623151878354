import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
// import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Helmet} from 'react-helmet';
import {imageUrl} from 'businessLogic/helpers/images';
import get from 'lodash/get';
import './styles.scss';

class PlatformMetas extends PureComponent {
	render() {
		const platform = get(this.props, 'entity', {});

		switch (this.props.context) {
			case 'student': {
				const titleParts = [];
				if (get(platform, 'name')) titleParts.push(get(platform, 'name'));
				if (this.props.suffix) titleParts.push(this.props.suffix);
				const title = titleParts.join(' | ');
				return (
					<Helmet>
						<title>{title}</title>
						{get(platform, 'description') && (
							<meta name="description" content={get(platform, 'description')} />
						)}
					</Helmet>
				);
			}

			case 'custom': {
				return (
					<Helmet>
						<title>{this.props.title}</title>
						{this.props.description && <meta name="description" content={this.props.description} />}
					</Helmet>
				);
			}

			case 'iosLogos': {
				const appLogo = get(platform, 'data.app.logo');
				const iosLogos = [];

				if (appLogo) {
					iosLogos.splice(
						0,
						0,
						<link
							key="shortcut"
							rel="shortcut icon"
							type="image/png"
							href={imageUrl(appLogo, 144, true, false)}
						/>,
						<link
							rel="apple-touch-icon"
							sizes="57x57"
							key="57x57"
							href={imageUrl(appLogo, 57, true, false)}
						/>,
						<link
							rel="apple-touch-icon"
							sizes="72x72"
							key="72x72"
							href={imageUrl(appLogo, 72, true, false)}
						/>,
						<link
							rel="apple-touch-icon"
							sizes="114x114"
							key="114x114"
							href={imageUrl(appLogo, 114, true, false)}
						/>,
						<link
							rel="apple-touch-icon"
							sizes="144x144"
							key="144x144"
							href={imageUrl(appLogo, 144, true, false)}
						/>,
					);
				}
				return <Helmet>{iosLogos}</Helmet>;
			}

			case 'admin': {
				const titleParts = ['Admin'];
				if (get(platform, 'name')) titleParts.push(get(platform, 'name', ''));
				if (this.props.suffix) titleParts.push(this.props.suffix);
				const title = titleParts.join(' ');
				return (
					<Helmet>
						<title>{title}</title>
						<link
							rel="shortcut icon"
							type="image/png"
							href="/favicon-black.png"
							media="(prefers-color-scheme: light)"
						/>
						<link
							rel="shortcut icon"
							type="image/png"
							href="/favicon-white.png"
							media="(prefers-color-scheme: dark)"
						/>
						<link
							rel="shortcut icon"
							type="image/png"
							href="/favicon-black.png"
							media="(prefers-color-scheme: no-preference)"
						/>
					</Helmet>
				);
			}

			default: {
				const titleParts = [];
				if (get(platform, 'name')) titleParts.push(get(platform, 'name', ''));
				titleParts.push('*');
				const title = titleParts.join(' ');
				return (
					<Helmet>
						<title>{title}</title>
						{/* <link rel="shortcut icon" type="image/png" href="/favicon.jpg" /> */}
					</Helmet>
				);
			}
		}
	}
}

PlatformMetas.propTypes = {
	context: PropTypes.oneOf(['admin', 'student', 'custom', 'iosLogos']),
	title: PropTypes.string,
	description: PropTypes.string,
	suffix: PropTypes.string,
	entity: PropTypes.object,
};

PlatformMetas.defaultProps = {
	context: 'admin',
};

export default PlatformMetas;
