import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

class ListWithSquarePoints extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Lista con puntos`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		listItems: [
			{
				text:
					'Ma quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequia.',
			},
			{
				text:
					'Ma quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequia.',
			},
			{
				text:
					'Ma quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequia.',
			},
		],
	};

	static brickDefaultConfig = {};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		let items = data.listItems || [];

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ListWithSquarePoints']);

		const classes = classNames({
			'brick': true,
			'list-with-square-points-brick': true,
		});

		let elements = items.map((listItem, index) => {
			return (
				<Animation
					key={index}
					playAnimation={this.state.playEffect}
					delay={(index + 1) * 0.5}
					effect={brickAnimation}
				>
					<li className="list-with-square-points-brick__ul__li">
						<span className="square" />
						<FrontEditableText
							fieldName="text"
							text={listItem.text}
							set={(fieldPath, fieldData) => {
								//Propagamos la llamada al método set añadiendo mi nodo al path
								if (this.props.set)
									this.props.set(['listItems', index].concat(fieldPath), fieldData);
							}}
						/>
					</li>
				</Animation>
			);
		});

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<ul className="list-with-square-points-brick__ul">{elements}</ul>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ListWithSquarePoints);
