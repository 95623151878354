import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import VideoImage from 'businessLogic/shared/Video/VideoImage';
import VideoCaption from 'businessLogic/shared/Video/VideoCaption';
import {fromString} from 'css-color-converter';
import Logger from 'businessLogic/services/Logger';

import {t} from 'businessLogic/scope/user/helper/userTtag';

import Vimeo from '@u-wave/react-vimeo';
// https://github.com/u-wave/react-vimeo
import './styles.scss';
import PropTypes from 'prop-types';

class VimeoDefault extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			viewImageVideo: true,
			videoTime: '--:--',
			paused: true,
			error: false,
		};
	}
	handleClickVideo = () => {
		this.setState({
			paused: false,
			viewImageVideo: false,
		});
	};

	// calculateTotalTime = () => {
	// 	const g_this = this;
	// 	let videoContainer = this.refs.videoContainer;
	// 	let iframe = videoContainer.querySelector("iframe");
	// 	let player = new Player(iframe);
	// 	player
	// 		.getDuration()
	// 		.then(function(duration) {
	// 			let minutes = Math.floor(duration / 60);
	// 			if (minutes <= 10) {
	// 				minutes = "0" + minutes;
	// 			}
	// 			let seconds_int = duration - minutes * 60;
	// 			if (seconds_int <= 10) {
	// 				seconds_int = "0" + seconds_int;
	// 			}
	// 			let seconds_str = seconds_int.toString();
	// 			let seconds = seconds_str.substr(0, 2);
	// 			let time = minutes + ":" + seconds;
	// 			g_this.setState({
	// 				videoTime: time
	// 			});
	// 		})
	// 		.catch(function(error) {
	// 			// an error occurred
	// 		});
	// };
	render() {
		const {image, description, credit, vimeoID, onPlay, onPause, onEnd} = this.props;
		const {error} = this.state;
		const interactionColor = getComputedStyle(document.documentElement).getPropertyValue(
			'--interactionColor',
		);
		const cssColor = fromString(interactionColor);
		const hexInteractionColor = cssColor ? cssColor.toHexString() : interactionColor;
		const vimeoColor = hexInteractionColor.replace('#', '');
		return (
			<div className="video-vimeo-container" ref="videoContainer">
				{this.props.videoCaption && (
					<VideoCaption
						description={description}
						credit={credit}
						videoState={this.state.viewImageVideo}
						videoTime={this.state.videoTime}
					/>
				)}
				{this.props.image && (
					<VideoImage
						onClick={this.handleClickVideo}
						image={image}
						videoState={this.state.viewImageVideo}
					/>
				)}
				<div className="video-vimeo-container__video-embed">
					<Logger.ErrorBoundary beforeCapture={() => Logger.setTag('codeBlock', 'VimeoVideo')}>
						{!error && (
							<Vimeo
								video={vimeoID}
								paused={this.state.paused}
								color={vimeoColor}
								speed={true}
								onError={(error) => {
									console.error('Vimeo error', error);
									// this.setState({error: true});
								}}
								onPlay={() => {
									this.setState({paused: false});
									onPlay();
								}}
								onPause={() => {
									this.setState({paused: true});
									onPause();
								}}
								onEnd={() => {
									this.setState({paused: true});
									onEnd();
								}}
							/>
						)}
						{error && (
							<div className="video-vimeo-container__video-embed__error">
								{t`Lo sentimos, se ha producido un error al intentar embeber el video.`}
								<br />
								{t`Comprueba que has introducido el enlace correctamente y que dispone de la configuración de seguridad necesaria para visualizarse desde esta página.`}
							</div>
						)}
					</Logger.ErrorBoundary>
				</div>
			</div>
		);
	}
	componentDidMount() {
		// this.calculateTotalTime();
	}
}

VimeoDefault.propTypes = {
	image: PropTypes.string,
	description: PropTypes.string,
	credit: PropTypes.string,
	video: PropTypes.string,
	videoCaption: PropTypes.bool,
	controls: PropTypes.bool,
	onPlay: PropTypes.func,
	onPause: PropTypes.func,
	onEnd: PropTypes.func,
};

VimeoDefault.defaultProps = {
	image: '',
	description: '',
	credit: '',
	video: '',
	videoCaption: true,
	controls: true,
	onPlay: () => {},
	onPause: () => {},
	onEnd: () => {},
};

export default brickWrapper(VimeoDefault);
