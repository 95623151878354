import React from 'react';
import Box from '@mui/material/Box';

import DialogMui from 'businessLogic/shared/Modals/DialogMui';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import entityManager from 'businessLogic/services/EntityManager';
import {getColorObject} from 'businessLogic/helpers/functions';
import ColorSelector from 'businessLogic/shared/Selects/ColorSelector';

import platformColors from 'styles/colors';

import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';

function PaperComponent(props) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

// Función que comprueba si el color no es ninguno de los colores de la paleta, y en ese caso
// lo almacenará en el storage para que se pueda seleccionar en el futuro
const checkColor = (colors, color) => {
	if (!color) {
		return;
	}
	try {
		const colorObject = colors.find((c) => c.color.hex === color?.hex);
		if (!colorObject) {
			const currentColors = JSON.parse(localStorage.getItem('customColors')) || [];
			const newColors = [color, ...currentColors];
			// Si ya hay 4 colores en el storage, se eliminará el último
			if (newColors.length > 4) {
				newColors.pop();
			}
			localStorage.setItem('customColors', JSON.stringify(newColors));
		}
	} catch (error) {
		console.error('Error en checkColor', error);
	}
};

const getCustomColors = () => {
	const defaultColorObjects = [
		{title: '#FEDC82', color: getColorObject('#FEDC82')},
		{title: '#FA5F85', color: getColorObject('#FA5F85')},
		{title: '#33CCFF', color: getColorObject('#33CCFF')},
		{title: '#E97B5C', color: getColorObject('#E97B5C')},
	];
	try {
		const customColors = JSON.parse(localStorage.getItem('customColors')) || [];
		const customColorObjects = customColors.map((color) => {
			return {title: color.hex, color};
		});
		const colors = [...customColorObjects, ...defaultColorObjects];
		// Devolvermos un máximo de 4 colores, dando prioridad a los colores personalizados
		return colors.slice(0, 4);
	} catch (error) {
		console.error('Error en getCustomColors', error);
		return defaultColorObjects;
	}
};

const styles = (sx) => () => ({
	...sx,
});

export default function ColorSelectorModal({
	sx,
	open,
	color,
	onChange,
	onClose,
	excludeCustomColors = false,
	defaultAlpha = 1,
	disableAlpha = false,
}) {
	const [colorChanged, setColorChanged] = React.useState(false);
	const [prevColor, setPrevColor] = React.useState(null);

	React.useEffect(() => {
		if (open) {
			setPrevColor(color);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const {highlightColor, interactionColor, color1, color2, color3, color4} =
		entityManager.getCustomStyles();

	const customColors = getCustomColors();
	const colors = [];
	if (!excludeCustomColors) {
		colors.push(
			{
				title: t`Color de interacción`,
				color: getColorObject(interactionColor, defaultAlpha, '--interactionColor'),
			},
			{
				title: t`Color de destacado`,
				color: getColorObject(highlightColor, defaultAlpha, '--highlightColor'),
			},
			{title: t`Color 1`, color: getColorObject(color1, defaultAlpha, '--color1')},
			{title: t`Color 2`, color: getColorObject(color2, defaultAlpha, '--color2')},
			{title: t`Color 3`, color: getColorObject(color3, defaultAlpha, '--color3')},
			{title: t`Color 4`, color: getColorObject(color4, defaultAlpha, '--color4')},
		);
	} else {
		colors.push(
			{
				title: platformColors.logoPinkDark,
				color: getColorObject(platformColors.logoPinkDark, defaultAlpha),
			},
			{
				title: platformColors.logoYellowDark2,
				color: getColorObject(platformColors.logoYellowDark2, defaultAlpha),
			},
			{
				title: platformColors.logoPurple,
				color: getColorObject(platformColors.logoPurple, defaultAlpha),
			},
			{
				title: platformColors.logoGreenDark,
				color: getColorObject(platformColors.logoGreenDark, defaultAlpha),
			},
			{
				title: platformColors.logoBlueDark,
				color: getColorObject(platformColors.logoBlueDark, defaultAlpha),
			},
			{
				title: platformColors.mainBlue,
				color: getColorObject(platformColors.mainBlue, defaultAlpha),
			},
		);
	}
	colors.push(
		{title: '#FFFFFF', color: getColorObject('#FFFFFF', defaultAlpha)},
		{title: '#000000', color: getColorObject('#000000', defaultAlpha)},
		...customColors,
	);
	const colorSelectors = [
		// Quita el color
		<Box
			key={'reset'}
			className={`color-circle reset-color ${!color ? 'disabled' : ''}`}
			onClick={() => {
				onChange(null);
			}}
		>
			<FormatColorResetIcon />
		</Box>,
	];
	colorSelectors.push(
		...colors.map((customColor) => {
			return (
				<Box
					key={customColor.title}
					title={customColor.title}
					className={`color-circle ${
						customColor.color?.hex === (color?.hex || false) ? 'active' : ''
					}`}
					sx={{
						backgroundColor: customColor.color.hex,
					}}
					onClick={() => {
						onChange(customColor.color);
						setColorChanged(true);
					}}
				/>
			);
		}),
	);

	const onAccept = () => {
		if (colorChanged) {
			checkColor(colors, color);
		}
		onClose();
	};

	const onCancel = () => {
		onClose();
		onChange(prevColor);
	};

	return (
		<DialogMui
			onClose={onAccept}
			open={open}
			title={t`Selección de color`}
			PaperComponent={PaperComponent}
			aria-labelledby="color-selector"
			className="ColorSelectorModal"
			sx={styles(sx)}
		>
			<DialogContent
				sx={{
					'width': 300,
					'padding': 0,
					'& .color-selector .chrome-picker': {
						borderBottom: 'none',
					},
				}}
			>
				<ColorSelector
					onSelectColor={(color) => {
						onChange(color);
						setColorChanged(true);
					}}
					color={color?.rgb || false}
					fullColor={true}
					defaultAlpha={defaultAlpha}
					disableAlpha={disableAlpha}
				/>
				{/* Listado con círculos de colores predeterminados para seleccionar color de manera rápida */}
				<Box
					sx={{
						'display': 'flex',
						'flexWrap': 'wrap',
						'justifyContent': 'center',
						'padding': '0 10px',

						'& .color-circle': {
							'width': '30px',
							'height': '30px',
							'borderRadius': '50%',
							'margin': '5px',
							'cursor': 'pointer',
							'boxShadow': '0 0 0 1px rgba(0,0,0,.1)',
							'&:hover': {
								boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
							},
							'&.active': {
								boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
							},
							'&.reset-color': {
								'boxShadow': 'none',

								'backgroundColor': 'transparent',
								'display': 'flex',
								'alignItems': 'center',
								'justifyContent': 'center',
								'color': '#a4a4a4',
								'&:hover': {
									boxShadow: 'none',
									transform: 'scale(1.1)',
									color: '#000',
								},

								'&.disabled': {
									'cursor': 'not-allowed',
									'boxShadow': 'none',
									'opacity': 0.5,
									'&:hover': {
										boxShadow: 'none',
										transform: 'none',
										color: '#a4a4a4',
									},
								},
							},
						},
					}}
				>
					{colorSelectors}
				</Box>
			</DialogContent>
			<DialogActions
				sx={{
					justifyContent: 'center',
					paddingTop: 2,
					paddingBottom: 2,
				}}
			>
				<Button onClick={onCancel} className="btn-comp" color="secondary" variant="contained">
					{t`Cancelar`}
				</Button>
				<Button
					onClick={onAccept}
					className="btn-comp"
					// disabled={disableButton}
					color="primary"
					variant="contained"
				>
					{t`Aceptar`}
				</Button>
			</DialogActions>
		</DialogMui>
	);
}
