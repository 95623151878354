import React, {PureComponent} from 'react';
import classNames from 'classnames';
import editionBrickWrapper from '../editionBrickWrapper';
import './styles.scss';

class Tabs extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeTabIndex: 0,
		};
	}
	changeActiveTab = (tabIndex) => {
		if (this.state.activeTabIndex !== tabIndex) {
			this.setState({activeTabIndex: tabIndex});
		}
	};
	render() {
		//Nos quedamos con los hijos de tipo Tab
		const tabList = this.props.editionBrickStruct.content.filter((tab) => tab.type === 'Tab');

		const tabHeaderElements = tabList.map((tab, index) => (
			<li
				onClick={() => this.changeActiveTab(index)}
				className={this.state.activeTabIndex === index ? 'active' : ''}
				key={index}
			>
				<span>{tab.title}</span>
			</li>
		));

		const tabContentElements = tabList.map((tab, index) => {
			const classes = classNames({
				'edition-brick-tabs__content': true,
				'active': this.state.activeTabIndex === index,
			});

			return (
				<div key={index} className={classes}>
					{this.props.renderChildren(tab.content, tab.fieldName)}
				</div>
			);
		});

		return (
			<div className="edition-brick-tabs">
				<ul className="edition-brick-tabs__tab-ul">{tabHeaderElements}</ul>
				<div className="edition-brick-tabs__content-container">{tabContentElements}</div>
			</div>
		);
	}
}

export default editionBrickWrapper(Tabs);
