import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

class DraggableTagLabel extends Component {
	render() {
		const mainStyleClass = 'tag-label-draggable';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			[`${mainStyleClass + '--active'}`]: this.props.active
		});

		return (
			<div className={classes}>
				<p className={mainStyleClass + '__text'}>{this.props.text}</p>
			</div>
		);
	}
}

DraggableTagLabel.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	active: PropTypes.bool,
};
DraggableTagLabel.defaultProps = {
	text: 'Texto de etiqueta',
	active: false,
};
export default DraggableTagLabel;
