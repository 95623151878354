import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';

import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class ImportantTextCenterQuotesBigSize extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Cita grande - estilo 1`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		text:
			'Feri dem eos volorat. Archil iusdaecto inveria nditat.Hiligent quae re pre la doloremporro magni od quiatur a sunditate cuptas dolestrunt, cus ipiendis into el magnis soluptiunt dunt',
		author: 'Archil Iusdaecto',
	};

	static brickDefaultConfig = {};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const classes = classNames({
			'brick': true,
			'important-text-center-quotes-big-size': true,
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div className={classes + '__quote'}>
						<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
					</div>
					<div className={classes + '__author'}>
						<FrontEditableText fieldName="author" text={data.author} set={this.props.set} />
					</div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ImportantTextCenterQuotesBigSize);
