import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
// import 'react-quill/dist/quill.core.css';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

class ParagraphWithCapital extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Párrafo con letra capital`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		text: 'Ma quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi as exerchita doloreperum quaectur, ut into te la nobitatur? Quiduciet, iunt ut am, nem nos aut molupiet etusamus, ut resed et fugiti a dolore pa vel istios es cus sed modipiderum re doluptat et aut qui amet ipsam vent inctoris quis sitatib uscidis que posam istios es cus sed modipiderum.Ibus, tet landis alissit odicima gnatiis doluptam sit quiatum quatem nos militas sint untestiae nonemquiam iuriae nonsequist ulparum sit et placcaborum quiasperio quis doluptis nemqui ommodita imporiorita doluptatibus ad quia audistia et rem reribust, sit harumquatem hil ium quodiction evelest pro eaturer namusciti optatur, qui con porum qui volupta quibust ipit as volorpor moluptius nus des et arcidelecto te estrum ese plitio consed que lam apist odi custis dissunt ibeatur, illanient mollanis ditatis earibus, quam in earions editasciti optatur, qui con porum qui.',
	};
	static brickDefaultConfig = {};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const classes = classNames({
			'brick': true,
			'paragraph-with-capital-brick': true,
		});

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ParagraphWithCapital);
