import React, {Component} from 'react';
import classNames from 'classnames';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import {PropTypes} from 'prop-types';
import {setEllipsisText} from 'businessLogic/helpers/functions';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class LabelTrigger extends Component {
	render() {
		const classes = classNames({
			'label-trigger': true
		});
		return (
			<div className={classes} onClick={this.props.onClick} title={t`Más información`}>
				{this.props.label.text && (
					<div className="label-trigger__text">
						<FrontEditableText
							text={setEllipsisText(this.props.label.text, this.props.label.limit)}
							editable={false}
						/>
					</div>
				)}
			</div>
		);
	}
}
LabelTrigger.propTypes = {
	label: PropTypes.object,
	onClick: PropTypes.func,
	active: PropTypes.bool
};
LabelTrigger.defaultProps = {
	label: {
		text: t`Texto más información`,
		limit: undefined
	},
	active: false
};

export default LabelTrigger;
