const DEVICE_WIDTHS = {
	mobile: 480,
	mobileLandscape: 768,
	tabletPortrait: 992,
	tabletLandscape: 1220,
	desktop: 1920,
	desktop4K: 3840,
};

//Dado un ancho cualquiera, devuelve un valor estándar según los definidos
function getNextWidth(width) {
	if (!width) {
		return DEVICE_WIDTHS['desktop'];
	}
	return Object.values(DEVICE_WIDTHS).reduce((prev, curr) => {
		if (prev - width < 0) {
			return curr;
		}
		if (curr - width < 0) {
			return prev;
		}

		return prev - width < curr - width ? prev : curr;
	});
}

//Devuelve el ancho asociado al dispositivo según el objeto de anchos pasado, o si no existe, el de por defecto
export function getDeviceWidth(device, customWidths = {}) {
	return getNextWidth(customWidths[device] || DEVICE_WIDTHS[device]);
}

function getImgProxyUrl(relUrl, width = 0, height = 0, resizeType = 'fit') {
	// return `/imgpr/rt:${resizeType}/w:${width}/h:${height}/dpr:${Math.ceil(
	// 	window.devicePixelRatio || 1,
	// )}/${btoa(encodeURI(relUrl))}`;
	// Santi: Ponemos siempre dpr 1 para evitar erroes en dispositivos con mayor pixel ratio
	return `/imgpr/rt:${resizeType}/w:${width}/h:${height}/dpr:1/${btoa(encodeURI(relUrl))}`;
}

function getFullImgProxyUrl(
	relUrl,
	{width, height, focus_x, focus_y, resizeType = 'fit', enlarge = false},
) {
	let url = '/imgpr/';

	if (resizeType) {
		url += `rt:${resizeType}/`;
	}

	if (width !== undefined) {
		url += `w:${width}/`;
	}

	if (height !== undefined) {
		url += `h:${height}/`;
	}

	if (enlarge !== undefined) {
		url += `el:${enlarge}/`;
	}

	if (focus_x !== undefined || focus_y !== undefined) {
		const x = focus_x ? Math.abs(focus_x) / 100 : 0.5;
		const y = focus_y ? Math.abs(focus_y) / 100 : 0.5;
		url += `g:fp:${x}:${y}/`;
	}

	url += `${Buffer.from(encodeURI(relUrl)).toString('base64')}`;

	return url;
}

//Devuelve la url de la imagen
// Cuando indicamos que sea thumbnail, se recorta cuadrada al ancho indicado
export function imageUrl(
	path,
	width = DEVICE_WIDTHS['desktop'],
	thumbnail = false,
	addHost = true,
) {
	if (!path) {
		return path;
	}

	if (typeof path !== 'string') {
		return '';
	}

	//Para scorm dejamos las urls intactas
	if (process.env.REACT_APP_EVENTS_API === 'scorm') {
		return path;
	}

	//Si no empieza por / es una imagen embebida y la devolvemos tal cual
	if (!path.match('^/')) {
		return path;
	}

	let url = path;

	//Se redimensionan todas las imágenes excepto los svg y los gifs que no sean thumbnail
	if (!path.match('.svg$') && (thumbnail || !path.match('.gif$')) && !path.match('.webp$')) {
		if (path.match('^/uploads/')) {
			const bucketName = process.env.REACT_APP_UPLOADS_BUCKET_NAME || 'createandshare_uploads';
			url = path.replace('/uploads/', `gs://${bucketName}/`);
		} else {
			url = `${process.env.REACT_APP_PUBLIC_URL || process.env.PUBLIC_URL}${path}`;
		}
		// }

		url = thumbnail ? getImgProxyUrl(url, width, width, 'fill') : getImgProxyUrl(url, width);
	}

	if (addHost && process.env.NODE_ENV === 'production') {
		url = `https://cdn.cs01.cloud${url}`;
	}

	return url;
}

//Devuelve la url de la imagen con opciones extras
export function imageFullUrl(
	path,
	{
		width = DEVICE_WIDTHS['desktop'],
		height,
		focus_x,
		focus_y,
		resizeType = 'fill',
		enlarge = false,
		addHost = true,
	},
) {
	if (!path) {
		return path;
	}

	if (typeof path !== 'string') {
		return '';
	}

	//Para scorm dejamos las urls intactas
	if (process.env.REACT_APP_EVENTS_API === 'scorm') {
		return path;
	}

	//Si no empieza por / es una imagen embebida y la devolvemos tal cual
	if (!path.match('^/')) {
		return path;
	}

	let url = path;

	//Se redimensionan todas las imágenes excepto los svg y los gifs que no sean thumbnail
	if (!path.match('.svg$') && !path.match('.gif$')) {
		if (path.match('^/uploads/')) {
			const bucketName = process.env.REACT_APP_UPLOADS_BUCKET_NAME || 'createandshare_uploads';
			url = path.replace('/uploads/', `gs://${bucketName}/`);
		} else {
			url = `${process.env.REACT_APP_PUBLIC_URL || process.env.PUBLIC_URL}${path}`;
		}
		// }

		// url = thumbnail ? getImgProxyUrl(url, width, width, 'fill') : getImgProxyUrl(url, width);
		url = getFullImgProxyUrl(url, {width, height, focus_x, focus_y, resizeType, enlarge});
	}

	if (addHost && process.env.NODE_ENV === 'production') {
		url = `https://cdn.cs01.cloud${url}`;
	}

	return url;
}
