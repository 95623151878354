import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Image',
						fieldName: 'image',
					},
					{
						type: 'Text',
						fieldName: 'brickIdentifier',
						title: t`Identificador del formulario de contacto`,
					},
					{
						type: 'Text',
						fieldName: 'title',
						title: t`Título`,
					},
					{
						type: 'Text',
						fieldName: 'subtitle',
						title: t`Subtítulo`,
					},
					{
						type: 'Text',
						fieldName: 'emailUser',
						title: t`Texto para el input del email del usuario`,
					},
					{
						type: 'Text',
						fieldName: 'emailAdmin',
						title: t`Email donde será enviado el formulario de contacto`,
					},
					// {
					// 	type: 'Text',
					// 	fieldName: 'text',
					// 	title: t`Instrucciones para que el usuario complete el campo`,
					// },
					{
						type: 'Text',
						fieldName: 'button',
						title: t`Texto del botón de enviar`,
					},

					{
						type: 'TextEditor',
						fieldName: 'finalText',
						title: t`Texto para el usuario tras completar el formulario`,
						defaultValue: t`Tu consulta ha sido enviada con éxito. Gracias por contactar con nosotros`,
					},
					// contenido flexible campos personalizables
					{
						type: 'RepeaterFlexContent',
						fieldName: 'flexibleContent',
						blockTitle: t`Campo de formulario`,
						content: [
							{
								label: t`Texto`,
								flexibleContentType: 'TextField',
								content: [
									{
										type: 'Text',
										fieldName: 'textFieldForm',
										title: t`Texto`,
										placeholder: t`Introduce el texto de ejemplo`,
									},
								],
							},
							{
								label: t`Check`,
								flexibleContentType: 'CheckField',
								content: [
									{
										type: 'Text',
										fieldName: 'labelField',
										title: t`Label`,
									},
									{
										type: 'Text',
										fieldName: 'checkFieldForm',
										title: t`Texto para ampliar información`,
										placeholder: t`Introduce el texto para ampliar información que se abrirá en un modal`,
									},
									{
										type: 'Text',
										fieldName: 'checkFieldFormTextLink',
										title: t`Texto del link para mostrar más información`,
										placeholder: t`Introduce el texto del link para mostrar el modal`,
									},
								],
							},
							{
								label: t`Email`,
								flexibleContentType: 'EmailField',
								content: [
									{
										type: 'Text',
										fieldName: 'emailFieldForm',
										title: t`Email`,
										placeholder: t`Introduce el email de ejemplo`,
									},
								],
							},
							{
								label: t`Teléfono`,
								flexibleContentType: 'PhoneField',
								content: [
									{
										type: 'Text',
										fieldName: 'phoneFieldForm',
										title: t`Teléfono`,
										placeholder: t`Introduce el teléfono de ejemplo`,
									},
								],
							},
							{
								label: t`Área de texto`,
								flexibleContentType: 'TextAreaField',
								content: [
									{
										type: 'Text',
										fieldName: 'textAreaFieldForm',
										title: t`Área de texto`,
										placeholder: t`Introduce el texto de ejemplo`,
									},
								],
							},
						],
						commonContent: [
							{
								type: 'Switch',
								fieldName: 'required',
								title: t`¿Es obligatorio?`,
							},

							{
								type: 'Text',
								fieldName: 'slugField',
								title: t`Introduce un slug para el campo`,
							},
						],
					},
					//contenido flexible campos personalizables
				],
			},
		],
	},
];

export default editionFields;
