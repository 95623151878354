import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Image',
						title: t`Imagen`,
						fieldName: 'image',
					},
					{
						type: 'Text',
						fieldName: 'textLinkInstructions',
						title: t`Texto del enlace de instrucciones`,
						placeholder: t`Añade texto para el enlace de intrucciones de tu test`,
					},
					{
						type: 'TextArea',
						fieldName: 'instructions',
						title: t`Instrucciones`,
						placeholder: t`Añade un texto de ayuda para tus usuarios, si lo consideras necesario`,
						rows: 2,
					},
					{
						type: 'TextEditor',
						fieldName: 'beforeHeading',
						title: t`Título`,
						placeholder: t`Introduce un título`,
					},

					{
						type: 'TextEditor',
						fieldName: 'descriptionTest',
						title: t`Descripción`,
						placeholder: t`Instrucciones de funcionamiento del test. Marca las casillas correctas y envía tu respuesta`,
					},
					{
						type: 'Text',
						fieldName: 'textButtonStart',
						title: t`Texto del botón de inicio`,
						placeholder: t`Introduce el texto del botón de inicio`,
					},
					{
						type: 'Repeater',
						min: 2,
						max: 2,
						fieldName: 'categories',
						blockTitle: t`Categoría`,
						content: [
							{
								type: 'Text',
								fieldName: 'category',
								title: t`Nombre de la categoría`,
								placeholder: t`Introduce el nombre de la categoría`,
							},
							{
								type: 'Repeater',
								min: 0,
								defaultNumber: 4,
								fieldName: 'tags',
								blockTitle: t`Etiqueta`,
								content: [
									{
										type: 'Text',
										fieldName: 'tagName',
										title: t`Texto de la etiqueta`,
										placeholder: t`Introduce el texto de la etiqueta`,
									},
								],
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Configuración`,
				fieldName: 'config',
				content: [
					{
						type: 'Number',
						fieldName: 'pointSuccess',
						title: t`Puntos por cada pregunta acertada`,
						placeholder: t`Aumenta o disminuye de 10 en 10`,
						min: 10,
						max: 100,
						step: 10,
					},
					{
						type: 'Number',
						fieldName: 'questionTimeInSeconds',
						title: t`Segundos por pregunta`,
						instructions: t`El tiempo restante en el momento de contestar se sumará a la puntuación de la pregunta si esta es correcta`,
						min: 0,
						max: 300,
						step: 5,
					},
					{
						type: 'TextArea',
						fieldName: 'brickName',
						title: t`Nombre del test`,
						placeholder: t`Introduce un titulo para el test`,
						instructions: t`El título del test (no visible para el usuario) te permitirá identificarlo en el panel de estadísticas.`,
						rows: 2,
					},
					{
						type: 'TextArea',
						fieldName: 'brickDescription',
						title: t`Descripción del test`,
						placeholder: t`Introduce una descripción para el test`,
						instructions: t`La descripción del test (no visible para el usuario) te permitirá identificarlo en el panel de estadísticas.`,
						rows: 4,
					},
				],
			},
		],
	},
];
export default editionFields;
