import React from 'react';

export default React.createContext({
	registerConfig: () => {},
	unregisterConfig: () => {},
	updateRegisteredConfig: () => {},
	updateConfigRanges: () => {},
	startScrollAnimation: () => {},
	stopScrollAnimation: () => {},
	observeBrick: () => {},
	unobserveBrick: () => {},
	observeImage: () => {},
	unobserveImage: () => {},
	returnCurrentConfig: () => {},
});
