import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import SeveralImages from 'businessLogic/core/shared/Image/SeveralImages';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import get from 'lodash/get';
import './styles.scss';
class ThreeImagesFullWidthTitleText extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Composición de tres imágenes ancho completo con texto`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
				title: 'DUSUE NOBISTA MANTE MAS MANSA TRESE PEDITATU',
				text: 'Quis eost, ofcim sinverum vellant quam corrumque csxsasasa il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi as exerchita doloreperum quaectur, ut into te la nobitatur? Quiduciet, iunt ut am, nem nos aut molupiet etusamus, ut resed et fugiti a dolore pa vel istios es cus sed modipiderum .Um hilluptassi ofctus, comnim quam accum sequia nihictio mansutrea daportes nomaresa buertesa.',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
				title: 'DUSUE NOBISTA MANTE MAS MANSA TRESE PEDITATU',
				text: 'Quis eost, ofcim sinverum vellant quam corrumque csxsasasa il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi as exerchita doloreperum quaectur, ut into te la nobitatur? Quiduciet, iunt ut am, nem nos aut molupiet etusamus, ut resed et fugiti a dolore pa vel istios es cus sed modipiderum .Um hilluptassi ofctus, comnim quam accum sequia nihictio mansutrea daportes nomaresa buertesa.',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
				title: 'DUSUE NOBISTA MANTE MAS MANSA TRESE PEDITATU',
				text: 'Quis eost, ofcim sinverum vellant quam corrumque csxsasasa il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi as exerchita doloreperum quaectur, ut into te la nobitatur? Quiduciet, iunt ut am, nem nos aut molupiet etusamus, ut resed et fugiti a dolore pa vel istios es cus sed modipiderum .Um hilluptassi ofctus, comnim quam accum sequia nihictio mansutrea daportes nomaresa buertesa.',
			},
		],
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && this.state.counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}
	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ThreeImagesFullWidthTitleText']);

		const images = data.images || [];

		const classes = classNames({
			'brick': true,
			'three-images-full-width-title-text-brick': true,
		});

		return (
			<Container
				size="fullWidth"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<WindowSizeContext.Consumer>
					{({device}) => {
						return (
							<div className={classes}>
								<SeveralImages
									images={images}
									format="landscape"
									margin={false}
									effect={brickAnimation}
									delay={0.5}
									playAnimation={this.state.playEffect}
									set={(fieldPath, fieldData) => {
										//Propagamos la llamada al método set añadiendo mi nodo al path
										this.props.set(['images'].concat(fieldPath), fieldData);
									}}
									clickable={device === 'mobile' || device === 'mobileLandscape' ? false : true}
								/>
							</div>
						);
					}}
				</WindowSizeContext.Consumer>
			</Container>
		);
	}
}

export default brickWrapper(ThreeImagesFullWidthTitleText);
