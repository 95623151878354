import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class SwitchTest extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		let defaultValue = false;
		if (editionBrickStruct.defaultValue) {
			defaultValue = editionBrickStruct.defaultValue;
		}

		return defaultValue;
	};

	setValue = (value) => {
		this.props.setMainField(value);
	};

	handleToggle = () => {
		this.setValue(!this.props.getMainField());
	};

	render() {
		return (
			<div className="edition-brick-switch--test">
				{this.props.editionBrickStruct.title && (
					<label className="edition-brick-switch--test__label">
						{this.props.editionBrickStruct.title}
					</label>
				)}
				<div
					className={
						'edition-brick-switch--test__button ' + (this.props.getMainField() ? 'active' : '')
					}
				>
					{/* <span className="edition-brick-switch--test__button__state failure">
						{t`Falsa`}
					</span> */}
					<div
						className="edition-brick-switch--test__button__btn-switch"
						onClick={this.handleToggle}
					/>
					<span className="edition-brick-switch--test__button__state success">
						{/* {this.props.getMainField() ? "Verdadera" : "Falsa"} */}
						{t`Correcta`}
					</span>
				</div>
			</div>
		);
	}
}

export default editionBrickWrapper(SwitchTest);
