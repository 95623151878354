import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import brickImage from './img/brickPreview.jpg';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class ImportantTextWithPhotoOutsideBox extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Cita con imagen - estilo 3`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		imageObj: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
		},
		text: '"Feri dem eos volorat. Archil iusdaecto inveria nditat.Hiligent quae re pre la doloremporro magni od quiatur a sunditate cuptas dolestrunt, cus ipiendis into el magnis soluptiunt dunt"',
		author: 'ARCHIL IUSDAECTO',
	};

	static brickDefaultConfig = {};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ImportantTextWithPhotoOutsideBox']);

		const classes = classNames({
			'brick': true,
			'important-text-with-photo-outside-box-brick': true,
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div className="important-text-with-photo-outside-box-brick__content">
						<Animation playAnimation={this.state.playEffect} delay={1} effect={brickAnimation}>
							<div className="important-text-with-photo-outside-box-brick__content__photo">
								<NewImageClickable imgObj={data.imageObj} defaultVisible={true} clickable={false} />
							</div>
						</Animation>
						<Animation
							playAnimation={this.state.playEffect}
							delay={1.5}
							effect={brickAnimation === 'fade-scale' ? undefined : brickAnimation}
						>
							<div className="important-text-with-photo-outside-box-brick__text-content">
								<div className="important-text-with-photo-outside-box-brick__text-content__text">
									<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
								</div>
								<div className="important-text-with-photo-outside-box-brick__text-content__author">
									<FrontEditableText fieldName="author" text={data.author} set={this.props.set} />
								</div>
							</div>
						</Animation>
					</div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ImportantTextWithPhotoOutsideBox);
