import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';
import NewImageClickable from '../NewImageClickable';

/**
 * Componente para imagen, tendrá la imagen de fondo y podrá tener diferentes proporciones y punto de centrado
 */

class Image extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			openedImage: false
		};
	}

	render() {
		const {className, imgObj, format, clickable, imgWidths} = this.props;
		const classes = classNames({
			'image-cmp': true,

			[`image-cmp--${format}`]: true,
			// "image-cmp--square": square, //100% para un aspect ratio 1:1
			// "image-cmp--portrait": portrait, //133,33% para un aspect ratio 3:4
			// "image-cmp--landscape": landscape, //56.25% para un aspect ratio 16:9.
			[`${className}`]: true
		});

		return (
			<div className={classes}>
				<div className="image-cmp__image">
					<NewImageClickable imgObj={imgObj} clickable={clickable} imgWidths={imgWidths} />
				</div>
			</div>
		);
	}
}

Image.propTypes = {
	className: PropTypes.string,
	imgObj: PropTypes.object,
	format: PropTypes.oneOf(['square', 'landscape', 'portrait']),
	clickable: PropTypes.bool,
	imgWidths: PropTypes.object //Objeto con el ancho asociado a cada dispositivo
};

Image.defaultProps = {
	className: '',
	format: 'landscape',
	clickable: true,
	imgWidths: {}
};

export default Image;
