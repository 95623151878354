// import {client} from "businessLogic/store/store";
// import {
// 	PILL_DELETE_SUBSCRIBE,
// 	PILLS_QUERY,
// 	PILL_ADD_SUBSCRIBE,
// 	ADD_NEW_BRICK_SUBSCRIBE
// } from "./query";
// import {deletePill, initialState} from "../actions";
// import {getIdentificator} from "businessLogic/store/data/identificator";

/**
 * Subscription encargada de actualizar el resto de clientes cuando se borra una píldora
 */
export function subscribeDeletePill() {
	//Santi, comento porque da error en server, hasta que rehagamos las suscripciones
	return null;
	// return client
	// 	.subscribe({
	// 		query: PILL_DELETE_SUBSCRIBE,
	// 		variables: {
	// 			identificator: getIdentificator()
	// 		}
	// 	})
	// 	.subscribe(
	// 		(response) => {
	// 			const {id} = response.data.deletePillSubscription;
	// 			deletePill(id);
	// 			// Leemos los datos que hay en cache asociados a la query PILLS_QUERY
	// 			const data = client.readQuery({query: PILLS_QUERY});
	// 			// Borramos el id de la píldora correspondiente de todos los datos de píldoras
	// 			data.pills = [].concat(data.pills.filter((pill) => pill.id !== id));
	// 			// Escribimos de nuevo la query a cache.
	// 			client.writeQuery({query: PILLS_QUERY, data});
	// 		},
	// 		(error) => console.error("Error in subscriptions scenes/MyPills", error)
	// 	);
}

/**
 * Subscription encargada de actualizar el resto de clientes cuando se añade una píldora
 */
export function subscribeCreatePill() {
	//Santi, comento porque da error en server, hasta que rehagamos las suscripciones
	return null;
	// return client
	// 	.subscribe({
	// 		query: PILL_ADD_SUBSCRIBE,
	// 		variables: {
	// 			identificator: getIdentificator()
	// 		}
	// 	})
	// 	.subscribe(
	// 		(response) => {
	// 			// Leemos los datos que hay en cache asociados a la query PILLS_QUERY
	// 			const data = client.readQuery({query: PILLS_QUERY});
	// 			// Añadimos la píldora nueva a los datos de píldoras
	// 			data.pills.push(response.data.addPillSubscription);
	// 			// Seteamos los datos a nuestro store
	// 			initialState(data.pills);
	// 			// Escribimos de nuevo la query a cache.
	// 			client.writeQuery({query: PILLS_QUERY, data});
	// 		},
	// 		(error) => console.error("Error in subscriptions scenes/MyPills", error)
	// 	);
}

/**
 * UnSubscription es una function encargada de dejar de observar la subcription
 */
export function unsubscribeCreatePill() {
	//Santi, comento porque da error en server, hasta que rehagamos las suscripciones
	// subscription.unsubscribe();
}
export function unsubscribeDeletePill() {
	//Santi, comento porque da error en server, hasta que rehagamos las suscripciones
	// subscription.unsubscribe();
}

/**
 * Subscription encargada de actualizar el resto de clientes cuando se añade una píldora
 */
export function subscribeAddNewBrick() {
	//Santi, comento porque da error en server, hasta que rehagamos las suscripciones
	return null;
	// return client
	// 	.subscribe({
	// 		query: ADD_NEW_BRICK_SUBSCRIBE,
	// 		variables: {
	// 			identificator: getIdentificator()
	// 		}
	// 	})
	// 	.subscribe(
	// 		(response) => {
	// 			let pillUpdated = response.data.addNewBrickSubscription;
	// 			// Leemos los datos que hay en cache asociados a la query PILLS_QUERY
	// 			const data = client.readQuery({query: PILLS_QUERY});
	// 			// Añadimos la píldora nueva a los datos de píldoras
	// 			data.pills.forEach((element) => {
	// 				if (element.id === pillUpdated.id) {
	// 					element = pillUpdated;
	// 				}
	// 			});
	// 			// Seteamos los datos a nuestro store
	// 			initialState(data.pills);
	// 			// Escribimos de nuevo la query a cache.
	// 			client.writeQuery({query: PILLS_QUERY, data});
	// 		},
	// 		(error) => console.error("Error in subscriptions scenes/MyPills", error)
	// 	);
}

export function unsubscribeAddNewBrick() {
	//Santi, comento porque da error en server, hasta que rehagamos las suscripciones
	// subscription.unsubscribe();
}
