import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Configuración`,
				fieldName: 'data',
				content: [
					{
						type: 'ColorBg',
						fieldName: ['background', 'colorBg'],
					},
					{
						type: 'ImageBg',
						fieldName: ['background', 'imageBg'],
					},

					{
						type: 'ColorLayerOpacity',
						fieldName: ['background', 'colorLayerOpacity'],
					},
					{
						type: 'ThemeSelector',
						fieldName: ['background', 'themeSelected'],
					},
				],
			},
		],
	},
];

export default editionFields;
