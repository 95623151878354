import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

class Instructions extends PureComponent {
	render() {
		return <span className="instructions">{this.props.text}</span>;
	}
}

Instructions.propTypes = {
	text: PropTypes.string
};

Instructions.defaultProps = {
	text: ''
};

export default Instructions;
