import React from 'react';
import PropTypes from 'prop-types';
import {push as pushAction, replace as replaceAction} from 'businessLogic/store/data/router';
import classNames from 'classnames';
import './styles.scss';

const Link = (props) => {
	const {className, decoration, target, to, replace, children, disabled, ...other} = props;

	if (!to) {
		return children;
	}

	const handleClick = (event) => {
		// Ignore any click other than a left click
		if (
			(event.button && event.button !== 0) ||
			event.metaKey ||
			event.altKey ||
			event.ctrlKey ||
			event.shiftKey ||
			event.defaultPrevented === true
		) {
			return;
		}

		// Prevent the default behaviour (page reload, etc.)
		event.preventDefault();
		// Dispatch the appropriate navigation action
		if (replace) {
			replaceAction(to);
		} else {
			pushAction(to);
		}
	};
	const classes = classNames({
		link: true,
		[`link--decoration-${decoration}`]: true,
		[`${className}`]: true,
	});
	const isWeb = /http/i.exec(to);
	const isMail = /@/g.exec(to);
	const isWWW = /www./g.exec(to);

	if (disabled) {
		return (
			<span className={classes} {...other}>
				{children}
			</span>
		);
	}

	if (isWeb) {
		return (
			<a className={classes} href={to} target={target} disabled={disabled} {...other}>
				{children}
			</a>
		);
	}
	if (isMail || isWWW) {
		let toNew = to;
		if (isMail) {
			toNew = 'mailto:' + to;
		}
		if (isWWW) {
			toNew = 'http://' + to;
		}
		return (
			<a className={classes} href={toNew} target={target} disabled={disabled} {...other}>
				{children}
			</a>
		);
	}
	if (target === '_blank') {
		return (
			<a className={classes} href={to} target={target} disabled={disabled} {...other}>
				{children}
			</a>
		);
	}
	return (
		<a
			className={classes}
			href={to}
			onClick={handleClick}
			target={target}
			disabled={disabled}
			{...other}
		>
			{children}
		</a>
	);
};

Link.propTypes = {
	to: PropTypes.string.isRequired,
	className: PropTypes.string,
	decoration: PropTypes.oneOf(['default', 'none', 'emphasize']),
	target: PropTypes.string,
	replace: PropTypes.bool,
	disabled: PropTypes.bool,
};

Link.defaultProps = {
	className: '',
	decoration: 'default',
	target: '',
	replace: false,
	disabled: false,
};
export default Link;
