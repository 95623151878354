import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import SeveralImages from 'businessLogic/core/shared/Image/SeveralImages';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import get from 'lodash/get';
import './styles.scss';

class ThreeImagesSquareTitleText extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Composición de tres imágenes cuadradas con texto`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
				title: 'DUSUE NOBISTA MANTE MAS MANSA TRESE',
				text: 'Quis eost, ofcim sinverum vellant quam corrumque il moluptium ariscimod eveducid.Cim eum quasper oribusae vendae nos aut faciistem sitint.Olut aut ut que et la simustium voluptatur alitatia dis re endit illis doluptati simet inveniam, sit reptat manstrea sastrera manutre',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
				title: 'DUSUE NOBISTA MANTE MAS MANSA TRESE',
				text: 'Quis eost, ofcim sinverum vellant quam corrumque il moluptium ariscimod eveducid.Cim eum quasper oribusae vendae nos aut faciistem sitint.Olut aut ut que et la simustium voluptatur alitatia dis re endit illis doluptati simet inveniam, sit reptat manstrea sastrera manutre',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
				title: 'DUSUE NOBISTA MANTE MAS MANSA TRESE',
				text: 'Quis eost, ofcim sinverum vellant quam corrumque il moluptium ariscimod eveducid.Cim eum quasper oribusae vendae nos aut faciistem sitint.Olut aut ut que et la simustium voluptatur alitatia dis re endit illis doluptati simet inveniam, sit reptat manstrea sastrera manutre',
			},
		],
	};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && this.state.counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const images = data.images || [];
		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ThreeImagesSquareTitleText']);

		const classes = classNames({
			'brick': true,
			'three-images-square-title-text-brick': true,
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<WindowSizeContext.Consumer>
					{({device}) => {
						return (
							<div className={classes}>
								<SeveralImages
									images={images}
									format="square"
									margin={true}
									effect={brickAnimation}
									delay={0.5}
									playAnimation={this.state.playEffect}
									set={(fieldPath, fieldData) => {
										//Propagamos la llamada al método set añadiendo mi nodo al path
										this.props.set(['images'].concat(fieldPath), fieldData);
									}}
									clickable={device === 'mobile' || device === 'mobileLandscape' ? false : true}
								/>
							</div>
						);
					}}
				</WindowSizeContext.Consumer>
			</Container>
		);
	}
}

export default brickWrapper(ThreeImagesSquareTitleText);
