import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
// import 'react-quill/dist/quill.core.css';
import Container from 'businessLogic/core/shared/Container/Container';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

class ParagraphInterview extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Entrevista pregunta - respuesta`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		questions: [
			{
				question:
					'¿Quiderum ea cupiet volorep eribus eaturasr rerovidrf et licto voluptat re dolut quas delesci nimporem raes aligend aepersped?',
				answer:
					'Optas isci neceruptur? Quiderum ea cupiet volorep eribus eatur rerovid et licto voluptat re dolut quas delesci nimporem rae officie nimodis aligend aepersped quasitium, omnis site volorat atibers piciam idebitiatet quam as molorum lit modicimpos.',
			},
		],
	};
	static brickDefaultConfig = {};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const {questions, questionMark = 'P. ', answerMark = 'R. '} = data;
		const questionsRows = questions.map((questionItem, index) => {
			return (
				<ul key={index}>
					<li className="paragraph-interview-brick__question">
						<span className="paragraph-interview-brick__label">{questionMark}</span>
						<FrontEditableText
							fieldName="question"
							text={questionItem.question}
							set={(fieldPath, fieldData) => {
								//Propagamos la llamada al método set añadiendo mi nodo al path
								if (this.props.set)
									this.props.set(['questions', index].concat(fieldPath), fieldData);
							}}
						/>
					</li>
					<li className="paragraph-interview-brick__answer">
						<span className="paragraph-interview-brick__label">{answerMark}</span>
						<FrontEditableText
							fieldName="answer"
							text={questionItem.answer}
							set={(fieldPath, fieldData) => {
								//Propagamos la llamada al método set añadiendo mi nodo al path
								if (this.props.set)
									this.props.set(['questions', index].concat(fieldPath), fieldData);
							}}
						/>
					</li>
				</ul>
			);
		});

		const classes = classNames({
			'brick': true,
			'paragraph-interview-brick': true,
		});

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}> {questionsRows} </div>
			</Container>
		);
	}
}

export default brickWrapper(ParagraphInterview);
