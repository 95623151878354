import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Repeater',
						min: 1,
						max: 10,
						fieldName: 'images',
						blockTitle: t`Imagen`,
						content: [
							{
								type: 'Image',
								fieldName: 'imageObj',
								defaultImage: '/uploads/default-images/csDefault1Opt.svg',
							},
						],
					},
					{
						type: 'TextEditor',
						fieldName: 'titleContactForm',
						title: t`¿Tienes alguna pregunta?`,
						placeholder: t`Introduce un texto ej.: ¿Tienes alguna pregunta?`,
					},
					{
						type: 'TextEditor',
						fieldName: 'subtitleContactForm',
						title: t`Mándanos todas tus dudas`,
						placeholder: t`Introduce un texto ej.: Mándanos todas tus dudas`,
					},
					{
						type: 'Text',
						fieldName: 'textContactForm',
						title: t`Añade tu comentario`,
						placeholder: t`Introduce un texto ej.: Añade tu comentario`,
					},
					{
						type: 'Text',
						fieldName: 'buttonContactForm',
						title: t`Texto del botón de enviar`,
						defaultValue: t`Enviar`,
					},
				],
			},
		],
	},
];

export default editionFields;
