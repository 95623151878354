import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {getRgbaString} from 'businessLogic/helpers/functions';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import get from 'lodash/get';
import './styles.scss';

class SeparatorLine extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Línea`;
	static brickImage = brickImage;

	// static brickDefaultStyles = {
	// 	containerStyle: {
	// 		generalColor: {r: '115', g: '229', b: '209', a: '1'},
	// 	},
	// };

	render() {
		// const styles = this.props.brick.styles || {};
		let styles;
		if (!get(this.props, 'brick.styles.containerStyle.generalColor')) {
			const highlightColor = getComputedStyle(document.documentElement).getPropertyValue(
				'--highlightColor',
			);
			styles = {
				containerStyle: {
					generalColor: highlightColor,
				},
			};
		} else {
			styles = this.props.brick.styles || {};
		}
		let stylesLine = {
			line: {},
		};

		const generalColor = styles.containerStyle.generalColor;
		if (generalColor) stylesLine.line.backgroundColor = getRgbaString(generalColor);

		const classes = classNames({
			'brick': true,
			'separator-line-small': true,
		});
		return (
			<Container
				size="small"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div className="separator-line-small__item" style={stylesLine.line} />
				</div>
			</Container>
		);
	}
}

export default brickWrapper(SeparatorLine);
