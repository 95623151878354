import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {getRgbaString} from 'businessLogic/helpers/functions';
import get from 'lodash/get';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

class SeparatorLineWithImg extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Línea con imagen`;
	static brickImage = brickImage;
	static brickDefaultData = {
		image: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
		},
	};

	// static brickDefaultStyles = {
	// 	containerStyle: {
	// 		generalColor: {r: '115', g: '229', b: '209', a: '1'},
	// 	},
	// };

	render() {
		const data = this.props.brick.data;
		// const styles = this.props.brick.styles || {};
		let styles;
		if (!get(this.props, 'brick.styles.containerStyle.generalColor')) {
			const highlightColor = getComputedStyle(document.documentElement).getPropertyValue(
				'--highlightColor',
			);
			styles = {
				containerStyle: {
					generalColor: highlightColor,
				},
			};
		} else {
			styles = this.props.brick.styles || {};
		}
		const imageObj = data.image;

		let stylesLine = {
			line: {},
		};

		const generalColor = styles.containerStyle.generalColor;
		if (generalColor) stylesLine.line.backgroundColor = getRgbaString(generalColor);

		const classes = classNames({
			'brick': true,
			'separator-line-img': true,
		});
		return (
			<Container
				size="extra-large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div className="separator-line-img__line" style={stylesLine.line} />
					<div className="separator-line-img__img">
						{imageObj.imageSrc && (
							<NewImageClickable imgObj={imageObj} clickable={false} defaultVisible={true} />
						)}
					</div>
					<div className="separator-line-img__line" style={stylesLine.line} />
				</div>
			</Container>
		);
	}
}

export default brickWrapper(SeparatorLineWithImg);
