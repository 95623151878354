import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import classNames from 'classnames';
import './styles.scss';

class Form extends PureComponent {
	render() {
		const {data, index} = this.props;
		let classes = classNames({
			'form-contact': true
		});

		return (
			<div className={classes}>
				<FrontEditableText
					className={classes + '__title'}
					fieldName="titleContactForm"
					text={data.titleContactForm}
					editable={false}
					set={(fieldPath, fieldData) => {
						//Propagamos la llamada al método set añadiendo mi nodo al path
						if (this.props.set) this.props.set(['images', index].concat(fieldPath), fieldData);
					}}
				/>
				{/* <h2 className={classes + "__title"}>¿Tienes alguna pregunta?</h2> */}
				<FrontEditableText
					className={classes + '__text'}
					fieldName="subtitleContactForm"
					text={data.subtitleContactForm}
					editable={false}
					set={(fieldPath, fieldData) => {
						//Propagamos la llamada al método set añadiendo mi nodo al path
						if (this.props.set) this.props.set(['images', index].concat(fieldPath), fieldData);
					}}
				/>
				{/* <p className={classes + "__text"}>Mándanos todas tus dudas</p> */}
				<textarea className={classes + '__textarea'} placeholder={data.textContactForm} />
				<button className={classes + '__button'}>{data.buttonContactForm}</button>
			</div>
		);
	}
}

Form.propTypes = {
	data: PropTypes.object
};

Form.defaultProps = {
	data: {}
};

export default Form;
