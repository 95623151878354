import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import {imageUrl} from 'businessLogic/helpers/images';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
// import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import get from 'lodash/get';
import './styles.scss';

class ProposalCover extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Portada - estilo 3`;
	static brickIcon = 'cover';
	static brickImage = brickImage;

	static brickDefaultData = {
		beforeTitle: 'PRAESENT',
		title: 'PRAESENT BIBENDUM ALIQUET JUSTO, EGET FEUGIAT TURPIS ULTRICES ET.',
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliguyam erat, sed diam',
		extraText1: 'Lorem ipsum',
		extraText2: 'consectetur adipiscing eli0',
		logos: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/logo-example.svg',
					credit: '',
				},
			},
		],
	};

	static brickDefaultStyles = {
		containerStyle: {
			colorOverlay: {r: '16', g: '29', b: '51', a: '1'},
			imageBg: '/uploads/default-images/csDefault1Opt.svg',
		},
	};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const logos = data.logos || [];
		const classes = classNames({
			'brick': true,
			'proposal-cover': true,
		});

		const colorOverlay = get(styles, ['containerStyle', 'colorOverlay']);
		const imageBg = get(styles, ['containerStyle', 'imageBg']);
		let stylesInside = {
			overlay: {},
			imageBg: {},
		};

		if (colorOverlay) {
			stylesInside.overlay.backgroundColor = `rgba(${colorOverlay.r},${colorOverlay.g}, ${colorOverlay.b}, ${colorOverlay.a})`;
		} else {
			stylesInside.overlay.backgroundColor = `rgba(16,29,54,1)`;
		}

		if (imageBg) {
			stylesInside.imageBg.backgroundImage = `url('${imageBg}')`;
		} else {
			stylesInside.imageBg.backgroundImage = `url('/uploads/default-images/csDefault1Opt.svg')`;
		}

		//constante para saber si de los dos logos alguno se ha eliminado pero el objeto sigue teniendo 2 elementos
		const emptyLogo = logos.find((logo) => logo.imageObj.imageSrc === '');
		//constante para saber si hay un logo o 2 creados
		const size = Object.keys(logos).length;
		//constante para asignar clase a los logos
		const onlyOneLogo = emptyLogo || size === 1 ? true : false;

		return (
			<Container
				size="full-width"
				scrollEffect={styles.scrollEffect}
				styleConfig={styles.containerStyle}
				withMargin={false}
			>
				<div className={classes}>
					<div className="proposal-cover__background">
						<div className="proposal-cover__background--white"></div>
						<div className="proposal-cover__background--image" style={stylesInside.imageBg}></div>
						<div className="proposal-cover__content" style={stylesInside.overlay}>
							<div className="proposal-cover__content__before-title">
								<FrontEditableText
									fieldName="beforeTitle"
									text={data.beforeTitle}
									editable={true}
									set={this.props.set}
								/>
							</div>

							<div className="proposal-cover__content__text">
								<FrontEditableText
									fieldName="text"
									text={data.text}
									editable={true}
									set={this.props.set}
								/>
							</div>
							<div className={'proposal-cover__logos ' + (onlyOneLogo ? 'center' : 'justify')}>
								{logos.map((logo, index) => {
									return (
										<div
											className={'proposal-cover__logos__item ' + (onlyOneLogo ? 'only-one' : '')}
											key={index}
										>
											{logo.imageObj.imageSrc && (
												<img src={imageUrl(logo.imageObj.imageSrc)} alt={logo.imageObj.credit} />
											)}
										</div>
									);
								})}
							</div>
							{data.extraText1 && (
								<div className="proposal-cover__content__extra-text">
									<FrontEditableText
										fieldName="extraText1"
										text={data.extraText1}
										editable={true}
										set={this.props.set}
									/>
								</div>
							)}
							{data.extraText2 && (
								<div className="proposal-cover__content__extra-text">
									<FrontEditableText
										fieldName="extraText2"
										text={data.extraText2}
										editable={true}
										set={this.props.set}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ProposalCover);
