import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import brickImage2 from './img/brickPreview2.png';
import editionFields from './edit.js';
import LabelTrigger from './components/LabelTrigger';
import LayerMoreInfo from 'businessLogic/core/shared/Modals/LayerMoreInfo';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import Animation from 'businessLogic/core/shared/Animation';
import './styles.scss';
import get from 'lodash/get';

class ShowInfoLabels extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Recuadros`;
	static brickIcon = 'show-info-labels';
	static brickImage = brickImage;
	static brickImage2 = brickImage2;
	static brickDefaultData = {
		labelBoxes: [
			{
				_id: 'Box_1',
				labelInfo: 'Lorem ipsum dolor sit amet',
			},
			{
				_id: 'Box_2',
				labelInfo: 'Fusce commodo',
			},
			{
				_id: 'Box_3',
				labelInfo: 'Fusce commodo purus vestibulum risus.',
			},
			{
				_id: 'Box_4',
				labelInfo: 'Praesent scelerisque massa viverra suscipit.',
			},
		],
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 80,
			paddingBottom: 80,
		},
	};
	constructor(props) {
		super(props);
		this.state = {
			showMoreInfo: false,
			childList: 'default',
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	showInfo = (boxId) => {
		this.setState({
			showMoreInfo: true,
			childList: boxId,
		});
	};
	hideInfo = () => {
		this.setState({
			showMoreInfo: false,
		});
	};
	componentDidUpdate(prevProps) {
		const labelBoxes = get(this.props, 'brick.data.labelBoxes');
		const prevLabelBoxes = get(prevProps, 'brick.data.labelBoxes');
		if (labelBoxes.length < prevLabelBoxes.length) {
			const actualBoxIds = labelBoxes.map((e) => e._id);
			const deletedBox = prevLabelBoxes.filter((e) => !actualBoxIds.includes(e._id))[0];
			const childrenIds = prevProps.brick.children[deletedBox._id];
			if (childrenIds) {
				childrenIds.forEach((brickId) => this.props.deleteBrick(brickId));
			}
		}
	}
	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ShowInfoLabels']);

		const classes = classNames({
			'brick': true,
			'show-info-labels-brick': true,
		});
		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					{data.labelBoxes.map((box, index) => {
						return (
							<div className="box-wrapper" key={index}>
								<Animation
									playAnimation={this.state.playEffect}
									delay={(index + 1) * 0.5}
									effect={brickAnimation}
								>
									<LabelTrigger
										label={{
											text: box.labelInfo,
											limit: 67,
										}}
										onClick={() => {
											this.showInfo(box._id);
										}}
									/>
								</Animation>
							</div>
						);
					})}
				</div>
				<LayerMoreInfo
					show={this.state.showMoreInfo}
					onClose={this.hideInfo}
					mainProps={this.props}
					childList={this.state.childList.toString()}
					editMode={this.props.editMode}
				/>
			</Container>
		);
	}
}
export default brickWrapper(ShowInfoLabels);
