import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Link from 'businessLogic/shared/Links/Link';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import Icon from 'ui/shared/Icon';
import {imageUrl} from 'businessLogic/helpers/images';
import './styles.scss';

class ImagesWithLink extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Imagenes con enlace`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {
		images: [
			{
				image: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
					footer: '',
				},
				hrefLink: '',
			},
			{
				image: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
					footer: '',
				},
				hrefLink: '',
			},
			{
				image: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
					footer: '',
				},
				hrefLink: '',
			},
		],
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ImagesWithLink']);

		const classes = classNames({
			'brick': true,
			'imagesWithLink-brick': true,
		});

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					{data.images.map((imageData, index) => {
						const imageObj = imageData.image;
						const hrefLink = imageData.hrefLink;
						return (
							<div className={'imageWithLink'} key={`${index}_${imageObj.src}`}>
								<Link to={hrefLink} target="_blank">
									<Animation
										playAnimation={this.state.playEffect}
										delay={0.5}
										effect={brickAnimation}
									>
										{/* <ImageComp imgObj={imageObj} clickable={false} /> */}
										<div className="imageWithLink-img-container">
											{data.showLinkIcon && (
												<div className="link-icon-container">
													<Icon name="format-link" size="normal" className="link-icon" />
												</div>
											)}
											<img src={imageUrl(imageObj.imageSrc)} />
										</div>
									</Animation>
									{(imageObj.credit || imageObj.footer) && (
										<div className="image-brick__caption">
											{imageObj.footer && (
												<div className="image-brick__caption__footer"> {imageObj.footer} </div>
											)}
											{imageObj.credit && (
												<div className="image-brick__caption__credit"> {imageObj.credit} </div>
											)}
										</div>
									)}
								</Link>
							</div>
						);
					})}
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ImagesWithLink);
