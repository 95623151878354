import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import QuestionTest from './components/QuestionTest';
import Swiper from 'react-id-swiper';
import './styles.scss';

class Test extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			responses: [],
		};
		this.swiper = null;
	}

	getResponses = () => this.state.responses;

	setResponse = (questionIndex, responseIndex) => {
		if (this.state.responses[questionIndex] === undefined) {
			//clonar array donde guardamos el índice de respuestas seleccionadas en cada pregunta
			const responses = this.state.responses.splice(0);

			responses[questionIndex] = responseIndex;
			this.setState({responses}); //actualizar el estado con el array clonado

			clearTimeout(this.goNextTimeoutHandler);
			this.goNextTimeoutHandler = setTimeout(() => {
				if (this.finishHandle()) {
					if (this.props.onFinish) {
						this.props.onFinish();
					}
				} else {
					this.goNext();
				}
			}, 500);
		}
	};

	finishHandle = () => {
		if (this.props.questions.length === this.state.activeIndex + 1) {
			return true;
		}
	};

	goNext = () => {
		if (this.swiper) {
			this.swiper.slideNext();
		}
		this.props.setHeight(this.swiper.slides[this.state.activeIndex].clientHeight);
		this.props.onScroll();
	};

	changeActiveSlideContent = () => {
		this.setState({
			activeIndex: this.swiper.activeIndex,
		});
		this.props.setHeight(this.swiper.slides[this.state.activeIndex].clientHeight);
		this.props.onScroll();
	};

	componentWillUnmount() {
		clearTimeout(this.goNextTimeoutHandler);
	}

	render() {
		const classes = classNames({
			'test-personality-images-brick__questions-total': true,
			'swiper-no-swiping': true,
		});
		//Parámetros configuración swiper
		const params = {
			shouldSwiperUpdate: true, //si no no se vuelve a renderizar al añadir imágenes al carrusel
			rebuildOnUpdate: false, //se recontruye si se cambian parámetros (valueDelay) o se añaden elementos al carrusel
			direction: 'vertical',
			spaceBetween: 0,
			speed: 1500,
			slidesPerView: 1,
			loop: false,
			noSwiping: true,
			autoHeight: true,

			getSwiper: (swiper) => {
				this.swiper = swiper;
				this.changeActiveSlideContent();
				this.swiper.on('slideChange', () => {
					this.changeActiveSlideContent();
				});
			},
		};

		return (
			<div className={classes}>
				<Swiper {...params}>
					{this.props.questions.map((questionItem, questionIndex) => (
						<QuestionTest
							key={questionIndex}
							textQuestion={questionItem.question}
							responsesQuestion={questionItem.answers}
							onClick={(responseIndex) => {
								this.setResponse(questionIndex, responseIndex);
							}}
							responseActive={this.state.responses[questionIndex]}
							index={questionIndex}
							activeIndex={this.state.activeIndex}
							set={(fieldPath, fieldData) => {
								//Propagamos la llamada al método set añadiendo mi nodo al path
								if (this.props.set)
									this.props.set(['questions', questionIndex].concat(fieldPath), fieldData);
							}}
						/>
					))}
				</Swiper>

				<div className="pagination">
					<span className="pagination__bullet">{this.state.activeIndex + 1}</span>
					<span className="pagination__bullet"> / </span>
					<span className="pagination__bullet">{this.props.questions.length}</span>
				</div>
			</div>
		);
	}
}
Test.propTypes = {
	questions: PropTypes.array,
	onFinish: PropTypes.func,
	set: PropTypes.func,
	setHeight: PropTypes.func,
	onScroll: PropTypes.func,
};
export default Test;
