import React, {PureComponent} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SelectedChoiceQuestion from './components/SelectedChoiceQuestion';
import './styles.scss';

class Question extends PureComponent {
	render() {
		const {index, activeIndex} = this.props;
		const mainStyleClass = 'test-exam-question';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			'swiper-slide': true,
			'swiper-no-swiping': true,
		});

		return (
			<div className={classes}>
				{(activeIndex === index || activeIndex - 1 === index || activeIndex + 1 === index) && (
					<SelectedChoiceQuestion {...this.props} />
				)}
			</div>
		);
	}
}

Question.propTypes = {
	content: PropTypes.object,
	onFinishQuestion: PropTypes.func,
	set: PropTypes.func,
	index: PropTypes.number,
	onTyping: PropTypes.func,
	disable: PropTypes.bool,
	submissions: PropTypes.object,
};

export default Question;
