import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import StylesOptionsWrapper from '../StylesOptionsWrapper';
import AdminColorSelector from 'businessLogic/shared/Selects/AdminColorSelector';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';

import './styles.scss';

class CustomColor extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	static getDefaultValue = () => {
		const defaultValue = {
			generalColor: false,
		};

		return defaultValue;
	};

	handleChangeGeneralColor = (color) => {
		this.props.setMainField(color);
	};

	render() {
		const generalColor = this.props.getMainField();

		//si la estructura tiene un nombre lo utilizamos
		let labelText = t`Elige color`;
		if (this.props.editionBrickStruct.name) {
			labelText = this.props.editionBrickStruct.name;
		}
		return (
			<StylesOptionsWrapper>
				<label className="item-title">{labelText}</label>
				<AdminColorSelector value={generalColor} onChange={this.handleChangeGeneralColor} />
			</StylesOptionsWrapper>
		);
	}
}

export default editionBrickWrapper(CustomColor);
