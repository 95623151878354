import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import {imageUrl} from 'businessLogic/helpers/images';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import get from 'lodash/get';
import './styles.scss';

class BudgetCover extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Portada - estilo 2`;
	static brickIcon = 'cover';
	static brickImage = brickImage;

	static brickDefaultData = {
		beforeTitle: 'PRAESENT',
		title: 'PRAESENT BIBENDUM ALIQUET JUSTO, EGET FEUGIAT TURPIS ULTRICES ET.',
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sed dolor diam. In euismod lobortis lectus, vel eleifend lacus gravida eu. Nam ultricies congue ipsum, in mattis dui iaculis a. Ut nec magna porttitor, dapibus diam ut, porttitor leo. Donec vestibulum suscipit velit nec porta. Aliquam euismod finibus imperdiet. In vel risus vel diam consequat tincidunt id ac tortor. Etiam vestibulum tellus nunc. Pellentesque id lacus et massa gravida fringilla quis venenatis elit.',
		extraText: '28 FEB 2019',
		logos: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/logo-example.svg',
					credit: '',
				},
			},
		],
	};

	static brickDefaultStyles = {
		containerStyle: {
			colorOverlay: {r: '11', g: '16', b: '17', a: '0.72'},
		},
	};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const logos = data.logos || [];
		const classes = classNames({
			'brick': true,
			'budget-cover': true,
		});

		const colorOverlay = get(styles, ['containerStyle', 'colorOverlay']);
		let stylesInside = {
			overlay: {},
		};
		if (colorOverlay) {
			stylesInside.overlay.backgroundColor = `rgba(${colorOverlay.r},${colorOverlay.g}, ${colorOverlay.b}, ${colorOverlay.a})`;
		} else {
			stylesInside.overlay.backgroundColor = `rgba(11,16,17,0.72)`;
		}

		return (
			<Container
				size="full-width"
				scrollEffect={styles.scrollEffect}
				styleConfig={styles.containerStyle}
				withMargin={false}
			>
				<WindowSizeContext.Consumer>
					{({device}) => {
						return (
							<div className={classes}>
								<div className="budget-cover__content" style={stylesInside.overlay}>
									<div className="budget-cover__content__before-title">
										<FrontEditableText
											fieldName="beforeTitle"
											text={data.beforeTitle}
											editable={true}
											set={this.props.set}
										/>
										<div className="budget-cover__content__before-title__line" />
									</div>

									<div className="budget-cover__content__title">
										<FrontEditableText
											fieldName="title"
											text={data.title}
											editable={true}
											set={this.props.set}
										/>
									</div>

									<div className="budget-cover__content__text">
										<FrontEditableText
											fieldName="text"
											text={data.text}
											editable={true}
											set={this.props.set}
										/>
									</div>

									<div className="budget-cover__content__extra-text">
										<FrontEditableText
											fieldName="extraText"
											text={data.extraText}
											editable={true}
											set={this.props.set}
										/>
									</div>
									{(device === 'mobile' ||
										device === 'mobileLandscape' ||
										device === 'tabletPortrait') && (
										<ul className="budget-cover__logos">
											{logos.map((logo, index) => {
												return (
													<li className="budget-cover__logos__item" key={index}>
														<img
															src={imageUrl(logo.imageObj.imageSrc)}
															alt={logo.imageObj.credit}
														/>
													</li>
												);
											})}
										</ul>
									)}
								</div>
								{(device === 'tabletLandscape' || device === 'desktop') && (
									<ul className="budget-cover__logos">
										{logos.map((logo, index) => {
											return (
												<li className="budget-cover__logos__item" key={index}>
													<img src={imageUrl(logo.imageObj.imageSrc)} alt={logo.imageObj.credit} />
												</li>
											);
										})}
									</ul>
								)}
							</div>
						);
					}}
				</WindowSizeContext.Consumer>
			</Container>
		);
	}
}

export default brickWrapper(BudgetCover);
