import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import HotSpotPopUp from 'businessLogic/core/shared/Buttons/HotSpotPopUp';
import brickImage from './img/brickPreview.jpg';
import brickImage2 from './img/brickPreview2.jpg';
import classNames from 'classnames';
import {imageUrl} from 'businessLogic/helpers/images';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import ImgOverlay from 'businessLogic/core/shared/Layers/ImgOverlay';
import get from 'lodash/get';
import editionFields from './edit.js';
import './styles.scss';

// react-image-mapper
// https://www.npmjs.com/package/react-image-mapper

class ShowHotSpotsMediumSize extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Puntos calientes`;
	static brickIcon = 'image';
	static brickHelpText = () => t`Permite incluir píldoras dentro de puntos clicables en una imagen`;
	static brickImage = brickImage;
	static brickImage2 = brickImage2;
	static brickDefaultData = {
		image: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
		},
		showNumbers: false,
		hotSpots: [
			{
				coordinates: {
					x: 50,
					y: 50,
				},
				title: 'Lorem ipsum dolor quisque.',
				text: 'Lorem ipsum dolor sit amet consectetur adipiscing elit cum donec aliquet libero nulla, accumsan aenean rhoncus mollis natoque fusce felis potenti curae risus.',
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault4Opt.svg',
					credit: '',
				},
			},
			{
				coordinates: {
					x: 25,
					y: 10,
				},
				title: 'Lorem ipsum dolor quisque.',
				text: 'Lorem ipsum dolor sit amet consectetur adipiscing elit cum donec aliquet libero nulla, accumsan aenean rhoncus mollis natoque fusce felis potenti curae risus.',
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
			},
			{
				coordinates: {
					x: 75,
					y: 30,
				},
				title: 'Lorem ipsum dolor quisque.',
				text: 'Lorem ipsum dolor sit amet consectetur adipiscing elit cum donec aliquet libero nulla, accumsan aenean rhoncus mollis natoque fusce felis potenti curae risus.',
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
			},
		],
	};

	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			activeIndex: -1,
			playEffect: false,
			counter: 0,
		};
	}
	changeActiveIndex = (index) => {
		if (this.state.activeIndex !== index) {
			this.setState({activeIndex: index});
		} else {
			this.setState({activeIndex: -1});
		}
	};

	hadleOverlayClose = () => {
		this.setState({activeIndex: -1});
	};

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const image = data.image.imageSrc;
		const credit = data.image.credit;
		const showNumbers = data.showNumbers;
		const newStyles = Object.assign(styles.containerStyle);
		newStyles.zIndex = 20;
		const colorLayer = get(styles, 'colorLayer');
		const classes = classNames({
			'brick': true,
			'show-hot-spots': true,
		});
		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ShowHotSpots']);
		const allSpots = data.hotSpots.map((item, index) => {
			return (
				<HotSpotPopUp
					key={index}
					data={item}
					index={index}
					onShow={this.changeActiveIndex}
					onClose={this.hadleOverlayClose}
					active={this.state.activeIndex}
					showNumbers={showNumbers}
					delay={index * 0.5}
					effect={Array.isArray(brickAnimation) ? brickAnimation[0] : brickAnimation}
					playAnimation={this.state.playEffect}
				/>
			);
		});

		return (
			<Container size="normal" styleConfig={newStyles} scrollEffect={styles.scrollEffect}>
				<div className={classes}>
					{allSpots}
					<img className="show-hot-spots__image-main" src={imageUrl(image)} alt={credit} />
					<ImgOverlay colorLayer={colorLayer} />
					<span className="show-hot-spots__credit">{credit}</span>
					<div className="show-hot-spots__overlay-close" onClick={this.hadleOverlayClose} />
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ShowHotSpotsMediumSize);
