import React, {PureComponent} from 'react';
import Dialog from 'businessLogic/shared/Modals/Dialog';
import PropTypes from 'prop-types';
import FilesList from './components/FilesList';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class ImageSelectorDialog extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showDialog: this.props.visible,
		};
	}
	show = () => {
		this.setState({showDialog: true});
	};

	close = () => {
		this.setState({showDialog: false});
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	selectImage = (imageUrl) => {
		if (this.props.onSelect) {
			this.props.onSelect(imageUrl);
		}
		this.close();
	};

	render() {
		return (
			<Dialog
				size="normal"
				title={this.props.dialogName}
				visible={this.state.showDialog}
				mask={this.props.mask}
				noDraggable={this.props.noDraggable}
				zIndex={this.props.zIndex}
				onCancel={this.close}
				footer={false}
			>
				{this.state.showDialog && (
					<FilesList
						onSelect={this.selectImage}
						filter={this.props.filter}
						fileType={this.props.fileType}
					/>
				)}
			</Dialog>
		);
	}
}

ImageSelectorDialog.propTypes = {
	className: PropTypes.string,
	onSelect: PropTypes.func,
	onClose: PropTypes.func,
	noDraggable: PropTypes.bool,
	mask: PropTypes.bool,
	zIndex: PropTypes.number,
	filter: PropTypes.object,
	dialogName: PropTypes.string,

	fileType: PropTypes.oneOf(['pdf', 'img']),
};

ImageSelectorDialog.defaultProps = {
	className: '',
	visible: false,
	noDraggable: true,
	mask: true,
	zIndex: 2,
	dialogName: t`Imagen`,
	fileType: 'img',
};

export default ImageSelectorDialog;
