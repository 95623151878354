import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Video',
						fieldName: 'video',
						title: t`Vídeo`,
						placeholder: t`Selecciona un video`,
					},
				],
			},
		],
	},
];

export default editionFields;
