import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import LayerMoreInfo from 'businessLogic/core/shared/Modals/LayerMoreInfo';
import LayerTrigger from 'businessLogic/core/shared/Buttons/LayerTrigger';
import brickImage from './img/brickPreview.jpg';
import brickImage2 from './img/brickPreview2.png';
import editionFields from './edit.js';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import './styles.scss';

class ShowInfoThreeImages extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Más información con composición de tres imágenes`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickImage2 = brickImage2;
	static brickDefaultData = () => ({
		buttonText: _('Más información'),
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
			},
		],
	});
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			showMoreInfo: false,
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	showInfo = () => {
		this.setState({
			showMoreInfo: true,
		});

		this.props.brickEvent('showInfo');
	};
	hideInfo = () => {
		this.setState({
			showMoreInfo: false,
		});

		this.props.brickEvent('hideInfo');
	};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const images = data.images || [];

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ShowInfoThreeImages']);
		const classes = classNames({
			'brick': true,
			'show-info-three-image-composition': true,
		});

		let creditsList = images.map((item, index) => {
			if (item.imageObj.credit) {
				return (
					<li key={index} className="caption-credit">
						<span>{index + 1 + '. '}</span> {item.imageObj.credit}
					</li>
				);
			}
			return null;
		});

		return (
			<Container
				size="extra-large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div className="show-info-three-image-composition__wrapper">
						<Animation
							playAnimation={this.state.playEffect}
							delay={1}
							effect={Array.isArray(brickAnimation) ? brickAnimation[0] : brickAnimation}
						>
							<div className="image-wrap image1">
								<div className="the-img">
									<NewImageClickable imgObj={images[0].imageObj} />
								</div>

								{images[0].imageObj.credit && (
									<div className="image-caption">
										<div className="caption-credit"> {images[0].imageObj.credit} </div>
									</div>
								)}
							</div>
						</Animation>
						<Animation
							playAnimation={this.state.playEffect}
							delay={1.5}
							effect={Array.isArray(brickAnimation) ? brickAnimation[1] : brickAnimation}
						>
							<div className="image-wrap image2">
								<div className="the-img">
									<NewImageClickable imgObj={images[1].imageObj} />
								</div>
								{images[1].imageObj.credit && (
									<div className="image-caption">
										<div className="caption-credit"> {images[1].imageObj.credit} </div>
									</div>
								)}
							</div>
						</Animation>
						<Animation
							playAnimation={this.state.playEffect}
							delay={2}
							effect={Array.isArray(brickAnimation) ? brickAnimation[2] : brickAnimation}
						>
							<div className="image-wrap image3">
								<div className="the-img">
									<NewImageClickable imgObj={images[2].imageObj} />
									<LayerTrigger onShow={this.showInfo} buttonText={data.buttonText} />
								</div>
								{images[2].imageObj.credit && (
									<div className="image-caption image-caption--right">
										<div className="caption-credit"> {images[2].imageObj.credit} </div>
									</div>
								)}
							</div>
						</Animation>
					</div>

					<ul className="brick__caption--credits">{creditsList}</ul>
				</div>
				<LayerMoreInfo
					show={this.state.showMoreInfo}
					onClose={this.hideInfo}
					mainProps={this.props}
					editMode={this.props.editMode}
				/>
			</Container>
		);
	}
}

export default brickWrapper(ShowInfoThreeImages);
