import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './font/icons.css';
import './styles.scss';
import MaterialUiIcon from './components/MaterialUiIcon';

class Icon extends PureComponent {
	render() {
		const {className, name, size, rotate, mirrowX, mirrowY, title, color} = this.props;
		const classes = classNames({
			'icon-comp': true,
			[`icon-comp-${name}`]: true,
			[`icon-comp--size-${size}`]: true,
			'icon-comp--mirrowX': mirrowX,
			'icon-comp--mirrowY': mirrowY,
			[`${className}`]: true,
		});
		const rotateClass = {
			transform: 'rotate(' + {rotate} + 'deg)',
			color,
		};

		// Comprobamos si es un icono propio o de librería externa
		if (name) {
			const nameParts = name.split('.');
			if (nameParts.length > 1) {
				if (nameParts[0] === 'materialui') {
					return (
						<MaterialUiIcon
							name={nameParts[1]}
							className={classes}
							style={rotateClass}
							title={title}
						/>
					);
				} else {
					return null;
				}
			}
		}

		return <i className={classes} style={rotateClass} title={title} />;
	}
}

Icon.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	size: PropTypes.oneOf(['small', 'normal', 'large']),
	rotate: PropTypes.number,
	mirrowX: PropTypes.bool,
	mirrowY: PropTypes.bool,
	title: PropTypes.string,
};

Icon.defaultProps = {
	className: '',
	size: 'normal',
	rotate: 0,
	mirrowX: false,
	mirrowY: false,
};

export default Icon;
