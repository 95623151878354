import React, {PureComponent} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Input from 'ui/shared/FormFields/Input';
import './styles.scss';

class InputCustomCheckBox extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			checked: this.props.checked || false,
		};
	}
	ifChecked = (event) => {
		this.setState({checked: event.target.checked});
		this.props.setData(event);
	};
	getCheckedStatus = () => {
		if (this.props.checked !== undefined) {
			return this.props.checked;
		}
		return this.state.checked;
	};
	render() {
		const classes = classNames({
			'input-custom-checkbox': true,
			[this.props.answer]: true,
		});
		const checked = classNames({
			'input-custom-checkbox__check': true,
			'checked': this.getCheckedStatus(),
		});

		return (
			<div className={classes}>
				<Input
					errorText={this.props.errorText}
					type="checkbox"
					className="input-custom-checkbox__input"
					onChange={this.ifChecked}
					theme="checkbox"
					ref="input"
					name={this.props.name}
					checked={this.getCheckedStatus()}
				/>
				<div className={checked}>
					<div className="input-custom-checkbox__check__icon__bar input-custom-checkbox__check__icon__bar--left" />
					<div className="input-custom-checkbox__check__icon__bar input-custom-checkbox__check__icon__bar--right" />
				</div>
				{this.props.children}
			</div>
		);
	}
}

InputCustomCheckBox.propTypes = {
	answer: PropTypes.string,
	setData: PropTypes.func,
	checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

InputCustomCheckBox.defaultProps = {
	answer: '',
};

export default InputCustomCheckBox;
