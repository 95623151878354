import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import VideoImage from 'businessLogic/shared/Video/VideoImage';
import VideoCaption from 'businessLogic/shared/Video/VideoCaption';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';
import './styles.scss';

class YouTubeDefault extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			viewImageVideo: true,
			videoTime: '--:--',
			player: null,
		};
	}

	componentDidMount() {
		this.calculateTotalTime();
		if (this.state.player !== null) {
			if (this.props.autoPlay === true) {
				this.state.player.playVideo();
			} else {
				this.state.player.pauseVideo();
			}
		}
	}
	componentDidUpdate() {
		if (this.state.player !== null) {
			if (this.props.autoPlay === true) {
				this.state.player.playVideo();
			}
		}
	}

	handleClickVideo = () => {
		this.setState({
			viewImageVideo: false,
		});
		if (this.state.player) {
			this.state.player.playVideo();
		}
	};

	onReady = (event) => {
		const player = event.target;
		this.setState({
			player,
		});
		let duration = player.getDuration();
		this.calculateTotalTime(duration);
	};

	calculateTotalTime = (duration) => {
		let minutes = Math.floor(duration / 60);
		if (minutes <= 10) {
			minutes = '0' + minutes;
		}
		let seconds_int = duration - minutes * 60;
		if (seconds_int <= 10) {
			seconds_int = '0' + seconds_int;
		}
		let seconds_str = seconds_int.toString();
		let seconds = seconds_str.substr(0, 2);
		let time = minutes + ':' + seconds;
		this.setState({
			videoTime: time,
		});
	};

	render() {
		const {videoCaption, description, credit, video, height, width} = this.props;

		const opts = {
			playerVars: {
				controls: this.props.controls === false ? 0 : 1,
				fs: 0,
				iv_load_policy: 3,
				showinfo: 0,
			},
			height: height,
			width: width,
		};

		return (
			<div className="vertical-video-youtube-container" ref="videoContainer">
				{this.props.videoCaption && (
					<VideoCaption
						description={description}
						credit={credit}
						videoState={this.state.viewImageVideo}
						videoTime={this.state.videoTime}
					/>
				)}
				{this.props.videoCaption && (
					<VideoImage
						onClick={this.handleClickVideo}
						image={videoCaption}
						videoState={this.state.viewImageVideo}
					/>
				)}
				<div className="vertical-video-youtube-container__video-embed">
					<YouTube videoId={video} onReady={this.onReady} opts={opts} />
				</div>
			</div>
		);
	}
}

YouTubeDefault.propTypes = {
	videoCaption: PropTypes.string,
	description: PropTypes.string,
	credit: PropTypes.string,
	video: PropTypes.string,
	controls: PropTypes.bool,
	autoPlay: PropTypes.bool,
};

YouTubeDefault.defaultProps = {
	videoCaption: '',
	description: '',
	credit: '',
	video: '',
	controls: true,
	autoPlay: false,
};

export default brickWrapper(YouTubeDefault);
