import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';
import Icon from 'ui/shared/Icon';

/**
 * Componente para gestionar los diferentes elementos que puede contener un ladrillo (las preguntas de un test, los puestos de un ranking... etc)
 */
class ElementBoxInDialog extends Component {
	handleClick = () => {
		if (this.props.onClick) {
			// 	const imgId = this.props.imgId !== undefined ? this.props.imgId : this.props.imageUrl;
			// 	this.props.onClick(imgId);
			this.props.onClick();
		}
	};
	handleEdit = () => {
		if (this.props.onEdit) {
			// 	const imgId = this.props.imgId !== undefined ? this.props.imgId : this.props.imageUrl;
			// 	this.props.onClick(imgId);
			this.props.onEdit();
		}
	};
	handleDelete = () => {
		if (this.props.onDelete) {
			// 	const imgId = this.props.imgId !== undefined ? this.props.imgId : this.props.imageUrl;
			// 	this.props.onDelete(imgId);
			this.props.onDelete();
		}
	};
	render() {
		const {className, labelBox, iconLabel, onClick, onEdit, onDelete, selected} = this.props;
		const classes = classNames({
			'ElementBoxinDialog-cmp': true,
			'ElementBoxinDialog-cmp--icon-label': iconLabel,
			'ElementBoxinDialog-cmp--clickable': onClick,
			'ElementBoxinDialog-cmp--editable': onEdit,
			'ElementBoxinDialog-cmp--delete': onDelete,
			'ElementBoxinDialog-cmp--selected': selected,
			[`${className}`]: true
		});

		return (
			<div className={classes}>
				<div className="ElementBoxinDialog-cmp__element-box" onClick={this.handleClick}>
					<div className="ElementBoxinDialog-cmp__element-box__icon-label">
						<Icon name={iconLabel} size="normal" />
					</div>
					{labelBox}
				</div>
				<div className="ElementBoxinDialog-cmp__element-icons">
					<div className="ElementBoxinDialog-cmp__element-icons--edit" onClick={this.handleEdit}>
						<Icon name="edit" size="normal" />
					</div>
					<div
						className="ElementBoxinDialog-cmp__element-icons--delete"
						onClick={this.handleDelete}
					>
						<Icon name="delete" size="normal" />
					</div>
				</div>
			</div>
		);
	}
}

ElementBoxInDialog.propTypes = {
	className: PropTypes.string,
	labelBox: PropTypes.string,
	iconLabel: PropTypes.string,
	onClick: PropTypes.func,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func,
	selected: PropTypes.bool
};

ElementBoxInDialog.defaultProps = {
	className: '',
	labelBox: '',
	iconLabel: '',
	onClick: null,
	onEdit: null,
	onDelete: null,
	selected: false
};

export default ElementBoxInDialog;
