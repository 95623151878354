import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

class ListOrderedWithBox extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Lista con números en cajas`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		listItems: [
			{
				title: 'Dusue nobista mante erase',
				text:
					'Ma quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur	as imendit aut ea quatis ent duci si sequia.',
			},
			{
				title: 'Dusue nobista mante erase',
				text:
					'Ma quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur	as imendit aut ea quatis ent duci si sequia.',
			},
		],
	};

	static brickDefaultConfig = {startNumber: 1};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick?.styles ?? {};
		const config = this.props.brick?.config ?? {};
		let items = data.listItems || [];

		const classes = classNames({
			'list-ordered-with-box-brick': true,
		});

		const baseNumber = parseInt(config?.startNumber || 1);

		let elements = items.map((listItem, index) => {
			const currentNumber = index + baseNumber;
			const classEvenOdd = currentNumber % 2 === 0 ? 'even' : 'odd';
			const currentNumberText = currentNumber <= 9 ? `0${currentNumber}` : `${currentNumber}`;

			return (
				<li key={index} className={classes + '__ul__li ' + classEvenOdd}>
					<div className={classes + '--left'}>
						<span className={classes + '--left__span'}>{currentNumberText + '.'}</span>
						<div className={classes + '--left__title'}>
							<FrontEditableText
								fieldName="title"
								text={listItem.title}
								set={(fieldPath, fieldData) => {
									//Propagamos la llamada al método set añadiendo mi nodo al path
									if (this.props.set)
										this.props.set(['listItems', index].concat(fieldPath), fieldData);
								}}
							/>
						</div>
					</div>
					<div className={classes + '--right'}>
						<FrontEditableText
							fieldName="text"
							text={listItem.text}
							set={(fieldPath, fieldData) => {
								//Propagamos la llamada al método set añadiendo mi nodo al path
								if (this.props.set)
									this.props.set(['listItems', index].concat(fieldPath), fieldData);
							}}
						/>
					</div>
				</li>
			);
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={'brick ' + classes}>
					<ul className="list-ordered-with-box-brick__ul">{elements}</ul>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ListOrderedWithBox);
