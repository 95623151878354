import React from 'react';
import {useSelected} from 'slate-react';
import Wiki from './components/Wiki';

const Element = ({attributes, children, element}) => {
	const selected = useSelected();
	let className = '';
	if (element.align) {
		className += `ql-align-${element.align} `;
	}
	if (element.indent) {
		className += `ql-indent-${element.indent} `;
	}

	switch (element.type) {
		case 'blockquote':
			return (
				<blockquote {...attributes} className={className}>
					{children}
				</blockquote>
			);
		case 'codeblock':
			return (
				<pre {...attributes} className={className}>
					<code>{children}</code>
				</pre>
			);
		case 'heading-one':
			return (
				<h1 {...attributes} className={className}>
					{children}
				</h1>
			);
		case 'heading-two':
			return (
				<h2 {...attributes} className={className}>
					{children}
				</h2>
			);
		case 'heading-three':
			return (
				<h3 {...attributes} className={className}>
					{children}
				</h3>
			);
		case 'heading-four':
			return (
				<h4 {...attributes} className={className}>
					{children}
				</h4>
			);
		case 'heading-five':
			return (
				<h5 {...attributes} className={className}>
					{children}
				</h5>
			);
		case 'heading-six':
			return (
				<h6 {...attributes} className={className}>
					{children}
				</h6>
			);
		case 'list-item':
			return (
				<li {...attributes} className={className}>
					{children}
				</li>
			);
		case 'numbered-list':
			return (
				<ol {...attributes} className={className}>
					{children}
				</ol>
			);
		case 'bulleted-list':
			return (
				<ul {...attributes} className={className}>
					{children}
				</ul>
			);
		case 'link':
			// console.log('element: ', element);

			return (
				<a
					{...attributes}
					className="my-link"
					href={element.url}
					target="_blank"
					rel="noopener noreferrer"
					style={{
						display: 'inline-block',
						position: 'relative',
						// textDecoration: 'none',
						color: 'inherit',
						fontWeight: selected ? 'bold' : 'normal',
						cursor: 'pointer',
					}}
				>
					{children}
				</a>
			);
		case 'wiki':
			// console.log('element: ', element);
			// console.log('attributes: ', attributes);
			return (
				<Wiki data={element.wikiData} selected={selected} {...attributes}>
					{children}
				</Wiki>
			);
		default:
			return (
				<p {...attributes} className={className}>
					{children}
				</p>
			);
	}
};

export default Element;
