import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DraggableTagLabel from 'businessLogic/core/shared/Label/DraggableTagLabel';
import Category from './components/Category';
import {toShufle} from 'businessLogic/helpers/functions';
import {DragDropContainer, DropTarget} from 'react-drag-drop-container';
import AlarmIcon from '@mui/icons-material/Alarm';
import ScoreIcon from '@mui/icons-material/Speed';
import './styles.scss';

class Test extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			finished: false,
			tagLabelDecks: {
				categoryA: [],
				categoryB: [],
				pendings: [],
			},
			feedback: undefined,
			activeCategory: undefined,
			showLabelsStack: true,
			isGrabbing: false,
			remainingResponseTime: props.initialQuestionTime,
			responsePoints: props.valueSuccess,
		};
		this.successes = 0;
		this.failures = 0;
		this.remainingTestTime = 0;
		this.labelsStack = this.toShufleTags(this.props.tagsCollection);
	}

	componentDidMount() {
		const pendings = this.toShufleTags(this.props.tagsCollection);
		this.setState({
			tagLabelDecks: {
				categoryA: [],
				categoryB: [],
				pendings,
			},
		});
		this.remainingLabels = pendings.length;
		this.startCounter();
	}

	//Crear la baraja con todos los labelsTags juntos y añade un campo a cada tag, con la categoría a la que pertenece
	getLabelsTags = (dataTagsCollection) => {
		let stack = [];
		dataTagsCollection.forEach((element, index) => {
			element.tags.forEach((tag) => {
				stack.push({tagName: tag.tagName, category: index});
			});
		});
		return stack;
	};

	/*Recibe un array de  toda la colección de tags y las baraja aleatoriamente */
	toShufleTags = (dataTagsCollection) => {
		return toShufle(this.getLabelsTags(dataTagsCollection));
	};

	/*Hace una pequeña parada en la animación del feedback, hasta que se muestra la siguiente tag */
	animationFeedback(duration) {
		if (this.state.feedback !== undefined) {
			// set state
			clearTimeout(this.resetFeedback);
			this.resetFeedback = setTimeout(() => {
				this.setState(
					(state) => {
						const newState = {
							feedback: undefined,
							showLabelsStack: true,
						};
						if (this.remainingLabels !== state.tagLabelDecks.pendings.length) {
							newState.remainingResponseTime = this.props.initialQuestionTime;
							newState.responsePoints = this.props.valueSuccess;
							this.remainingLabels = state.tagLabelDecks.pendings.length;
							this.counterStarted = false;
						}
						return newState;
					},
					() => {
						this.startCounter();
					},
				);
			}, duration);
		}
	}

	handleOnDrag = () => {
		this.setState({
			isGrabbing: true,
		});
	};
	handleOnDragEnd = () => {
		this.setState({
			isGrabbing: false,
		});
	};

	/*solo se dispara en caso de que las targetKey del elemento que se arrastra y el elemento de destino coinciden */
	handleOnDrop = (event) => {
		const labelTarget = event.dragData.target; //Tag
		const targetDeck = event.dropData.idCategory;

		/*Acierto */
		if (labelTarget === targetDeck) {
			this.setState((state) => {
				//Nueva referencia
				const updatedTagLabelDecks = Object.assign({}, state.tagLabelDecks);

				//Tag acertadas y montón sobre sobre el que debería de apilarse
				const successefulTagLabel = event.dragData.tag; //Tag
				const targetDeck = event.dropData.idCategory; //Destino

				/*1--Modificar la ubicación de cada targeta */
				updatedTagLabelDecks.pendings.shift();
				if (targetDeck === 0) {
					updatedTagLabelDecks.categoryA.push(successefulTagLabel);
				} else {
					updatedTagLabelDecks.categoryB.push(successefulTagLabel);
				}
				/*2--Recuento de puntos*/
				this.successes++;
				this.remainingTestTime = this.remainingTestTime + this.state.remainingResponseTime;

				/*3--Si el juego terminó, comunicar al padre con los resultados*/
				/*Comprobar si ha finalizado y ejecutar el handle del evento finalizar */
				let responsesCompleted = state.finished;
				if (updatedTagLabelDecks.pendings.length === 0) {
					responsesCompleted = true;
					this.handleFinishedQuestion(2000);
				}

				return {
					tagLabelDecks: updatedTagLabelDecks,
					finished: responsesCompleted,
					feedback: true,
					activeCategory: targetDeck,
					showLabelsStack: false,
				};
			});
		} else {
			/*Fallo*/
			// this.successes--;
			this.setState((state) => {
				if (state.responsePoints > 0) {
					this.successes--;
				}
				return {
					feedback: false,
					activeCategory: targetDeck,
					showLabelsStack: true,
					responsePoints: Math.max(0, state.responsePoints - this.props.valueSuccess),
				};
			});
		}

		this.animationFeedback(1500);
	};

	/*Se invoca cuando se considera que la pregunta está finalizada. Recoge los resultados y ejecuta el método onFinished declarado en la clase del Brick */
	handleFinishedQuestion = (duration) => {
		clearTimeout(this.goNextTimeoutHandler);

		this.goNextTimeoutHandler = setTimeout(() => {
			this.props.onFinished(this.successes, this.failures, this.remainingTestTime);
		}, duration);
	};

	startCounter = () => {
		// Reducimos el contador de segundos usando requestAnimationFrame
		if (this.state.remainingResponseTime && !this.counterStarted) {
			this.counterStarted = true;

			let lastTimestamp;
			let milisecondsFromStart = 0;

			const loop = (timestamp) => {
				if (!lastTimestamp) lastTimestamp = timestamp;
				const delta = timestamp - lastTimestamp;
				lastTimestamp = timestamp;
				let newRemainingTime = this.state.remainingResponseTime;
				// Solo contamos cuando se está mostrando la etiqueta
				if (this.state.showLabelsStack) {
					milisecondsFromStart += delta;

					newRemainingTime = Math.max(
						0,
						Math.round(this.props.initialQuestionTime - Math.round(milisecondsFromStart / 1000)),
					);

					this.setState({remainingResponseTime: newRemainingTime});
				}

				if (newRemainingTime > 0 && this.state.showLabelsStack) {
					requestAnimationFrame(loop);
				}
			};

			requestAnimationFrame(loop);
		}
	};

	render() {
		const mainStyleClass = 'classify-game-test-brick';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
		});
		const categories = this.props.tagsCollection;
		const stackLabelTag = this.state.tagLabelDecks.pendings;
		let counterIdTag = 0;

		const highlightPoints = this.state.responsePoints > 0 && this.state.feedback === true;
		const hidePoints = this.state.responsePoints <= 0;

		const highlightTime = this.state.remainingResponseTime > 0 && this.state.feedback === true;
		const hideTime = this.state.remainingResponseTime <= 0;

		return (
			<div className={classes}>
				<div className="row row--align--item--center--lg row--nowrap--lg">
					<div className="col-xs-6 col-lg-4 col--order1">
						<DropTarget
							targetKey={'category'}
							dropData={{idCategory: 0}}
							highlightClassName={'classify-game-test__category__grag-dover'}
						>
							<Category
								title={categories[0].category}
								tagsLabelSolution={this.state.tagLabelDecks.categoryA}
								feedback={this.state.activeCategory === 0 ? this.state.feedback : undefined}
							/>
						</DropTarget>
					</div>
					<div className="col-xs-6 col-lg-4 col--order3">
						<DropTarget
							targetKey={'category'}
							dropData={{idCategory: 1}}
							highlightClassName={'classify-game-test__category__grag-dover'}
						>
							<Category
								title={categories[1].category}
								tagsLabelSolution={this.state.tagLabelDecks.categoryB}
								feedback={this.state.activeCategory === 1 ? this.state.feedback : undefined}
							/>
						</DropTarget>
					</div>
					<div className="col-xs-12 col-lg-4 col--order2">
						<div className={mainStyleClass + '__stack'}>
							{this.state.showLabelsStack && stackLabelTag && stackLabelTag.length > 0 && (
								<DragDropContainer
									targetKey={'category'}
									dragData={{
										id: counterIdTag++,
										tag: stackLabelTag[0],
										target: stackLabelTag[0].category,
									}}
									disappearDraggedElement={true}
									onDrop={this.handleOnDrop}
									onDrag={this.handleOnDrag} //mientras se este arrastrando //cambiar la interación del cursor
									onDragEnd={this.handleOnDragEnd}
									render={() => {
										return (
											<DraggableTagLabel
												text={stackLabelTag[0].tagName}
												active={this.state.isGrabbing}
											/>
										);
									}}
								/>
							)}
						</div>
					</div>
				</div>
				{this.remainingLabels > 0 && (
					<div className={`${mainStyleClass}__score-info`}>
						{this.props.valueSuccess > 0 && (
							<div
								className={`${mainStyleClass}__score-info__element ${
									highlightPoints ? 'highlight' : ''
								} ${hidePoints ? 'hide' : ''}`}
							>
								<ScoreIcon />
								{this.props.valueSuccess}
							</div>
						)}
						{this.props.initialQuestionTime > 0 && (
							<div
								className={`${mainStyleClass}__score-info__element ${
									highlightTime ? 'highlight' : ''
								} ${hideTime ? 'hide' : ''}`}
							>
								<AlarmIcon />
								{this.state.remainingResponseTime}
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}
Test.propType = {
	tagsCollection: PropTypes.array,
	onFinished: PropTypes.func,
	valueSuccess: PropTypes.number,
	initialQuestionTime: PropTypes.number,
};

export default Test;
