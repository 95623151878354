import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import NumericSlider from './components/NumericSlider';
import {slugify} from '@createandshare/tools';
import PointScale from './components/PointScale';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import './styles.scss';

/****
Los valores de cada respuesta deben de ser guardado siempre con esta estructura 

			response: {
				[autoID]{
					value
				}
			}
	
**** */

class NumericSliderQuestion extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			response: {},
		};
	}

	canContinue = () => {
		if (!get(this.props, 'flexibleContent.required', false) || !isEmpty(this.state.response)) {
			return true;
		}
		return false;
	};

	parseTypeNumericInt = (string) => {
		if (isNaN(string) || string === '') return undefined;
		return parseInt(string, 10);
	};
	getPointsScale = () => {
		const min = this.parseTypeNumericInt(this.props.flexibleContent.min);
		const max = this.parseTypeNumericInt(this.props.flexibleContent.max);
		const scale = this.parseTypeNumericInt(this.props.flexibleContent.scale);

		const multiplesScale = [];
		if (scale === undefined || scale === 0) {
			return undefined;
		}
		for (let i = min; i <= max; i++) {
			if (i % scale === 0) {
				multiplesScale.push(i);
			}
		}
		return multiplesScale;
	};

	handleChange = (event, value) => {
		const name = this.props.flexibleContent._id;
		this.setState(
			{
				response: {
					[name]: {
						value,
					},
				},
			},
			this.sendDataQuestion,
		);
	};

	sendDataQuestion = () => {
		const dataState = Object.assign({}, this.state.response);
		const keyData = this.props.flexibleContent._id;
		const type = this.props.flexibleContent.flexibleContentType;
		const name = slugify(this.props.flexibleContent.name);
		if (dataState[keyData]) {
			Object.assign(dataState[keyData], {type}, {name});
			this.props.onFinishQuestion(dataState);
		}
	};

	render() {
		const mainStyleClass = 'numeric-slider-question';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
		});
		let {
			flexibleContent: {_id, statement, min, max, scale},
		} = this.props;

		min = this.parseTypeNumericInt(min) || 0;
		max = this.parseTypeNumericInt(max) || 10;
		scale = this.parseTypeNumericInt(scale) || 0;

		const pointScale = this.getPointsScale();
		const value = get(this.state.response, [_id.toString(), 'value']);

		return (
			<div className={classes}>
				<div className="row">
					<div className="col-xs-12 col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
						<div className={mainStyleClass + '__statement swiper-no-swiping'}>
							<FrontEditableText
								text={statement}
								fieldName="statement"
								set={(fieldPath, fieldData) => {
									//Propagamos la llamada al método set añadiendo mi nodo al path
									if (this.props.set)
										this.props.set(
											['flexibleContent', this.props.index].concat(fieldPath),
											fieldData,
										);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-10 col-xs-offset-1 col-sm-12 col-sm-offset-0 col-xl-8 col-xl-offset-2">
						<div className={mainStyleClass + '__slider swiper-no-swiping'}>
							<NumericSlider
								name={_id}
								value={value}
								min={min}
								max={max}
								onChange={this.handleChange}
							/>
							<React.Fragment>
								<PointScale point={min} posX={0} passed={min <= value ? true : false} />
								<PointScale point={max} posX={100} passed={max <= value ? true : false} />
							</React.Fragment>
							{(scale || scale !== 0) &&
								pointScale !== undefined &&
								pointScale.map((itemPoint, indexPoint) => {
									return (
										<PointScale
											key={indexPoint}
											point={itemPoint}
											posX={((itemPoint - min) / (max - min)) * 100}
											passed={itemPoint <= value ? true : false}
										/>
									);
								})}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

NumericSliderQuestion.propTypes = {
	flexibleContent: PropTypes.object,
	onFinishQuestion: PropTypes.func,
	set: PropTypes.func,
	index: PropTypes.number,
};

NumericSliderQuestion.defaultProps = {
	flexibleContent: {
		min: 0,
		max: 100,
		scale: 10,
	},
};

export default NumericSliderQuestion;
