import React from 'react';
import {useSlate} from 'slate-react';
import ToggleButton from '../basics/ToggleButton';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import SubscriptIcon from '@mui/icons-material/Subscript';
import SuperscriptIcon from '@mui/icons-material/Superscript';

const Icon = ({format}) => {
	switch (format) {
		case 'bold':
			return <FormatBoldIcon />;
		case 'italic':
			return <FormatItalicIcon />;
		case 'underlined':
			return <FormatUnderlinedIcon />;
		case 'strikethrough':
			return <FormatStrikethroughIcon />;
		case 'subscript':
			return <SubscriptIcon />;
		case 'superscript':
			return <SuperscriptIcon />;
		default:
			return null;
	}
};

const StyledToggleMarkButton = ({format}) => {
	const editor = useSlate();
	const {isMarkActive, toggleMark} = editor.helpers;

	const isActive = isMarkActive(editor, format);

	return (
		<ToggleButton format={format} onClick={() => toggleMark(editor, format)} selected={isActive}>
			<Icon format={format} />
		</ToggleButton>
	);
};

export default StyledToggleMarkButton;
