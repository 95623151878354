import gql from 'graphql-tag';

export const AUDIOS_QUERY = gql`
	query Audios($offset: Int, $limit: Int) {
		audios(offset: $offset, limit: $limit) {
			id
			name
			path
			author
		}
	}
`;

export const AUDIO_ADD_SUBSCRIBE = gql`
	subscription AddAudioSubscription($identificator: String) {
		addAudioSubscription(identificator: $identificator) {
			id
			name
			path
			author
		}
	}
`;

export const AUDIO_DELETE_SUBSCRIBE = gql`
	subscription DeleteAudioSubscription($identificator: String) {
		deleteAudioSubscription(identificator: $identificator) {
			id
			name
			path
			author
		}
	}
`;

export const AUDIO_ADD_MUTATION = gql`
	mutation addAudio($input: MediaInput!, $identificator: String) {
		addAudio(input: $input, identificator: $identificator) {
			id
			name
			path
			author
		}
	}
`;

export const AUDIO_DELETE_MUTATION = gql`
	mutation deleteAudio($idAudio: String!, $identificator: String) {
		deleteAudio(idAudio: $idAudio, identificator: $identificator) {
			id
			deleted
		}
	}
`;
