import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import userPerms from '@createandshare/userperms';

import SimpleTestIntro from 'businessLogic/core/shared/Test/SimpleTestIntro';
import Test from './components/Test';
import TestResult from 'businessLogic/core/shared/Test/TestResult';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Element} from 'react-scroll';
import get from 'lodash/get';

import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import InstructionTestBrick from 'businessLogic/core/shared/Test/InstructionTestBrick';
import CallActionTestBrickWithBoxes from 'businessLogic/core/shared/Test/CallActionTestBrickWithBoxes';
import ModalForTestBricks from 'businessLogic/core/shared/Test/ModalForTestBricks';
import './styles.scss';

class DiscoverImage extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Descubre la imagen`;
	static brickIcon = 'test';
	static brickImage = brickImage;
	static brickHelpText = () => t`Juego con imágenes y límite de tiempo`;
	static canReload = true;
	static brickDefaultConfig = () => ({
		pointSuccess: 15,
		pointFailure: 0,
		brickName: t`Descubre la imagen`,
		brickDescription: t`Permite recoger preferencias del usuario a través de una clasificación de elementos. `,
		requiredActions: true,
	});
	static brickDefaultData = () => ({
		image: {
			imageSrc: '/uploads/default-images/ilustration_test.svg',
			credit: '',
		},
		textLinkInstructions: '',
		instructions: '',
		beforeHeading: _('Descubre la imagen'),
		title: _('Descubre la imagen'),
		descriptionTest: _(
			'Instrucciones de funcionamiento del test. Marca las casillas correctas y envía tu respuesta',
		),
		textButtonStart: _('EMPEZAR'),
		textResult: _('¡Buen trabajo!'),
		questions: [
			{
				question: '¿Lorem ipsum dolor sit amet consectetur adipiscing elit?',
				questionImageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
				answers: [
					{
						answer: 'Sociis feugiat nulla venenatis aenean, netus suspendisse.',
					},
					{
						answer: 'Lorem ipsum dolor',
					},
				],
			},
			{
				question: '¿Magnis velit aptent diam dapibus, sodales porta.?',
				questionImageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
				answers: [
					{
						answer: 'Viverra fusce ',
					},
					{
						answer: 'Vestibulum sodales',
					},
				],
			},
		],
	});

	/*El estado stateMachine, simula una máquina de Estados que contempla a su vez 3 posibles estados del componente: estado intro, estado test, estado result */
	constructor(props) {
		super(props);
		this.state = {
			type: 'DiscoverImage',
			completed: false,
			success: false,
			stateMachine: 'intro',
			successes: 0,
			failures: 0,
			completedQuestions: 0,
			coefSuccess: 0,
			score: {
				min: 0,
				max: 0,
				raw: 0,
				scaled: 0,
			},
		};
		this.testDiscoverImage = React.createRef();
		this.props.getBrickStateData('state', this.state).then((state) => {
			this.setState(state);
		});
	}

	saveBrickState = () => {
		this.props.setBrickStateData('state', this.state);
	};

	nextHandle = () => {
		if (this.finishHandle()) {
			this.initStateTestResult();
		} else {
			this.testDiscoverImage.current.goNext();
		}
	};

	finishHandle = () => {
		if (this.props.brick.data.questions.length === this.state.completedQuestions) {
			return true;
		}
		return false;
	};
	handleScrollTest = () => {
		// scroller.scrollTo("brick--" + this.props.brickId, {
		// 	smooth: false,
		// 	containerId: "scroll-container",
		// 	offset: 0
		// });
	};

	getScore = () => {
		const valueFailure = get(this.props.brick.config, 'pointFailure', 0);
		const valueSuccess = get(this.props.brick.config, 'pointSuccess', 0);
		const maxScore = this.state.completedQuestions * parseInt(valueSuccess, 10);
		const totalFailures = this.state.failures * parseInt(valueFailure, 10);
		const userScore = parseFloat(valueSuccess) * parseFloat(this.state.coefSuccess) - totalFailures;
		return {
			min: 0,
			max: maxScore,
			raw: userScore,
			scaled: userScore / maxScore,
		};
	};

	initStateTestQuestion = () => {
		this.setState({stateMachine: 'test'}, this.saveBrickState);
		this.handleScrollTest();

		//Lanzamos el evento de inicio de test
		this.props.brickEvent('start');
	};
	initStateTestResult = () => {
		const score = this.getScore();
		if (this.finishHandle()) {
			this.setState(
				{
					stateMachine: 'result',
					score,
					completed: true,
					success: true,
				},
				this.saveBrickState,
			);
			this.handleScrollTest();
		}

		//Lanzamos el evento de fin de test
		this.props.brickEvent('end');

		//Marcamos el test como completado
		const status = {
			progress: 100,
			completed: true,
			success: true,
			score,
		};
		this.props.setBrickStateData('status', status).then(() => {
			this.props.configObject.callEventListeners('brickStatusChanged', {
				brick: 'DiscoverImage',
				type: 'evaluate',
				status,
			});
		});
	};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const questions = data.questions || [];
		const classes = classNames({
			'brick': true,
			'discover-image-brick': true,
		});
		const pointFailure = get(this.props.brick.config, 'pointFailure', 0);
		const pointSuccess = get(this.props.brick.config, 'pointSuccess', 0);
		let step;
		switch (this.state.stateMachine) {
			default:
			case 'intro':
				step = (
					<CSSTransition key="intro" timeout={500} classNames="show">
						<div className="step stept--1">
							<CallActionTestBrickWithBoxes
								type="toStart"
								textButton={data.textButtonStart}
								beforeHeading={data.beforeHeading}
								image={data.image}
								onClick={this.initStateTestQuestion}
								set={this.props.set}
								className={classes}
								requireLogin={userPerms.gamesRequireLogin()}
							>
								<SimpleTestIntro
									textTitle={data.title}
									textDescription={data.descriptionTest}
									textButton={data.textButtonStart}
									valueSuccess={pointSuccess}
									valueFailure={pointFailure}
									onClick={this.initStateTestQuestion}
									set={this.props.set}
								/>
								<InstructionTestBrick
									textLabel={data.textLinkInstructions}
									textHelp={data.instructions}
									theme="dark"
								/>
							</CallActionTestBrickWithBoxes>
						</div>
					</CSSTransition>
				);
				break;
			case 'test':
				step = (
					<CSSTransition key="test" timeout={500} classNames="show">
						<div className="step stept--2">
							<ModalForTestBricks
								canClose={false}
								onClose={this.initStateTestResult}
								isEditMode={this.props.editMode}
							>
								<Test
									ref={this.testDiscoverImage}
									questionsCollection={questions}
									set={this.props.set}
									onFishedQuestion={(successes, failures, coefSuccess) => {
										this.setState(
											(state) => {
												return {
													successes: state.successes + successes,
													failures: state.failures + failures,
													completedQuestions: state.completedQuestions + 1,
													coefSuccess: state.coefSuccess + coefSuccess,
												};
											},
											() => this.nextHandle(),
										);
									}}
									onScroll={this.handleScrollTest}
								/>
							</ModalForTestBricks>
						</div>
					</CSSTransition>
				);
				break;
			case 'result':
				step = (
					<CSSTransition key="tresult" timeout={500} classNames="show">
						<div className="step stept--3">
							<TestResult score={this.state.score} />
						</div>
					</CSSTransition>
				);
				break;
		}
		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<Element name={'brick--' + this.props.brickId}>
					<div className={classes}>
						{/* <div className="discover-image-brick__beforeHeading">
							<FrontEditableText
								fieldName="beforeHeading"
								text={data.beforeHeading}
								set={this.props.set}
							/>
						</div> */}

						<TransitionGroup className="todo-list">{step}</TransitionGroup>
					</div>
				</Element>
			</Container>
		);
	}
}

export default brickWrapper(DiscoverImage);
