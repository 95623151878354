import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import brickImage from './img/brickPreview.jpg';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class ImportantTextWithPhotoBox extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Cita con imagen - estilo 2`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		imageObj: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
		},
		text: '"Feri dem eos volorat. Archil iusdaecto inveria nditat.Hiligent quae re pre la doloremporro magni od quiatur a sunditate cuptas dolestrunt, cus ipiendis into el magnis soluptiunt dunt"',
		author: 'ARCHIL IUSDAECTO',
	};

	static brickDefaultConfig = {};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ImportantTextWithPhotoBox']);

		const classes = classNames({
			'important-text-with-photo-box-brick': true,
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<Animation
					playAnimation={this.state.playEffect}
					delay={1}
					effect={brickAnimation === 'fade-scale' ? undefined : brickAnimation}
				>
					<div className={classes + ' brick'}>
						<div className={classes + '__author'}>
							<FrontEditableText fieldName="author" text={data.author} set={this.props.set} />
						</div>
						<div className={classes + '__content'}>
							<div className={classes + '__content__photo'}>
								<Animation
									playAnimation={this.state.playEffect}
									delay={1}
									effect={brickAnimation === 'fade-scale' ? brickAnimation : undefined}
								>
									<NewImageClickable
										imgObj={data.imageObj}
										defaultVisible={true}
										clickable={false}
									/>
								</Animation>
							</div>
							<div className={classes + '__content__text'}>
								<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
							</div>
						</div>
					</div>
				</Animation>
			</Container>
		);
	}
}

export default brickWrapper(ImportantTextWithPhotoBox);
