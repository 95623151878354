import React, {PureComponent} from 'react';
import {BrickSwitch} from './';
import PillConfigContext from 'businessLogic/contexts/PillConfigContext';
import courseStateManager from 'businessLogic/services/CourseStateManager';
import brickAnomDataManager from 'businessLogic/services/BrickAnomDataManager';
import statLogger from 'businessLogic/services/StatLogger';
import {
	getBrick,
	deleteBrick,
	updateBrick,
	mutationDeleteBrick,
	mutationUpdateBrick,
	addNewChildBrickAsync,
} from 'businessLogic/store/data/pills';
import store from 'businessLogic/store/store.js';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import Logger from 'businessLogic/services/Logger';

function brickWrapper(WrapperComponent) {
	return class extends PureComponent {
		static editionFields = WrapperComponent.editionFields;
		static getName = () => {
			if (WrapperComponent.brickName) {
				if (typeof WrapperComponent.brickName === 'function') {
					return WrapperComponent.brickName();
				}
				return WrapperComponent.brickName;
			}
			return '';
		};
		static getHelpText = () => {
			if (WrapperComponent.brickHelpText) {
				if (typeof WrapperComponent.brickHelpText === 'function') {
					return WrapperComponent.brickHelpText();
				}
				return WrapperComponent.brickHelpText;
			}
			return '';
		};
		static getIcon = () => {
			if (WrapperComponent.brickIcon) {
				if (typeof WrapperComponent.brickIcon === 'function') {
					return WrapperComponent.brickIcon();
				}
				return WrapperComponent.brickIcon;
			}
			return '';
		};
		static getImage = () => {
			if (WrapperComponent.brickImage) {
				if (typeof WrapperComponent.brickImage === 'function') {
					return WrapperComponent.brickImage();
				}
				return WrapperComponent.brickImage;
			}
			return '';
		};

		static getImage2 = () => {
			if (WrapperComponent.brickImage2) {
				if (typeof WrapperComponent.brickImage2 === 'function') {
					return WrapperComponent.brickImage2();
				}
				return WrapperComponent.brickImage2;
			}
			return '';
		};
		static getDefaultData = () => {
			if (WrapperComponent.brickDefaultData) {
				if (typeof WrapperComponent.brickDefaultData === 'function') {
					return WrapperComponent.brickDefaultData();
				}
				return WrapperComponent.brickDefaultData;
			}
			return {};
		};
		static getDefaultConfig = () => {
			if (WrapperComponent.brickDefaultConfig) {
				if (typeof WrapperComponent.brickDefaultConfig === 'function') {
					return WrapperComponent.brickDefaultConfig();
				}
				return WrapperComponent.brickDefaultConfig;
			}
			return {};
		};
		static getDefaultStyles = () => {
			if (WrapperComponent.brickDefaultStyles) {
				if (typeof WrapperComponent.brickDefaultStyles === 'function') {
					return WrapperComponent.brickDefaultStyles();
				}
				return WrapperComponent.brickDefaultStyles;
			}
			return {};
		};
		static getHeaders = (brickData) => {
			if (WrapperComponent.getHeaders) {
				return WrapperComponent.getHeaders(brickData);
			}
			return [];
		};
		static canReload = () => !!WrapperComponent.canReload;

		static disableEdit = () => !!WrapperComponent.disableEdit;

		constructor(props) {
			super(props);

			//Creamos una referencia para acceder a la instancia del ladrillo
			this.wrapperComponentRef = React.createRef();

			//Creamos otra referencia para acceder al contenedor del ladrillo y gestionar cuando está visible
			this.brickContainerRef = React.createRef();
		}

		get = (key) => this.props.brick[key];
		getlistChildren = (key) => get(this.props.brick, ['children', key], []);
		getConfig = (key) => get(this.props.brick, ['config', key]);
		showEditDialog = () => {
			if (this.props.editMode && this.props.editBrick) {
				this.props.editBrick(this.props.brickId);
			}
		};
		//Para borrar un ladrillo, hay que quitar el identificador de la lista de hijos del padre
		//Esto implica que es el padre el que debe actualizarse cuando se ejecuta la acción de borrar sobre un ladrillo
		//Creamos una función que pasaremos a los hijos de un ladrillo, para que notifiquen al padre su borrado
		deleteBrick = (brickId) => {
			let newBrick = cloneDeep(this.props.brick);

			//Recorremos los listados de hijos del ladrillo para borrar las referencias al que queremos borrar
			Object.keys(newBrick.children).forEach((key) => {
				const filteredChildList = newBrick.children[key].filter((bid) => bid !== brickId);
				newBrick.children[key] = filteredChildList;
			});

			//Actualizamos el store
			updateBrick(this.props.pillId, newBrick);
			deleteBrick(this.props.pillId, brickId);

			//Enviamos al servidor la petición de borrar la píldora
			mutationDeleteBrick(this.props.pillId, brickId);
		};

		duplicateBrick = async (brickId, parentBrickId) => {
			// Buscamos el listado de hijos en el que está el ladrillo y su índice
			const childLists = this.props.brick.children;
			let childList = 'default';
			let brickIndex;
			for (const childListKey of Object.keys(childLists)) {
				brickIndex = childLists[childListKey].findIndex((bid) => bid === brickId);
				if (brickIndex >= 0) {
					childList = childListKey;
					break;
				}
			}

			// Obtenemos la información del ladrillo que queremos duplicar
			const brick = getBrick(store.getState(), this.props.pillId, brickId);

			// Añadimos un nuevo ladrillo con la información del anterior en el índice siguiente
			const newBrickId = await addNewChildBrickAsync(
				this.props.pillId,
				parentBrickId || this.props.brick.id,
				brick.type,
				brickIndex + 1,
				childList,
				brick.config,
				brick.data,
				brick.styles,
				brick.children,
			);

			// Actualizamos la lista de hijos, duplicando también los hijos del ladrillo
			const newBrick = getBrick(store.getState(), this.props.pillId, newBrickId);
			newBrick.children = cloneDeep(brick.children);
			const childrenKeys = Object.keys(newBrick.children);
			for (const childKey of childrenKeys) {
				newBrick.children[childKey] = await Promise.all(
					newBrick.children[childKey].map((bid) => {
						return this.duplicateBrick(bid, newBrickId);
					}),
				);
			}

			//Actualizamos el store
			updateBrick(this.props.pillId, newBrick);

			//Enviamos al servidor la actualización del ladrillo
			mutationUpdateBrick(this.props.pillId, newBrick.id, newBrick);

			return newBrickId;
		};

		//Función que pasamos a los hijos para permitirles moverse arriba
		moveUp = (brickId) => {
			let newBrick = cloneDeep(this.props.brick);

			//Recorremos los listados de hijos del ladrillo para encontrar el ladrillo y modificar su índice
			Object.keys(newBrick.children).forEach((key) => {
				let brickIndex = -1;
				//Filtramos la lista de hijos para eliminar el elemento que queremos mover, a la vez que obtenemos su índice actual
				const filteredChildList = newBrick.children[key].filter((bid, index) => {
					if (bid !== brickId) {
						return true;
					}
					brickIndex = index;
					return false;
				});

				//Ahora añadimos el elemento en su nuevo índice
				if (brickIndex > 0) {
					filteredChildList.splice(brickIndex - 1, 0, brickId);
					newBrick.children[key] = filteredChildList;
				}
			});

			//Actualizamos el store
			updateBrick(this.props.pillId, newBrick);

			//Enviamos al servidor la actualización del ladrillo
			mutationUpdateBrick(this.props.pillId, newBrick.id, newBrick);

			//Solicitamos al ladrillo que actualice la configuración
			this.configMethods.updateConfigRanges();
		};

		//Función que pasamos a los hijos para permitirles moverse abajo
		moveDown = (brickId) => {
			let newBrick = cloneDeep(this.props.brick);

			//Recorremos los listados de hijos del ladrillo para encontrar el ladrillo y modificar su índice
			Object.keys(newBrick.children).forEach((key) => {
				let brickIndex = -1;
				//Filtramos la lista de hijos para eliminar el elemento que queremos mover, a la vez que obtenemos su índice actual
				const filteredChildList = newBrick.children[key].filter((bid, index) => {
					if (bid !== brickId) {
						return true;
					}
					brickIndex = index;
					return false;
				});

				//Ahora añadimos el elemento en su nuevo índice
				if (brickIndex >= 0) {
					filteredChildList.splice(brickIndex + 1, 0, brickId);
					newBrick.children[key] = filteredChildList;
				}
			});

			//Actualizamos el store
			updateBrick(this.props.pillId, newBrick);

			//Enviamos al servidor la actualización del ladrillo
			mutationUpdateBrick(this.props.pillId, newBrick.id, newBrick);

			//Solicitamos al ladrillo que actualice la configuración
			this.configMethods.updateConfigRanges();
		};

		//fieldPath puede ser un string, un índice, o un array de string o índices
		set = (fieldPath, fieldData) => {
			const newBrick = cloneDeep(this.props.brick);
			set(newBrick.data, fieldPath, fieldData);

			updateBrick(this.props.pillId, newBrick);
			this.programServerUpdate(newBrick);
		};

		programServerUpdate = (newBrick) => {
			this.brickDataToUpdate = newBrick;
			if (this.timeoutHandle) clearTimeout(this.timeoutHandle);
			this.timeoutHandle = setTimeout(() => {
				this.serverUpdate();
			}, 500);
		};

		serverUpdate = () => {
			mutationUpdateBrick(this.props.pillId, this.brickDataToUpdate.id, this.brickDataToUpdate);
			this.timeoutHandle = null;
		};

		handleVisibilityChange = (isVisible) => {
			if (this.wrapperComponentRef?.current?.handleVisibilityChange) {
				this.wrapperComponentRef.current.handleVisibilityChange(isVisible);
			}
		};

		setBrickStateData = async (path, data) => {
			//Solo almacenamos la información si no estamos en modo edición
			if (!this.props.editMode) {
				await courseStateManager.setBrickData(this.props.pillId, this.props.brickId, path, data);
			}
		};

		getBrickStateData = async (path, defaultData) => {
			//Solo devolvemos la información si no estamos en modo edición
			if (!this.props.editBrick) {
				return await courseStateManager.getBrickData(
					this.props.pillId,
					this.props.brickId,
					path,
					defaultData,
				);
			}
			return defaultData;
		};

		setBrickAnomData = async (data) => {
			//Solo almacenamos la información si no estamos en modo edición
			if (!this.props.editMode) {
				await brickAnomDataManager.setBrickAnomData(this.props.pillId, this.props.brickId, data);
			}
		};

		brickAnomDataExists = () => {
			//Solo almacenamos la información si no estamos en modo edición
			if (!this.props.editMode) {
				return brickAnomDataManager.brickAnomDataExists(this.props.pillId, this.props.brickId);
			}

			return false;
		};

		brickEvent = (brickAction) => {
			statLogger.brickEvent({
				section: this.props.pill.section,
				pillId: this.props.pillId,
				brickId: this.props.brickId,
				brickType: this.props.brick.type,
				parentId: this.props.parentId,
				brickAction,
			});
		};

		componentDidMount() {
			this.configMethods = this.wrapperComponentRef.current.props.configObject;

			//Añadimos el ladrillo a la observación de intersección
			this.configMethods.observeBrick(this.brickContainerRef.current, this.handleVisibilityChange);
		}

		componentWillUnmount() {
			if (this.timeoutHandle) {
				clearTimeout(this.timeoutHandle);
				this.serverUpdate();
			}

			//Dejamos de observar el ladrillo
			this.configMethods.unobserveBrick(this.brickContainerRef.current);
		}
		//Renderiza los ladrillos del campo children
		renderChildren = (childrenPropName = 'default', options) =>
			this.renderBricks(this.getlistChildren(childrenPropName), options, childrenPropName);
		//Renderiza los ladrillos contenidos en el array de ids pasado por parámetro
		//Options es un objeto con opciones para el renderizado del ladrillo, puede incluir dos propiedades:
		//includeAddBrickButton: Bool
		//includeEditButtons: bool
		renderBricks = (brickList, options, childList = 'default') =>
			brickList.map((brickId, index) => {
				const actions = {
					addBrick: this.props.addBrick,
					editBrick: this.props.editBrick,
					deleteBrick: this.deleteBrick,
					duplicateBrick: this.duplicateBrick,
				};

				if (index > 0) {
					actions.moveUp = this.moveUp;
				}
				if (index < brickList.length - 1) {
					actions.moveDown = this.moveDown;
				}
				return (
					<BrickSwitch
						key={brickId}
						pillId={this.props.pillId}
						parentType={this.props.parentType}
						parentId={this.props.parentId}
						pill={this.props.pill}
						brickId={brickId}
						editMode={this.props.editMode}
						brickIndex={index}
						options={options}
						childList={childList}
						parentBrickId={this.props.brick.id}
						{...actions}
					/>
				);
			});
		render() {
			return (
				<Logger.ErrorBoundary beforeCapture={() => Logger.setTag('codeBlock', 'brick')}>
					<PillConfigContext.Consumer>
						{(configObject) => (
							<div ref={this.brickContainerRef}>
								<WrapperComponent
									ref={this.wrapperComponentRef}
									renderChildren={this.renderChildren}
									renderBricks={this.renderBricks}
									get={this.get}
									set={this.set}
									getlistChildren={this.getlistChildren}
									getConfig={this.getConfig}
									setBrickStateData={this.setBrickStateData}
									getBrickStateData={this.getBrickStateData}
									setBrickAnomData={this.setBrickAnomData}
									brickAnomDataExists={this.brickAnomDataExists}
									brickEvent={this.brickEvent}
									showEditDialog={this.showEditDialog}
									configObject={configObject}
									courseStateManager={courseStateManager}
									{...this.props}
								/>
							</div>
						)}
					</PillConfigContext.Consumer>
				</Logger.ErrorBoundary>
			);
		}
	};
}

export default brickWrapper;
