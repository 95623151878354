import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Repeater',
						min: 1,
						max: 20,
						fieldName: 'labelBoxes',
						blockTitle: t`Recuadro`,
						content: [
							{
								type: 'TextEditor',
								fieldName: 'labelInfo',
								title: t`Texto`,
								placeholder: t`Introduce el texto de "más información"`,
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'ThemeSelector',
						fieldName: ['containerStyle', 'themeSelected'],
					},
				],
			},
		],
	},
];

export default editionFields;
