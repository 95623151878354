import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import ImagesMain from './components/ImagesMain';
import ImagesHorizontalNavigation from './components/ImagesHorizontalNavigation';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class GalleryHorizontal extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Galería - estilo 2`;
	static brickIcon = 'carousel';
	static brickImage = brickImage;

	static brickDefaultData = {
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault4Opt.svg',
					credit: '',
				},
			},
		],
	};

	constructor(props) {
		super(props);
		this.state = {
			selectedImageIndex: 0,
			currentGalleryIndex: 0,
			classActive: false,
		};
	}

	handleSelectImage = (imageIndex) => {
		this.setState(() => ({
			selectedImageIndex: imageIndex,
			classActive: !this.state.classActive,
		}));
	};

	handlePreviousClick = () => {
		if (this.state.currentGalleryIndex > 0) {
			this.setState((prevState) => ({currentGalleryIndex: prevState.currentGalleryIndex - 1}));
		}
	};

	handleNextClick = () => {
		if (this.state.currentGalleryIndex < this.props.brick.data.images.length - 1) {
			this.setState((prevState) => ({currentGalleryIndex: prevState.currentGalleryIndex + 1}));
		}
	};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const images = data.images || [];
		const selectedImageIndex = this.state.selectedImageIndex;
		const {className} = this.props;

		const classes = classNames({
			'brick': true,
			'gallery-horizontal': true,
			[`${className}`]: true,
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<WindowSizeContext.Consumer>
					{({device}) => {
						return (
							<div className={classes}>
								<ImagesMain
									items={images}
									selectedImageIndex={this.state.selectedImageIndex}
									clickable={device === 'mobile' || device === 'mobileLandscape' ? false : true}
								/>

								<div className="creditTextDownRight">
									{images[selectedImageIndex].imageObj.credit}
								</div>

								<ImagesHorizontalNavigation
									items={images}
									selectedImageIndex={this.state.selectedImageIndex}
									classActive={this.state.classActive}
									currentGalleryIndex={this.state.currentGalleryIndex}
									onSelectImage={this.handleSelectImage}
									onNextClick={this.handleNextClick}
									onPreviousClick={this.handlePreviousClick}
								/>
							</div>
						);
					}}
				</WindowSizeContext.Consumer>
			</Container>
		);
	}
}

export default brickWrapper(GalleryHorizontal);
