import {t} from 'businessLogic/scope/admin/helper/adminTtag';
// import {generateId} from 'businessLogic/helpers/functions';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'TextEditor',
						fieldName: 'title',
						title: t`Título`,
						placeholder: t`Introduce un título`,
					},
					{
						type: 'TextEditor',
						fieldName: 'references',
						title: t`Fuentes de información`,
						placeholder: t`Introduce las fuentes`,
					},
					{
						type: 'DataSheet',
						fieldName: 'dataSheet',
						title: t`Introduce los datos del gráfico de barras`,
					},
					{
						type: 'TextEditor',
						fieldName: 'titleX',
						title: t`Título del ejeX`,
						placeholder: t`Introduce un título para tu eje X`,
					},
					{
						type: 'TextEditor',
						fieldName: 'titleY',
						title: t`Título del ejeY`,
						placeholder: t`Introduce un título para tu eje Y`,
					},
				],
			},
			{
				type: 'Tab',
				title: t`Configuración`,
				fieldName: 'config',
				content: [
					{
						type: 'YAxisConfig',
						fieldName: 'yAxisConfig',
						title: t`CONFIGURACIÓN DEL EJE "Y"`,
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'ColorBg',
						fieldName: ['containerStyle', 'colorBg'],
					},
					{
						type: 'PaddingTop',
						fieldName: ['containerStyle', 'paddingTop'],
					},
					{
						type: 'PaddingBottom',
						fieldName: ['containerStyle', 'paddingBottom'],
					},
					{
						type: 'ThemeSelector',
						fieldName: ['containerStyle', 'themeSelected'],
					},
				],
			},
		],
	},
];
export default editionFields;
