import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Text',
						fieldName: 'questionMark',
						title: t`Etiqueta de pregunta`,
						defaultValue: 'P. ',
						placeholder: `P. `,
					},
					{
						type: 'Text',
						fieldName: 'answerMark',
						title: t`Etiqueta de respuesta`,
						defaultValue: 'R. ',
						placeholder: `R. `,
					},
					{
						type: 'Repeater',
						min: 1,
						fieldName: 'questions',
						blockTitle: t`Pregunta`,
						content: [
							{
								type: 'TextEditor',
								fieldName: 'question',
								title: t`Pregunta`,
								placeholder: t`Introduce la pregunta`,
								defaultValue:
									'¿Quiderum ea cupiet volorep eribus eaturasr rerovidrf et licto voluptat re dolut quas delesci nimporem raes aligend aepersped?',
							},
							{
								type: 'TextEditor',
								fieldName: 'answer',
								title: t`Respuesta`,
								placeholder: t`Introduce la respuesta`,
								defaultValue:
									'Optas isci neceruptur? Quiderum ea cupiet volorep eribus eatur rerovid et licto voluptat re dolut quas delesci nimporem rae officie nimodis aligend aepersped quasitium, omnis site volorat atibers piciam idebitiatet quam as molorum lit modicimpos.',
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'ColorBg',
						fieldName: ['containerStyle', 'colorBg'],
					},
					{
						type: 'ImageBg',
						fieldName: ['containerStyle', 'imageBg'],
					},

					{
						type: 'ColorLayerOpacity',
						fieldName: ['containerStyle', 'colorLayerOpacity'],
					},
					{
						type: 'PaddingTop',
						fieldName: ['containerStyle', 'paddingTop'],
					},
					{
						type: 'PaddingBottom',
						fieldName: ['containerStyle', 'paddingBottom'],
					},
					{
						type: 'ThemeSelector',
						fieldName: ['containerStyle', 'themeSelected'],
					},
				],
			},
		],
	},
];
export default editionFields;
