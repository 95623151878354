import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import ImageComp from 'businessLogic/core/shared/Image/Image';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import brickImage from './img/brickPreview.jpg';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import editionFields from './edit.js';
import isEmpty from 'lodash/isEmpty';
import './styles.scss';

class ImageFullWidthWithText extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Imagen ancho completo con texto`;
	static brickIcon = 'image';
	static brickImage = brickImage;

	static brickDefaultData = {
		imageObj: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
		},
		beforeTitle: 'MIDA VOLUMAN SAM',
		title: 'DUS QUE NOBIST VELESEQUE VELLORE',
		text: 'Quis eost, officim sinverum vellan quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.',
	};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}
	static brickDefaultStyles = {
		containerStyle: {
			colorOverlay: {r: '11', g: '16', b: '17', a: '0.4'},
		},
	};
	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}
	render() {
		const data = this.props.brick.data;
		// const styles = this.props.brick.styles || {};
		let styles;
		if (isEmpty(this.props.brick.styles)) {
			styles = ImageFullWidthWithText.brickDefaultStyles;
		} else {
			styles = this.props.brick.styles || {};
		}
		const imageObj = data.imageObj;
		const classes = classNames({
			'brick': true,
			'image-full-width-text': true,
			'image-full-width-text--with-caption': imageObj.footer || imageObj.credit,
		});
		const colorOverlay = get(styles, ['containerStyle', 'colorOverlay']);
		let stylesInside = {
			overlay: {},
		};
		if (colorOverlay) {
			stylesInside.overlay.backgroundColor = `rgba(${colorOverlay.r},${colorOverlay.g}, ${colorOverlay.b}, ${colorOverlay.a})`;
		} else {
			stylesInside.overlay.backgroundColor = `rgba(11,16,17,0.4)`;
		}
		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ImageFullWidthWithText']);

		return (
			<Container
				size="fullWidth"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div className="image-wrap">
						<ImageComp imgObj={imageObj} clickable={false} />
						{imageObj.credit && <div className="image-wrap__credits"> {imageObj.credit} </div>}
					</div>
					<div className="text-container" style={stylesInside.overlay}>
						<div className="text-container__inner">
							<Animation playAnimation={this.state.playEffect} delay={1} effect={brickAnimation}>
								<div className="before-title-box">
									<FrontEditableText
										fieldName="beforeTitle"
										text={data.beforeTitle}
										set={this.props.set}
									/>
								</div>
								<div className="title-box">
									<FrontEditableText fieldName="title" text={data.title} set={this.props.set} />
								</div>
								<div className="text-box">
									<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
								</div>
							</Animation>
						</div>
					</div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ImageFullWidthWithText);
