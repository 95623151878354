import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					// {
					// 	type: 'Text',
					// 	fieldName: 'introText',
					// 	title: t`Texto`,
					// },
					{
						type: 'DateTimeSelector',
						fieldName: 'destDateTime',
						title: t`Fecha objetivo`,
					},
				],
			},
		],
	},
];
export default editionFields;
