import React from 'react';

import DialogMui from 'businessLogic/shared/Modals/DialogMui';

import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import FilesList from './components/FilesList';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';

const ImageSelectorDialog = ({show, onClose, onSelect, title, filter, fileType}) => {
	return (
		<DialogMui onClose={onClose} open={show} title={title}>
			<DialogContent>
				<Box sx={{minHeight: 300, padding: 1}}>
					<FilesList
						onSelect={(imageUrl) => {
							if (onSelect) {
								onSelect(imageUrl);
							}
							onClose();
						}}
						filter={filter}
						fileType={fileType}
					/>
				</Box>
			</DialogContent>
		</DialogMui>
	);
};

ImageSelectorDialog.propTypes = {
	onSelect: PropTypes.func,
	onClose: PropTypes.func,
	filter: PropTypes.object,
	title: PropTypes.string,

	fileType: PropTypes.oneOf(['pdf', 'img']),
};

ImageSelectorDialog.defaultProps = {
	title: t`Imagen`,
	fileType: 'img',
};

export default ImageSelectorDialog;
