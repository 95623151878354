import React, {PureComponent} from 'react';
import Icon from 'ui/shared/Icon';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class MenuNewContent extends PureComponent {
	render() {
		return (
			<div className="MenuNewContent">
				<div
					className="btn-add-item"
					onClick={this.props.onClick}
					aria-haspopup="true"
					role="button"
				>
					<Icon name="add-round" size="large" ref="playBtnIcon" />
					<span>{t`Añadir un nuevo contenido flexible`}</span>
				</div>
				<Menu
					anchorEl={this.props.anchorEl}
					open={this.props.open}
					onClose={() => {
						this.props.onClose(null);
					}}
				>
					{this.props.typeContent.map((itemType, index) => (
						<MenuItem
							key={index}
							onClick={() => {
								this.props.onClose(itemType.flexibleContentType);
							}}
						>
							{itemType.label}
						</MenuItem>
					))}
				</Menu>
			</div>
		);
	}
}

MenuNewContent.propTypes = {
	typeContent: PropTypes.array,
	anchorEl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onClick: PropTypes.func,
};
export default MenuNewContent;
