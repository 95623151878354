import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import brickImage2 from './img/brickPreview2.jpg';
import editionFields from './edit.js';
import './styles.scss';

class ImportantTextBigSize extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Destacado con subrayado animado`;
	static brickImage = brickImage;
	static brickImage2 = brickImage2;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		text: 'Dusue nobista dsvdvdvadipnt estia dolupta pre rest aut que modi amnstrea',
	};
	static brickDefaultConfig = {};

	constructor(props) {
		super(props);
		this.state = {
			animation: false,
		};
	}

	handleVisibilityChange(isVisible) {
		if (isVisible) {
			this.setState({
				animation: true,
			});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const classes = classNames({
			'brick': true,
			'important-text-big-size-brick': true,
			'animation': this.state.animation,
		});

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes + ' brick'}>
					<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ImportantTextBigSize);
