import React from 'react';
import PropTypes from 'prop-types';
import Button from 'businessLogic/shared/Buttons/Button';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import {isLogged} from 'businessLogic/store/data/session';
import store from 'businessLogic/store/store';

// import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
// import BeforeHeadingTestBrick from 'businessLogic/core/shared/Test/BeforeHeadingTestBrick';
import {ReactComponent as IlustrationTestSvg} from 'assets/images/ilustration_test.svg';
import LoginRequiredModal from './components/LoginRequiredModal';
import {push} from 'businessLogic/store/data/router';
import {loginUrl, registUrl} from 'businessLogic/scope/user/helper/userUrlHelper';

import './styles.scss';

const CallActionTestBrickWithBoxes = ({
	className,
	image,
	beforeHeading,
	set,
	textButton,
	type,
	children,
	onClick,
	disabledAgain,
	requireLogin,
}) => {
	const [openLoginRequiredModal, setOpenLoginRequiredModal] = React.useState(false);
	const mainStyleClass = 'call-action-test-brick-with-boxes';
	const classes = classNames({
		[`${mainStyleClass}`]: true,
		[`${className}`]: true,
	});

	let imageObj = image || {
		imageSrc: '/uploads/default-images/ilustration_test.svg',
		credit: '',
	};

	const isDefaultImage = imageObj.imageSrc.includes('/default-images/ilustration_test.svg');

	let styles = {};
	if (isDefaultImage) {
		styles = {
			backBox: {
				backgroundColor: '#ffffff',
				boxShadow: '1px 1px 15px -4px rgba(0, 0, 0, 0.4)',
			},
		};
	} else if (imageObj.imageSrc) {
		styles = {
			backBox: {
				backgroundImage: 'url("' + imageObj.imageSrc + '")',
			},
		};
	}

	return (
		<div className={classes}>
			<div className={mainStyleClass + '__boxes-container'}>
				{imageObj && imageObj.imageSrc && (
					<div
						className={mainStyleClass.concat('__boxes-container').concat('__image')}
						//pone fondo blanco solo cuando la imagen que venga sea la de por defecto
						style={styles.backBox}
					>
						{isDefaultImage && <IlustrationTestSvg />}
						{imageObj.credit && <div className="image-credit">{imageObj.credit}</div>}
					</div>
				)}
				<div
					className={
						mainStyleClass +
						'__boxes-container' +
						'__description' +
						(!imageObj.imageSrc ? ' no-image' : '')
					}
				>
					{/* <BeforeHeadingTestBrick text={beforeHeading} set={set} /> */}

					{beforeHeading && (
						<FrontEditableText
							fieldName="beforeHeading"
							text={beforeHeading}
							set={set}
							className={mainStyleClass

								.concat('__before-heading')
								.concat(!textButton ? ' no-button' : '')}
						/>
					)}
					<div
						className={mainStyleClass
							.concat('__boxes-container')
							.concat('__description')
							.concat('__content-container')
							.concat(!textButton ? ' no-button' : '')
							.concat(type !== 'toStart' ? ' one-button' : '')}
					>
						{children}

						{onClick && (
							<div
								className={mainStyleClass
									.concat('__boxes-container')
									.concat('__description')
									.concat('__content-container')
									.concat('__button')
									.concat(type !== 'toStart' ? ' one-button' : '')}
							>
								<Button
									theme="primary"
									view="outline"
									rounded={true}
									onClick={() => {
										if (requireLogin && !isLogged(store.getState())) {
											setOpenLoginRequiredModal(true);
										} else {
											if (onClick) onClick();
										}
									}}
									disabled={disabledAgain}
								>
									{type === 'toRepeat' && <Icon name="replay" size="normal" />}
									{textButton}
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
			{requireLogin && (
				<LoginRequiredModal
					open={openLoginRequiredModal}
					onClose={() => setOpenLoginRequiredModal(false)}
					onLogin={() => {
						// Guadamos la ruta previa en el storeLocal para poder redirigir tras hacer el login
						if (localStorage) {
							localStorage.setItem('beforeRoute', window.location.href);
						}
						push(loginUrl());
						setOpenLoginRequiredModal(false);
					}}
					onRegister={() => {
						// Guadamos la ruta previa en el storeLocal para poder redirigir tras hacer el login
						if (localStorage) {
							localStorage.setItem('beforeRoute', window.location.href);
						}
						push(registUrl());
						setOpenLoginRequiredModal(false);
					}}
				/>
			)}
		</div>
	);
};

CallActionTestBrickWithBoxes.propTypes = {
	className: PropTypes.string,
	type: PropTypes.oneOf(['toStart', 'toRepeat', 'toInfoFinish']),
	textButton: PropTypes.string,
	beforeHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	image: PropTypes.object,
	onClick: PropTypes.func,
	set: PropTypes.func,
	disabledAgain: PropTypes.bool,
	requireLogin: PropTypes.bool,
};
CallActionTestBrickWithBoxes.defaultProps = {
	disabledAgain: false,
	requireLogin: false,
	// no ponemos imagen por defecto para poder controlar si se le pasa imagen se muestra si no no, más sencillo
	// los test antiguos no mostrarán la imágen
	// si queremos que los test antiguos muestren la imagen activaremos este valor por defecto
	// pero habrá que añadir otro prop para controlar cuando se quiere mostrar la imagen y cuando no.
	// image: {
	// 	imageSrc: '/uploads/default-images/ilustration_test.svg',
	// 	credit: '',
	// },
};

export default CallActionTestBrickWithBoxes;
