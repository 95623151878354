import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DraggableTagLabel from 'businessLogic/core/shared/Label/DraggableTagLabel';
import Category from './components/Category';
import {DragDropContainer, DropTarget} from 'react-drag-drop-container';
import get from 'lodash/get';
import './styles.scss';

class Test extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			finished: false,
			tagLabelDecks: {
				categoryA: [],
				categoryB: [],
				pendings: [], // añadir una sola o toda la colección?
			},
			activeCategory: undefined,
			showLabelsStack: true,
			isGrabbing: false,
		};

		this.labelsStack = [];
	}

	componentDidMount() {
		this.setState({
			tagLabelDecks: {
				categoryA: [],
				categoryB: [],
				pendings: [].concat(this.props.tagsCollection), // añadir una sola o toda la colección?
			},
		});
	}

	handleOnDrag = () => {
		this.setState({
			isGrabbing: true,
		});
	};
	handleOnDragEnd = () => {
		this.setState({
			isGrabbing: false,
		});
	};

	/*solo se dispara en caso de que las targetKey del elemento que se arrastra y el elemento de destino coinciden */
	handleOnDrop = (event) => {
		this.setState((state) => {
			//Nueva referencia
			const updatedTagLabelDecks = Object.assign({}, state.tagLabelDecks);

			//Tag acertadas y montón sobre sobre el que debería de apilarse
			const successefulTagLabel = event.dragData.tag; //Tag
			const targetDeck = event.dropData.idCategory; //Destino

			/*1--Modificar la ubicación de cada targeta */
			updatedTagLabelDecks.pendings.shift();
			if (targetDeck === 0) {
				updatedTagLabelDecks.categoryA.push(successefulTagLabel);
			} else {
				updatedTagLabelDecks.categoryB.push(successefulTagLabel);
			}

			/*3--Si el juego terminó, comunicar al padre con los resultados*/
			/*Comprobar si ha finalizado y ejecutar el handle del evento finalizar */
			let responsesCompleted = state.finished;
			if (updatedTagLabelDecks.pendings.length === 0) {
				responsesCompleted = true;
				this.handleFinishedQuestion(500);
			}

			return {
				tagLabelDecks: updatedTagLabelDecks,
				finished: responsesCompleted,
				feedback: true,
				activeCategory: targetDeck,
				showLabelsStack: false,
			};
		});
		this.animationFeedback(1000);
	};
	/*Hace una pequeña parada en la animación del feedback, hasta que se muestra la siguiente tag */
	animationFeedback(duration) {
		if (this.state.feedback !== undefined) {
			// set state
			clearTimeout(this.resetFeedback);
			this.resetFeedback = setTimeout(() => {
				/*Contabilizar el tiempo tardado por el usuario en contestar, en el Coeficiente de acierto */
				this.setState({
					showLabelsStack: true,
				});
			}, duration);
		}
	}
	/*Se invoca cuando se considera que la pregunta está finalizada. Recoge los resultados y ejecuta el método onFinished declarado en la clase del Brick */
	handleFinishedQuestion = (duration) => {
		clearTimeout(this.goNextTimeoutHandler);

		this.goNextTimeoutHandler = setTimeout(() => {
			const response = [
				{
					name: this.props.categories[0].category,
					elements: get(this.state, 'tagLabelDecks.categoryA', []).map(
						(element) => element.tagName,
					),
				},
				{
					name: this.props.categories[1].category,
					elements: get(this.state, 'tagLabelDecks.categoryB', []).map(
						(element) => element.tagName,
					),
				},
			];
			this.props.onFinished(response);
		}, duration);
	};

	render() {
		const mainStyleClass = 'classify-game-test';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
		});
		const categories = this.props.categories;
		const stackLabelTag = this.state.tagLabelDecks.pendings;
		let counterIdTag = 0;

		return (
			<div className={classes}>
				<div className="row row--align--item--center--lg row--nowrap--lg">
					<div className="col-xs-6 col-lg-4 col--order1">
						<DropTarget
							targetKey={'category'}
							dropData={{idCategory: 0}}
							highlightClassName={'classify-game-test__category__grag-dover'}
						>
							<Category
								title={categories[0].category}
								tagsLabelSolution={this.state.tagLabelDecks.categoryA}
								feedback={undefined}
							/>
						</DropTarget>
					</div>

					<div className="col-xs-6 col-lg-4 col--order3">
						<DropTarget
							targetKey={'category'}
							dropData={{idCategory: 1}}
							highlightClassName={'classify-game-test__category__grag-dover'}
						>
							<Category
								title={categories[1].category}
								tagsLabelSolution={this.state.tagLabelDecks.categoryB}
								feedback={undefined}
							/>
						</DropTarget>
					</div>
					<div className="col-xs-12 col-lg-4 col--order2">
						{this.state.showLabelsStack && (
							<div className={mainStyleClass + '__stack'}>
								{stackLabelTag && stackLabelTag.length > 0 && (
									<DragDropContainer
										targetKey={'category'}
										dragData={{
											id: counterIdTag++,
											tag: stackLabelTag[0],
											target: stackLabelTag[0].category,
										}}
										disappearDraggedElement={true}
										onDrop={this.handleOnDrop}
										onDrag={this.handleOnDrag} //mientras se este arrastrando //cambiar la interación del cursor
										onDragEnd={this.handleOnDragEnd}
										render={() => {
											return (
												<DraggableTagLabel
													text={stackLabelTag[0].tagName}
													active={this.state.isGrabbing}
												/>
											);
										}}
									/>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
Test.propType = {
	tagsCollection: PropTypes.array,
	categories: PropTypes.array,
	onFinished: PropTypes.func,
};

export default Test;
