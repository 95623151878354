import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Audio',
						fieldName: 'audio',
						title: t`Audio`,
						placeholder: t`Selecciona un audio`,
					},
					{
						type: 'Switch',
						fieldName: 'changeSpeed',
						title: t`El ladrillo modifica la velocidad del scroll`,
					},
					{
						type: 'Switch',
						fieldName: 'loop',
						title: t`Reproducción en bucle`,
					},
					{
						type: 'Switch',
						fieldName: 'pauseDisappear',
						title: t`Pausar audio al salir de la ventana`,
					},
					{
						type: 'Number',
						fieldName: 'volume',
						title: t`Volumen del audio`,
						placeholder: t`Aumenta o disminuye`,
						min: 0,
						max: 100,
						step: 10,
					},
				],
			},
		],
	},
];
export default editionFields;
