import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import classNames from 'classnames';
import './styles.scss';

class Response extends Component {
	render() {
		const mainStyleClass = 'discover-image__response';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			[`${mainStyleClass}--failure`]: this.props.feedbackCorrect === false,
			[`${mainStyleClass}--success`]:
				this.props.feedbackCorrect === true ||
				(this.props.feedbackCorrect === undefined &&
					this.props.disable &&
					this.props.valueSolution),
			[`${mainStyleClass}--disable`]: this.props.disable && this.props.feedbackCorrect === undefined
		});
		return (
			<div className={classes}>
				<div className={mainStyleClass + '__text'} onClick={this.props.onClick}>
					<FrontEditableText text={this.props.text} editable={false} />
				</div>
			</div>
		);
	}
}
/*feedbackCorrect: tiene un valor inicial de undefined , por eso no recibe ningún defaultProps */

Response.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onClick: PropTypes.func,
	disable: PropTypes.bool,
	feedbackCorrect: PropTypes.bool,
	valueSolution: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

Response.defaultProps = {
	disable: false
};

export default Response;
