import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import get from 'lodash/get';
import './styles.scss';

class HeadingFullWidthBox extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Título con caja`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		heading: 'DUSUE NOBISTA MICIUS ET QUIA LIGOAS NOBISTA MICIUS',
	};
	static brickDefaultConfig = {};

	static brickDefaultStyles = {
		containerStyle: {
			colorBg: 'default',
		},
	};
	render() {
		const data = this.props.brick.data;
		// const styles = this.props.brick.styles || {};
		let styles;
		if (get(this.props, 'brick.styles.containerStyle.colorBg') === 'default') {
			const highlightColor = getComputedStyle(document.documentElement).getPropertyValue(
				'--highlightColor',
			);

			styles = {
				containerStyle: {
					colorBg: highlightColor,
				},
			};
		} else {
			styles = this.props.brick.styles || {};
		}

		const classes = classNames({
			'brick': true,
			'heading-full-width-box-brick': true,
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<FrontEditableText fieldName="heading" text={data.heading} set={this.props.set} />
				</div>
			</Container>
		);
	}
}

export default brickWrapper(HeadingFullWidthBox);
