import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import TextEditorComponent from 'businessLogic/shared/Text/TextEditor';
import Instructions from 'businessLogic/core/shared/Edit/Instructions';
import './styles.scss';

class TextEditor extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		let defaultValue = {textValue: '', deltaValue: {ops: []}};
		if (editionBrickStruct.defaultValue) {
			defaultValue = editionBrickStruct.defaultValue;
		}

		return defaultValue;
	};

	onChange = (textObject) => {
		this.props.setMainField(textObject);
	};
	render() {
		return (
			<div className="edition-brick-textEditor">
				<label className="edition-brick-textEditor__label">
					{this.props.editionBrickStruct.title}
				</label>
				{this.props.editionBrickStruct.instructions !== undefined && (
					<Instructions text={this.props.editionBrickStruct.instructions} />
				)}
				<TextEditorComponent
					reducedVersion={true}
					increaseValueVersion={true}
					reloadOnVersionChange={false}
					text={
						this.props.getMainField() || TextEditor.getDefaultValue(this.props.editionBrickStruct)
					}
					placeholder={this.props.editionBrickStruct.placeholder}
					onChange={this.onChange}
				/>
			</div>
		);
	}
}

export default editionBrickWrapper(TextEditor);
