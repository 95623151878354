import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Dialog from 'businessLogic/shared/Modals/Dialog';
import ButtonLoadInSelector from 'businessLogic/shared/Buttons/ButtonLoadInSelector';
import VideoList from './components/VideoList';
import VideoUrlModal from './components/VideoUrlModal';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class VideoSelectorDialog extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showDialog: false,
			showDialogYoutube: false,
			showDialogVimeo: false,
		};
	}
	show = () => {
		this.setState({showDialog: true});
	};
	showYoutube = () => {
		this.setState({showDialogYoutube: true});
	};
	showVimeo = () => {
		this.setState({showDialogVimeo: true});
	};

	close = () => {
		this.setState({showDialog: false});
	};
	closeYoutube = () => {
		this.setState({showDialogYoutube: false}); //se cierran los dos diálogos
	};
	closeVimeo = () => {
		this.setState({showDialogVimeo: false}); //se cierran los dos diálogos
	};

	selectVideo = (videoUrl) => {
		this.props.onSelect(videoUrl);
		this.close();
	};
	render() {
		return (
			<Dialog
				size="normal"
				title={t`Vídeo`}
				visible={this.state.showDialog}
				onCancel={this.close}
				noDraggable={this.props.noDraggable}
				mask={this.props.mask}
				zIndex={this.props.zIndex || 1.2}
			>
				{this.state.showDialog && (
					<React.Fragment>
						{t`Subir video desde:`}
						<div className="load-options">
							<ButtonLoadInSelector iconName="my-files" nameButton={t`Mis Archivos`} />
							<ButtonLoadInSelector
								iconName="youtube"
								nameButton={t`Youtube`}
								onClick={this.showYoutube}
							/>
							<ButtonLoadInSelector
								iconName="vimeo"
								nameButton={t`Vimeo`}
								onClick={this.showVimeo}
							/>
						</div>
						<VideoList onSelect={this.selectVideo} />
						<VideoUrlModal
							internetPlatform="Youtube"
							showDialog={this.state.showDialogYoutube}
							close={this.closeYoutube}
							noDraggable={this.props.noDraggable}
							mask={this.props.mask}
							zIndex={this.props.zIndex + 1 || 1.5}
							onChangeInputLink={this.selectVideo} //utilizamos la misma opción para guardar el video
						/>
						<VideoUrlModal
							internetPlatform="Vimeo"
							showDialog={this.state.showDialogVimeo}
							close={this.closeVimeo}
							noDraggable={this.props.noDraggable}
							mask={this.props.mask}
							zIndex={this.props.zIndex + 1 || 1.5}
							onChangeInputLink={this.selectVideo}
						/>
					</React.Fragment>
				)}
			</Dialog>
		);
	}
}

VideoSelectorDialog.propTypes = {
	className: PropTypes.string,
	onSelect: PropTypes.func,
	noDraggable: PropTypes.bool,
	mask: PropTypes.bool,
	zIndex: PropTypes.number,
};

VideoSelectorDialog.defaultProps = {
	className: '',
	visible: false,
	noDraggable: true,
	mask: true,
};

export default VideoSelectorDialog;
