import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import LegalConditionsPopUP from './components/LegalConditionsPopUP';
import classNames from 'classnames';
import './styles.scss';

class LegalConditionsLink extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showLegals: false
		};
	}

	showLegals = () => {
		this.setState({showLegals: true});
	};

	hideLegals = () => {
		this.setState({showLegals: false});
	};

	render() {
		const {label, link, text, errorText} = this.props;
		let classes = classNames({
			'legal-conditions-link': true,
			error: errorText !== ''
		});

		return (
			<div className={classes}>
				<label>
					{label}
					<span onClick={this.showLegals}>{link}</span>
				</label>
				<LegalConditionsPopUP text={text} onHide={this.hideLegals} show={this.state.showLegals} />
				{errorText !== '' && <span className="checkbox__error">{errorText}</span>}
			</div>
		);
	}
}

LegalConditionsLink.propTypes = {
	data: PropTypes.object
};

LegalConditionsLink.defaultProps = {
	data: {}
};

export default LegalConditionsLink;
