import React from 'react';
import entityManager from 'businessLogic/services/EntityManager';
import {getRgbaString} from 'businessLogic/helpers/functions';
import {ThemeProvider as Mui4ThemeProvider} from '@material-ui/core/styles';
import {createTheme as mui4CreateTheme} from '@material-ui/core/styles';
import {ThemeProvider as Mui5ThemeProvider} from '@mui/material/styles';
import {createTheme as mui5CreateTheme} from '@mui/material/styles';

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import enGB from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import it from 'date-fns/locale/it';
import de from 'date-fns/locale/de';

const locales = {
	en_GB: enGB,
	es_ES: es,
	pt_PT: pt,
	it_IT: it,
	de_DE: de,
};

export default function ThemeProvider({children}) {
	const {
		highlightColor,
		interactionColor,
		menuColor,
		font1,
		font2,
		color1,
		color2,
		color3,
		color4,
	} = entityManager.getCustomStyles();

	const root = document.documentElement;
	if (highlightColor) root.style.setProperty('--highlightColor', getRgbaString(highlightColor));
	if (interactionColor)
		root.style.setProperty('--interactionColor', getRgbaString(interactionColor));
	if (menuColor) root.style.setProperty('--menuColor', getRgbaString(menuColor));
	if (font1) root.style.setProperty('--font1', font1);
	if (font2) root.style.setProperty('--font2', font2);
	if (color1) root.style.setProperty('--color1', getRgbaString(color1));
	if (color2) root.style.setProperty('--color2', getRgbaString(color2));
	if (color3) root.style.setProperty('--color3', getRgbaString(color3));
	if (color4) root.style.setProperty('--color4', getRgbaString(color4));

	const theme = {
		platformStyles: {
			highlightColor: getRgbaString(highlightColor) || '#73e5d1',
			interactionColor: getRgbaString(interactionColor) || '#e97b5c',
			menuColor: getRgbaString(menuColor) || 'rgba(16, 29, 54, 0.9)',
			font1,
			font2,
			color1: getRgbaString(color1) || '#687EDB',
			color2: getRgbaString(color2) || '#FEDC82',
			color3: getRgbaString(color3) || '#FA5F85',
			color4: getRgbaString(color4) || '#33CCFF',
		},
		typography: {
			fontFamily: [font1, 'Montserrat', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
		},
	};

	const mui4Theme = mui4CreateTheme(theme);
	const mui5Theme = mui5CreateTheme(theme);

	const adminLang = entityManager.getAdminLang();

	return (
		<Mui4ThemeProvider theme={mui4Theme}>
			<Mui5ThemeProvider theme={mui5Theme}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={locales[adminLang]}>
					{children}
				</MuiPickersUtilsProvider>
			</Mui5ThemeProvider>
		</Mui4ThemeProvider>
	);
}
