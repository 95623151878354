import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import classNames from 'classnames';
import './styles.scss';

class ResponseTest extends Component {
	render() {
		const classes = classNames({
			'response-test-images-component': true,
			'checked': this.props.active,
		});

		return (
			<figure className={classes} onClick={this.props.onClick}>
				<div className="response-test-images-component__image">
					{/* <div className="response-test-component__counter">
						<span>{this.props.optionLetter}</span>
					</div> */}
					<NewImageClickable
						imgObj={this.props.image}
						clickable={false}
						alt={this.props.image.credit}
						defaultVisible={true}
					/>
				</div>
				{this.props.text && (
					<figcaption className="response-test-images-component__text">
						<FrontEditableText text={this.props.text} editable={false} />
					</figcaption>
				)}
			</figure>
		);
	}
}

ResponseTest.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	optionLetter: PropTypes.string,
	onClick: PropTypes.func,
	active: PropTypes.bool,
};

ResponseTest.defaultProps = {
	active: false,
};
export default ResponseTest;
