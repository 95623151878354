import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from 'businessLogic/shared/Modals/Dialog';
import AudioList from './components/AudioList';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class AudioSelectorDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDialog: false,
		};
	}
	show = () => {
		this.setState({showDialog: true});
	};

	close = () => {
		this.setState({showDialog: false});
	};

	selectAudio = (audioUrl) => {
		this.props.onSelect(audioUrl);
		this.close();
	};

	render() {
		return (
			<Dialog
				size="normal"
				title={t`Audio`}
				visible={this.state.showDialog}
				onCancel={this.close}
				noDraggable={this.props.noDraggable}
				mask={this.props.mask}
				zIndex={this.props.zIndex}
			>
				{this.state.showDialog && <AudioList onSelect={this.selectAudio} />}
			</Dialog>
		);
	}
}

AudioSelectorDialog.propTypes = {
	className: PropTypes.string,
	onSelect: PropTypes.func,
	noDraggable: PropTypes.bool,
	mask: PropTypes.bool,
	zIndex: PropTypes.number,
};

AudioSelectorDialog.defaultProps = {
	className: '',
	visible: false,
	noDraggable: true,
	mask: true,
};

export default AudioSelectorDialog;
