import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {setEllipsisText} from 'businessLogic/helpers/functions';
import Icon from 'ui/shared/Icon';
import './styles.scss';

class Response extends Component {
	getFeedbackCorrect = () => {
		if (this.props.disable) {
			if (this.props.checked) {
				if (this.props.valueSolution) {
					return true;
				}
				return false;
			}
			return undefined;
		}
	};
	render() {
		const {checked, value, disable, name, text, valueSolution} = this.props;
		const mainStyleClass = 'test-exam-radio-response';
		const feedbackCorrect = this.getFeedbackCorrect();
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			[`${mainStyleClass + '--checked'}`]: checked,
			[`${mainStyleClass + '--disable'}`]: disable,
			[`${mainStyleClass + '--success'}`]: valueSolution,
			[`${mainStyleClass + '--failure'}`]: feedbackCorrect === false
		});
		return (
			<div className={classes}>
				<label className={mainStyleClass + '__trigger'}>
					<input
						name={name}
						value={value}
						checked={checked}
						type="radio"
						onChange={this.props.onChange}
						className="visuallyhidden"
						disabled={disable}
					/>

					<div className={mainStyleClass + '__status'}>
						<div className={mainStyleClass + '__status__icon'}>
							{disable && valueSolution && <Icon name="success" />}
							{disable && checked && (valueSolution === false) && <Icon name="failure" />}
						</div>
					</div>
					<p
						className={mainStyleClass + '__text'}
						dangerouslySetInnerHTML={{__html: setEllipsisText(text, undefined)}}
					/>
				</label>
			</div>
		);
	}
}
/*feedbackCorrect: tiene un valor inicial de undefined , por eso no recibe ningún defaultProps */

Response.propTypes = {
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	valueSolution: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	disable: PropTypes.bool
};

Response.defaultProps = {
	disable: false
};

export default Response;
