import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import Icon from 'ui/shared/Icon';
import cloneDeep from 'lodash/cloneDeep';
import {getDefaultValue} from '../editionBricks';
import MenuNewContent from './components/MenuNewContent';
import {generateId} from 'businessLogic/helpers/functions';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class RepeaterFlexContent extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		const defaultValue = [];
		const repeaterSize = editionBrickStruct.defaultNumber || editionBrickStruct.min || 0;
		const newObject = {};

		editionBrickStruct.commonContent.forEach((element) => {
			newObject[element.fieldName] = getDefaultValue(element);
		});

		for (let i = 0; i < repeaterSize; i++) {
			const objectCopy = cloneDeep(newObject);
			objectCopy._id = generateId();
			defaultValue.push(objectCopy);
		}

		return defaultValue;
	};

	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
			newContentFlex: undefined,
		};
		this.menuRef = React.createRef();
	}
	/***Función para abrir el menu de selección ***/
	handleClick = (event) => {
		this.setState({anchorEl: event.currentTarget});
	};

	handleClose = (item) => {
		this.setState({
			anchorEl: null,
		});
		if (item) this.handleAdd(item);
	};

	/***Función para eliminar un elemento del repeater ***/
	handleDelete = (i) => {
		const blocksData = cloneDeep(this.props.getMainField());
		blocksData.splice(i, 1);
		this.props.setMainField(blocksData);
	};

	/***Función para añadir un elemento del repeater ***/
	handleAdd = (item) => {
		let myObjectDefaultData = {
			_id: generateId(),
		};
		if (this.props.editionBrickStruct.content !== undefined) {
			const flexIndex = this.props.editionBrickStruct.content.findIndex(
				(element) => element.flexibleContentType === item,
			);
			const newStruct = this.props.editionBrickStruct.commonContent.concat(
				this.props.editionBrickStruct.content[flexIndex].content,
			);
			for (let i = 0; i < newStruct.length; i++) {
				myObjectDefaultData[newStruct[i].fieldName] = getDefaultValue(newStruct[i]);
			}

			myObjectDefaultData.flexibleContentType = item;

			//Creamos un id único basado en la fecha en milisegundos y un número aleatorio
			// myObjectDefaultData.autoId = (Date.now() * 1000000 + Math.random() * 1000000);

			const blocksData = cloneDeep(this.props.getMainField([]));

			blocksData.push(myObjectDefaultData);
			this.props.setMainField(blocksData);
		} else {
			console.error('error content');
		}
	};

	/***Función mover hacia arriba un elemento del repeater ***/
	handleUp = (i) => {
		const blocksData = cloneDeep(this.props.getMainField());
		let item = blocksData[i];
		if (i >= 1) {
			blocksData.splice(i, 1);
			blocksData.splice(i - 1, 0, item);
			this.setState({up: true});
		} else {
			this.setState({up: false});
		}
		this.props.setMainField(blocksData);
	};

	/***Función mover hacia abajo un elemento del repeater ***/
	handleDown = (i) => {
		const blocksData = cloneDeep(this.props.getMainField());
		let item = blocksData[i];
		if (i <= this.props.getMainField().length) {
			blocksData.splice(i, 1);
			blocksData.splice(i + 1, 0, item);
			this.setState({down: true});
		} else {
			this.setState({down: false});
		}
		this.props.setMainField(blocksData);
	};

	/***Función para recoger los label y los flexibleContentType  de las posibles opciones de tipo contenido flexible  ***/
	getItemListMenu = () => {
		const typeFlexContent = this.props.editionBrickStruct.content
			.filter((itemTypeContent) => !itemTypeContent.deprecated)
			.map((itemTypeContent) => ({
				label: itemTypeContent.label,
				flexibleContentType: itemTypeContent.flexibleContentType,
			}));
		return typeFlexContent;
	};

	render() {
		const blocks = [];

		if (this.props.getMainField() !== undefined) {
			for (let i = 0; i < this.props.getMainField().length; i++) {
				const flexIndex = this.props.editionBrickStruct.content.findIndex(
					(element) =>
						element.flexibleContentType === this.props.getMainField()[i].flexibleContentType,
				);

				blocks.push(
					<div
						key={i}
						className={
							'edition-brick-repeater--flexContent__block edition-brick-repeater--flexContent__block--' +
							this.props.editionBrickStruct.content[flexIndex].flexibleContentType
						}
					>
						<h4 className="edition-brick-repeater--flexContent__block__title">
							{this.props.editionBrickStruct.blockTitle +
								' ' +
								(i + 1) +
								'.   ' +
								this.props.editionBrickStruct.content[flexIndex].label}
						</h4>

						{/* Renderizar estructura de campos en común */}
						{this.props.renderChildren(this.props.editionBrickStruct.commonContent, i)}
						{this.props.renderChildren(this.props.editionBrickStruct.content[flexIndex].content, i)}

						<div className="item-options">
							<div
								className={'btn btn-delete-item'}
								onClick={() => {
									this.handleDelete(i);
								}}
							>
								<Icon name="delete" size="small" ref="playBtnIcon" />
							</div>

							{i > 0 && (
								<div
									className="btn btn-arrow-up"
									onClick={() => {
										this.handleUp(i);
									}}
								>
									<Icon name="arrow-up" size="small" ref="playBtnIcon" />
								</div>
							)}
							{i + 1 !== this.props.getMainField().length && (
								<div
									className="btn btn-arrow-down"
									onClick={() => {
										this.handleDown(i);
									}}
								>
									<Icon name="arrow-down" size="small" ref="playBtnIcon" />
								</div>
							)}
						</div>
					</div>,
				);
			}
		}

		return (
			<div className="edition-brick-repeater--flexContent">
				<h4>{t`Contenido personalizable`}</h4>

				{blocks}
				{((this.props.getMainField() !== undefined &&
					this.props.getMainField().length < this.props.editionBrickStruct.max) ||
					this.props.editionBrickStruct.max === undefined) && (
					<div className="add-item">
						<MenuNewContent
							typeContent={this.getItemListMenu()}
							ref={this.menuRef}
							anchorEl={this.state.anchorEl}
							open={Boolean(this.state.anchorEl)}
							onClose={this.handleClose}
							onClick={(event) => {
								this.handleClick(event);
							}}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default editionBrickWrapper(RepeaterFlexContent);
