import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import Instructions from 'businessLogic/core/shared/Edit/Instructions';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css'; //importante los estilos de la librería para la tabla, cursor de añadir, celda seleccionada etc
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
// import get from 'lodash/get';
import set from 'lodash/set';
import {generateId} from 'businessLogic/helpers/functions';
import Icon from 'ui/shared/Icon';
import './styles.scss';

class DataSheet extends PureComponent {
	//onCellsChanged(arrayOfChanges[, arrayOfAdditions])
	onChange = (changes, additions) => {
		//si no estoy introduciendo valor por error en las celdas de añadir o eliminar
		//o en las celdas grises de las esquinas
		// if (typeof changes[0] !== 'undefined') {
		if (
			changes[0].cell.value !== '+' &&
			changes[0].cell.value !== '-' &&
			changes[0].cell.value !== ''
		) {
			const data = this.getData();
			// console.log('changes', changes);
			// console.log('additions', additions); //additions es lo que estoy pegando //se pierde el dato de la fila de solo lectura
			// console.log('data', data); //multiplicar el length por el value.length y sabemos los que hay

			//almacenamos numero de filas y columnas actuales
			const actualCols = data.length;
			const actualRows = data[0].value.length;

			//variables para almacenar numero de filas y columnas que se han pegado
			let newCols = 0;
			let newRows = 0;

			//variables para almacenar número de filas y columnas que se van a insertar
			let colsToInsert = 0;
			let rowsToInsert = 0;

			let totalChanges = [].concat(changes);

			//en additions tenemos todos los datos que no han cabido al pegar
			//más una fila que estará en changes - es la que corresponde a la fila inutil donde estan los botones de eliminar
			if (typeof additions !== 'undefined') {
				//unimos additions y changes para tener todos los datos que hay que actualizar juntos
				totalChanges = totalChanges.concat(additions);
				//en el la ultima celda de additions se indica la columna, más 1 que hay que coger de la fila inutil de changes
				newCols = additions[additions.length - 1].col;
				newRows = additions[additions.length - 1].row;
				//si additions no existe, mirammos en changes si hay que añadir una fila o no.
			} else {
				newCols = changes[changes.length - 1].col;
				newRows = changes[changes.length - 1].row;
			}

			//calculamos las filas y columnas que habría que insertar
			colsToInsert = newCols - actualCols;
			rowsToInsert = newRows - actualRows;

			//insertarmos las filas y las columnas necesarias antes de aplicar los cambios
			if (colsToInsert > 0) {
				for (let i = 0; i < colsToInsert; i++) {
					this.createNewCol();
				}
			}
			if (rowsToInsert > 0) {
				for (let i = 0; i < rowsToInsert; i++) {
					this.createNewRow();
				}
			}

			//tenemos que obtener de nuevo el data para ver si ha habido nuevas filas o columnas
			const newData = this.getData();
			totalChanges.forEach(({row, col, value}) => {
				if (row === 0 || col === 0) {
					//si lo que se está cambiando es el encabezado de columna, porque estoy en la primera fila
					//asignamos el value al name
					if (row === 0) {
						newData[col - 1].name = value;
					}
					//si lo que se está cambiando es el encabezado de fila, porque estoy en la primera columna
					//asignamos el value a la label
					if (col === 0) {
						//hay que actualizar todas las label de cada columna si no se quedan sin identificar las filas de las columnas
						//con la siguiente instrucción se actualiza solo las label de la primera columna
						//newData[col].value[row - 1].label = value;
						//con el for recorremos todas las columnas modificando sus label
						for (let c = 0; c < newData.length; c++) {
							newData[c].value[row - 1].label = value;
						}
					}
					// si no estoy en ninguno de los dos casos es porque es el valor númerico.
				} else {
					// no estoy modificando la COLUMNA NUEVA, así que asigno el valor
					if (col <= newData.length) {
						let valueStr = value.replace(',', '.');
						newData[col - 1].value[row - 1].raw = valueStr;
					}
				}
			});
			this.props.setMainField(newData);
		}
		// }
	};

	// getNumberBodyCols = () => this.getData().length;

	// getNumberBodyRows = () => this.getData()[0].value.length; //tomar como referencia la primera columna

	generateThRow = (string, j, readOnly = false, className = '') => {
		if (string === '+') {
			return {
				readOnly: readOnly,
				value: string,
				className: className,
				component: (
					<div onClick={this.createNewRow} title={t`Nueva fila`}>
						<Icon name="add-row" size="small" />
					</div>
				),
				forceComponent: true,
			};
		} else {
			return {
				readOnly: readOnly,
				value: string || t`Serie ` + j + 1,
				className: className,
			};
		}
	};

	generateThCol = (string, i, readOnly = false, className = '') => {
		const alphabet = ' ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		if (string === '+') {
			return {
				readOnly: readOnly,
				value: string,
				className: className,
				component: (
					<div onClick={this.createNewCol} title={t`Nueva columna`}>
						<Icon name="add-row" size="small" />
					</div>
				),
				forceComponent: true,
			};
		} else {
			return {
				readOnly: readOnly,
				value: string ? string : t`Columna ` + alphabet[i % alphabet.length],
				className: className,
			};
		}
	};
	createNewRow = () => {
		const dataSheet = [].concat(this.props.editionBrickData.data.dataSheet);
		//creamos la fila nueva añadiendo al final de cada columna una celda vacía
		for (let i = 0; i < dataSheet.length; i++) {
			let label = t`Serie ` + (dataSheet[i].value.length + 1);

			dataSheet[i].value.push({
				_id: generateId(),
				raw: 0,
				label: label,
			});
		}
		this.props.setMainField(dataSheet);
	};

	createNewCol = () => {
		const dataSheet = [].concat(this.props.editionBrickData.data.dataSheet);
		const cellsOfCol = [];
		const alphabet = ' ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		for (let j = 0; j < dataSheet[0].value.length; j++) {
			cellsOfCol.push({
				_id: generateId(),
				raw: 0,
				label: '',
			});
		}
		dataSheet.push({
			name: t`Columna ` + alphabet[(dataSheet.length + 1) % alphabet.length],
			value: cellsOfCol,
		});
		this.props.setMainField(dataSheet);
	};
	deleteRow = () => {
		const selectedRow = this.state.selected.start.i;
		const dataSheet = [].concat(this.props.editionBrickData.data.dataSheet);
		//si solo queda una fila no se puede eliminar

		if (selectedRow === 1 && dataSheet[0].value.length === 1) {
			alert(t`No se puede eliminar`);
		} else {
			for (let i = 0; i < dataSheet.length; i++) {
				dataSheet[i].value.splice(selectedRow - 1, 1);
			}
		}

		this.props.setMainField(dataSheet);
	};

	deleteCol = () => {
		const selectedCol = this.state.selected.start.j;
		const dataSheet = [].concat(this.props.editionBrickData.data.dataSheet);
		//si solo queda una columna no se puede eliminar
		if (selectedCol - 1 === 0 && dataSheet.length === 1) {
			alert(t`no se puede eliminar`);
		} else {
			dataSheet.splice(selectedCol - 1, 1);
		}
		this.props.setMainField(dataSheet);
	};

	getData = () => {
		// return this.props.getMainField() || DataSheet.getDefaultValue(this.props.editionBrickStruct);
		return this.props.getMainField();
	};

	createGrid = (data) => {
		const grid = [];
		const rowThTable = [this.generateThCol(' ', 0, true)]; //el primer true indica readOnly, el segundo selected
		const colThTable = [];

		//**Generar cuerpo de la tabla - DATA CONTIENE TODAS LAS COLUMNAS

		for (let i = 0; i < data.length; i++) {
			const columnas = data[i].value;

			rowThTable.push(this.generateThCol(data[i].name, i + 1, false));
			for (let j = 0; j < columnas.length; j++) {
				const fila = columnas[j];

				//obtenemos la label para los encabezados de fila - columna de título izquierda
				//cada fila lleva sus labels que deberían ser iguales
				//nos quedamos con las labels de la primera fila que son las que contendrán los valores si son modificados en el edition brick
				if (i === 0) {
					colThTable[j] = fila.label;
				}

				//creamos en la posición correcta de la tabla el valor numérico
				set(grid, [j, i, 'value'], fila.raw);

				//si estamos ya en la última columna
				//creamos la celda correspondiente a la COLUMNA NUEVA VACÍA
				if (i === data.length - 1) {
					set(grid, [j, i + 1, 'value'], '-');
					set(grid, [j, i + 1, 'readOnly'], false); //añadir y eliminar readonly? problemas al pegar
					set(grid, [j, i + 1, 'className'], 'delete-row');
					set(
						grid,
						[j, i + 1, 'component'],
						<div onClick={this.deleteRow} title={t`Eliminar fila`}>
							<Icon name="delete" size="small" />
						</div>,
					);
					set(grid, [j, i + 1, 'forceComponent'], true);
				}

				//si estamos ya en la última fila
				//creamos la celda correspondiente a la FILA NUEVA VACÍA
				if (j === columnas.length - 1) {
					set(grid, [j + 1, i, 'value'], '-');
					set(grid, [j + 1, i, 'readOnly'], false); //añadir y eliminar readonly? problemas al pegar
					set(grid, [j + 1, i, 'className'], 'delete-col');
					set(
						grid,
						[j + 1, i, 'component'],
						<div onClick={this.deleteCol} title={t`Eliminar columna`}>
							<Icon name="delete" size="small" />
						</div>,
					);
					set(grid, [j + 1, i, 'forceComponent'], true);
				}

				//crear la celda nueva de la esquina inferior derecha
				if (i === data.length - 1 && j === columnas.length - 1) {
					set(grid, [j + 1, i + 1, 'value'], '');
					set(grid, [j + 1, i + 1, 'className'], 'right-corner');
					set(grid, [j + 1, i + 1, 'readOnly'], false); //esquina inferior derecha readonly? problemas al pegar
				}
			}
		}

		//**Añadimos el título de columna NUEVA COLUMNA
		rowThTable.push(this.generateThCol('+', data.length + 1, false, 'new-col')); //añadir y eliminar readonly? problemas al pegar

		//**Añadir cabecera de fila . columna de título
		grid.forEach((row, i) => {
			// Si estoy en la última fila el encabezado sera para insertar NUEVA FILA
			if (i === grid.length - 1) {
				row.unshift(this.generateThRow('+', i, false, 'new-row')); //añadir y eliminar readonly? problemas al pegar
			} else {
				row.unshift(this.generateThRow(colThTable[i], i));
			}
		});

		//**Añadir los encabezados de columna - fila de título superior
		grid.unshift(rowThTable);

		return grid;
	};

	constructor(props) {
		super(props);

		this.state = {
			// selected: {
			// 	start: {i: 0, j: 0},
			// 	end: {i: 0, j: 0},
			// },
			selected: null,
		};
	}

	onSelect = (selected) => {
		const selectedCol = selected.start.j;
		const selectedRow = selected.start.i;
		this.setState({
			selected: {
				start: {i: selectedRow, j: selectedCol},
				end: {i: selectedRow, j: selectedCol},
			},
		});
	};

	// // Aquí controlamos las acciones de añadir y eliminar filas y columnas
	// onSelect = (selected) => {
	// 	const selectedCol = selected.start.j;
	// 	const selectedRow = selected.start.i;
	// 	const dataSheet = [].concat(this.props.editionBrickData.data.dataSheet);

	// 	//SE HA SELECCIONADO CELDA DE AÑADIR COLUMNA
	// 	if (selectedRow === 0 && selectedCol > dataSheet.length) {
	// 		//creamos la columna nueva = El encabezado y tantas celdas como filas tenga la tabla (usamos de referencia la primera columna de la misma)
	// 		const cellsOfCol = [];
	// 		const alphabet = ' ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	// 		for (let j = 0; j < dataSheet[0].value.length; j++) {
	// 			cellsOfCol.push({
	// 				_id: generateId(),
	// 				raw: 0,
	// 				label: '',
	// 			});
	// 		}
	// 		dataSheet.push({
	// 			name: alphabet[selectedCol % alphabet.length],
	// 			value: cellsOfCol,
	// 		});
	// 		//SE HA SELECCIONADO CELDA DE AÑADIR FILA
	// 	} else if (selectedCol === 0 && selectedRow > dataSheet[0].value.length) {
	// 		//creamos la fila nueva añadiendo al final de cada columna una celda vacía
	// 		for (let i = 0; i < dataSheet.length; i++) {
	// 			let label = dataSheet[i].value.length + 1;

	// 			dataSheet[i].value.push({
	// 				_id: generateId(),
	// 				raw: 0,
	// 				label: label.toString(),
	// 			});
	// 		}
	// 	} else if (
	// 		selectedRow > 0 &&
	// 		selectedRow < dataSheet[0].value.length + 1 &&
	// 		selectedCol > dataSheet.length
	// 	) {
	// 		//eliminamos la última celda de cada columna
	// 		for (let i = 0; i < dataSheet.length; i++) {
	// 			dataSheet[i].value.splice(selectedRow - 1, 1);
	// 		}
	// 	} else if (
	// 		selectedCol > 0 &&
	// 		selectedCol < dataSheet.length + 1 &&
	// 		selectedRow > dataSheet[0].value.length
	// 	) {
	// 		//eliminamos la última columna
	// 		dataSheet.splice(selectedCol - 1, 1);
	// 	}

	// 	// this.setState({
	// 	// 	selected: {
	// 	// 		start: {i: selectedRow, j: selectedCol},
	// 	// 		end: {i: selectedRow, j: selectedCol},
	// 	// 	},
	// 	// });
	// 	this.props.setMainField(dataSheet);
	// };

	render() {
		const dataTable = this.getData();

		return (
			<div className="edition-brick-data-sheet">
				<label className="edition-brick-textEditor__label">
					{this.props.editionBrickStruct.title}
				</label>
				{this.props.editionBrickStruct.instructions !== undefined && (
					<Instructions text={this.props.editionBrickStruct.instructions} />
				)}
				{/* TODO */}
				<div className="sheet-container">
					<ReactDataSheet
						data={this.createGrid(dataTable)}
						valueRenderer={(cell) => cell.value}
						onContextMenu={(e, cell) => (cell.readOnly ? e.preventDefault() : null)}
						onCellsChanged={this.onChange}
						onSelect={this.onSelect}
						// selected={null}
						// selected={this.state.selected}
					/>
				</div>
			</div>
		);
	}
}

export default editionBrickWrapper(DataSheet);
