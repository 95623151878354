import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ChromePicker} from 'react-color';
import Icon from 'ui/shared/Icon';
import './styles.scss';

class ColorSelector extends Component {
	deleteColor = () => {
		this.props.onSelectColor(false);
	};

	handleChangeComplete = (color) => {
		if (this.props.fullColor) {
			this.props.onSelectColor(color);
			return;
		}

		this.props.onSelectColor(color.rgb);
	};

	render() {
		const colorEmpty = {
			r: 0,
			g: 0,
			b: 0,
			a: this.props.defaultAlpha || 1,
		};
		return (
			<div className="color-selector">
				<div className="btn-delete-color" onClick={this.deleteColor}>
					<Icon name="delete-color" size="small" />
				</div>
				<ChromePicker
					onChangeComplete={this.handleChangeComplete}
					color={this.props.color === false ? colorEmpty : this.props.color}
					disableAlpha={this.props.disableAlpha}
				/>
			</div>
		);
	}
}

ColorSelector.propTypes = {
	onSelectColor: PropTypes.func,
	color: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	fullColor: PropTypes.bool,
};

ColorSelector.defaultProps = {
	onSelectColor: () => {},
	color: false,
	fullColor: false,
};

export default ColorSelector;
