import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Repeater',
						min: 1,
						fieldName: 'listItems',
						blockTitle: t`Elementos de lista`,
						content: [
							{
								type: 'TextEditor',
								fieldName: 'text',
								title: t`Texto`,
								placeholder: t`Introduce un texto`,
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'ColorBg',
						fieldName: ['containerStyle', 'colorBg'],
					},
					{
						type: 'ImageBg',
						fieldName: ['containerStyle', 'imageBg'],
					},

					{
						type: 'ColorLayerOpacity',
						fieldName: ['containerStyle', 'colorLayerOpacity'],
					},
					{
						type: 'PaddingTop',
						fieldName: ['containerStyle', 'paddingTop'],
					},
					{
						type: 'PaddingBottom',
						fieldName: ['containerStyle', 'paddingBottom'],
					},
					{
						type: 'ThemeSelector',
						fieldName: ['containerStyle', 'themeSelected'],
					},
				],
			},
		],
	},
];
export default editionFields;
