import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Number',
						fieldName: 'height',
						title: t`Tamaño del espaciado`,
						placeholder: t`Aumenta o disminuye el tamaño del espaciado`,
						min: 5,
						max: 300,
						step: 1,
					},
				],
			},
		],
	},
];
export default editionFields;
