import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Button from 'businessLogic/shared/Buttons/Button';
import classNames from 'classnames';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class DialogFooter extends PureComponent {
	render() {
		const {footer} = this.props;
		const classes = classNames({
			'dialog-footer': true,
			'dialog-footer--show': footer,
			'dialog-footer--hide': !footer
		});
		return (
			<div className={classes}>
				<div className="ok-button">
					<Button theme="primary" rounded={true} onClick={this.props.onClose}>
						{t`Listo`}
					</Button>
				</div>
			</div>
		);
	}
}

DialogFooter.propTypes = {
	className: PropTypes.string,
	text: PropTypes.string,
	footer: PropTypes.bool,
	onClose: PropTypes.func
};

export default DialogFooter;
