import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import TabContent from 'businessLogic/core/shared/Tab/TabContent';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.png';
import editionFields from './edit.js';
import './styles.scss';

class ShowInfoHexagons extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Ampliar Información - Hexágonos`;
	static brickIcon = 'show-info-hexagons';
	static brickImage = brickImage;

	static brickDefaultData = () => ({
		brickTitle: 'Asterid fauderi bessili',
		brickText:
			'Asterid fauderi bessili consuloca dius. Nimuncu pplius iam trior ad si pulia? Quam sulocul tissimi hilia? Otiam firit esil uteret diondam. Gratastrena re ips, qua rehemoltorum num se inam creviris cae moltordi, ubliae tanditisquit verra, sum ine conum adduceperum desse con hocci tericibena nesenat orteri.',
		tabs: [
			{
				section:
					'Quam sulocul tissimi hilia? Otiam firit esil uteret diondam. Gratastrena re ips, qua rehemoltorum numse inam creviris cae.',
				tabContent: [
					{
						imageObj: {
							imageSrc: '/uploads/default-images/csDefault1Opt.svg',
							credit: '',
						},
						title: 'ROS EOS INIS NAM UTASE BANSTREA SREAS',
						text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
					},
				],
			},
			{
				section:
					'Quam sulocul tissimi hilia? Otiam firit esil uteret diondam. Gratastrena re ips, qua rehemoltorum numse inam creviris cae.',
				tabContent: [
					{
						imageObj: {
							imageSrc: '/uploads/default-images/csDefault2Opt.svg',
							credit: '',
						},
						title: 'ROS EOS INIS NAM UTASE BANSTREA SREAS',
						text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
					},
				],
			},
			{
				section:
					'Quam sulocul tissimi hilia? Otiam firit esil uteret diondam. Gratastrena re ips, qua rehemoltorum numse inam creviris cae.',
				tabContent: [
					{
						imageObj: {
							imageSrc: '/uploads/default-images/csDefault3Opt.svg',
							credit: '',
						},
						title: 'ROS EOS INIS NAM UTASE BANSTREA SREAS',
						text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
					},
				],
			},
			{
				section:
					'Quam sulocul tissimi hilia? Otiam firit esil uteret diondam. Gratastrena re ips, qua rehemoltorum numse inam creviris cae.',
				tabContent: [
					{
						imageObj: {
							imageSrc: '/uploads/default-images/csDefault4Opt.svg',
							credit: '',
						},
						title: 'ROS EOS INIS NAM UTASE BANSTREA SREAS',
						text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
					},
				],
			},
			{
				section:
					'Quam sulocul tissimi hilia? Otiam firit esil uteret diondam. Gratastrena re ips, qua rehemoltorum numse inam creviris cae.',
				tabContent: [
					{
						imageObj: {
							imageSrc: '/uploads/default-images/csDefault5Opt.svg',
							credit: '',
						},
						title: 'ROS EOS INIS NAM UTASE BANSTREA SREAS',
						text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
					},
				],
			},
		],
	});

	constructor(props) {
		super(props);
		this.state = {
			activeTabIndex: -1,
			tabContentHeight: 0,
			tabContenContainertHeight: 0,
		};
		this.swiper = null;
	}

	changeActiveTab = (tabIndex) => {
		if (this.state.activeTabIndex !== tabIndex) {
			this.setState({activeTabIndex: tabIndex});
			setTimeout(() => {
				this.calculateHeight();
			}, 500);
		}
	};

	closeImage = () => {
		this.setState({activeTabIndex: -1});
	};

	calculateHeight = () => {
		let activeContentArray = this.refs.tabsContentContainer.querySelectorAll(
			'.show-info-hexagons__content',
		);
		let activeTabIndex = activeContentArray[this.state.activeTabIndex];
		if (activeTabIndex !== undefined) {
			this.setState({
				tabContentHeight: activeTabIndex.clientHeight,
				tabContenContainertHeight: this.refs.tabsContentContainer.clientHeight,
			});
		}
	};

	componentDidMount() {
		let _this = this;
		setTimeout(function () {
			_this.calculateHeight();
		}, 500);
	}

	renderLi = (tabListItem, index) => {
		return (
			<li
				onClick={() => this.changeActiveTab(index)}
				className={
					(this.state.activeTabIndex === index ? 'active' : '') +
					(index + 1 === this.props.brick.data.tabs.length && index + 1 === 1
						? ' one-hexagon '
						: '') +
					(index % 2 === 0 ? ' even-hexagon' : '')
				}
				key={index}
			>
				<div className="top-hexagon" />
				<FrontEditableText
					fieldName="section"
					text={tabListItem.section}
					editable={false}
					set={(fieldPath, fieldData) => {
						//Propagamos la llamada al método set añadiendo mi nodo al path
						if (this.props.set) this.props.set(['tabs', index].concat(fieldPath), fieldData);
					}}
				/>
				<div className="bottom-hexagon" />
			</li>
		);
	};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const tabs = data.tabs || [];
		const classes = classNames({
			'brick': true,
			'show-info-hexagons': true,
		});

		let tabsListFirstRow = [];
		let tabsListSecondRow = [];
		let tabsListThirdRow = [];
		let tabsListFourthRow = [];
		let _this = this;

		switch (tabs.length) {
			case 1:
			case 2:
			case 3:
			case 4:
				tabs.forEach(function (tabListItem, index) {
					tabsListFirstRow.push(_this.renderLi(tabListItem, index));
				});
				break;
			case 5:
				tabs.forEach(function (tabListItem, index) {
					if (index <= 1) {
						tabsListFirstRow.push(_this.renderLi(tabListItem, index));
					} else {
						tabsListSecondRow.push(_this.renderLi(tabListItem, index));
					}
				});
				break;
			case 6:
				tabs.forEach(function (tabListItem, index) {
					if (index <= 0) {
						tabsListFirstRow.push(_this.renderLi(tabListItem, index));
					} else if (index >= 1 && index <= 2) {
						tabsListSecondRow.push(_this.renderLi(tabListItem, index));
					} else {
						tabsListThirdRow.push(_this.renderLi(tabListItem, index));
					}
				});
				break;
			case 7:
				tabs.forEach(function (tabListItem, index) {
					if (index <= 2) {
						tabsListFirstRow.push(_this.renderLi(tabListItem, index));
					} else {
						tabsListSecondRow.push(_this.renderLi(tabListItem, index));
					}
				});
				break;
			case 8:
				tabs.forEach(function (tabListItem, index) {
					if (index <= 0) {
						tabsListFirstRow.push(_this.renderLi(tabListItem, index));
					} else if (index >= 1 && index <= 2) {
						tabsListSecondRow.push(_this.renderLi(tabListItem, index));
					} else if (index >= 3 && index <= 5) {
						tabsListThirdRow.push(_this.renderLi(tabListItem, index));
					} else {
						tabsListFourthRow.push(_this.renderLi(tabListItem, index));
					}
				});

				break;
			case 9:
				tabs.forEach(function (tabListItem, index) {
					if (index <= 3) {
						tabsListFirstRow.push(_this.renderLi(tabListItem, index));
					} else {
						tabsListSecondRow.push(_this.renderLi(tabListItem, index));
					}
				});
				break;
			case 10:
				tabs.forEach(function (tabListItem, index) {
					if (index <= 2) {
						tabsListFirstRow.push(_this.renderLi(tabListItem, index));
					} else if (index >= 3 && index <= 6) {
						tabsListSecondRow.push(_this.renderLi(tabListItem, index));
					} else {
						tabsListThirdRow.push(_this.renderLi(tabListItem, index));
					}
				});
				break;
			default:
		}

		const tabsContent = tabs.map((tabContentItems, tabIndex) => {
			//Parche para evitar fallo de prod que ya debería estar solucionado al añadir hexágonos
			if (!tabContentItems.tabContent) tabContentItems.tabContent = [];
			return (
				<TabContent
					key={tabIndex}
					item={tabContentItems}
					index={tabIndex}
					activeIndex={this.state.activeTabIndex}
					onClose={this.closeImage}
				/>
			);
		});

		let imgBg;
		if (styles) {
			if (styles.containerStyle) {
				if (styles.containerStyle.imageBg) {
					imgBg = true;
				} else {
					imgBg = false;
				}
			}
		}

		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					{imgBg && <div className="show-info-hexagons__mask" />}
					<div className="show-info-hexagons__main-content">
						<div className="show-info-hexagons__main-content__title">
							<FrontEditableText
								fieldName="brickTitle"
								text={data.brickTitle}
								set={this.props.set}
							/>
						</div>
						<div className="show-info-hexagons__main-content__separator" />
						<div className="show-info-hexagons__main-content__text">
							<FrontEditableText fieldName="brickText" text={data.brickText} set={this.props.set} />
						</div>
						<ul className="show-info-hexagons__main-content__selector">
							{tabsListFirstRow.length > 0 && (
								<div className="show-info-hexagons__main-content__selector__row">
									{tabsListFirstRow}
								</div>
							)}
							{tabsListSecondRow.length > 0 && (
								<div className="show-info-hexagons__main-content__selector__row">
									{tabsListSecondRow}
								</div>
							)}
							{tabsListThirdRow.length > 0 && (
								<div className="show-info-hexagons__main-content__selector__row">
									{tabsListThirdRow}
								</div>
							)}
							{tabsListFourthRow.length > 0 && (
								<div className="show-info-hexagons__main-content__selector__row">
									{tabsListFourthRow}
								</div>
							)}
						</ul>
					</div>
					<div
						className={
							'show-info-hexagons__content-container ' +
							(this.state.tabContentHeight < this.state.tabContenContainertHeight
								? 'no-scroll'
								: '')
						}
						ref="tabsContentContainer"
					>
						{tabsContent}
					</div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ShowInfoHexagons);
