import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const DialogMui = ({open, onClose, title, children, PaperComponent, sx, ...props}) => {
	return (
		<Dialog onClose={onClose} open={open} sx={sx} PaperComponent={PaperComponent} {...props}>
			<DialogTitle
				sx={{
					m: 0,
					p: 2,
					textAlign: 'center',
					marginBottom: 0,
					fontSize: 22,
					fontWeight: 300,
				}}
			>
				{title}
				{onClose ? (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>
			{children}
		</Dialog>
	);
};

DialogMui.propTypes = {
	onClose: PropTypes.func,
	title: PropTypes.string,
};

export default DialogMui;
