import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Image',
						fieldName: 'image',
						defaultImage: '/uploads/default-images/csDefault1Opt.svg',
						defaultCredit: t`Crédito de ejemplo`,
					},
					{
						type: 'Switch',
						fieldName: 'showNumbers',
						title: t`Mostrar números en los puntos calientes`,
					},
					{
						type: 'Repeater',
						min: 0,
						fieldName: 'hotSpots',
						blockTitle: t`Punto caliente`,
						content: [
							{
								type: 'Coordinates',
								fieldName: 'coordinates',
								title: t`Coordenadas`,
							},
							{
								type: 'TextEditor',
								fieldName: 'title',
								title: t`Título`,
								placeholder: t`Introduce el título`,
							},
							{
								type: 'TextEditor',
								fieldName: 'text',
								title: t`Texto`,
								placeholder: t`Introduce el texto`,
							},
							{
								type: 'Image',
								fieldName: 'imageObj',
								defaultImage: '/uploads/default-images/csDefault2Opt.svg',
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'CustomColor',
						name: t`Color capa de velado sobre imagen`,
						fieldName: ['colorLayer'],
					},
				],
			},
		],
	},
];
export default editionFields;
