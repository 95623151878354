import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'TextEditor',
						fieldName: 'brickTitle',
						title: t`Título`,
						placeholder: t`Introduce un título`,
						defaultValue: 'TITULO DE EJEMPLO',
					},
					{
						type: 'TextEditor',
						fieldName: 'brickText',
						title: t`Texto`,
						placeholder: t`Introduce un texto`,
						defaultValue:
							'Asterid fauderi bessili consuloca dius. Nimuncu pplius iam trior ad si pulia? Quam sulocul tissimi hilia? Otiam firit esil uteret diondam. Gratastrena re ips, qua rehemoltorum num se inam creviris cae moltordi, ubliae tanditisquit verra, sum ine conum adduceperum desse con hocci tericibena nesenat orteri.',
					},
					{
						type: 'Repeater',
						min: 1,
						max: 10,
						fieldName: 'tabs',
						blockTitle: 'Tabs',
						content: [
							{
								type: 'TextEditor',
								fieldName: 'section',
								title: t`Título de la sección`,
								placeholder: t`Introduce el título de la sección`,
							},
							{
								type: 'Repeater',
								min: 1,
								max: 10,
								fieldName: 'tabContent',
								blockTitle: 'Tab Content',
								content: [
									{
										type: 'Image',
										fieldName: 'imageObj',
										defaultImage: '/uploads/default-images/csDefault1Opt.svg',
									},
									{
										type: 'TextEditor',
										fieldName: 'title',
										title: t`Título de la imagen`,
										placeholder: t`Introduce el título de la imagen`,
									},
									{
										type: 'TextEditor',
										fieldName: 'text',
										title: t`Texto de la imagen`,
										placeholder: t`Introduce el texto de la imagen`,
									},
								],
							},
						],
					},
				],
			},
		],
	},
];
export default editionFields;
