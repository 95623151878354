import React from 'react';
import {useSlate} from 'slate-react';
import ToggleButton from '../basics/ToggleButton';

import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CodeIcon from '@mui/icons-material/Code';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

const Icon = ({format}) => {
	switch (format) {
		case 'blockquote':
			return <FormatQuoteIcon />;
		case 'bulleted-list':
			return <FormatListBulletedIcon />;
		case 'numbered-list':
			return <FormatListNumberedIcon />;
		case 'codeblock':
			return <CodeIcon />;
		case 'left':
			return <FormatAlignLeftIcon />;
		case 'center':
			return <FormatAlignCenterIcon />;
		case 'right':
			return <FormatAlignRightIcon />;
		case 'justify':
			return <FormatAlignJustifyIcon />;
		default:
			return null;
	}
};

const StyledToggleBlockButton = ({format}) => {
	const editor = useSlate();
	const {isBlockActive, toggleBlock, isAlignFormat} = editor.helpers;

	const isActive = isBlockActive(editor, format, isAlignFormat(format) ? 'align' : 'type');

	return (
		<ToggleButton format={format} onClick={() => toggleBlock(editor, format)} selected={isActive}>
			<Icon format={format} />
		</ToggleButton>
	);
};

export default StyledToggleBlockButton;
