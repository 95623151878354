import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import { imageUrl } from 'businessLogic/helpers/images';
import './styles.scss';

class ImagesHorizontalNavigation extends PureComponent {
	render() {
		const classes = classNames({
			'images-navigation-horizontal': true
		});

		const {
			items,
			selectedImageIndex,
			currentGalleryIndex,
			onSelectImage,
			onPreviousClick,
			onNextClick
		} = this.props;

		return (
			<div className={classes}>
				{currentGalleryIndex > 0 && (
					<div className="float-icon-left" onClick={onPreviousClick}>
						<Icon name="navigate-prev" size="normal" />
					</div>
				)}
				<div
					className={
						selectedImageIndex === currentGalleryIndex ?
							'images-navigation-horizontal__item imgActive' :
							'images-navigation-horizontal__item'
					}
					style={{
						backgroundImage: 'url(\'' + imageUrl(items[currentGalleryIndex].imageObj.imageSrc) + '\')'
					}}
					onClick={() => onSelectImage(currentGalleryIndex)}
				/>
				<div
					className={
						selectedImageIndex === currentGalleryIndex + 1 ?
							'images-navigation-horizontal__item imgActive' :
							'images-navigation-horizontal__item'
					}
					style={{
						backgroundImage:
							'url(\'' + imageUrl(items[currentGalleryIndex + 1].imageObj.imageSrc) + '\')'
					}}
					onClick={() => onSelectImage(currentGalleryIndex + 1)}
				/>
				<div
					className={
						selectedImageIndex === currentGalleryIndex + 2 ?
							'images-navigation-horizontal__item imgActive' :
							'images-navigation-horizontal__item'
					}
					style={{
						backgroundImage:
							'url(\'' + imageUrl(items[currentGalleryIndex + 2].imageObj.imageSrc) + '\')'
					}}
					onClick={() => onSelectImage(currentGalleryIndex + 2)}
				/>
				<div
					className={
						selectedImageIndex === currentGalleryIndex + 3 ?
							'images-navigation-horizontal__item imgActive' :
							'images-navigation-horizontal__item'
					}
					style={{
						backgroundImage:
							'url(\'' + imageUrl(items[currentGalleryIndex + 3].imageObj.imageSrc) + '\')'
					}}
					onClick={() => onSelectImage(currentGalleryIndex + 3)}
				/>
				{currentGalleryIndex < items.length - 4 && (
					<div className="float-icon-right" onClick={onNextClick}>
						<Icon name="navigate-next" size="normal" />
					</div>
				)}
			</div>
		);
	}
}

ImagesHorizontalNavigation.propTypes = {
	items: PropTypes.array,
	selectedImageIndex: PropTypes.number,
	classActive: PropTypes.bool,
	currentGalleryIndex: PropTypes.number,
	onSelectImage: PropTypes.func,
	onNextClick: PropTypes.func,
	onPreviousClick: PropTypes.func
};

ImagesHorizontalNavigation.defaultProps = {
	items: [],
	selectedImageIndex: 0,
	classActive: false,
	currentGalleryIndex: 0,
	onSelectImage: 0
};

export default ImagesHorizontalNavigation;
