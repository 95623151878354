import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Speedometer from 'businessLogic/core/shared/Icon/Speedometer';
import {t} from 'businessLogic/scope/user/helper/userTtag';
import CallActionTestBrickWithBoxes from 'businessLogic/core/shared/Test/CallActionTestBrickWithBoxes';
import './styles.scss';

class TestResult extends PureComponent {
	constructor(props) {
		super(props);
		this.animationFrameHandler = null;
		this.state = {
			counterScore: 0,
			percentScore: 0,
		};
	}

	initTimeCounter = () => {
		this.start = 0;
		this.animationFrameHandler = window.requestAnimationFrame(this.scoreAnimateInit);
	};

	scoreAnimateInit = (timestamp) => {
		if (!this.start) {
			this.start = timestamp;
		}
		const dif = timestamp - this.start;
		const totalAnimationTime = 2000;

		this.setState((state, props) => {
			let currentCounterScore = state.counterScore;
			let currentPercentScore = state.percentScore;
			if (currentCounterScore < props.score.raw) {
				//no queremos calcular los puntos de 1 en 1 si no que queremos que haga el cálculo dependiendo de los segundos que queremos que dure
				//la regla es que si lo que hemos conseguido (raw) es el total de 2 segundos, ¿cuanto es dif?
				currentCounterScore = Math.trunc((props.score.raw * dif) / totalAnimationTime);
				//calculamos el porcentaje para pasarlo al speedometer para que vaya independiente de la puntuación
				//el total siempre es el tiempo
				currentPercentScore = Math.trunc(
					((props.score.raw / props.score.max) * 100 * dif) / totalAnimationTime,
				);
				this.animationFrameHandler = window.requestAnimationFrame(this.scoreAnimateInit);
			} else {
				window.cancelAnimationFrame(this.animationFrameHandler);
			}
			return {
				counterScore: currentCounterScore,
				percentScore: currentPercentScore,
			};
		});
	};

	componentDidMount() {
		this.initTimeCounter();
	}

	render() {
		const classes = classNames({
			'test-basic-brick__result': true,
		});
		let totalScore = this.props.score.max;
		let userScore = this.props.score.raw;
		let percentuserScore = totalScore !== 0 ? (userScore / totalScore) * 100 : 0;

		if (!totalScore || isNaN(totalScore) || totalScore === 0) {
			const successes = this.props.successes || 0;
			const failures = this.props.failures || 0;
			const total = successes + failures;

			percentuserScore = total > 0 ? (successes / (successes + failures)) * 100 : 0;
		}

		let feedBack;

		// if (totalScore && !isNaN(totalScore) && totalScore !== 0) {
		if (
			percentuserScore !== null ||
			percentuserScore !== undefined ||
			percentuserScore !== '' ||
			percentuserScore !== ' ' ||
			isNaN(percentuserScore) !== true
		) {
			if (totalScore === 0) {
				feedBack = (
					<div className="test-basic-brick__result__feedbak">
						<h3>{t`Gracias`}</h3>
						<p>
							{t`por realizar el test.`}
							<br />
							{t`Sigue así.`}
						</p>
					</div>
				);
			} else if (percentuserScore <= 40) {
				feedBack = (
					<div className="test-basic-brick__result__feedbak">
						<h3>{t`Un poco flojo,`}</h3>
						<p>
							{t`necesitas repasar`} <br />
							{t`para llegar al aprobado.`}
						</p>
					</div>
				);
			} else if (percentuserScore <= 65) {
				feedBack = (
					<div className="test-basic-brick__result__feedbak">
						<h3>{t`Está bien,`}</h3>
						<p>
							{t`pero no te conformes.`}
							<br /> {t`Puedes hacerlo mejor.`}
						</p>
					</div>
				);
			} else if (percentuserScore <= 85) {
				feedBack = (
					<div className="test-basic-brick__result__feedbak">
						<h3>{t`Muy bien,`}</h3>
						<p>{t`se nota que has trabajado.`}</p>
					</div>
				);
			} else {
				feedBack = (
					<div className="test-basic-brick__result__feedbak">
						<h3>{t`¡Enhorabuena!`} </h3>
						<p>
							{t`Lo has hecho fenomenal.`}
							<br />
							{t`Sigue así.`}
						</p>
					</div>
				);
			}
		} else {
			console.error(
				'La puntuación alcanzada no es correcta porque no hay ningun posible acierto. Configura tus soluciones',
			);
		}
		// } else {
		// 	console.error('Por favor, necesitas dar un valor a cada pregunta acertada distinto de 0');
		// }

		return (
			<CallActionTestBrickWithBoxes className={classes} type="toInfoFinish">
				<div className={classes}>
					{totalScore !== 0 && (
						<div className="row test-basic-brick__result__speedometer">
							<Speedometer min={0} max={100} value={this.state.percentScore} />
						</div>
					)}
					{feedBack}
					{totalScore !== 0 && (
						<div className="test-basic-brick__result__score">
							<p>
								<span className="test-basic-brick__result__score__claim">
									{t`Has conseguido:`}{' '}
								</span>
								<span className="test-basic-brick__result__score--done">
									{this.state.counterScore} {t`puntos`}
								</span>
							</p>
						</div>
					)}
				</div>
			</CallActionTestBrickWithBoxes>
		);
	}
}

TestResult.propTypes = {
	successes: PropTypes.number,
	failures: PropTypes.number,
	completedQuestions: PropTypes.number,
	valueFailure: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	valueSuccess: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	coefSucces: PropTypes.number,
	set: PropTypes.func,
};

export default TestResult;
