import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getBrick} from 'businessLogic/store/data/pills';
import {BRICKS} from './bricks';
import ElementBoxInDialog from 'businessLogic/core/shared/Modals/ElementBoxInDialog';
import EditBrickWrapper from 'businessLogic/core/shared/Edit/EditBrickWrapper';
import EditContent from 'businessLogic/core/shared/Edit/EditContent';

// const EditContent = React.lazy(() =>
// 	import(/* webpackChunkName: "admin_EditContent" */ 'businessLogic/core/shared/Edit/EditContent'),
// );

//Se encarga de renderizar ladrillos o su ventana de edición
class BrickSwitch extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			reloadCounter: 1,
		};
	}

	//Método que recarga el ladrillo cambiando su key, para que renderice desde el inicio
	reloadBrick = () => {
		this.setState((state) => ({
			reloadCounter: state.reloadCounter + 1,
		}));
	};

	render() {
		//Si no tenemos ladrillo no mostramos nada
		if (!this.props.brick) {
			return <div />;
		}
		const Brick = BRICKS[this.props.brick.type];
		if (!Brick) {
			return null;
		}

		switch (this.props.viewMode) {
			case 'block':
				return (
					<ElementBoxInDialog
						labelBox={Brick.getName()}
						iconLabel={Brick.getIcon()}
						onDelete={this.props.deleteBrick}
						onEdit={this.props.editBrick}
					/>
				);

			case 'edit':
				if (Brick.editionFields) {
					const editionFields =
						typeof Brick.editionFields === 'function' ? Brick.editionFields() : Brick.editionFields;
					return (
						<EditContent
							title={Brick.getName()}
							helpText={Brick.getHelpText()}
							pillId={this.props.pillId}
							parentType={this.props.parentType}
							parentId={this.props.parentId}
							brick={this.props.brick}
							editionFields={editionFields}
						/>
					);
				}
				return <div />;

			//Renderizamos el ladrillo
			default:
				return (
					<EditBrickWrapper
						{...this.props}
						reloadBrick={Brick.canReload() ? this.reloadBrick : undefined}
						disableEdit={Brick.disableEdit()}
					>
						<Brick {...this.props} key={`${this.props.brick.id}_r${this.state.reloadCounter}`} />
					</EditBrickWrapper>
				);
		}
	}
}

BrickSwitch.propTypes = {
	brick: PropTypes.object,
	editDialog: PropTypes.bool,
	viewMode: PropTypes.oneOf(['view', 'block', 'edit']),
	addBrick: PropTypes.func,
	editBrick: PropTypes.func,
	deleteBrick: PropTypes.func,
	childList: PropTypes.string,
	zIndex: PropTypes.number,
};

BrickSwitch.defaultProps = {
	viewMode: 'view',
	editDialog: false,
	childList: 'default',
	zIndex: 1,
};

/*********************************
 **** Conectamos con el store ****
 *********************************/
//Info: La conexión con el store la hacemos en el bloque general por si pudiera llegar a darse
//el caso de que un ladrillo sin cambiar su id, cambiase su tipo. Si la conexión la hiciese
//cada ladrillo en su código, ya va implicito el tipo, y por tanto no podría reaccionar.
//Por otra parte, también se ahorra código al solo hacer un connect en lugar de muchos en cada ladrillo
const mapStateToProps = (state, ownProps) => {
	//Filtramos los valores null para evitar errores a posteriori
	const brick = getBrick(state, ownProps.pillId, ownProps.brickId);
	return {
		brick,
		...ownProps,
	};
};

const BrickSwitchConnect = connect(mapStateToProps)(BrickSwitch);

BrickSwitchConnect.propTypes = {
	pillId: PropTypes.string.isRequired,
	brickId: PropTypes.string.isRequired,
	parentType: PropTypes.string,
	parentId: PropTypes.string,
	editDialog: PropTypes.bool,
	viewMode: PropTypes.oneOf(['view', 'block', 'edit']),
	addBrick: PropTypes.func,
	editBrick: PropTypes.func,
	deleteBrick: PropTypes.func,
	zIndex: PropTypes.number,
	options: PropTypes.object, //Objeto con opciones para el renderizado del ladrillo
};

BrickSwitchConnect.defaultProps = {
	editDialog: false,
	viewMode: 'view',
};

export default BrickSwitchConnect;
