import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';

import './styles.scss';

class ImageTitleText extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Imagen con texto - estilo 2`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {
		imageObj: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
		},
		title: 'DUS QUE NOBIST VELESEQUE VELLORE',
		text: 'Quis eost, officim sinverum vellan quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.',
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}
	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const credit = data.imageObj.credit || '';
		const footer = data.imageObj.footer || '';
		const imageObj = data.imageObj || {};

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ImageTitleText']);

		const classes = classNames({
			'brick': true,
			'image-title-text-brick': true,
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<WindowSizeContext.Consumer>
					{({device}) => {
						return (
							<div className={classes}>
								<div className="image-title-text-brick__container">
									<div className="image-title-text-brick__container__content">
										<div className="image-title-text-brick__container__content__title">
											<FrontEditableText fieldName="title" text={data.title} set={this.props.set} />
										</div>
										<div className="image-title-text-brick__container__content__separator" />
										<div className="image-title-text-brick__container__content__text">
											<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
										</div>
									</div>
									<div className="image-title-text-brick__container__image">
										<Animation
											playAnimation={this.state.playEffect}
											effect={brickAnimation}
											delay={0.5}
											duration={1.2}
										>
											<NewImageClickable
												imgObj={imageObj}
												clickable={
													device === 'mobile' || device === 'mobileLandscape' ? false : true
												}
											/>

											{(credit || footer) && (
												<div className="image-title-text-brick__container__image__credit">
													{' '}
													{credit}{' '}
												</div>
											)}
										</Animation>
									</div>
								</div>
							</div>
						);
					}}
				</WindowSizeContext.Consumer>
			</Container>
		);
	}
}

export default brickWrapper(ImageTitleText);
