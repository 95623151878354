import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

class ScrollBarTestBrick extends PureComponent {
	render() {
		const mainStyleClass = 'scroll-bar-test-brick';
		const classes = classNames({
			[`${mainStyleClass}`]: true
		});
		return (
			<div className={classes}>
				<svg className={mainStyleClass + '__box'} width="10" height="100%">
					<g className={mainStyleClass + '__background'}>
						<line x1="50%" y1="10%" x2="50%" y2="95%" />
					</g>
					<g className={mainStyleClass + '__line'}>
						<line x1="50%" y1="10%" x2="50%" y2={(this.props.percentValue - 5) + '%'} />
					</g>
				</svg>
			</div>
		);
	}
}
ScrollBarTestBrick.propTypes = {
	percentValue: PropTypes.number
};
ScrollBarTestBrick.defaultProps = {
	percentValue:0
};
export default ScrollBarTestBrick;
