import store from 'businessLogic/store/store.js';
import {getDataUser} from 'businessLogic/store/data/session';
import {storageAvailable} from 'businessLogic/helpers/functions';

import entityManager from 'businessLogic/services/EntityManager';
/*

Interfaz común de eventos

Esta clase recoge todos los métodos que se podrán llamar desde la plataforma o curso,
aunque luego según el contexto se implementarán unos u otros. 

*/
class StatLogger {
	constructor() {
		this.isExportedScormCourse = process.env.REACT_APP_EVENTS_API === 'scorm';
		this.visibilityChangeHandler = null;
	}

	getLoggedUser() {
		return getDataUser(store.getState());
	}

	isLogged() {
		if (this.isExportedScormCourse) return false;

		return !!this.getLoggedUser();
	}

	isAdminAsUser() {
		const user = this.getLoggedUser();
		return user && user.adminAsUser;
	}

	isAdmin() {
		const user = this.getLoggedUser();
		return user && user.admin;
	}

	isAdminOrAdminAsUser() {
		const user = this.getLoggedUser();
		return user && (user.admin || user.adminAsUser);
	}

	isNativeDevice() {
		if (localStorage) {
			const nativeDeviceName = localStorage.getItem('nativeApp_deviceName');
			return !!nativeDeviceName;
		}
		return false;
	}

	areCookiesAccepted() {
		if (this.isLogged()) return true;
		if (this.isNativeDevice()) return true;

		const showCookieConsent = entityManager.isCookieConsentActive();

		if (!showCookieConsent) return true;

		// Obtenemos el valor de la cookie CookieConsent
		const cookieConsent = document.cookie
			.split('; ')
			.find((row) => row.startsWith('CookieConsent'));
		const cookieConsentValue = cookieConsent ? cookieConsent.split('=')[1] === 'true' : false;

		return cookieConsentValue;
	}

	async loggedIn() {
		if (this.isExportedScormCourse) return;
		if (this.isAdminAsUser()) return;
		if (!this.areCookiesAccepted()) return;

		await fetch('/event/loggedIn', {
			method: 'post',
			credentials: 'same-origin',
			mode: 'same-origin',
		});
	}

	async initFirstLevelPill(pillId, section) {
		if (this.isExportedScormCourse) return;
		if (this.isAdminOrAdminAsUser()) return;
		if (!this.areCookiesAccepted()) return;

		await fetch('/event/initFirstLevelPill', {
			method: 'post',
			credentials: 'same-origin',
			mode: 'same-origin',
			body: JSON.stringify({
				pillId,
				section,
			}),
		});

		this.visibilityChangeHandler = () => {
			if (navigator && navigator.sendBeacon) {
				if (document.visibilityState === 'visible') {
					navigator.sendBeacon(
						'/event/resumeFirstLevelPill',
						JSON.stringify({
							pillId,
							section,
						}),
					);
				} else {
					navigator.sendBeacon(
						'/event/hideFirstLevelPill',
						JSON.stringify({
							pillId,
							section,
						}),
					);
				}
			}
		};
		document.addEventListener('visibilitychange', this.visibilityChangeHandler);
	}

	async terminateFirstLevelPill(pillId, section) {
		if (this.isExportedScormCourse) return;
		if (this.isAdminOrAdminAsUser()) return;
		if (!this.areCookiesAccepted()) return;

		await fetch('/event/terminateFirstLevelPill', {
			method: 'post',
			credentials: 'same-origin',
			mode: 'same-origin',
			body: JSON.stringify({
				pillId,
				section,
			}),
		});

		if (this.visibilityChangeHandler) {
			document.removeEventListener('visibilitychange', this.visibilityChangeHandler);
		}
	}

	async initSecondLevelPill(pillId, parentId, section) {
		if (this.isExportedScormCourse) return;
		if (this.isAdminOrAdminAsUser()) return;
		if (!this.areCookiesAccepted()) return;

		await fetch('/event/initSecondLevelPill', {
			method: 'post',
			credentials: 'same-origin',
			mode: 'same-origin',
			body: JSON.stringify({
				pillId,
				parentId,
				section,
			}),
		});
	}

	async terminateSecondLevelPill(pillId, parentId, section) {
		if (this.isExportedScormCourse) return;
		if (this.isAdminOrAdminAsUser()) return;
		if (!this.areCookiesAccepted()) return;

		await fetch('/event/terminateSecondLevelPill', {
			method: 'post',
			credentials: 'same-origin',
			mode: 'same-origin',
			body: JSON.stringify({
				pillId,
				parentId,
				section,
			}),
		});
	}

	async scrollEvent({pillId, parentId, section, scrollPercentage}) {
		if (this.isExportedScormCourse) return;
		if (this.isAdminOrAdminAsUser()) return;
		if (!this.areCookiesAccepted()) return;

		await fetch('/event/scrollEvent', {
			method: 'post',
			credentials: 'same-origin',
			mode: 'same-origin',
			body: JSON.stringify({
				parentId,
				pillId,
				scrollPercentage,
				section,
			}),
		});
	}

	async brickEvent({pillId, brickId, brickType, brickAction, parentId, section}) {
		if (this.isExportedScormCourse) return;
		if (this.isAdminOrAdminAsUser()) return;
		if (!this.areCookiesAccepted()) return;

		await fetch('/event/brickEvent', {
			method: 'post',
			credentials: 'same-origin',
			mode: 'same-origin',
			body: JSON.stringify({
				parentId,
				pillId,
				brickId,
				brickType,
				brickAction,
				section,
			}),
		});
	}

	async newPillReaction({pillId, newReaction}) {
		if (this.isExportedScormCourse) return;
		if (this.isAdminOrAdminAsUser()) return;

		const user = this.getLoggedUser();

		if (!user && storageAvailable('localStorage')) {
			// Almacenamos el valor elegido para que la siguiente vez sepamos que ya ha votado
			localStorage.setItem(`reaction_${pillId}`, newReaction);
		}

		await fetch('/event/newPillReaction', {
			method: 'post',
			credentials: 'same-origin',
			mode: 'same-origin',
			body: JSON.stringify({
				pillId,
				newReaction,
			}),
		});
	}

	async changePillReaction({pillId, newReaction, currentReaction}) {
		if (this.isExportedScormCourse) return;
		if (this.isAdminOrAdminAsUser()) return;

		const user = this.getLoggedUser();

		if (!user && storageAvailable('localStorage')) {
			// Almacenamos el valor elegido para que la siguiente vez sepamos que ya ha votado
			localStorage.setItem(`reaction_${pillId}`, newReaction);
		}

		await fetch('/event/changePillReaction', {
			method: 'post',
			credentials: 'same-origin',
			mode: 'same-origin',
			body: JSON.stringify({
				pillId,
				newReaction,
				currentReaction,
			}),
		});
	}
}

export default new StatLogger();
