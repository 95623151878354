import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TrashIcon from '@mui/icons-material/Delete';
import AudioIcon from '@mui/icons-material/LibraryMusic';
import DocumentIcon from '@mui/icons-material/Description';
import PdfIcon from '@mui/icons-material/PictureAsPdf';

const styles = (sx) => (theme) => ({
	'&.FileItem': {
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'space-between',
		'gap': '0.5rem',
		'width': '100%',
		'height': '100%',
		'padding': '0.5rem',
		'borderRadius': '0.5rem',
		'backgroundColor': '#fff',
		'boxShadow': '0 0 0.5rem rgba(0, 0, 0, 0.1)',
		'& .FileItem__image': {
			width: 70,
			height: 'auto',
		},
		'& .FileItem__video': {
			width: 70,
			height: 'auto',
		},
		'& .FileItem__column': {
			'display': 'flex',
			'flexDirection': 'column',
			'gap': '0.25rem',
			'flex': 1,
			'maxWidth': '70%',
			'& .FileItem__name': {
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
			'& .FileItem__size': {
				whiteSpace: 'nowrap',
			},
		},
		'& .FileItem__icon': {
			color: theme.platformStyles.highlightColor,
			width: 60,
			height: 'auto',
		},
	},
	...sx,
});

/**
 *
 * @param {Object} file - Archivo subido de tipo File
 * @param {String} mimeType - Tipo de archivo
 * @param {Function} onDelete - Función que se ejecuta al eliminar un archivo
 * @param {Object} sx - Estilos de MUI
 * @returns Devuelve un listado de los archivos subidos con la opción de eliminarlos, mostrando un preview según el tipo de archivo
 *
 */
export default function FileItem({file, mimeType, onDelete, sx}) {
	return (
		<Box className="FileItem" sx={styles(sx)}>
			{mimeType === 'image' && (
				<img className="FileItem__image" src={URL.createObjectURL(file)} alt={file.name} />
			)}
			{/* Si es video, mostramos captura */}
			{mimeType === 'video' && (
				<video className="FileItem__video" src={URL.createObjectURL(file)} />
			)}
			{mimeType === 'audio' && <AudioIcon className="FileItem__icon" />}
			{mimeType === 'document' && <DocumentIcon className="FileItem__icon" />}
			{mimeType === 'pdf' && <PdfIcon className="FileItem__icon" size="large" />}
			{mimeType === 'any' && <DocumentIcon className="FileItem__icon" />}

			<Box className="FileItem__column">
				<Box className="FileItem__name">{file.name}</Box>
				<Box className="FileItem__size">{file.size / 1000} KB</Box>
			</Box>

			<IconButton
				className="FileItem__delete"
				variant="contained"
				color="error"
				onClick={() => onDelete(file)}
			>
				<TrashIcon />
			</IconButton>
		</Box>
	);
}
