import * as CONST from './constants.js';
import cloneDeep from 'lodash/cloneDeep';

let initialState = {
	editMode: true,
	dialog: {
		type: 'none',
		relatedId: ''
	}
};

//Exportamos por defecto la función reducer que gestiona todas las acciones
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case CONST.CHANGE_EDIT_MODE:
			return changeEditMode(state, action.dataPayload);
		case CONST.CHANGE_DIALOG:
			return changeDialog(state, action.dataPayload);
		default:
			return state;
	}
}

//Definimos las funciones que gestionan cada acción recibida para modificar el store

function changeEditMode(state, data) {
	const newState = cloneDeep(state);
	newState.editMode = data.editMode;
	return newState;
}

function changeDialog(state, data) {
	const newState = cloneDeep(state);
	newState.dialog = data;
	return newState;
}
