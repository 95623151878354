import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import StylesOptionsWrapper from '../StylesOptionsWrapper';
import AdminColorSelector from 'businessLogic/shared/Selects/AdminColorSelector';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';

import './styles.scss';

class ColorLayerOpacity extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	static getDefaultValue = () => {
		const defaultValue = {
			colorLayerOpacity: false,
		};

		return defaultValue;
	};

	handleChangeColorLayerOpacity = (color) => {
		this.props.setMainField(color);
	};

	render() {
		const colorLayerOpacity = this.props.getMainField();

		return (
			<StylesOptionsWrapper>
				<label className="item-title">{t`Capa de velado`}</label>
				<AdminColorSelector
					value={colorLayerOpacity}
					onChange={this.handleChangeColorLayerOpacity}
					defaultAlpha={0.8}
				/>
			</StylesOptionsWrapper>
		);
	}
}

export default editionBrickWrapper(ColorLayerOpacity);
