import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Swiper from 'react-id-swiper';
import Container from 'businessLogic/core/shared/Container/Container';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Icon from 'ui/shared/Icon';
import {imageUrl} from 'businessLogic/helpers/images';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

class CarouselWithBottomCaption extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Carrusel con texto`;
	static brickIcon = 'carousel';
	static brickImage = brickImage;
	static canReload = true;
	static brickDefaultConfig = {
		delay: 2,
		transitionTime: 2,
		autoplay: false,
		loop: false,
	};

	static brickDefaultData = {
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
				title: 'DUS QUE NOBISTA MEI',
				text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
				title: 'DUS QUE NOBISTA MEI',
				text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
				title: 'DUS QUE NOBISTA MEI',
				text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault4Opt.svg',
					credit: '',
				},
				title: 'DUS QUE NOBISTA MEI',
				text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault5Opt.svg',
					credit: '',
				},
				title: 'DUS QUE NOBISTA MEI',
				text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
			},
		],
	};

	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
		};
		this.swiper = null;
	}

	// componentDidMount() {
	// 	this.activeSlide();
	// 	this.changeActiveSlideContent();
	// 	let _this = this;
	// 	this.swiper.on('slideChange', function() {
	// 		_this.activeSlide();
	// 		_this.changeActiveSlideContent();
	// 	});
	// }

	activeSlide = () => {
		let numberSlides = this.props.brick.data.images.length;
		let slideActive;
		if (this.props.brick.config.loop === false) {
			slideActive = this.swiper.activeIndex;
			let prev = this.refs.swiperPrev;
			let next = this.refs.swiperNext;
			if (slideActive === 0) {
				prev.classList.add('disabled');
			} else if (slideActive + 1 === numberSlides) {
				next.classList.add('disabled');
			} else {
				prev.classList.remove('disabled');
				next.classList.remove('disabled');
			}
		}
	};

	changeActiveSlideContent = () => {
		if (this.props.brick.config.loop === false) {
			this.setState({
				activeIndex: this.swiper.activeIndex,
			});
		} else {
			let indexLoop = this.swiper.activeIndex - 1;
			if (this.swiper.activeIndex > this.props.brick.data.images.length) {
				let indexFixed = indexLoop - this.props.brick.data.images.length;
				this.setState({
					activeIndex: indexFixed,
				});
			} else if (indexLoop < 0) {
				let indexFixed = indexLoop + this.props.brick.data.images.length;
				this.setState({
					activeIndex: indexFixed,
				});
			} else {
				this.setState({
					activeIndex: indexLoop,
				});
			}
		}
	};

	goNext = () => {
		if (this.swiper) this.swiper.slideNext();
	};

	goPrev = () => {
		if (this.swiper) this.swiper.slidePrev();
	};

	render() {
		const data = this.props.brick.data;
		const config = this.props.brick.config;
		const styles = this.props.brick.styles || {};
		const images = data.images || [];
		let delay = config.delay || 0;
		let transitionTime = config.transitionTime || 0;

		let autoPlayInfo;
		if (config.autoplay === false) {
			autoPlayInfo = false;
		} else {
			delay = delay * 1000;
			autoPlayInfo = {
				delay: delay,
				disableOnInteraction: false,
			};
		}
		let speedInfo;
		if (transitionTime === 0) {
			speedInfo = 300;
		} else {
			transitionTime = transitionTime * 1000;
			speedInfo = transitionTime;
		}

		//https://www.npmjs.com/package/react-id-swiper
		const params = {
			shouldSwiperUpdate: true, //si no no se vuelve a renderizar al añadir imágenes al carrusel
			rebuildOnUpdate: false, //se recontruye si se cambian parámetros (valueDelay) o se añaden elementos al carrusel
			autoplay: autoPlayInfo,
			speed: speedInfo,
			spaceBetween: 0,
			loop: config.loop,
			preventClicks: false,
			preventClicksPropagation: false,
			// pagination: {
			// 	el: '.swiper-pagination',
			// 	clickable: true,
			// 	renderBullet: function(index, className) {
			// 		return '<span class="' + className + '">' + (index + 1) + '</span>';
			// 	},
			// },
			getSwiper: (swiper) => {
				this.swiper = swiper;
				this.activeSlide();
				this.changeActiveSlideContent();
				this.swiper.on('slideChange', () => {
					this.activeSlide();
					this.changeActiveSlideContent();
				});
			},
		};
		const classes = classNames({
			'brick': true,
			'carousel-with-bottom-caption': true,
		});

		let elements = images.map((slide, index) => {
			return (
				<div className="carousel-with-bottom-caption__item" key={index}>
					<div
						className="carousel-with-bottom-caption__item__img"
						style={{
							backgroundImage: `url('${imageUrl(slide.imageObj.imageSrc)}')`,
						}}
					/>
					{slide.imageObj.credit !== '' && (
						<div className="carousel-with-bottom-caption__item__img__credits">
							{slide.imageObj.credit}
						</div>
					)}

					{(slide.title !== '' || slide.text !== '') && (
						<div className="carousel-with-bottom-caption__item__caption">
							<div className="carousel-with-bottom-caption__item__caption__title">
								<div className="number">
									<span>{index + 1}</span>
									<span> / </span>
									<span>{images.length}</span>
								</div>

								<FrontEditableText
									fieldName="title"
									text={slide.title}
									set={(fieldPath, fieldData) => {
										//Propagamos la llamada al método set añadiendo mi nodo al path
										if (this.props.set)
											this.props.set(['images', index].concat(fieldPath), fieldData);
									}}
								/>
							</div>
							<div className="carousel-with-bottom-caption__item__caption__text">
								<FrontEditableText
									fieldName="text"
									text={slide.text}
									set={(fieldPath, fieldData) => {
										//Propagamos la llamada al método set añadiendo mi nodo al path
										if (this.props.set)
											this.props.set(['images', index].concat(fieldPath), fieldData);
									}}
								/>
							</div>
						</div>
					)}
				</div>
			);
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<Swiper {...params}>{elements}</Swiper>
					<div ref="swiperPrev" className="swiper-prev" onClick={this.goPrev}>
						<Icon name="navigate-prev" size="normal" />
					</div>
					<div ref="swiperNext" className="swiper-next" onClick={this.goNext}>
						<Icon name="navigate-next" size="normal" />
					</div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(CarouselWithBottomCaption);
