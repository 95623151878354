import React, {PureComponent} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

class AnswerAnimatedIcon extends PureComponent {
	render() {
		const classes = classNames({
			'answer-animated-icon': true,
			[this.props.answer]: true
		});
		return (
			<div className={classes}>
				<div className="answer-animated-icon__bar answer-animated-icon__bar--left" />
				<div className="answer-animated-icon__bar answer-animated-icon__bar--right" />
			</div>
		);
	}
}

AnswerAnimatedIcon.propTypes = {
	answer: PropTypes.string
};

AnswerAnimatedIcon.defaultProps = {
	answer: ''
};

export default AnswerAnimatedIcon;
