import tinycolor from 'tinycolor2';

export default {
	white: '#ffffff',
	black: '#000000',
	transparent: 'transparent',
	logoPink: '#fa5f85',
	logoPinkLight: '#f5d5ff',
	logoPinkExtraLight: '#f5d5ff6a',
	logoPinkDark: tinycolor('#fa5f85').darken(10).toString(),
	logoYellow: '#fedc82',
	logoYellowLight: '#fff5df',
	logoYellowExtraLight: '#fff5df6a',
	logoYellowDark: tinycolor('#fedc82').darken(10).toString(),
	logoYellowDark2: tinycolor('#fedc82').darken(20).toString(),
	logoPurple: '#687edb',
	logoPurpleLight: '#d8ddf8',
	logoPurpleExtraLight: '#eceffb',
	logoPurpleDark: tinycolor('#687edb').darken(10).toString(),
	logoGreen: '#31c6b8',
	logoGreenLight: '#c0efeb',
	logoGreenExtraLight: '#c0efeb6a',
	logoGreenDark: tinycolor('#31c6b8').darken(10).toString(),
	logoBlue: '#3cf',
	logoBlueLight: '#c0f1fe',
	logoBlueExtraLight: '#c0f1fe6a',
	logoBlueDark: tinycolor('#3cf').darken(10).toString(),

	mainBlue: '#101d36',
	grey: '#dddddd',
};
