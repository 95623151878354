import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import Icon from 'ui/shared/Icon';
import './styles.scss';

class ImagesHorizontalNavigation extends PureComponent {
	render() {
		const classes = classNames({
			'images-horizontal-navigation': true,
		});

		const images = this.props.items;
		const currentIndex = this.props.currentIndex;
		const onNextClick = this.props.onNextClick;
		const onPreviousClick = this.props.onPreviousClick;

		return (
			<div className={classes}>
				<div className="icon-navigate-prev" onClick={onPreviousClick}>
					<Icon name="navigate-prev" size="normal" className="cursorPointer" />
				</div>
				<NewImageClickable
					imgObj={images[currentIndex].imageObj}
					clickable={this.props.clickable}
				/>
				<div className="icon-navigate-next" onClick={onNextClick}>
					<Icon name="navigate-next" size="normal" className="cursorPointer" />
				</div>
			</div>
		);
	}
}

ImagesHorizontalNavigation.propTypes = {
	items: PropTypes.array,
	currentIndex: PropTypes.number,
	onNextClick: PropTypes.func,
	onPreviousClick: PropTypes.func,
	clickable: PropTypes.bool,
};

ImagesHorizontalNavigation.defaultProps = {
	items: [],
	currentIndex: 0,
};

export default ImagesHorizontalNavigation;
