/*

Interfaz común de eventos de los cursos

Esta clase recoge todos los métodos que se podrán llamar desde la plataforma o curso,
aunque luego según el contexto se implementarán unos u otros. 

*/
class CourseStateManager {
	//La clase CourseStateManager lleva el registro del curso y el tiempo de sesión
	course = null;
	sessionStartTime = null;
	pillDataCallbacks = {};
	brickDataCallbacks = {};

	setCourse(course) {
		this.course = course;
	}

	//Registra el inicio de una nueva sesión para un curso
	async initCourseSession(course) {
		this.sessionStartTime = new Date();
		this.course = course;
	}

	//Registra la finalización de la sessión para un curso, permitiendo el registro
	//del tiempo de la sesión
	async endCourseSession() {
		this.course = null;
	}

	async updateCache() {}

	//Registra un nuevo estado de completitud para el curso de la sesión actual
	async setCourseCompletionStatus() {}

	//Obtiene el estado de completitud del curso de la sesión actual
	async getCourseCompletionStatus() {
		return 'incomplete';
	}

	//Registra un nuevo estado de superación para el curso de la sesión actual
	async setCourseSuccessStatus() {}

	//Obtiene el estado de superación del curso de la sesión actual
	async getCourseSuccessStatus() {
		return 'unknown';
	}

	//Registra la localización actual dentro del curso de la sesión actual
	async setCourseCurrentLocation() {}

	//Obtiene la localización actual dentro del curso de la sesión actual
	async getCourseCurrentLocation() {
		return '';
	}

	//Registra un nuevo progreso para el curso de la sesión actual
	async setCourseProgress() {}

	//Obtiene el progreso del curso de la sesión actual
	async getCourseProgress() {
		return 0;
	}

	//Registra una nueva puntuación para el curso de la sesión actual
	async setCourseScore() {}

	//Obtiene la puntuación del curso de la sesión actual
	async getCourseScore() {
		return {
			scaled: 0,
			raw: 0,
			min: 0,
			max: 100,
		};
	}

	async finishCourse() {}

	async setPillData() {}

	async getPillData() {
		return undefined;
	}

	async getUpdatedPillData() {
		return undefined;
	}

	//Nos permite suscribirnos a los cambios en los datos asociados a una píldora
	//El callback se llamará pasando como parámetro los datos de la píldora
	subscribeToPillData(pillId, callback) {
		if (this.pillDataCallbacks[pillId]) {
			this.pillDataCallbacks[pillId].add(callback);
		} else {
			this.pillDataCallbacks[pillId] = new Set([callback]);
		}
	}

	//Nos permite desuscribirnos de los cambios en los datos asociados a una píldora
	unsubscribeFromPillData(pillId, callback) {
		if (this.pillDataCallbacks[pillId]) {
			this.pillDataCallbacks[pillId].delete(callback);
		}
	}

	//Llama a todos los callbacks suscritos a una píldora
	publishToPillData(pillId, data) {
		if (this.pillDataCallbacks[pillId]) {
			this.pillDataCallbacks[pillId].forEach((callback) => callback(data));
		}
	}

	async setBrickData() {}

	async getBrickData() {
		return undefined;
	}

	//Nos permite suscribirnos a los cambios en los datos asociados a una píldora
	//El callback se llamará pasando como parámetro los datos de la píldora
	subscribeToBrickData(pillId, brickId, callback) {
		const key = pillId + brickId;
		if (this.brickDataCallbacks[key]) {
			this.brickDataCallbacks[key].add(callback);
		} else {
			this.brickDataCallbacks[key] = new Set([callback]);
		}
	}

	//Nos permite desuscribirnos de los cambios en los datos asociados a una píldora
	unsubscribeFromBrickData(pillId, brickId, callback) {
		const key = pillId + brickId;
		if (this.brickDataCallbacks[key]) {
			this.brickDataCallbacks[key].delete(callback);
		}
	}

	//Llama a todos los callbacks suscritos a una píldora
	publishToBrickData(pillId, brickId, data) {
		const key = pillId + brickId;
		if (this.brickDataCallbacks[key]) {
			this.brickDataCallbacks[key].forEach((callback) => callback(data));
		}
	}
}

export default CourseStateManager;
