import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import './styles.scss';

class ColorAuto extends PureComponent {
	render() {
		return (
			<div
				className={
					'edition-brick-color-auto color--' + (this.props.path[this.props.path.length - 1] + 1)
				}
			/>
		);
	}
}

export default editionBrickWrapper(ColorAuto);
