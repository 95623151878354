import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'TextEditor',
						fieldName: 'beforeTitle',
						title: t`Antetítulo`,
						placeholder: t`Introduce un antetítulo`,
					},
					{
						type: 'TextEditor',
						fieldName: 'text',
						title: t`Texto`,
						placeholder: t`Introduce un texto`,
					},
					{
						type: 'TextEditor',
						fieldName: 'extraText1',
						title: t`Texto extra 1`,
						placeholder: t`Introduce un texto extra`,
					},
					{
						type: 'TextEditor',
						fieldName: 'extraText2',
						title: t`Texto extra 2`,
						placeholder: t`Introduce un texto extra`,
					},
					{
						type: 'Repeater',
						max: 2,
						fieldName: 'logos',
						blockTitle: t`Logos`,
						content: [
							{
								type: 'Image',
								fieldName: 'imageObj',
								defaultImage: '/uploads/default-images/logo-example.svg',
								credit: false,
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'ColorLayerOpacity',
						fieldName: ['containerStyle', 'colorOverlay'],
					},
					{
						type: 'ImageBg',
						fieldName: ['containerStyle', 'imageBg'],
					},
					{
						type: 'ThemeSelector',
						fieldName: ['containerStyle', 'themeSelected'],
					},
				],
			},
		],
	},
];

export default editionFields;
