import Quill from 'quill';
const Inline = Quill.import('blots/inline');

class WikiBlot extends Inline {
	// Ante posibles dudas de código visitar: https://github.com/quilljs/quill/blob/develop/formats/link.js ya que está basado en esto
	// Para poder tener botones que tengan funcionalidad personalizada con "tags" personalizas, necesitamos usar Parchment de quillJS
	// Guia Cloning Medium with Parchment https://quilljs.com/guides/cloning-medium-with-parchment/
	// Necesitamos crear una class que extienda a Inline, en la cual crearemos un node cuyo value, recibimos del evento "wikiHandler" a través
	// del this.quillEditor.format("wiki", value), el cual nos dará true cuando hacemos el click que lo activa y nos pasará un objeto con el
	// contenido que vamos a recibir para el elemento Wiki
	static create(value) {
		const node = super.create(value);
		// Para evitar errores por contenido vacio comprobamos que los valores del objeto recibido son diferentes de "undefined"
		if (value.img !== undefined) {
			node.setAttribute('data-image', value.img);
		}
		if (value.imgCredits !== undefined) {
			node.setAttribute('data-img-credits', value.imgCredits);
		}
		if (value.text !== undefined) {
			node.setAttribute('data-text', value.text);
		}
		return node;
	}

	static formats(domNode) {
		// Posteriormente para que cuando ejecutamos el código anterior no reciba los parámetros creados vacios
		// necesitamos a través del "formats" recuperar los atributos que tienen el contenido en este caso "data-image" y "date-text"
		const object = {
			text: domNode.getAttribute('data-text'),
			img: domNode.getAttribute('data-image'),
			imgCredits: domNode.getAttribute('data-img-credits')
		};

		return object;
	}
	format(name, value) {
		if (name !== this.statics.blotName || !value) return super.format(name, value);
		if (value.img !== undefined) {
			this.domNode.setAttribute('data-image', value.img);
		}
		if (value.imgCredits !== undefined) {
			this.domNode.setAttribute('data-img-credits', value.imgCredits);
		}
		if (value.text !== undefined) {
			this.domNode.setAttribute('data-text', value.text);
		}
	}
}
WikiBlot.blotName = 'wiki';
WikiBlot.tagName = 'wiki';
WikiBlot.className = 'my-wiki';

export default WikiBlot;
