import React, {Component} from 'react';
import classNames from 'classnames';
import editionBrickWrapper from '../editionBrickWrapper';
import VideoSelectorDialog from 'businessLogic/shared/MediaSelector/VideoSelectorDialog';
import ImageSelectorDialog from 'businessLogic/shared/MediaSelector/ImageSelectorDialog';
import LabelForInput from 'businessLogic/shared/Input/LabelForInput';
import Icon from 'ui/shared/Icon';
import Input from 'ui/shared/FormFields/Input';
import EditImageBox from 'businessLogic/shared/Image/EditImageBox';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';

import './styles.scss';

class Video extends Component {
	constructor(props) {
		super(props);

		this.state = {
			srcVideo: this.props.get([this.props.editionBrickStruct.fieldName, 'videoSrc']),
		};
	}
	handleDelete = () => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'videoSrc'], ' ');
	};

	handleReplace = () => {
		this.refs.videoSelectorDialog.show();
	};
	handleDeleteThumbnail = () => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'thumbnailSrc'], '');
	};

	handleReplaceThumbnail = () => {
		this.refs.thumbnailSelectorDialog.show();
	};

	handleNewThumbnail = (thumbnailSrc) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'thumbnailSrc'], thumbnailSrc);
	};

	handleNewVideo = (videoSrc) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'videoSrc'], videoSrc);
	};

	handleChangeCredit = (event) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'credit'], event.target.value);
	};

	render() {
		const editionBrickStruct = this.props.editionBrickStruct;
		const showCredit = editionBrickStruct.credit !== undefined ? editionBrickStruct.credit : true;

		let srcVideo = this.props.get([editionBrickStruct.fieldName, 'videoSrc']);
		let srcThumbnail = this.props.get([editionBrickStruct.fieldName, 'thumbnailSrc']);
		let valueCredit = this.props.get([editionBrickStruct.fieldName, 'credit']);

		//Parche para los ladrillos ya creados anteriormente
		if (srcVideo === undefined) srcVideo = this.props.get('video');
		if (valueCredit === undefined) valueCredit = this.props.get('credit');
		const classes = classNames({
			'edition-brick-video': true,
		});
		return (
			<div className={classes}>
				<div className={classes + '__select-video'}>
					<LabelForInput text={t`Vídeo:`} />
					<div className={classes + '__select-video__options'}>
						<div className={classes + '__select-video__options__icon'}>
							{/* <Icon name="video" size="normal" /> */}
							<video preload="metadata">
								<source src={this.state.srcVideo} type="video/mp4" />
							</video>
						</div>
						<div
							className={classes + '__select-video__options__add btn-video'}
							onClick={this.handleReplace}
						>
							<Icon name="plus" size="normal" />
							<span>{t`Añadir/Reemplazar vídeo`}</span>
						</div>
						<div
							className={classes + '__select-video__options__remove btn-video'}
							onClick={this.handleDelete}
						>
							<Icon name="delete" size="normal" />
							<span>{t`Borrar vídeo`}</span>
						</div>
					</div>
					<p>{srcVideo}</p>
				</div>
				<ul className={classes + '__video-info'}>
					{showCredit && (
						<li className={classes + '__video-info__credit'}>
							<LabelForInput text={t`Crédito:`} />
							<Input
								name="credito-foto"
								type="text"
								placeholder={t`Introduce el crédito`}
								value={valueCredit}
								onChange={this.handleChangeCredit}
							/>
						</li>
					)}
				</ul>
				<div className={classes + '__video-thumbnail'}>
					<LabelForInput text={`Thumbnail:`} />
					<EditImageBox
						srcImage={srcThumbnail}
						onDelete={this.handleDeleteThumbnail}
						onReplace={this.handleReplaceThumbnail}
						small={true}
						editable={true}
					/>
				</div>
				<VideoSelectorDialog ref="videoSelectorDialog" onSelect={this.handleNewVideo} />
				<ImageSelectorDialog ref="thumbnailSelectorDialog" onSelect={this.handleNewThumbnail} />
			</div>
		);
	}
}

export default editionBrickWrapper(Video);
