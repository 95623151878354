import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import './styles.scss';

class DraggableTagImage extends Component {
	render() {
		const mainStyleClass = 'tag-image-draggable';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			[`${mainStyleClass + '--active'}`]: this.props.active,
		});
		return (
			<figure className={classes}>
				<div className={mainStyleClass + '__image'}>
					<NewImageClickable
						imgObj={this.props.image}
						clickable={this.props.clickable}
						alt={this.props.image.credit}
						defaultVisible={true}
					/>
				</div>
				{this.props.text && (
					<figcaption className={mainStyleClass + '__text'}>{this.props.text}</figcaption>
				)}
			</figure>
		);
	}
}

DraggableTagImage.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
	clickable: PropTypes.bool,
	image: PropTypes.object,
	active: PropTypes.bool,
};
DraggableTagImage.defaultProps = {
	text: 'Texto de etiqueta',
	active: false,
};
export default DraggableTagImage;
