import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import Input from 'ui/shared/FormFields/Input';
import Instructions from 'businessLogic/core/shared/Edit/Instructions';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class YAxisConfig extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		let defaultValue = 0;
		if (editionBrickStruct.defaultValue) {
			defaultValue = editionBrickStruct.defaultValue;
		}
		return defaultValue;
	};

	onChangeMin = (event) => {
		this.props.setMainField({
			yAxisMin: event.target.value,
			yAxisMax: this.props.getMainField().yAxisMax,
			yAxisInterval: this.props.getMainField().yAxisInterval,
		});
	};
	onChangeMax = (event) => {
		this.props.setMainField({
			yAxisMax: event.target.value,
			yAxisMin: this.props.getMainField().yAxisMin,
			yAxisInterval: this.props.getMainField().yAxisInterval,
		});
	};
	onChangeInterval = (event) => {
		this.props.setMainField({
			yAxisInterval: event.target.value,
			yAxisMax: this.props.getMainField().yAxisMax,
			yAxisMin: this.props.getMainField().yAxisMin,
		});
	};

	render() {
		return (
			<div className={'edition-brick-number ' + this.props.editionBrickStruct.fieldName}>
				<label className="edition-brick-text__label">{this.props.editionBrickStruct.title}</label>
				{this.props.editionBrickStruct.instructions !== undefined && (
					<Instructions text={this.props.editionBrickStruct.instructions} />
				)}
				<div className="yaxis-min">
					<label> {t`Valor mínimo`} </label>
					<Input
						name="box-number"
						type="number"
						value={this.props.getMainField().yAxisMin}
						onChange={this.onChangeMin}
					/>
				</div>
				<div className="yaxis-max">
					<label>{t`Valor máximo`} </label>
					<Input
						name="box-number"
						type="number"
						value={this.props.getMainField().yAxisMax}
						onChange={this.onChangeMax}
					/>
				</div>
				{this.props.getMainField().yAxisMax > 0 && (
					<div className="yaxis-interval">
						<label>{t`Intervalo`} </label>
						<Instructions text="Para que se aplique, el intervalo debe ser como mínimo de un 5% sobre la diferencia entre el máximo y el mínimo" />
						<Input
							name="box-number"
							type="number"
							value={this.props.getMainField().yAxisInterval}
							onChange={this.onChangeInterval}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default editionBrickWrapper(YAxisConfig);
