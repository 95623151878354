import React from 'react';
import {useSlate} from 'slate-react';
import ToggleButton from '../basics/ToggleButton';
import LinkIcon from '@mui/icons-material/Link';

import UrlDialog from './components/UrlDialog';

const AddLinkButton = () => {
	const [open, setOpen] = React.useState(false);
	const editor = useSlate();
	const {wrapLink, getLink, editLink, unwrapLink} = editor.helpers;

	const [link] = getLink(editor);
	// console.log('link', link);

	return (
		<React.Fragment>
			<ToggleButton
				format="link"
				disabled={editor.selection == null}
				onClick={() => {
					setOpen(true);
				}}
				value="link"
				selected={link != null}
			>
				<LinkIcon />
			</ToggleButton>
			<UrlDialog
				open={open}
				onCancel={() => setOpen(false)}
				onAccept={(url) => {
					if (url && url.length > 0) {
						if (!link) {
							wrapLink(editor, url);
						} else {
							editLink(editor, url);
						}
					} else {
						unwrapLink(editor);
					}
					setOpen(false);
				}}
				defaultValue={link ? link.url : ''}
			/>
		</React.Fragment>
	);
};

export default AddLinkButton;
