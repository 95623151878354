import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';

import './styles.scss';

class ImageTitleTextBox extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Imagen con texto - estilo 1`;
	static brickIcon = 'image';
	static brickImage = brickImage;

	static brickDefaultData = {
		imageObj: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
		},
		title: 'DUS QUE NOBIST VELESEQUE VELLORE',
		text: 'Quis eost, officim sinverum vellan quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.',
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
			// generalColor: {r: '115', g: '229', b: '209', a: '1'},
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		// const styles = this.props.brick.styles || {};
		let styles;
		if (isEmpty(this.props.brick.styles)) {
			styles = ImageTitleTextBox.brickDefaultStyles;
		} else {
			styles = this.props.brick.styles || {};
		}

		const credit = data.imageObj.credit || '';
		const footer = data.imageObj.footer || '';
		const imageObj = data.imageObj || {};

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ImageTitleTextBox']);

		const classes = classNames({
			'brick': true,
			'image-title-text-box-brick': true,
			'image-title-text-box-brick--with-caption': footer || credit,
		});

		let newStyles = {
			backText: {},
		};

		const generalColor = styles.containerStyle.generalColor;

		if (generalColor) {
			newStyles.backText.backgroundColor =
				'rgba(' +
				generalColor.r +
				',' +
				generalColor.g +
				',' +
				generalColor.b +
				',' +
				generalColor.a +
				')';
		} else {
			const highlightColor = getComputedStyle(document.documentElement).getPropertyValue(
				'--highlightColor',
			);
			newStyles.backText.backgroundColor = highlightColor;
		}

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<WindowSizeContext.Consumer>
					{({device}) => {
						return (
							<div className={classes}>
								<div className="image-title-text-box-brick__box">
									<div className="image-title-text-box-brick__box__image">
										<Animation
											playAnimation={this.state.playEffect}
											delay={0.5}
											effect={brickAnimation}
										>
											<NewImageClickable
												imgObj={imageObj}
												clickable={
													device === 'mobile' || device === 'mobileLandscape' ? false : true
												}
											/>

											{(credit || footer) && (
												<div className="image-title-text-box-brick__box__image__credit">
													{' '}
													{credit}{' '}
												</div>
											)}
										</Animation>
									</div>

									<div
										className="image-title-text-box-brick__box__content"
										style={newStyles.backText}
									>
										<div className="image-title-text-box-brick__box__content__title">
											<Animation
												playAnimation={this.state.playEffect}
												delay={2 * 0.5}
												effect={brickAnimation}
											>
												<FrontEditableText
													fieldName="title"
													text={data.title}
													set={this.props.set}
												/>
											</Animation>
										</div>
										<div className="image-title-text-box-brick__box__content__text">
											<Animation
												playAnimation={this.state.playEffect}
												delay={2 * 0.5}
												effect={brickAnimation}
											>
												<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
											</Animation>
										</div>
									</div>
								</div>
							</div>
						);
					}}
				</WindowSizeContext.Consumer>
			</Container>
		);
	}
}

export default brickWrapper(ImageTitleTextBox);
