import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {getRgbaString} from 'businessLogic/helpers/functions';
import get from 'lodash/get';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

class SeparatorBall extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Círculo`;
	static brickImage = brickImage;

	// static brickDefaultStyles = {
	// 	containerStyle: {
	// 		generalColor: 'default',
	// 	},
	// };

	render() {
		// const styles = this.props.brick.styles || {};
		let styles;
		if (!get(this.props, 'brick.styles.containerStyle.generalColor')) {
			const highlightColor = getComputedStyle(document.documentElement).getPropertyValue(
				'--highlightColor',
			);
			styles = {
				containerStyle: {
					generalColor: highlightColor,
				},
			};
		} else {
			styles = this.props.brick.styles || {};
		}
		let stylesBall = {
			ball: {},
		};

		const generalColor = styles.containerStyle.generalColor;

		if (generalColor) stylesBall.ball.backgroundColor = getRgbaString(generalColor);

		const classes = classNames({
			'brick': true,
			'separator-ball': true,
		});
		return (
			<Container
				size="small"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div className="separator-ball__item" style={stylesBall.ball} />
				</div>
			</Container>
		);
	}
}

export default brickWrapper(SeparatorBall);
