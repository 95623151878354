import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Icon from 'ui/shared/Icon';
import './styles.scss';

class DialogHeader extends PureComponent {
	render() {
		return (
			<div className={this.props.className + ' dialog-header'}>
				<h2>{this.props.text}</h2>
				<span href="" className="dialog-header__close" onClick={this.props.onClose}>
					<Icon name="close-modal" size="normal" />
				</span>
			</div>
		);
	}
}

DialogHeader.propTypes = {
	className: PropTypes.string,
	text: PropTypes.string,
	onClose: PropTypes.func
};

export default DialogHeader;
