import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

class HeadingBeforeHeadingBox extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t` Título y antetítulo - estilo 2`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		beforeHeading: 'NEXT CHAPTER',
		heading: 'Dusue nobista micius et quia ligoas nobista micius',
	};
	static brickDefaultConfig = {};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const classes = classNames({
			'brick': true,
			'heading-beforeheading-box-brick': true,
		});

		return (
			<Container
				size="normal"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<div className="heading-beforeheading-box-brick__heading">
						<div className="heading-beforeheading-box-brick__before-heading">
							<div className="heading-beforeheading-box-brick__before-heading__line--left" />
							<FrontEditableText
								fieldName="beforeHeading"
								text={data.beforeHeading}
								set={this.props.set}
							/>
							<div className="heading-beforeheading-box-brick__before-heading__line--right" />
						</div>

						<FrontEditableText fieldName="heading" text={data.heading} set={this.props.set} />
					</div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(HeadingBeforeHeadingBox);
