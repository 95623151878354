import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';

import './styles.scss';

class ThreeImageCompositionWithText extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Composición de tres imágenes con dos textos ancho completo`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {
		firstImage: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
		},
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault2Opt.svg',
					credit: '',
				},
				beforeTitle: 'EVERIOS DUCID MAIONSED',
				title: 'DUS QUE NOBIST VELESEQUE VELLORE',
				text: 'Quis eost, ofcim sinverum vellant quam corrumque csxsasasa il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi as exerchita doloreperum quaectur, ut into te la nobitatur? Quiduciet, iunt ut am, nem nos aut molupiet etusamus, ut resed et fugiti a dolore pa vel istios es cus sed modipiderum',
			},
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault3Opt.svg',
					credit: '',
				},
				beforeTitle: 'EVERIOS DUCID MAIONSED',
				title: 'DUS QUE NOBIST VELESEQUE VELLORE',
				text: 'Quis eost, ofcim sinverum vellant quam corrumque csxsasasa il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi as exerchita doloreperum quaectur, ut into te la nobitatur? Quiduciet, iunt ut am, nem nos aut molupiet etusamus, ut resed et fugiti a dolore pa vel istios es cus sed modipiderum',
			},
		],
	};
	static brickDefaultConfig = {};
	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const images = data.images || [];
		const mainStyleClass = 'three-image-composition-with-text-brick';

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ThreeImageCompositionWithText']);

		const classes = classNames({
			brick: true,
			[`${mainStyleClass}`]: true,
		});

		let imgBg;
		if (styles) {
			if (styles.containerStyle) {
				if (styles.containerStyle.imageBg) {
					imgBg = true;
				} else {
					imgBg = false;
				}
			}
		}

		return (
			<Container
				size="fullWidth"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<WindowSizeContext.Consumer>
					{({device}) => {
						return (
							<div className={classes + (imgBg ? ' with-imgBg' : '')}>
								<div className="row row--noMargin--lg row--nowrap--lg">
									<div className="col-xs-12 col-lg-6">
										<article className={mainStyleClass + '__item--main'}>
											<Animation
												playAnimation={this.state.playEffect}
												delay={1 * 0.5}
												effect={brickAnimation}
											>
												<figure className={mainStyleClass + '__item--main__img'}>
													<NewImageClickable
														imgObj={data.firstImage}
														clickable={
															device === 'mobile' || device === 'mobileLandscape' ? false : true
														}
													/>
												</figure>
											</Animation>
										</article>
									</div>

									<div className="col-xs-12 col-lg-6">
										{/* row--noMargin */}
										<div className="row row--noMargin--xs row--margin--lg">
											<div className="col-xs-9 col-md-12">
												<Animation
													playAnimation={this.state.playEffect}
													delay={2 * 0.5}
													effect={brickAnimation}
												>
													<article
														className={
															mainStyleClass + '__item ' + mainStyleClass + '__item--first'
														}
													>
														<div className={mainStyleClass + '__item__inner'}>
															<header className={mainStyleClass + '__item__header'}>
																<div className={mainStyleClass + '__item__before-title'}>
																	<FrontEditableText
																		fieldName="beforeTitle"
																		text={images[0].beforeTitle}
																		set={(fieldPath, fieldData) => {
																			//Propagamos la llamada al método set añadiendo mi nodo al path
																			if (this.props.set)
																				this.props.set(['images', 0].concat(fieldPath), fieldData);
																		}}
																	/>
																</div>
																<div className={mainStyleClass + '__item__title'}>
																	<FrontEditableText
																		fieldName="title"
																		text={images[0].title}
																		set={(fieldPath, fieldData) => {
																			//Propagamos la llamada al método set añadiendo mi nodo al path
																			if (this.props.set)
																				this.props.set(['images', 0].concat(fieldPath), fieldData);
																		}}
																	/>
																</div>
															</header>
															<div className={mainStyleClass + '__item__description'}>
																<FrontEditableText
																	fieldName="text"
																	text={images[0].text}
																	set={(fieldPath, fieldData) => {
																		//Propagamos la llamada al método set añadiendo mi nodo al path
																		if (this.props.set)
																			this.props.set(['images', 0].concat(fieldPath), fieldData);
																	}}
																/>
															</div>
														</div>

														<figure className={mainStyleClass + '__item__img'}>
															<NewImageClickable imgObj={images[0].imageObj} />
														</figure>
													</article>
												</Animation>
											</div>

											<div className="col-xs-9 col-xs-offset-3 col-md-12 col-md-offset-0">
												<Animation
													playAnimation={this.state.playEffect}
													delay={3 * 0.5}
													effect={brickAnimation}
												>
													<article
														className={mainStyleClass + '__item ' + mainStyleClass + '__item--last'}
													>
														<div className={mainStyleClass + '__item__inner'}>
															<header className={mainStyleClass + '__item__header'}>
																<div className={mainStyleClass + '__item__before-title'}>
																	<FrontEditableText
																		fieldName="beforeTitle"
																		text={images[1].beforeTitle}
																		set={(fieldPath, fieldData) => {
																			//Propagamos la llamada al método set añadiendo mi nodo al path
																			if (this.props.set)
																				this.props.set(['images', 1].concat(fieldPath), fieldData);
																		}}
																	/>
																</div>
																<div className={mainStyleClass + '__item__title'}>
																	<FrontEditableText
																		fieldName="title"
																		text={images[1].title}
																		set={(fieldPath, fieldData) => {
																			//Propagamos la llamada al método set añadiendo mi nodo al path
																			if (this.props.set)
																				this.props.set(['images', 1].concat(fieldPath), fieldData);
																		}}
																	/>
																</div>
															</header>
															<div className={mainStyleClass + '__item__description'}>
																<FrontEditableText
																	fieldName="text"
																	text={images[1].text}
																	set={(fieldPath, fieldData) => {
																		//Propagamos la llamada al método set añadiendo mi nodo al path
																		if (this.props.set)
																			this.props.set(['images', 1].concat(fieldPath), fieldData);
																	}}
																/>
															</div>
														</div>

														<figure className={mainStyleClass + '__item__img'}>
															<NewImageClickable imgObj={images[1].imageObj} />
														</figure>
													</article>
												</Animation>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					}}
				</WindowSizeContext.Consumer>
				<ul className={mainStyleClass + '__credits'}>
					{data.firstImage.credit && <li>{'1. ' + data.firstImage.credit}</li>}
					{images[0].imageObj.credit && <li>{'2. ' + images[0].imageObj.credit}</li>}
					{images[1].imageObj.credit && <li>{'3. ' + images[1].imageObj.credit}</li>}
				</ul>
			</Container>
		);
	}
}

export default brickWrapper(ThreeImageCompositionWithText);
