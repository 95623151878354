import React from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import store from 'businessLogic/store/store';
import {isSuperAdmin} from 'businessLogic/store/data/session';

const SuperAdmin = ({editionBrickStruct, renderChildren}) => {
	if (!isSuperAdmin(store.getState())) {
		return null;
	}

	const content = editionBrickStruct.content;
	const field = editionBrickStruct.fieldName;

	return <div className="edition-brick-super-admin">{renderChildren(content, field)}</div>;
};

export default editionBrickWrapper(SuperAdmin);
