import React from 'react';
import Divider from '@mui/material/Divider';

const StyledDivider = ({...props}) => (
	<Divider
		orientation="vertical"
		flexItem
		sx={{
			margin: '0 5px',
		}}
		{...props}
	/>
);

export default StyledDivider;
