import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';

import SimpleTestIntro from 'businessLogic/core/shared/Test/SimpleTestIntro';
import Test from './components/Test';

import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Element} from 'react-scroll';

import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';

import InstructionTestBrick from 'businessLogic/core/shared/Test/InstructionTestBrick';
import CallActionTestBrickWithBoxes from 'businessLogic/core/shared/Test/CallActionTestBrickWithBoxes';
import ModalForTestBricks from 'businessLogic/core/shared/Test/ModalForTestBricks';

import './styles.scss';

class ClassifySurvey extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Clasifica los elementos`;
	static brickIcon = 'test';
	static brickImage = brickImage;
	static canReload = true;
	static brickHelpText = () =>
		t`Permite recoger datos de preferencias del usuario a través de una clasificación de elementos.`;
	static brickDefaultConfig = () => ({
		pointSuccess: 0,
		pointFailure: 0,
		brickName: t`Clasifica los elementos`,
		brickDescription: t`Permite recoger datos de preferencias del usuario a través de una clasificación de elementos. `,
		requiredActions: true,
	});
	static brickDefaultData = () => ({
		image: {
			imageSrc: '/uploads/default-images/ilustration_test.svg',
			credit: '',
		},
		textLinkInstructions: '',
		instructions: '',
		beforeHeading: _('Clasifica los elementos'),
		title: _('Cataloga estos items según tu preferencia'),
		descriptionTest: _(
			'Instrucciones de funcionamiento del test. Marca las casillas correctas y envía tu respuesta',
		),
		textButtonStart: _('EMPEZAR'),
		textFinalFeedback: _('¡Gracias!'),
		imageFinalFeedback: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
		},
		categories: [
			{
				category: _('CATEGORÍA A'),
			},
			{
				category: _('CATEGORÍA B'),
			},
		],
		tags: [
			{
				tagName: _('Etiqueta 1'),
			},
			{
				tagName: _('Etiqueta 2'),
			},
			{
				tagName: _('Etiqueta 3'),
			},
			{
				tagName: _('Etiqueta 4'),
			},
		],
	});
	constructor(props) {
		super(props);
		this.state = {
			type: 'ClassifySurvey',
			completed: false,
			success: false,
			stateMachine: 'intro',
		};
		this.classifySurvey = React.createRef();
		this.props.getBrickStateData('').then((brickStateData) => {
			if (brickStateData && brickStateData.state) {
				this.setState(brickStateData.state);

				//Parche para solventar los casos donde no se ha guardado el status del ladrillo
				if (brickStateData.state.stateMachine === 'result' && !brickStateData.status) {
					this.initStateTestResult();
				}
			}
		});
	}

	saveBrickState = () => {
		this.props.setBrickStateData('state', this.state);
	};

	handleScrollTest = () => {
		// scroller.scrollTo("brick--" + this.props.brickId, {
		// 	smooth: false,
		// 	containerId: "scroll-container",
		// 	offset: 0
		// });
	};

	initStateTestQuestion = () => {
		this.setState({stateMachine: 'test'}, this.saveBrickState);
		this.handleScrollTest();

		//Lanzamos el evento de inicio de test
		this.props.brickEvent('start');
	};

	initStateTestResult = (response) => {
		this.setState(
			{
				stateMachine: 'result',
				completed: true,
				success: true,
				response,
			},
			this.saveBrickState,
		);
		this.handleScrollTest();

		//Lanzamos el evento de fin de test
		this.props.brickEvent('end');
		//Marcamos el test como completado y añadidmos si es evaluable

		const status = {
			completed: true,
			success: true,
		};
		this.props.setBrickStateData('status', status).then(() => {
			this.props.configObject.callEventListeners('brickStatusChanged', {
				brick: 'ClassifySurvey',
				type: 'info',
				status,
			});
		});

		// Enviamos también datos anónimos de este ladrillo
		this.props.setBrickAnomData(response);
	};
	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const categories = data.categories || [];
		const tagsCollection = data.tags || [];
		const image = data.imageFinalFeedback || {};
		const classes = classNames({
			'brick': true,
			'classify-survey-brick': true,
		});

		let step;

		switch (this.state.stateMachine) {
			default:
			case 'intro':
				step = (
					<CSSTransition key="intro" timeout={500} classNames="show">
						<div className="step stept--1">
							<CallActionTestBrickWithBoxes
								type="toStart"
								textButton={data.textButtonStart}
								beforeHeading={data.beforeHeading}
								image={data.image}
								onClick={this.initStateTestQuestion}
								set={this.props.set}
								className={classes}
							>
								<SimpleTestIntro
									textDescription={data.descriptionTest}
									onClick={this.initStateTestQuestion}
									set={this.props.set}
								/>
								<InstructionTestBrick
									textLabel={data.textLinkInstructions}
									textHelp={data.instructions}
									theme="dark"
								/>
							</CallActionTestBrickWithBoxes>
						</div>
					</CSSTransition>
				);
				break;
			case 'test':
				step = (
					<CSSTransition key="test" timeout={500} classNames="show">
						<div className="step stept--2">
							<ModalForTestBricks
								onClose={this.initStateTestResult}
								isEditMode={this.props.editMode}
							>
								<Test
									ref={this.classifySurvey}
									tagsCollection={tagsCollection}
									categories={categories}
									onFinished={this.initStateTestResult}
									set={this.props.set}
									onScroll={this.handleScrollTest}
								/>
							</ModalForTestBricks>
						</div>
					</CSSTransition>
				);
				break;
			case 'result':
				step = (
					<CSSTransition key="tresult" timeout={500} classNames="show">
						<div className="step stept--3">
							<CallActionTestBrickWithBoxes
								type="toInfoFinish"
								className={classes}
								image={image}
								beforeHeading={data.textFinalFeedback}
								set={(fieldPath, fieldData) => {
									//Propagamos la llamada al método set añadiendo mi nodo al path
									if (this.props.set)
										this.props.set(
											['profiles', this.state.resultIndex].concat(fieldPath),
											fieldData,
										);
								}}
							></CallActionTestBrickWithBoxes>
						</div>
					</CSSTransition>
				);
				break;
		}

		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<Element name={'brick--' + this.props.brickId}>
					<div className={classes}>
						<TransitionGroup className="todo-list">{step}</TransitionGroup>
					</div>
				</Element>
			</Container>
		);
	}
}

export default brickWrapper(ClassifySurvey);
