import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import HotSpotModal from 'businessLogic/core/shared/Buttons/HotSpotModal';
import LayerMoreInfo from 'businessLogic/core/shared/Modals/LayerMoreInfo';
import ImgOverlay from 'businessLogic/core/shared/Layers/ImgOverlay';
import {imageUrl} from 'businessLogic/helpers/images';
import editionFields from './edit.js';
import brickImage from './img/brickPreview.jpg';
import brickImage2 from './img/brickPreview2.png';
import classNames from 'classnames';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

// react-image-mapper
// https://www.npmjs.com/package/react-image-mapper

class ShowHotSpotsPopUp extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Puntos calientes con imagen grande y  pop-up`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickImage2 = brickImage2;
	static brickHelpText = () => t`Permite incluir píldoras dentro de puntos clicables en una imagen`;
	static brickDefaultData = {
		image: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
		},
		showNumbers: false,
		hotSpots: [
			{
				_id: 'Box_1',
				coordinates: {
					x: 50,
					y: 50,
				},
			},
			{
				_id: 'Box_2',
				coordinates: {
					x: 25,
					y: 10,
				},
			},
			{
				_id: 'Box_3',
				coordinates: {
					x: 75,
					y: 30,
				},
			},
		],
	};

	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			showMoreInfo: false,
			childList: 'default',
			playEffect: false,
			counter: 0,
		};
	}
	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}
	showInfo = (boxId) => {
		this.setState({
			showMoreInfo: true,
			childList: boxId,
		});
	};
	hideInfo = () => {
		this.setState({
			showMoreInfo: false,
		});
	};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const image = data.image.imageSrc;
		const credit = data.image.credit;
		const showNumbers = data.showNumbers;
		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ShowHotSpots']);
		const colorLayer = get(styles, 'colorLayer');

		const classes = classNames({
			'brick': true,
			'show-hot-spots': true,
		});

		let allSpots = data.hotSpots.map((item, index) => {
			return (
				<div className="hot-spot-container" key={index}>
					<HotSpotModal
						data={item}
						index={index}
						onClick={() => {
							this.showInfo(item._id);
						}}
						showNumbers={showNumbers}
						playAnimation={this.state.playEffect}
						delay={(index + 1) * 0.5}
						effect={brickAnimation}
					/>
				</div>
			);
		});

		return (
			<Container
				size="extra-large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					{allSpots}
					<img className="show-hot-spots__image-main" src={imageUrl(image)} alt={credit} />
					<ImgOverlay colorLayer={colorLayer} />
					<span className="show-hot-spots__credit">{credit}</span>
				</div>
				<LayerMoreInfo
					show={this.state.showMoreInfo}
					onClose={this.hideInfo}
					mainProps={this.props}
					childList={this.state.childList.toString()}
					editMode={this.props.editMode}
				/>
			</Container>
		);
	}
}

export default brickWrapper(ShowHotSpotsPopUp);
