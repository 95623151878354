import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import {slugify} from '@createandshare/tools';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import './styles.scss';

/****
Los valores de cada respuesta deben de ser guardado siempre con esta estructura 

			response: {
				[autoID]{
					value
				}
			}
	
**** */

class AssessmentQuestion extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			response: {},
		};
	}

	canContinue = () => {
		if (!get(this.props, 'flexibleContent.required', false) || !isEmpty(this.state.response)) {
			return true;
		}
		return false;
	};

	parseTypeNumericInt = (string) => {
		if (isNaN(string) || string === '') return undefined;
		return parseInt(string, 10);
	};
	getPointsScale = () => {
		const min = this.parseTypeNumericInt(this.props.flexibleContent.min);
		const max = this.parseTypeNumericInt(this.props.flexibleContent.max);
		const scale = 1;

		const multiplesScale = [];
		if (scale === undefined || scale === 0) {
			return undefined;
		}
		for (let i = min; i <= max; i++) {
			if (i % scale === 0) {
				multiplesScale.push(i);
			}
		}
		return multiplesScale;
	};

	handleChange = (value) => {
		const name = this.props.flexibleContent._id;
		this.setState(
			{
				response: {
					[name]: {
						value,
					},
				},
			},
			this.sendDataQuestion,
		);
	};

	sendDataQuestion = () => {
		const dataState = Object.assign({}, this.state.response);
		const keyData = this.props.flexibleContent._id;
		const type = this.props.flexibleContent.flexibleContentType;
		const name = slugify(this.props.flexibleContent.name);
		if (dataState[keyData]) {
			Object.assign(dataState[keyData], {type}, {name});
			this.props.onFinishQuestion(dataState);
		}
	};

	render() {
		const mainStyleClass = 'assessment-question';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
		});
		let {
			// flexibleContent: {_id, statement, min, max},
			flexibleContent: {_id, statement, minLabel, maxLabel},
		} = this.props;

		// min = this.parseTypeNumericInt(min) || 0;
		// max = this.parseTypeNumericInt(max) || 10;

		const pointScale = this.getPointsScale();
		const value = get(this.state.response, [_id.toString(), 'value']);

		return (
			<div className={classes}>
				<div className="row">
					<div className="col-xs-12 col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
						<div className={mainStyleClass + '__statement swiper-no-swiping'}>
							<FrontEditableText
								text={statement}
								fieldName="statement"
								set={(fieldPath, fieldData) => {
									//Propagamos la llamada al método set añadiendo mi nodo al path
									if (this.props.set)
										this.props.set(
											['flexibleContent', this.props.index].concat(fieldPath),
											fieldData,
										);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12 col-sm-12 col-sm-offset-0 col-xl-8 col-xl-offset-2">
						<div className={mainStyleClass + '__slider swiper-no-swiping'}>
							{/* Fila con un botón circular por cada número entre min y max
									Al hacer click en uno, se llama a handleChange con el valor correspondiente
									El elemento seleccionado queda marcado */}
							<div className={mainStyleClass + '__slider__row'}>
								{pointScale.map((point, index) => {
									const isFirstItem = index === 0;
									const isLastItem = index === pointScale.length - 1;
									const selected = value === point;
									const classes = classNames({
										[`${mainStyleClass}__slider__row__item`]: true,
										[`${mainStyleClass}__slider__row__item--selected`]: selected,
									});
									return (
										<div key={point} className={`${mainStyleClass}__slider__row__item__container`}>
											<button
												key={point}
												className={classes}
												onClick={() => this.handleChange(point)}
											>
												{point}
											</button>
											{isFirstItem && (
												<div
													className={`${mainStyleClass}__slider__row__item__label ${mainStyleClass}__slider__row__item__label--left`}
												>
													{minLabel}
												</div>
											)}
											{isLastItem && (
												<div
													className={`${mainStyleClass}__slider__row__item__label ${mainStyleClass}__slider__row__item__label--right`}
												>
													{maxLabel}
												</div>
											)}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
				{/* {showLabelRow && (
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-sm-offset-0 col-xl-8 col-xl-offset-2">
							<div className={mainStyleClass + '__labels__row'}>
								<div className={mainStyleClass + '__labels__row__item'}>{minLabel}</div>

								<div className={mainStyleClass + '__labels__row__item'}>{maxLabel}</div>
							</div>
						</div>
					</div>
				)} */}
			</div>
		);
	}
}

AssessmentQuestion.propTypes = {
	flexibleContent: PropTypes.object,
	onFinishQuestion: PropTypes.func,
	set: PropTypes.func,
	index: PropTypes.number,
};

AssessmentQuestion.defaultProps = {
	flexibleContent: {
		min: 0,
		max: 100,
		scale: 10,
	},
};

export default AssessmentQuestion;
