import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Icon from 'ui/shared/Icon';
import brickImage from './img/brickPreview.jpg';
import brickImage2 from './img/brickPreview2.jpg';
import editionFields from './edit.js';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class ShowInfoDidYouKnow extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`¿Sabías qué?`;
	static brickIcon = 'image';
	static brickHelpText = () =>
		t`Permite ocultar una parte del texto que el usuario descubrirá al hacer clic sobre una flecha.`;
	static brickImage = brickImage;
	static brickImage2 = brickImage2;
	static brickDefaultData = {
		subtitleLess: t`¿SABÍAS QUÉ?`,
		titleLess: 'Lorem ipsum dolor sit amet, consectur adipiscing elit.',

		imageObjLess: {
			imageSrc: '/uploads/default-images/csDefault2Opt.svg',
			credit: '',
		},
		textLess:
			'Quis eost, ofcim sinverum vellant quam corrumque il moluptium ariscimod eveducid.Cim eum quasper oribusae vendae nos aut faciistem sitint.Olut aut ut que et la simustium voluptatur alitatia dis re endit illis doluptati simet inveniam, sit reptat manstrea sastrera manutre',
		imageObjMore: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
		},
		textMore:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pharetra enim lacus, ac interdum tortor venenatis et. Quisque posuere ipsum aliquet tortor dignissim sagittis. Morbi pulvinar, arcu sit amet porttitor commodo, tellus mauris ',
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};
	constructor(props) {
		super(props);
		this.state = {
			showMoreInfo: false,
			fixedWidth: 0,
			playEffect: false,
			counter: 0,
		};
	}
	showInfo = () => {
		this.setWidthContent();
		if (this.state.showMoreInfo === false) {
			this.setState({showMoreInfo: true});
		} else {
			this.setState({showMoreInfo: false});
		}
	};
	setWidthContent = () => {
		let siblingReference = this.refs.siblingReference;
		let currentlyWidth = siblingReference.clientWidth;
		//la primera vez que se renderiza si el ancho es 0 el texto crea una capa de mucho alto y se descuadra
		if (currentlyWidth === 0) currentlyWidth = 670;
		this.setState({fixedWidth: currentlyWidth});
	};
	componentDidMount() {
		this.setWidthContent();
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const imageLess = data.imageObjLess || {};
		const imageMore = data.imageObjMore || {};

		const pillAnimation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const brickAnimation = get(pillAnimation, ['data', 'ListSmallNumbers']);

		const classes = classNames({
			'brick': true,
			'show-info-didYouKnow-brick': true,
		});

		return (
			<Container
				size="extra-large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<Animation playAnimation={this.state.playEffect} delay={0.5} effect={brickAnimation}>
						<div className="row">
							<div className="col-xs-12 col-lg-6 col--less">
								<div className={'show-info-didYouKnow-brick__less'} ref="siblingReference">
									<div className={'col col-xs-10 col-xs-offset-1'}>
										<div className="show-info-didYouKnow-brick__less__subtitle">
											<FrontEditableText
												fieldName="subtitleLess"
												text={data.subtitleLess}
												set={this.props.set}
											/>
										</div>
									</div>
									<div className={'col col-xs-10 col-xs-offset-1'}>
										<div className="show-info-didYouKnow-brick__less__title">
											<FrontEditableText
												fieldName="titleLess"
												text={data.titleLess}
												set={this.props.set}
											/>
										</div>
									</div>
									<div className={'col col-xs-10 col-xs-10 col-xs-offset-1'}>
										<div className="show-info-didYouKnow-brick__less__text">
											<FrontEditableText
												fieldName="textLess"
												text={data.textLess}
												set={this.props.set}
											/>
										</div>
									</div>
									{imageLess.imageSrc !== '' && (
										<div className={'col col-xs-6 col-xs-offset-3'}>
											<div className="show-info-didYouKnow-brick__less__img">
												<NewImageClickable imgObj={imageLess} defaultVisible={true} />
											</div>
										</div>
									)}
								</div>
							</div>
							<div
								className={
									'col-xs-12 col-lg-6 col--more ' +
									(this.state.showMoreInfo === true ? ' show ' : '')
								}
							>
								<div
									className={
										'show-info-didYouKnow-brick__more' +
										(this.state.showMoreInfo === true ? ' show ' : '')
									}
									style={{width: this.state.fixedWidth + 'px'}}
								>
									<div className="col col-xs-10 col-xs-offset-1">
										<div className={'show-info-didYouKnow-brick__more__text'}>
											<FrontEditableText
												fieldName="textMore"
												text={data.textMore}
												set={this.props.set}
											/>
										</div>
									</div>
									{imageMore.imageSrc !== '' && (
										<div className="col-xs-12 col-lg-10 col-lg-offset-1">
											<div className={'show-info-didYouKnow-brick__more__media'}>
												<NewImageClickable imgObj={imageMore} defaultVisible={true} />
											</div>
										</div>
									)}
								</div>
							</div>
							<div className="trigger-show" onClick={this.showInfo} role="button">
								<div className="trigger-show__bar" />
								<div className="trigger-show__wrapper-icon">
									<Icon
										name="arrow-menu-open"
										size="small"
										className={this.state.showMoreInfo === true ? 'rotate' : ''}
									/>
								</div>
							</div>
						</div>
					</Animation>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ShowInfoDidYouKnow);
