import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import PlayButtonAnimation from 'businessLogic/core/shared/Buttons/PlayButtonAnimation';
import get from 'lodash/get';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';

import './styles.scss';

//Variable global para gestionar si el audio está desbloqueado en iOs
let isAudioUnlocked = false;

class PlayScroll extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Play scroll`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickHelpText = () => (
		<p>
			{t`Permite dar movimiento a una píldora con un scroll (vertical) automático.`}
			<br />
			<br />
			{t`Este ladrillo sirve para crear Storytelling. ¿Quieres ver un ejemplo? Pincha `}
			<a href="https://story-telling.cs01.cloud/" target="_blank" rel="noreferrer noopener">
				<i>{t`aquí`}</i>
			</a>
		</p>
	);
	static brickDefaultData = {
		animation: true,
		scrollSpeed: 5,
	};
	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 0,
			paddingBottom: 0,
		},
	};

	constructor(props) {
		super(props);
		this.brickRef = React.createRef();
		this.state = {
			play: false,
			show: true,
		};
	}

	handlePlay = () => {
		//Desbloqueamos los audios autoplay en ios haciendo un muteOn y muteOff ante esta solicitud del usuario
		if (!isAudioUnlocked) {
			this.props.configObject.callEventListeners('muteOn');
			this.props.configObject.callEventListeners('muteOff');
			isAudioUnlocked = true;
		}
		this.setState({play: true, show: false});
		this.props.configObject.startScrollAnimation();
		this.props.brickEvent('scrollPlay');
	};

	stopPlay = () => {
		this.setState({play: false, show: true});
		this.props.configObject.stopScrollAnimation();
		this.props.brickEvent('scrollPause');
	};

	onScrollStop = () => {
		this.setState({play: false, show: true});
	};

	componentDidMount() {
		this.props.configObject.addEventListener('stop', this.onScrollStop);

		//control ladrillos antiguos sin velocidad en el playScroll
		const scrollSpeed = get(this.props, 'brick.data.scrollSpeed', 5);
		//creamos el objeto para el array de configuración de la píldora
		const configSpeedObjetc = {
			scrollSpeed: parseInt(scrollSpeed, 10),
		};
		//registramos la configuración
		this.props.configObject.registerConfig(configSpeedObjetc, this.brickRef.current);
	}

	componentWillUnmount() {
		this.props.configObject.removeEventListener('stop', this.onScrollStop);
		//cuando el componente se va a desmontar limpiamos esta configuración (velocidad de scroll)
		this.props.configObject.unregisterConfig(this.brickRef.current);
	}

	render() {
		const data = this.props.brick.data || {};
		const styles = this.props.brick.styles || {};

		const classes = classNames({
			'brick': true,
			'play-scroll-brick': true,
		});

		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				{/* <div className={classes} ref="play"> */}
				<div className={classes} ref={this.brickRef}>
					<PlayButtonAnimation
						handlePlayPause={this.handlePlay}
						show={this.state.show}
						playState={false}
						sticky={false}
						counter={0}
						editMode={this.props.editMode}
						animation={data.animation}
					/>
					{this.state.play && (
						<div className="play-scroll-brick__overlay-stop" onClick={this.stopPlay} />
					)}
				</div>
			</Container>
		);
	}
}

export default brickWrapper(PlayScroll);
