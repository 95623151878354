import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import DraggableTagImage from 'businessLogic/core/shared/Image/DraggableTagImage';
import './styles.scss';

class Category extends Component {
	render() {
		const mainStyleClass = 'classify-game-image-test__category';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
			[`${mainStyleClass + '--drop-success'}`]:
				this.props.feedback !== undefined && this.props.feedback,
			[`${mainStyleClass + '--drop-failure'}`]:
				this.props.feedback !== undefined && !this.props.feedback
		});
		return (
			<li className={classes}>
				<p className={mainStyleClass + '__name'}>{this.props.title}</p>
				{this.props.tagsLabelSolution && (
					<div className={mainStyleClass + '__area-drop'}>
						{this.props.tagsLabelSolution.map((itemTag, indexTag) => {
							return (
								<DraggableTagImage
									key={indexTag}
									text={false}
									active={false}
									image={itemTag.tagNameImageObj}
									clickable={true}
								/>
							);
						})}
						{this.props.feedback !== undefined && (
							<div className={mainStyleClass + '__feedback'}>
								<Icon name={this.props.feedback ? 'success' : 'failure'} />
							</div>
						)}
					</div>
				)}
			</li>
		);
	}
}

Category.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	feedback: PropTypes.bool,
	tagsLabelSolution: PropTypes.array
};

export default Category;
