import {client} from 'businessLogic/store/store';
import {
	PILL_DELETE_MUTATION,
	PILLS_QUERY,
	CREATE_PILL_MUTATION,
	ADD_NEW_CHILD_BRICK_MUTATION,
	UPDATE_BRICK_MUTATION,
	DELETE_BRICK_MUTATION,
	CREATE_BRICK_MUTATION,
	UPDATE_PILL_MUTATION,
} from './query';
import {initialState} from '../actions';
import Logger from 'businessLogic/services/Logger';

export function mutationDeletePill(pillId) {
	client
		.mutate({
			variables: {
				idPill: pillId,
			},
			mutation: PILL_DELETE_MUTATION,
			update: (proxy, {data: {deletePill}}) => {
				if (deletePill.error) {
					//se tendrá que hacer el redirect a login y la recarga de la página
					alert(`Error, ${deletePill.error}`);
					// history.go("/login");
				} else {
					// Leemos los datos que hay en cache asociados a la query PILLS_BY_OWNER_QUERY
					const data = proxy.readQuery({query: PILLS_QUERY});
					// Borramos el id de la píldora correspondiente de todos los datos de píldoras
					data.pills = [].concat(data.pills.filter((pill) => pill.id !== deletePill.id));
					// Escribimos de nuevo la query a cache.
					proxy.writeQuery({query: PILLS_QUERY, data});
				}
			},
		})
		.catch((error) => {
			Logger.captureException(error, (scope) => {
				scope.setContext('pill', {pillId});
				return scope;
			});
			alert(
				'No se ha borrado correctamente, se ha producido algún problema, actualice de nuevo la página cargandola de nuevo y vuelva a intentarlo.',
			);
		});
}

export function mutationCreatePill(input) {
	client
		.mutate({
			variables: {
				input,
			},
			mutation: CREATE_PILL_MUTATION,
			update: (proxy, {data: {createPill}}) => {
				if (createPill.error) {
					//se tendrá que hacer el redirect a login y la recarga de la página
					alert(`Error, ${createPill.error}`);
					// history.go("/login");
				} else {
					// Leemos los datos que hay en cache asociados a la query PILLS_BY_OWNER_QUERY
					const data = proxy.readQuery({
						query: PILLS_QUERY,
					});
					// Añadimos la píldora nueva a los datos de píldoras
					data.pills.push(createPill);
					// Seteamos los datos a nuestro store
					initialState(data.pills);
					// Escribimos de nuevo la query a cache.
					proxy.writeQuery({
						query: PILLS_QUERY,
						data,
					});
				}
			},
		})
		.catch((error) => {
			Logger.captureException(error, (scope) => {
				scope.setContext('pill', {input});
				return scope;
			});
			alert(
				'No se ha creado correctamente, se ha producido algún problema, actualice de nuevo la página cargandola de nuevo y vuelva a intentarlo.',
			);
		});
}

export function mutationUpdatePill(pillId, input) {
	client
		.mutate({
			variables: {
				idPill: pillId,
				input,
			},
			mutation: UPDATE_PILL_MUTATION,
		})
		.catch((error) => {
			Logger.captureException(error, (scope) => {
				scope.setContext('pill', {pillId, input});
				return scope;
			});
			alert(
				'La píldora no se ha actualizado correctamente, se ha producido algún problema, actualice de nuevo la página cargandola de nuevo y vuelva a intentarlo.',
			);
		});
}

export function mutationAddNewChildBrick(
	pillId,
	brickId,
	newBrickId,
	brickType,
	index,
	brickConfig = {},
	brickData = {},
	brickStyles = {},
	brickChildren = [],
	childList = 'default',
) {
	return client
		.mutate({
			variables: {
				pillId,
				brickId,
				newBrickId,
				brickType,
				index,
				brickConfig,
				brickData,
				brickStyles,
				brickChildren,
				childList,
			},
			mutation: ADD_NEW_CHILD_BRICK_MUTATION,
			update: (proxy, {data}) => {
				if (data.addNewChildBrick.error) {
					//se tendrá que hacer el redirect a login y la recarga de la página
					alert(`Error, ${data.addNewChildBrick.error}`);
					// history.go("/login");
				}
			},
		})
		.catch((error) => {
			Logger.captureException(error, (scope) => {
				scope.setContext('pill', {pillId, brickId});
				return scope;
			});
			alert(
				'El ladrillo no se ha creado correctamente, se ha producido algún problema, actualice de nuevo la página cargandola de nuevo y vuelva a intentarlo.',
			);
		});
}

export function mutationCreateBrick(
	pillId,
	brickId,
	brickType,
	brickConfig = {},
	brickData = {},
	brickStyles = {},
	brickChildren = [],
) {
	return client
		.mutate({
			variables: {
				pillId,
				brickId,
				brickType,
				brickConfig,
				brickData,
				brickStyles,
				brickChildren,
			},
			mutation: CREATE_BRICK_MUTATION,
			update: (proxy, {data}) => {
				if (data.createBrick.error) {
					//se tendrá que hacer el redirect a login y la recarga de la página
					alert(`Error, ${data.createBrick.error}`);
					// history.go("/login");
				}
			},
		})
		.catch((error) => {
			Logger.captureException(error, (scope) => {
				scope.setContext('pill', {pillId, brickId});
				return scope;
			});
			alert(
				'No se ha creado correctamente el ladrillo, se ha producido algún problema, actualice de nuevo la página cargandola de nuevo y vuelva a intentarlo.',
			);
		});
}

export async function mutationUpdateBrick(pillId, brickId, brick) {
	//Filtramos brick con los valores permitidos por el esquema
	const allowedKeys = ['id', 'type', 'children', 'data', 'config', 'styles'];
	const filteredBrick = {};
	Object.keys(brick).forEach((key) => {
		if (allowedKeys.indexOf(key) >= 0) {
			filteredBrick[key] = brick[key];
		}
	});
	try {
		await client.mutate({
			variables: {
				pillId,
				brickId,
				brick: filteredBrick,
			},
			mutation: UPDATE_BRICK_MUTATION,
			update: (proxy, {data}) => {
				if (data.updateBrick.error) {
					//se tendrá que hacer el redirect a login y la recarga de la página
					alert(`Error, ${data.updateBrick.error}`);
					// history.go("/login");
				}
			},
		});
	} catch (error) {
		Logger.captureException(error, (scope) => {
			scope.setContext('pill', {pillId, brickId});
			return scope;
		});
		alert(
			'No se ha actualizado correctamente el ladrillo, se ha producido algún problema, actualice de nuevo la página cargandola de nuevo y vuelva a intentarlo.',
		);
	}
}

export function mutationDeleteBrick(pillId, brickId) {
	client
		.mutate({
			variables: {
				pillId,
				brickId,
			},
			mutation: DELETE_BRICK_MUTATION,
			update: (proxy, {data}) => {
				if (data.deleteBrick.error) {
					//se tendrá que hacer el redirect a login y la recarga de la página
					alert(`Error, ${data.deleteBrick.error}`);
					// history.go("/login");
				}
			},
		})
		.catch((error) => {
			Logger.captureException(error, (scope) => {
				scope.setContext('pill', {pillId, brickId});
				return scope;
			});
			alert(
				'No se ha borrado correctamente el ladrillo, se ha producido algún problema, actualice de nuevo la página cargandola de nuevo y vuelva a intentarlo.',
			);
		});
}
