import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import ImageComp from 'businessLogic/core/shared/Image/Image';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import Animation from 'businessLogic/core/shared/Animation';
import get from 'lodash/get';
import './styles.scss';

class ImageFullWidth extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Imagen ancho completo`;
	static brickIcon = 'image';
	static brickImage = brickImage;
	static brickDefaultData = {
		image: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
		},
	};

	static brickDefaultStyles = {
		containerStyle: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	};
	constructor(props) {
		super(props);
		this.state = {
			playEffect: false,
			counter: 0,
		};
	}

	handleVisibilityChange(isVisible) {
		let counter = this.state.counter;
		if (isVisible === true && this.state.counter === 0) {
			this.setState({playEffect: true, counter: ++counter});
		}
	}

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const imageObj = data.image;
		const animation = get(get(this.props, 'configObject').returnCurrentConfig(), 'animation');
		const classes = classNames({
			'brick': true,
			'image-brick': true,
			'image-brick-full-width': true,
		});

		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<Animation playAnimation={this.state.playEffect} effect={get(animation, ['data', `Image`])}>
					<div className={classes}>
						<ImageComp imgObj={imageObj} clickable={false} />
						{imageObj.credit && (
							<div className="image-brick-full-width__caption">
								{imageObj.credit && (
									<div className="image-brick-full-width__caption__credit"> {imageObj.credit} </div>
								)}
							</div>
						)}
					</div>
				</Animation>
			</Container>
		);
	}
}

export default brickWrapper(ImageFullWidth);
