import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import WindowSizeContext from 'businessLogic/contexts/WindowSizeContext';
import './styles.scss';

const stackBlurImage = require('businessLogic/helpers/StackBlur.js');

class BlurImage extends Component {
	constructor(props) {
		super(props);
		this.canvasBg = React.createRef();
	}
	componentDidMount() {
		this.ctxBg = this.canvasBg.current.getContext('2d');
		this.updateDrawImage(this.props.timer);
	}
	componentDidUpdate(prevProps) {
		if (this.props.imageSrc !== prevProps.imageSrc) {
			this.updateDrawImage(this.props.timer);
		}
	}
	shouldComponentUpdate(nextProps) {
		if (this.props.timer !== nextProps.timer) {
			this.updateDrawImage(nextProps.timer);
		}
		return false;
	}
	/* el canvas se repinta hasta 60fps gracias a window.requestAnimationFrame, que recibe como parámetro de callback esta función  */
	updateDrawImage = (timer) => {
		if (this.canvasBg.current && this.canvasBg.current.width) {
			this.drawPicture(
				this.ctxBg,
				this.canvasBg.current.width,
				this.canvasBg.current.height,
				timer,
			);
			this.ctxBg.save();
		}
	};

	/*======================================
	Funciones encargadas de dibujar elementos 
	========================================*/
	drawPicture = (ctx, width, height, timer) => {
		let imgObj = new Image();
		imgObj.src = this.props.imageSrc;
		imgObj.onload = () => {
			if (!!imgObj && !!this.canvasBg.current) {
				stackBlurImage(
					imgObj,
					this.canvasBg.current,
					timer * 0.1 === 0 ? 100 * 0.5 : (100 - timer) * 0.5,
					width,
					height,
					ctx,
				);
			}
			this.ctxBg.save();
		};
	};

	/*Cada vez que hay un cambio en canvas, no se borra lo anterior, se superpone a lo que había antes. Por eso es necesario borrar el frame previo durante una animación */
	clean = (canvas) => {
		/*Limpia todo el canvas volviéndolo a redimensionar */
		const cleanCanvas = canvas.current.width;
		canvas.current.width = cleanCanvas;
	};

	/*Helper para ajustar una imagen a un lienzo efecto Contain*/
	fitImageOn = (canvas, imageObj, ctx) => {
		let imageAspectRatio = imageObj.width / imageObj.height,
			canvasAspectRatio = canvas.width / canvas.height,
			sHeight,
			sWidth,
			sX,
			sY;

		// If image's aspect ratio is less than canvas's we fit on height
		// and place the image centrally along width
		if (imageAspectRatio < canvasAspectRatio) {
			sHeight = canvas.height;
			sWidth = imageObj.width * (sHeight / imageObj.height);
			sX = (canvas.width - sWidth) / 2;
			sY = 0;
		}

		// If image's aspect ratio is greater than canvas's we fit on width
		// and place the image centrally along height
		else if (imageAspectRatio > canvasAspectRatio) {
			sWidth = canvas.width;
			sHeight = imageObj.height * (sWidth / imageObj.width);
			sX = 0;
			sY = (canvas.height - sHeight) / 2;
		}

		// Happy path - keep aspect ratio
		else {
			sHeight = canvas.height;
			sWidth = canvas.width;
			sX = 0;
			sY = 0;
		}
		ctx.drawImage(imageObj, sX, sY, sWidth, sHeight);
	};

	render() {
		const mainStyleClass = 'blur-image-canvas__bg';
		const classes = classNames({
			[mainStyleClass]: true,
		});
		return (
			<WindowSizeContext.Consumer>
				{({width, device}) => {
					if (
						device === 'mobile' ||
						device === 'mobileLandscape' ||
						device === 'mobileLandscape' ||
						device === 'tabletPortrait'
					) {
						return (
							<div className={classes}>
								<canvas
									ref={this.canvasBg}
									width={width - 34}
									height={(width - 34) * 0.6978 + 40}
									className={mainStyleClass + '__canvas'}
								/>
							</div>
						);
					} else if (device === 'tabletLandscape') {
						return (
							<div className={classes}>
								<canvas
									ref={this.canvasBg}
									width={860 * 0.5}
									height={860 * 0.5 * 0.6978}
									className={mainStyleClass + '__canvas'}
								/>
							</div>
						);
					} else if (device === 'desktop') {
						return (
							<div className={classes}>
								<canvas
									ref={this.canvasBg}
									width={width * 0.8 * 0.5}
									height={width * 0.8 * 0.5 * 0.6978}
									className={mainStyleClass + '__canvas'}
								/>
							</div>
						);
					} else {
						return (
							<div className={classes}>
								<canvas
									ref={this.canvasBg}
									width={1489 * 0.8 * 0.5}
									height={1489 * 0.8 * 0.5 * 0.6978}
									className={mainStyleClass + '__canvas'}
								/>
							</div>
						);
					}
				}}
			</WindowSizeContext.Consumer>
		);
	}
}

BlurImage.propType = {
	imageSrc: PropTypes.string,
	timer: PropTypes.number,
};

export default BlurImage;
