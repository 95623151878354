import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import './styles.scss';

class SpacerHalfScreen extends PureComponent {
	static brickName = () => t`Espaciado media pantalla`;
	static brickImage = brickImage;
	static disableEdit = true;

	render() {
		const styles = this.props.brick.styles || {};

		const classes = classNames({
			'brick': true,
			'spacer-half-screen': true,
		});
		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					{this.props.editMode && (
						<div className="spacer-half-screen__edit">
							<span className="spacer-half-screen__edit__text">{t`Espaciado media pantalla`}</span>
							<span className="spacer-half-screen__edit__advice">
								{t`(Texto y fondo solo visible en modo edición)`}
							</span>
						</div>
					)}
				</div>
			</Container>
		);
	}
}

export default brickWrapper(SpacerHalfScreen);
