import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Text',
						fieldName: 'button',
						title: t`Texto del botón de descarga`,
					},
					{
						type: 'Text',
						fieldName: 'downloadUrl',
						title: t`Url del recurso descargable`,
					},
					{
						type: 'Switch',
						fieldName: 'openInsteadOfDownload',
						title: t`Abrir el recurso en lugar de descargarlo`,
					},
					{
						type: 'Switch',
						fieldName: 'openInCurrentTab',
						title: t`Abrir en la pestaña actual`,
					},
				],
			},
		],
	},
];
export default editionFields;
