import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';
import {imageUrl, getDeviceWidth} from 'businessLogic/helpers/images';

class NewImageCmp extends PureComponent {
	render() {
		const {className, image} = this.props;
		const classes = classNames({
			'new-image-cmp': true,
			[`${className}`]: true,
		});
		const divStyle = {};
		divStyle.backgroundImage =
			'url("' +
			imageUrl(image.imageSrc, getDeviceWidth(this.props.device, this.props.imgWidths)) +
			'")';

		if (image && image.centerPosition && image.centerPosition.x > 0 && image.centerPosition.y > 0) {
			divStyle.backgroundPosition = image.centerPosition.x + '% ' + image.centerPosition.y + '%';
		}
		return (
			<div className={classes}>
				<div className="new-image-cmp__image" style={divStyle} />
			</div>
		);
	}
}

NewImageCmp.propTypes = {
	className: PropTypes.string,
	image: PropTypes.object,
	device: PropTypes.string,
};

NewImageCmp.defaultProps = {
	className: '',
	image: {},
	device: 'desktop',
};

export default NewImageCmp;
