import ScormCourseStateManager from './ScormCourseStateManager';
import CsCourseStateManager from './CsCourseStateManager';

//Exportamos una instancia, de manera que sea única para todo el proyecto
// implementando el modelo singleton en javascript

let courseStateManagerInstance;
if (process.env.REACT_APP_EVENTS_API === 'scorm') {
	courseStateManagerInstance = new ScormCourseStateManager();
} else {
	courseStateManagerInstance = new CsCourseStateManager();
}

// Object.freeze(courseStateManagerInstance);
const exportCourseStateManagerInstance = courseStateManagerInstance;

export default exportCourseStateManagerInstance;
