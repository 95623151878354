import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VisibleImageContext from 'businessLogic/contexts/VisibleImageContext';
import AddBrickButton from 'businessLogic/core/shared/Buttons/AddBrickButton';
import get from 'lodash/get';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class BricksContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.brickRef = React.createRef();
	}
	addBrick = () => {
		this.props.mainProps.addBrick(this.props.mainProps.brickId, 0, this.props.childList);
	};

	render() {
		const {mainProps, childList, editMode} = this.props;
		const classes = classNames({
			'bricks-container': true,
			'theme-light': true,
			'edit-mode': editMode,
		});

		const noBricks = get(mainProps, `brick.children.${childList}`, []).length === 0;
		const intro = {
			title: t`Empieza a crear`,
			text: t`Sitúa el ratón sobre este texto y haz click en el icono + que aparece debajo para añadir un ladrillo dentro de este bloque`,
		};

		const layer = (
			<div className={classes}>
				<VisibleImageContext.Provider value={{visibleImages: true}}>
					<div className="bricks-container__brick-wrapper">
						{mainProps.children}
						<div className="main-container-rows-brick" ref={this.brickRef}>
							{mainProps.editMode && (
								<div className="first-brick">
									{noBricks && mainProps.editMode ? (
										<div className="main-container-rows-brick__help-text">
											{/* <h2>{intro.title}</h2> */}
											<p>{intro.text}</p>
										</div>
									) : (
										''
									)}
									<div className="brick-add-button">
										<AddBrickButton ref="addBrickButton" addBrick={this.addBrick} />
									</div>
								</div>
							)}
							{mainProps.renderChildren(childList, {
								includeAddBrickButton: true,
								includeEditButtons: true,
							})}
						</div>
					</div>
				</VisibleImageContext.Provider>
			</div>
		);

		return layer;
	}
}
BricksContainer.propTypes = {
	mainProps: PropTypes.object,
	childList: PropTypes.string,
};

BricksContainer.defaultProps = {
	show: false,
	childList: 'default',
};
export default BricksContainer;
