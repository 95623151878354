import React from 'react';
import {getRgbaString} from 'businessLogic/helpers/functions';

const getColor = (colorObject) => {
	if (!colorObject) {
		return undefined;
	}

	if (typeof colorObject === 'string') {
		return colorObject;
	} else if (typeof colorObject === 'object') {
		const defaultColor = getRgbaString(colorObject?.rgb) || colorObject?.hex;
		if (colorObject?.cssVar) {
			return `var(${colorObject.cssVar}, ${defaultColor})`;
		} else {
			return defaultColor;
		}
	}
};

const Leaf = ({attributes, children, leaf}) => {
	const styles = {};
	let leafChildren = children;

	if (leaf.bold) {
		// styles.fontWeight = 'bold';
		leafChildren = <strong>{leafChildren}</strong>;
	}

	if (leaf.italic) {
		// styles.fontStyle = 'italic';
		leafChildren = <em>{leafChildren}</em>;
	}

	if (leaf.underlined) {
		// styles.textDecoration = 'underline';
		leafChildren = <u>{leafChildren}</u>;
	}

	if (leaf.strikethrough) {
		// styles.textDecoration = 'line-through';
		leafChildren = <s>{leafChildren}</s>;
	}

	if (leaf.code) {
		// styles.fontFamily = 'monospace';
		leafChildren = <code>{leafChildren}</code>;
	}

	if (leaf.highlightColor) {
		styles.backgroundColor = getColor(leaf.highlightColor);
	}

	if (leaf.color) {
		styles.color = getColor(leaf.color);
	}

	if (leaf.fontSize) {
		styles.fontSize = leaf.fontSize;
	}

	if (leaf.emFontSize && leaf.emFontSize !== 1) {
		styles.fontSize = `${leaf.emFontSize}em`;
	}

	if (leaf.fontFamily) {
		styles.fontFamily = leaf.fontFamily;
	}

	if (leaf.subscript) {
		// styles.verticalAlign = 'sub';
		leafChildren = <sub>{leafChildren}</sub>;
	}

	if (leaf.superscript) {
		// styles.verticalAlign = 'super';
		leafChildren = <sup>{leafChildren}</sup>;
	}

	// console.log('leaf: ', leaf);
	// console.log('attributes: ', attributes);
	// console.log('children: ', children);
	return (
		<span {...attributes} style={styles}>
			{leafChildren}
		</span>
	);
};

export default Leaf;
