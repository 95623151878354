import Logger from 'businessLogic/services/Logger';
import get from 'lodash/get';
import {client} from 'businessLogic/store/store';
import {ENTITY_QUERY} from 'businessLogic/store/data/entity/queries';

import userPerms from '@createandshare/userperms';
import productDefinition from '@createandshare/productdefinition';
import {addLocale} from 'ttag';
import {setAdminCurrentLang} from 'businessLogic/scope/admin/helper/adminTtag';
import {setUserCurrentLang} from 'businessLogic/scope/user/helper/userTtag';
import {setDefaultLocale} from 'businessLogic/helpers/date';

const loadedLocales = [];

const downloadLangJson = async (lang) => {
	try {
		if (loadedLocales.includes(lang)) {
			return;
		}

		const translationObjResponse = await fetch(`/i18n/${lang}.po.json`);
		if (translationObjResponse) {
			const translationObj = await translationObjResponse.json();

			addLocale(lang, translationObj);
			loadedLocales.push(lang);
		}
	} catch (err) {
		Logger.captureException(err);
	}
};

const EntityManager = function () {
	let entity = {};
	this.initialize = async () => {
		// Obtenemos la entidad actual
		const preview = window.location.search.includes('preview=true');
		const queryResponse = await client.query({query: ENTITY_QUERY, variables: {preview}});
		entity = get(queryResponse, 'data.currentEntity');

		const productData = this.getProductData();
		userPerms.setPermsObj(productData);
		productDefinition.setDefinitionObj(productData);

		const langs = this.getLangs();

		await this.configureLangs(langs);

		const preloadLangs = langs.preloadLangs || [];

		for (const lang of preloadLangs) {
			await downloadLangJson(lang);
		}

		Logger.setTag('entity', entity?.slug);
		Logger.setTag('entityType', entity?.subtype);

		return entity;
	};

	this.configureLang = async (lang) => {
		return this.configureLangs({adminLang: lang, userLang: lang});
	};

	this.configureLangs = async ({adminLang, userLang}) => {
		if (adminLang !== 'es_ES') {
			await downloadLangJson(adminLang);
		}

		if (userLang !== 'es_ES' && userLang !== adminLang) {
			await downloadLangJson(userLang);
		}

		setAdminCurrentLang(adminLang);
		setUserCurrentLang(userLang);

		await setDefaultLocale(userLang, adminLang);

		Logger.setTag('adminLang', adminLang);
		Logger.setTag('userLang', userLang);
	};

	this.setEntity = (entityObj) => (entity = entityObj);
	this.getEntity = () => entity;

	this.getPlatformName = () => get(entity, 'name');

	this.defaultStyles = {
		highlightColor: '#73E5D1',
		interactionColor: '#E97B5C',
		menuColor: {r: 16, g: 29, b: 54, a: 0.9}, // #101D36
		color1: '#687EDB',
		color2: '#FEDC82',
		color3: '#FA5F85',
		color4: '#33CCFF',
		font1: 'Montserrat',
		font2: 'Ubuntu',
	};

	this.getCustomStyles = () => {
		const {
			highlightColor,
			interactionColor,
			menuColor,
			font1,
			font2,
			color1,
			color2,
			color3,
			color4,
		} = get(entity, 'data.customStyles', {});
		return {
			highlightColor: highlightColor || this.defaultStyles.highlightColor,
			interactionColor: interactionColor || this.defaultStyles.interactionColor,
			menuColor: menuColor || this.defaultStyles.menuColor,
			font1: font1 || this.defaultStyles.font1,
			font2: font2 || this.defaultStyles.font2,
			color1: color1 || this.defaultStyles.color1,
			color2: color2 || this.defaultStyles.color2,
			color3: color3 || this.defaultStyles.color3,
			color4: color4 || this.defaultStyles.color4,
			lightFontColor: '#FFFFFF',
			darkFontColor: '#101d36',
		};
	};

	this.getProductData = () => get(entity, 'productData');

	this.getAdminLang = () => get(entity, 'langs.adminLang', 'es_ES');
	this.getAdminLangInJSFormat = () => this.getAdminLang().replace('_', '-');
	this.getUserLang = () => get(entity, 'langs.userLang', 'es_ES');
	this.getUserLangInJSFormat = () => this.getUserLang().replace('_', '-');
	this.getPreloadLangs = () => get(entity, 'langs.preloadLangs', []);
	this.getLangs = () => ({
		adminLang: this.getAdminLang(),
		userLang: this.getUserLang(),
		preloadLangs: this.getPreloadLangs(),
	});

	const getSection = (sections = [], sectionSlug) => {
		for (const sect of sections) {
			if (sect.slug === sectionSlug) {
				return sect;
			}

			if (sect.sections && sect.sections.length) {
				const section = getSection(sect.sections, sectionSlug);
				if (section) {
					return section;
				}
			}
		}

		return;
	};

	this.getSectionConfig = (sectionSlug) => {
		const sections = entity.sections || [];

		const section = getSection(sections, sectionSlug);

		return section?.config || {};
	};

	this.getSectionBySlug = (sectionSlug) => {
		const sections = entity.sections || [];

		const section = getSection(sections, sectionSlug);

		return section;
	};

	this.hasUserFields = () => {
		const userFields = get(entity, 'data.userFields', []);
		return userFields.length > 0;
	};

	this.getUsersFields = () => {
		const userFields = get(entity, 'data.userFields', []);
		return userFields;
	};

	this.getUsersCustomFields = () => {
		const userFields = this.getUsersFields();

		const customFields = [];

		for (const field of userFields) {
			if (field.type === 'group' && field.config?.fields) {
				const subfields = field.config?.fields || [];
				for (const subfield of subfields) {
					if (subfield.type === 'customFields') {
						customFields.push({...subfield, key: `data.${subfield.key}`});
					}
				}
			}
		}

		return customFields;
	};

	this.getUserField = (key) => {
		const userFields = this.getUsersFields();

		for (const field of userFields) {
			if (field.key === key) {
				return field;
			}
			if (field.type === 'group' && field.config?.fields) {
				const subfields = field.config?.fields || [];
				for (const subfield of subfields) {
					if (subfield.key === key) {
						return subfield;
					}
				}
			}
		}
		return null;
	};

	this.getUserCustomField = (key) => {
		const customFields = this.getUsersCustomFields();

		for (const field of customFields) {
			if (field.key === key) {
				return field;
			}
		}
		return null;
	};

	this.isRegistrationEnabled = () => {
		return (
			get(entity, 'data.loginRegist.isOpenRegist', false) &&
			get(entity, 'data.loginRegist.loginType.value') === 'platform'
		);
	};

	this.showProfileV2 = () => {
		// return get(entity, 'data.loginRegist.showProfileV2', false);
		return true;
	};

	this.slateTextEditor = () => {
		return true;
		// return get(entity, 'data.loginRegist.slateTextEditor', false);
	};

	this.isOpenIdEnabled = () => {
		return get(entity, 'data.loginRegist.loginType.value') === 'openID';
	};

	this.getPillOptions = () => {
		return get(entity, 'data.pillOptions', {});
	};

	this.getKeepAccessOnDuplicatePill = () => {
		return get(entity, 'data.pillOptions.keepAccessOnDuplicatePill', false);
	};

	this.getAppCustomizations = () => get(entity, 'appCustomizations');

	this.getAppCustomizationPeopleFields = () => {
		const appCustomizations = this.getAppCustomizations();
		const peopleFields = get(appCustomizations, 'peopleFields', []);

		if (!peopleFields || !peopleFields.length)
			return [
				{
					type: 'avatar',
				},
				{
					type: 'name',
				},
				{
					type: 'email',
					styles: {
						marginTop: '25px',
					},
				},
			];

		return peopleFields;
	};

	// Upload size limits
	this.getUploadSizeLimits = () => {
		const defaultUploadSizeLimits = {
			video: 200,
			image: 25,
			audio: 100,
			file: 100,
			pillFile: 100,
		};
		const uploadSizeLimits = get(entity, 'data.uploadSizeLimits') || defaultUploadSizeLimits;
		return {
			...defaultUploadSizeLimits,
			...uploadSizeLimits,
		};
	};

	this.getVideoUploadSizeLimit = () => {
		const uploadSizeLimits = this.getUploadSizeLimits();
		return uploadSizeLimits.video;
	};

	this.getImageUploadSizeLimit = () => {
		const uploadSizeLimits = this.getUploadSizeLimits();
		return uploadSizeLimits.image;
	};

	this.getAudioUploadSizeLimit = () => {
		const uploadSizeLimits = this.getUploadSizeLimits();
		return uploadSizeLimits.audio;
	};

	this.getFileUploadSizeLimit = () => {
		const uploadSizeLimits = this.getUploadSizeLimits();
		return uploadSizeLimits.file;
	};

	this.getPillFileUploadSizeLimit = () => {
		const uploadSizeLimits = this.getUploadSizeLimits();
		return uploadSizeLimits.pillFile;
	};

	this.isCookieConsentActive = () => {
		return get(entity, 'data.legals.cookieConsent.active', false);
	};

	this.isCookiesPolicyActive = () => {
		return get(entity, 'data.legals.cookiesPolicy.active', false);
	};

	this.getGoogleAnalyticsId = () => {
		return get(entity, 'data.gaId');
	};
};

export default new EntityManager();
