import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Input from 'ui/shared/FormFields/Input';
import InputCustomCheckBox from 'businessLogic/shared/Input/InputCustomCheckBox';
import LegalConditionsLink from 'businessLogic/shared/Links/LegalConditionsLink';
import TextArea from 'businessLogic/shared/Input/TextArea';
import FileInput from './components/FileInput';
import classNames from 'classnames';
import './styles.scss';

class Form extends PureComponent {
	showLegalPopUp = () => {
		this.props.onShow(true);
	};

	setPersonalizableField = (value, field) => {
		this.props.setData(field, value);
	};

	render() {
		const {data, onSendData, validation, sending} = this.props;
		let classes = classNames({
			'form-personalizable-contest': true,
		});

		return (
			<div className={classes}>
				{data.flexibleContent.map((flexibleField, index) => {
					let isInvalid = false;
					let errorMsg = '';
					if (validation[flexibleField.slugField]) {
						isInvalid = validation[flexibleField.slugField].isInvalid;
						errorMsg = validation[flexibleField.slugField].message;
					}
					switch (flexibleField.flexibleContentType) {
						case 'TextField':
							return (
								<Input
									key={index}
									name={flexibleField.slugField}
									className="form-personalizable-contest__input"
									type="text"
									placeholder={flexibleField.textFieldForm}
									theme="platform-input"
									errorText={(isInvalid && errorMsg) || undefined}
									onChange={(event) => {
										this.setPersonalizableField(event.target.value, flexibleField.slugField);
									}}
								/>
							);
						case 'CheckField':
							return (
								<div className="form-personalizable-contest__input--checkbox" key={index}>
									<InputCustomCheckBox
										errorText={(isInvalid && errorMsg) || undefined}
										name={flexibleField.slugField}
										setData={(event) => {
											// console.log('Check', event.target.checked);
											this.setPersonalizableField(event.target.checked, flexibleField.slugField);
										}}
									>
										{/* si hay texto para mostrar en un modal */}
										{flexibleField.checkFieldForm ? (
											<LegalConditionsLink
												label={flexibleField.labelField}
												link={flexibleField.checkFieldFormTextLink}
												text={flexibleField.checkFieldForm}
											/>
										) : (
											<LegalConditionsLink label={flexibleField.labelField} />
										)}
									</InputCustomCheckBox>
								</div>
							);

						case 'EmailField':
							return (
								<Input
									name={flexibleField.slugField}
									key={index}
									className="form-personalizable-contest__input"
									type="email"
									placeholder={flexibleField.emailFieldForm}
									theme="platform-input"
									errorText={(isInvalid && errorMsg) || undefined}
									onChange={(event) => {
										this.setPersonalizableField(event.target.value, flexibleField.slugField);
									}}
								/>
							);
						case 'PhoneField':
							return (
								<Input
									name={flexibleField.slugField}
									key={index}
									className="form-personalizable-contest__input"
									type="tel"
									placeholder={flexibleField.phoneFieldForm}
									theme="platform-input"
									errorText={(isInvalid && errorMsg) || undefined}
									onChange={(event) => {
										this.setPersonalizableField(event.target.value, flexibleField.slugField);
									}}
								/>
							);
						case 'TextAreaField':
							return (
								<TextArea
									name={flexibleField.slugField}
									key={index}
									className="form-personalizable-contest__textarea"
									placeholder={flexibleField.textAreaFieldForm}
									theme="platform-textarea"
									errorText={(isInvalid && errorMsg) || undefined}
									rows={3}
									onChange={(event) => {
										this.setPersonalizableField(event.target.value, flexibleField.slugField);
									}}
								/>
							);

						case 'File': {
							const label = flexibleField.label;
							const indications = flexibleField.indications;
							return (
								<div className="form-personalizable-contest__input--file" key={index}>
									{label && (
										<label className="form-personalizable-contest__input--file__label">
											{label}
										</label>
									)}
									{indications && (
										<p className="form-personalizable-contest__input--file__indications">
											{indications}
										</p>
									)}
									<FileInput
										mimeType={flexibleField.mimeType}
										multiple={flexibleField.multiple}
										onChange={(files) => {
											this.setPersonalizableField(files, flexibleField.slugField);
										}}
										errorText={(isInvalid && errorMsg) || undefined}
									/>
								</div>
							);
						}

						default:
							return (
								<Input
									name={flexibleField.slugField}
									key={index}
									className="form-personalizable-contest__input"
									type="text"
									placeholder={flexibleField.textFieldForm}
									theme="platform-input"
									errorText={(isInvalid && errorMsg) || undefined}
									onChange={(event) => {
										this.setPersonalizableField(event.target.value, flexibleField.slugField);
									}}
								/>
							);
					}
				})}

				<button
					className={`form-personalizable-contest__button ${sending && 'sending'}`}
					onClick={onSendData}
				>
					{/* {data.button} */}
					{sending ? 'Enviando...' : data.button}
				</button>
			</div>
		);
	}
}

Form.propTypes = {
	data: PropTypes.object,
};

Form.defaultProps = {
	data: {},
};

export default Form;
