import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import Icon from 'ui/shared/Icon';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import isEmpty from 'lodash/isEmpty';
import {rgbaStringToObject} from 'businessLogic/helpers/functions';
import './styles.scss';

class ImportantTextMsgBox extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Información importante`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		text: 'Micim sinverum vellant quam corrumque il moluptium.',
		important: 'Important',
	};
	static brickDefaultConfig = {};
	// static brickDefaultStyles = {
	// 	containerStyle: {
	// 		generalColor: {r: '115', g: '229', b: '209', a: '1'},
	// 	},
	// };
	render() {
		const data = this.props.brick.data;

		const highlightColor = getComputedStyle(document.documentElement).getPropertyValue(
			'--highlightColor',
		);
		let styles;
		if (isEmpty(this.props.brick.styles)) {
			styles = {
				containerStyle: {
					generalColor: rgbaStringToObject(highlightColor),
				},
			};
		} else {
			styles = this.props.brick.styles || {};
		}

		const classes = classNames({
			'brick': true,
			'important-text-msg-box-brick': true,
		});

		let stylesImportantText = {
			importantText: {},
			borderBox: {},
			textColor: {},
		};

		const generalColor = styles?.containerStyle?.generalColor;

		let selectedColor = highlightColor;

		if (generalColor) {
			selectedColor =
				'rgba(' +
				generalColor.r +
				',' +
				generalColor.g +
				',' +
				generalColor.b +
				',' +
				generalColor.a +
				')';
		}

		stylesImportantText.importantText.backgroundColor = selectedColor;
		stylesImportantText.borderBox.borderColor = selectedColor;
		stylesImportantText.textColor.color = selectedColor;

		return (
			<Container
				size="small"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes} style={stylesImportantText.borderBox}>
					<div
						className="important-text-msg-box-brick__bg"
						style={stylesImportantText.importantText}
					></div>
					<div className="important-text-msg-box-brick__icon" style={stylesImportantText.textColor}>
						<Icon name="info" size="normal" />
					</div>
					<div className="important-text-msg-box-brick__content">
						<div
							className="important-text-msg-box-brick__content__important"
							style={stylesImportantText.textColor}
						>
							<FrontEditableText fieldName="important" text={data.important} set={this.props.set} />
						</div>
						<div className="important-text-msg-box-brick__content__text">
							<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
						</div>
					</div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ImportantTextMsgBox);
