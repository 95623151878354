import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import './styles.scss';

class ImagesMain extends PureComponent {
	render() {
		const classes = classNames({
			'images-main': true,
		});

		const images = this.props.items;
		const selectedImageIndex = this.props.selectedImageIndex;

		return (
			<div className={classes}>
				<NewImageClickable
					imgObj={images[selectedImageIndex].imageObj}
					clickable={this.props.clickable}
				/>
			</div>
		);
	}
}

ImagesMain.propTypes = {
	items: PropTypes.array,
	selectedImageIndex: PropTypes.number,
	clickable: PropTypes.bool,
};

ImagesMain.defaultProps = {
	items: [],
	selectedImageIndex: 0,
};

export default ImagesMain;
