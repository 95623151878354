import React from 'react';
import {useSlate} from 'slate-react';
import ToggleButton from '../basics/ToggleButton';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';

import AIDialog from './components/AIDialog';

const SetAlignmentButton = () => {
	const editor = useSlate();
	const [open, setOpen] = React.useState(false);
	const [prompt, setPrompt] = React.useState('');
	const [anchorEl, setAnchorEl] = React.useState(null);
	const {getSelectionContent, replaceSelectionContent} = editor.helpers;

	const handleAccept = (response) => {
		replaceSelectionContent(editor, response);
		setOpen(false);
	};

	return (
		<>
			<ToggleButton
				format={'alignment'}
				onClick={(event) => {
					event.preventDefault();
					event.stopPropagation();
					if (anchorEl) {
						setAnchorEl(null);
						return;
					}

					setAnchorEl(event.currentTarget);
				}}
				selected={false}
			>
				<AutoAwesomeIcon />
			</ToggleButton>
			<Popper
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => {
					setAnchorEl(null);
				}}
				// anchorOrigin={{
				// 	vertical: 'bottom',
				// 	horizontal: 'left',
				// }}
				sx={{
					'zIndex': 1,
					'& .MuiPaper-root': {
						'boxShadow': '0px 0px 10px 0px rgba(0,0,0,0.2)',
						'padding': '10px',
						'& button': {
							width: '100%',
						},
					},
				}}
				modifiers={[
					{
						name: 'offset',
						enabled: true,
						options: {
							offset: [0, 0],
						},
					},
				]}
			>
				<Paper
					sx={{
						transition: 'opacity 0.75s',
						backgroundColor: '#ffffff',
						// padding: '5px 5px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<ToggleButton
						onClick={(event) => {
							event.preventDefault();
							event.stopPropagation();
							setPrompt('');
							setOpen(true);
							setAnchorEl(null);
						}}
					>
						Redactar
					</ToggleButton>
					<ToggleButton
						onClick={(event) => {
							event.preventDefault();
							event.stopPropagation();
							setPrompt('Aumenta la longitud del texto');
							setOpen(true);
							setAnchorEl(null);
						}}
					>
						Alargar
					</ToggleButton>
					<ToggleButton
						onClick={(event) => {
							event.preventDefault();
							event.stopPropagation();
							setPrompt('Acorta el texto');
							setOpen(true);
							setAnchorEl(null);
						}}
					>
						Acortar
					</ToggleButton>
					<ToggleButton
						onClick={(event) => {
							event.preventDefault();
							event.stopPropagation();
							setPrompt('Simplifica el lenguaje');
							setOpen(true);
							setAnchorEl(null);
						}}
					>
						Simplificar lenguaje
					</ToggleButton>
					<ToggleButton
						onClick={(event) => {
							event.preventDefault();
							event.stopPropagation();
							setPrompt('Mejora la redacción');
							setOpen(true);
							setAnchorEl(null);
						}}
					>
						Mejorar redacción
					</ToggleButton>
				</Paper>
			</Popper>
			<AIDialog
				open={open}
				onCancel={() => setOpen(false)}
				selectedText={getSelectionContent(editor)}
				defaultPrompt={prompt}
				onAccept={handleAccept}
			/>
		</>
	);
};

export default SetAlignmentButton;
