import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
// import Instructions from 'businessLogic/core/shared/Edit/Instructions';
import './styles.scss';

class Switch extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		let defaultValue = '';
		if (editionBrickStruct.defaultValue) {
			defaultValue = editionBrickStruct.defaultValue;
		}

		return defaultValue;
	};

	setValue = (value) => {
		this.props.setMainField(value);
	};

	handleToggle = () => {
		this.setValue(!this.props.getMainField());
	};

	render() {
		return (
			<React.Fragment>
				<div className="edition-brick-switch">
					<label
						className="edition-brick-switch__label"
						title={this.props.editionBrickStruct.instructions}
					>
						{this.props.editionBrickStruct.title}
					</label>
					<div
						className={
							'edition-brick-switch__button ' + (this.props.getMainField() ? 'active' : '')
						}
					>
						<span className="edition-brick-switch__button__state">
							{this.props.getMainField() ? 'On' : 'Off'}
						</span>
						<div className="edition-brick-switch__button__btn-switch" onClick={this.handleToggle} />
					</div>
				</div>
				{/* {this.props.editionBrickStruct.instructions !== undefined && (
					<Instructions text={this.props.editionBrickStruct.instructions} />
				)} */}
			</React.Fragment>
		);
	}
}

export default editionBrickWrapper(Switch);
