import {memo, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';

const Portal = ({id, children}) => {
	const el = useRef(document.getElementById(id) || document.createElement('div'));
	const [dynamic] = useState(!el.current.parentElement);
	useEffect(() => {
		const element = el.current;
		if (dynamic) {
			element.id = id;
			document.body.appendChild(element);
		}
		return () => {
			if (dynamic && element.parentElement && element.parentElement.contains(element)) {
				element.parentElement.removeChild(element);
			}
		};
	}, [id, dynamic]);
	return createPortal(children, el.current);
};
export default memo(Portal);
