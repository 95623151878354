import store from 'businessLogic/store/store.js';
import * as CONST from './constants.js';

//Action launchers
//Aquí se definen todas las acciones que lanzarán "dispatch" para modificar el store
//Estas acciones son el interfaz que tienen los componentes para modificar el store
//Ejemplo
// export function createPill(dataPill) {
// 	store.dispatch({type: CONST.CREATE_PILL, dataPill});
// }

export function setEditMode(editMode) {
	store.dispatch({type: CONST.CHANGE_EDIT_MODE, dataPayload: {editMode}});
}

export function closeDialog() {
	store.dispatch({type: CONST.CHANGE_DIALOG, dataPayload: {type: 'none'}});
}

export function openAddBrickDialog(parentBrickId, index = -1, childList = 'default') {
	store.dispatch({
		type: CONST.CHANGE_DIALOG,
		dataPayload: {type: 'addBrick', relatedId: parentBrickId, index, childList},
	});
}

export function openEditBrickDialog(brickId) {
	store.dispatch({
		type: CONST.CHANGE_DIALOG,
		dataPayload: {type: 'editBrick', relatedId: brickId},
	});
}

export function openEditPillDialog() {
	store.dispatch({
		type: CONST.CHANGE_DIALOG,
		dataPayload: {type: 'editPill'},
	});
}

export function openAIPillDialog() {
	store.dispatch({
		type: CONST.CHANGE_DIALOG,
		dataPayload: {type: 'aiPill'},
	});
}
