import React, {PureComponent} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

class Animation extends PureComponent {
	render() {
		const {effect, delay, duration, playAnimation} = this.props;
		const classes = classNames({
			'animation': true,
			'play-animation': playAnimation,
			[effect]: effect,
		});

		const styles = {};
		if (delay) styles.animationDelay = `${delay}s`;
		if (duration) styles.animationDuration = `${duration}s`;

		return (
			<div className={classes} style={styles}>
				{this.props.children}
			</div>
		);
	}
}

Animation.propTypes = {
	effect: PropTypes.oneOf([
		'fade',
		'scale',
		'fade-scale',
		'translate-top-to-bottom',
		'translate-bottom-to-top',
		'translate-left-to-right',
		'translate-right-to-left',
	]),
	delay: PropTypes.number,
	duration: PropTypes.number,
	playAnimation: PropTypes.bool,
};

Animation.defaultProps = {
	delay: 0,
	duration: 1,
	playAnimation: false,
};

export default Animation;
