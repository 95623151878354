class EventHandlerCache {
	constructor() {
		this.eventHandlers = {};
	}

	//Devuelve la referencia de la función manejadora
	getEventHandler(eventName, key, handler) {
		//Si no existe la caché para el evento EventName, se crea el objeto
		if (!this.eventHandlers.hasOwnProperty(eventName)) {
			this.eventHandlers[eventName] = {};
		}

		//Si ya existe una función para la clave key, devolvemos el dato cacheado
		if (this.eventHandlers[eventName].hasOwnProperty(key)) {
			return this.eventHandlers[eventName][key];
		}

		//En caso de que no exista en caché, almacenamos el valor pasado y devolvemos ese mismo valor
		this.eventHandlers[eventName][key] = handler;
		return handler;
	}
}

export default EventHandlerCache;
