import React from 'react';
import entityManager from 'businessLogic/services/EntityManager';
import QuillTextEditor from 'businessLogic/shared/Text/QuillTextEditor';
import SlateTextEditor from 'businessLogic/shared/Text/SlateTextEditor';

export default function ComponentName(props) {
	const slateTextEditor = entityManager.slateTextEditor();

	if (slateTextEditor) {
		return <SlateTextEditor {...props} />;
	}
	return <QuillTextEditor {...props} />;
}
