import React from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PsychologyIcon from '@mui/icons-material/Psychology';
import Loading from '@mui/material/CircularProgress';

import store from 'businessLogic/store/store';
import {getPill} from 'businessLogic/store/data/pills/selectors';
import {generateId} from 'businessLogic/helpers/functions';

const htmlToText = (html) => {
	const tmp = document.createElement('DIV');
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || '';
};

const excludeKeywords = [
	'type',
	'src',
	'imageSrc',
	'audioSrc',
	'videoSrc',
	'imageBg',
	'fileName',
	'colorBg',
	'paddingTop',
	'paddingBottom',
	'colorLayerOpacity',
	'colorText',
	'themeSelected',
	'_id',
	'flexibleContentType',
	'deltaValue',
];

// Recorremos todas las propiedades del objeto
// Si es una de las propiedades que no queremos, la saltamos
// Si es un objeto, lo recorremos
// Si es un array, lo recorremos
// Si la propiedad se llama textValue, lo convertimos a texto plano y lo añadimos a pillText
// Si es un string, lo añadimos a pillText
const getBrickText = (brickData) => {
	let pillText = '';
	for (const key in brickData) {
		if (excludeKeywords.includes(key)) {
			continue;
		}

		const value = brickData[key];

		if (typeof value === 'object') {
			if (Array.isArray(value)) {
				for (const item of value) {
					pillText += getBrickText(item);
				}
			} else {
				pillText += getBrickText(value);
			}
		} else if (key === 'textValue') {
			pillText += '\n';
			pillText += htmlToText(value);
		} else if (typeof value === 'string' && value !== '') {
			pillText += '\n';
			pillText += value;
		}
	}

	return pillText;
};

const getPillText = (pill, testId) => {
	const content = pill.content;
	const bricks = content.bricks;
	const containerBrick = bricks.find((brick) => brick.id === content.containerBrick);
	const containerBrickChildren = containerBrick?.children?.default ?? [];

	let pillText = '';

	for (const brickId of containerBrickChildren) {
		if (brickId === testId) {
			break;
		}

		const brick = bricks.find((brick) => brick.id === brickId);

		const brickData = brick.data;
		pillText += getBrickText(brickData);
		pillText += '\n\n';
	}

	return pillText;
};

const GenerateQuestion = ({pillId, get, getByPath, setByPath, editionBrickData, path}) => {
	const [loading, setLoading] = React.useState(false);

	return (
		<Box
			className="edition-brick-generate-question"
			sx={{
				display: 'flex',
				justifyContent: 'right',
				marginTop: '20px',
			}}
		>
			<Button
				variant="contained"
				color="primary"
				startIcon={loading ? <Loading size={20} /> : <PsychologyIcon />}
				disabled={loading}
				onClick={async () => {
					setLoading(true);
					// console.log('Generar pregunta', rest);
					try {
						// Obtenemos el texto de la píldora
						const testBrickId = editionBrickData.id;
						const pill = getPill(store.getState(), pillId);
						const pillText = getPillText(pill, testBrickId);

						// console.log('pill', pillText);

						const testBrick = pill.content.bricks.find((brick) => brick.id === testBrickId);
						const testBrickData = testBrick.data;
						const questions = testBrickData.questions;
						const currentQuestion = get('question');
						const otherQuestions = questions.filter(
							(question) => question.question !== currentQuestion,
						);
						// console.log('otherQuestions', otherQuestions);

						const serviceUrl =
							'https://europe-west1-createandshare.cloudfunctions.net/cs-ia-service/generateQuestion';
						const response = await fetch(serviceUrl, {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({
								content: pillText,
								questions: otherQuestions,
							}),
						});

						const data = await response.json();

						// console.log('data', data);

						const question = data.question.question;
						const answers = data.question.answers;

						const questionIndex = path[path.length - 1];
						// El path de la pregunta es el path recibido, quitando el último elemento
						const questionsPath = path.slice(0, -1);

						const currentQuestions = getByPath(questionsPath);

						// Añadimos la nueva pregunta al array de preguntas sustituyendo la pregunta actual
						const newQuestions = [
							...currentQuestions.slice(0, questionIndex),
							{
								_id: generateId(),
								question,
								answers: answers.map((answer) => ({
									_id: generateId(),
									...answer,
								})),
							},
							...currentQuestions.slice(questionIndex + 1),
						];
						setByPath(questionsPath, newQuestions);

						// setLoading(false);
					} catch (error) {
						console.error('Error al generar pregunta', error);
						setLoading(false);
					}
				}}
			>
				{loading ? 'Generando pregunta...' : 'Generar pregunta'}
			</Button>
		</Box>
	);
};

export default editionBrickWrapper(GenerateQuestion);
