import * as React from 'react';
import Box from '@mui/material/Box';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import EditImage from 'businessLogic/shared/Image/EditImageV2';
import ImageSelectorDialog from 'businessLogic/shared/MediaSelector/ImageSelectorDialogV2';
import Icon from 'ui/shared/Icon';

import {imageUrl} from 'businessLogic/helpers/images';

const getBackgroundPosition = (imageObj) => {
	const {x, y} = imageObj?.centerPosition || {};

	if (x && y) {
		return `${x}% ${y}%`;
	}

	return 'center center';
};

const ImageSelector = ({imageObj, onChange}) => {
	const [showEdit, setShowEdit] = React.useState(false);
	const [showImageSelector, setShowImageSelector] = React.useState(false);
	return (
		<React.Fragment>
			{/* Contenedor con la imagen como background */}
			<Box
				sx={{
					width: '100%',
					paddingBottom: '57.5%',
					backgroundImage: `url("${
						imageObj?.imageSrc
							? imageUrl(imageObj?.imageSrc)
							: imageUrl('/uploads/default-images/picture.svg')
					}")`,
					backgroundPosition: getBackgroundPosition(imageObj),
					backgroundSize: imageObj?.imageSrc ? 'cover' : 'contain',
					backgroundRepeat: 'no-repeat',
					position: 'relative',
				}}
			>
				{/* Contenedor de velado on hover con los botones */}
				<Box
					sx={{
						'position': 'absolute',
						'width': '100%',
						'height': '100%',
						'backgroundColor': 'rgba(0,0,0,0.4)',
						'opacity': 0,
						'&:hover': {
							opacity: 1,
						},
						'transition': 'opacity .25s',
					}}
				>
					<Box sx={{position: 'absolute', top: 0, right: 0, color: '#fff', padding: 1}}>
						<Box
							sx={{
								'display': 'inline-block',
								'cursor': 'pointer',
								'opacity': 0.8,
								'&:hover': {
									opacity: 1,
								},
								'transition': 'opacity .25s',
							}}
							title={t`Cambiar imagen`}
							onClick={() => {
								setShowImageSelector(true);
							}}
						>
							<Icon name="image-search" size="normal" />
						</Box>
						<Box
							sx={{
								// 'display': 'inline-block',
								'display': 'none',
								'cursor': 'pointer',
								'opacity': 0.8,
								'&:hover': {
									opacity: 1,
								},
								'transition': 'opacity .25s',
							}}
							title={t`Cambiar punto focal`}
							onClick={() => {
								// onOpenModal(true);
								setShowEdit(true);
							}}
						>
							<Icon name="edit" size="normal" />
						</Box>
						<Box
							sx={{
								'display': 'inline-block',
								'cursor': 'pointer',
								'opacity': 0.8,
								'&:hover': {
									opacity: 1,
								},
								'transition': 'opacity .25s',
							}}
							title={t`Quitar imagen`}
							onClick={() => {
								onChange('');
							}}
						>
							<Icon name="delete" size="normal" />
						</Box>
					</Box>
				</Box>
			</Box>
			<ImageSelectorDialog
				show={showImageSelector}
				onClose={() => setShowImageSelector(false)}
				onSelect={(imageUrl) => {
					const newImageObj = Object.assign({}, imageObj);
					newImageObj.imageSrc = imageUrl;
					onChange(newImageObj);
				}}
			/>
			<EditImage
				srcImage={imageObj?.imageSrc}
				show={showEdit}
				onHide={() => setShowEdit(false)}
				handleChangeCenterPosition={(centerPosition) => {
					const newImageObj = Object.assign({}, imageObj);
					newImageObj.centerPosition = centerPosition;
					onChange(newImageObj);
				}}
				valueCenterPosition={imageObj && imageObj.centerPosition}
			/>
		</React.Fragment>
	);
};

export default ImageSelector;
