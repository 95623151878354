import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import Container from 'businessLogic/core/shared/Container/Container';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import set from 'lodash/set';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

class ImportantTextCenterBox extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Destacado con caja`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		text:
			'Feri dem eos volorat. Archil iusdaecto inveria nditat.Hiligent quae re pre la doloremporro magni od quiatur a sunditate cuptas dolestrunt, cus ipiendis into el magnis soluptiunt dunt',
	};

	static brickDefaultConfig = {};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		set(styles, 'containerStyle.overflow', 'visible');
		set(styles, 'containerStyle.borderRadius', 12);

		const classes = classNames({
			'brick': true,
			'important-text-center-box-brick': true,
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<FrontEditableText fieldName="text" text={data.text} set={this.props.set} />
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ImportantTextCenterBox);
