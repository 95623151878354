import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Image',
						fieldName: 'image',
						defaultImage: '/uploads/default-images/csDefault1Opt.svg',
						defaultCredit: 'Crédito de ejemplo',
						footer: true,
					},
				],
			},
		],
	},
];
export default editionFields;
