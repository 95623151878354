import React, {Component} from 'react';
import Loading from 'ui/scope/admin/Loading';
import {connect} from 'react-redux';
import UniversalRouter from 'universal-router';
import get from 'lodash/get';
import ga from 'businessLogic/services/GoogleAnalyticsManager';
import entityManager from 'businessLogic/services/EntityManager';
import PlatformMetas from 'businessLogic/shared/Layout/PlatformMetas';
import {checkLoggedUser} from 'businessLogic/store/data/session/actions';
import ThemeProvider from 'businessLogic/routes/wrappers/ThemeProvider';

class Router extends Component {
	constructor(props) {
		super(props);
		this.state = {
			component: <div />,
			loading: true,
		};
		this.initializeRouter(props);
	}

	initializeRouter = async (props) => {
		this.router = await this.getUniversalRouter();
		this.setState({loading: false});
		this.updateComponent(props);
	};

	updateComponent = (props) => {
		if (!this.router) return;
		this.router.resolve(props).then((component) => {
			const componentType = get(component, 'type.name');
			const authType = get(component, 'props.authType');

			if (authType !== 'admin' && authType !== 'superAdmin' && componentType !== 'Redirect') {
				ga.pageview(props.pathname);
			}

			if (!props.omitRender) {
				this.setState({
					component,
				});
			}
		});
	};

	shouldComponentUpdate(nextProps, nextState) {
		//Si cambia el path de la ruta o la sesión del usuario, iniciamos una actualización del componente, que provocará un cambio en el state
		if (this.props.pathname !== nextProps.pathname || this.props.search !== nextProps.search) {
			this.updateComponent(nextProps);
		}

		//Solo renderizamos si cambia la referencia del componente asociado a la ruta
		return this.state.component !== nextState.component;
	}

	getUniversalRouter = async () => {
		// Lanzamos la comprobación de si el usuario está logado en asíncrono
		checkLoggedUser();

		// Obtenemos la entidad actual
		const entity = await entityManager.initialize();

		// Inicializamos Analitics con la entidad
		ga.initializeFromEntity(entity);

		if (process.env.REACT_APP_EVENTS_API === 'scorm') {
			const {scormRoutes} = await import(
				/* webpackChunkName: "scormRoutes" */ 'businessLogic/routes/scormRoutes.js'
			);
			return new UniversalRouter(scormRoutes, {context: {entity}});
		} else {
			const {getRoutes} = await import(
				/* webpackChunkName: "routes" */ 'businessLogic/routes/routes.js'
			);

			const routes = getRoutes(entity);

			return new UniversalRouter(routes, {context: {entity}});
		}
	};

	render() {
		if (this.state.loading) {
			return null;
		}

		return (
			<ThemeProvider>
				<React.Suspense fallback={<Loading />}>
					<PlatformMetas context="iosLogos" entity={entityManager.getEntity()} />
					{this.state.component}
				</React.Suspense>
			</ThemeProvider>
		);
	}
}

//function que sirve para pasar los datos al render
const mapStateToProps = (state) => {
	const url = get(state, ['dataGeneral', 'router']);
	return {
		pathname: url.pathname || '/',
		search: url.search || '',
		hash: url.hash || '',
		omitRender: url.omitRender,
	};
};

const RouterConnect = connect(mapStateToProps)(Router);

export default RouterConnect;
