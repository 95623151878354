import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ViewText from './components/ViewText';
import {BrickContext} from 'businessLogic/core/bricks';
import omit from 'lodash/omit';
import get from 'lodash/get';
import shallowEqual from 'shallowequal';
import ComponentLoader from 'businessLogic/shared/ComponentLoader';
import './styles.scss';

const TextEditor = React.lazy(
	ComponentLoader(() =>
		import(/* webpackChunkName: "TextEditor" */ 'businessLogic/shared/Text/TextEditor'),
	),
);

class FrontEditableText extends Component {
	shouldComponentUpdate(nextProps) {
		//Solo comparamos los props (este componente no tiene estado) excluyendo la función set
		const filteredNextProps = omit(nextProps, ['set']);
		const filteredProps = omit(this.props, ['set']);

		return !shallowEqual(filteredProps, filteredNextProps);
	}
	onChange = (textObject) => {
		if (
			this.props.set &&
			this.props.fieldName &&
			(get(this.props, 'text.textValue') !== get(textObject, 'textValue') ||
				get(this.props, 'text.deltaValue') !== get(textObject, 'deltaValue'))
		) {
			this.props.set(this.props.fieldName, textObject);
		}
	};
	render() {
		const {className, text} = this.props;
		const classes = classNames({
			'front-editable-text': true,
			[`${className}`]: true,
		});

		return (
			<BrickContext.Consumer>
				{(context) => {
					const editMode = context.editMode;
					let textComponent;
					if (editMode && this.props.editable) {
						textComponent = (
							<TextEditor
								reducedVersion={true}
								text={text}
								onChange={this.onChange}
								reloadOnVersionChange={true}
							/>
						);
					} else {
						const textValue = text && text.textValue !== undefined ? text.textValue : text;

						//Añado la clase ql-editor para que se mantengan los mismos estilos que vemos en el editor
						textComponent = <ViewText text={textValue} />;
					}
					return <div className={classes}>{textComponent}</div>;
				}}
			</BrickContext.Consumer>
		);
	}
}

FrontEditableText.propTypes = {
	className: PropTypes.string,
	fieldName: PropTypes.string,
	set: PropTypes.func,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	editable: PropTypes.bool,
};

FrontEditableText.defaultProps = {
	className: '',
	text: {
		textValue: '<p></p>',
		deltaValue: {
			ops: [
				{
					insert: '',
				},
			],
		},
	},
	editable: true,
};

export default FrontEditableText;
