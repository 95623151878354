import {useSelector} from 'react-redux';
import {isSuperAdmin} from 'businessLogic/store/data/session';

const OnlySuperAdmin = (props) => {
	const superAdmin = useSelector(isSuperAdmin);
	if (superAdmin) {
		return props.children;
	}
	return null;
};

export default OnlySuperAdmin;
