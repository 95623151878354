import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import EditImage from 'businessLogic/shared/Image/EditImage';
import {imageUrl} from 'businessLogic/helpers/images';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import './styles.scss';

class EditImageBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showEdit: false,
			openImageActions: false,
		};
	}
	handleMainButton = () => {
		if (this.props.onClickMainButton) {
			this.props.onClickMainButton();
		}
	};

	showEditImage = () => {
		this.setState({showEdit: true});
	};

	hideEditImage = () => {
		this.setState({showEdit: false});
	};
	render() {
		const {
			className,
			srcImage,
			onDelete,
			onReplace,
			onEdit,
			onClickMainButton,
			roundMainButton,
			square,
			landscape,
			portrait,
			positionImg,
			small,
			handleChangeCenterPosition,
			valueCenterPosition,
			editable,
			canAccessEdit,
			index,
			showCompleteImage,
		} = this.props;
		const classes = classNames({
			'edit-image-box': true,
			'edit-image-box--delete': onDelete,
			'edit-image-box--replace': onReplace,
			'edit-image-box--edit': onEdit,
			'edit-image-box--main-button': onClickMainButton,
			'edit-image-box--round-main-button': roundMainButton,
			'edit-image-box--square': square,
			'edit-image-box--landscape': landscape,
			'edit-image-box--portrait': portrait,
			'edit-image-box--small': small,
			'edit-image-box--contain': showCompleteImage,
			[`${className}`]: true,
		});

		/**proporciones tamaños imagen
		 * Imágenes cuadradas SQUARE, proporción 1 (350 x 350)
		 * Imágenes horizontales normales LANDSCAPE, proporción 1.6 (400 x 250)
		 * Imágenes verticales PORTRAIT, proporción 0.8 (280 x 350)
		 **/

		/**
		 * positionImg será un array con la posicion para el fondo de la imagen
		 * [x,y]
		 * [0px,0px] sería colocarla haciendo coindidir la esquina superior izquierda
		 * **/

		let divStyle;
		if (positionImg !== null) {
			divStyle = {
				backgroundImage:
					'url("' +
					(srcImage ? imageUrl(srcImage) : imageUrl('/uploads/default-images/picture.svg')) +
					'")',
				backgroundPositionX: positionImg[0],
				backgroundPositionY: positionImg[1],
			};
		} else {
		}

		divStyle = {
			backgroundImage:
				'url("' +
				(srcImage ? imageUrl(srcImage) : imageUrl('/uploads/default-images/picture.svg')) +
				'")',
		};
		if (valueCenterPosition && valueCenterPosition.x > 0 && valueCenterPosition.y > 0) {
			divStyle.backgroundPosition = valueCenterPosition.x + '% ' + valueCenterPosition.y + '%';
		}

		return (
			<div className={classes}>
				<div className="edit-image-box__image-general-container">
					<div
						className={
							'edit-image-box__image-general-container__image-container ' +
							(!srcImage ? 'default-img' : '')
						}
						style={divStyle}
						onMouseEnter={() => {
							this.setState({openImageActions: true});
						}}
						onMouseLeave={() => {
							this.setState({openImageActions: false});
						}}
					>
						{canAccessEdit && (
							<SpeedDial
								ariaLabel={t`Acciones`}
								open={this.state.openImageActions}
								direction="left"
								sx={() => ({
									'position': 'absolute',
									'right': '5px',
									// 'left': '0',
									// 'margin': 'auto',
									'top': '5px',
									'zIndex': 1,
									'paddingRight': 0,
									'justifyContent': 'center',

									'& .MuiSpeedDial-fab': {
										display: 'none',
									},
									'& .MuiSpeedDial-actions': {
										flexDirection: 'row',
										paddingRight: '0px',
										marginRight: '0px',
									},
									'& button': {
										width: '27px',
										height: '27px',
										minHeight: '27px',
										margin: '5px',
									},
									'& .icon-comp': {
										'fontSize': '15px',
										'margin': 0,
										'paddingTop': '2px',
										'&.change-image-icon': {
											paddingLeft: '2px',
										},
									},
								})}
							>
								{onReplace && (
									<SpeedDialAction
										icon={<Icon name="image-search" size="small" className="change-image-icon" />}
										tooltipTitle={t`Cambiar imagen`}
										onClick={onReplace}
										tooltipPlacement="bottom"
									/>
								)}

								{editable && handleChangeCenterPosition && (
									<SpeedDialAction
										icon={<Icon name="edit" size="small" className="edit-image-icon" />}
										tooltipTitle={t`Editar centrado de imagen`}
										onClick={this.showEditImage}
										tooltipPlacement="bottom"
									/>
								)}

								{onDelete && (
									<SpeedDialAction
										icon={<Icon name="delete" size="small" />}
										tooltipTitle={t`Eliminar`}
										onClick={onDelete}
										tooltipPlacement="bottom"
									/>
								)}
							</SpeedDial>
						)}
					</div>

					{canAccessEdit && editable && (
						<EditImage
							srcImage={srcImage}
							show={this.state.showEdit}
							onHide={this.hideEditImage}
							handleChangeCenterPosition={handleChangeCenterPosition}
							valueCenterPosition={valueCenterPosition}
							index={index}
						/>
					)}
				</div>
			</div>
		);
	}
}

EditImageBox.propTypes = {
	srcImage: PropTypes.string,
	onDelete: PropTypes.func,
	onReplace: PropTypes.func,
	onEdit: PropTypes.func,
	mainIcon: PropTypes.string,
	mainLabel: PropTypes.string,
	onClickMainButton: PropTypes.func,
	square: PropTypes.bool,
	landscape: PropTypes.bool,
	portrait: PropTypes.bool,
	positionImg: PropTypes.array,
	small: PropTypes.bool,
	editable: PropTypes.bool,
	canAccessEdit: PropTypes.bool,
};

EditImageBox.defaultProps = {
	srcImage: '',
	onDelete: null,
	onReplace: null,
	onEdit: null,
	mainIcon: '',
	mainLabel: '',
	onClickMainButton: null,
	square: false,
	landscape: false,
	portrait: false,
	positionImg: null,
	small: false,
	editable: false,
	canAccessEdit: true,
};

export default EditImageBox;
