import {client} from 'businessLogic/store/store';
import eventQueries from 'businessLogic/store/data/events/queries';
import LZString from 'lz-string';

class BrickAnomDataManager {
	static getKey(pillId, brickId) {
		return ['anomData', pillId, brickId].join('_');
	}

	static async setBrickAnomData(pillId, brickId, data) {
		const brickAnomData = await client.mutate({
			mutation: eventQueries.SET_BRICK_ANOM_DATA,
			variables: {
				pillId,
				brickId,
				data
			}
		});

		//Si la mutación se ha realizado con éxito, guardamos el resultado en el localStorage para poder comprobar más adelante si se ha enviado
		if (brickAnomData && typeof Storage !== 'undefined') {
			const key = BrickAnomDataManager.getKey(pillId, brickId);
			const value = LZString.compress(JSON.stringify(brickAnomData));
			localStorage.setItem(key, value);
		}
	}

	static async getBrickAnomDataList(pillId, brickId) {
		return await client.query({
			query: eventQueries.GET_BRICK_ANOM_DATA_LIST,
			variables: {
				pillId,
				brickId
			}
		});
	}

	static brickAnomDataExists(pillId, brickId) {
		if (typeof Storage === 'undefined') return false;

		return !!localStorage.getItem(BrickAnomDataManager.getKey(pillId, brickId));
	}

	static getBrickAnomData(pillId, brickId) {
		if (typeof Storage === 'undefined') return {};

		const serializedData = localStorage.getItem(BrickAnomDataManager.getKey(pillId, brickId));

		if (!serializedData) return {};

		return JSON.parse(LZString.decompress(serializedData));
	}
}

export default BrickAnomDataManager;
