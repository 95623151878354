import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';

class HeadingBig extends PureComponent {
	static editionFields = editionFields;
	//Datos para listar el ladrillo
	static brickName = () => t`Título grande`;
	static brickImage = brickImage;
	//Definimos la estructura de datos que tendrá el ladrillo cuando lo incluimos (el valor con el que vendrá precargado)

	static brickDefaultData = {
		heading: {
			slateValue: [
				{
					type: 'paragraph',
					children: [
						{
							text: 'DUSUE NOBISTA MICIUS ET QUIA LIGOAS NOBISTA MICIUS',
							bold: true,
						},
					],
					align: 'center',
				},
			],
			textValue:
				'<p class="ql-align-center"><span style=""><strong>DUSUE NOBISTA MICIUS ET QUIA LIGOAS NOBISTA MICIUS</strong></span></p>',
		},
		v: 2,
	};
	static brickDefaultConfig = {};

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};

		const classes = classNames({
			'brick': true,
			'heading-big-brick': true,
			'v2': data.v === 2,
		});

		return (
			<Container
				size="large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes}>
					<FrontEditableText fieldName="heading" text={data.heading} set={this.props.set} />
				</div>
			</Container>
		);
	}
}

export default brickWrapper(HeadingBig);
