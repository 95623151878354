import store from 'businessLogic/store/store.js';
import * as CONST from './constants.js';
import {getEmail} from 'businessLogic/store/data/session';
import {getBrick} from './selectors';
import {
	mutationAddNewChildBrick,
	mutationCreateBrick,
	mutationCreatePill,
	mutationDeletePill,
} from './graphql/mutations';
import {
	getBrickDefaultData,
	getBrickDefaultConfig,
	getBrickDefaultStyles,
} from 'businessLogic/core/bricks/bricks';

//Action launchers
//Aquí se definen todas las acciones que lanzarán "dispatch" para modificar el store
//Estas acciones son el interfaz que tienen los componentes para modificar el store
//Ejemplo
// export function createPill(dataPill) {
// 	store.dispatch({type: CONST.CREATE_PILL, dataPill});
// }

//Generador de ids locales provisional, hay que hacer uno global conectado al store
let lastPillId = 1;

// export function createPill(newPill) {
// 	//Lanzamos la acción al store para que la incluya
// 	store.dispatch({type: CONST.CREATE_PILL, dataPayload: {newPill}});
// }
//Crea una nueva píldora y devuelve su id
export function createPill(name, description) {
	const pillId = ++lastPillId;
	//Creamos la nueva estructura de píldora
	const newPill = {
		id: pillId.toString(),
		name,
		description,
		content: {
			containerBrick: '1',
			bricks: [
				{
					id: '1',
					type: 'MainContainerBricks',
					children: [],
				},
			],
		},
	};
	mutationCreatePill({name, description});

	//Lanzamos la acción al store para que la incluya
	store.dispatch({type: CONST.CREATE_PILL, dataPayload: {newPill}});

	//Devolvemos el nuevo id de píldora (Debería ser)
	return pillId.toString();
}

export function updatePill(pillId, pillData) {
	store.dispatch({type: CONST.UPDATE_PILL, dataPayload: {pillId, pillData}});
}

//Borra una píldora a partir de su id
export function deletePill(pillId) {
	//Lanzamos la acción al store para que la borre
	const state = store.getState();
	const owner = getEmail(state);
	mutationDeletePill(pillId, owner);
	store.dispatch({type: CONST.DELETE_PILL, dataPayload: {pillId}});
}

function generateBrickId(pillId) {
	const state = store.getState();
	let newBrickId = `${Math.floor(Math.random() * 100000)}`;
	let existBrick = getBrick(state, pillId, newBrickId);
	while (existBrick !== undefined) {
		newBrickId = `${Math.floor(Math.random() * 100000)}`;
		existBrick = getBrick(state, pillId, newBrickId);
	}
	return newBrickId;
}

//Crea un nuevo ladrillo dentro de una píldora y devuelve su id
export function createBrick(
	pillId,
	brickType,
	brickConfig = null,
	brickData = null,
	brickStyles = null,
	brickChildren = {default: []},
) {
	const brickId = generateBrickId(pillId);
	mutationCreateBrick(pillId, brickId, brickType, brickConfig, brickData, brickChildren);
	const newBrick = {
		id: brickId.toString(),
		type: brickType,
		config: brickConfig ? brickConfig : getBrickDefaultConfig(brickType),
		data: brickData ? brickData : getBrickDefaultData(brickType),
		styles: brickStyles ? brickStyles : getBrickDefaultStyles(brickType),
		children: brickChildren,
	};
	store.dispatch({type: CONST.CREATE_BRICK, dataPayload: {pillId, newBrick}});
	return brickId.toString();
}

//Crea un nuevo ladrillo dentro de una píldora, y lo añade como hijo de otro ladrillo. Devuelve el id del nuevo ladrillo.
export function addNewChildBrick(
	pillId,
	brickId,
	brickType,
	index = -1,
	childList = 'default',
	brickConfig = null,
	brickData = null,
	brickStyles = null,
	brickChildren = {default: []},
) {
	let newBrickId = generateBrickId(pillId);

	const newBrick = {
		id: newBrickId,
		type: brickType,
		parentId: brickId,
		config: brickConfig ? brickConfig : getBrickDefaultConfig(brickType),
		data: brickData ? brickData : getBrickDefaultData(brickType),
		styles: brickStyles ? brickStyles : getBrickDefaultStyles(brickType),
		children: brickChildren,
	};
	mutationAddNewChildBrick(
		pillId,
		brickId,
		newBrick.id,
		newBrick.type,
		index,
		newBrick.config,
		newBrick.data,
		newBrick.styles,
		newBrick.children,
		childList,
	);
	store.dispatch({
		type: CONST.ADD_CHILD_BRICK,
		dataPayload: {pillId, brickId, newBrick, index, childList},
	});
	return newBrickId;
}

export async function addNewChildBrickAsync(
	pillId,
	brickId,
	brickType,
	index = -1,
	childList = 'default',
	brickConfig = null,
	brickData = null,
	brickStyles = null,
	brickChildren = {default: []},
) {
	let newBrickId = generateBrickId(pillId);

	const newBrick = {
		id: newBrickId,
		type: brickType,
		parentId: brickId,
		config: brickConfig ? brickConfig : getBrickDefaultConfig(brickType),
		data: brickData ? brickData : getBrickDefaultData(brickType),
		styles: brickStyles ? brickStyles : getBrickDefaultStyles(brickType),
		children: brickChildren,
	};
	await mutationAddNewChildBrick(
		pillId,
		brickId,
		newBrick.id,
		newBrick.type,
		index,
		newBrick.config,
		newBrick.data,
		newBrick.styles,
		newBrick.children,
		childList,
	);
	store.dispatch({
		type: CONST.ADD_CHILD_BRICK,
		dataPayload: {pillId, brickId, newBrick, index, childList},
	});
	return newBrickId;
}

export function updateBrick(pillId, brick) {
	store.dispatch({type: CONST.UPDATE_BRICK, dataPayload: {pillId, brick}});
}

export function deleteBrick(pillId, brickId) {
	store.dispatch({type: CONST.DELETE_BRICK, dataPayload: {pillId, brickId}});
}

//El parámetro flatPillList indica si queremos que las píldoras anidadas dentro de otras píldoras
//pasen a primer nivel, de manera que puedan ser encontradas por BrickSwitch, que solo lee de primer nivel
export function initialState(initialState, flatPillList = false) {
	store.dispatch({type: CONST.INITIAL_STATE, dataPayload: {initialState, flatPillList}});
}
