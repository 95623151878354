import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import VideoTimer from '../VideoTimer';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

class VideoImage extends PureComponent {
	render() {
		const {description, credit, videoState, videoTime} = this.props;
		const videoImageClasses = classNames({
			'video-caption': true,
			'video-caption--show': videoState,
			'video-caption--hide': !videoState
		});
		return (
			<div className={videoImageClasses}>
				<div className="video-description"> {description} </div>
				{credit && <div className="video-credits"> {credit} </div>}
				<VideoTimer time={videoTime} />
			</div>
		);
	}
}

VideoImage.propTypes = {
	className: PropTypes.string,
	image: PropTypes.string,
	onClick: PropTypes.func,
	videoState: PropTypes.bool
};

export default brickWrapper(VideoImage);
