import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextEditor from 'businessLogic/shared/Text/TextEditor';
import LabelForInput from 'businessLogic/shared/Input/LabelForInput';
import Input from 'ui/shared/FormFields/Input';
import EditImageBox from 'businessLogic/shared/Image/EditImageBox';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';

import './styles.scss';

class EditImageSubmenu extends Component {
	handleDelete = () => {
		if (this.props.onDelete) {
			this.props.onDelete();
		}
	};
	handleReplace = () => {
		if (this.props.onReplace) {
			this.props.onReplace();
		}
	};
	handleEdit = () => {
		if (this.props.onEdit) {
			this.props.onEdit();
		}
	};
	handleMainButton = () => {
		if (this.props.onClickMainButton) {
			this.props.onClickMainButton();
		}
	};
	//Función que actualiza el store ante cada cambio en algún input de texto
	onChangeCaptionText = (text, delta) => {
		this.props.onChangeCaptionText(text, delta);
	};
	render() {
		const {
			className,
			srcImage,
			onDelete,
			onReplace,
			onEdit,
			onClickMainButton,
			roundMainButton,
			onChangeCenterPosition,
			valueCenterPosition,
			index,
			canAccessEdit,
			//para los input del footer

			valueCredit,
			onChangeCredit,

			valueFooter,
			onChangeFooter,

			valueCaptionTitle,
			onChangeCaptionTitle,

			valueCaptionText,
			showCompleteImage,
		} = this.props;
		const classes = classNames({
			'edit-image-submenu': true,
			'edit-image-submenu--delete': onDelete,
			'edit-image-submenu--replace': onReplace,
			'edit-image-submenu--edit': onEdit,
			'edit-image-submenu--main-button': onClickMainButton,
			'edit-image-submenu--round-main-button': roundMainButton,

			[`${className}`]: true,
		});
		return (
			<div className={classes}>
				<div className="top-info">
					<div className="image">
						<EditImageBox
							srcImage={srcImage}
							onDelete={onDelete}
							onReplace={onReplace}
							onClickMainButton={onClickMainButton}
							small={true}
							handleChangeCenterPosition={onChangeCenterPosition}
							valueCenterPosition={valueCenterPosition}
							editable={this.props.editable}
							canAccessEdit={canAccessEdit}
							showCompleteImage={showCompleteImage}
							index={index}
						/>
					</div>
					{valueFooter !== undefined ||
						valueCredit !== undefined ||
						(valueCaptionTitle !== undefined && (
							<ul className="image-info">
								{valueFooter !== undefined && (
									<li className="img-footer">
										<LabelForInput text={t`Pie de foto:`} />
										<Input
											name="pie-foto"
											type="text"
											placeholder={t`Agrega un pie de foto`}
											value={valueFooter}
											onChange={onChangeFooter}
										/>
									</li>
								)}
								{valueCredit !== undefined && (
									<li className="img-credit">
										<LabelForInput text={t`Crédito:`} />
										<Input
											name="credito-foto"
											type="text"
											placeholder={t`Introduce el crédito`}
											value={valueCredit}
											onChange={onChangeCredit}
										/>
									</li>
								)}
								{valueCaptionTitle !== undefined && (
									<li className="img-caption-title">
										<LabelForInput text={t`Título:`} />
										<Input
											name="titulo-foto"
											type="text"
											placeholder={t`Agrega el título de la imagen`}
											value={valueCaptionTitle}
											onChange={onChangeCaptionTitle}
										/>
									</li>
								)}
							</ul>
						))}
				</div>
				<div className="bottom-info">
					{valueCaptionText !== undefined && (
						<div className="img-caption-text">
							<LabelForInput text={t`Texto:`} />
							<TextEditor
								text={valueCaptionText || t`Agrega un texto a la imagen`}
								reducedVersion={true}
								onChange={(text, delta) => {
									this.onChangeCaptionText(text, delta);
								}}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}

EditImageSubmenu.propTypes = {
	srcImage: PropTypes.string,
	onDelete: PropTypes.func,
	onReplace: PropTypes.func,
	onEdit: PropTypes.func,
	onClickMainButton: PropTypes.func,
	editable: PropTypes.bool,
	canAccessEdit: PropTypes.bool,
	valueCredit: PropTypes.string,
	onChangeCredit: PropTypes.func,

	valueFooter: PropTypes.string,
	onChangeFooter: PropTypes.func,
};

EditImageSubmenu.defaultProps = {
	srcImage: '',
	onDelete: null,
	onReplace: null,
	onEdit: null,
	onClickMainButton: null,
	editable: false,
	canAccessEdit: true,
};

export default EditImageSubmenu;
