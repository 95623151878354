import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import classNames from 'classnames';
import {t, jt} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class SimpleTestIntro extends PureComponent {
	render() {
		const classes = classNames({
			'simple-test-basic-brick__intro': true,
		});
		const textValueSuccess = (
			<React.Fragment>
				<span className="score--success"> {this.props.valueSuccess}</span> {t`puntos`}
			</React.Fragment>
		);
		const textValuefailure = (
			<React.Fragment>
				<span className="score--failure"> {this.props.valueFailure}</span>
				{t`puntos`}
			</React.Fragment>
		);
		return (
			<div className={classes}>
				<div className="simple-test-basic-brick__intro__description">
					<FrontEditableText
						className="description-test-basic"
						fieldName="descriptionTest"
						text={this.props.textDescription}
						set={this.props.set}
					/>
				</div>

				{this.props.valueSuccess !== 0 ||
					(typeof this.props.valueSuccess !== 'undefined' && (
						<div className="simple-test-basic-brick__intro__descriptionScore">
							<p>{jt`Valor de cada pregunta acertada:${textValueSuccess}`}</p>
							{this.props.valueFailure !== 0 && (
								<p>{jt`Puntos descontados por cada fallo: ${textValuefailure}`}</p>
							)}
						</div>
					))}
			</div>
		);
	}
}

SimpleTestIntro.propTypes = {
	textTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	textDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	textButton: PropTypes.string,
	valueSuccess: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	valueFailure: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onClick: PropTypes.func,
	set: PropTypes.func,
};

export default SimpleTestIntro;
