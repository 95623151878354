import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import ImageMapper from 'react-image-mapper';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import './styles.scss';

class Coordinates extends PureComponent {
	static getDefaultValue = (editionBrickStruct) => {
		let defaultValue = 0;
		if (editionBrickStruct.defaultValue) {
			defaultValue = editionBrickStruct.defaultValue;
		}

		return defaultValue;
	};

	constructor(props) {
		super(props);
		this.state = {naturalImgWidth: 0};
	}

	componentDidMount() {
		this.setState({naturalImgWidth: this.refs.forImageMapperSize.clientWidth});
	}

	clickedOutside = (capturedPosition) => {
		const radiusSmallSpot = 11;
		const radiusBigSpot = 23;
		const positionX = capturedPosition.nativeEvent.layerX;
		const positionY = capturedPosition.nativeEvent.layerY;
		const imgClientWidth = this.refs.forImgSize.img.clientWidth;
		const imgClientHeight = this.refs.forImgSize.img.clientHeight;
		const percentagePositionX = ((positionX + radiusSmallSpot) * 100) / imgClientWidth;
		const percentagePositionY = ((positionY + radiusSmallSpot) * 100) / imgClientHeight;

		// calculando el porcentage del radio de la esfera para restarselo al porcentaje de la imagen total para que salga centrado
		const percentageSpotRadiusX = (radiusBigSpot * 100) / imgClientWidth;
		const percentageSpotRadiusY = (radiusBigSpot * 100) / imgClientHeight;

		// pasar los dos valores (que son porcentajes en la funcion setMainField({x,y})) >> x: capturedPosition.nativeEvent.layerX
		this.props.setMainField({
			x: percentagePositionX - percentageSpotRadiusX,
			y: percentagePositionY - percentageSpotRadiusY,
		});
	};

	render() {
		let coordinatesX = this.props.getMainField().x || 0;
		let coordinatesY = this.props.getMainField().y || 0;

		return (
			<div className="edition-brick-coordinates">
				<span className="edition-brick-coordinates__image-info-text">
					{t`Haz clic en el lugar exacto de la imagen donde quieras situar el punto caliente`}
				</span>
				<div className="edition-brick-coordinates__image-mapper" ref="forImageMapperSize">
					<ImageMapper
						src={this.props.editionBrickData.data.image.imageSrc}
						width={this.state.naturalImgWidth}
						onImageClick={(capturedPosition) => this.clickedOutside(capturedPosition)}
						ref="forImgSize"
					/>
					{coordinatesX !== 0 && coordinatesY !== 0 && (
						<span
							className="edition-brick-coordinates__image-mapper__marker-circle"
							style={{top: coordinatesY + '%', left: coordinatesX + '%'}}
						/>
					)}
				</div>
			</div>
		);
	}
}

export default editionBrickWrapper(Coordinates);
