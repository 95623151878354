/* eslint-disable no-console */
import {client} from 'businessLogic/store/store';
import {GET_USER_DATA} from './query';
import {initialDataSession} from '../actions';
import Logger from 'businessLogic/services/Logger';

export function queryGetDataUser() {
	return client
		.query({
			query: GET_USER_DATA,
			fetchPolicy: process.env.REACT_APP_EVENTS_API === 'scorm' ? 'cache-only' : 'network-only',
		})
		.then(async ({data}) => {
			initialDataSession(data.dataUser);
			client.writeQuery({query: GET_USER_DATA, data: data});
			return true;
		})
		.catch((error) => Logger.captureException(error));
}
