import React, {PureComponent} from 'react';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import YouTubeDefault from './components/YouTubeDefault';
import YouTubeBackground from './components/YouTubeBackground';
import YouTubeForIE11 from './components/YouTubeForIE11';
import PropTypes from 'prop-types';
import {getYoutubeId} from 'businessLogic/helpers/functions';
import './styles.scss';

class VideoYouTube extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			viewImageVideo: true,
			videoTime: '--:--',
			player: null,
			paused: true,
			videoDimension: {
				height: 0,
				width: 0,
			},
		};
		// Internet Explorer 6-11
		this.isIE = /*@cc_on!@*/ false || !!document.documentMode;
	}

	componentDidMount() {
		let containerVideo = this.refs.videoContainer;
		this.setState({
			videoDimension: {
				height: containerVideo.offsetHeight,
				width: containerVideo.offsetWidth,
			},
		});
	}

	render() {
		const {videoCaption, description, credit, video, videoLoop, onPlay, onPause, onEnd} =
			this.props;
		let youtubeID = getYoutubeId(video);

		return (
			<div className="video-youtube-container" ref="videoContainer">
				<div
					className={
						'video-youtube-container__video-embed' +
						(this.props.autoPlay ? ' background ' : ' default ')
					}
				>
					{!this.isIE && (
						<React.Fragment>
							{this.props.autoPlay === false ||
								(this.props.autoPlay === undefined && (
									<YouTubeDefault
										video={youtubeID}
										videoCaption={videoCaption}
										description={description}
										credit={credit}
										controls={true}
										onPlay={onPlay}
										onPause={onPause}
										onEnd={onEnd}
									/>
								))}
							{this.props.autoPlay === true && (
								<YouTubeBackground
									video={youtubeID}
									paused={this.state.paused}
									loop={videoLoop}
									muted={this.props.muted}
								/>
							)}
						</React.Fragment>
					)}
					{this.isIE && (
						<YouTubeForIE11 youtubeID={youtubeID} dimensions={this.state.videoDimension} />
					)}
				</div>
			</div>
		);
	}
}

VideoYouTube.propTypes = {
	videoCaption: PropTypes.string,
	description: PropTypes.string,
	credit: PropTypes.string,
	video: PropTypes.string,
	controls: PropTypes.bool,
	onPlay: PropTypes.func,
	onPause: PropTypes.func,
	onEnd: PropTypes.func,
};

VideoYouTube.defaultProps = {
	videoCaption: '',
	description: '',
	credit: '',
	video: '',
	controls: true,
	onPlay: () => {},
	onPause: () => {},
	onEnd: () => {},
};

export default brickWrapper(VideoYouTube);
