import * as CONST from './constants.js';
import get from 'lodash/get';
//Funciones que devuelven partes del store, partiendo del store global

// Obtiene el token
export const getToken = (state) => get(state, ['dataGeneral', 'session', CONST.AUTH_TOKEN]);

// Obtiene los datos de los usuarios
export const getDataUser = (state) => get(state, ['dataGeneral', 'session', 'dataUser']);

// Obtiene el email del usuario
export const getEmail = (state) => get(state, ['dataGeneral', 'session', 'dataUser', 'email']);

export const isSuperAdmin = (state) =>
	get(state, ['dataGeneral', 'session', 'dataUser', 'superAdmin'], false);

export const isAdminAs = (state) =>
	get(state, ['dataGeneral', 'session', 'dataUser', 'adminAsUser'], false) ||
	get(state, ['dataGeneral', 'session', 'dataUser', 'adminAsGroup'], false);

// Comprueba si el usuario está logado
export const isLogged = (state) => {
	if (process.env.REACT_APP_EVENTS_API === 'scorm') {
		return true;
	}
	return !!get(state, ['dataGeneral', 'session', 'dataUser'], false);
};
