import React, {PureComponent} from 'react';
import editionBrickWrapper from '../editionBrickWrapper';
import ImageSelectorDialog from 'businessLogic/shared/MediaSelector/ImageSelectorDialog';
import ColorSelector from 'businessLogic/shared/Selects/ColorSelector';
import EditImageSubmenu from 'businessLogic/scope/admin/shared/Image/EditImageSubmenu';
import ThemeSelector from 'businessLogic/scope/admin/shared/Settings/ThemeSelector';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import get from 'lodash/get';
import './styles.scss';

class ContainerStyle extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	static getDefaultValue = () => {
		const defaultValue = {
			paddingTop: 0,
			paddingBottom: 0,
			imageBg: '',
			colorBg: false,
			colorLayerOpacity: false,
			colorText: false,
		};

		return defaultValue;
	};

	handleChangePaddingTop = (event) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'paddingTop'], event.target.value);
	};

	handleChangePaddingBottom = (event) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'paddingBottom'], event.target.value);
	};

	handleDeleteImageBackground = () => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'imageBg'], '');
	};

	handleClickReplaceImageBackground = () => {
		this.refs.selectorDialog.show();
	};

	handleNewImageBackground = (imageSrc) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'imageBg'], imageSrc);
	};

	handleChangeColorBackground = (color) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'colorBg'], color);
	};

	handleChangeThemeSelected = (theme) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'themeSelected'], theme);
	};

	handleChangeColorLayerOpacity = (color) => {
		this.props.set([this.props.editionBrickStruct.fieldName, 'colorLayerOpacity'], color);
	};

	render() {
		const editionBrickStruct = this.props.editionBrickStruct;
		const showPadding =
			editionBrickStruct.padding !== undefined ? editionBrickStruct.padding : true;

		const containerStyles = this.props.getMainField();
		const imageBg = get(containerStyles, 'imageBg', '');
		const colorBg = get(containerStyles, 'colorBg', '');
		const colorLayerOpacity = get(containerStyles, 'colorLayerOpacity', '');
		const paddingTop = get(containerStyles, 'paddingTop', '');
		const paddingBottom = get(containerStyles, 'paddingBottom', '');
		const themeSelected = get(containerStyles, 'themeSelected', '');

		return (
			<div className="default-styles-options">
				<ul className="list-styles-ul">
					<li>
						<label className="item-title">{t`Color de fondo`}</label>
						<ColorSelector
							color={colorBg}
							onSelectColor={this.handleChangeColorBackground}
							type="background-color"
						/>
					</li>
					<li>
						<label className="item-title">{t`Imagen de fondo`}</label>
						<EditImageSubmenu
							srcImage={imageBg}
							onDelete={this.handleDeleteImageBackground}
							onReplace={this.handleClickReplaceImageBackground}
						/>
					</li>
					<li>
						<label className="item-title">{t`Capa de velado`}</label>
						<ColorSelector
							color={colorLayerOpacity}
							onSelectColor={this.handleChangeColorLayerOpacity}
							type="layer-opacity-color"
						/>
					</li>
				</ul>
				{showPadding && (
					<ul className="ul-bottom">
						<li>
							<div className="wrap-item">
								<label className="item-title padding-item">{t`Padding superior`}</label>
								<input
									type="number"
									name="paddingTop"
									value={paddingTop}
									step="5"
									placeholder={t`Aumenta o disminuye el padding`}
									onChange={this.handleChangePaddingTop}
								/>
							</div>
						</li>
						<li>
							<div className="wrap-item">
								<label className="item-title padding-item">{t`Padding inferior`}</label>
								<input
									type="number"
									name="paddingBottom"
									value={paddingBottom}
									step="5"
									placeholder={t`Aumenta o disminuye el padding`}
									onChange={this.handleChangePaddingBottom}
								/>
							</div>
						</li>
					</ul>
				)}

				<ThemeSelector
					label={t`Tema`}
					onSelectTheme={this.handleChangeThemeSelected}
					active={themeSelected}
				/>
				{/* </Dialog> */}
				<ImageSelectorDialog ref="selectorDialog" onSelect={this.handleNewImageBackground} />
			</div>
		);
	}
}

export default editionBrickWrapper(ContainerStyle);
