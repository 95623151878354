import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';

const StyledToggleButton = ({format, children, selected, disabled, onClick, sx}) => {
	return (
		<ToggleButton
			value={format || ''}
			onMouseDown={(e) => {
				// prevent toolbar from taking focus away from editor
				e.preventDefault();
			}}
			onClick={onClick}
			selected={selected}
			disabled={disabled}
			sx={{
				'&:hover': {
					backgroundColor: '#ebebeb',
				},
				'&:focus': {
					backgroundColor: '#ebebeb',
				},
				'&:active': {
					backgroundColor: '#ebebeb',
				},
				'&:disabled': {
					border: 'none',
				},
				'border': 'none',
				'borderRadius': 0,
				'padding': '7px 7px',
				...sx,
			}}
		>
			{children}
		</ToggleButton>
	);
};

export default StyledToggleButton;
