import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import Form from './components/Form';
import NewImageClickable from 'businessLogic/core/shared/Image/NewImageClickable';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import './styles.scss';

class ShowInfoContactForm extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Formulario de contacto`;
	static brickIcon = 'image';
	static brickHelpText = () => t`Permite que el usuario se comunique con una dirección de email.`;
	static brickImage = brickImage;
	static brickDefaultData = () => ({
		images: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/csDefault1Opt.svg',
					credit: '',
				},
			},
		],
		titleContactForm: _('¿Tienes alguna pregunta?'),
		subtitleContactForm: _('Mándanos todas tus dudas'),
		textContactForm: _('Añade tu comentario'),
		buttonContactForm: _('Enviar'),
	});

	render() {
		const data = this.props.brick.data;
		const styles = this.props.brick.styles || {};
		const images = data.images || [];

		const classes = classNames({
			'brick': true,
			'show-info-form-contact': true,
		});

		return (
			<Container
				size="extra-large"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes + ' row'}>
					{window.innerWidth <= 768 && (
						<React.Fragment>
							<NewImageClickable
								imgObj={images[0].imageObj}
								clickable={false}
								defaultVisible={true}
							/>
							<Form data={data} />
						</React.Fragment>
					)}
					{window.innerWidth > 768 && (
						<div className={classes + '__tablet--padding col-xs-12 col-sm-12 col-md-12'}>
							<div className="col-sm-offset-1">
								<div className="col-xs-4 col-sm-4 col-md-4">
									<Form data={data} />
								</div>
								<div className={classes + '__imgPosition col-sm-offset-3 col-sm-8'}>
									<NewImageClickable
										clickable={false}
										defaultVisible={true}
										imgObj={images[0].imageObj} // cambiar el valor que pasamos
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</Container>
		);
	}
}

export default brickWrapper(ShowInfoContactForm);
