import React from 'react';
import {useSlate} from 'slate-react';
import ToggleButton from '../basics/ToggleButton';

import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';

const Icon = ({step}) => {
	if (step > 0) {
		return <FormatIndentIncreaseIcon />;
	}

	return <FormatIndentDecreaseIcon />;
};

const IncreaseIndentButton = ({step = 1}) => {
	const editor = useSlate();

	const {setBlock, getBlockFormat} = editor.helpers;

	const indent = getBlockFormat(editor, 'indent') || 0;

	const isActive = false;

	return (
		<>
			<ToggleButton
				format={'indent'}
				onClick={(event) => {
					event.preventDefault();
					event.stopPropagation();
					setBlock(editor, 'indent', indent + step);
				}}
				selected={isActive}
				disabled={indent + step < 0 || indent + step > 6}
			>
				<Icon
					step={step}
					// sx={{
					// 	color: isActive ? color.hex : 'inherit',
					// }}
				/>
			</ToggleButton>
		</>
	);
};

export default IncreaseIndentButton;
