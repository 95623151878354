import React, {Component} from 'react';
import ButtonInTextBar from '../ButtonInTextBar';
import Separator from '../Separator';
import {BlockPicker} from 'react-color';
import './styles.scss';



class ToolBar extends Component {
	constructor(props) {
		super(props);
		this.highlightColorPicker = this.highlightColorPicker.bind(this);
		this.textColorPicker = this.textColorPicker.bind(this);
	}
	componentDidMount() {
		this.highlightColorPicker();
		this.textColorPicker();
	}
	highlightColorPicker() {
		const buttonHighlight = this.refs.buttonHighlight.querySelector('.text-bar-button');
		const pickerHighlight = this.refs.buttonHighlight.querySelector('.block-picker');
		const pickerTextColor = this.refs.buttonTextColor.querySelector('.block-picker');
		const pickerBlockPicker = this.refs.blockPickerHighlight.querySelector('.block-picker');
		buttonHighlight.addEventListener('click', function() {
			pickerHighlight.classList.toggle('show-picker');
			pickerTextColor.classList.remove('show-picker');
		});
		pickerBlockPicker.addEventListener('click', function() {
			pickerHighlight.classList.toggle('show-picker');
			pickerTextColor.classList.remove('show-picker');
		});
	}

	textColorPicker() {
		const buttonTextColor = this.refs.buttonTextColor.querySelector('.text-bar-button');
		const pickerTextColor = this.refs.buttonTextColor.querySelector('.block-picker');
		const pickerBlockPicker = this.refs.blockPickerTextColor.querySelector('.block-picker');
		const pickerHighlight = this.refs.buttonHighlight.querySelector('.block-picker');
		buttonTextColor.addEventListener('click', function() {
			pickerTextColor.classList.toggle('show-picker');
			pickerHighlight.classList.remove('show-picker');
		});
		pickerBlockPicker.addEventListener('click', function() {
			pickerTextColor.classList.toggle('show-picker');
			pickerHighlight.classList.remove('show-picker');
		});
	}
	headingSelect = () => {
		this.refs.headingSelect.classList.toggle('show-select');
	};
	render() {
		const {_thisTextEditor} = this.props;


		const textColorsPalette = [
			'#4D4D4D',
			'#999999',
			'#F44E3B',
			'#FE9200',
			'#FCDC00',
			'#DBDF00',
			'#A4DD00',
			'#68CCCA',
			'#73D8FF',
			'#AEA1FF',
			'#FDA1FF',
			'#333333',
			'#808080',
			'#cccccc',
			'#D33115',
			'#E27300',
			'#FCC400',
			'#B0BC00',
			'#68BC00',
			'#16A5A5',
			'#009CE0',
			'#7B64FF',
			'#FA28FF',
			'#000000',
			'#666666',
			'#B3B3B3',
			'#9F0500',
			'#C45100',
			'#FB9E00',
			'#808900',
			'#194D33',
			'#0C797D',
			'#0062B1',
			'#653294',
			'#AB149E'
		];
		return (
			<div className="text-editor-toolbar" id={_thisTextEditor.randomID}>
				<button className="te-clean-button" onClick={_thisTextEditor.textBold}>
					<ButtonInTextBar iconName="text-bold" active={_thisTextEditor.state.format.bold} />
				</button>
				<button className="te-clean-button" onClick={_thisTextEditor.textItalic}>
					<ButtonInTextBar iconName="text-italic" active={_thisTextEditor.state.format.italic} />
				</button>
				<button className="te-clean-button" onClick={_thisTextEditor.textUnderline}>
					<ButtonInTextBar
						iconName="text-underline"
						active={_thisTextEditor.state.format.underline}
					/>
				</button>
				<Separator />
				<button className="te-clean-button" onClick={_thisTextEditor.alignLeft}>
					<ButtonInTextBar
						iconName="align-left"
						active={_thisTextEditor.state.format.align === ''}
					/>
				</button>
				<button className="te-clean-button" onClick={_thisTextEditor.alignCenter}>
					<ButtonInTextBar
						iconName="align-center"
						active={_thisTextEditor.state.format.align === 'center'}
					/>
				</button>
				<button className="te-clean-button" onClick={_thisTextEditor.alignRight}>
					<ButtonInTextBar
						iconName="align-right"
						active={_thisTextEditor.state.format.align === 'right'}
					/>
				</button>
				<button className="te-clean-button" onClick={_thisTextEditor.alignJustify}>
					<ButtonInTextBar
						iconName="align-justify"
						active={_thisTextEditor.state.format.align === 'justify'}
					/>
				</button>
				<Separator />
				<button className="te-clean-button" onClick={_thisTextEditor.listOrdered}>
					<ButtonInTextBar
						iconName="numeric-list"
						active={_thisTextEditor.state.format.list === 'ordered'}
					/>
				</button>
				<button className="te-clean-button" onClick={_thisTextEditor.listBullets}>
					<ButtonInTextBar
						iconName="symbol-list"
						active={_thisTextEditor.state.format.list === 'bullets'}
					/>
				</button>
				<Separator />
				<button className="ql-link te-clean-button" onClick={_thisTextEditor.linkHandler}>
					<ButtonInTextBar
						iconName="text-link"
						active={_thisTextEditor.state.format.link === 'link'}
					/>
				</button>
				<button className="wiki te-clean-button" onClick={_thisTextEditor.wikiHandler}>
					<ButtonInTextBar iconName="text-wikipedia" active={_thisTextEditor.state.format.wiki} />
				</button>
				<button
					className="btn-dropdown-picker highlight-picker te-clean-button"
					ref="buttonHighlight"
					value={_thisTextEditor.state.background}
				>
					<ButtonInTextBar iconName="text-highlight" />
					<div ref="blockPickerHighlight">
						<BlockPicker
							triangle="top"
							onChangeComplete={_thisTextEditor.handleChangeBackgroundColor}
							color={_thisTextEditor.state.background}
						/>
					</div>
				</button>
				<Separator />
				<button
					className="btn-dropdown-picker color-picker te-clean-button"
					ref="buttonTextColor"
					value={_thisTextEditor.state.color}
				>
					<ButtonInTextBar iconName="text-color" />
					<div ref="blockPickerTextColor">
						<BlockPicker
							triangle="top"
							onChangeComplete={_thisTextEditor.handleChangeTextColor}
							color={_thisTextEditor.state.color}
							colors={textColorsPalette}
						/>
					</div>
				</button>
				<Separator />
				<button className="ql-clean te-clean-button">
					<ButtonInTextBar iconName="edit" />
				</button>
			</div>
		);
	}
}

export default ToolBar;
