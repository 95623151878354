import React, {PureComponent} from 'react';
import Logger from 'businessLogic/services/Logger';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import VideoImage from 'businessLogic/shared/Video/VideoImage';
import VideoCaption from 'businessLogic/shared/Video/VideoCaption';
import PropTypes from 'prop-types';
import './styles.scss';

class VerticalVideoUploaded extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			viewImageVideo: true,
			videoTime: '--:--',
			videoSrc: '',
		};

		this.videoRef = React.createRef();

		// En caso de scorm devolvemos el path original
		if (process.env.REACT_APP_EVENTS_API === 'scorm') {
			this.state.videoSrc = props.video;
		} else {
			// Si no es scorm, solicitamos la url
			this.getVideoUrl();
		}
	}

	playVideo = () => {
		const videoPlayer = this.videoRef.current;
		if (videoPlayer) {
			videoPlayer.play().catch((err) => {
				Logger.captureException(err);
			});
		}
	};

	handleClickVideo = () => {
		this.setState({
			viewImageVideo: false,
		});
		try {
			this.playVideo();
		} catch (e) {
			Logger.captureException(e, (scope) => {
				scope.setContext('video', {src: this.props.video});
				return scope;
			});
		}
	};

	componentDidMount() {
		this.calculateTotalTime();
		if (window.innerWidth < 1024 && this.props.autoPlay === true) {
			this.playVideo();
		}
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	async getVideoUrl() {
		// En caso de scorm devolvemos el path original
		if (process.env.REACT_APP_EVENTS_API === 'scorm') {
			this.setState({videoSrc: this.props.video});
			return;
		}

		const response = await fetch('/getDownloadLink', {
			method: 'post',
			credentials: 'same-origin',
			mode: 'same-origin',
			body: JSON.stringify({path: this.props.video}),

			headers: {
				'Content-Type': 'application/json',
			},
		});

		const responseJson = await response.json();

		if (responseJson.error === 0 && this._isMounted) {
			this.setState({videoSrc: responseJson.downloadLink});
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.autoPlay === true) {
			this.playVideo();
		}
		// if (window.innerWidth < 768 && this.props.autoPlay === true) {
		// 	this.playVideo();
		// }

		if (prevProps.video !== this.props.video) {
			this.getVideoUrl();
		}
	}

	calculateTotalTime = () => {
		const g_this = this;
		let videoPlayer = this.videoRef.current;
		if (videoPlayer) {
			videoPlayer.addEventListener(
				'loadedmetadata',
				function () {
					let duration = videoPlayer.duration;
					let minutes = Math.floor(duration / 60);
					if (minutes <= 10) {
						minutes = '0' + minutes;
					}
					let seconds_int = duration - minutes * 60;
					if (seconds_int <= 10) {
						seconds_int = '0' + seconds_int;
					}
					let seconds_str = seconds_int.toString();
					let seconds = seconds_str.substr(0, 2);
					let time = minutes + ':' + seconds;
					g_this.setState({
						videoTime: time,
					});
				},
				true,
			);
		}
	};

	render() {
		if (process.env.REACT_APP_EVENTS_API === 'scorm') {
			// eslint-disable-next-line no-console
			console.log('VerticalVideoUploaded', this.state.videoSrc, this.props.video);
		}
		return (
			<div className="vertical-video-uploaded-container" ref="videoContainer">
				{this.props.videoCaption && (
					<VideoCaption
						description={this.props.description}
						credit={this.props.credit}
						videoState={this.state.viewImageVideo}
						videoTime={this.state.videoTime}
					/>
				)}
				{this.props.videoCaption && (
					<VideoImage
						onClick={this.handleClickVideo}
						image={this.props.videoCaption}
						videoState={this.state.viewImageVideo}
					/>
				)}
				<div
					className={
						'vertical-video-uploaded-container__video-embed' +
						(this.props.autoPlay ? ' background ' : ' default ')
					}
				>
					<video
						width="100%"
						controls={this.props.controls}
						controlsList="nodownload"
						loop={this.props.videoLoop}
						muted={this.props.muted}
						ref={this.videoRef}
						key={this.state.videoSrc}
					>
						<source src={this.state.videoSrc} type="video/mp4" />
						Your browser does not support HTML5 video.
					</video>
				</div>
			</div>
		);
	}
}

VerticalVideoUploaded.propTypes = {
	videoCaption: PropTypes.string,
	description: PropTypes.string,
	credit: PropTypes.string,
	video: PropTypes.string,
	controls: PropTypes.bool,
};

VerticalVideoUploaded.defaultProps = {
	videoCaption: '',
	description: '',
	credit: '',
	video: '',
	controls: true,
};

export default brickWrapper(VerticalVideoUploaded);
