import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import get from 'lodash/get';
import NewImageCmp from 'businessLogic/shared/Image/NewImageCmp';
import Response from './components/Response';
import './styles.scss';

class SelectedChoiceQuestion extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			finished: false,
			response: undefined,
			feedback: '',
		};
		this.successes = 0;
		this.failures = 0;
		this.coefSuccess = 0;
	}
	handleOnchange = (event) => {
		const response = event.target.value;

		this.setState(() => {
			return {
				response,
			};
		}, this.sendDataQuestion);
	};

	sendDataQuestion = () => {
		this.props.onFinishQuestion(this.state.response);
	};
	render() {
		const mainStyleClass = 'selected-choice-test-question';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
		});
		const {
			content: {answers, statement, imgStatement, _id},
		} = this.props;
		return (
			<div className={classes}>
				<div className="row row--center--md row--nowrap--md">
					<div className="col-xs-12 col-md-6">
						<div className="row">
							<div className="col-xs-12 col-sm-10 col-sm-offset-1">
								<div className={mainStyleClass + '__statement'}>
									<FrontEditableText
										text={statement}
										fieldName="statement"
										set={(fieldPath, fieldData) => {
											//Propagamos la llamada al método set añadiendo mi nodo al path
											if (this.props.set)
												this.props.set(
													['flexibleContent', this.props.index].concat(fieldPath),
													fieldData,
												);
										}}
									/>
								</div>
							</div>
						</div>
						{imgStatement.imageSrc && (
							<div className="row">
								<div className="col-xs-12 col-sm-10 col-sm-offset-1 col-xl-8  col-xl-offset-2">
									<figure className={mainStyleClass + '__img-statement'}>
										<NewImageCmp
											image={imgStatement}
											imgWidths={640}
											defaultVisible={true}
										/>
									</figure>
								</div>
							</div>
						)}
					</div>
					<div className="col-xs-12 col-md-6">
						<div className="row">
							<div className="col-xs-12 col-sm-10 col-sm-offset-1 ">
								<div className={mainStyleClass + '__answers'}>
									<div className={mainStyleClass + '__fieldset'}>
										{answers.map((item, indexAnswer) => {
											const answerId = get(item, ['_id'], '');
											const submissions = () => {
												if (this.props.submissions) return this.props.submissions[_id];
											};
											return (
												<Response
													key={indexAnswer}
													name={_id}
													text={item.answer}
													value={answerId}
													onChange={this.handleOnchange}
													checked={
														!this.props.disable ?
															this.state.response === answerId.toString() :
															submissions() === answerId
													}
													disable={this.props.disable}
													valueSolution={
														this.props.disable ? item.valueSolution : undefined
													}
												/>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

SelectedChoiceQuestion.propTypes = {
	subtype: PropTypes.oneOf(['preferenceOrder', 'randomOrder']),
	content: PropTypes.object,
	index: PropTypes.number,
	onFinishQuestion: PropTypes.func,
	set: PropTypes.func,
	submissions: PropTypes.object,
	disable: PropTypes.bool,
};
SelectedChoiceQuestion.defaultProps = {
	subtype: 'randomOrder',
};
export default SelectedChoiceQuestion;
