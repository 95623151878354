import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Swiper from 'react-id-swiper';
import Container from 'businessLogic/core/shared/Container/Container';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import {imageUrl} from 'businessLogic/helpers/images';
import brickImage from './img/brickPreview.jpg';
import editionFields from './edit.js';
import './styles.scss';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import NavigationContext from 'businessLogic/contexts/NavigationContext';
import {getTitle, getText, getImage} from 'businessLogic/helpers/pillSelectorHelper';
import get from 'lodash/get';

class Cover extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Portada - estilo 1`;
	static brickIcon = 'cover';
	static brickImage = brickImage;

	static brickDefaultConfig = {
		delay: 5,
		transitionTime: 5,
		autoplay: true,
		loop: true,
	};

	static brickDefaultData = () => ({
		publicationTitle: _('Nombre de la publicación'),
		publicationImage: {
			imageSrc: '/uploads/default-images/csDefault1Opt.svg',
			credit: '',
		},
		publicationNumber: _('Número 1'),
		publicationSection: _('Sección 1'),
		images: [
			{
				pillLink: {
					pillId: '',
					usePillData: false,
					imageObj: {imageSrc: '/uploads/default-images/csDefault1Opt.svg', credit: ''},
					title: 'ROS EOS INIS NAM UTASE BANSTREA SREAS',
					text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
				},
			},
			{
				pillLink: {
					pillId: '',
					usePillData: false,
					imageObj: {imageSrc: '/uploads/default-images/csDefault2Opt.svg', credit: ''},
					title: 'ROS EOS INIS NAM UTASE BANSTREA SREAS',
					text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
				},
			},
			{
				pillLink: {
					pillId: '',
					usePillData: false,
					imageObj: {imageSrc: '/uploads/default-images/csDefault3Opt.svg', credit: ''},
					title: 'ROS EOS INIS NAM UTASE BANSTREA SREAS',
					text: 'Quis eost, officim sinverum vellant quam corrumque il moluptium ariscimod everios ducid maionsed magnimetur as imendit aut ea quatis ent duci si sequi.Ibus no.',
				},
			},
		],
		logos: [
			{
				imageObj: {
					imageSrc: '/uploads/default-images/logo-example.svg',
					credit: '',
				},
			},
		],
	});

	static brickDefaultStyles = {
		containerStyle: {
			colorLayerOpacity: {r: '0', g: '0', b: '0', a: '0.3'},
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
		};
		this.swiper = null;
	}

	changeActiveSlideContent = () => {
		if (this.props.brick.config.loop === false) {
			this.setState({
				activeIndex: this.swiper.activeIndex,
			});
		} else {
			let indexLoop = this.swiper.activeIndex - 1;
			if (this.swiper.activeIndex > this.props.brick.data.images.length) {
				let indexFixed = indexLoop - this.props.brick.data.images.length;
				this.setState({
					activeIndex: indexFixed,
				});
			} else if (indexLoop < 0) {
				let indexFixed = indexLoop + this.props.brick.data.images.length;
				this.setState({
					activeIndex: indexFixed,
				});
			} else {
				this.setState({
					activeIndex: indexLoop,
				});
			}
		}
	};

	render() {
		const data = this.props.brick.data;

		const config = this.props.brick.config;
		const styles = this.props.brick.styles || {};

		const images = data.images || [];
		const logos = data.logos || [];
		let delay = config.delay || 0;
		let transitionTime = config.transitionTime || 0;

		let autoPlayInfo;
		if (config.autoplay === false) {
			autoPlayInfo = false;
		} else {
			delay = delay * 1000;
			autoPlayInfo = {
				delay: delay,
				disableOnInteraction: false,
			};
		}
		let speedInfo;
		if (transitionTime === 0) {
			speedInfo = 300;
		} else {
			transitionTime = transitionTime * 1000;
			speedInfo = transitionTime;
		}

		//https://www.npmjs.com/package/react-id-swiper
		const params = {
			shouldSwiperUpdate: true, //si no no se vuelve a renderizar al añadir imágenes al carrusel
			rebuildOnUpdate: false, //se recontruye si se cambian parámetros (valueDelay) o se añaden elementos al carrusel
			autoplay: autoPlayInfo,
			speed: speedInfo,
			spaceBetween: 0,
			loop: config.loop,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' + (index + 1) + '</span>';
				},
			},
			getSwiper: (swiper) => {
				this.swiper = swiper;

				this.changeActiveSlideContent();
				this.swiper.on('slideChange', () => {
					this.changeActiveSlideContent();
				});
			},
		};
		const classes = classNames({
			brick: true,
			cover: true,
		});

		let elements = images.map((slide, index) => {
			return (
				<div className="cover__wrap__right__slider__item" key={index}>
					<NavigationContext.Consumer>
						{(contextObject) => (
							<span className={!slide.link ? 'no-link' : ''}>
								<div
									className="cover__wrap__right__slider__item__img"
									style={{
										backgroundImage: `url('${imageUrl(getImage(slide.pillLink).imageSrc)}')`,
									}}
									onClick={() => {
										if (slide.pillLink.pillId) contextObject.goToPill(slide.pillLink.pillId);
									}}
								>
									{getImage(slide.pillLink).credit !== '' && (
										<div className="cover__wrap__right__slider__item__img__credits">
											{getImage(slide.pillLink).credit}
										</div>
									)}
								</div>
								{(getTitle(slide.pillLink) !== '' || getText(slide.pillLink) !== '') && (
									<div className="cover__wrap__right__slider__item__caption inside-swiper">
										<div className="cover__wrap__right__slider__item__caption__title">
											<FrontEditableText
												fieldName="title"
												text={getTitle(slide.pillLink)}
												set={(fieldPath, fieldData) => {
													//Propagamos la llamada al método set añadiendo mi nodo al path
													if (this.props.set)
														this.props.set(
															['images', index, 'pillLink'].concat(fieldPath),
															fieldData,
														);
												}}
											/>
										</div>
										<div className="cover__wrap__right__slider__item__caption__text">
											<FrontEditableText
												fieldName="text"
												text={getText(slide.pillLink && !slide.pillLink.usePillData)}
												set={(fieldPath, fieldData) => {
													//Propagamos la llamada al método set añadiendo mi nodo al path
													if (this.props.set)
														this.props.set(
															['images', index, 'pillLink'].concat(fieldPath),
															fieldData,
														);
												}}
											/>
										</div>
										{(getTitle(slide.pillLink) !== '' || getText(slide.pillLink) !== '') && (
											<div className="cover__item__caption inside-swiper">
												<div
													className="cover__item__caption__title"
													onClick={() => {
														if (slide.pillLink.pillId)
															contextObject.goToPill(slide.pillLink.pillId);
													}}
												>
													<FrontEditableText
														fieldName="title"
														text={getTitle(slide.pillLink)}
														editable={!slide.pillLink.usePillData}
														set={(fieldPath, fieldData) => {
															//Propagamos la llamada al método set añadiendo mi nodo al path
															if (this.props.set)
																this.props.set(
																	['images', index, 'pillLink'].concat(fieldPath),
																	fieldData,
																);
														}}
													/>
												</div>
												<div
													className="cover__item__caption__text"
													onClick={() => {
														if (slide.pillLink.pillId)
															contextObject.goToPill(slide.pillLink.pillId);
													}}
												>
													<FrontEditableText
														fieldName="text"
														text={getText(slide.pillLink)}
														editable={!slide.pillLink.usePillData}
														set={(fieldPath, fieldData) => {
															//Propagamos la llamada al método set añadiendo mi nodo al path
															if (this.props.set)
																this.props.set(
																	['images', index, 'pillLink'].concat(fieldPath),
																	fieldData,
																);
														}}
													/>
												</div>
											</div>
										)}
									</div>
								)}
							</span>
						)}
					</NavigationContext.Consumer>
				</div>
			);
		});

		let logosElements = logos.map((logo, index) => {
			let img = <img src={imageUrl(logo.imageObj.imageSrc)} alt={logo.imageObj.credit} />;
			if (logo.link) {
				img = (
					<a target="_blank" rel="noreferrer" href={logo.link}>
						{img}
					</a>
				);
			}
			return (
				<li className="cover__wrap__right__logos__item" key={index}>
					{img}
				</li>
			);
		});

		let imgBg;
		if (styles) {
			if (styles.containerStyle) {
				if (styles.containerStyle.imageBg) {
					imgBg = true;
				} else {
					imgBg = false;
				}
			}
		}

		let stylesOverlay = {
			overlay: {},
		};
		const colorLayerOpacity = get(styles, ['containerStyle', 'colorLayerOpacity']);
		if (colorLayerOpacity)
			stylesOverlay.overlay.backgroundColor =
				'rgba(' +
				colorLayerOpacity.r +
				',' +
				colorLayerOpacity.g +
				',' +
				colorLayerOpacity.b +
				',' +
				colorLayerOpacity.a +
				')';
		if (colorLayerOpacity && colorLayerOpacity === true)
			stylesOverlay.overlay.backgroundColor = 'transparent';

		return (
			<Container
				size="full-width"
				styleConfig={styles.containerStyle}
				scrollEffect={styles.scrollEffect}
			>
				<div className={classes} ref="carouselContainer">
					<div className="cover__wrap">
						{imgBg && <div className="cover__wrap__mask" style={stylesOverlay.overlay} />}
						<div className="wrap-block">
							<div className="cover__wrap__content">
								<div className="cover__wrap__content__publication">
									<div className="cover__wrap__content__publication__title">
										<FrontEditableText
											fieldName="publicationTitle"
											text={data.publicationTitle}
											editable={false}
											set={this.props.set}
										/>
									</div>
								</div>
								<div className="cover__wrap__content__publication__info">
									<div className="cover__wrap__content__publication__info__number">
										<FrontEditableText
											fieldName="publicationNumber"
											text={data.publicationNumber}
											editable={false}
											set={this.props.set}
										/>
									</div>
									<div className="cover__wrap__content__publication__info__section">
										<FrontEditableText
											fieldName="publicationSection"
											text={data.publicationSection}
											editable={false}
											set={this.props.set}
										/>
									</div>
								</div>
								<div
									className="cover__wrap__content__publication__img"
									style={{
										backgroundImage: `url('${imageUrl(data.publicationImage.imageSrc)}')`,
									}}
								/>
								<div className="cover__wrap__content__caption">
									<NavigationContext.Consumer>
										{(contextObject) => (
											<div
												onClick={() => {
													if (images[this.state.activeIndex].pillLink.pillId)
														contextObject.goToPill(images[this.state.activeIndex].pillLink.pillId);
												}}
												className={
													images[this.state.activeIndex]?.pillLink?.pillId ? 'with-link' : ''
												}
											>
												<div className="cover__wrap__content__caption__title">
													<FrontEditableText
														fieldName="title"
														text={getTitle(images[this.state.activeIndex].pillLink)}
														editable={false}
														set={(fieldPath, fieldData) => {
															//Propagamos la llamada al método set añadiendo mi nodo al path
															if (this.props.set)
																this.props.set(
																	['images', this.state.activeIndex, 'pillLink'].concat(fieldPath),
																	fieldData,
																);
														}}
													/>
												</div>
												<div className="cover__wrap__content__caption__text">
													<FrontEditableText
														fieldName="text"
														text={getText(images[this.state.activeIndex].pillLink)}
														editable={false}
														set={(fieldPath, fieldData) => {
															//Propagamos la llamada al método set añadiendo mi nodo al path
															if (this.props.set)
																this.props.set(
																	['images', this.state.activeIndex, 'pillLink'].concat(fieldPath),
																	fieldData,
																);
														}}
													/>
												</div>
											</div>
										)}
									</NavigationContext.Consumer>
								</div>
							</div>
							<div className="cover__wrap__right">
								<div className="cover__wrap__right__slider">
									<Swiper {...params}>{elements}</Swiper>
								</div>
								<ul className="cover__wrap__right__logos">{logosElements}</ul>
							</div>
						</div>
					</div>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(Cover);
