import React from 'react';
import {useSlate} from 'slate-react';
import ToggleButton from '../basics/ToggleButton';

import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';

const Icon = ({step}) => {
	if (step >= 0) {
		return <TextIncreaseIcon />;
	}

	return <TextDecreaseIcon />;
};

const IncreaseFontButton = ({step = 0.1}) => {
	const editor = useSlate();

	const {getMark, setMark} = editor.helpers;

	const emFontSize = getMark(editor, 'emFontSize') || 1;
	const isActive = step >= 0 ? emFontSize > 1 : emFontSize < 1;

	return (
		<>
			<ToggleButton
				format={'emFontSize'}
				onClick={(event) => {
					event.preventDefault();
					event.stopPropagation();
					setMark(editor, 'emFontSize', emFontSize + step);
				}}
				selected={isActive}
				disabled={emFontSize + step <= 0}
			>
				<Icon
					step={step}
					// sx={{
					// 	color: isActive ? color.hex : 'inherit',
					// }}
				/>
			</ToggleButton>
		</>
	);
};

export default IncreaseFontButton;
