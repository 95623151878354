import React, {PureComponent} from 'react';
import Logger from 'businessLogic/services/Logger';
import AddElementInSelector from 'businessLogic/shared/MediaSelector/AddElementInSelector';
import './styles.scss';
import Dropzone from 'react-dropzone';
import {AUDIOS_QUERY, AUDIO_ADD_MUTATION, AUDIO_DELETE_MUTATION} from './query';
import {graphql} from 'react-apollo';
import {compose} from 'recompose';
import VideoAudioBoxRow from 'businessLogic/shared/MediaSelector/VideoAudioBoxRow';
import entityManager from 'businessLogic/services/EntityManager';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import get from 'lodash/get';

class AudiosList extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			filesInProcess: [],
			scrollToFetch: 0,
			page: 1,
		};

		this.uploadingFileId = 0;
	}

	upload = async (file) => {
		//Obtenemos el tamaño del fichero a subir para ver si supera el límite
		const fileSize = file.size;
		const sizeLimit = entityManager.getAudioUploadSizeLimit();
		if (fileSize > sizeLimit * 1024 * 1024) {
			alert(
				t`El fichero que estás intentando subir pesa más de ${sizeLimit}MB. Por favor, reduce el tamaño y vuelve a intentarlo.`,
			);
			return;
		}

		// Solicitamos la url de carga
		const uploadLinkFetchPromise = fetch('/getUploadLink', {
			method: 'post',
			credentials: 'same-origin',
			mode: 'same-origin',
			body: JSON.stringify({filename: file.name, type: file.type}),

			headers: {
				'Content-Type': 'application/json',
			},
		});

		// Obtenemos la url de carga
		const uploadLinkFetchRes = await uploadLinkFetchPromise;

		const uploadLinkFetchJson = await uploadLinkFetchRes.json();

		if (uploadLinkFetchJson.error !== 0) {
			Logger.captureError('Error al obtener la url de carga para subir el fichero', (scope) => {
				return scope;
			});
			alert(
				t`Se ha producido un error al subir el fichero. Prueba a recargar la página y volver a intentarlo. Si el problema persiste contacta con el administrador de la plataforma.`,
			);
		}

		const {url, relPath} = uploadLinkFetchJson;

		// Info del video que vamos a subir
		const fileToUpload = {
			id: ++this.uploadingFileId,
			file,
			progress: 0,
		};

		//Añadimos un audio dummy para que el usuario sepa que se está subiendo
		this.setState((state) => {
			const filesInProcess = [].concat(state.filesInProcess);
			filesInProcess.push(fileToUpload);
			return {filesInProcess};
		});

		// TODO: Obtener la duración del video y guardarla en base de datos
		// https://stackoverflow.com/questions/29285056/get-video-duration-when-input-a-video-file/29285597

		const ajax = new XMLHttpRequest();
		ajax.upload.addEventListener(
			'progress',
			(event) => {
				fileToUpload.progress = Math.round((100 * event.loaded) / event.total);
				this.setState((state) => {
					const filesInProcess = [].concat(state.filesInProcess);
					const fileIndex = filesInProcess.findIndex(
						(fileInProcess) => fileInProcess.id === fileToUpload.id,
					);

					if (fileIndex < 0) return;

					filesInProcess[fileIndex] = Object.assign({}, fileToUpload);
					return {filesInProcess};
				});
			},
			false,
		);
		ajax.addEventListener(
			'load',
			() => {
				if (ajax.status === 200) {
					// Obtenemos el nombre del fichero de relPath
					const relPathParts = relPath.split('/');
					const relPathPartsLength = relPathParts.length;
					const fileName = relPathParts[relPathPartsLength - 1];
					this.props
						.addNewAudio({
							input: {name: fileName, path: relPath, fileSize: fileSize},
						})
						.then(() => {
							this.removeLoading(fileToUpload);
						});
					return;
				}

				if (ajax.status === 413) {
					alert(
						t`El fichero que estás intentando subir pesa demasiado. Por favor, reduce el tamaño y vuelve a intentarlo.`,
					);
					//Eliminamos el audio dummy
					this.removeLoading(fileToUpload);
					return;
				}
				Logger.captureError('Error al subir fichero', (scope) => {
					scope.setContext('ajax', {status: ajax.status, response: ajax.response});
					return scope;
				});
				alert(
					t`Se ha producido un error al subir el fichero. Prueba a recargar la página y volver a intentarlo. Si el problema persiste contacta con el administrador de la plataforma.`,
				);
				this.removeLoading(fileToUpload);
			},
			false,
		);
		ajax.addEventListener(
			'error',
			(event) => {
				Logger.captureError('Evento de error al subir fichero', (scope) => {
					scope.setContext('event', {event});
					return scope;
				});
				console.error('Error al subir video', event);
				alert(
					t`Se ha producido un error al subir el fichero. Prueba a recargar la página y volver a intentarlo. Si el problema persiste contacta con el administrador de la plataforma.`,
				);
				this.removeLoading(fileToUpload);
			},
			false,
		);
		ajax.addEventListener(
			'abort',
			(event) => {
				console.error('Subida de fichero abortada', event);
				this.removeLoading(fileToUpload);
			},
			false,
		);

		ajax.open('PUT', url, true);
		ajax.setRequestHeader('Content-Type', file.type);
		ajax.send(file);
	};

	onDrop = (files) => {
		files.forEach(this.upload);
	};

	handleUpload = () => {
		this.uploadInput.click();
	};

	onUpload = (ev) => {
		ev.preventDefault();
		const files = this.uploadInput.files;
		Object.values(files).forEach(this.upload);
	};

	removeLoading = (fileToUpload) => {
		this.setState((state) => {
			const filesInProcess = [].concat(state.filesInProcess);
			const fileIndex = filesInProcess.findIndex(
				(fileInProcess) => fileInProcess.id === fileToUpload.id,
			);

			if (fileIndex < 0) return;

			filesInProcess.splice(fileIndex, 1);

			return {filesInProcess};
		});
	};

	deleteAudio = async (audio) => {
		let mutationDelete = await this.props.deleteAudio({
			idAudio: audio.id,
		});
		/**TODO faltaría darle estilos a no poder borrar el audio */

		if (!get(mutationDelete, 'data.deleteAudio.deleted')) {
			alert(t`No se puede borrar el audio, ya que está siendo usada en alguna pildora`);
		} else {
			this.props.audios.refetch();
		}
	};

	getAudios = () => {
		let renderAudiosLoading;
		const {loading, audios} = this.props.audios;
		const {onSelect} = this.props;
		if (loading) {
			renderAudiosLoading = <div>{t`Loading...`}</div>;
			return renderAudiosLoading;
		}
		if (audios.length === 0) {
			renderAudiosLoading = <div>{t`No hay ningún audio aún.`}</div>;
			return renderAudiosLoading;
		}

		if (this.state.filesInProcess.length > 0) {
			renderAudiosLoading = this.state.filesInProcess.map(() => {
				return (
					<VideoAudioBoxRow
						thumbnailImg="/uploads/default-images/120x100.png"
						videoTitle="----- ----- ----- -----"
						videoAutor="----- -----"
						videoDuration="0:00"
						key={`${audios.length + this.state.filesInProcess.length}key`}
					/>
				);
			});
		}
		let renderAudios;
		renderAudios = audios.map((audio) => {
			if (audio !== null) {
				return (
					<VideoAudioBoxRow
						key={audio.id}
						thumbnailImg="/uploads/default-images/120x100.png"
						videoTitle={audio.name}
						videoUrl={audio.path}
						videoAutor={audio.author}
						videoDuration="4:00"
						onDelete={() => this.deleteAudio(audio)}
						onClick={() => onSelect(audio.path)}
					/>
				);
			}
			return null;
		});
		if (Array.isArray(renderAudiosLoading) && renderAudiosLoading.length > 0) {
			renderAudios = renderAudiosLoading.concat(renderAudios);
		}
		return renderAudios;
	};

	handleScroll = (event) => {
		let scrollTop = event.srcElement.scrollTop;
		let scroll = event.srcElement.scrollHeight - 450;
		let scrollToFetch = scroll - 400;
		// let differentScroll = scrollTop - this.state.scrollTop;
		if (scrollTop > scrollToFetch) {
			if (scrollToFetch !== this.state.scrollToFetch) {
				let pageNew = this.state.page + 1;
				this.setState({
					scrollToFetch: scrollToFetch,
					page: pageNew,
				});
				this.props.fetchMoreToAudios(this.state.page);
			}
		}
	};
	render() {
		return (
			<Dropzone onDrop={this.onDrop} disableClick={true} activeStyle={{backgroundColor: '#CEE3F6'}}>
				{({getRootProps}) => (
					<div className="add-audio-thumbnails-box" {...getRootProps()}>
						{' '}
						<AddElementInSelector
							iconName="add-audio"
							nameButton={t`Añadir audio`}
							handleUpload={this.handleUpload}
						/>
						{this.getAudios()}
						<input
							style={{display: 'none'}}
							ref={(ref) => {
								this.uploadInput = ref;
							}}
							type={'file'}
							accept={'audio/*'}
							hidden={true}
							onChange={this.onUpload}
							multiple
						/>
					</div>
				)}
			</Dropzone>
		);
	}
}

const AUDIOS_LIMIT = 10;
const withData = graphql(AUDIOS_QUERY, {
	name: 'audios',
	options: () => {
		return {
			variables: {
				offset: 1,
				limit: AUDIOS_LIMIT,
			},
		};
	},
	props: (props) => {
		return {
			...props,
			fetchMoreToAudios: (page) => {
				return props.audios.fetchMore({
					variables: {
						offset: page,
					},
					updateQuery: (prev, {fetchMoreResult}) => {
						if (!fetchMoreResult.audios || fetchMoreResult.audios.length === 0) {
							return prev;
						}
						let arrAudioNew = [];
						for (var i in prev.audios) {
							var shared = false;
							for (var j in fetchMoreResult.audios)
								if (fetchMoreResult.audios[j].id === prev.audios[i].id) {
									shared = true;
									break;
								}
							if (!shared) arrAudioNew.push(prev.audios[i]);
						}
						arrAudioNew = arrAudioNew.concat(fetchMoreResult.audios);
						return Object.assign(
							{},
							{
								audios: arrAudioNew,
							},
						);
					},
				});
			},
		};
	},
})(AudiosList);

const withDataAndMutation = compose(
	graphql(AUDIO_ADD_MUTATION, {
		props({mutate}) {
			return {
				addNewAudio({input}) {
					return mutate({
						variables: {input},
						updateQueries: {
							Audios: (prev, {mutationResult}) => {
								const newAudio = mutationResult.data.addAudio;
								return Object.assign(
									{},
									{
										audios: [newAudio, ...prev.audios],
									},
								);
							},
						},
					});
				},
			};
		},
	}),
	graphql(AUDIO_DELETE_MUTATION, {
		props({mutate}) {
			return {
				deleteAudio({idAudio}) {
					return mutate({
						variables: {idAudio},
						update: (store, {data: {deleteAudio}}) => {
							const data = store.readQuery({
								query: AUDIOS_QUERY,
								variables: {offset: 1, limit: AUDIOS_LIMIT},
							});
							if (deleteAudio.deleted) {
								data.audios.some(function (item, index) {
									return data.audios[index]['id'] === deleteAudio.id
										? !!data.audios.splice(index, 1)
										: false;
								});
							}
							store.writeQuery({
								query: AUDIOS_QUERY,
								data,
								variables: {offset: 1, limit: AUDIOS_LIMIT},
							});
						},
					});
				},
			};
		},
	}),
)(withData);

export default withDataAndMutation;
