import React from 'react';

// Componente que muestra un elemento inline que al hacer hover (o click en moviles) muestra un tooltip con una imagen (opcional) y un texto (opcional)
// tal y como hace la wikipedia
const Wiki = React.forwardRef(({children, data, selected, ...attributes}, ref) => {
	// const uniqueId = React.useMemo(() => Math.random().toString(36).substring(7), []);
	// const [anchorEl, setAnchorEl] = React.useState(null);

	// const open = Boolean(anchorEl);

	// const handlePopoverOpen = (event) => {
	// 	// console.log('handlePopoverOpen: ', event);
	// 	setAnchorEl(event.currentTarget);
	// };

	// const handlePopoverClose = () => {
	// 	// console.log('handlePopoverClose: ');
	// 	setAnchorEl(null);
	// };

	return (
		<span
			className="my-wiki"
			data-image={data?.imageUrl}
			data-img-credits={data?.imageCredits}
			data-text={data?.text}
			style={{
				fontWeight: selected ? 'bold' : 'normal',
			}}
			{...attributes}
			ref={ref}
		>
			{children}
		</span>
	);
});

// 	return (
// 		<React.Fragment>
// 			<Box
// 				className="Wiki"
// 				sx={styles(sx)}
// 				aria-owns={open ? `wiki-${uniqueId}` : undefined}
// 				aria-haspopup="true"
// 				onMouseEnter={handlePopoverOpen}
// 				onMouseLeave={handlePopoverClose}
// 				onClick={handlePopoverOpen}
// 				{...attributes}
// 			>
// 				{children}
// 			</Box>
// 			<Popover
// 				id={`wiki-${uniqueId}`}
// 				sx={{
// 					pointerEvents: 'none',
// 				}}
// 				open={open}
// 				anchorEl={anchorEl}
// 				anchorOrigin={{
// 					vertical: 'bottom',
// 					horizontal: 'left',
// 				}}
// 				transformOrigin={{
// 					vertical: 'top',
// 					horizontal: 'left',
// 				}}
// 				onClose={handlePopoverClose}
// 				disableRestoreFocus
// 			>
// 				<Box sx={{p: 1}}>
// 					<img
// 						src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/80/Wikipedia-logo-v2.svg/1200px-Wikipedia-logo-v2.svg.png"
// 						alt="Wikipedia"
// 						width="100"
// 					/>
// 					<p>
// 						Wikipedia es una enciclopedia libre, <br />
// 						<a href="https://es.wikipedia.org/wiki/Wikipedia:Portada">más información</a>
// 					</p>
// 				</Box>
// 			</Popover>
// 		</React.Fragment>
// 	);
// }

export default Wiki;
