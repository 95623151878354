import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import PopUpHotSpot from 'businessLogic/core/shared/PopUps/PopUpHotSpot';
import classNames from 'classnames';
import Animation from 'businessLogic/core/shared/Animation';
import Portal from 'businessLogic/shared/Portal';
import './styles.scss';

class HotSpotPopUp extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			classPopUpDirectionDown: false,
			classPopUpDirectionUp: false,
		};
	}

	handleSpotClick = () => {
		this.props.onShow(this.props.index);
		if (window.innerWidth >= 768) {
			let spotPostionY = parseInt(this.refs.hotSpotNumber.getBoundingClientRect().top, 10);
			let windowHeight = parseInt(window.innerHeight / 2, 10);

			if (spotPostionY < windowHeight) {
				this.setState({
					classPopUpDirectionUp: false,
					classPopUpDirectionDown: true,
				});
			} else if (spotPostionY > windowHeight) {
				this.setState({
					classPopUpDirectionDown: false,
					classPopUpDirectionUp: true,
				});
			}
		}
	};

	render() {
		const {data, index, playAnimation, effect, delay, onClose} = this.props;
		const classes = classNames({'hotspot-pop-up': true});
		const classPopUp = classNames({
			'pop-up-hotspot': true,
			'pop-up-hotspot__show': this.state.classToggle,
		});

		const coordinatesX = data.coordinates.x || 0;
		const coordinatesY = data.coordinates.y || 0;
		return (
			<React.Fragment>
				{coordinatesX !== 0 && coordinatesY !== 0 && (
					<React.Fragment>
						<div
							className={classes + (this.props.active === index ? ' shown ' : '')}
							key={index}
							style={{top: coordinatesY + '%', left: coordinatesX + '%'}}
						>
							<div className="hotspot-pop-up__trigger">
								<Animation playAnimation={playAnimation} effect={effect} delay={delay}>
									{this.props.showNumbers === true ? (
										<div
											className="hotspot-pop-up__number"
											onClick={this.handleSpotClick}
											ref="hotSpotNumber"
										>
											{index + 1}
										</div>
									) : (
										<div
											className="hotspot-pop-up__pointer"
											ref="hotSpotNumber"
											onClick={this.handleSpotClick}
										/>
									)}
								</Animation>
							</div>

							{window.innerWidth >= 768 && (
								<div
									ref="hotSpotPopUp"
									className={
										'pop-up-hotspot__desktop ' +
										classPopUp +
										'' +
										(this.props.active === index ? ' show ' : '')
									}
								>
									<PopUpHotSpot
										data={data}
										index={index}
										key={index}
										popUpDirectionDown={this.state.classPopUpDirectionDown}
										popUpDirectionUp={this.state.classPopUpDirectionUp}
										device={'desktop'}
									/>
								</div>
							)}
						</div>

						{window.innerWidth < 768 && (
							<Portal id="portals">
								<div
									className={
										'pop-up-hotspot__mobile ' +
										classPopUp +
										(this.props.active === index ? ' show ' : '')
									}
									ref="hotSpotPopUp"
								>
									<PopUpHotSpot
										index={index}
										key={index}
										data={data}
										popUpDirectionDown={this.state.classPopUpDirectionDown}
										popUpDirectionUp={this.state.classPopUpDirectionUp}
										onClose={onClose}
										device={'mobile'}
									/>
								</div>
							</Portal>
						)}
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}

HotSpotPopUp.propTypes = {
	data: PropTypes.object,
	index: PropTypes.number,
	onClose: PropTypes.func,
	onShow: PropTypes.func,
	playAnimation: PropTypes.bool,
	showNumbers: PropTypes.bool,
};

HotSpotPopUp.defaultProps = {
	data: {},
	index: 0,
};

export default HotSpotPopUp;
