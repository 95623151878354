import React, {PureComponent} from 'react';
import classNames from 'classnames';
import brickWrapper from 'businessLogic/core/bricks/brickWrapper';
import Container from 'businessLogic/core/shared/Container/Container';
import brickImage from './img/brickPreview.jpg';
import CallActionTestBrickWithBoxes from 'businessLogic/core/shared/Test/CallActionTestBrickWithBoxes';
import ModalForTestBricks from 'businessLogic/core/shared/Test/ModalForTestBricks';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import Icon from 'ui/shared/Icon';
import Test from './components/Test';
import ClosedNotice from './components/ClosedNotice';
import store from 'businessLogic/store/store.js';
import {getEmail} from 'businessLogic/store/data/session';

import InstructionTestBrick from 'businessLogic/core/shared/Test/InstructionTestBrick';
import get from 'lodash/get';
import editionFields from './edit.js';
import {t} from 'businessLogic/scope/admin/helper/adminTtag';
import {gettext as _} from 'businessLogic/scope/user/helper/userTtag';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import './styles.scss';

class Survey extends PureComponent {
	static editionFields = editionFields;
	static brickName = () => t`Encuesta`;
	static brickIcon = 'test';
	static brickImage = brickImage;
	static canReload = true;
	static brickHelpText = () =>
		t`Permite al administrador recopilar datos del usuario mediante diferentes tipos de cuestionarios combinables.`;
	static brickDefaultConfig = () => ({
		brickName: t`Nombre de la Encuesta`,
		brickDescription: t`Permite al administrador recopilar datos del usuario mediante diferentes tipos de cuestionarios combinables.`,
		requiredActions: true,
	});

	static brickDefaultData = () => ({
		/*Datos comunes a todos los elementos del repeater */
		image: {
			imageSrc: '/uploads/default-images/ilustration_test.svg',
			credit: '',
		},
		textLinkInstructions: '',
		instructions: '',
		beforeHeading: _('ENCUESTA'),
		title: _('Encuesta con varias preguntas'),
		textButtonStart: _('EMPEZAR'),
		/*Escoger un tipo entre estas posibles plantillas de campos  */
		flexibleContent: [],
	});

	//Método estático para generar los headers del csv a partir de los datos del ladrillo introducidos por el administrador
	static getHeaders = (brickData) => {
		const brickDataFlexible = brickData.data.flexibleContent;
		const dinamicHeader = [{label: _('Fecha de inscripción'), key: 'createdAt'}];

		for (let i = 0; i < brickDataFlexible.length; i++) {
			if (brickDataFlexible[i].maxOptionsResponses === undefined) {
				//Casos para una sola respuesta
				dinamicHeader.push({
					label: brickDataFlexible[i].name,
					key: brickDataFlexible[i]._id + '.value',
				});
			} else {
				//Casos multirespuesta
				//No hay máximo de respuestas
				if (
					brickDataFlexible[i].maxOptionsResponses === 0 ||
					brickDataFlexible[i].maxOptionsResponses === ''
				) {
					for (let j = 0; j < brickDataFlexible[i].options.length; j++) {
						dinamicHeader.push({
							label: brickDataFlexible[i].name + '_' + (j + 1),
							key: brickDataFlexible[i]._id + '.value.' + j,
						});
					}
				} else {
					//Hay máximo de respuestas
					for (let j = 0; j < brickDataFlexible[i].maxOptionsResponses; j++) {
						dinamicHeader.push({
							label: brickDataFlexible[i].name + '_' + (j + 1),
							key: brickDataFlexible[i]._id + '.value.' + j,
						});
					}
				}
			}
		}

		if (get(brickData, 'config.includeUserEmail', false)) {
			dinamicHeader.push({
				label: t`Usuario`,
				key: 'user',
			});
		}

		return dinamicHeader;
	};

	constructor(props) {
		super(props);
		this.state = {
			type: 'Survey',
			stateMachine: 'intro',
			completed: false,
			dataSent: this.props.brickAnomDataExists(), //Bool. Informa de si existen datos almacenados de esta encuesta
		};
		this.responsesData = {};
		this.survey = React.createRef();
		this.props.getBrickStateData('').then((brickStateData) => {
			if (brickStateData && brickStateData.state) {
				this.setState(brickStateData.state);

				//Parche para solventar los casos donde no se ha guardado el status del ladrillo
				if (brickStateData.state.stateMachine === 'result' && !brickStateData.status) {
					this.initStateTestResult();
				}
			}
		});
	}
	saveBrickState = () => {
		this.props.setBrickStateData('state', this.state);
	};
	sendData = async () => {
		const includeUserEmail = get(this.props, 'brick.config.includeUserEmail', false);
		if (includeUserEmail) {
			const currentUser = getEmail(store.getState());

			this.responsesData.user = currentUser;
		}
		this.props.setBrickAnomData(this.responsesData);
		this.props.setBrickStateData('response', this.responsesData);
		this.setState({dataSent: true}, this.initStateTestResult);
	};
	initStateTestIntro = () => {
		this.setState({stateMachine: 'intro'}, this.saveBrickState);
	};
	initStateTest = () => {
		this.setState({stateMachine: 'test'});
	};

	initStateTestResult = () => {
		this.setState({stateMachine: 'result'}, this.saveBrickState);
		//Lanzamos el evento de fin de test
		this.props.brickEvent('end');
		//Marcamos el test como completado y añadidmos si es evaluable
		const status = {
			completed: true,
			success: true,
		};
		this.props.setBrickStateData('status', status).then(() => {
			this.props.configObject.callEventListeners('brickStatusChanged', {
				brick: 'Survey',
				type: 'info',
				status,
			});
		});
	};

	// Escuchamos el evento surveyCompleted
	surveyCompleted = (event) => {
		const {pillId, brickId, brickType, brickState} = event.detail;
		if (
			brickId === this.props.brick.id &&
			pillId === this.props.pillId &&
			brickType === 'Survey' &&
			brickState === 'result'
		) {
			this.props.getBrickStateData('').then((brickStateData) => {
				if (brickStateData && brickStateData.state) {
					this.setState(brickStateData.state);

					//Parche para solventar los casos donde no se ha guardado el status del ladrillo
					if (brickStateData.state.stateMachine === 'result' && !brickStateData.status) {
						this.initStateTestResult();
					}
				}
			});
		}
	};
	componentDidMount() {
		window.addEventListener('surveyCompleted', this.surveyCompleted);
	}

	componentWillUnmount() {
		window.removeEventListener('surveyCompleted', this.surveyCompleted);
	}

	render() {
		const data = this.props.brick.data;
		const content = data.flexibleContent || [];
		const mainStyleClass = 'survey-brick';
		const classes = classNames({
			brick: true,
			[`${mainStyleClass}`]: true,
		});
		const configNameBrick = get(this.props.brick, ['config', 'brickName'], '');
		let intro = (
			<CSSTransition key="intro" timeout={500} classNames="show">
				<div className="step stept--1">
					<CallActionTestBrickWithBoxes
						type="toStart"
						textButton={data.textButtonStart}
						beforeHeading={data.beforeHeading}
						image={data.image}
						onClick={this.initStateTest}
						set={this.props.set}
						className={classes}
					>
						<div className={mainStyleClass + '__title'}>
							{/* <p dangerouslySetInnerHTML={{__html: setEllipsisText(data.title, 30)}} /> */}
							<FrontEditableText fieldName="title" text={data.title} set={this.props.set} />
						</div>
						<InstructionTestBrick
							textLabel={data.textLinkInstructions}
							textHelp={data.instructions}
							theme="dark"
						/>
					</CallActionTestBrickWithBoxes>
				</div>
			</CSSTransition>
		);
		let survey = (
			<CSSTransition key="test" timeout={500} classNames="show">
				<div className="step stept--2">
					<ModalForTestBricks
						onClose={this.initStateTestIntro}
						isEditMode={this.props.editMode}
						exitMessage={_(
							'¿Quieres continuar con la encuesta? si abandonas se perderan todos tus datos',
						)}
						text={this.props.exitMessage}
					>
						<Test
							beforeHeading={data.beforeHeading}
							collectionflexibleContent={content}
							onFinishTest={(responses) => {
								this.responsesData = Object.assign(this.responsesData, responses);
								this.sendData();
							}}
							set={this.props.set}
						/>
					</ModalForTestBricks>
				</div>
			</CSSTransition>
		);
		let result = (
			<CSSTransition key="tresult" timeout={500} classNames="show">
				<CallActionTestBrickWithBoxes
					type="toInfoFinish"
					textButton={data.textButtonAgain}
					// beforeHeading={data.beforeHeading}
					// textInstruction={data.instructions}
					set={this.props.set}
					className={classes}
				>
					{/* <div className={mainStyleClass + '__title--main'}>
						<FrontEditableText fieldName="title" text={data.title} set={this.props.set} />
					</div> */}
					<div className={mainStyleClass + '__feedback-thankfulness'}>
						<Icon name="emoticon--very-happy" size="normal" />
					</div>
					<div className={mainStyleClass + '__title--feedback-thankfulness'}>
						<p>{_('¡Gracias por participar!')}</p>
					</div>
				</CallActionTestBrickWithBoxes>
			</CSSTransition>
		);

		let step;
		switch (this.state.stateMachine) {
			default:
			case 'intro':
				step = intro;
				break;
			case 'test':
				step = survey;
				break;
			case 'result':
				step = result;
				break;
		}
		// Si la encuesta se ha dado por cerrada , el admin la puede seguir viendo pero no será visible para el usuario final

		if (data.isClosed) {
			if (!this.props.editMode) return null;
			return <ClosedNotice text={configNameBrick} />;
		}

		return (
			<Container size="full-width">
				<div className={classes}>
					<TransitionGroup className="todo-list">
						{this.state.dataSent ? result : step}
					</TransitionGroup>
				</div>
			</Container>
		);
	}
}

export default brickWrapper(Survey);
