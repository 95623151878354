import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FrontEditableText from 'businessLogic/shared/Text/FrontEditableText';
import {slugify} from '@createandshare/tools';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {t} from 'businessLogic/scope/user/helper/userTtag';

import {ReactComponent as Img1} from './img/1.svg';
import {ReactComponent as Img2} from './img/2.svg';
import {ReactComponent as Img3} from './img/3.svg';
import {ReactComponent as Img4} from './img/4.svg';
import {ReactComponent as Img5} from './img/5.svg';

import './styles.scss';

/****
Los valores de cada respuesta deben de ser guardado siempre con esta estructura 

			response: {
				[autoID]{
					value
				}
			}
	
**** */

class VisualAssessmentQuestion extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			response: {},
		};
	}

	canContinue = () => {
		if (!get(this.props, 'flexibleContent.required', false) || !isEmpty(this.state.response)) {
			return true;
		}
		return false;
	};

	parseTypeNumericInt = (string) => {
		if (isNaN(string) || string === '') return undefined;
		return parseInt(string, 10);
	};
	getPointsScale = () => {
		const min = this.parseTypeNumericInt(this.props.flexibleContent.min);
		const max = this.parseTypeNumericInt(this.props.flexibleContent.max);
		const scale = 1;

		const multiplesScale = [];
		if (scale === undefined || scale === 0) {
			return undefined;
		}
		for (let i = min; i <= max; i++) {
			if (i % scale === 0) {
				multiplesScale.push(i);
			}
		}
		return multiplesScale;
	};

	handleChange = (value) => {
		const name = this.props.flexibleContent._id;
		this.setState(
			{
				response: {
					[name]: {
						value,
					},
				},
			},
			this.sendDataQuestion,
		);
	};

	sendDataQuestion = () => {
		const dataState = Object.assign({}, this.state.response);
		const keyData = this.props.flexibleContent._id;
		const type = this.props.flexibleContent.flexibleContentType;
		const name = slugify(this.props.flexibleContent.name);
		if (dataState[keyData]) {
			Object.assign(dataState[keyData], {type}, {name});
			this.props.onFinishQuestion(dataState);
		}
	};

	render() {
		const mainStyleClass = 'visual-assessment-question';
		const classes = classNames({
			[`${mainStyleClass}`]: true,
		});
		let {
			// flexibleContent: {_id, statement, min, max},
			flexibleContent: {_id, statement, reduced},
		} = this.props;

		const value = get(this.state.response, [_id.toString(), 'value']);

		const selectionValues = [t`Muy malo`, t`Malo`, t`Normal`, t`Bueno`, t`Muy bueno`];

		return (
			<div className={classes}>
				<div className="row">
					<div className="col-xs-12 col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
						<div className={mainStyleClass + '__statement swiper-no-swiping'}>
							<FrontEditableText
								text={statement}
								fieldName="statement"
								set={(fieldPath, fieldData) => {
									//Propagamos la llamada al método set añadiendo mi nodo al path
									if (this.props.set)
										this.props.set(
											['flexibleContent', this.props.index].concat(fieldPath),
											fieldData,
										);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12 col-sm-12 col-sm-offset-0 col-xl-8 col-xl-offset-2">
						<div className={mainStyleClass + '__slider swiper-no-swiping'}>
							{/* Fila con un botón circular por cada número entre min y max
									Al hacer click en uno, se llama a handleChange con el valor correspondiente
									El elemento seleccionado queda marcado */}
							<div className={mainStyleClass + '__slider__row'}>
								{!reduced && (
									<div
										className={`${mainStyleClass}__slider__row__item__container ${
											value === selectionValues[0] && 'selected'
										} face1`}
										onClick={() => this.handleChange(selectionValues[0])}
									>
										<Img1 />
									</div>
								)}
								<div
									className={`${mainStyleClass}__slider__row__item__container ${
										reduced && 'reduced'
									}
									${value === selectionValues[1] && 'selected'} face2`}
									onClick={() => this.handleChange(selectionValues[1])}
								>
									<Img2 />
								</div>
								<div
									className={`${mainStyleClass}__slider__row__item__container ${
										reduced && 'reduced'
									}
									${value === selectionValues[2] && 'selected'} face3`}
									onClick={() => this.handleChange(selectionValues[2])}
								>
									<Img3 />
								</div>
								<div
									className={`${mainStyleClass}__slider__row__item__container ${
										reduced && 'reduced'
									}
									${value === selectionValues[3] && 'selected'} face4`}
									onClick={() => this.handleChange(selectionValues[3])}
								>
									<Img4 />
								</div>
								{!reduced && (
									<div
										className={`${mainStyleClass}__slider__row__item__container ${
											value === selectionValues[4] && 'selected'
										} face5`}
										onClick={() => this.handleChange(selectionValues[4])}
									>
										<Img5 />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				{/* {showLabelRow && (
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-sm-offset-0 col-xl-8 col-xl-offset-2">
							<div className={mainStyleClass + '__labels__row'}>
								<div className={mainStyleClass + '__labels__row__item'}>{minLabel}</div>

								<div className={mainStyleClass + '__labels__row__item'}>{maxLabel}</div>
							</div>
						</div>
					</div>
				)} */}
			</div>
		);
	}
}

VisualAssessmentQuestion.propTypes = {
	flexibleContent: PropTypes.object,
	onFinishQuestion: PropTypes.func,
	set: PropTypes.func,
	index: PropTypes.number,
};

VisualAssessmentQuestion.defaultProps = {
	flexibleContent: {
		min: 0,
		max: 100,
		scale: 10,
	},
};

export default VisualAssessmentQuestion;
