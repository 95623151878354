import {t} from 'businessLogic/scope/admin/helper/adminTtag';

export const editionFields = () => [
	{
		type: 'Tabs',
		content: [
			{
				type: 'Tab',
				title: t`Contenido`,
				fieldName: 'data',
				content: [
					{
						type: 'Audio',
						fieldName: 'audio',
						title: t`Podcast`,
						placeholder: t`Selecciona un Podcast`,
					},
					{
						type: 'Image',
						fieldName: 'imageObj',
						title: t`Imagen del podcast`,
						defaultImage: '/uploads/default-images/csDefault1Opt.svg',
					},
					{
						type: 'TextEditor',
						fieldName: 'podcastTitle',
						title: t`Título del podcast`,
						placeholder: t`Introduce el título del podcast`,
					},

					{
						type: 'TextEditor',
						fieldName: 'podcastDescription',
						title: t`Descripción del podcast`,
						placeholder: t`Introduce el descripción del podcast`,
					},

					{
						type: 'Repeater',
						max: 6,
						fieldName: 'participants',
						blockTitle: t`Participantes`,
						content: [
							{
								type: 'Image',
								fieldName: 'imageObj',
								defaultImage: '/uploads/default-images/user.png',
								credit: false,
							},

							{
								type: 'TextEditor',
								fieldName: 'participantName',
								title: t`Nombre del participante`,
								placeholder: t`Introduce el nombre del participante`,
							},
						],
					},
				],
			},
			{
				type: 'Tab',
				title: t`Estilos`,
				fieldName: 'styles',
				content: [
					{
						type: 'ThemeSelector',
						fieldName: ['containerStyle', 'themeSelected'],
					},
				],
			},
		],
	},
];
export default editionFields;
