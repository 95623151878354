import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'ui/shared/Icon';
import {imageUrl as imageUrlFunc} from 'businessLogic/helpers/images';

import './styles.scss';

class ThumbnailBox extends Component {
	getThumbnailSize = () => {
		switch (this.props.size) {
			case 'normal':
				return 300;
			case 'large':
				return 450;
			case 'small':
			default:
				return 150;
		}
	};
	handleClick = () => {
		if (this.props.onClick) {
			const imgId = this.props.imgId !== undefined ? this.props.imgId : this.props.imageUrl;
			this.props.onClick(imgId);
		}
	};
	handleDelete = () => {
		if (this.props.onDelete) {
			const imgId = this.props.imgId !== undefined ? this.props.imgId : this.props.imageUrl;
			this.props.onDelete(imgId);
		}
	};
	render() {
		const {
			className,
			title,
			subtitle,
			onDelete,
			hoverEffect,
			onClick,
			size,
			imageUrl,
			cover,
		} = this.props;
		const classes = classNames({
			'thumbnail-box': true,
			'thumbnail-box--hoverEffect': hoverEffect,
			'thumbnail-box--delete': onDelete,
			'thumbnail-box--clickable': onClick,
			'thumbnail-box--title': title,
			[`thumbnail-box--${size}`]: true,

			[`${className}`]: true,
		});
		const styles = {
			image: {
				backgroundImage: 'url("' + imageUrlFunc(imageUrl, this.getThumbnailSize(), true) + '")',
				backgroundSize: cover ? 'cover' : 'contain',
			},
		};

		//obtener el nombre del fichero pdf para ponerlo con el thumbnail
		let pathObject = '';
		if (typeof this.props.imgId !== 'undefined') {
			pathObject = this.props.imgId.split('/');
		}

		//obtener tamaño de objeto devuelto por split
		Object.size = function(obj) {
			var psize = 0,
				key;
			for (key in obj) {
				if (obj.hasOwnProperty(key)) psize++;
			}
			return psize;
		};
		let psize = Object.size(pathObject);

		//el nombre del fichero siempre será la última posición del objeto devuelto por split
		let newFileName = pathObject[--psize];

		return (
			<div className={classes} title={newFileName}>
				<span className="thumbnail-box__title">{title}</span>
				<div className="thumbnail-box__image-container" onClick={this.handleClick}>
					<div className="thumbnail-box__image" style={styles.image} />
					{subtitle && (
						<div className={`thumbnail-box__subtitle thumbnail-box__subtitle--${size}`}>
							{subtitle}
						</div>
					)}
				</div>

				<div className="thumbnail-box__delete-button" onClick={this.handleDelete}>
					<Icon name="delete" size="small" />
				</div>
			</div>
		);
	}
}

ThumbnailBox.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	imageUrl: PropTypes.string,
	onDelete: PropTypes.func,
	onClick: PropTypes.func,
	hoverEffect: PropTypes.bool,
	size: PropTypes.oneOf(['small', 'normal', 'large']),
	cover: PropTypes.bool,
	imgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ThumbnailBox.defaultProps = {
	className: '',
	title: '',
	subtitle: '',
	imageUrl: '',
	hoverEffect: true,
	onDelete: null,
	size: 'small',
	cover: true,
};
export default ThumbnailBox;
